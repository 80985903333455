import React from "react";

import Box from "konnekt/Box";
import Label from "konnekt/Label";
import Icon from "konnekt/Icon";
import Button from "konnekt/Buttons";
import { ModalHeader, ResponsiveModal } from "konnekt/Modal";

const CancelCampaignModal = ({
  isCancelModalOpen,
  setIsCancelModalOpen,
  campaignName,
  onCancelCampaign,
}) => {
  const closeModel = () => setIsCancelModalOpen(false);

  return (
    <ResponsiveModal
      isOpen={isCancelModalOpen}
      onHide={closeModel}
      width={{ _: "554px" }}
      maxWidth="100%"
    >
      <ModalHeader onHide={closeModel}>Cancel campaign</ModalHeader>
      <Box p="16px 24px">
        <Label variant="text7">
          Are you sure you want to cancel{" "}
          <Label as="span" variant="text6">
            {campaignName}
          </Label>{" "}
          campaign?
        </Label>
        <Box
          p="8px 12px"
          bg="rgba(253, 176, 34, 0.1)"
          display="flex"
          alignItems="center"
          mt="8px"
          borderRadius="4px"
          width="fit-content"
        >
          <Icon iconName="infoOutlined" iconSize="tiny" color="#FDB022" />
          <Label ml="12px" variant="text8">
            Please note, this action cannot be undone.
          </Label>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gridGap="16px"
        p="8px 24px 16px"
      >
        <Button
          type="outlineGreen"
          p="8px 12px"
          minWidth="80px"
          minHeight="36px"
          onClick={closeModel}
        >
          No
        </Button>
        <Button
          p="8px 12px"
          minWidth="80px"
          minHeight="36px"
          onClick={onCancelCampaign}
        >
          Yes
        </Button>
      </Box>
    </ResponsiveModal>
  );
};

export default CancelCampaignModal;
