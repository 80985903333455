import React from "react";

const carouselCard = ({ fill = "#00A68B", ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...rest}
    >
      <g clipPath="url(#clip0_777_4247)">
        <path
          d="M5.8335 4.99935C5.8335 4.77834 5.92129 4.56637 6.07757 4.41009C6.23385 4.25381 6.44582 4.16602 6.66683 4.16602H13.3335C13.5545 4.16602 13.7665 4.25381 13.9228 4.41009C14.079 4.56637 14.1668 4.77834 14.1668 4.99935V14.9993C14.1668 15.2204 14.079 15.4323 13.9228 15.5886C13.7665 15.7449 13.5545 15.8327 13.3335 15.8327H6.66683C6.44582 15.8327 6.23385 15.7449 6.07757 15.5886C5.92129 15.4323 5.8335 15.2204 5.8335 14.9993V4.99935Z"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3332 14.1673H17.4998C17.2788 14.1673 17.0669 14.0795 16.9106 13.9232C16.7543 13.767 16.6665 13.555 16.6665 13.334V6.66732C16.6665 6.4463 16.7543 6.23434 16.9106 6.07806C17.0669 5.92178 17.2788 5.83398 17.4998 5.83398H18.3332"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6665 14.1673H2.49984C2.72085 14.1673 2.93281 14.0795 3.08909 13.9232C3.24537 13.767 3.33317 13.555 3.33317 13.334V6.66732C3.33317 6.4463 3.24537 6.23434 3.08909 6.07806C2.93281 5.92178 2.72085 5.83398 2.49984 5.83398H1.6665"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_777_4247">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default carouselCard;
