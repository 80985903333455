import PropTypes from "prop-types";
import React, { useRef } from "react";
import styled from "styled-components";
import { layout, space, color, flexbox, variant } from "styled-system";
import ReactDOM from "react-dom";
import useOnKeyPress from "customHooks/useOnKeyPress";
import { ESC } from "constants/KeyboardKeys";
import Box from "konnekt/Box";

const StyledModal = styled.div`
  position: absolute;
  z-index: 1040;
  position: absolute;
  height: auto;
  background-color: #fff;
  outline: none;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 584px;
  width: 100%;

  ${() =>
    variant({
      variants: {
        center: {},
        rightSide: {
          left: "unset",
          right: 0,
          top: 0,
          height: "100%",
          transform: "unset",
          borderRadius: 0,
          minWidth: "516px",
          maxWidth: "90vw",
          width: "unset",
        },
      },
    })}

  ${layout} 
  ${space} 
  ${flexbox} 
  ${color}
`;

const mapOverlayCss = {
  normal: {
    backgroundColor: "#222",
  },
  greyBg: {
    backgroundColor: "#32374E",
  },
};

const Modal = ({
  children,
  isOpen,
  onHide,
  style,
  overlayCss = {},
  overlayVariant = "normal",
  ...rest
}) => {
  const modalRef = useRef();
  useOnKeyPress([ESC], modalRef, isOpen ? onHide : null);

  if (!isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <>
      <Box
        position="fixed"
        top={0}
        left={0}
        zIndex={1040}
        width="100%"
        height="100%"
        opacity={0.5}
        pointerEvents="auto"
        onClick={onHide}
        data-testid="modal-close-button"
        {...(mapOverlayCss[overlayVariant] || {})}
        {...overlayCss}
      />
      <StyledModal ref={modalRef} {...rest} style={style}>
        {children}
      </StyledModal>
    </>,
    document.getElementById("modal"),
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  overlayVariant: PropTypes.oneOf(["normal", "greyBg"]),
  variant: PropTypes.oneOf(["center", "rightSide"]),
};

Modal.defaultProps = {
  isOpen: false,
  iconName: null,
  overlayVariant: "normal",
  variant: "center",
};

export default Modal;
