import _ from "lodash";
import {WABA_API_STATUS_APPROVED} from "constants/enums/WABAAPIApprovalStatuses";

const onboardingStatuses = {
    ONBOARDED: "Onboarded",
    OTP_VERIFICATION_PENDING: "Otp Verification Pending",
    CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

export const getIfOrganizationIsOnboarded = (organizations) => {
  return organizations[0] &&
    organizations[0].organization_id &&
    organizations[0].organization_id.onboarding_status
    ? organizations[0].organization_id.onboarding_status ===
        onboardingStatuses.ONBOARDED
    : false;
};

export const getIfOrganizationWABAIsApproved = (organizations) => {
  return organizations[0] &&
    organizations[0].organization_id &&
    organizations[0].organization_id.wa_api_approval_status
    ? organizations[0].organization_id.wa_api_approval_status ===
      WABA_API_STATUS_APPROVED
    : false;
};

export const removeAllExtraChar = (str) => {
    if (_.isString(str)) {
        return str.replace(/[^1-9]/g, "");
    } else {
        return "";
    }
};
