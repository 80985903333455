const closeIfIsPopupWindow = (
  // This is name that you do set from where you open popup
  windowNameRegex = /(salesChannelPopup-).*/,
) => {
  if (window.opener && window.name.match(windowNameRegex)) {
    window.close();
  }
};

export default closeIfIsPopupWindow;
