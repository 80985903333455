import React from "react";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";

const withUseSignupSourceTally = (WrappedComponent) => {
  return (props) => {
    const isSignupSourceTally = useIsSignupSourceTally();

    return (
      <WrappedComponent {...props} isSignupSourceTally={isSignupSourceTally} />
    );
  };
};

export default withUseSignupSourceTally;
