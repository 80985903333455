/* eslint-disable default-param-last */
import { showNotification } from "util/utility";
import {
  getSelectedChannelId,
  getUserOrganizationId,
} from "modules/App/AppSelectors";
import storageService from "services/storageService";
import {
  SHOPIFY_AUTOCHECKOUT_TEMPLATE_GOT_TIMEDOUT,
  SHOPIFY_AUTOCHECKOUT_TEMPLATE_WAS_REJECTED,
} from "constants/storageService";
import { callApiV2 } from "../../util/apiCaller";
import {
  CHANGE_COLLECTIONS_SAVED_STATUS,
  GET_COLLECTIONS,
  SET_COLLECTION_BODY,
  SET_COLLECTION_IN_TOP_10,
  UPADTE_LOADING_COLLECTIONS,
  UPDATE_COLLECTIONS_FETCH,
  AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE,
  AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED,
  APPROVE_AUTO_CHECKOUT_SAMPLE,
  AUTO_CHECKOUT_LOADER,
  AUTO_CHECKOUT_MESSAGE_UPDATE,
  AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED,
  FETCH_AUTO_CHECKOUT_MESSAGE_DETAILS,
  CHOOSE_AUTO_CHECKOUT_TEMPLATE,
  REJECTED_AUTO_CHECKOUT_SAMPLE,
  INIT_SAMPLE_APPROVED_AND_USED,
  UPDATE_CONNECTION_SYNC_STATUS,
  SET_CATALOG_SOURCE,
  SET_CATALOG_NAME,
  SET_CATALOG_TOTAL_COUNT,
  SET_CATALOG_LAST_SYNC_AT,
  TEMPLATE_TIMEOUT,
} from "./CommerceSettingsActionTypes";
import { getCatlogConnectionStatus } from "./CommerceSettingsSelectors";
import { CATALOG_CONNECTION_SUCCESS } from "./constants";

export const getCollections = (orgId) => async (dispatch) => {
  try {
    dispatch({
      type: UPADTE_LOADING_COLLECTIONS,
      payload: true,
    });
    dispatch({
      type: UPDATE_COLLECTIONS_FETCH,
      payload: true,
    });
    const res = await callApiV2({
      endpoint: `/v1/organizations/${orgId}/collections/`,
      isErrorSuppressed: true,
    });
    dispatch({
      type: GET_COLLECTIONS,
      payload: res.data.collections,
    });
  } catch (err) {
    dispatch({
      type: UPADTE_LOADING_COLLECTIONS,
      payload: false,
    });
    dispatch({
      type: UPDATE_COLLECTIONS_FETCH,
      payload: true,
    });
  }
};

export const setCollectionsBody = (collectionId, body) => ({
  type: SET_COLLECTION_BODY,
  payload: {
    id: collectionId,
    body,
  },
});

export const setIncludeInTop10 = (collectionId, isChecked) => ({
  type: SET_COLLECTION_IN_TOP_10,
  payload: {
    id: collectionId,
    isChecked,
  },
});

export const changeCollectionsSavedStatus = (collectionSavedStatus) => ({
  type: CHANGE_COLLECTIONS_SAVED_STATUS,
  payload: collectionSavedStatus,
});

export const createOrUpdateCheckoutMessage = (
  isEnabled,
  withToast,
  isSampleTemplateBeingUsed = false,
) => async (dispatch, getState) => {
  const orgId = getUserOrganizationId(getState());
  const {
    templateId,
    mergeVariablesBody,
    mergeVariablesHeader,
  } = getState().commerceSettings.autoCheckout;

  try {
    dispatch({
      type: AUTO_CHECKOUT_LOADER,
      payload: isSampleTemplateBeingUsed,
    });

    const res = await callApiV2({
      endpoint: `v1/organizations/${orgId}/checkout-message/`,
      method: "post",
      body: {
        template_id: templateId,
        is_enabled: isEnabled,
        message_type: "shopify_cart",
        merge_variables_body: mergeVariablesBody,
        merge_variables_header: mergeVariablesHeader,
      },
      isErrorSuppressed: true,
    });
    if (res.result) {
      dispatch({
        type: AUTO_CHECKOUT_MESSAGE_UPDATE,
        payload: {
          isEnabled: res.data.is_enabled,
        },
      });
      if (withToast) {
        showNotification("success", "Enabled Auto Checkout");
      }
    } else {
      dispatch({
        type: AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED,
      });
      if (withToast) {
        showNotification(
          "error",
          "Something went wrong in updating checkout message",
        );
      }
    }
  } catch (err) {
    dispatch({
      type: AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED,
    });
    if (withToast) {
      showNotification(
        "error",
        "Something went wrong in updating checkout message",
      );
    }
  }
};

export const approveAutoCheckoutSample = (id, approvalStatus) => (
  dispatch,
  getState,
) => {
  const currenttemplateId = getState().commerceSettings.autoCheckout.templateId;
  if (id === currenttemplateId && approvalStatus === "APPROVED") {
    dispatch(createOrUpdateCheckoutMessage(true, false, true));
    storageService.remove(SHOPIFY_AUTOCHECKOUT_TEMPLATE_WAS_REJECTED, 1);
    dispatch({
      type: APPROVE_AUTO_CHECKOUT_SAMPLE,
    });
  }
  if (id === currenttemplateId && approvalStatus === "REJECTED") {
    storageService.set(SHOPIFY_AUTOCHECKOUT_TEMPLATE_WAS_REJECTED, 1);
    dispatch({
      type: REJECTED_AUTO_CHECKOUT_SAMPLE,
    });
  }
};

export const templateGotTimedout = () => async (disptach, getState) => {
  const { templateId } = getState().commerceSettings.autoCheckout;

  // ? If template was created then only do timeout
  if (templateId) {
    storageService.set(SHOPIFY_AUTOCHECKOUT_TEMPLATE_GOT_TIMEDOUT, 1);
    disptach({
      type: TEMPLATE_TIMEOUT,
    });
  }
};

export const initSampleApprovedAndUsedForFirst = () => ({
  type: INIT_SAMPLE_APPROVED_AND_USED,
});

export const createAutoCheckoutTemplate = (
  template,
  mergeVariablesBody = {},
  mergeVariablesHeader = {},
  onSuccess,
) => async (dispatch, getState) => {
  const orgId = getUserOrganizationId(getState());
  const channelId = getSelectedChannelId(getState());
  try {
    dispatch({
      type: AUTO_CHECKOUT_LOADER,
      payload: true,
    });
    const res = await callApiV2({
      endpoint: `v1/organizations/${orgId}/message-templates/channels/${channelId}/`,
      method: "post",
      body: template,
    });
    if (res.result) {
      if (onSuccess) {
        onSuccess();
      }
      dispatch({
        type: AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE,
        payload: {
          templateId: res.id,
          mergeVariablesBody,
          mergeVariablesHeader,
        },
      });
    } else {
      dispatch({
        type: AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED,
    });
  }
};

export const fetchAutoCheckoutMessageDetails = () => async (
  dispatch,
  getState,
) => {
  const orgId = getUserOrganizationId(getState());
  try {
    const res = await callApiV2({
      endpoint: `v1/organizations/${orgId}/checkout-message/`,
    });
    if (res.result) {
      if (res.data) {
        dispatch({
          type: FETCH_AUTO_CHECKOUT_MESSAGE_DETAILS,
          payload: {
            templateId: res.data.template?.id,
            isEnabled: res.data.is_enabled,
            mergeVariablesHeader: res.data.template
              ? res.data.merge_variables_header ?? {}
              : {},
            mergeVariablesBody: res.data.template
              ? res.data.merge_variables_body ?? {}
              : {},
          },
        });
      }
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const chooseAutoCheckoutTemplate = (
  templateId,
  mergeVariablesHeader,
  mergeVariablesBody,
) => ({
  type: CHOOSE_AUTO_CHECKOUT_TEMPLATE,
  payload: {
    templateId,
    mergeVariablesHeader,
    mergeVariablesBody,
  },
});

export const updateConnectionSyncStatus = (
  connectionSyncStatus,
  isProductSyncStatus = false,
) => (dispatch, getState) => {
  if (
    !isProductSyncStatus ||
    // Will assume syncStatus to be for connection and not product sync status if calatog connection status was not SUCCESS
    getCatlogConnectionStatus(getState()) !== CATALOG_CONNECTION_SUCCESS
  ) {
    return dispatch({
      type: UPDATE_CONNECTION_SYNC_STATUS,
      payload: connectionSyncStatus,
    });
  }
  return null;
};

export const setCatalogSource = (catalogSource) => ({
  type: SET_CATALOG_SOURCE,
  payload: catalogSource,
});

export const setCatalogName = (catalogName) => ({
  type: SET_CATALOG_NAME,
  payload: catalogName,
});

export const setCatalogTotalCount = (catalogTotalCount) => ({
  type: SET_CATALOG_TOTAL_COUNT,
  payload: catalogTotalCount,
});

export const setCatalogLastSyncAt = (catalogLastSyncTime) => ({
  type: SET_CATALOG_LAST_SYNC_AT,
  payload: catalogLastSyncTime,
});
