import { useQuery } from "@tanstack/react-query";

import useOrganizationId from "customHooks/useOrganizationId";

import { callApiV2 } from "util/apiCaller";
import { CONNECTED, INSTAGRAM, WHATSAPP } from "constants/channelConstants";

export const RQ_FETCH_ORG_CHANNEL_LIST = "RQ_FETCH_ORG_CHANNEL_LIST";

const fetchOrgChannelList = async (orgID) => {
  try {
    const res = await callApiV2({
      method: "get",
      endpoint: `v1/organizations/${orgID}/channel_account/`,
    });

    if (res?.result) {
      return res.data;
    }

    throw new Error(res.message);
  } catch (error) {
    throw new Error(error.message);
  }
};

const useOrgChannelList = ({ select = (data) => data, options = {} } = {}) => {
  const orgId = useOrganizationId();

  return useQuery(
    [RQ_FETCH_ORG_CHANNEL_LIST, orgId],
    () => fetchOrgChannelList(orgId),
    {
      ...options,
      enabled:
        options.enabled === undefined ? !!orgId : !!options.enabled && !!orgId,
      select,
    },
  );
};

export const getInstaChannelDetails = (data) => {
  return data
    ?.filter((item) => item.channel_type === INSTAGRAM)?.[0]
    ?.accounts?.filter((item) => item.status === CONNECTED)?.[0];
};

export const checkIfInstaConnected = (data) => {
  return getInstaChannelDetails(data)?.status === CONNECTED;
};

export const getInstaChannelAccountIdentifier = (data) => {
  return getInstaChannelDetails(data)?.channel_account_identifier;
};

export const getInstaActiveChannelid = (data) => {
  return getInstaChannelDetails(data)?.channel_account_id;
};

export const getWhatsappChannelDetails = (data) => {
  return data
    ?.filter((item) => item.channel_type === WHATSAPP)?.[0]
    ?.accounts?.filter((item) => item.status === CONNECTED)?.[0];
};

export const getWhatsappChannelAccountIdentifier = (data) => {
  return getWhatsappChannelDetails(data)?.channel_account_identifier;
};

export const getWhatsappActiveChannelid = (data) => {
  return getWhatsappChannelDetails(data)?.channel_account_id;
};

export const getWhatsappSignupType = (data) => {
  return getWhatsappChannelDetails(data)?.signup_type;
};

export const getWhatsappContactsSynced = (data) => {
  return getWhatsappChannelDetails(data)?.meta_data?.initial_contacts_synced;
};

export const getWhatsappContactsLastSyncedAt = (data) => {
  return getWhatsappChannelDetails(data)?.meta_data
    ?.initial_contacts_synced_last_updated_at_utc;
};

export default useOrgChannelList;
