import {
  GET_ORDER_DETAIL_START,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAILURE,
  SEND_RESPONSE_START,
  SEND_RESPONSE_SUCCESS,
  SEND_RESPONSE_FAILURE,
} from "./type";

const initialState = { data: {}, loadingPage: true, loadingButton: "" };

const codOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_DETAIL_START:
      return { ...state, loadingPage: true };
    case GET_ORDER_DETAIL_SUCCESS:
      return { ...state, loadingPage: false, data: action.payload };
    case GET_ORDER_DETAIL_FAILURE:
      return { ...state, loadingPage: true, data: action.payload };
    case SEND_RESPONSE_START:
      return { ...state, loadingButton: action.payload };
    case SEND_RESPONSE_SUCCESS:
    case SEND_RESPONSE_FAILURE:
      return { ...state, loadingButton: "" };

    default:
      return state;
  }
};

export default codOrderReducer;
