import Box from "konnekt/Box";
import Label from "konnekt/Label";
import Modal from "konnekt/Modal";
import useUnreadNudge, { getNudgeList } from "useApi/Inbox/useUnreadNudge";
import useMutateNudge from "useApi/Inbox/useMutateNudge";
import SIDEBAR_WIDTH, { SIDEBAR_EXPANDED_WIDTH } from "constants/sidebar";
import { RQ_FETCH_READ_NUDGE } from "useApi/Inbox/useReadNudge";
import useOrganizationId from "customHooks/useOrganizationId";
import { useQueryClient } from "@tanstack/react-query";
import { Mixpanel } from "util/tracking";
import AnnouncementCard from "./AnnouncementCard";
import { INT_CONCIERGE_MARK_ALL_AS_READ } from "../constant";

const AnnouncementsPopup = ({ showContent, onHide, ...rest }) => {
  const orgId = useOrganizationId();
  const queryClient = useQueryClient();

  const { data: nudgeList } = useUnreadNudge({ select: getNudgeList });

  const { mutateAsync: mutateNudgeAsync, isLoading } = useMutateNudge();

  const renderAnnouncements = () => {
    return nudgeList?.map((nudge, index) => {
      return (
        <AnnouncementCard
          key={nudge.id}
          id={nudge.id}
          name={nudge.title}
          description={nudge.body}
          time={nudge.created_at_utc}
          showDivider={nudgeList.length - 1 !== index}
          index={index}
        />
      );
    });
  };

  const handleMarkAllAsRead = async () => {
    if (isLoading) {
      return;
    }

    await mutateNudgeAsync({
      data: {
        mark_all_read: "true",
      },
    });

    queryClient.invalidateQueries({
      queryKey: [RQ_FETCH_READ_NUDGE, orgId],
    });

    Mixpanel.track(INT_CONCIERGE_MARK_ALL_AS_READ, {});
  };

  return (
    <Modal
      isOpen
      width="400px"
      borderRadius="4px"
      boxShadow="0px 5px 13px 0px #6666661A"
      overlayCss={{
        left: showContent ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_WIDTH,
      }}
      style={{
        left: showContent
          ? `calc(${SIDEBAR_EXPANDED_WIDTH} + 4px)`
          : `calc(${SIDEBAR_WIDTH} + 4px)`,
        transform: "translateY(0)",
        top: "unset",
        bottom: "10px",
      }}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p="16px"
        borderBottom="1px solid #D0D4E1"
      >
        <Label variant="text2" color="#0A0B10">
          Announcements
        </Label>
        <Label
          lineHeight="1"
          onClick={handleMarkAllAsRead}
          variant="text6"
          color="#0067DD"
        >
          Mark all as read
        </Label>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxHeight="70vh"
        overflowY="auto"
      >
        {renderAnnouncements()}
      </Box>
    </Modal>
  );
};

export default AnnouncementsPopup;
