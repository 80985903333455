// Code delivery Type
export const CODE_DELIVERY_ZERO_TAP = "ZERO_TAP";
export const CODE_DELIVERY_ONE_TAP = "ONE_TAP";
export const CODE_DELIVERY_COPY_CODE = "COPY_CODE";

interface AuthBodyList {
  [key: string]: {
    mainText: string;
    security: string;
    validityPeriod: string;
  };
}

// Auth template body list

export const authBodyList: AuthBodyList = {
  af: {
    mainText: "{{1}} is jou verifikasiekode.",
    security: " Moenie hierdie kode deel nie, vir jou eie sekuriteit.",
    validityPeriod: "Hierdie kode verstryk oor {{2}} minute.",
  },
  ar: {
    mainText: "‏{{1}}‏ هو كود التحقق الخاص بك.",
    security: "تشارك هذا الكود مع أي شخص.",
    validityPeriod: "تنتهي صلاحية هذا الكود خلال ‏{{2}}‏ دقائق.",
  },
  az: {
    mainText: "{{1}} sizin təsdiqləmə kodunuzdur.",
    security: "Təhlükəsizliyiniz üçün bu kodu paylaşmayın.",
    validityPeriod: "Bu kodun müddəti {{2}} dəqiqəyə bitir.",
  },
  bg: {
    mainText: "{{1}} е вашият код за потвърждение.",
    security: "С оглед на сигурността ви не споделяйте този код.",
    validityPeriod: "Този код изтича след {{2}} минути",
  },
  bn: {
    mainText: "আপনার যাচাইকরণের কোড {{1}}",
    security: "। আপনার নিরাপত্তার জন্য এই কোড শেয়ার করবেন না।",
    validityPeriod: "এই কোডের মেয়াদ {{2}} মিনিটের মধ্যে শেষ হবে।",
  },
  ca: {
    mainText: "{{1}} és el teu codi de verificació.",
    security: "Per seguretat, no el comparteixis.",
    validityPeriod: "Aquest codi caduca d'aquí a {{2}} minuts.",
  },
  cs: {
    mainText: "Váš ověřovací kód je {{1}}.",
    security: "Z bezpečnostních důvodů kód s nikým nesdílejte.",
    validityPeriod: "Platnost kódu skončí za {{2}} min.",
  },
  da: {
    mainText: "{{1}} er din verificeringskode.",
    security: "Af hensyn til din sikkerhed må du ikke dele denne kode.",
    validityPeriod: "Denne kode udløber om {{2}} minutter.",
  },
  de: {
    mainText: "Dein Verifizierungscode lautet {{1}}.",
    security: " Aus Sicherheitsgründen solltest du den Code nicht teilen.",
    validityPeriod: "Dieser Code verfällt in {{2}} Minuten.",
  },
  el: {
    mainText: "Το {{1}} είναι ο κωδικός επαλήθευσης.",
    security: "Για τη δική σας ασφάλεια, μην τον κοινοποιήσετε σε τρίτους.",
    validityPeriod: "Ο κωδικός λήγει σε {{2}} λεπτά.",
  },
  en: {
    mainText: "{{1}} is your verification code.",
    security: "For your security, do not share this code.",
    validityPeriod: "This code expires in {{2}} minutes.",
  },
  en_GB: {
    mainText: "{{1}} is your verification code.",
    security: "For your security, do not share this code.",
    validityPeriod: "This code expires in {{2}} minutes.",
  },
  en_US: {
    mainText: "{{1}} is your verification code.",
    security: "For your security, do not share this code.",
    validityPeriod: "This code expires in {{2}} minutes.",
  },
  es: {
    mainText: "Tu código de verificación es {{1}}.",
    security: "Por tu seguridad, no lo compartas.",
    validityPeriod: "Este código caduca en {{2}} minutos.",
  },
  es_AR: {
    mainText: "Tu código de verificación es {{1}}.",
    security: "Por tu seguridad, no lo compartas.",
    validityPeriod: "Este código caduca en {{2}} minutos.",
  },
  es_ES: {
    mainText: "Tu código de verificación es {{1}}.",
    security: "Por tu seguridad, no lo compartas.",
    validityPeriod: "Este código caduca en {{2}} minutos.",
  },
  es_MX: {
    mainText: "Tu código de verificación es {{1}}.",
    security: "Por tu seguridad, no lo compartas.",
    validityPeriod: "Este código caduca en {{2}} minutos.",
  },
  et: {
    mainText: "{{1}} on sinu kinnituskood.",
    security: "Turvalisuse huvides ära jaga seda koodi.",
    validityPeriod: "See kood aegub {{2}} minuti pärast.",
  },
  fa: {
    mainText: "‏{{1}}‏ کد درستی‌سنجی شما است. برای حفظ امنیتتان این کد",
    security: "را به‌اشتراک نگذارید.",
    validityPeriod: "این کد تا ‏{{2}‏ دقیقه دیگر منقضی می‌شود.",
  },
  fi: {
    mainText: "{{1}} on vahvistuskoodisi.",
    security: "Pidä koodi omana tietonasi turvallisuussyistä.",
    validityPeriod: "Tämä koodi vanhenee {{2}} minuutin kuluttua.",
  },
  fil: {
    mainText: "{{1}} ang iyong verification code.",
    security: "Para sa iyong seguridad, huwag i-share ang code na ito.",
    validityPeriod: "Mag-e-expire ang code na ito sa loob ng {{2}} minuto.",
  },
  fr: {
    mainText: "Votre code de vérification est {{1}}.",
    security: "Pour votre sécurité, ne le partagez pas.",
    validityPeriod: "Ce code expire dans {{2}} minutes.",
  },
  ga: {
    mainText: "Is é {{1}} do chód fíoraithe.",
    security: "Ar mhaithe le do shlándáil, ná comhroinn an cód.",
    validityPeriod: "Rachaidh an cód seo in éag i gceann {{2}} nóiméad.",
  },
  gu: {
    mainText: "{{1}}એ તમારો ખાતરીનો કોડ છે.",
    security: "તમારી સુરક્ષા માટે, આ કોડ કોઈની સાથે શેર કરશો નહિ.",
    validityPeriod: "આ કોડ {{2}} મિનિટમાં સમાપ્ત થશે.",
  },
  ha: {
    mainText: "{{{1}} ita ce lambar sirrinka ta tantancewa.",
    security: "Saboda tsaro, kar ka yaɗa wannan lambar sirrin.",
    validityPeriod: "Wa'adin wannan lambar sirrin zai cika nan da minti {{2}}.",
  },
  he: {
    mainText: "‏{{1}}‏ הוא קוד האימות שלך. מטעמי אבטחה, אין ",
    security: "לשתף את הקוד הזה.",
    validityPeriod: "תוקף הקוד הזה יפוג בעוד ‏{{2}}‏ דקות.",
  },
  hi: {
    mainText: "{{1}} आपका वेरिफ़िकेशन कोड है. अपनी सुरक्षा के लिए",
    security: ", इस कोड को किसी के साथ शेयर न करें.",
    validityPeriod: "यह कोड {{2}} मिनट में एक्सपायर हो जाएगा.",
  },
  hr: {
    mainText: "{{1}} je vaš kôd za provjeru valjanosti. ",
    security: "Nemojte dijeliti kôd radi svoje sigurnosti.",
    validityPeriod: "Ovaj kôd istječe za {{2}} min.",
  },
  hu: {
    mainText: "A(z) {{1}} az ellenőrző kódod.",
    security: "A saját biztonságod érdekében mással ne oszd meg ezt a kódot.",
    validityPeriod: "Ez a kód {{2}} perc múlva lejár.",
  },
  id: {
    mainText: "{{1}} adalah kode verifikasi Anda.",
    security: "Demi keamanan, jangan bagikan kode ini.",
    validityPeriod: "Kode ini kedaluwarsa dalam {{2}} menit.",
  },
  it: {
    mainText: "{{1}} è il tuo codice di verifica.",
    security:
      "Per garantire la tua sicurezza, ti consigliamo di non condividere questo codice.",
    validityPeriod: "Questo codice scadrà tra 10 minuti.",
  },
  ja: {
    mainText: "{{1}}があなたの認証コードです。",
    security: "セキュリティのため、このコードは他の人に共有しないでください。",
    validityPeriod: "このコードは{{2}}分後に期限切れとなります",
  },
  kk: {
    mainText: "{{1}} — сіздің растау кодыңыз.",
    security: "Қауіпсіздігіңіз үшін бұл кодпен бөліспеңіз.",
    validityPeriod: "Кодтың мерзімі {{2}} минуттан кейін аяқталады.",
  },
  kn: {
    mainText: "{{1}} ಇದು ನಿಮ್ಮ ಪರಿಶೀಲನೆ ಕೋಡ್ ಆಗಿದೆ.",
    security: "ನಿಮ್ಮ ಭದ್ರತೆಗಾಗಿ, ಈ ಕೋಡ್ ಅನ್ನು ಹಂಚಿಕೊಳ್ಳಬೇಡಿ.",
    validityPeriod: "ಈ ಕೋಡ್ 10 ನಿಮಿಷಗಳಲ್ಲಿ ಅವಧಿ ಮೀರುತ್ತದೆ",
  },
  ko: {
    mainText: "인증 코드는 {{1}}입니다.",
    security: "보안을 위해 이 코드는 공유하지 마세요.",
    validityPeriod: "이 코드는 {{2}}분 후에 만료됩니다.",
  },
  lo: {
    mainText: "{{1}} ແມ່ນລະຫັດຢືນຢັນຂອງທ່ານ.",
    security: "ສຳລັບຄວາມປອດໄພຂອງທ່ານ, ຢ່າແບ່ງປັນລະຫັດນີ້.",
    validityPeriod: "ລະຫັດນີ້ຈະໝົດອາຍຸໃນ {{2}} ນາທີ.",
  },
  lt: {
    mainText: "{{1}} yra jūsų patvirtinimo kodas.",
    security: "Saugos sumetimais nebendrinkite šio kodo.",
    validityPeriod: "Šis kodas baigs galioti po {{2}} min.",
  },
  lv: {
    mainText: "{{1}} ir jūsu verifikācijas kods.",
    security: "Jūsu drošībai nekopīgojiet šo kodu.",
    validityPeriod: "Koda derīgums beidzas pēc {{2}} minūtēm",
  },
  mk: {
    mainText: "{{1}} е твојот код за верификација.",
    security: "За твоја безбедност, не го споделувај овој код.",
    validityPeriod: "Кодов истекува за {{2}} минути.",
  },
  ml: {
    mainText: "നിങ്ങളുടെ വെരിഫിക്കേഷൻ കോഡ് {{1}} ആണ്.",
    security: "നിങ്ങളുടെ സുരക്ഷയ്‌ക്ക്, ഈ കോഡ് പങ്കിടരുത്.",
    validityPeriod: "ഈ കോഡ് {{2}} മിനിറ്റിനുള്ളിൽ കാലഹരണപ്പെടുന്നു",
  },
  mr: {
    mainText: "{{1}} हा तुमचा व्हेरिफिकेशन कोड आहे.",
    security: "तुमच्या सुरक्षेसाठी हा कोड शेअर करू नका.",
    validityPeriod: "हा कोड {{2}} मिनिटांमध्ये एक्स्पायर होतो.",
  },
  ms: {
    mainText: "{{1}} adalah kod penentusahan anda.",
    security: "Untuk keselamatan anda, jangan kongsikan kod ini.",
    validityPeriod: "Kod ini akan luput dalam masa {{2}} minit.",
  },
  nb: {
    mainText: "{{1}} er bekreftelseskoden din.",
    security: "Av hensyn til sikkerheten din må du ikke dele denne koden.",
    validityPeriod: "Denne koden utløper om 10 minutter.",
  },
  nl: {
    mainText: "{{1}} is uw verificatiecode.",
    security: "Deel deze code om veiligheidsredenen niet.",
    validityPeriod: "Deze code verloopt over {{2}} minuten.",
  },
  pa: {
    mainText: "ਤੁਹਾਡਾ ਤਸਦੀਕ ਕੋਡ {{1}} ਹੈ। ਆਪਣੀ ਸੁਰੱਖਿਆ ਦੇ ਲਈ",
    security: ", ਇਹ ਕੋਡ ਕਿਸੇ ਨਾਲ ਵੀ ਸਾਂਝਾ ਨਾ ਕਰੋ।",
    validityPeriod: "ਇਸ ਕੋਡ ਦੀ ਮਿਆਦ {{2}} ਮਿੰਟਾਂ ਵਿੱਚ ਸਮਾਪਤ ਹੋ ਜਾਵੇਗੀ।",
  },
  pl: {
    mainText: "{{1}} to kod weryfikacyjny.",
    security: "Ze względów bezpieczeństwa nie udostępniaj tego kodu.",
    validityPeriod: "Ten kod wygaśnie za {{2}} minut.",
  },
  pt_BR: {
    mainText: "Seu código de verificação é {{1}}.",
    security: "Para sua segurança, não o compartilhe.",
    validityPeriod: "Este código expira em {{2}} minutos.",
  },
  pt_PT: {
    mainText: "Seu código de verificação é {{1}}.",
    security: "Para sua segurança, não o compartilhe.",
    validityPeriod: "Este código expira em {{2}} minutos.",
  },
  ro: {
    mainText: "{{1}} este codul de verificare.",
    security: "Pentru siguranţa dvs., nu dezvăluiţi acest cod.",
    validityPeriod: "Acest cod va expira peste {{2}} minute.",
  },
  ru: {
    mainText: "Ваш код подтверждения — {{1}}.",
    security: "Из соображений безопасности не сообщайте никому этот код.",
    validityPeriod: "Срок действия этого кода истекает через {{2}} минут.",
  },
  sk: {
    mainText: "Váš overovací kód je {{1}}.",
    security: "V záujme vašej bezpečnosti tento kód s nikým nezdieľajte.",
    validityPeriod: "Platnosť tohto kódu vyprší o {{2}} min.",
  },
  sl: {
    mainText: "Vaša potrditvena koda je {{1}}. ",
    security: "Zaradi varnosti je ne delite.",
    validityPeriod: "Koda bo potekla čez 10 minut.",
  },
  sq: {
    mainText: "{{1}} është kodi yt i verifikimit.",
    security: "Për sigurinë tënde, mos e ndaj këtë kod.",
    validityPeriod: "Ky kod skadon pas {{2}} minutash.",
  },
  sr: {
    mainText: "{{1}} је ваш кôд за потврду.",
    security: "Ради сопствене безбедности, немојте га делити ни са ким.",
    validityPeriod: "Овај кôд истиче за {{2}} минута.",
  },
  sv: {
    mainText: "{{1}} är din verifieringskod.",
    security: "Av säkerhetsskäl ska du inte dela koden med någon annan.",
    validityPeriod: "Koden går ut om {{2}} minuter.",
  },
  sw: {
    mainText: "{{1}} ndio msimbo wako wa uthibitishaji.",
    security: "Kwa usalama wako, usiushiriki.",
    validityPeriod: "Msimbo huu utakwisha muda baada ya dakika {{2}}.",
  },
  ta: {
    mainText: "{{1}} என்பது உங்கள் சரிபார்ப்புக் குறியீடாகும்.",
    security:
      "பாதுகாப்பு நோக்கங்களுக்காக, இந்தக் குறியீட்டைப் பகிர வேண்டாம் என்று கேட்டுக் கொள்கிறோம்.",
    validityPeriod: "இந்தக் குறியீடு {{2}} நிமிடங்களில் காலாவதியாகும்.",
  },
  te: {
    mainText: "{{1}} మీ వెరిఫికేషన్ కోడ్. ",
    security: "మీ భద్రత కోసం ఈ కోడ్‌ను షేర్ చేయవద్దు.",
    validityPeriod: "ఈ కోడ్ గడువు {{2}} నిమిషాల్లో ముగుస్తుంది.",
  },
  th: {
    mainText: "รหัสยืนยันของคุณคือ {{1}}",
    security: "โปรดอย่าบอกรหัสนี้แก่ผู้อื่นเพื่อความปลอดภัยของคุณ",
    validityPeriod: "รหัสนี้จะหมดอายุใน {{2}} นาที",
  },
  tr: {
    mainText: "Doğrulama kodunuz: {{1}}.",
    security: "Güvenliğiniz için bu kodu paylaşmayın.",
    validityPeriod: "Bu kodun süresi {{2}} dakika sonra doluyor.",
  },
  uk: {
    mainText: "Ваш код підтвердження – {{1}}.",
    security: "З міркувань безпеки не повідомляйте нікому цей код.",
    validityPeriod: "Термін дії цього коду закінчується через {{2}} хвилин.",
  },
  ur: {
    mainText: "{{1}}‏ آپ کا توثیقی کوڈ ہے۔ اپنی حفاظت کی خاطر یہ کوڈ ",
    security: "شیئر نہ کریں۔",
    validityPeriod: "یہ کوڈ ‏10‏ منٹ میں زائد المیعاد ہو جائے گا۔",
  },
  uz: {
    mainText: "{{1}} – tasdiqlash kodingiz.",
    security: "Xavfsizlik maqsadida bu kodni hech kimga yubormang.",
    validityPeriod: "Bu kod muddati {{2}} daqiqadan keyin tugaydi.",
  },
  vi: {
    mainText: "{{1}} là mã xác minh của bạn.",
    security: "Để bảo mật, bạn đừng chia sẻ mã này.",
    validityPeriod: "Mã này sẽ hết hạn trong {{2}} phút nữa.",
  },
  zh_CN: {
    mainText: "{1}} 是您的验证码。",
    security: "为安全起见，请不要分享这组验证码。",
    validityPeriod: "这组验证码将在 {{2}} 分钟后过期",
  },
  zh_HK: {
    mainText: "{{1}} 是您的驗證碼。",
    security: "為安全起見，請勿分享此驗證碼。",
    validityPeriod: "此代碼將於 {{2}} 分鐘後失效。",
  },
  zh_TW: {
    mainText: "{1}} 是您的驗證碼。",
    security: "為了安全起見，請勿分享此驗證碼。",
    validityPeriod: "這組驗證碼將於 {{2}} 分鐘後失效。",
  },
  zu: {
    mainText: "Okuthi {{1}} kuyikhodi yakho yokuqinisekisa.",
    security: "Ukuze uthole ukuvikeleka, ungayithumeleli muntu le khodi.",
    validityPeriod: "Le khodi iphelelwa yisikhathi emizuzwini engu-{{2}}.",
  },
};
