import { v4 as uuidv4 } from "uuid";
import {
  PENDING,
  WAITING,
  APPROVED,
  REJECTED,
  OPTION_NONE,
  TYPE_MPM,
} from "./constants";
import {
  ARCHIVE_TEMPLATE_DEATILS,
  ARCHIVING_TEMPLATE_DEATILS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_STATUS,
  FETCH_ACTIVE_TEMPLATE_LIST_STATUS,
  FETCH_ACTIVE_TEMPLATE_LIST_V2,
  FETCH_DELETED_TEMPLATE_LIST,
  FETCH_DELETED_TEMPLATE_LIST_STATUS,
  CLEAR_ACTIVE_TEMPLATE,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_LIST,
  FETCH_TEMPLATE_LIST_STATUS,
  FETCH_TEMPLATE_STATUS,
  SYNC_TEMPLATE,
  TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL,
  UNARCHIVE_TEMPLATE_DEATILS,
  UNARCHIVING_TEMPLATE_DEATILS,
  UPDATE_ACTIVE_TEMPLATE_LIST,
  UPDATE_DELETED_TEMPLATE_LIST,
  UPDATE_TEMPLATE_DETAIL,
  UPDATE_TEMPLATE_LIST,
  UPDATING_TEMPLATE_DETAIL,
  TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION,
  UPDATE_ORG_TEMPLATES,
  EXPAND_COLLAPSE_TEMPLATE_CARD,
  SAVE_SAMPLE_TEMPLATE,
  ADD_FLOW_DETAIL_TO_TEMPLATE_FLOW,
  REMOVE_TEMPLATE_FLOW,
  UPDATE_TEMPLATE_BUTTONS_ORDER,
  UPDATE_COUPON_CODE,
  UPDATE_TEMPLATE_QUICK_REPLIES,
  UPDATE_TEMPLATE_PHONE_NUMBER,
  UPDATE_TEMPLATE_WEBSITE_URL,
  LOAD_INITIAL_TEMPLATE_BUTTONS,
  ADD_NEW_TEMPLATE_BUTTONS_SECTION,
  ADD_NEW_TEMPLATE_LTO_SECTION,
  RESET_TEMPLATE_BUTTONS,
  RESET_TEMPLATE_LTO,
  RESET_TEMPLATE_BUTTONS_BY_TEMPLATE_INDEX,
  RESET_TEMPLATE_LTO_BY_TEMPLATE_INDEX,
  UPDATE_BUTTON_SECTION_TYPE,
  UPDATE_LTO_TEXT,
  UPDATE_SEND_PRODUCT_BUTTON_TYPE,
  TEMPLATE_CAROUSEL_INIT_ITEM,
  TEMPLATE_CAROUSEL_ADD_NEW_ITEM,
  TEMPLATE_CAROUSEL_DELETE_ITEM,
  TEMPLATE_CAROUSEL_UPDATE_ITEM,
  TEMPLATE_CAROUSEL_DELETE_BUTTON,
  TEMPLATE_CAROUSEL_UPDATE_BUTTON,
  UPDATE_TEMPLATE_CAROUSEL_FOR_VIEW,
  RESET_TEMPLATE_CAROUSEL,
  INIT_TEMPLATE_CAROUSEL_FALLBACK,
  UPDATE_CAROUSEL_FALLBACK_BODY,
  UPDATE_CAROUSEL_FALLBACK_BUTTONS,
  ADD_CAROUSEL_CARDS_FOR_TEMPLATE_SELECTION,
  ADD_NEW_TEMPLATE_CAROUSEL_SECTION,
  UPDATE_CAROUSEL_FALLBACK_HEADER,
  LOADING_CAROUSEL_CARDS_FOR_TEMPLATE,
  ADD_NEW_AUTH_TEMPLATE_SECTION,
  UPDATE_CODE_DELIVERY_TYPE,
  UPDATE_ZERO_TAP_CHECKBOX,
  ADD_NEW_PACKAGE_NAME_IN_AUTH_TEMPLATE,
  UPDATE_PACKAGE_LIST_IN_AUTH_TEMPLATE,
  UPDATE_SECURITY_RECOMMENDATION_IN_AUTH_TEMPLATE,
  UPDATE_EXPIRY_TIME_STATUS_IN_AUTH_TEMPLATE,
  UPDATE_EXPIRY_TIME_IN_AUTH_TEMPLATE,
  UPDATE_AUTOFILL_TEXT_IN_AUTH_TEMPLATE,
  UPDATE_COPY_CODE_TEXT_IN_AUTH_TEMPLATE,
  UPDATE_VALIDITY_STATUS_IN_AUTH_TEMPLATE,
  UPDATE_VALIDITY_PERIOD_IN_AUTH_TEMPLATE,
  TOGGLE_CHANNEL_SELECTION_MODAL,
  SET_FLOW_BUTTON_TEXT,
  SET_FLOW_ID,
  SET_SCREEN_ID,
  SET_NAVIGATE_CHECKED,
  SET_FLOW_TOKEN,
  SET_FLOW_DATA,
  UPDATE_AUTH_TEMPLATE_FOR_VIEW,
  RESET_AUTH_TEMPLATE_STATE,
} from "./TemplateActions";
import {
  carouselDeleteButton,
  carouselUpdateButton,
  getCarouselDataFromAPIParams,
  getFallbackList,
  getUpdatedCarouselButtons,
} from "./pages/templateUtility";
import { isEmpty } from "lodash";
import { CODE_DELIVERY_ZERO_TAP } from "./pages/CreateTemplatePage/components/auth-template/auth-template-constant";

const getInitButtons = () => {
  return {
    couponCode: [],
    quickReplies: [],
    phoneNumber: [],
    websiteUrl: [],
    whatsAppFlow: [],
  };
};

const getInitLTO = () => {
  return {
    ltoText: "",
  };
};

const getInitButtonsOrder = () => {
  return ["websiteUrl", "quickReplies"];
};

const initialState = {
  templateList: [],
  activeTemplate: {},
  activeTemplateListV2: [],
  deletedTemplateList: [],
  pageNum: 1,
  pageSize: 2000,
  groupListCount: 0,
  totalCount: 0,
  activeTotalCount: 0,
  deletedTotalCount: 0,
  isCreatingTemplate: false,
  isFetchingTemplateList: false,
  isFetchingActiveTemplateListV2: false,
  isFetchingActiveTemplateList: false,
  isFetchingDeletedTemplateList: false,
  isFetchingInitialActiveTemplateList: true,
  isFetchingInitialDeletedTemplateList: false,
  isTemplateListLoaded: false,
  originalTemplateList: [],
  activeOriginalTemplateList: [],
  activeOriginalTemplateListV2: [],
  deletedOriginalTemplateList: [],
  isArchivingTemplateDetail: false,
  isArchiveTemplateDetail: false,
  isUnarchivingTemplateDetail: false,
  isUnarchiveTemplateDetail: false,
  isUpdatingTemplateDetail: false,
  isSyncing: false,
  isTemplateSampleModalVisible: false,
  isTemplateSampleModalConfirmationVisible: false,
  templateCardExpandId: null,
  sampleTemplateList: [],
  templateCreationApprovalFlows: {},
  templateButtons: [],
  templateButtonsOrder: [],
  templateButtonsSectionType: OPTION_NONE,
  templateSendProductButtonType: TYPE_MPM,
  templateLTO: [],
  templateButtonsAllSectionType: [], // this is required older template with different buttons type
  templateCarousels: [],
  templateCarouselCardsTemplateSelection: [],
  templateCarouselFallBacks: [],
  isLoadingCarouselsForTemplate: false,
  authTemplateList: [],
  isChannelSelectionModalOpen: false,
  flowButtonText: "",
  flowId: "",
  screenId: "",
  isNavigateChecked: true,
  flowToken: "",
  flowData: "",
};

const mapStatus = (status) => {
  switch (status) {
    case PENDING:
      return 1;
    case WAITING:
      return 1;
    case APPROVED:
      return 2;
    case REJECTED:
      return 2;
    default:
      return 3;
  }
};

const groupList = (list) => {
  const newList = Object.values(
    list.reduce((acc, current) => {
      acc[current.name] = acc[current.name] ?? [];
      acc[current.name].push(current);
      return acc;
    }, {}),
  );
  const formattedList = newList.map((items) => {
    const obj = {};
    obj.id = items[0]?.id;
    obj.name = items[0]?.name;
    obj.display_name = items[0]?.display_name;
    obj.category = items[0]?.category;
    obj.modified_at_utc = items[0]?.modified_at_utc;
    obj.created_by_user = items[0]?.created_by_user || {};
    obj.created_at_utc = items[0]?.created_at_utc || "";
    obj.templates = [];
    items.forEach((item) => {
      const template = {};
      template.id = item.id;
      template.header = item.header;
      template.header_format = item.header_format;
      template.allow_delete = item.allow_delete;
      template.body = item.body;
      template.footer = item.footer;
      template.language = item.language;
      template.approval_status = item.approval_status;
      template.created_at_utc = item.created_at_utc;
      template.modified_at_utc = item.modified_at_utc;
      template.buttons = item.buttons;
      template.wa_template_id = item.wa_template_id;
      template.created_by_user_id = item.created_by_user_id;
      template.organization_id = item.organization_id;
      obj.templates.push(template);
    });
    return obj;
  });
  const sortedList = formattedList.sort((a, b) => {
    if (
      mapStatus(a.templates[0]?.approval_status) <
      mapStatus(b.templates[0]?.approval_status)
    )
      return -1;
    if (
      mapStatus(a.templates[0]?.approval_status) >
      mapStatus(b.templates[0]?.approval_status)
    )
      return 1;
    return (
      Number(new Date(b.created_at_utc)) - Number(new Date(a.created_at_utc))
    );
  });
  return sortedList;
};

const templateReducer = (state = initialState, action) => {
  let newList = [];
  switch (action.type) {
    case REMOVE_TEMPLATE_FLOW: {
      return {
        ...state,
        templateCreationApprovalFlows: {
          ...state.templateCreationApprovalFlows,
          [action.payload.flowName]: {},
        },
      };
    }
    case ADD_FLOW_DETAIL_TO_TEMPLATE_FLOW: {
      const prevFlowDetail =
        state.templateCreationApprovalFlows[action.payload.flowName] || {};

      return {
        ...state,
        templateCreationApprovalFlows: {
          ...state.templateCreationApprovalFlows,
          [action.payload.flowName]: { ...prevFlowDetail, ...action.payload },
        },
      };
    }
    case SYNC_TEMPLATE:
      return {
        ...state,
        isSyncing: action.isSyncing,
      };
    case EXPAND_COLLAPSE_TEMPLATE_CARD:
      return {
        ...state,
        templateCardExpandId: action.templateId,
      };
    case SAVE_SAMPLE_TEMPLATE:
      return {
        ...state,
        sampleTemplateList: action.payload,
      };
    case FETCH_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        isFetchingTemplateList: action.isFetchingTemplateList,
        isFetchingInitialTemplateList: action.isFetchingInitialTemplateList,
      };

    case FETCH_ACTIVE_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        isFetchingActiveTemplateList: action.isFetchingActiveTemplateList,
        isFetchingInitialActiveTemplateList:
          action.isFetchingInitialActiveTemplateList,
      };

    case FETCH_DELETED_TEMPLATE_LIST_STATUS:
      return {
        ...state,
        isFetchingDeletedTemplateList: action.isFetchingDeletedTemplateList,
        isFetchingInitialDeletedTemplateList:
          action.isFetchingInitialDeletedTemplateList,
      };

    case FETCH_TEMPLATE_LIST:
      newList = groupList([...action.templateList]);
      return {
        ...state,
        templateList:
          action.pageNum === 1 ? newList : [...state.templateList, ...newList],
        originalTemplateList:
          action.pageNum === 1
            ? action.templateList
            : [...state.originalTemplateList, ...action.templateList],
        pageNum: action.pageNum,
        pageSize: action.pageSize,
        isTemplateListLoaded: true,
        groupListCount:
          action.pageNum === 1
            ? newList.length
            : [...state.templateList, ...newList].length,
        totalCount: action.totalCount,
        isFetchingTemplateList: action.isFetchingTemplateList,
        isFetchingInitialTemplateList: action.isFetchingInitialTemplateList,
      };

    case FETCH_ACTIVE_TEMPLATE_LIST_V2:
      newList = groupList(action.activeTemplateListV2);
      return {
        ...state,
        activeTemplateListV2: newList,
        activeOriginalTemplateListV2: action.activeTemplateListV2,
        activeTotalCount: newList.length,
        isFetchingActiveTemplateListV2: action.isFetchingActiveTemplateListV2,
      };

    case FETCH_DELETED_TEMPLATE_LIST:
      newList = groupList([...action.deletedTemplateList]);
      return {
        ...state,
        deletedTemplateList: newList,
        deletedOriginalTemplateList: action.deletedTemplateList,
        pageNum: action.pageNum,
        pageSize: action.pageSize,
        deletedTotalCount: newList.length,
        isFetchingDeletedTemplateList: action.isFetchingDeletedTemplateList,
        isFetchingInitialDeletedTemplateList:
          action.isFetchingInitialDeletedTemplateList,
      };

    case FETCH_TEMPLATE_STATUS:
      return {
        ...state,
        isFetchingTemplate: action.isFetchingTemplate,
      };

    case FETCH_TEMPLATE:
      return {
        ...state,
        activeTemplate: action.activeTemplate,
        isFetchingTemplate: action.isFetchingTemplate,
      };

    case CLEAR_ACTIVE_TEMPLATE:
      return {
        ...state,
        activeTemplate: {},
      };

    case CREATE_TEMPLATE_STATUS:
      return {
        ...state,
        isCreatingTemplate: action.isCreatingTemplate,
      };

    case CREATE_TEMPLATE:
      return {
        ...state,
        isCreatingTemplate: action.isCreatingTemplate,
        templateList: [action.template, ...state.templateList],
      };

    case UPDATE_TEMPLATE_LIST:
      newList = groupList(action.templateList);
      return {
        ...state,
        templateList: newList,
      };

    case UPDATE_ACTIVE_TEMPLATE_LIST:
      newList = groupList(action.activeTemplateListV2);
      return {
        ...state,
        activeTemplateListV2: newList,
      };

    case UPDATE_DELETED_TEMPLATE_LIST:
      newList = groupList(action.deletedTemplateList);
      return {
        ...state,
        deletedTemplateList: newList,
      };

    case UNARCHIVING_TEMPLATE_DEATILS:
      return {
        ...state,
        isUnarchivingTemplateDetail: action.isUnarchivingTemplateDetail,
      };

    case ARCHIVING_TEMPLATE_DEATILS:
      return {
        ...state,
        isArchivingTemplateDetail: action.isArchivingTemplateDetail,
      };

    case TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL:
      return {
        ...state,
        isTemplateSampleModalVisible: action.isTemplateSampleModalVisible,
        isTemplateSampleModalConfirmationVisible:
          action.isTemplateSampleModalConfirmationVisible,
      };

    case UNARCHIVE_TEMPLATE_DEATILS:
      return {
        ...state,
        isUnarchivingTemplateDetail: action.isUnarchivingTemplateDetail,
        isUnarchiveTemplateDetail: action.isUnarchiveTemplateDetail,
      };

    case ARCHIVE_TEMPLATE_DEATILS:
      return {
        ...state,
        isArchivingTemplateDetail: action.isArchivingTemplateDetail,
        isArchiveTemplateDetail: action.isArchiveTemplateDetail,
      };

    case UPDATING_TEMPLATE_DETAIL:
      return {
        ...state,
        isUpdatingTemplateDetail: action.isUpdatingTemplateDetail,
      };

    case UPDATE_TEMPLATE_DETAIL:
      return {
        ...state,
        isUpdatingTemplateDetail: action.isUpdatingTemplateDetail,
        activeTemplate: state.activeTemplate,
      };

    case TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION:
      return {
        ...state,
        isTemplateSampleModalConfirmationVisible:
          action.isTemplateSampleModalConfirmationVisible,
      };

    case UPDATE_ORG_TEMPLATES: {
      let isOldTemplate = false;
      let oldTemplateList = state.activeTemplateListV2;
      if (action.event && oldTemplateList) {
        oldTemplateList = oldTemplateList.map((item) => {
          if (item.name === action.event?.name) {
            item.templates = item.templates.map((template) => {
              if (template.id === action.event?.id) {
                isOldTemplate = true;
                return action.event;
              }
              return template;
            });
          }
          return item;
        });
        if (!isOldTemplate) {
          const obj = {};
          obj.id = action.event?.id;
          obj.name = action.event?.name;
          obj.display_name = action.event?.display_name;
          obj.category = action.event?.category;
          obj.modified_at_utc = action.event?.modified_at_utc;
          obj.created_by_user = action.event?.created_by_user
            ? action.event?.created_by_user
            : {};
          obj.created_at_utc = action.event?.created_at_utc
            ? action.event?.created_at_utc
            : "";
          obj.templates = [];
          obj.templates.push({ ...action.event });
          oldTemplateList.unshift(obj);
        }
      }
      const getActiveOriginalTemplateListV2 = () => {
        if (typeof action?.event !== "object") {
          return [...state.activeOriginalTemplateListV2];
        }
        const objIndex = state.activeOriginalTemplateListV2.findIndex(
          (item) => item.id === action?.event?.id,
        );
        // Condition when object is already there
        if (objIndex !== -1) {
          return state.activeOriginalTemplateListV2.map((item) => {
            if (item.id === action.event?.id) {
              return { ...action.event };
            }
            return { ...item };
          });
        }
        return [{ ...action.event }, ...state.activeOriginalTemplateListV2];
      };
      return {
        ...state,
        activeOriginalTemplateListV2: getActiveOriginalTemplateListV2(),
        activeTemplateListV2: oldTemplateList,
        groupListCount: oldTemplateList.length,
      };
    }

    case UPDATE_TEMPLATE_BUTTONS_ORDER: {
      const templateButtonOrderCopy = state.templateButtonsOrder.map(
        (buttonsOrder, index) => {
          if (index === action.payload.index) {
            return action.payload.data;
          }

          return buttonsOrder;
        },
      );

      return {
        ...state,
        templateButtonsOrder: templateButtonOrderCopy,
      };
    }

    case UPDATE_COUPON_CODE: {
      const templateButtonsCopy = state.templateButtons.map((button, index) => {
        if (index === action.payload.index) {
          return {
            ...state.templateButtons[index],
            couponCode: action.payload.data,
          };
        }

        return button;
      });

      return {
        ...state,
        templateButtons: templateButtonsCopy,
      };
    }

    case UPDATE_TEMPLATE_QUICK_REPLIES: {
      const templateButtonsCopy = state.templateButtons.map((button, index) => {
        if (index === action.payload.index) {
          return {
            ...state.templateButtons[index],
            quickReplies: action.payload.data,
          };
        }

        return button;
      });

      return {
        ...state,
        templateButtons: templateButtonsCopy,
      };
    }

    case UPDATE_TEMPLATE_PHONE_NUMBER: {
      const templateButtonsCopy = state.templateButtons.map((button, index) => {
        if (index === action.payload.index) {
          return {
            ...state.templateButtons[index],
            phoneNumber: action.payload.data,
          };
        }

        return button;
      });

      return {
        ...state,
        templateButtons: templateButtonsCopy,
      };
    }

    case UPDATE_TEMPLATE_WEBSITE_URL: {
      const templateButtonsCopy = state.templateButtons.map((button, index) => {
        if (index === action.payload.index) {
          return {
            ...state.templateButtons[index],
            websiteUrl: action.payload.data,
          };
        }

        return button;
      });

      return {
        ...state,
        templateButtons: templateButtonsCopy,
      };
    }

    case LOAD_INITIAL_TEMPLATE_BUTTONS:
      return {
        ...state,
        templateButtons: action.payload.formattedButtons,
        templateButtonsOrder: action.payload.buttonsOrder,
        templateButtonsSectionType: action.payload.sectionTypes?.[0],
        templateLTO: action.payload.ltoData,
        templateButtonsAllSectionType: action.payload.sectionTypes,
        templateSendProductButtonType: action.payload.sendProductButtonType,
      };

    case ADD_NEW_TEMPLATE_BUTTONS_SECTION:
      return {
        ...state,
        templateButtons: [...state.templateButtons, getInitButtons()],
        templateButtonsOrder: [
          ...state.templateButtonsOrder,
          getInitButtonsOrder(),
        ],
      };

    case ADD_NEW_TEMPLATE_LTO_SECTION:
      return {
        ...state,
        templateLTO: [...state.templateLTO, getInitLTO()],
      };

    case RESET_TEMPLATE_BUTTONS:
      return {
        ...state,
        templateButtons: [],
        templateButtonsOrder: [],
        templateButtonsSectionType: OPTION_NONE,
      };

    case RESET_TEMPLATE_LTO:
      return {
        ...state,
        templateLTO: [],
      };

    case RESET_TEMPLATE_BUTTONS_BY_TEMPLATE_INDEX: {
      const templateButtonsCopy = state.templateButtons.map(
        (buttons, index) => {
          if (index === action.payload) {
            return getInitButtons();
          }
          return buttons;
        },
      );
      const templateButtonsOrderCopy = state.templateButtonsOrder.map(
        (buttonsOrder, index) => {
          if (index === action.payload) {
            return getInitButtonsOrder();
          }
          return buttonsOrder;
        },
      );
      return {
        ...state,
        templateButtons: templateButtonsCopy,
        templateButtonsOrder: templateButtonsOrderCopy,
      };
    }

    case RESET_TEMPLATE_LTO_BY_TEMPLATE_INDEX: {
      const templateLTOCopy = state.templateLTO.map((lto, index) => {
        if (index === action.payload) {
          return getInitLTO();
        }
        return lto;
      });
      return {
        ...state,
        templateLTO: templateLTOCopy,
      };
    }

    case UPDATE_BUTTON_SECTION_TYPE: {
      return {
        ...state,
        templateButtonsSectionType: action.payload,
      };
    }

    case UPDATE_LTO_TEXT: {
      const templateLTOCopy = state.templateLTO.map((ltoData, index) => {
        if (index === action.payload.index) {
          return { ltoText: action.payload.data };
        }

        return ltoData;
      });

      return {
        ...state,
        templateLTO: templateLTOCopy,
      };
    }

    case UPDATE_SEND_PRODUCT_BUTTON_TYPE: {
      return {
        ...state,
        templateSendProductButtonType: action.payload,
      };
    }

    case TEMPLATE_CAROUSEL_INIT_ITEM: {
      return {
        ...state,
        templateCarousels: [[{ id: uuidv4() }]],
      };
    }

    case TEMPLATE_CAROUSEL_ADD_NEW_ITEM: {
      let templateCarouselsCopy;

      if (state.templateCarousels?.[action.payload]) {
        templateCarouselsCopy = state.templateCarousels?.map((item, index) => {
          if (index === action.payload) {
            return [...item, { id: uuidv4() }];
          }

          return item;
        });
      } else {
        templateCarouselsCopy = [...state.templateCarousels];

        templateCarouselsCopy.push([{ id: uuidv4() }]);
      }

      return {
        ...state,
        templateCarousels: templateCarouselsCopy,
      };
    }

    case TEMPLATE_CAROUSEL_DELETE_ITEM: {
      const templateCarouselsCopy = state.templateCarousels?.map(
        (item, index) => {
          if (index === action.payload.activeTemplateIndex) {
            return item.filter((carousel, activeCarouselIndex) => {
              return activeCarouselIndex !== action.payload.activeCarouselIndex;
            });
          }

          return item;
        },
      );

      return {
        ...state,
        templateCarousels: templateCarouselsCopy,
      };
    }

    case TEMPLATE_CAROUSEL_UPDATE_ITEM: {
      const templateCarouselsCopy = state.templateCarousels?.map(
        (item, index) => {
          if (index === action.payload.activeTemplateIndex) {
            return item.map((carousel, activeCarouselIndex) => {
              if (activeCarouselIndex === action.payload.activeCarouselIndex) {
                return { ...carousel, ...action.payload.data };
              }

              return carousel;
            });
          }

          return item;
        },
      );

      return {
        ...state,
        templateCarousels: templateCarouselsCopy,
      };
    }

    case TEMPLATE_CAROUSEL_DELETE_BUTTON: {
      const updatedCarousels = getUpdatedCarouselButtons({
        templateCarousels: state.templateCarousels,
        activeTemplateIndex: action.payload.activeTemplateIndex,
        activeCarouselIndex: action.payload.activeCarouselIndex,
        manipulateButtonsFn: (carousel) =>
          carouselDeleteButton(carousel, action.payload.buttonId),
      });

      return {
        ...state,
        templateCarousels: updatedCarousels,
      };
    }

    case TEMPLATE_CAROUSEL_UPDATE_BUTTON: {
      const updatedCarousels = getUpdatedCarouselButtons({
        templateCarousels: state.templateCarousels,
        activeTemplateIndex: action.payload.activeTemplateIndex,
        activeCarouselIndex: action.payload.activeCarouselIndex,
        manipulateButtonsFn: (carousel) =>
          carouselUpdateButton(
            carousel,
            action.payload.buttonId,
            action.payload.updatedButtonData,
          ),
      });

      return {
        ...state,
        templateCarousels: updatedCarousels,
      };
    }

    case UPDATE_TEMPLATE_CAROUSEL_FOR_VIEW: {
      return {
        ...state,
        templateCarousels: [
          ...state.templateCarousels,
          getCarouselDataFromAPIParams(action.payload.data),
        ],
      };
    }

    case RESET_TEMPLATE_CAROUSEL:
      return {
        ...state,
        templateCarousels: [],
      };

    case INIT_TEMPLATE_CAROUSEL_FALLBACK: {
      const updatedTemplateCarouselFallback = action.payload.map((carousel) => {
        return {
          body: getFallbackList(carousel.bodyVariables, "Body Variable"),
          buttons: getFallbackList(
            carousel.buttonsVariables,
            "Button Variable",
          ),
          buttonsIndex: carousel.buttonsVariableIndex,
          header: carousel.header,
        };
      });

      return {
        ...state,
        templateCarouselFallBacks: updatedTemplateCarouselFallback,
      };
    }

    case UPDATE_CAROUSEL_FALLBACK_BODY: {
      const updatedTemplateCarouselFallback = state.templateCarouselFallBacks.map(
        (carousel, carouselIndex) => {
          if (carouselIndex === action.payload.cardIndex) {
            return {
              ...carousel,
              body: action.payload.data,
            };
          }

          return carousel;
        },
      );

      return {
        ...state,
        templateCarouselFallBacks: updatedTemplateCarouselFallback,
      };
    }

    case ADD_CAROUSEL_CARDS_FOR_TEMPLATE_SELECTION: {
      const formattedCarousal = isEmpty(action.payload)
        ? []
        : getCarouselDataFromAPIParams(action.payload);

      return {
        ...state,
        templateCarouselCardsTemplateSelection: formattedCarousal,
      };
    }

    case UPDATE_CAROUSEL_FALLBACK_BUTTONS: {
      const updatedTemplateCarouselFallback = state.templateCarouselFallBacks.map(
        (carousel, carouselIndex) => {
          if (carouselIndex === action.payload.cardIndex) {
            const updatedButtons = carousel.buttons.map(
              (button, buttonIndex) => {
                if (buttonIndex === action.payload.buttonIndex) {
                  return {
                    ...button,
                    ...action.payload.data,
                  };
                }

                return button;
              },
            );

            return {
              ...carousel,
              buttons: updatedButtons,
            };
          }

          return carousel;
        },
      );

      return {
        ...state,
        templateCarouselFallBacks: updatedTemplateCarouselFallback,
      };
    }

    case UPDATE_CAROUSEL_FALLBACK_HEADER: {
      const updatedTemplateCarouselFallback = state.templateCarouselFallBacks.map(
        (carousel, carouselIndex) => {
          if (carouselIndex === action.payload.cardIndex) {
            return {
              ...carousel,
              header: action.payload.data,
            };
          }

          return carousel;
        },
      );

      return {
        ...state,
        templateCarouselFallBacks: updatedTemplateCarouselFallback,
      };
    }

    case ADD_NEW_TEMPLATE_CAROUSEL_SECTION:
      return {
        ...state,
        templateCarousels: [...state.templateCarousels, [{ id: uuidv4() }]],
      };

    case LOADING_CAROUSEL_CARDS_FOR_TEMPLATE:
      return {
        ...state,
        isLoadingCarouselsForTemplate: action.payload,
      };

    case ADD_NEW_AUTH_TEMPLATE_SECTION:
      return {
        ...state,
        authTemplateList: [
          ...state.authTemplateList,
          {
            id: uuidv4(),
            codeDeliveryType: CODE_DELIVERY_ZERO_TAP,
            zeroTapCheckbox: false,
            packageList: [{ id: uuidv4(), name: "", signature: "" }],
            addSecurityRecommendation: true,
            addExpiryTime: false,
            expiryTime: 10,
            autofillText: "Autofill",
            copyCodeText: "Copy code",
            validityStatus: true,
            validityPeriod: "10",
          },
        ],
      };

    case UPDATE_CODE_DELIVERY_TYPE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              codeDeliveryType: action.payload.codeDeliveryType,
            };
          }

          return item;
        }),
      };

    case UPDATE_ZERO_TAP_CHECKBOX:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              zeroTapCheckbox: action.payload.flag,
            };
          }

          return item;
        }),
      };

    case ADD_NEW_PACKAGE_NAME_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload) {
            return {
              ...item,
              packageList: [
                ...state.authTemplateList[action.payload].packageList,
                { id: uuidv4(), name: "", signature: "" },
              ],
            };
          }

          return item;
        }),
      };

    case UPDATE_PACKAGE_LIST_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              packageList: action.payload.packageList,
            };
          }

          return item;
        }),
      };

    case UPDATE_SECURITY_RECOMMENDATION_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              addSecurityRecommendation: action.payload.flag,
            };
          }

          return item;
        }),
      };

    case UPDATE_EXPIRY_TIME_STATUS_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              addExpiryTime: action.payload.flag,
            };
          }

          return item;
        }),
      };

    case UPDATE_EXPIRY_TIME_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              expiryTime: action.payload.value,
            };
          }

          return item;
        }),
      };

    case UPDATE_AUTOFILL_TEXT_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              autofillText: action.payload.value,
            };
          }

          return item;
        }),
      };

    case UPDATE_COPY_CODE_TEXT_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              copyCodeText: action.payload.value,
            };
          }

          return item;
        }),
      };

    case UPDATE_VALIDITY_STATUS_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              validityStatus: action.payload.value,
            };
          }

          return item;
        }),
      };

    case UPDATE_VALIDITY_PERIOD_IN_AUTH_TEMPLATE:
      return {
        ...state,
        authTemplateList: state.authTemplateList.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              validityPeriod: action.payload.value,
            };
          }

          return item;
        }),
      };

    case TOGGLE_CHANNEL_SELECTION_MODAL:
      return {
        ...state,
        isChannelSelectionModalOpen: action.payload,
      };
    case SET_FLOW_BUTTON_TEXT:
      return {
        ...state,
        flowButtonText: action.payload,
      };

    case SET_FLOW_ID:
      return {
        ...state,
        flowId: action.payload,
      };

    case SET_SCREEN_ID:
      return {
        ...state,
        screenId: action.payload,
      };

    case SET_NAVIGATE_CHECKED:
      return {
        ...state,
        isNavigateChecked: action.payload,
      };

    case SET_FLOW_TOKEN:
      return {
        ...state,
        flowToken: action.payload,
      };

    case SET_FLOW_DATA:
      return {
        ...state,
        flowData: action.payload,
      };

    case UPDATE_AUTH_TEMPLATE_FOR_VIEW:
      return {
        ...state,
        authTemplateList: [...state.authTemplateList, action.payload],
      };

    case RESET_AUTH_TEMPLATE_STATE:
      return {
        ...state,
        authTemplateList: [],
      };

    default:
      return {
        ...state,
      };
  }
};

export default templateReducer;

export const getTemplateApprovalFlow = (state, flowName) =>
  state.template.templateCreationApprovalFlows[flowName] || {};
export const getTemplateList = (state) => state.template.templateList;
export const getActiveTemplateList = (state) =>
  state.template.activeTemplateListV2;
export const getDeletedTemplateList = (state) =>
  state.template.deletedTemplateList;
export const getApprovedTemplateList = (state) =>
  state.template.activeOriginalTemplateList &&
  state.template.activeOriginalTemplateList.length > 0
    ? state.template.activeOriginalTemplateList.filter(
        (item) => item.approval_status === "APPROVED",
      )
    : [];
export const getApprovedTemplateListV2 = (state) =>
  state.template.activeOriginalTemplateListV2 &&
  state.template.activeOriginalTemplateListV2.length > 0
    ? state.template.activeOriginalTemplateListV2.filter(
        (item) => item.approval_status === "APPROVED",
      )
    : [];
export const getTemplate = (state, templateId) =>
  state.template.activeOriginalTemplateListV2.find(
    ({ id }) => templateId === id,
  );
export const getTemplatePageNum = (state) => state.template.pageNum;
export const getTemplatePageSize = (state) => state.template.pageSize;
export const getTemplateTotalCount = (state) => state.template.totalCount;
export const getTemplateGroupCount = (state) => state.template.groupListCount;
export const getActiveTemplateTotalCount = (state) =>
  state.template.activeTotalCount;
export const getDeletedTemplateTotalCount = (state) =>
  state.template.deletedTotalCount;
export const getCreateTemplateStatus = (state) =>
  state.template.isCreatingTemplate;
export const getTemplateListFetchStatus = (state) =>
  state.template.isFetchingTemplateList;
export const getActiveTemplateListFetchStatus = (state) =>
  state.template.isFetchingActiveTemplateList;
export const getDeletedTemplateListFetchStatus = (state) =>
  state.template.isFetchingDeletedTemplateList;
export const getInitialTemplateListFetchStatus = (state) =>
  state.template.isFetchingInitialTemplateList;
export const getInitialActiveTemplateListFetchStatus = (state) =>
  state.template.isFetchingActiveTemplateListV2;
export const getInitialDeletedTemplateListFetchStatus = (state) =>
  state.template.isFetchingInitialDeletedTemplateList;
export const getTemplateFetchStatus = (state) =>
  state.template.isFetchingTemplate;
export const getPageNUm = (state) => state.template.pageNum;
export const getPageSize = (state) => state.template.pageSize;
export const getTemplateCount = (state) => state.template.templateList.length;
export const getTotalTemplateCount = (state) => state.template.totalCount;
export const getActiveTemplate = (state) => state.template.activeTemplate;
export const getOriginalTemplateList = (state) =>
  state.template.originalTemplateList;
export const getOriginalActiveTemplateList = (state) =>
  state.template.activeOriginalTemplateListV2;
export const getOriginalDeletedTemplateList = (state) =>
  state.template.deletedOriginalTemplateList;
export const getIsTemplateListLoaded = (state) =>
  state.template.isTemplateListLoaded;
export const getArchivingTemplateFetchStatus = (state) =>
  state.template.isArchivingTemplateDetail;
export const getUnarchivingTemplateFetchStatus = (state) =>
  state.template.isUnarchivingTemplateDetail;
export const getArchiveTemplateFetchStatus = (state) =>
  state.template.isArchiveTemplateDetail;
export const getUnarchiveTemplateFetchStatus = (state) =>
  state.template.isUnarchiveTemplateDetail;
export const getUpdatingTemplateDetailsStatus = (state) =>
  state.template.isUpdatingTemplateDetail;
export const getSyncingTemplateDetailsStatus = (state) =>
  state.template.isSyncing;
export const getTemplateSampleModalState = (state) =>
  state.template.isTemplateSampleModalVisible;
export const getTemplateSampleConfirmationModalState = (state) =>
  state.template.isTemplateSampleModalConfirmationVisible;
export const getExpandTemplateCardId = (state) =>
  state.template.templateCardExpandId;
export const getSampleTemplateList = (state) =>
  state.template.sampleTemplateList;
// selectors

export const getAllTemplateButton = (state) => state.template.templateButtons;
export const getAllButtonOrder = (state) => state.template.templateButtonsOrder;
export const getAllLTOData = (state) => state.template.templateLTO;

export const getTemplateButtons = (state, index) =>
  state.template.templateButtons[index];
export const getTemplateButtonsOrder = (state, index) =>
  state.template.templateButtonsOrder[index];
export const getCouponCode = (state, index) =>
  state.template.templateButtons?.[index]?.couponCode;

export const getTemplateQuickReplies = (state, index) =>
  state.template.templateButtons?.[index]?.quickReplies;

export const getTemplatePhoneNumber = (state, index) =>
  state.template.templateButtons?.[index]?.phoneNumber;

export const getTemplateWebsiteUrl = (state, index) =>
  state.template.templateButtons?.[index]?.websiteUrl;

export const getTemplateButtonsSectionType = (state) =>
  state.template.templateButtonsSectionType;

export const getTemplateSendProductButtonType = (state) =>
  state.template.templateSendProductButtonType;

export const getTemplateButtonsAllSectionType = (state) =>
  state.template.templateButtonsAllSectionType;

export const getLTOText = (state, index) =>
  state.template.templateLTO[index]?.ltoText;

export const getCarouselTemplateCount = (state, index) =>
  state.template.templateCarousels?.[index]?.length;

export const getAllCarousels = (state) => state.template.templateCarousels;

export const getCarouselsFromIndex = (state, index) =>
  state.template.templateCarousels?.[index];

export const getCarouselImage = ({
  state,
  activeTemplateIndex,
  activeCarouselIndex,
}) =>
  state.template.templateCarousels?.[activeTemplateIndex]?.[activeCarouselIndex]
    ?.image;

export const getCarouselText = ({
  state,
  activeTemplateIndex,
  activeCarouselIndex,
}) =>
  state.template.templateCarousels?.[activeTemplateIndex]?.[activeCarouselIndex]
    ?.text;

export const getCarouselTitle = ({
  state,
  activeTemplateIndex,
  activeCarouselIndex,
}) =>
  state.template.templateCarousels?.[activeTemplateIndex]?.[activeCarouselIndex]
    ?.cardTitle;

export const getCarouselButtons = ({
  state,
  activeTemplateIndex,
  activeCarouselIndex,
}) =>
  state.template.templateCarousels?.[activeTemplateIndex]?.[activeCarouselIndex]
    ?.buttons;

export const getTemplateCarouselFallBacks = (state) =>
  state.template.templateCarouselFallBacks;

export const getTemplateCarouselCardsTemplateSelection = (state) =>
  state.template.templateCarouselCardsTemplateSelection;

export const getIsLoadingCarouselsForTemplate = (state) =>
  state.template.isLoadingCarouselsForTemplate;

export const getAuthTemplateList = (state) => state.template.authTemplateList;

export const getSelectedAuthTemplate = (state, index) =>
  state.template.authTemplateList?.[index];

export const getSelectedAuthTemplateCodeDeliveryType = (state, index) =>
  state.template.authTemplateList?.[index]?.codeDeliveryType;

export const getSelectedAuthTemplateZeroTapCheckbox = (state, index) =>
  state.template.authTemplateList?.[index]?.zeroTapCheckbox;

export const getSelectedAuthTemplatePackageList = (state, index) =>
  state.template.authTemplateList?.[index]?.packageList;

export const getSelectedAuthTemplateSecurityRecommendationStatus = (
  state,
  index,
) => state.template.authTemplateList?.[index]?.addSecurityRecommendation;

export const getSelectedAuthTemplateExpiryTimeStatus = (state, index) =>
  state.template.authTemplateList?.[index]?.addExpiryTime;

export const getSelectedAuthTemplateExpiryTime = (state, index) =>
  state.template.authTemplateList?.[index]?.expiryTime;

export const getSelectedAuthTemplateAutoFillText = (state, index) =>
  state.template.authTemplateList?.[index]?.autofillText;

export const getSelectedAuthTemplateCopyCodeText = (state, index) =>
  state.template.authTemplateList?.[index]?.copyCodeText;

export const getSelectedAuthTemplateValidityStatus = (state, index) =>
  state.template.authTemplateList?.[index]?.validityStatus;

export const getSelectedAuthTemplateValidityPeriod = (state, index) =>
  state.template.authTemplateList?.[index]?.validityPeriod;

export const getChannelSelectionModalState = (state) =>
  state.template.isChannelSelectionModalOpen;
export const getFlowButtonText = (state) => state.template.flowButtonText;
export const getFlowId = (state) => state.template.flowId;
export const getScreenId = (state) => state.template.screenId;
export const getIsNavigateChecked = (state) => state.template.isNavigateChecked;
export const getFlowToken = (state) => state.template.flowToken;
export const getFlowData = (state) => state.template.flowData;
