import { getSelectedOrganizationId } from "modules/App/AppSelectors";
import { callApiV2 } from "util/apiCaller";
import { showNotification } from "util/utility";
import { isLimitExceedForAppIntegration } from "util/integrationUtil";
import { getActiveSubscriptionList } from "modules/Payments/PaymentSelectors";
import {
  WHATSAPP_PAY,
  WHATSAPP_PAY_APP_ID,
} from "modules/BotFlows/constants/Templates/AutoCheckout";
import { isPaymentRelatedApp } from "./AppListData";
import { getAppsData } from "./integrationSelectors";
import {
  SET_LIMIT_EXCEEDED_FOR_APP_INTEGRATION,
  SET_APPS_DATA,
  SET_NEXT_APP_MODAL,
  UPDATE_WHATSAPP_PAY_STATUS,
  SET_APP_DETAILS_MODAL,
} from "./integrationTypes";

export const setNextAppModal = (bool, selectedApp) => (dispatch) => {
  return dispatch({
    type: SET_NEXT_APP_MODAL,
    payload: {
      selectedApp: { ...selectedApp },
      showNextModal: bool,
    },
  });
};

export const setAppDetailsModal = (bool, selectedApp) => (dispatch) => {
  return dispatch({
    type: SET_APP_DETAILS_MODAL,
    payload: {
      selectedApp: { ...selectedApp },
      showAppDetailsModal: bool,
    },
  });
};

export const fetchAppsInfoData = (hideLoader) => async (dispatch, getState) => {
  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get("status");
  const app_id = searchParams.get("app_id");
  const appsData = [...getAppsData(getState())];
  const orgId = getSelectedOrganizationId(getState());
  const subscriptionList = getActiveSubscriptionList(getState());
  if (appsData?.find((app) => app.isFetchingDetails)) {
    return;
  }
  dispatch({
    type: SET_APPS_DATA,
    payload: appsData.map((app) => {
      if (hideLoader) return app;
      return {
        ...app,
        isFetchingDetails: true,
      };
    }),
  });
  try {
    const res = await callApiV2({
      endpoint: `v1/platform_integrations/apps/apps-info/${orgId}/`,
      method: "get",
    });
    if (res?.result) {
      const appResults = res?.data;
      const disable = appResults?.find(
        (app) => isPaymentRelatedApp(app.name) && app.connected,
      );
      const disableForLimitExceed = isLimitExceedForAppIntegration(
        appResults,
        subscriptionList,
      );
      dispatch({
        type: SET_LIMIT_EXCEEDED_FOR_APP_INTEGRATION,
        payload: disableForLimitExceed,
      });
      appResults?.forEach((appResult) => {
        const appData = appsData?.find((app) => app.id === appResult.app_id);
        if (!appData) {
          return;
        }
        appData.id = appResult.app_id;
        appData.connected = appResult.connected;
        if (
          ((disable && isPaymentRelatedApp(appData?.appName)) ||
            disableForLimitExceed) &&
          !appData.connected
        ) {
          appData.disabled = true;
        } else {
          appData.disabled = false;
        }
        if (appResult?.connected) {
          appData.inprogress = false;
          appData.app_meta = appResult.app_meta;
        } else if (appData.appName === WHATSAPP_PAY) {
          appData.app_meta = appResult.app_meta;
          appData.handleClick = () => {
            dispatch(setNextAppModal(true, appData));
          };
        } else {
          appData.handleClick = () => {
            window.location.href = appResult.third_party_url;
          };
        }
        appData.isFetchingDetails = false;
      });
      if (status === "inprogress") {
        const curPayObject = appsData?.find((app) => app.id === app_id);
        if (curPayObject && !curPayObject?.connected) {
          curPayObject.inprogress = true;
        }
      }
      if (status === "success") {
        const curPayObject = appsData?.find((app) => app.id === app_id);
        if (curPayObject) {
          dispatch(setNextAppModal(true, curPayObject));
        }
      }
      dispatch({
        type: SET_APPS_DATA,
        payload: [...appsData],
      });
    } else {
      dispatch({
        type: SET_APPS_DATA,
        payload: appsData.map((app) => {
          return {
            ...app,
            isFetchingDetails: false,
          };
        }),
      });
    }
  } catch (err) {
    showNotification(
      "error",
      "Some error occured while fetching integrated apps. Please try again in some time.",
    );
    dispatch({
      type: SET_APPS_DATA,
      payload: appsData.map((app) => {
        return {
          ...app,
          isFetchingDetails: false,
        };
      }),
    });
  }
};

export const setWhatsAppPayData = (data) => async (dispatch, getState) => {
  const appsData = [...getAppsData(getState())];
  dispatch({
    type: SET_APPS_DATA,
    payload: appsData.map((app) => {
      if (app.id === WHATSAPP_PAY_APP_ID) {
        return {
          ...app,
          app_meta: data.attributes || data.app_meta,
          connected: data.active || data.connected,
        };
      }
      return app;
    }),
  });
};

export const setWhatsAppPayInprogressState = (data) => async (
  dispatch,
  getState,
) => {
  const appsData = [...getAppsData(getState())];
  dispatch({
    type: SET_APPS_DATA,
    payload: appsData.map((app) => {
      if (app.id === WHATSAPP_PAY_APP_ID) {
        return {
          ...app,
          inprogress: data.inProgress,
        };
      }
      return app;
    }),
  });
};

export const fetchAndSetAppsData = (data) => (dispatch) => {
  if (data?.extra?.status === "success") {
    showNotification("success", `${data?.extra?.app} successfully connected!`);
  } else if (data?.extra?.status === "error") {
    showNotification("error", data.extra.error_description);
  }
  dispatch(fetchAppsInfoData(true));
};

export const updateWhatsAppPayStatus = (status) => ({
  type: UPDATE_WHATSAPP_PAY_STATUS,
  payload: status,
});
