import { CHANNEL_TYPE_RCS } from "components/ChannelTypeDropdown/constants";

type QueryParamProps = {
  isHaptikSMSChannel: boolean;
  channelParam: string;
};

export const getChannelQueryParam = ({
  isHaptikSMSChannel,
  channelParam,
}: QueryParamProps): string => {
  if (isHaptikSMSChannel) {
    return "channel_type=sms";
  }
  if (channelParam === CHANNEL_TYPE_RCS) {
    return "channel_type=rcs";
  }
  return "";
};
