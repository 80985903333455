import { useEffect } from "react";

const useFBInit = (fbToken) => {
  useEffect(() => {
    if (window?.FB) {
      window.FB.init({
        appId: fbToken,
        cookie: true,
        xfbml: true,
        version: "v16.0",
        status: true,
      });
    } else if (window) {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: fbToken,
          cookie: true,
          xfbml: true,
          version: "v16.0",
          status: true,
        });
      };
    }
  }, [fbToken]);
};

export default useFBInit;
