import { useSelector } from "react-redux";

const useGetPaymentGateway = () => {
  const paymentGateway = useSelector(
    (state) => state?.app?.organizations[0]?.organization_id?.payment_gateway,
  );

  return paymentGateway;
};

export default useGetPaymentGateway;
