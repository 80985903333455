export const SET_WORKFLOW_STEPS = "SET_WORKFLOW_STEPS";
export const SET_ACTIVE_WORKFLOW_STEP = "SET_ACTIVE_WORKFLOW_STEP";
export const SET_WORKFLOW_NAME = "SET_WORKFLOW_NAME";
export const ADD_NEW_WORKFLOW_STEP = "ADD_NEW_WORKFLOW_STEP";
export const SET_WORKFLOW_STEP = "SET_WORKFLOW_STEP";
export const REMOVE_WORKFLOW_STEP = "REMOVE_WORKFLOW_STEP";
export const TOGGLE_AD_WORKFLOW_SUCCESS_MODAL_STATUS =
  "TOGGLE_AD_WORKFLOW_SUCCESS_MODAL_STATUS";
export const TOGGLE_ADS_SELECTION_MODAL_STATUS =
  "TOGGLE_ADS_SELECTION_MODAL_STATUS";
export const TOGGLE_REMOVE_AD_LINKAGE_MODAL_STATUS =
  "TOGGLE_REMOVE_AD_LINKAGE_MODAL_STATUS";
export const SET_EXISTING_WORKFLOW_VARIABLE = "SET_EXISTING_WORKFLOW_VARIABLE";

export const setWorkflowSteps = (workflowSteps) => ({
  type: SET_WORKFLOW_STEPS,
  workflowSteps,
});

export const addNewWorkflowStep = (step) => {
  return {
    type: ADD_NEW_WORKFLOW_STEP,
    step,
  };
};

export const removeWorkflowStep = (stepId) => {
  return {
    type: REMOVE_WORKFLOW_STEP,
    stepId,
  };
};

export const setWorkflowStep = (step, override = false) => ({
  type: SET_WORKFLOW_STEP,
  step,
  override,
});

export const setActiveWorkflowStep = (activeStepId) => ({
  type: SET_ACTIVE_WORKFLOW_STEP,
  activeStepId,
});

export const setExistingWorkflowVariables = (existingWorkflowVariables) => ({
  type: SET_EXISTING_WORKFLOW_VARIABLE,
  existingWorkflowVariables,
});

export const setWorkflowName = (workflowName) => ({
  type: SET_WORKFLOW_NAME,
  workflowName,
});

export const toggleAdWorkflowSuccessModalStatus = (selectedAd) => ({
  type: TOGGLE_AD_WORKFLOW_SUCCESS_MODAL_STATUS,
  selectedAd,
});

export const toggleAdsSelectionModalStatus = (value) => ({
  type: TOGGLE_ADS_SELECTION_MODAL_STATUS,
  value,
});

export const toggleRemoveAdLinkageModalStatus = (value) => ({
  type: TOGGLE_REMOVE_AD_LINKAGE_MODAL_STATUS,
  value,
});
