import React from "react";

const addInCircle = ({ fill, ...props }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9498 2.05032C14.6833 4.78381 14.6835 9.21602 11.9498 11.9498C9.21602 14.6835 4.78381 14.6833 2.05032 11.9498C-0.683441 9.21602 -0.683441 4.78409 2.05032 2.05032C4.78409 -0.683441 9.21602 -0.683441 11.9498 2.05032ZM8.04256 8.6798C8.0427 8.3186 8.33803 8.02327 8.69895 8.02341H11.2684C11.6293 8.02327 11.9248 7.7278 11.9248 7.36702L11.9247 6.6346C11.9248 6.27368 11.6293 5.97821 11.2684 5.97834L8.69881 5.97821C8.33789 5.97807 8.04242 5.6826 8.04242 5.32182V2.75236C8.04242 2.3913 7.74681 2.09569 7.38589 2.09583H6.65388C6.29283 2.09583 5.99736 2.3913 5.99736 2.75236V5.32182C5.99736 5.6826 5.70189 5.97807 5.34097 5.97821H2.77151C2.41059 5.97834 2.11512 6.27382 2.11512 6.6346V7.36688C2.11498 7.7278 2.41045 8.02327 2.77151 8.02327L5.34083 8.02341C5.70175 8.02327 5.99722 8.31874 5.99722 8.6798V11.249C5.99736 11.6102 6.29283 11.9056 6.65375 11.9055H7.38603C7.74695 11.9056 8.04242 11.6102 8.04242 11.2491L8.04256 8.6798Z"
        fill={fill}
      />
      <mask
        id="mask0_649_1853"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9498 2.05032C14.6833 4.78381 14.6835 9.21602 11.9498 11.9498C9.21602 14.6835 4.78381 14.6833 2.05032 11.9498C-0.683441 9.21602 -0.683441 4.78409 2.05032 2.05032C4.78409 -0.683441 9.21602 -0.683441 11.9498 2.05032ZM8.04256 8.6798C8.0427 8.3186 8.33803 8.02327 8.69895 8.02341H11.2684C11.6293 8.02327 11.9248 7.7278 11.9248 7.36702L11.9247 6.6346C11.9248 6.27368 11.6293 5.97821 11.2684 5.97834L8.69881 5.97821C8.33789 5.97807 8.04242 5.6826 8.04242 5.32182V2.75236C8.04242 2.3913 7.74681 2.09569 7.38589 2.09583H6.65388C6.29283 2.09583 5.99736 2.3913 5.99736 2.75236V5.32182C5.99736 5.6826 5.70189 5.97807 5.34097 5.97821H2.77151C2.41059 5.97834 2.11512 6.27382 2.11512 6.6346V7.36688C2.11498 7.7278 2.41045 8.02327 2.77151 8.02327L5.34083 8.02341C5.70175 8.02327 5.99722 8.31874 5.99722 8.6798V11.249C5.99736 11.6102 6.29283 11.9056 6.65375 11.9055H7.38603C7.74695 11.9056 8.04242 11.6102 8.04242 11.2491L8.04256 8.6798Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_649_1853)">
        <rect width="14" height="14" />
      </g>
    </svg>
  );
};

export default addInCircle;
