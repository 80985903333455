import useIsSandboxMode from "customHooks/useIsSandboxMode";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import useShouldVanishFeatureByPaymentPlan from "customHooks/useShouldVanishFeatureByPaymentPlan";
import useShowWalletFeature from "customHooks/useShowWalletFeature";
import Box from "konnekt/Box";
import {
  getOrganizationOnboardedStatus,
  getSelectedWhatsappNumber,
} from "modules/App/AppSelectors";
import { getIsHaptikUser } from "modules/App/HaptikReducer";
import {
  ACCESS_PERMISSIONS,
  BILLING_DETAILS,
  DEVELOPER_SETTINGS,
  MANAGE_WHATSAPP_PROFILE,
  MEMBER_PROFILE,
  QUICK_REPLIES,
  TEAM_SETTINGS,
} from "modules/Payments/vanishFeatureConstant";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useIsResellerSignupSource from "customHooks/useIsResellerSignupSource";
import useActivePlan from "customHooks/useActivePlan";
import useIsAdsPlanSignupSource from "customHooks/useIsAdsPlanSignupSource";
import { ORGANIZATION_SUB_MENU_LIST } from "./constants";
import MenuItem from "./MenuItem";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 24px;
`;

const MenuList = ({ toggleSettings }) => {
  const isOrganizationOnboarded = useSelector(getOrganizationOnboardedStatus);
  const isSignupSourceTally = useIsSignupSourceTally();
  const isSandboxMode = useIsSandboxMode();
  const isHaptikUser = getIsHaptikUser();
  const showWalletFeature = useShowWalletFeature();
  const phoneNumber = useSelector(getSelectedWhatsappNumber);
  const isResellerSignupSource = useIsResellerSignupSource();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const activePlan = useActivePlan();
  const isResellerWithPlanNotStarted = !activePlan && isResellerSignupSource;
  const isAdsPlanNotStarted = isAdsPlanSignupSource && !activePlan;
  const shouldVanishManageWhatsappProfile = useShouldVanishFeatureByPaymentPlan(
    [MANAGE_WHATSAPP_PROFILE],
  );
  const shouldVanishTeamSettings = useShouldVanishFeatureByPaymentPlan([
    TEAM_SETTINGS,
  ]);
  const shouldVanishDeveloperSetting = useShouldVanishFeatureByPaymentPlan([
    DEVELOPER_SETTINGS,
  ]);
  const shouldVanishAccessPermissions = useShouldVanishFeatureByPaymentPlan([
    ACCESS_PERMISSIONS,
  ]);
  const shouldVanishQuickReplies = useShouldVanishFeatureByPaymentPlan([
    QUICK_REPLIES,
  ]);
  const shouldVanishMemberProfile = useShouldVanishFeatureByPaymentPlan([
    MEMBER_PROFILE,
  ]);
  const shouldVanishBillingDetails = useShouldVanishFeatureByPaymentPlan([
    BILLING_DETAILS,
  ]);

  const isMenuItemVisible = (item) => {
    if (item.hideForResellerWithPlanNotStarted) {
      if (isResellerWithPlanNotStarted) {
        return false;
      }
    }
    if (item.hideForAdsPlanNotStarted) {
      if (isAdsPlanNotStarted) {
        return false;
      }
    }
    if (item.isPresentAlways) {
      return true;
    }
    if (isHaptikUser) {
      return item.isHaptikUser;
    }
    if (item.hideForTally) {
      if (isSignupSourceTally) {
        return false;
      }
    }
    if (item.hideIfNumberNotConnected && !phoneNumber) {
      return false;
    }
    if (item.showManageWhatsappProfile) {
      return !shouldVanishManageWhatsappProfile;
    }
    if (item.showTeamSettings) {
      return !shouldVanishTeamSettings;
    }
    if (item.showDevelopSetting) {
      return !shouldVanishDeveloperSetting;
    }
    if (item.showAccessPermissions) {
      return !shouldVanishAccessPermissions;
    }
    if (item.showQuickReplies) {
      return !shouldVanishQuickReplies;
    }
    if (item.showMemberProfile) {
      return !shouldVanishMemberProfile;
    }
    if (item.showBillingDetails) {
      return !showWalletFeature && !shouldVanishBillingDetails;
    }
    if (isOrganizationOnboarded || isSandboxMode || isSignupSourceTally) {
      return true;
    }
    return true;
  };

  const filteredMenuList = useMemo(() => {
    return ORGANIZATION_SUB_MENU_LIST.filter((item) => isMenuItemVisible(item));
  }, [
    isHaptikUser,
    isSandboxMode,
    isOrganizationOnboarded,
    showWalletFeature,
    isSignupSourceTally,
    phoneNumber,
  ]);

  return (
    <Container>
      {filteredMenuList?.length &&
        filteredMenuList?.map((item) => {
          return (
            <MenuItem
              toggleSettings={toggleSettings}
              item={item}
              key={item.label}
            />
          );
        })}
    </Container>
  );
};

export default MenuList;
