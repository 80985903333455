// OUT OF OFFICE
export const OOO_ADD_DAY = "OOO_ADD_DAY";
export const OOO_UPDATE_DAY = "OOO_UPDATE_DAY";
export const OOO_DELETE_DAY = "OOO_DELETE_DAY";
export const OOO_UPDATE_START_TIME = "OOO_UPDATE_START_TIME";
export const OOO_UPDATE_END_TIME = "OOO_UPDATE_END_TIME";
export const OOO_UPDATE_MEDIA_URL = "OOO_UPDATE_MEDIA_URL";
export const OOO_SAVE_BTN_CLICK = "OOO_IS_SAVED_CLICK";

export const OOO_UPDATE_MESSAGE_FALLBACKVALUES =
  "OOO_UPDATE_MESSAGE_FALLBACKVALUES";

export const FETCH_OUT_OF_OFFICE_DETAILS_START =
  "FETCH_OUT_OF_OFFICE_DETAILS_START";
export const FETCH_OUT_OF_OFFICE_DETAILS_SUCCESS =
  "FETCH_OUT_OF_OFFICE_DETAILS_SUCCESS";
export const FETCH_OUT_OF_OFFICE_DETAILS_FAILURE =
  "FETCH_OUT_OF_OFFICE_DETAILS_FAILURE";

export const TOGGLE_OUT_OF_OFFICE_STATUS_START =
  "TOGGLE_OUT_OF_OFFICE_STATUS_START";
export const TOGGLE_OUT_OF_OFFICE_STATUS_SUCCESS =
  "TOGGLE_OUT_OF_OFFICE_STATUS_SUCCESS";
export const TOGGLE_OUT_OF_OFFICE_STATUS_FAILURE =
  "TOGGLE_OUT_OF_OFFICE_STATUS_FAILURE";

export const UPDATING_OUT_OF_OFFICE_TIME_START =
  "UPDATING_OUT_OF_OFFICE_TIME_START";
export const UPDATING_OUT_OF_OFFICE_TIME_SUCCESS =
  "UPDATING_OUT_OF_OFFICE_TIME_SUCCESS";
export const UPDATING_OUT_OF_OFFICE_TIME_FAILURE =
  "UPDATING_OUT_OF_OFFICE_TIME_FAILURE";

export const UPDATING_OUT_OF_OFFICE_TEXT_START =
  "UPDATING_OUT_OF_OFFICE_TEXT_START";
export const UPDATING_OUT_OF_OFFICE_TEXT_SUCCESS =
  "UPDATING_OUT_OF_OFFICE_TEXT_SUCCESS";
export const UPDATING_OUT_OF_OFFICE_TEXT_FAILURE =
  "UPDATING_OUT_OF_OFFICE_TEXT_FAILURE";

export const UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_START =
  "UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_START";
export const UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_SUCCESS =
  "UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_SUCCESS";
export const UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_FAILURE =
  "UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_FAILURE";

// Welcome Message

export const UPDATING_WELCOME_MESSAGE_TEXT = "UPDATING_WELCOME_MESSAGE_TEXT";
export const UPDATING_WELCOME_MESSAGE_TEXT_SUCCESS =
  "UPDATING_WELCOME_MESSAGE_TEXT_SUCCESS";
export const UPDATING_WELCOME_MESSAGE_TEXT_FAILURE =
  "UPDATING_WELCOME_MESSAGE_TEXT_FAILURE";

export const UPDATING_WELCOME_MESSAGE_LIST_MESSAGE_STATUS_START =
  "UPDATING_WELCOME_MESSAGE_LIST_MESSAGE_STATUS_START";
export const UPDATING_WELCOME_MESSAGE_LIST_MESSAGE_STATUS_FAILURE =
  "UPDATING_WELCOME_MESSAGE_LIST_MESSAGE_STATUS_FAILURE";

// Delayed Message

export const UPDATE_RESPONSE_TIME_FOR_DELAYED_MESSAGE =
  "UPDATE_RESPONSE_TIME_FOR_DELAYED_MESSAGE";

export const FETCH_DELAYED_MESSAGE_DETAILS = "FETCH_DELAYED_MESSAGE_DETAILS";
export const FETCH_DELAYED_MESSAGE_DETAILS_SUCCESS =
  "FETCH_DELAYED_MESSAGE_DETAILS_SUCCESS";
export const FETCH_DELAYED_MESSAGE_DETAILS_FAILURE =
  "FETCH_DELAYED_MESSAGE_DETAILS_FAILURE";

export const TOGGLE_DELAYED_MESSAGE_STATUS = "TOGGLE_DELAYED_MESSAGE_STATUS";
export const TOGGLE_DELAYED_MESSAGE_STATUS_SUCCESS =
  "TOGGLE_DELAYED_MESSAGE_STATUS_SUCCESS";
export const TOGGLE_DELAYED_MESSAGE_STATUS_FAILURE =
  "TOGGLE_DELAYED_MESSAGE_STATUS_FAILURE";

export const UPDATING_DELAYED_RESPONSE_TIME = "UPDATING_DELAYED_RESPONSE_TIME";
export const UPDATING_DELAYED_RESPONSE_TIME_SUCCESS =
  "UPDATING_DELAYED_RESPONSE_TIME_SUCCESS";
export const UPDATING_DELAYED_RESPONSE_TIME_FAILURE =
  "UPDATING_DELAYED_RESPONSE_TIME_FAILURE";

export const UPDATING_DELAYED_MESSAGE_TEXT = "UPDATING_DELAYED_MESSAGE_TEXT";
export const UPDATING_DELAYED_MESSAGE_TEXT_SUCCESS =
  "UPDATING_DELAYED_MESSAGE_TEXT_SUCCESS";
export const UPDATING_DELAYED_MESSAGE_TEXT_FAILURE =
  "UPDATING_DELAYED_MESSAGE_TEXT_FAILURE";

export const UPDATE_FALLBACK_VALUES_FOR_DELAYED_MESSAGE =
  "UPDATE_FALLBACK_VALUES_FOR_DELAYED_MESSAGE";

export const UPDATE_MEDIA_URL_FOR_DELAYED_MESSAGE =
  "UPDATE_MEDIA_URL_FOR_DELAYED_MESSAGE";

export const SAVE_BTN_CLICK_FOR_DELAYED_MESSAGE =
  "SAVE_BTN_CLICK_FOR_DELAYED_MESSAGE";

export const UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_START =
  "UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_START";
export const UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_SUCCESS =
  "UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_SUCCESS";
export const UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_FAILURE =
  "UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_FAILURE";

// FQA BOT REPLIES

export const FETCH_FAQ_BOT_REPLIES_DETAILS = "FETCH_FAQ_BOT_REPLIES_DETAILS";
export const FETCH_FAQ_BOT_REPLIES_DETAILS_SUCCESS =
  "FETCH_FAQ_BOT_REPLIES_DETAILS_SUCCESS";
export const FETCH_FAQ_BOT_REPLIES_DETAILS_FAILURE =
  "FETCH_FAQ_BOT_REPLIES_DETAILS_FAILURE";

export const DELETE_FAQ_BOT_REPLY_STATUS = "DELETE_FAQ_BOT_REPLY_STATUS";
export const DELETE_FAQ_BOT_REPLY_STATUS_SUCCESS =
  "DELETE_FAQ_BOT_REPLY_STATUS_SUCCESS";
export const DELETE_FAQ_BOT_REPLY_STATUS_FAILURE =
  "DELETE_FAQ_BOT_REPLY_STATUS_FAILURE";

export const FETCH_FAQ_BOT_REPLY_DETAILS = "FETCH_FAQ_BOT_REPLY_DETAILS";
export const FETCH_FAQ_BOT_REPLY_DETAILS_SUCCESS =
  "FETCH_FAQ_BOT_REPLY_DETAILS_SUCCESS";
export const FETCH_FAQ_BOT_REPLY_DETAILS_FAILURE =
  "FETCH_FAQ_BOT_REPLY_DETAILS_FAILURE";

export const UPDATE_FALLBACK_VALUES_FOR_FAQ_BOT_REPLY_MESSAGE =
  "UPDATE_FALLBACK_VALUES_FOR_FAQ_BOT_REPLY_MESSAGE";

export const UPDATE_FAQ_BOT_REPLY_PRODUCT_LIST =
  "UPDATE_FAQ_BOT_REPLY_PRODUCT_LIST";

export const UPDATE_MEDIA_URL_FOR_FAQ_BOT_REPLY_MESSAGE =
  "UPDATE_MEDIA_URL_FOR_FAQ_BOT_REPLY_MESSAGE";

export const SAVE_BTN_CLICK_FOR_FAQ_BOT_REPLY_MESSAGE =
  "SAVE_BTN_CLICK_FOR_FAQ_BOT_REPLY_MESSAGE";

export const UPDATE_FAQ_BOT_REPLY = "UPDATE_FAQ_BOT_REPLY";

export const UPDATE_IS_LIST_MESSAGE_ENABLE_FAQ_BOT_REPLY =
  "UPDATE_IS_LIST_MESSAGE_ENABLE_FAQ_BOT_REPLY";

export const ADD_INPUT_VARIATION_FOR_FAQ_BOT =
  "ADD_INPUT_VARIATION_FOR_FAQ_BOT";

export const UPDATE_INPUT_VARIATION_FOR_FAQ_BOT =
  "UPDATE_INPUT_VARIATION_FOR_FAQ_BOT";

export const UPDATE_FAQ_REPLIES_CURRENT_PAGE =
  "UPDATE_FAQ_REPLIES_CURRENT_PAGE";

export const ADD_BUTTON_FOR_FAQ_BOT = "ADD_BUTTON_FOR_FAQ_BOT";

export const UPDATE_BUTTON_FOR_FAQ_BOT = "UPDATE_BUTTON_FOR_FAQ_BOT";

export const UPDATE_INPUT_FOR_FAQ_BOT = "UPDATE_INPUT_FOR_FAQ_BOT";

export const ADD_FAQ_BOT_REPLY = "ADD_FAQ_BOT_REPLY";
export const ADD_FAQ_BOT_REPLY_SUCCESS = "ADD_FAQ_BOT_REPLY_SUCCESS";
export const ADD_FAQ_BOT_REPLY_FAILURE = "ADD_FAQ_BOT_REPLY_FAILURE";

export const UPDATE_FAQ_BOT_REPLY_DETAILS = "UPDATE_FAQ_BOT_REPLY_DETAILS";
export const UPDATE_FAQ_BOT_REPLY_SUCCESS = "UPDATE_FAQ_BOT_REPLY_SUCCESS";
export const UPDATE_FAQ_BOT_REPLY_FAILURE = "UPDATE_FAQ_BOT_REPLY_FAILURE";

export const TOGGLE_FAQ_BOT_STATUS = "TOGGLE_FAQ_BOT_STATUS";
export const TOGGLE_FAQ_BOT_STATUS_SUCCESS = "TOGGLE_FAQ_BOT_STATUS_SUCCESS";
export const TOGGLE_FAQ_BOT_STATUS_FAILURE = "TOGGLE_FAQ_BOT_STATUS_FAILURE";

export const UPDATE_INITIAL_FAQ_BOT_STATUS = "UPDATE_INITIAL_FAQ_BOT_STATUS";

export const UPDATE_INDEX_VALUE_FOR_FAQ_BOT = "UPDATE_INDEX_VALUE_FOR_FAQ_BOT";

export const UPDATE_VARIABLE_COUNT_FOR_FAQ_BOT =
  "UPDATE_VARIABLE_COUNT_FOR_FAQ_BOT";

export const UPDATED_FAQ_BOT_REPLIES_FILTER = "UPDATED_FAQ_BOT_REPLIES_FILTER";

export const UPDATE_FAQ_BOT_REPLIES_DETAILS = "FAQ_BOT_REPLIES_DETAILS";

export const UPDATE_SELECTED_FILTER_FOR_FAQ_BOT =
  "UPDATE_SELECTED_FILTER_FOR_FAQ_BOT";

export const UPDATE_IS_BUTTON_DISABLE_FOR_FAQ_BOT =
  "UPDATE_IS_BUTTON_DISABLE_FOR_FAQ_BOT";

export const UPDATE_INPUT_VARIATION_ERROR_FOR_FAQ_BOT =
  "UPDATE_INPUT_VARIATION_ERROR_FOR_FAQ_BOT";

export const UPDATE_INPUT_ERROR_FOR_FAQ_BOT = "UPDATE_INPUT_ERROR_FOR_FAQ_BOT";

// Interaktive list Message

export const FETCH_INTERAKTIVE_LIST_DETAILS = "FETCH_INTERAKTIVE_LIST_DETAILS";
export const FETCH_INTERAKTIVE_LIST_DETAILS_SUCCESS =
  "FETCH_INTERAKTIVE_LIST_DETAILS_SUCCESS";
export const FETCH_INTERAKTIVE_LIST_DETAILS_FAILURE =
  "FETCH_INTERAKTIVE_LIST_DETAILS_FAILURE";

export const UPDATE_OPEN_ADD_MODAL_INTERAKTIVE_LIST =
  "UPDATE_OPEN_ADD_MODAL_INTERAKTIVE_LIST";

export const UPDATE_OPEN_CREATE_MODAL_INTERAKTIVE_LIST =
  "UPDATE_OPEN_CREATE_MODAL_INTERAKTIVE_LIST";

export const UPDATE_OPEN_DELETE_MODAL_INTERAKTIVE_LIST =
  "UPDATE_OPEN_DELETE_MODAL_INTERAKTIVE_LIST";

export const INCLUDE_NEW_REPLY = "INCLUDE_NEW_REPLY";

export const UPDATE_INTERAKTIVE_LIST_NAME = "UPDATE_INTERAKTIVE_LIST_NAME";
export const UPDATE_INTERAKTIVE_LIST = "UPDATE_INTERAKTIVE_LIST";

export const SET_REPLY_ID_LIST = "SET_REPLY_ID_LIST";

export const UPDATE_INTERAKTIVE_LIST_DETAILS =
  "UPDATE_INTERAKTIVE_LIST_DETAILS";
export const UPDATE_INTERAKTIVE_LIST_DETAILS_SUCCESS =
  "UPDATE_INTERAKTIVE_LIST_DETAILS_SUCCESS";
export const UPDATE_INTERAKTIVE_LIST_DETAILS_FAILURE =
  "UPDATE_INTERAKTIVE_LIST_DETAILS_FAILURE";

export const UPDATE_IS_BUTTON_DISABLE_FOR_LIST =
  "UPDATE_IS_BUTTON_DISABLE_FOR_LIST";

export const UPDATE_ERROR_VISIBILITY = "UPDATE_ERROR_VISIBILITY";

// chat assignment
export const UPDATE_SELECTED_AGENTS = "UPDATE_SELECTED_AGENTS";
export const UPDATE_SELECTED_VALUES = "UPDATE_SELECTED_VALUES";
export const UPDATE_CHAT_ASSIGNMENT_NAME = "UPDATE_CHAT_ASSIGNMENT_NAME";
export const UPDATE_CHAT_ASSIGNMENT_TRAIT = "UPDATE_CHAT_ASSIGNMENT_TRAIT";
export const UPDATE_CHAT_ASSIGNMENT_CONDITION =
  "UPDATE_CHAT_ASSIGNMENT_CONDITION";
export const RESET_CHAT_ASSIGNMENT_VALUES = "RESET_CHAT_ASSIGNMENT_VALUES";

// Welcome message
export const UPDATE_WELCOME_MESSAGE_REDUCER = "UPDATE_WELCOME_MESSAGE_REDUCER";
export const ADD_NEW_WELCOME_MESSAGE = "ADD_NEW_WELCOME_MESSAGE";
export const ADD_NEW_FILTER_CONDITION = "ADD_NEW_FILTER_CONDITION";
export const UPDATE_FILTER_CONDITION = "UPDATE_FILTER_CONDITION";
export const DELETE_FILTER_CONDITION = "DELETE_FILTER_CONDITION";
export const DELETE_WELCOME_MESSAGE = "DELETE_WELCOME_MESSAGE";
export const UPDATE_WELCOME_MESSAGE_FIELD = "UPDATE_WELCOME_MESSAGE_FIELD";
export const DISABLING_PERSONALISED_MESSAGE = "DISABLING_PERSONALISED_MESSAGE";
export const ENABLING_PERSONALISED_MESSAGE = "ENABLING_PERSONALISED_MESSAGE";
