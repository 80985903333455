import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { position, layout, flexbox } from "styled-system";

const StyledMobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  ${position}
  ${layout}
  ${flexbox}
`;

const FullScreenMobileModal = ({ children, isOpen, ...props }) => {
  if (!isOpen) {
    return null;
  }
  return <StyledMobileOverlay {...props}>{children}</StyledMobileOverlay>;
};

FullScreenMobileModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

FullScreenMobileModal.defaultProps = {
  isOpen: false,
};
export default FullScreenMobileModal;
