import React from "react";
import { ResponsiveModal } from "konnekt/Modal";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import HelpDeskModalBodyTally from "./HelpDeskModalBodyTally";
import HelpDeskModalBodyGeneral from "./HelpDeskModalBodyGeneral";

const HelpDeskModal = ({ isOpen, onHide }) => {
  const isTallySignupSource = useIsSignupSourceTally();
  return (
    <ResponsiveModal
      isOpen={isOpen}
      onHide={onHide}
      display="flex"
      flexDirection="column"
      height={{ _: "100%", tablet: "fit-content" }}
    >
      {isTallySignupSource ? (
        <HelpDeskModalBodyTally onHide={onHide} />
      ) : (
        <HelpDeskModalBodyGeneral onHide={onHide} />
      )}
    </ResponsiveModal>
  );
};

export default HelpDeskModal;
