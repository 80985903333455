import useIsSandboxMode from "customHooks/useIsSandboxMode";
import { getIfOrgHasPaymentEnabled } from "modules/Payments/PaymentSelectors";
import { useSelector } from "react-redux";
import useIsSignupSourceTally from "./useIsSignupSourceTally";

const useShouldShowInbox = () => {
  const activeSubscriptionFeatureList = useSelector(
    (state) => state.payment.activeSubscriptionFeatureList,
  );

  const isSandboxMode = useIsSandboxMode();
  // ? For tally inbox is being shown irrespective of subscription feature list
  const isSignupSourceTally = useIsSignupSourceTally();

  // Todo: Post Partner Plan migration is completed, payment enabled flag will be
  // true for everyone. And then this flag can be removed from everywhere in the
  // code
  const isPaymentEnabled = useSelector((state) =>
    getIfOrgHasPaymentEnabled(state),
  );

  const shouldShowInbox =
    !isPaymentEnabled ||
    !!activeSubscriptionFeatureList?.filter((item) => item === "inbox")
      .length ||
    isSandboxMode ||
    isSignupSourceTally;

  return shouldShowInbox;
};

export default useShouldShowInbox;
