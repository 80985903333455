import React, { useMemo } from "react";
import useIsSandboxMode from "customHooks/useIsSandboxMode";
import { useLocation } from "react-router-dom";
import { getNotShowSidebar } from "util/sidebar.util";
import Box from "konnekt/Box";
import useGetAvailableHeight from "customHooks/Organization/useGetAvailableHeight";
import { getIsHaptikUser } from "../HaptikReducer";

const ProtectedPageWrapper = ({ children, selectedAccountSegment }) => {
  const isSandboxMode = useIsSandboxMode();
  const location = useLocation();
  const availableHeight = useGetAvailableHeight();

  const props = useMemo(() => {
    if (getNotShowSidebar(location.pathname)) {
      return {};
    }
    if (
      selectedAccountSegment === "thankYouScreen" ||
      (selectedAccountSegment === "paymentPlanSelection" && !isSandboxMode)
    ) {
      return {};
    }
    return {
      position: "relative",
      width: {
        _: "100%",
        tablet: "calc(100vw - 60px)",
      },
      height: `calc(${availableHeight})`,
      left: { _: "0px", tablet: "60px" },
    };
  }, [
    selectedAccountSegment,
    isSandboxMode,
    location.pathname,
    availableHeight,
  ]);

  return <Box {...props}>{children}</Box>;
};

export default ProtectedPageWrapper;
