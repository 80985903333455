export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const UPADTE_LOADING_COLLECTIONS = "UPADTE_LOADING_COLLECTIONS";
export const SET_COLLECTION_BODY = "SET_COLLECTION_BODY";
export const SET_COLLECTION_IN_TOP_10 = "SET_COLLECTION_IN_TOP_10";
export const CHANGE_COLLECTIONS_SAVED_STATUS =
  "CHANGE_COLLECTIONS_SAVED_STATUS";
export const UPDATE_COLLECTIONS_FETCH = "UPDATE_COLLECTIONS_FETCH";
export const AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE =
  "AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE";
export const AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED =
  "AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED";
export const APPROVE_AUTO_CHECKOUT_SAMPLE = "APPROVE_AUTO_CHECKOUT_SAMPLE";
export const AUTO_CHECKOUT_LOADER = "AUTO_CHECKOUT_LOADER";
export const AUTO_CHECKOUT_MESSAGE_UPDATE = "AUTO_CHECKOUT_MESSAGE_UPDATE";
export const AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED =
  "AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED";
export const FETCH_AUTO_CHECKOUT_MESSAGE_DETAILS =
  "FETCH_AUTO_CHECKOUT_MESSAGE_DETAILS";
export const CHOOSE_AUTO_CHECKOUT_TEMPLATE = "CHOOSE_AUTO_CHECKOUT_TEMPLATE";
export const REJECTED_AUTO_CHECKOUT_SAMPLE = "REJECTED_AUTO_CHECKOUT_SAMPLE";
export const INIT_SAMPLE_APPROVED_AND_USED = "INIT_SAMPLE_APPROVED_AND_USED";
export const UPDATE_CONNECTION_SYNC_STATUS = "UPDATE_CONNECTION_SYNC_STATUS";
export const SET_CATALOG_SOURCE = "SET_CATALOG_SOURCE";
export const SET_CATALOG_NAME = "SET_CATALOG_NAME";
export const SET_CATALOG_LAST_SYNC_AT = "SET_CATALOG_LAST_SYNC_AT";
export const SET_CATALOG_TOTAL_COUNT = "SET_CATALOG_TOTAL_COUNT";
export const TEMPLATE_TIMEOUT = "TEMPLATE_TIMEOUT";
