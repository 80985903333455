import { useSelector } from "react-redux";

const useIsConversationBasedPlansEnabled = () => {
  return useSelector(
    (state) =>
      state.app?.organizations?.[0]?.organization_id
        ?.is_conversation_plan_allowed,
  );
};

export default useIsConversationBasedPlansEnabled;
