import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import {
  space,
  color,
  border,
  typography,
  height,
  layout,
  flexbox,
  system,
  variant,
  grid,
  position,
} from "styled-system";
import theme from "theme";

const StyledContainer = styled.p`
  ${variant({
    variants: {
      text1: {
        fontSize: theme.fontSizes.xl,
        fontWeight: theme.fontWeights.bold,
      },
      text2: {
        fontSize: theme.fontSizes.lg,
        fontWeight: theme.fontWeights.bold,
      },
      text3: {
        fontSize: theme.fontSizes.lg,
        fontWeight: theme.fontWeights.normal,
      },
      text4: {
        fontSize: theme.fontSizes.md,
        fontWeight: theme.fontWeights.bold,
      },
      text5: {
        fontSize: theme.fontSizes.md,
        fontWeight: theme.fontWeights.normal,
      },
      text6: {
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.bold,
      },
      text7: {
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.normal,
      },
      text8: {
        fontSize: theme.fontSizes.xs,
        fontWeight: theme.fontWeights.normal,
      },
      text9: {
        fontSize: theme.fontSizes.lg,
        fontWeight: theme.fontWeights.semibold,
      },
      text10: {
        fontSize: theme.fontSizes.md,
        fontWeight: theme.fontWeights.semibold,
      },
      text11: {
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.semibold,
      },
      text12: {
        fontSize: theme.fontSizes.xs,
        fontWeight: theme.fontWeights.bold,
      },
      text13: {
        fontSize: theme.fontSizes.md,
        fontWeight: theme.fontWeights.heavy,
      },
      text14: {
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.heavy,
      },
    },
  })}

  user-select: ${(props) => (props.noSelect ? "none" : "auto")};
  margin-bottom: 0;
  cursor: ${(props) => props.onClick && "pointer"};
  white-space: pre-wrap;

  ${(props) =>
    props.hoverCss &&
    `&:hover {
      ${props.hoverCss}
    }`}

  ${height}
  ${space}
  ${color}
  ${border}
  ${typography}
  ${layout}
  ${flexbox}
  ${grid}
  ${position}
  ${system({
    wordBreak: {
      property: "word-break",
    },
    cursor: {
      property: "cursor",
    },
    textDecoration: {
      property: "textDecoration",
    },
    whiteSpace: {
      property: "white-space",
    },
    textOverflow: {
      property: "text-overflow",
    },
    textTransform: {
      property: "text-transform",
    },
    transform: {
      property: "transform",
    },
    hyphens: {
      property: "hyphens",
    },
  })}
`;

// eslint-disable-next-line no-shadow
const Label = ({
  noSelect = false,
  children,
  variant: labelVariant = "text5",
  ...rest
}) => (
  <StyledContainer variant={labelVariant} noSelect={noSelect} {...rest}>
    {children || rest.text}
  </StyledContainer>
);

Label.propTypes = {
  noSelect: PropTypes.bool,
  variant: PropTypes.oneOf([
    "text1", // Text 1 - 22/600
    "text2", // Text 2 - 18/600
    "text3", // Text 3 - 18/400
    "text4", // Text 4 - 16/600
    "text5", // Text 5 - 16/400
    "text6", // Text 6 - 14/600
    "text7", // Text 7 - 14/400
    "text8", // Text 8 - 12/400
    "text9", // Text 9 - 18/500
    "text10", // Text 10 - 16/500
    "text11", // Text 11 - 14/500
    "text12", // Text 12 - 12/600
    "text13", // Text 13 - 16/700
    "text14", // Text 14 - 14/700
  ]),
};

Label.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  color: "#222222",
  // eslint-disable-next-line react/default-props-match-prop-types
  fontStyle: "normal",
  noSelect: false,
  variant: "text5",
};

export default Label;
