export const queryItemsDict = {
  str: [
    {
      keyName: "is",
      keyValue: "is",
    },
    {
      keyName: "contains",
      keyValue: "contains",
    },
    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],
  int: [
    {
      keyName: "greater than",
      keyValue: "greater than",
    },
    {
      keyName: "less than",
      keyValue: "less than",
    },
    {
      keyName: "is",
      keyValue: "is",
    },

    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],
  number: [
    {
      keyName: "greater than",
      keyValue: "greater than",
    },
    {
      keyName: "less than",
      keyValue: "less than",
    },
    {
      keyName: "is",
      keyValue: "is",
    },

    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],
  bool: [
    {
      keyName: "is true",
      keyValue: "is true",
    },
    {
      keyName: "is false",
      keyValue: "is false",
    },
    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],
  NoneType: [
    {
      keyName: "is true",
      keyValue: "is true",
    },
    {
      keyName: "is false",
      keyValue: "is false",
    },
    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],

  list: [
    {
      keyName: "contains ",
      keyValue: "contains ",
    },
  ],
  datetime: [
    {
      keyName: "more than",
      keyValue: "more than",
      extraText: "days ago",
    },
    {
      keyName: "exactly",
      keyValue: "exactly",
      extraText: "days ago",
    },
    {
      keyName: "less than",
      keyValue: "less than",
      extraText: "days ago",
    },
    {
      keyName: "after",
      keyValue: "after",
    },
    {
      keyName: "on",
      keyValue: "on",
    },
    {
      keyName: "before",
      keyValue: "before",
    },
    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],
  dict: [
    {
      keyName: "is",
      keyValue: "is",
    },
    {
      keyName: "contains",
      keyValue: "contains",
    },
    {
      keyName: "is empty",
      keyValue: "is empty",
    },
    {
      keyName: "has any value",
      keyValue: "has any value",
    },
  ],
};

export const relationKeyDict = {
  is: "eq",
  contains: "contains",
  "greater than": "gt",
  "less than": "lt",
  equal: "eq",
  "is true": "eq",
  "is false": "eq",
  "is empty": "is_unknown",
  "has any value": "is_known",
  exactly: "eq",
  "more than": "gt",
  before: "lt",
  on: "eq",
  after: "gt",
  in: "in",
  "not in": "nin",
  are: "in",
  "are not": "nin",
  "contains ": "in",
};

export const invertRelationKeyDict = {
  str: {
    eq: "is",
    ne: "is not",
    contains: "contains",
    not: "does not contain",
    is_unknown: "is empty",
    is_known: "has any value",
  },
  string: {
    eq: "is",
    ne: "is not",
    contains: "contains",
    not: "does not contain",
    is_unknown: "is empty",
    is_known: "has any value",
  },
  int: {
    gt: "greater than",
    lt: "less than",
    eq: "is",
    is_unknown: "is empty",
    is_known: "has any value",
  },
  number: {
    gt: "greater than",
    lt: "less than",
    eq: "is",
    is_unknown: "is empty",
    is_known: "has any value",
  },
  bool: {
    eq: "is",
    is_unknown: "is empty",
    is_known: "has any value",
  },
  NoneType: {
    eq: "is",
    is_unknown: "is empty",
    is_known: "has any value",
  },
  datetime: {
    lt: "before",
    eq: "on",
    gt: "after",
    is_unknown: "is empty",
    is_known: "has any value",
    // "lt": "more than",
    // "gt": "less than",
    // "eq": "exactly",
  },
  dict: {
    eq: "is",
    contains: "contains",
    is_unknown: "is empty",
    is_known: "has any value",
    ne: "is not",
  },
  tags: {
    in: "are",
    nin: "are not",
  },
  list: {
    in: "contains ",
  },
};
