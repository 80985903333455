import React from "react";
import useShouldShowCoexistenceFeature from "customHooks/useShouldShowCoexistenceFeature";

const withShowCoexistenceFeature = (WrappedComponent) => {
  return (props) => {
    const showCoexistenceFeature = useShouldShowCoexistenceFeature();

    return (
      <WrappedComponent
        {...props}
        showCoexistenceFeature={showCoexistenceFeature}
      />
    );
  };
};

export default withShowCoexistenceFeature;
