import React from "react";

const rcs = ({ fill, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_181_1869)">
        <path
          id="Vector"
          d="M9.16667 10C9.16667 10.221 9.25447 10.433 9.41075 10.5893C9.56703 10.7456 9.77899 10.8334 10 10.8334C10.221 10.8334 10.433 10.7456 10.5893 10.5893C10.7455 10.433 10.8333 10.221 10.8333 10C10.8333 9.77901 10.7455 9.56705 10.5893 9.41076C10.433 9.25448 10.221 9.16669 10 9.16669C9.77899 9.16669 9.56703 9.25448 9.41075 9.41076C9.25447 9.56705 9.16667 9.77901 9.16667 10Z"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M5.83333 9.99998C5.83333 10.8241 6.0777 11.6297 6.53554 12.3149C6.99338 13.0001 7.64412 13.5341 8.40548 13.8495C9.16684 14.1648 10.0046 14.2474 10.8129 14.0866C11.6211 13.9258 12.3636 13.529 12.9463 12.9463C13.529 12.3635 13.9258 11.6211 14.0866 10.8129C14.2474 10.0046 14.1649 9.16682 13.8495 8.40547C13.5341 7.64411 13.0001 6.99336 12.3149 6.53552C11.6297 6.07768 10.8241 5.83331 10 5.83331"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M5.24167 15.7975C6.171 16.5602 7.27007 17.0883 8.44624 17.3374C9.62241 17.5864 10.8412 17.549 11.9999 17.2284C13.1586 16.9077 14.2232 16.3133 15.1041 15.495C15.9849 14.6768 16.6562 13.6588 17.0612 12.5269C17.4663 11.3949 17.5933 10.1822 17.4315 8.99087C17.2698 7.79956 16.824 6.6646 16.1317 5.68164C15.4395 4.69868 14.5211 3.89654 13.454 3.34283C12.3869 2.78911 11.2022 2.50005 10 2.5"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_181_1869">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default rcs;
