export const NORMAL_MESSAGE = "Normal Message";
export const BUTTON_MESSAGE = "Button Message";
export const LIST_MESSAGE = "List Message";
export const TEMPLATE_MESSAGE = "Template Message";
export const LOCAL_STEP_ID = "non_saved_response";
export const STEP_TYPE = "Response Step";
export const defaultStepObj = {
  message: "",
  message_type: NORMAL_MESSAGE,
  list_message_name: null,
  variable_fallback_values: "",
  buttons: [],
  variable_list: [],
  workflow_variables: [],
  user_trait_name: null,
  step_type: "Response Step",
  webhook_data: {},
  variable_fallback_values: [],
};
