function appleIcon({ fill, ...props }) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9402 3.19C12.3182 2.75428 12.6065 2.24817 12.7884 1.70074C12.9703 1.1533 13.0422 0.575328 13.0002 0C11.8395 0.093687 10.7622 0.639556 10.0002 1.52C9.63525 1.94198 9.35873 2.43288 9.18696 2.96364C9.0152 3.49441 8.95167 4.05424 9.00016 4.61C9.5663 4.61472 10.1259 4.4891 10.6358 4.24286C11.1456 3.99662 11.5919 3.63637 11.9402 3.19ZM14.4602 10.63C14.4669 9.86374 14.6687 9.11182 15.0466 8.4452C15.4245 7.77859 15.9661 7.21926 16.6202 6.82C16.2074 6.22524 15.6617 5.73483 15.0264 5.38767C14.3911 5.04052 13.6836 4.84615 12.9602 4.82C11.4002 4.66 9.96016 5.73 9.13016 5.73C8.30016 5.73 7.13016 4.84 5.83016 4.86C4.98028 4.888 4.15218 5.13578 3.4266 5.57919C2.70103 6.02259 2.10276 6.64648 1.69016 7.39C-0.0698442 10.45 1.24016 15 3.00016 17.47C3.80016 18.68 4.80016 20.05 6.12016 20C7.44016 19.95 7.87016 19.18 9.40016 19.18C10.9302 19.18 11.4002 20 12.7002 19.97C14.0002 19.94 14.9202 18.73 15.7602 17.52C16.3552 16.6415 16.82 15.6816 17.1402 14.67C16.3476 14.332 15.6715 13.7693 15.1952 13.0513C14.7189 12.3333 14.4634 11.4916 14.4602 10.63Z"
        fill={fill}
      />
    </svg>
  );
}

export default appleIcon;
