import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";

export const RQ_TOTAL_CUSTOMER_COUNT = "RQ_TOTAL_CUSTOMER_COUNT";

const fetchTotalCustomerCount = async ({ orgId }) => {
  const res = await callApiV2({
    method: "post",
    endpoint: `v1/organizations/${orgId}/customers/count/`,
    body: [],
    shouldUseCampaignDomain: true,
  });

  if (res?.result) {
    return res.data;
  }
  throw new Error(res.message);
};

const useTotalCustomerCount = ({
  select = (data) => data,
  options = {},
} = {}) => {
  const orgId = useOrganizationId();

  return useQuery(
    [RQ_TOTAL_CUSTOMER_COUNT, orgId],
    () => fetchTotalCustomerCount({ orgId }),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined ? !!orgId : !!options.enabled && !!orgId,
    },
  );
};

export default useTotalCustomerCount;
