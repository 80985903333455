import useMqttAuthToken, {
  getMqttTopicList,
} from "useApi/App/socket/useMqttAuthToken";
import { useSelector } from "react-redux";
import { getInternalPhoneNumber } from "modules/App/AppSelectors";
import useOrganizationId from "./useOrganizationId";
import useIsSandboxMode from "./useIsSandboxMode";

const useSocketTopics = () => {
  const orgId = useOrganizationId();
  const { data: mqttTopicList } = useMqttAuthToken(getMqttTopicList);
  const topic1 = orgId;
  const isSandboxMode = useIsSandboxMode();
  const channelPhoneNumber = useSelector(getInternalPhoneNumber);

  if (orgId) {
    if (!isSandboxMode) {
      if (channelPhoneNumber) {
        return mqttTopicList;
      }
    } else {
      return [topic1];
    }
  }

  return null;
};

export default useSocketTopics;
