import useUser, { selectFeatures } from "useApi/App/useUser";

const useMaskNumber = () => {
  const { data: permissionsList } = useUser(selectFeatures);
  if (!permissionsList) {
    return true;
  }
  return !!permissionsList.filter(
    (item) => item.feature_code === "phone_number_hide",
  ).length;
};

export default useMaskNumber;
