import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";

const RQ_OFFLINE_BANNER_IGNORE = "RQ_OFFLINE_BANNER_IGNORE";

const fetchIgnoreOfflineBannerList = async (): Promise<Array<string>> => {
  try {
    const result = await fetch(
      `https://hellohaptik.github.io/InteraktBanner/data/offlineBannerIgnoreOrgs.json?timestamp=${new Date()}`,
    );

    return await result.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const useIsOrgAllowedToIgnoreOfflineBanner = (options: any = {}) => {
  const orgId = useOrganizationId();

  return useQuery({
    queryFn: () => fetchIgnoreOfflineBannerList(),
    queryKey: [RQ_OFFLINE_BANNER_IGNORE, orgId],
    select: (data: Array<string>) => {
      return data.includes(orgId);
    },
    enabled:
      options.enabled === undefined ? !!orgId : !!orgId && !!options.enabled,
  });
};

export default useIsOrgAllowedToIgnoreOfflineBanner;
