import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import _, { isEqual } from "lodash";
import JSON5 from "json5";
import moment from "moment";
import libphonenumber from "google-libphonenumber";
import startCase from "lodash/startCase";
import colors from "util/constant";
import mediaSupportedSize from "constants/media-supported-size.json";
import { countryCodeList } from "constants/countryConstants";
import callApi from "./apiCaller";
import { getUrlExtension, getHTMLDecodedText } from "./chatUtil";
import { updateMediaUrl } from "./mediaUtil";
import {
  SHOPIFY_AUTOCHECKOUT_TEMPLATE_GOT_TIMEDOUT,
  SHOPIFY_AUTOCHECKOUT_TEMPLATE_WAS_REJECTED,
  SHOPIFY_SANDBOX_BULK_CAMPAIGNS_ID,
} from "constants/storageService";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import Box from "konnekt/Box";
import history from "../history";
import { invertRelationKeyDict } from "components/UserTraits/constant";
import { Mixpanel } from "./tracking";
import { INT_CONCIERGE_NOTIFICATION_CARD_CLICKED } from "modules/Concierge/constant";
import { CHANNEL_TYPE_RCS } from "components/ChannelTypeDropdown/constants";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const validMobileNoV2 = (phoneNumber) => {
  if (phoneNumber) {
    const stringifiedNumber = phoneNumber.toString();
    return stringifiedNumber.length > 4 && stringifiedNumber.length < 20;
  }
  return false;
};

export function isValidMobileNo(phoneNumber, orgId) {
  if (orgId && orgId === "eb9d475b-11e1-4502-8512-95378e2249cb") {
    return validMobileNoV2(phoneNumber);
  }
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
  } catch (err) {
    return false;
  }
}

export function isValidISODate(date) {
  return moment(date, "YYYY-MM-DDThh:mm:ss", true).isValid();
}

export function getAgentFirstName(agent) {
  return agent && agent.first_name ? _.startCase(agent.first_name) : "";
}

export function isJSON(str) {
  try {
    return JSON5.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

/**
 * Use this to show toast
 * @param {"success" | "warning" | "error" | "socket-offline" | "offline" | "online" | "nudge"} type The type of toast to be shown
 * @param {string} msg The message to be shown
 * @returns
 */
export function showNotification(type, msg, autoCloseTimer = 2000) {
  switch (type) {
    case "success":
      toast.success(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "40px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_success.svg"
                alt=""
              />
            </i>
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          className: "toast-width-m",
        },
      );
      return;

    case "warning":
      toast.warning(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "40px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_warning.svg"
                alt=""
              />
            </i>
            <div className="toast-font" style={{ wordBreak: "unset" }}>
              {msg}
            </div>
          </div>
        ),
        {
          className: "toast-width-m",
        },
      );
      return;

    case "error":
      toast.error(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "40px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/warning_error.svg"
                alt=""
              />
            </i>
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          className: "toast-width-m",
        },
      );
      return;

    case "socket-offline":
    case "offline":
      toast.error(
        () => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "180px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_error.svg"
                alt=""
              />
            </i>
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          progress: undefined,
          className: "toast-width-m",
        },
      );
      return;

    case "online":
      toast.dismiss();
      toast.success(
        () => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "180px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_success.svg"
                alt=""
              />
            </i>
            <div className="toast-font">{msg}</div>
          </div>
        ),
        {
          position: "top-center",
          autoClose: autoCloseTimer,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          className: "toast-width-m",
        },
      );
      return;

    default:
      return;
  }
}

export function showNudge({ title, description }) {
  toast.success(
    () => (
      <Box
        display="flex"
        gridGap="12px"
        backgroundColor="#FEFEFE"
        p="14px 12px"
        onClick={() => {
          Mixpanel.track(INT_CONCIERGE_NOTIFICATION_CARD_CLICKED, {});
        }}
        maxHeight="500px"
        overflowY="auto"
      >
        <Icon iconName="announcementSidebar" iconSize="xs" />
        <Box>
          <Label hyphens="auto" variant="text6" color="#000">
            {title}
          </Label>
          <Label
            maxWidth="300px"
            hyphens="auto"
            mt="12px"
            variant="text7"
            color="#1E212F"
          >
            {description}
          </Label>
        </Box>
      </Box>
    ),
    {
      style: {
        boxShadow: "0px 2px 8px 0px #00000026",
        borderRadius: "4px",
        maxWidth: "400px",
        backgroundColor: "#FEFEFE",
        border: "1px solid #D0D4E1",
        padding: "13px",
      },
      progressStyle: {
        backgroundColor: "#D0D4E1",
      },
      hideProgressBar: false,
      progress: undefined,
      theme: "light",
      autoClose: 8000,
      className: "toast-nudge",
      onClick: () => {
        history.push("/announcements");
      },
    },
  );
}

/**
 * Use this to show toast
 * @param {"success" | "error" } type The type of toast to be shown
 * @param {string} msg The message to be shown
 * @returns
 */
export function showNotificationV2(type, msg) {
  if (type === "success") {
    return toast.success(
      () => (
        <Box
          display="flex"
          gridGap="12px"
          alignItems="center"
          backgroundColor="#32D583"
          p="14px 12px"
        >
          <Icon
            iconName="filledTick"
            height="20px"
            width="20px"
            color="#FFFFFF"
          />
          <Label fontWeight={500} fontSize="14px" color="#FFFFFF">
            {msg}
          </Label>
        </Box>
      ),
      {
        closeButton: false,
        style: {
          boxShadow: "0px 2px 8px 0px #00000026",
          borderRadius: "4px",
          backgroundColor: "#32D583",
          maxWidth: "400px",
        },
        autoClose: false,
      },
    );
  }

  if (type === "error") {
    return toast.error(
      () => (
        <Box
          display="flex"
          gridGap="12px"
          alignItems="center"
          backgroundColor="#D54F32"
          p="14px 12px"
        >
          <Icon
            iconName="errorOctagon"
            height="20px"
            width="20px"
            color="#FFFFFF"
          />
          <Label fontWeight={500} fontSize="14px" color="#FFFFFF">
            {msg}
          </Label>
        </Box>
      ),
      {
        closeButton: false,
        style: {
          boxShadow: "0px 2px 8px 0px #00000026",
          borderRadius: "4px",
          backgroundColor: "#D54F32",
          maxWidth: "400px",
        },
        autoClose: false,
      },
    );
  }

  return null;
}

export function showCustomNotification(type, component, options, toastId) {
  switch (type) {
    case "warning":
      toast.warning(component, options);
      break;

    case "success":
      if (toastId) {
        toast.dismiss(toastId);
      }
      toast.success(component, options);
      break;

    case "error":
      if (toastId) {
        toast.dismiss(toastId);
      }
      toast.error(component, options);
      break;

    default:
  }
}

export function handleFlatObject(obj) {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) =>
          typeof o[k] === "object" && o[k] ? _flatten(o[k]) : { [k]: o[k] },
        ),
      );
    })(obj),
  );
}

export function validBaseURL(str) {
  const pattern = new RegExp(
    "^(https:\\/\\/)" + // enforce https protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
}

export function checkIfValidUrl(text) {
  return new RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,10})(:[0-9]+)?(/.*)?",
  ).test(text);
}

export function isValidDotNotation(text) {
  return /^[a-zA-Z_$][a-zA-Z0-9_$]*(\.[a-zA-Z_$][a-zA-Z0-9_$]*|\[['"]?[a-zA-Z0-9_$]+['"]?\])*$|^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(
    text,
  );
}

export function checkIfStringHasVariables(text) {
  return text ? text.match(/\{\{[1-9][0-9]*\}\}/g) : [];
}

export function checkIfStringHasSubstring(string, substring) {
  return string ? string.includes(substring) : false;
}

export function removeDuplicateFromArray(arr) {
  const newArr = [];
  arr.forEach((item) => {
    if (
      newArr.filter((itm) => JSON5.stringify(itm) === JSON5.stringify(item))
        .length === 0
    ) {
      newArr.push(item);
    }
  });
  return newArr;
}

export function sortTemplateVariables(unsortedVariables = []) {
  const sortedVariables = [...unsortedVariables];
  return sortedVariables.sort((first, second) => {
    const firstIndex = parseInt(first.replace("{{", "").replace("}}", ""), 10);
    const secondIndex = parseInt(
      second.replace("{{", "").replace("}}", ""),
      10,
    );
    return firstIndex - secondIndex;
  });
}

export function validateMediaUrl(media_url, callback, mediaType) {
  const isValidUrl = checkIfValidUrl(media_url);
  if (isValidUrl) {
    callApi(
      `v1/validate/whatsapp/media-url/`,
      "post",
      {
        media_url: updateMediaUrl(media_url),
      },
      true,
    ).then((res) => {
      if (res && res.result) {
        if (!res.data.is_valid_media) {
          showNotification("warning", res.message);
        }
        callback(null, res);
      } else {
        showNotification(
          "warning",
          "There is an issue in the Media URL you submitted. Please resubmit it or use another URL.",
        );
        callback(null, false);
      }
    });
  } else {
    showNotification(
      "warning",
      "In the {{media_url}} Fallback Value, please check if you have entered a URL containing a media file. In the {{media_url}} Value, either select 'Use Constant Value' or a trait which contains media URLs",
    );

    callback(null, false);
  }
}

export function getConvertedCSVData(csv_data) {
  const newList = [];
  const headers = csv_data[0].data;
  headers.forEach((item, index) => {
    const items = [];
    for (let i = 1; i < csv_data.length; i++) {
      items.push(csv_data[i].data[index]);
    }
    newList.push({
      header_name: item,
      mapped_variable: item,
      isMappedToCSVName: true,
      items,
      index,
    });
  });
  return newList;
}

export function getPaginatedOffset(page, size) {
  return (page - 1) * size;
}

export const getPageDetailViaUrl = (url) => {
  const query = new URLSearchParams(url.split("?")[1]);

  const pageSize = parseInt(query.get("limit"), 10) || 0;
  const offset = query.get("offset") || 0;
  return {
    pageSize,
    pageNum: Math.round(offset / pageSize) + 1,
  };
};

export function getPages(count, pagesize) {
  if (count > 0) {
    return Math.ceil(count / pagesize);
  }
  return 0;
}

export function getNumberSuffix(i) {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export function makeNameVariableFirstInList(list) {
  list = list.filter((item) => item.keyName !== "name");
  list.unshift({
    keyName: "name",
    keyValue: "name",
    dataType: "str",
  });
  return list;
}

export function getFilenameFromUrl(url, mediaType) {
  const newUrl = decodeURIComponent(url);
  if (newUrl) {
    const m = newUrl.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      if (m[1] !== "drive") {
        return m[1];
      }
      // ? In case it's a drive link
      if (mediaType === "IMAGE") {
        return "Image";
      }
      if (mediaType === "VIDEO") {
        return "Video";
      }
      // ? "Document" is fallback in case of drive link
      return "Document";
    }
  }
  return "";
}

export function isValidBaseUrl(url) {
  const match = url.toString().match(/^.+?[^\/:](?=[?\/]|$)/);
  return match[0] === url;
}

export function fetchUserDeviceType() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export const getFullName = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return "";
  }
  if (!lastName) {
    return firstName.trim();
  }
  if (!firstName) {
    return lastName.trim();
  }
  return `${firstName} ${lastName}`.trim();
};

export function handleSearch(qtext, keyNames, list) {
  qtext = qtext.replaceAll("(", "\\(");
  qtext = qtext.replaceAll(")", "\\)");
  const exp = new RegExp(qtext, "i");
  list = list.filter((item) => {
    let isMatch = false;
    keyNames.forEach((key) => {
      if (!!item[key] && !!item[key].match(exp)) {
        isMatch = true;
      }
    });
    return isMatch;
  });

  return list;
}

export function getFormattedUnit(unit) {
  const unitObj = {
    minute: "min",
    minutes: "min",
    hour: "hr",
    hours: "hr",
    day: "day",
    days: "day",
    month: "mon",
    months: "mon",
    year: "yr",
    years: "yr",
  };
  return unitObj[unit];
}

export function isMobile() {
  return "ontouchstart" in document.documentElement;
}

export const getFormattedDate = (item, format = "MMM DD, YYYY") => {
  if (item) {
    return moment(item).tz("asia/kolkata").format(format);
  }
  return moment().tz("asia/kolkata").format(format);
};

export const getFileType = (name, fromHandleSend) => {
  // should work without fromHandleSend Argument

  const imageTypes = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
  const audioTypes = ["wav", "mp3", "m4a", "audio", "amr", "ogg"];
  const videoTypes = ["mp4", "3gpp"];
  const docTypes = ["doc", "docx"];
  const excelTypes = ["xlsx", "xls", "csv"];
  const pdf = ["pdf"];

  let extension;

  // check  for upload
  if (name && typeof name === "object") {
    extension = getUrlExtension(name.name);
  } else if (name) {
    extension = getUrlExtension(name);
  }

  if (name) {
    if (imageTypes.indexOf(extension) > -1) {
      return "Image";
    }
    if (audioTypes.indexOf(extension) > -1) {
      return "Audio";
    }
    if (videoTypes.indexOf(extension) > -1) {
      return "Video";
    }
    if (fromHandleSend) {
      return "Document";
    }
    if (docTypes.indexOf(extension) > -1) {
      return "wordDoc";
    }
    if (excelTypes.indexOf(extension) > -1) {
      return "excel";
    }
    if (pdf.indexOf(extension) > -1) {
      return "Document";
    }
    return null;
  }
  return "format not supported";
};

export const getFileTypeInsta = (name) => {
  const imageTypes = ["png", "jpeg", "jpg", "gif"];
  const audioTypes = ["acc", "mp4", "m4a", "wav"];
  const videoTypes = ["mp4", "ogg", "avi", "mov", "webm"];

  let extension;

  // check  for upload
  if (name && typeof name === "object") {
    extension = getUrlExtension(name.name);
  } else if (name) {
    extension = getUrlExtension(name);
  }

  if (name) {
    if (imageTypes.indexOf(extension) > -1) {
      return "Image";
    }
    if (audioTypes.indexOf(extension) > -1) {
      return "Audio";
    }
    if (videoTypes.indexOf(extension) > -1) {
      return "Video";
    }

    return null;
  }
  return "format not supported";
};

export const getPreview = (mediaUrl, isSelected = false) => {
  const fileType = getFileType(mediaUrl);

  switch (fileType) {
    case "Image":
    case "Video":
      // check for upload at inbox page
      return getHTMLDecodedText(mediaUrl) || URL.createObjectURL(mediaUrl);
    case "Document":
      return "/images/document.svg";
    case "Audio":
      return isSelected
        ? "/images/audiochat_white.svg"
        : "/images/audio_chat.svg";
    case "wordDoc":
      return "/images/doc.svg";
    case "excel":
      return "/images/excel.svg";
    // ? fallback to a doument preview if it is document from drive
    default:
      return "/images/document.svg";
  }
};

export function checkIfArrayHasNonEmptyStringElements(array) {
  if (array && array.length > 0) {
    let checkIfBlank = false;
    array.forEach((arrayItem) => {
      if (arrayItem === "" && !checkIfBlank) {
        checkIfBlank = true;
      }
    });
    return !checkIfBlank;
  }
  return false;
}

export const getColorAccordingToIndex = (index) => {
  return colors[index % colors.length];
};

export const searchList = (item, searchString) =>
  item.toLowerCase().includes(searchString.toLowerCase());

export const getDaysOption = () => {
  return [
    {
      label: "Monday",
      value: "Monday",
    },
    {
      label: "Tuesday",
      value: "Tuesday",
    },
    {
      label: "Wednesday",
      value: "Wednesday",
    },
    {
      label: "Thursday",
      value: "Thursday",
    },
    {
      label: "Friday",
      value: "Friday",
    },
    {
      label: "Saturday",
      value: "Saturday",
    },
    {
      label: "Sunday",
      value: "Sunday",
    },
  ];
};

export const isEmoji = (str) => {
  if (str) {
    const ranges = [
      "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
    ];
    return str.match(ranges.join("|"));
  }

  return null;
};

const preserveKeys = [
  "hideOutOfOfficeAnnouncement",
  "hideXDaysAgoAnnouncement",
  "hidePromotionalTemplates",
  "botFlowsVisited",
  "showRqDevTools",
  "partner_token",
  `kiwi_${SHOPIFY_SANDBOX_BULK_CAMPAIGNS_ID}`,
  `kiwi_${SHOPIFY_AUTOCHECKOUT_TEMPLATE_WAS_REJECTED}`,
  `kiwi_${SHOPIFY_AUTOCHECKOUT_TEMPLATE_GOT_TIMEDOUT}`,
];

export const clearLocalStorage = () => {
  const currentValues = {};
  preserveKeys.forEach(
    (key) => (currentValues[key] = window.localStorage.getItem(key)),
  );
  window.localStorage.clear();
  preserveKeys.forEach((key) => {
    if (currentValues[key] && currentValues[key] !== "null") {
      window.localStorage.setItem(key, currentValues[key]);
    }
  });
};

export const minTwoDigits = (n) => {
  return (n < 10 ? "0" : "") + n;
};

export const getSupportedMediaValidation = (fileSize, fileType) => {
  switch (fileType) {
    case "Image":
      return fileSize > mediaSupportedSize.Image;

    case "Audio":
      return fileSize > mediaSupportedSize.Audio;

    case "Video":
      return fileSize > mediaSupportedSize.Video;

    case "Document":
      return fileSize > mediaSupportedSize.Document;

    case "wordDoc":
      return fileSize > mediaSupportedSize.Document;

    case "excel":
      return fileSize > mediaSupportedSize.Document;

    case "Application":
      return fileSize > mediaSupportedSize.Application;

    case "Sticker":
      return fileSize > mediaSupportedSize.Sticker;
  }
};

export const validationForMediaFile = (file) => {
  let isErrorVisible = false;
  const fileSize = parseFloat(file?.size);
  const fileType = getFileType(file);
  if (fileSize && fileType && getSupportedMediaValidation(fileSize, fileType)) {
    isErrorVisible = true;
    showNotification(
      "warning",
      `Media file size exceeds the limit allowed. Please ensure the ${fileType.toLowerCase()} size is less than ${parseInt(
        mediaSupportedSize[fileType] / (1024 * 1024),
      )} MB`,
    );

    return isErrorVisible;
  }
  return isErrorVisible;
};

export const detectMobile = () => {
  return !!window && window.innerWidth <= 768;
};

export const validateWebsiteURL = (string) => {
  const res =
    string &&
    string.match(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/gi,
    );
  return res !== null;
};

export const maskNumber = (number, shouldMask = true) => {
  if (!shouldMask || !number) {
    return number;
  }

  const reducer = (accumulator, item, index, array) => {
    if (index < array.length - 3 && index > array.length - 11) {
      accumulator[index] = "X";
    } else {
      accumulator[index] = item;
    }

    return accumulator;
  };

  return number.toString().split("").reduce(reducer, []).join("");
};

export function maskUserTraits(userTrait = "", shouldMaskUserTraits = true) {
  if (userTrait && shouldMaskUserTraits) {
    return userTrait.replace(/./g, "X");
  }
  return userTrait;
}

export function updateLastSync(org, campaignId) {
  // TODO: Find a better way to store Last sync data
  if (org[0].organization_id?.campaigns_last_synced_at_utc) {
    org[0].organization_id.campaigns_last_synced_at_utc[
      campaignId
    ] = moment.utc().format();
  } else {
    org[0].organization_id.campaigns_last_synced_at_utc = {};
    org[0].organization_id.campaigns_last_synced_at_utc[
      campaignId
    ] = moment.utc().format();
  }
}

export const getCustomerNameInitials = (customerName) => {
  if (customerName) {
    return startCase(customerName[0]);
  }

  return "#";
};

// For example, GMT, UTC, PST, MST, CST, EST, etc...
export const timeZoneAbbreviation = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);

  // In Chrome browser, new Date().toString() is
  // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

  // In Safari browser, new Date().toString() is
  // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

  if (tz.includes(" ")) {
    return tz
      .split(" ")
      .map(([first]) => first)
      .join("");
  }
  return tz;
};

export const getTimeZone = () => {
  return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getUrlParams = (variable) => {
  if (variable && typeof variable === "string") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return urlParams.get(variable);
  }

  return null;
};

export const validationSignupFieldsForCharCount = (user) => {
  let isValid = true;
  let message = "";
  const errors = {};
  if (user.first_name.length > 64) {
    isValid = false;
    errors.first_name = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (user.last_name.length > 64) {
    isValid = false;
    errors.last_name = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (user.email.length > 64) {
    isValid = false;
    errors.email = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (user.company_name.length > 64) {
    isValid = false;
    errors.company_name = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (user.company_website.length > 64) {
    isValid = false;
    errors.company_website = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (user.company_location.length > 64) {
    isValid = false;
    errors.company_location = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  if (user.designation?.length > 64) {
    isValid = false;
    errors.designation = "You have exceeded your character limit";
    message = "You have exceeded your character limit";
  }
  return {
    isValid,
    message,
    errors,
  };
};

// Change ISO time to their local timezone and then convert to IST timezone
// ISO time format -> 2000-01-01 10:00:00
export const updateOtherTimezoneToISTTimezone = (val) => {
  const formats = [moment.ISO_8601, "YYYY-MM-DD  :)  HH*mm*ss"];
  if (val && moment(val, formats, true).isValid()) {
    return new Date(new Date(val).getTime() + 19800000)
      .toISOString()
      .slice(0, -5);
  }
};

// Change IST ISO time to their local timezone
// ISO time format -> 2000-01-01T10:00:00
export const updateISTTimezoneToLocalTimezone = (val) => {
  const updatedValue = `${val.replace(/T/g, " ")}.000Z`;
  const formats = [moment.ISO_8601, "YYYY-MM-DD  :)  HH*mm*ss"];
  if (val && moment(updatedValue, formats, true).isValid()) {
    return new Date(new Date(updatedValue).getTime() - 19800000).toTimeString();
  }
};

export const updateIftimezoneIsDifferent = (val, timezonOffset) => {
  const updatedValue = `${val.replace(/T/g, " ")}`;
  const formats = [moment.ISO_8601, "YYYY-MM-DD  :)  HH*mm*ss"];
  const currentTimezoneOffset = new Date()?.getTimezoneOffset();
  if (val && moment(updatedValue, formats, true).isValid()) {
    if (currentTimezoneOffset !== timezonOffset) {
      const minutesToBeadded = timezonOffset - currentTimezoneOffset;
      return new Date(
        new Date(updatedValue).getTime() + minutesToBeadded * 60000,
      ).toTimeString();
    }

    return new Date(updatedValue).toTimeString();
  }
};

export const getCountryNameFromCountryInitials = (initials) => {
  return (
    countryCodeList.filter((item) => item.initials === initials)?.[0]?.name ??
    initials
  );
};

export const getContainerAvailableScrollArea = (element) => {
  if (element) {
    return element.scrollHeight - element.clientHeight;
  }

  return 0;
};

export const deepEqual = (object1, object2) => {
  return isEqual(object1, object2);
};

export const removeElementFromArrayIndex = (array, index) => {
  if (index > -1) {
    // only splice array when item is found
    array.splice(index, 1); // 2nd parameter means remove one item only
  }

  return array;
};

export const isTagsFromTrait = (trait) => {
  return (
    trait.keyValue === "tags" &&
    (trait.dataType === "list" || trait.dataType === "dict")
  );
};

export const createNewCondition = () => {
  const id = uuidv4();

  return {
    id,
    trait: "",
    supr_op: null,
    op: "",
    val: "",
  };
};

export const isHTTPinURL = (url) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return true;
  }
  return false;
};

const removeAllInvalidValues = (array) => {
  return array.filter((item) => item !== "");
};

export const getDynamicButtonIndexOrder = (buttons) => {
  if (!Array.isArray(buttons)) {
    return [];
  }
  const channelParam = getUrlParams("channel_type");
  const isRCSChannel = channelParam === CHANNEL_TYPE_RCS;

  const orderList = buttons.map((item, index) => {
    const buttonUrl = isRCSChannel ? item.action?.openUrl?.url : item.url;
    if (item.type === "URL" && buttonUrl?.includes("{{1}}")) {
      return index;
    }
    return "";
  });

  return removeAllInvalidValues(orderList);
};

export const getDataTypeFromFilterValue = (trait, val) => {
  if (trait === "tags") {
    return "tags";
  }

  if (Array.isArray(val)) {
    return "list";
  }

  if (typeof val === "string") {
    return "str";
  }

  if (typeof val === "number") {
    return "number";
  }

  if (typeof val === "boolean") {
    return "dict";
  }

  if (typeof val === "object") {
    return "object";
  }

  return "NoneType";
};

export const finalFiltersToAppliedFilters = (finalFilters) => {
  const appliedFilters = [];

  finalFilters.forEach((filterType) => {
    filterType.filters.forEach((filter) => {
      if (filter.trait === "id") {
        return;
      }

      appliedFilters.push({
        keyName: filter.trait,
        keyValue: filter.val,
        relation: invertRelationKeyDict?.[
          getDataTypeFromFilterValue(filter.trait, filter.val)
        ]?.[filter.op]
          ? invertRelationKeyDict[
              getDataTypeFromFilterValue(filter.trait, filter.val)
            ][filter.op]
          : "eq",
        isOpenQuery: false,
        dataType: getDataTypeFromFilterValue(filter.trait, filter.val),
        parent: filterType.name,
        isShowFilterChip: filter.trait !== "id",
        supr_op: filter.supr_op,
        isApplied: true,
      });
    });
  });

  return appliedFilters;
};

export const isValidJSON = (text) => {
  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    return false;
  }
};

export const humanizeNumber = (number) => {
  const formatNumber = (num) => {
    const formatted = num.toFixed(1);
    return formatted.endsWith(".0") ? formatted.slice(0, -2) : formatted;
  };

  if (number < 1000) return number.toString();
  if (number < 1000000) return formatNumber(number / 1000) + "K";
  if (number < 1000000000) return formatNumber(number / 1000000) + "M";

  return "1M+";
};
