import React, { useState } from "react";
import Timer from "react-compound-timer";
import { toast } from "react-toastify";
import { ThemeProvider } from "styled-components";

import Box from "konnekt/Box";
import Label from "konnekt/Label";
import Icon from "konnekt/Icon";
import useUpdateCampaign from "useApi/Notification/useUpdateCampaign";
import CancelCampaignModal from "./CancelCampaignModal";
import { showNotification } from "util/utility";
import theme from "theme";

const EditNotificationToast = ({ campaign, history }) => {
  const { mutate: updateCampaignDetails, isLoading } = useUpdateCampaign();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const onCancelCampaign = () => {
    setIsCancelModalOpen(false);
    toast.dismiss(campaign.id);
    const newCampaign = { ...campaign };
    newCampaign["campaign_status"] = "Cancelled";
    if (!isLoading) {
      updateCampaignDetails(
        {
          newCampaign,
          campaignId: newCampaign.id,
        },
        {
          onSuccess: () => {
            showNotification(
              "success",
              "Campaign has been cancelled successfully",
            );
          },
        },
      );
    }
  };

  const onEditCampaign = () => {
    toast.dismiss(campaign.id);
    const newCampaign = { ...campaign };
    newCampaign["campaign_status"] = "Edit";
    updateCampaignDetails(
      {
        newCampaign,
        campaignId: newCampaign.id,
      },
      {
        onSuccess: () => {
          history.push(`/notification/campaign/${newCampaign.id}/update`);
          showNotification(
            "success",
            "Now you can start editing your campaign",
          );
        },
      },
    );
  };

  return (
    <>
      <Box p="8px 4px">
        <Box display="flex">
          <Box mr="12px" mt="2px">
            <Icon iconName="campaignOutlined" iconSize="xs" color="#666666" />
          </Box>
          <Box>
            <Box display="flex">
              <Label variant="text6">{campaign.name}</Label>&nbsp;
              <Label variant="text11">
                campaign goes live in&nbsp;
                <Timer
                  initialTime={60000}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => toast.dismiss(campaign.id),
                    },
                  ]}
                >
                  <Timer.Seconds />s
                </Timer>
              </Label>
            </Box>
            <Label variant="text11">
              Make any necessary edits until the timer expires.
            </Label>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" gridGap="16px" mt="4px">
          <Label
            variant="text7"
            color="#3F91DA"
            textDecoration="underline"
            onClick={onEditCampaign}
          >
            Edit campaign
          </Label>
          <Label
            variant="text7"
            color="#3F91DA"
            textDecoration="underline"
            onClick={() => setIsCancelModalOpen(true)}
          >
            Cancel campaign
          </Label>
        </Box>
      </Box>
      {/* react-toastify render this component outside of App.js that's why it's not wrapped inside ThemeProvider */}
      <ThemeProvider theme={theme}>
        <CancelCampaignModal
          isCancelModalOpen={isCancelModalOpen}
          setIsCancelModalOpen={setIsCancelModalOpen}
          campaignName={campaign.name}
          onCancelCampaign={onCancelCampaign}
        />
      </ThemeProvider>
    </>
  );
};

export default EditNotificationToast;
