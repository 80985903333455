import React from "react";

function stop({ fill, ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_514_1325)">
        <path
          id="Vector"
          d="M11.3333 2.66602H4.66666C4.13623 2.66602 3.62752 2.87673 3.25245 3.2518C2.87738 3.62687 2.66666 4.13558 2.66666 4.66602V11.3327C2.66666 11.8631 2.87738 12.3718 3.25245 12.7469C3.62752 13.122 4.13623 13.3327 4.66666 13.3327H11.3333C11.8638 13.3327 12.3725 13.122 12.7475 12.7469C13.1226 12.3718 13.3333 11.8631 13.3333 11.3327V4.66602C13.3333 4.13558 13.1226 3.62687 12.7475 3.2518C12.3725 2.87673 11.8638 2.66602 11.3333 2.66602Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_514_1325">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default stop;
