import moment from "moment";
import { getIfOrganizationIsOnFreePlan } from "modules/Payments/PaymentSelectors";
import { ONBOARDED } from "constants/onBoardingStatus";
import {
  HAPTIK_PARTNER_ID,
  INTERAKT_PARTNER_ID,
  THRIVE_PARTNER_ID,
} from "./constants/partners";

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const allowedOrgs = [
  "ba4308f1-a506-44d2-a8c3-17380216cf91",
  "80a1681a-3fbd-4580-beff-eb93478211a5",
];

export const getAuthenticatedState = (state) => state.app.isAuthenticated;
/**
 * @deprecated - Use useUser hook instead of this
 */
export const getSessionUser = (state) => state.app.user;
/**
 * @deprecated - Use useUser hook instead of this
 */
export const getSessionUserAgentId = (state) => state.app.user.id;
export const getWabaId = (state) =>
  state.app.organizations?.[0]?.organization_id?.waba_id;
export const getCatalogId = (state) =>
  state.app.organizations?.[0]?.organization_id?.metadata?.catalog_id;
export const getCatalogSyncStatus = (state) =>
  state.app.organizations?.[0]?.organization_id?.metadata?.catalog_sync_status;
export const getLastProductSyncAt = (state) =>
  state.app.organizations?.[0]?.organization_id?.last_product_synced_at;
export const getToken = (state) => state.app.token;
export const getLoggingState = (state) => state.app.isLoggingIn;
export const getAppLoadingState = (state) => state.app.isLoadingApp;
export const getBottomNavBarState = (state) => state.app.isBottomNavVisible;
export const getVerifyingState = (state) => state.app.isVerifying;
export const getVerifyingTokenState = (state) => state.app.isVerifyingToken;
export const getUserOrganizationState = (state) =>
  state.app.isFetchingOrganizationDetails;
export const getUserOrganizations = (state) => state.app.organizations;
export const getOrganizationUpdateStatus = (state) =>
  state.app.isUpdatingOrganization;
export const getUserActiveOrganization = (state) =>
  state.app.organizations && state.app.organizations.length > 0
    ? state.app.organizations[0].organization_id
    : {};
export const getSelectedOrganizationId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.id
    : "";
export const getSelectedOrganizationName = (state) =>
  state.app.organizations?.[0]?.organization_id?.name ?? "";
export const getSelectedOrganizationWAOnlyState = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.only_wa_notifications
    : false;
export const getUserOrganizationAgents = (state) => state.app.activeAgents;
export const getUserOrganizationAgentsCount = (state) =>
  state.app.activeAgents.length;
export const getAllAgents = (state) => state.app.allAgents;
export const getAllAgentsObjectMap = (state) => state.app.allAgentsObjectMap;
export const getUserOrganizationId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.id
    : "";
export const getPaymentEnabledStatus = (state) =>
  state.app.organizations?.[0]?.organization_id?.is_payment_enabled || false;
export const getOrganizationStripeCustomerId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.stripe_customer_id
    : "";
export const getUserChannels = (state) => state.app.channels;
export const getActiveChannel = (state) => getUserChannels(state)?.[0] ?? {};
export const getMessagingLimit = (state) => {
  // ? For free plans we messaging_limit is not source of truth
  if (getIfOrganizationIsOnFreePlan(state)) {
    return 200;
  }
  return state.app.channels?.[0]?.messaging_limit;
};

export const getIsSandboxMode = (state) => {
  const organizationData = state.app?.organizations[0]?.organization_id;
  const controlSwitch = () => {
    const sandboxModeValue = localStorage.getItem("sandboxMode");

    if (sandboxModeValue === "undefined" || sandboxModeValue === null) {
      return false;
    }

    return sandboxModeValue === true || sandboxModeValue === "true";
  };

  return (
    (!!organizationData &&
      organizationData.onboarding_status !== ONBOARDED &&
      organizationData.metadata?.sandbox_code?.length > 0 &&
      (process.env.REACT_APP_SANDBOX_MODE_ENABLED === "true" ||
        controlSwitch())) ??
    (process.env.REACT_APP_SANDBOX_MODE_ENABLED === "true" || controlSwitch())
  );
};
export const getSelectedPhoneExtension = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].country_code
    : ""; // this.props.channels && this.props.channels[0] && this.props.channels[0].phone_number
export const getInternalPhoneNumber = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].channel_phone_number
    : ""; // this.props.channels && this.props.channels[0] && this.props.channels[0].phone_number
export const getSelectedWhatsappNumber = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].phone_number
    : ""; // this.props.channels && this.props.channels[0] && this.props.channels[0].phone_number
export const getSelectedChannelId = (state) =>
  state.app.channels && state.app.channels[0] ? state.app.channels[0].id : "";
export const getSelectedChannelName = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].display_name
    : "";
export const getSelectedChannelPhotoUrl = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].profile_photo_url
    : "";
export const getSelectedChannelContainerNamespace = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].container_namespace
    : "";
export const getSelectedChannelStatus = (state) =>
  state.app.channels && state.app.channels[0]
    ? state.app.channels[0].wa_approval_status
    : "";
/**
 *
 * @deprecated use getOrganizationTagListV2 of this
 */
export const getOrganizationTagList = (state) => state.app.tagList;
export const getOrganizationTagListV2 = (state) =>
  Object.values(state.app.allTags || {});
export const getOrganizationAllTagObject = (state) => state.app.allTags;
export const getOranizationTagsFetchStatus = (state) =>
  state.app.isFetchingOrganizationTags;
export const getOrganizationCreatingStatus = (state) =>
  state.app.isCreatingOrganizationTag;

export const getOrganizationsLength = (state) =>
  state.app.organizations ? state.app.organizations.length : 0;

// * This selector needs to be replaced fully by getOrganizationOnboardingStatus
// ! Don't use this selector
// ? use getOrganizationOnboardedStatus in place of this
export const getOrganizationOnboardStatus = (state) =>
  state.app.isOrganizationOnboarded;
export const getOrganizationOnboardingStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.onboarding_status
    : "";

export const getOrganizationEmail = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.email
    : "";

export const getOrganizationPhone = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.phone
    : "";

export const getOrganizationOnboardingType = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.onboarding_type
    : "";

export const getOrganizationWebsite = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.company_website
    : "";

export const getOrganizationCompanyType = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.company_type
    : "";

export const getOrganizationLocation = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.company_location
    : "";

export const getOrganizationMinRevenue = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.min_revenue
    : "";

export const getOrganizationMaxRevenue = (state) =>
  state?.app?.organizations?.[0]?.organization_id
    ? state.app.organizations[0].organization_id.max_revenue
    : "";

export const getOrganizationOnboardedStatus = (state) =>
  state?.app?.organizations?.[0]?.organization_id &&
  state.app.organizations[0].organization_id.onboarding_status
    ? state.app.organizations[0].organization_id.onboarding_status ===
      onboardingStatuses.ONBOARDED
    : false;
export const getFbBusinessManagerId = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id
    ? state.app.organizations[0].organization_id.FB_business_manager_id
    : "";
// export const getFbBusinessManagerId = state => state.app.organizations && state.app.organizations[0] && state.app.organizations[0].organization_id ? '' : state.app.organizations[0].organization_id.FB_business_manager_id;
export const getFbBusinessVerificationStatus = (state) =>
  state.app.organizations?.[0]?.organization_id
    ?.FB_business_verification_status ?? "";
export const getWhatsAppAPIApprovalStatus = (state) =>
  state.app.organizations?.[0]?.organization_id?.wa_api_approval_status ?? "";

export const getVerifyingBusinessManagerIdState = (state) =>
  state.app.isVerifyingBusinessManagerId;
export const getVerifiedBusinessManagerIdState = (state) =>
  state.app.isVerifiedBusinessManagerId;
export const getUpdatingBusinessManagerIdState = (state) =>
  state.app.isUpdatingBusinessManagerId;
export const getUpdatedBusinessManagerIdState = (state) =>
  state.app.isUpdatedBusinessManagerId;

export const getWebClientId = (state) => state.app.webClientId;
export const getOTPSendingState = (state) => state.app.isSendingOTP;
export const getOTPSentState = (state) => state.app.isOTPSent;
export const getOTPVerifyingState = (state) => state.app.isVerifyingOTP;
export const getVerifiedState = (state) => state.app.isVerified;
export const getNetworkStatus = (state) => state.app.isOnline;
export const getAppTokenFound = (state) => state.app.isTokenFound;
export const getOrganizationQuickReplies = (state) => state.app.orgQuickReplies;
export const getQuickReplyFetchingState = (state) =>
  state.app.isFetchingQuickReplies;
export const getQuickReplyAddingState = (state) =>
  state.app.isAddingQuickReplies;
export const getQuickReplyUpdatingState = (state) =>
  state.app.isUpdatingQuickReplies;
export const getQuickReplyRemovingState = (state) =>
  state.app.isRemovingQuickReplies;
export const getOrganizationQuickRepliesLength = (state) =>
  state.app.orgQuickReplies.length;
export const getQuickReplyAlreadyLoadedState = (state) =>
  state.app.isQuickRepliesLoadedAlready;
export const getIsFetchingQuickReplies = (state) =>
  state.app.isFetchingQuickReplies;
export const getOrganizationEventSources = (state) => state.app.orgEventSources;
export const getIsFetchingEventSources = (state) =>
  state.app.isFetchingEventSources;
export const getEventSourceAlreadyLoadedState = (state) =>
  state.app.isEventSourcesLoadedAlready;

export const getOrganizationTagsAlreadyLoadedState = (state) =>
  state.app.isOrganizationtagsLoadedAlready;
export const getOrganizationMembers = (state) =>
  state.app.orgMembers.filter((item) => !!!item.is_deleted);
export const getMemberFetchingState = (state) => state.app.isFetchingMembers;
export const getMemberAddingState = (state) => state.app.isAddingMembers;
export const getMemberUpdatingState = (state) => state.app.isUpdatingMembers;
export const getMemberRemovingState = (state) => state.app.isRemovingMembers;
export const getOrganizationMembersLength = (state) =>
  state.app.orgMembers.filter((item) => !!!item.is_deleted).length;

export const checkIsUserDeviceMobile = (state) => state.app.IsUserDeviceMobile;
export const getQualityList = (state) => state.app.qualityList;
export const getActiveQuality = (state) => state.app.activeQuality;
export const getLanguageList = (state) => state.app.languages;
export const getLanguageDict = (state) => state.app.languageDict;
export const getUserRegisteringState = (state) => state.app.isRegistering;
export const getUserRegisteredState = (state) => state.app.isRegistered;
export const getGeneratingEmailState = (state) => state.app.isGeneratingEmail;
export const getAddingUserChannelState = (state) =>
  state.app.isAddingUserChannel;
export const getFetchingUserChannelState = (state) =>
  state.app.isFetchingUserChannels;
export const getSelectedAccountSegment = (state) =>
  state.app.selectedAccountSegment;
export const getSelectedRecoveryPasswordSegment = (state) =>
  state.app.selectedRecoveryPasswordSegment;
export const getStatusSendingMailForResetPasswordSegment = (state) =>
  state.app.isSendingMailForResetPassword;
export const getRecoveryPasswordStatus = (state) =>
  state.app.isRecoveryPassword;
export const getSelectedResetPasswordSegment = (state) =>
  state.app.selectedResetPasswordSegment;
export const getStatusUpdatingResetPasswordSegment = (state) =>
  state.app.isUpdatingResetPassword;
export const getStatusResendingInvitationForMember = (state) =>
  state.app.isResendingInvitationForMember;
export const getStatusShowBox = (state) => state.app.isShowBox;
export const getFirstTimeOrgOnboardedStatus = (state) =>
  state.app.isOrganizationFirstOnboarded;

export const getSaveWidgetPreferencesStatus = (state) =>
  state.app.isSavingWidgetPreferences;
export const getWidgetPreferences = (state) => state.app.widgetPreferences;
export const getAllUserTablePreferences = (state) =>
  state.app.allUserPreferences;
export const getBulkUploadSelectedTags = (state) =>
  state.app.selectedBulkUploadTags;
export const getBulkUploadSelectedSegment = (state) =>
  state.app.selectedBulkUploadSegment;
export const getCountryList = (state) => state.app.countryList;
export const getShopifySelectedSegment = (state) =>
  state.app.selectedShopifySegment;

export const getAddQuickReplyModalState = (state) =>
  state.app.isOpenAddReplyForm;
export const getEditQuickReplyModalState = (state) =>
  state.app.isOpenEditReplyForm;
export const getDeleteQuickReplyModalState = (state) =>
  state.app.isOpenDeleteReplyForm;
export const getDeleteTeamSettingModalState = (state) =>
  state.app.isOpenDeleteMember;
export const getEditTeamSettingModalState = (state) =>
  state.app.isOpenEditMember;
export const getQuickReplyFieldsState = (state) => state.app.quickReplyFields;
export const getQuickReplyVariableCount = (state) =>
  state.app.quickReplyVariableCount;
export const getQuickReplyInputFields = (state) =>
  state.app.quickReplyInputfields;

export const getPaymentModalStatus = (state) =>
  state.app.isOpenSubscriptionStartPaymentModal;
export const getBugReportSubjectText = (state) => state.app.bugReportSubject;
export const getBugReportDetailText = (state) => state.app.bugReportDetail;
export const getBugReportIssueWithText = (state) =>
  state.app.bugReportIssueWith;

export const getEmojiCardModalState = (state) => state.app.isOpenEmojiCard;

export const getTeamMemberInputFields = (state) =>
  state.app.teamMemberInputFields;
export const getAddTeamMemberModalState = (state) =>
  state.app.isOpenAddTeamMemberModal;

export const getOrganizationDetailInputValues = (state) =>
  state.app.organizationDetailInputValues;
/**
 * @deprecated - Use useUser hook instead of this
 */
export const getUserRoleGroup = (state) =>
  state.app.user && state.app.user.role ? state.app.user.role : "";
/**
 * @deprecated - Use useUser (with selectFeatureCodes selector) hook instead of this
 */
export const getUserFeaturePermissions = (state) =>
  state.app.user && state.app.user.userPermissions
    ? state.app.user.userPermissions
    : [];
export const getIfUserHasInboxAllChatsAccess = (state) =>
  state.app.user &&
  state.app.user.userPermissions &&
  state.app.user.userPermissions.length > 0
    ? state.app.user.userPermissions.indexOf("inbox_all_chats") > -1
    : false;
export const getIfUserHasAddContactAccess = (state) =>
  state.app.user &&
  state.app.user.userPermissions &&
  state.app.user.userPermissions.length > 0
    ? state.app.user.userPermissions.indexOf("users_add_contacts") > -1
    : false;
export const getIfOrganizationIsOrgCreatedPost29042021 = (state) =>
  state.app &&
  state.app.organizations &&
  state.app.organizations[0] &&
  moment(parseInt(process.env.REACT_APP_USERPILOT_ORG_USAGE)).isBefore(
    moment(
      new Date(
        state.app.organizations[0].organization_id
          ? state.app.organizations[0].organization_id.created_at_utc
          : "",
      ),
    ),
  );

export const getOrgCreatedAtUtc = (state) =>
  state.app?.organizations?.[0]?.organization_id?.created_at_utc
    ? new Date(state.app?.organizations?.[0]?.organization_id?.created_at_utc)
    : "";
export const getOrganizationTraitByKeyName = (state, keyName) =>
  state.app &&
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id[keyName]
    ? state.app.organizations[0].organization_id[keyName]
    : null;

export const getWabaPolicyViolationStatus = (state) =>
  getOrganizationTraitByKeyName(state, "waba_policy_violation_status");
export const getIsWabaPolicyViolationVisible = (state) =>
  getOrganizationTraitByKeyName(
    state,
    "waba_policy_violation_warning_is_visible",
  );

export const getOrgCurrency = (state) =>
  getOrganizationTraitByKeyName(state, "currency");

export const getIfOrganizationHasCatalogLinked = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");
  return metaData?.catalog_id;
};

export const getKlampIntegrationApiKey = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");

  return metaData?.integration_api_key;
};
export const getKlampIntegrationOrgId = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");

  return metaData?.integration_org_id;
};

export const getMaxWorkflowSteps = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");

  return metaData?.max_workflow_steps;
};

export const getSandboxCode = (state) => {
  return getOrganizationTraitByKeyName(state, "metadata")?.sandbox_code;
};

export const getIfOrganizationHasCatalogStatus = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");
  return metaData?.catalog_sync_status;
};
export const getComplianceInfoStatus = (state) => {
  const metaData = getOrganizationTraitByKeyName(state, "metadata");
  return metaData?.compliace_info_status;
};
export const getIsFetchingFBEmbeddedData = (state) =>
  state.app.isFetchingFbEmbeddedData;
export const getIfOrganizationHasEmbeddedSignupActive = (state) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id.is_embedded_signup_enabled;

export const getIfOrganizationHasWebscanEnabled = (state) =>
  state?.app?.organizations?.[0]?.organization_id?.has_web_qna_enabled ?? false;

export const getUserJourney = (state) => state.app.user_journey;
export const getSidebarStatus = (state) => state.app.isShowSidebar;
export const getUserPreferencesLoadedState = (state) =>
  state.app.isUserPreferencesLoaded;

export const getKeyValueFromChannels = (state, keyName) =>
  state.app.channels &&
  state.app.channels[0] &&
  state.app.channels[0][`${keyName}`]
    ? state.app.channels[0][`${keyName}`]
    : null;

export const getKeyValueFromOrganizations = (state, keyName) =>
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id &&
  state.app.organizations[0].organization_id[keyName]
    ? state.app.organizations[0].organization_id[keyName]
    : null;

export const getWigetStatus = (state) =>
  state.app.organizations &&
  state.app.organizations[0]?.organization_id?.metadata
    .has_active_shopify_widget;

export const getListMessageDetails = (state) => state.app.listMessageDetails;

export const getCampaignLastSyncTime = (campaignId) => (state) =>
  state.app?.organizations?.[0]?.organization_id
    ?.campaigns_last_synced_at_utc?.[campaignId];

export const getSandBoxCode = (state) =>
  state.app?.organizations[0]?.organization_id?.metadata?.sandbox_code;

export const getActiveAccountId = (state) => state.app?.activeAccountId;

export const getIsOrganizationsHasHaptikPartner = (state) => {
  // Check if Organizations has Haptik Partner
  return (
    state.app?.organizations[0]?.organization_id?.partner === HAPTIK_PARTNER_ID
  );
};
export const getIsOrganizationsHasInteraktPartner = (state) => {
  // Check if Organizations has Interakt Partner
  return (
    state.app?.organizations[0]?.organization_id?.partner ===
    INTERAKT_PARTNER_ID
  );
};

export const showEstimatedCampaignCost = (state) => {
  // Estimed Campaign cost should be hidden for Haptik & Thrive Partner
  const partnerID = state.app?.organizations[0]?.organization_id?.partner;
  const isHaptikOrThrivePartner =
    partnerID === HAPTIK_PARTNER_ID || partnerID === THRIVE_PARTNER_ID;
  return !isHaptikOrThrivePartner;
};

export const getHaptikOrThrivePartner = (state) => {
  const partnerID = state.app?.organizations[0]?.organization_id?.partner;
  return partnerID === HAPTIK_PARTNER_ID || partnerID === THRIVE_PARTNER_ID;
};

export const getESErrorPopupVisibility = (state) =>
  state.app?.ESErrorPopupVisibility;

export const getPartnerId = (state) =>
  state.app?.organizations[0]?.organization_id?.partner;

export const getIsWhatsAppPayAllowed = (state) =>
  state.app?.organizations[0]?.organization_id?.is_whatsapp_pay_allowed;

export const getIsCoexistenceAllowed = (state) =>
  state.app?.organizations[0]?.organization_id?.metadata
    ?.is_coexistence_allowed;

export const getIsSignalsVerificationFlowActivated = (state) =>
  state.app?.organizations[0]?.organization_id?.metadata
    ?.signals_verification_flow_activated;

export const getBusinessFailedVerificatioModalState = (state) =>
  state.app?.isOpenBusinessVerificationFailedModal;

export const getIsSmartAdsNewFlowEnabled = (state) =>
  state.app?.organizations[0]?.organization_id?.has_smart_ads_new_flow_enabled;

export const isWorkflowWebhookEnabled = (state) =>
  state.app?.organizations[0]?.organization_id?.is_workflow_webhook_enabled;

export const getIsCampaignReportsPasswordProtectionEnabled = (state) =>
  state.app?.organizations[0]?.organization_id
    ?.has_campaign_reports_password_protection_enabled;

export const getIsRCSFlowEnabled = (state) =>
  state.app?.organizations[0]?.organization_id?.is_rcs_channel_enabled;

export const getIsAutomationStatsEnabled = (state) =>
  state.app?.organizations[0]?.organization_id?.metadata
    ?.is_automation_stats_enabled;
