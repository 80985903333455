export const FETCHING_ALL_PAYMENT_METHODS = "FETCHING_ALL_PAYMENT_METHODS";
export const FETCH_ALL_PAYMENT_METHODS = "FETCH_ALL_PAYMENT_METHODS";
export const ATTACH_PAYMENT_METHOD = "ATTACH_PAYMENT_METHOD";
export const ATTACH_PAYMENT_METHOD_STATUS = "ATTACH_PAYMENT_METHOD_STATUS";
export const INVOICE_FETCH_STATUS = "INVOICE_FETCH_STATUS";
export const FETCH_INVOICE = "FETCH_INVOICE";
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const SUBSCRIPTION_FETCH_STATUS = "SUBSCRIPTION_FETCH_STATUS";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_PROCESS_STATUS_MESSAGE =
  "UPDATE_PAYMENT_PROCESS_STATUS_MESSAGE";
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE";
export const FETCH_PAYMENT_INVOICE = "FETCH_PAYMENT_INVOICE";
export const PAYMENT_INVOICE_FETCH_STATUS = "PAYMENT_INVOICE_FETCH_STATUS";
export const FETCH_NOTIFICATION_USAGE = "FETCH_NOTIFICATION_USAGE";
export const NOTIFICATION_USAGE_FETCH_STATUS =
  "NOTIFICATION_USAGE_FETCH_STATUS";
export const FETCH_BILLING_CYCLE_RANGE = "FETCH_BILLING_CYCLE_RANGE";
export const BILLING_CYCLE_RANGE_FETCH_STATUS =
  "BILLING_CYCLE_RANGE_FETCH_STATUS";
export const UNIQUE_USER_COUNT_FETCH_STATUS = "UNIQUE_USER_COUNT_FETCH_STATUS";
export const FETCH_UNIQUE_USER_COUNT = "FETCH_UNIQUE_USER_COUNT";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADDING_PAYMENT_METHOD = "ADDING_PAYMENT_METHOD";
export const UPDATE_PAYMENT_DONE_STATUS = "UPDATE_PAYMENT_DONE_STATUS";
export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";
export const SET_DEFAULT_PAYMENT_METHOD_STATUS =
  "SET_DEFAULT_PAYMENT_METHOD_STATUS";
export const DELETE_PAYMENT_METHOD_STATUS = "DELETE_PAYMENT_METHOD_STATUS";
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const FETCH_CREDIT_LIST = "FETCH_CREDIT_LIST";
export const FETCHING_CREDIT_LIST = "FETCHING_CREDIT_LIST";
export const SET_TOGGLE_ORGANIZATION_DETAIL_MODAL_STATE =
  "SET_TOGGLE_ORGANIZATION_DETAIL_MODAL_STATE";
export const SET_USAGE_HISTORY_ACTIVE_TAB = "SET_USAGE_HISTORY_ACTIVE_TAB";
export const TOGGLE_USAGE_HISTORY_CALENDER = "TOGGLE_USAGE_HISTORY_CALENDER";
export const SET_USAGE_HISTORY_CALENDER_FIELDS =
  "SET_USAGE_HISTORY_CALENDER_FIELDS";
export const TOGGLE_ADD_PAYMENT_METHOD_MODAL =
  "TOGGLE_ADD_PAYMENT_METHOD_MODAL";
export const TOGGLE_EDIT_NAME_MODAL = "TOGGLE_EDIT_NAME_MODAL";
export const TOGGLE_EDIT_EMAIL_MODAL = "TOGGLE_EDIT_EMAIL_MODAL";
export const TOGGLE_EDIT_ADDRESS_MODAL = "TOGGLE_EDIT_ADDRESS_MODAL";
export const TOGGLE_EDIT_GST_NUMBER_MODAL = "TOGGLE_EDIT_GST_NUMBER_MODAL";
export const TOGGLE_EDIT_GST_NUMBER_MODAL_MOBILE =
  "TOGGLE_EDIT_GST_NUMBER_MODAL_MOBILE";
export const IS_PAYMENT_DONE_RIBBON = "IS_PAYMENT_DONE_RIBBON";
export const IS_DAYS_LEFT_FREE_TRAIL_RIBBON = "IS_DAYS_LEFT_FREE_TRAIL_RIBBON";
export const SET_SUBSCRIPTION_EXPIRED_RIBBON_STATUS =
  "SET_SUBSCRIPTION_EXPIRED_RIBBON_STATUS";
export const TOGGLE_REDIRECT_TO_SHOPIFY_STATE =
  "TOGGLE_REDIRECT_TO_SHOPIFY_STATE";
export const UPDATE_PAYMENT_PLAN_CHANGE_STATE =
  "UPDATE_PAYMENT_PLAN_CHANGE_STATE";
export const FETCHING_ORG_BUSINESS_INFO = "FETCHING_ORG_BUSINESS_INFO";
export const FETCH_ORG_BUSINESS_INFO = "FETCH_ORG_BUSINESS_INFO";
export const SET_WHATSAPP_CONVERSATION_CHARGES =
  "SET_WHATSAPP_CONVERSATION_CHARGES";
