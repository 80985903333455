import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

const useGetNotificationRibbonHeight = () => {
  const notificationRibbonDetails = useSelector(
    (state) => state.app.notificationRibbonDetails,
    isEqual,
  );

  return `100vh - ${notificationRibbonDetails?.height}px`;
};

const withUseGetNotificationRibbonHeight = (WrappedComponent) => {
  return (props) => {
    const availableHeight = useGetNotificationRibbonHeight();

    return <WrappedComponent availableHeight={availableHeight} {...props} />;
  };
};

export default withUseGetNotificationRibbonHeight;
