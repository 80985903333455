import SIDEBAR_WIDTH, { SIDEBAR_EXPANDED_WIDTH } from "constants/sidebar";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import Modal from "konnekt/Modal";

const NoAnnouncements = ({ showContent, ...rest }) => {
  return (
    <Modal
      isOpen
      overlayCss={{
        left: showContent ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_WIDTH,
      }}
      style={{
        left: showContent
          ? `calc(${SIDEBAR_EXPANDED_WIDTH} + 4px)`
          : `calc(${SIDEBAR_WIDTH} + 4px)`,
        transform: "translateY(0)",
        top: "unset",
        bottom: "10px",
      }}
      width="400px"
      borderRadius="4px"
      boxShadow="0px 5px 13px 0px #6666661A"
      {...rest}
    >
      <Box p="16px" borderBottom="1px solid #D0D4E1">
        <Label variant="text2" color="#0A0B10">
          Announcements
        </Label>
      </Box>
      <Box
        p="16px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
      >
        <Icon noWrapper iconName="announcementHub" width="120px" />
        <Label variant="text2" mt="26px">
          No announcements yet
        </Label>
        <Label variant="text5" color="#464D6D" mt="16px">
          Stay tuned for updates! Currently, there are no new announcements to
          display. Once there's activity, you'll find all your important alerts
          and messages here.
        </Label>
      </Box>
    </Modal>
  );
};

export default NoAnnouncements;
