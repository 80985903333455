import { INT_WEB_LOGOUT } from "constants/mixPanel/login";
import { logOutUser } from "modules/App/AppActions";
import { getIsHaptikUser } from "modules/App/HaptikReducer";
import { Mixpanel } from "util/tracking";

export const ORGANIZATION_SUB_MENU_LIST = [
  {
    label: "Manage WhatsApp Profile",
    url: "/settings/signup-business",
    iconName: "whatsappProfile",
    color: "#464D6D",
    hideIfNumberNotConnected: true,
    showManageWhatsappProfile: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
  },
  {
    label: "Team Settings",
    url: "/settings/team-setting",
    iconName: "usersNew",
    color: "#464D6D",
    accessControlProps: {
      isRestrictedPage: true,
      shouldHavePermissions: ["settings_team_management"],
    },
    showTeamSettings: true,
    hideForResellerWithPlanNotStarted: true,
  },
  {
    label: "Developer Setting",
    url: "/settings/developer-setting",
    isHaptikUser: true,
    hideForTally: true,
    iconName: "developerSetting",
    strokeColor: "#464D6D",
    showDevelopSetting: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
  },
  {
    label: "Access Permissions",
    url: "/settings/permissions",
    iconName: "accessPermission",
    strokeColor: "#464D6D",
    accessControlProps: {
      isRestrictedPage: true,
      shouldHaveRoles: ["Owner"],
      shouldHavePermissions: [],
    },
    showAccessPermissions: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
  },
  {
    label: "Account Details",
    url: "/organization/account-details",
    isPresentAlways: true,
    iconName: "accountDetails",
    color: "#464D6D",
  },
  {
    label: "Quick Replies",
    url: "/settings/quick-replies",
    iconName: "quickReplyNew",
    strokeColor: "#464D6D",
    showQuickReplies: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
  },
  {
    label: "Member Profile",
    url: "/settings/profile",
    isPresentAlways: true,
    iconName: "memberProfile",
    color: "#464D6D",
    showMemberProfile: true,
  },
  {
    label: "Manage Tags",
    url: "/settings/manage-tags",
    iconName: "tags",
    strokeColor: "#464D6D",
    isPresentAlways: true,
  },
  {
    label: "Billing Details",
    url: "/billing/usage-history",
    iconName: "paymentLink",
    color: "#464D6D",
    hideForTally: true,
    isHaptikUser: true,
    showBillingDetails: true,
    accessControlProps: {
      isRestrictedPage: true,
      shouldHaveRoles: [],
      shouldHavePermissions: ["settings_billing_details"],
    },
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
  },
  {
    label: "Logout",
    button: true,
    isPresentAlways: true,
    iconName: "logout",
    strokeColor: "#464D6D",
    onClick: (dispatch) => {
      dispatch(logOutUser());
      Mixpanel.track(INT_WEB_LOGOUT, {});
      if (getIsHaptikUser()) {
        window.location.href = "https://api.haptikapi.com/logout";
      }
    },
  },
];
