import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getOrganizationTraitByKeyName } from "modules/App/AppSelectors";
import LaunchFlap from "konnekt/LaunchFlap";
import useCloseWithLimit from "customHooks/useCloseWithLimit";
import useOrganizationId from "customHooks/useOrganizationId";
import useFilteredAnnouncement from "customHooks/useFilteredAnnouncement";
import useActivePlan from "customHooks/useActivePlan";
import useBottomFlap from "useApi/App/useBottomFlap";
import { useLocation } from "react-router-dom";
import useAnnouncementTextReplace from "customHooks/useAnnouncementTextReplace";
import useUser from "useApi/App/useUser";

const Banner = ({ nonLoggedIn = false }) => {
  const isWebView = localStorage.getItem("webview");

  const orgId = useOrganizationId();
  const signUpSource = useSelector((state) =>
    getOrganizationTraitByKeyName(state, "signup_source"),
  );
  const activePlan = useActivePlan();
  const getFilteredBanner = useFilteredAnnouncement();
  const [caughtErr, setCaughtErr] = useState(false);
  const [storageVariable, setStorageVariable] = useState();
  const [closeLimit, setCloseLimit] = useState(1);
  const [text, setText] = useState("");
  const [linkText, setLinkText] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [iconSettings, setIconSettings] = useState();
  const [bannerSettings, setBannerSettings] = useState({
    bg: "#BE364E",
    color: "#fff",
  });

  const { isShown, handleClose } = useCloseWithLimit({
    defaultShown: false,
    storageVariable,
    closeLimit,
  });
  const { data: bottomFlapData, isInitialLoading } = useBottomFlap();

  const [image, setImage] = useState("");
  const [popupComponent, setPopupComponent] = useState(null);
  const [popupComponentProps, setPopupComponentProps] = useState(null);
  const [popupVisibility, setPopupVisibility] = useState(false);
  const [linkAction, setLinkAction] = useState("");
  const location = useLocation();
  const replaceVariables = useAnnouncementTextReplace();
  const { isLoading: userIsLoading } = useUser();
  const channelIsLoaded = useSelector((state) => !!state.app.channels?.[0]);

  useEffect(() => {
    const getDataFromRibbonApi = async () => {
      try {
        const filteredBanner = getFilteredBanner(bottomFlapData);

        setCloseLimit(filteredBanner.closeLimit);
        setStorageVariable(filteredBanner.id);
        setBannerSettings((prev) =>
          typeof filteredBanner.theme === "object"
            ? { ...prev, ...filteredBanner.theme }
            : { ...prev },
        );
        setText(replaceVariables(filteredBanner.text));
        setLinkText(replaceVariables(filteredBanner.cta?.text));
        setLinkUrl(replaceVariables(filteredBanner.cta?.url));
        setIconSettings((prev) =>
          typeof filteredBanner.icon === "object"
            ? { ...prev, ...filteredBanner.icon }
            : { ...prev },
        );
        if (filteredBanner.cta?.action) {
          setLinkAction(filteredBanner.cta?.action);
        }
        if (filteredBanner.cta?.popupImage) {
          setImage(filteredBanner.cta?.popupImage);
        }
        if (filteredBanner.cta?.componentName) {
          setPopupComponent(filteredBanner.cta?.componentName);
        }
        if (filteredBanner.cta?.componentProps) {
          setPopupComponentProps(filteredBanner.cta?.componentProps);
        }
        setCaughtErr(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Error in banner", err);
        setCaughtErr(true);
      }
    };
    if (
      (orgId &&
        signUpSource &&
        activePlan &&
        !isInitialLoading &&
        channelIsLoaded &&
        !userIsLoading) ||
      (nonLoggedIn && !isInitialLoading)
    ) {
      getDataFromRibbonApi();
    }
  }, [
    orgId,
    signUpSource,
    activePlan,
    location.pathname,
    isInitialLoading,
    nonLoggedIn,
    channelIsLoaded,
    userIsLoading,
  ]);

  if (caughtErr) return null;

  if (!isShown) return null;

  if (isWebView) return null;

  if (!text) return null;

  return (
    <LaunchFlap
      message={text}
      path={{
        name: linkText,
        url: linkUrl,
      }}
      headingProps={{ color: bannerSettings.color }}
      textContainerProps={{ paddingRight: "8px" }}
      {...bannerSettings}
      handleClose={handleClose}
      iconSettings={iconSettings}
      position="fixed"
      right={{ _: "7px", tablet: "30px" }}
      bottom={{ _: "80px", tablet: "30px" }}
      zIndex="999"
      maxWidth="356px"
      popup={{
        src: image,
        link: linkUrl,
        setVisibility: setPopupVisibility,
        visibility: popupVisibility,
        componentName: popupComponent,
        componentProps: popupComponentProps,
      }}
      linkAction={linkAction}
    />
  );
};

export default Banner;
