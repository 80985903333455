export const getCollections = (state) =>
  state.commerceSettings.collections.data;
export const getCollectionsLoading = (state) =>
  state.commerceSettings.collections.loading;
export const getCollectionsFetched = (state) =>
  state.commerceSettings.collections.fetched;
export const getCollectionsSavedStatus = (state) =>
  state.commerceSettings.collections.collectionsSaved;
export const getCatlogSource = (state) => state.commerceSettings.catalogSource;
export const getCatlogConnectionStatus = (state) =>
  state.commerceSettings.connectionSyncStatus;
export const getCatlogName = (state) => state.commerceSettings.catalogName;
export const getCatlogTotalCount = (state) =>
  state.commerceSettings.catalogTotalCount;
export const getCatlogLastSyncAt = (state) =>
  state.commerceSettings.catalogLastSyncedAt;
