import React from "react";

const Home = ({ fill, ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_88_18326)">
        <path
          d="M2 5.3335C2 4.80306 2.21071 4.29436 2.58579 3.91928C2.96086 3.54421 3.46957 3.3335 4 3.3335H12C12.5304 3.3335 13.0391 3.54421 13.4142 3.91928C13.7893 4.29436 14 4.80306 14 5.3335V10.6668C14 11.1973 13.7893 11.706 13.4142 12.081C13.0391 12.4561 12.5304 12.6668 12 12.6668H4C3.46957 12.6668 2.96086 12.4561 2.58579 12.081C2.21071 11.706 2 11.1973 2 10.6668V5.3335Z"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 6.6665H14"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66663 10H4.67329"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33337 10H8.66671"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_88_18326">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Home;
