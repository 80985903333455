import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import PersonLogo from "components/ChatWindow/PersonLogo";
import { setActiveAccountId } from "modules/App/AppActions";
import { getOrganizationOnboardedStatus } from "modules/App/AppSelectors";
import { getFormattedChannelPhoneNumber } from "util/accountUtil";
import { getNameIntials } from "util/chatUtil";
import ConnectNumberCard from "./ConnectNumberCard";

const Container = styled(Box)`
  display: flex;
  gap: 16px;
  flex: 1 0 0;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

const RightContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  cursor: pointer;
`;

const AccountItem = ({ item = {}, toggleSettings }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isOrganizationOnboarded = useSelector(getOrganizationOnboardedStatus);

  const switchAccount = () => {
    window.localStorage.setItem("organizationId", item.id);
    if (toggleSettings) {
      toggleSettings();
    }
    history.push("/notification");
    window.location.reload();
  };

  const openAccountDetailsPage = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (toggleSettings) {
      toggleSettings();
    }
    dispatch(setActiveAccountId(item.id));
    history.push("/organization/account-details");
  };

  return (
    <Container
      bg={isMouseOver ? "#E5F5F3" : "white"}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      onClick={switchAccount}
      alignItems={item.channel_phone_number ? "center" : "flex-start"}
    >
      <PersonLogo
        textContainerProps={{
          bg: isMouseOver ? "#00A68B" : "#eff1f4",
          color: isMouseOver ? "white" : "#32374E",
          fontWeight: "600",
          fontSize: "20px",
        }}
        size={40}
        text={getNameIntials(item?.name)}
      />
      <RightContainer>
        <Label variant="text6" color="#0A0B10">
          {item.name}
        </Label>
        {isOrganizationOnboarded && item.channel_phone_number ? (
          <Label variant="text8" color="#5A638C">
            {getFormattedChannelPhoneNumber(item.channel_phone_number)}
          </Label>
        ) : (
          <ConnectNumberCard subOrg />
        )}
      </RightContainer>
      {isMouseOver && (
        <Icon
          onClick={openAccountDetailsPage}
          iconName="accountDetails"
          iconSize="small"
          color="#5A638C"
        />
      )}
    </Container>
  );
};

export default AccountItem;
