import { useState, useEffect } from "react";
import storageService from "services/storageService";

/**
 * @typedef {Object} CloseWithLimitParams
 * @property {boolean} defaultShown the default value of isShown
 * @property {string} storageVariable the storageVariable in localStorage
 * @property {number} closeLimit no of times to close
 */
/**
 * @typedef {Object} CloseWithLimitReturn
 * @property {boolean} isShown boolean to show or not show the component
 * @property {()=>void} handleClose handles the Closing
 */

/**
 * @param {CloseWithLimitParams}
 * @returns {CloseWithLimitReturn}
 */
function useCloseWithLimit({ defaultShown, storageVariable, closeLimit }) {
  const [isShown, setIsShown] = useState(defaultShown);
  const storage = storageService.get(storageVariable);

  useEffect(() => {
    if (!storage) {
      setIsShown(true);
      return;
    }
    if (parseInt(storage, 10) < closeLimit) {
      setIsShown(true);
      return;
    }
    setIsShown(false);
  }, [closeLimit, storage]);

  const handleClose = () => {
    const bannerClosedCount = storage ? parseInt(storage, 10) : 0;
    storageService.set(storageVariable, bannerClosedCount + 1);
    setIsShown(false);
  };

  return { isShown, handleClose };
}

export default useCloseWithLimit;
