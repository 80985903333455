/* eslint-disable import/prefer-default-export */
export const getFileDetails = async (fileUrl) => {
  try {
    const res = await fetch(fileUrl);
    return {
      size: res.headers.get("content-length"),
      type: res.headers.get("content-type"),
    };
    // eslint-disable-next-line no-empty
  } catch (err) {
    return {};
  }
};

export const updateMediaUrl = (mediaURL) => {
  if (!mediaURL.includes("drive.google.com") || !mediaURL.includes("/file/d")) {
    return mediaURL;
  }
  // ? update url if it is drive.google.com url to a downlodable url
  const fileId = (mediaURL.split("/file/d/")[1] || "").split("/")?.[0] ?? "";
  return `https://drive.google.com/uc?export=download&id=${fileId}`;
};
