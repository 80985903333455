import { NOT_STARTED } from "constants/apiStatus";
import {
  SET_SETTINGS_AND_MESSAGES,
  SET_ACTIVE_BLOCKER_TYPE,
  UPDATE_CURRENT_FLOW,
  UPDATE_BOT_MESSAGE,
  CHANGE_BOT_FLOW_STATUS,
  UPDATE_IS_FETCHING_BOT_FLOW,
  SET_ORG_BOT_ID,
  UPDATE_SET_LIVE_STATUS,
  SET_SHOW_BOTFLOW_TEMPLATE_REJECTION_MODAL,
} from "./BotFlowsActionTypes";

const initialState = {
  status: null,
  orgBotId: null,
  settings: {},
  botMessages: [],
  currentFlow: {},
  //! To make sure that we are fetching bot flow for only one time I have kept isFetchingBotFlow in redux
  //! In case of local isFetchingBotFlow because of parent re render it was getting reset to false and useEffect was running twice
  isFetchingBotFlowStatus: NOT_STARTED,
  activeBlockerType: null,
  setLiveStatus: NOT_STARTED,
  showBotFlowTemplateRejectionModal: false,
};

// eslint-disable-next-line default-param-last
const botFlowsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS_AND_MESSAGES: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.settings,
        },
        botMessages: action.payload.overrideBotMessages
          ? [...action.payload.botMessages]
          : [...state.botMessages, ...action.payload.botMessages],
      };
    }

    case SET_ACTIVE_BLOCKER_TYPE: {
      return {
        ...state,
        activeBlockerType: action.payload,
      };
    }

    case UPDATE_BOT_MESSAGE: {
      return {
        ...state,
        botMessages: state.botMessages.map((prevBotMessage) => {
          if (prevBotMessage.id === action.payload.id) {
            return {
              id: action.payload.id,
              message: { ...prevBotMessage.message, ...action.payload.message },
            };
          }
          return prevBotMessage;
        }),
      };
    }

    case UPDATE_CURRENT_FLOW: {
      return {
        ...state,
        currentFlow: {
          ...state.currentFlow,
          ...action.payload,
        },
      };
    }

    case CHANGE_BOT_FLOW_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case SET_ORG_BOT_ID: {
      return {
        ...state,
        orgBotId: action.payload,
      };
    }

    case UPDATE_IS_FETCHING_BOT_FLOW: {
      return {
        ...state,
        isFetchingBotFlowStatus: action.payload,
      };
    }

    case UPDATE_SET_LIVE_STATUS: {
      return {
        ...state,
        setLiveStatus: action.payload,
      };
    }

    case SET_SHOW_BOTFLOW_TEMPLATE_REJECTION_MODAL: {
      return {
        ...state,
        showBotFlowTemplateRejectionModal: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default botFlowsReducer;
