import useActivePlanV2 from "./useActivePlanV2";
import useLastPlan from "./useLastPlan";

// TODO: Rename it to useActivePlanWithFallback
const useActivePlan = () => {
  const activePlan = useActivePlanV2();

  const subscriptionListPlan = useLastPlan();

  return activePlan || subscriptionListPlan;
};

export default useActivePlan;
