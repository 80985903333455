import { callApiV2 } from "./apiCaller";
import { showNotification } from "./utility";

interface FileUploadInterface {
  fileUrlToUpload: string;
  orgId: string;
  category?: string;
}

interface FileUploadResponseInterface {
  fileHandle: string;
  fileUrl: string;
}

export async function handleFbFileUrlUpload({
  fileUrlToUpload,
  orgId,
  category = "message_template_media",
}: FileUploadInterface): Promise<FileUploadResponseInterface | null> {
  if (orgId) {
    try {
      const res = await callApiV2({
        method: "POST",
        endpoint: `/v1/organizations/${orgId}/file/get_header_handle/?fileCategory=${category}`,
        body: {
          uploadFile: fileUrlToUpload,
        },
      });

      if (res.result) {
        if (res?.data?.file_handle && res?.data?.file_url) {
          return {
            fileHandle: res.data.file_handle,
            fileUrl: res.data.file_url,
          };
        }
      }

      throw new Error(res.message);
    } catch (error: Error | any) {
      showNotification("error", error.message);
    }
  }

  showNotification("error", "Failed to upload file");
  return null;
}
