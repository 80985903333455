import { useQuery } from "@tanstack/react-query";

export const RQ_BOTTOM_FLAP = "RQ_BOTTOM_FLAP";

const fetchBottomFlap = async () => {
  const res = await fetch(
    `https://hellohaptik.github.io/InteraktBanner/data/launchflap_config/${
      process.env.REACT_APP_ENVIRONMENT
    }.json?timestamp=${new Date()}`,
  );
  const data = await res.json();

  return data;
};

const useBottomFlap = (select = (data) => data, options = {}) => {
  return useQuery([RQ_BOTTOM_FLAP], fetchBottomFlap, {
    select,
    ...options,
  });
};

export default useBottomFlap;
