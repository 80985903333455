import _ from "lodash";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {
  UPDATE_ACTIVE_CHAT_ID,
  UPDATE_AGENT_CHANGE_MESSAGE,
  TOGGLE_CHAT_WINDOW,
  UPDATE_CHAT_ACTIVATED_MESSAGE,
  UPDATE_CHAT_CLOSED_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  UPDATE_DELIVERY_EVENT_MESSAGE,
  UPDATE_MESSAGE_THREAD,
  UPDATE_MESSAGE_THREAD_STATUS,
  UPDATE_NOTE_MESSAGE,
  UPDATE_SEEN_EVENT_MESSAGE,
  UPDATE_SEND_EVENT_FAILED_MESSAGE,
  UPDATE_SEND_EVENT_MESSAGE,
  TOGGLE_USER_DETAIL_VIEW,
  UPDATE_ACTIVE_CHAT,
  UPDATE_CONVERSATION_CLOSED,
  ASSIGNING_AGENT_TO_CHAT,
  ASSIGNED_AGENT_TO_CHAT,
  SELECT_FILTER_TYPE,
  UPDATE_DOCUMENT_MESSAGE,
  REMOVE_DOCUMENT_MESSAGE,
  UPDATE_AGENT_ASSIGNMENT_MESSAGE,
  UPDATE_CHAT_OPENED_MESSAGE,
  CLOSING_CHAT,
  UPDATE_CUSTOMER_DETAILS_CHAT,
  UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID,
  UPDATE_INBOX_SYNC_MOBILE,
  SET_NOTIFICATION_RIBBON,
  UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET,
  MARK_MESSAGE_AS_BEING_RETRIED,
  UPDATE_NEW_CONVERSATIONS_WITH_THREAD,
  EMPTY_MESSAGE_THREAD,
  UPDATE_FILTER_OPTIONS,
  UPDATE_LIMIT_EXCEEDED_POPUP_STATE,
  ASSIGNING_ACTIVE_CONVERSATION_LABEL,
  UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL,
  CLEAR_ACTIVE_CONVERSATION_LABEL,
  UPDATE_EVENT_COUNT,
  UPDATE_SPAM_CHAT_STATUS,
  TOOGLE_SPAM_CHAT_START,
  TOOGLE_SPAM_CHAT_SUCCESS,
  TOOGLE_SPAM_CHAT_FAILURE,
  UPDATE_NEW_CUSTOMER_MESSAGES_DATA,
  UPDATE_IS_NEW_CUSTOMER_MESSAGES,
  DELETE_MESSAGE_FROM_THREAD,
  UPDATE_CHAT_MABA_STATUS,
} from "./ThreadActions";
import { messageErrorCodes } from "constants/enums/channelMessageErrors";

import { CLOSE_SEARCH_SECTION } from "modules/Inbox/inboxStateTypes";
import { sortArrayWithTimeStamp, sanitizeFromNumber } from "util/chatUtil";
import { RESET_FILTER } from "./ConversationActions";

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const initialState = {
  currentStatus: "",
  currentFilters: {},
  isFetchingActiveChats: false,
  isFetchingClosedChats: false,
  threads: {},
  isClosingChat: false,
  toggleForChange: false,
  inactivatedThreads: {},
  chatThreads: {},
  activeConversation: {},
  activeFromNumber: "",
  isChatWindowVisible: false,
  isUserDetailsViewVisible: false,
  selectedFilter: "",
  selectedChatType: "Open",
  selectedSort: "Newest",
  filterOptions: ["All", "You", "Unassigned", "Spam"],
  chatOptions: ["Open", "Closed"],
  sortOptions: ["Newest", "Oldest"],
  selectedConversationUserProfile: {
    userDetails: {},
    tags: [],
    orderHistory: [],
    notes: [],
    conversationHistory: [],
  },
  agentProfileOrder: [],
  quickReplyTemplates: [],
  approvedTemplateMessages: [],
  storedDocumentTemplates: [],
  isFetchingActiveChatMessages: false,
  isAssigningAgent: false,
  sentMessages: {},
  statusMessages: {},
  selectedCustomerChatHistory: {},
  historyThread: {
    offset: 0,
  },
  selectedCustomerFromNumber: "",
  toggleForDocumentChange: false,
  showNotificationRibbon: false,
  isFetchingConversations: false,
  chats: [],
  offset: 0,
  chatPageSize: 10,
  chatTotalCount: 0,
  isLimitExceeded: false,
  limitExceedErrorCode: "",
  chatCurrentCount: 0,
  spam: {
    toogleSpamLoading: false,
  },
  isNewCustomerMessage: false,
  newCustomerMessagesCount: 0,
};

const inboxReducer = (state = initialState, action) => {
  // TODO: Need to do cleanup of this reducer. mutating original state is not correct way how redux works. This is causing weird bugs
  let threads = {};
  let inactivatedThreads = {};
  let messageType = "CustomerMessage";
  let chatId = "";
  let fromNumber = "";
  let activeConversation = {};
  let chatThreads = {};
  let messageLength;
  let sentMessages = {};
  const statusMessages = {};

  switch (action.type) {
    case UPDATE_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };

    case UPDATE_CHAT_MESSAGE: {
      let isNewMessage = false;
      let newMessagesCount = 0;
      activeConversation = state.activeConversation;

      threads = cloneDeep(state.threads);
      inactivatedThreads = state.inactivatedThreads;

      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(action.event.from)
      ) {
        messageType = "AgentMessage";
        fromNumber = sanitizeFromNumber(action.event.to);
      } else {
        fromNumber = sanitizeFromNumber(action.event.from);
        messageType = "CustomerMessage";
      }

      if (
        activeConversation &&
        activeConversation.phone_number === fromNumber &&
        action.event.channel_message_id &&
        action.event.channel_message_id !== "" &&
        action.currentUserId
      ) {
        window.trackerQueue.push({
          trackerData: {
            chat_id: activeConversation?.id,
            last_message_read_at_utc: new Date(),
            agent_user_id: action.currentUserId,
            channel_message_id: action.event.channel_message_id,
          },
        });
      }

      if (action.event && fromNumber) {
        const activeConversationPhoneNumber =
          (activeConversation && activeConversation.phone_number) ||
          activeConversation?.customer?.channel_phone_number ||
          activeConversation?.customer_id?.channel_phone_number ||
          activeConversation?.customer_id?.channel_account_identifier;
        if (activeConversationPhoneNumber === fromNumber) {
          activeConversation.last_message = action.event;

          if (messageType == "CustomerMessage") {
            activeConversation.last_customer_message_at_utc = new moment().toISOString();
            isNewMessage = true;
            newMessagesCount = state.newCustomerMessagesCount + 1;
          } else {
            isNewMessage = false;
            newMessagesCount = 0;
          }
          activeConversation.recieved_at_utc = new moment().toISOString();
          activeConversation.timestamp = new Date().getTime();
        }

        if (threads[fromNumber]) {
          threads[fromNumber].last_message = action.event;
          // Update chatTotalCount

          threads[fromNumber].chatTotalCount =
            threads[fromNumber].chatTotalCount + 1;

          if (threads[fromNumber].messages) {
            if (
              !threads[fromNumber].sentMessages[
                action.event.channel_message_id
              ] &&
              !threads[fromNumber].sentMessages[action.event.wc_id]
            ) {
              threads[fromNumber].messages.push({
                messageType,
                messageId: action.event.channel_message_id,
                message: action.event,
                timestamp: new Date(action.event.created_at_utc).getTime(),
              });

              if (action.event.channel_message_id) {
                threads[fromNumber].sentMessages[
                  action.event.channel_message_id
                ] = 1;
              }
              if (action.event.wc_id) {
                threads[fromNumber].sentMessages[action.event.wc_id] = 1;
              }
            }
          }
        } else if (
          inactivatedThreads[fromNumber] &&
          inactivatedThreads[fromNumber].messages
        ) {
          inactivatedThreads[fromNumber].messages.push({
            messageType,
            messageId: action.event.channel_message_id,
            message: action.event,
            timestamp: new Date(action.event.created_at_utc).getTime(),
          });
        } else {
          inactivatedThreads[fromNumber] = {};
          inactivatedThreads[fromNumber].messages = [];
          inactivatedThreads[fromNumber].messages.push({
            messageType,
            messageId: action.event.channel_message_id,
            message: action.event,
            timestamp: new Date(action.event.created_at_utc).getTime(),
          });
        }

        const activeMessages = threads?.[fromNumber]?.messages;

        const activeChannelMessageId = action?.event?.channel_message_id;

        const recievedMetaDataReaction = action?.event?.meta_data?.reactions;

        if (
          activeMessages?.length > 0 &&
          activeChannelMessageId &&
          Array.isArray(recievedMetaDataReaction)
        ) {
          const selectedMessage = activeMessages.find(
            (item) =>
              item.message.channel_message_id === activeChannelMessageId,
          );

          if (
            selectedMessage?.message &&
            !isEqual(
              selectedMessage.message.reactions,
              recievedMetaDataReaction,
            )
          ) {
            selectedMessage.message.reactions = recievedMetaDataReaction;
          }
        }
      }

      return {
        ...state,
        threads,
        inactivatedThreads,
        sentMessages,
        isNewCustomerMessage: isNewMessage,
        newCustomerMessagesCount: newMessagesCount,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_NOTE_MESSAGE: {
      const threadsCopy = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threadsCopy[fromNumber]) {
          // update notes count
          threadsCopy[fromNumber].noteTotalCount =
            threadsCopy[fromNumber].noteTotalCount + 1;
          if (
            !threadsCopy[fromNumber].sentMessages[
              action.event.channel_message_id
            ] &&
            !threadsCopy[fromNumber].sentMessages[action.event.wc_id]
          ) {
            threadsCopy[fromNumber].messages.push({
              messageType: "note",
              messageId: action.event.messageId,
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
          }
          if (action.event.channel_message_id) {
            threadsCopy[fromNumber].sentMessages[
              action.event.channel_message_id
            ] = 1;
          }
          if (action.event.wc_id) {
            threadsCopy[fromNumber].sentMessages[action.event.wc_id] = 1;
          }
        }
      }
      return {
        ...state,
        threads: threadsCopy,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_SEND_EVENT_MESSAGE: {
      activeConversation = state.activeConversation;
      let threadsCopy = cloneDeep(state.threads);
      inactivatedThreads = state.inactivatedThreads;
      sentMessages = state.sentMessages;
      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(action.event.to)
      ) {
        if (action.event.isOOOMessage) {
          messageType = "OutOfOfficeMessage";
        } else {
          messageType = "AgentMessage";
        }
        fromNumber = sanitizeFromNumber(action.event.from);
      } else {
        fromNumber = sanitizeFromNumber(action.event.to);
        messageType = "CustomerMessage";
      }

      if (threadsCopy[fromNumber]) {
        threadsCopy[fromNumber].noteTotalCount =
          threadsCopy[fromNumber].noteTotalCount + 1;
      }

      if (
        !action.isNewCustomerMessage &&
        activeConversation &&
        activeConversation?.phone_number === fromNumber &&
        action.event.channel_message_id &&
        action.event.channel_message_id !== "" &&
        action.currentUserId &&
        window.trackerQueue
      ) {
        window.trackerQueue.push({
          trackerData: {
            chat_id: activeConversation?.id,
            last_message_read_at_utc: new Date(),
            agent_user_id: action.currentUserId,
            channel_message_id: action.event.channel_message_id,
          },
        });
      }

      if (action.event && fromNumber) {
        if (
          threadsCopy[fromNumber] &&
          !threadsCopy[fromNumber].sentMessages[
            action.event.channel_message_id
          ] &&
          (_.isNull(action.event.wc_id) ||
            !threadsCopy[fromNumber].sentMessages[action.event.wc_id])
        ) {
          if (threadsCopy[fromNumber] && threadsCopy[fromNumber].messages) {
            threadsCopy[fromNumber].last_message = action.event;
            threadsCopy[fromNumber].last_message.created_at_utc = moment(
              new Date(),
            ).toISOString();
            threadsCopy[fromNumber].messages.push({
              messageType:
                action.event.chat_message_type &&
                action.event.chat_message_type !== ""
                  ? action.event.chat_message_type
                  : messageType,
              messageId: action.event.messageId,
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action.event.channel_message_id) {
              threadsCopy[fromNumber].sentMessages[
                action.event.channel_message_id
              ] = 1;
            }
            if (action.event.wc_id) {
              threadsCopy[fromNumber].sentMessages[action.event.wc_id] = 1;
            }
          }
        } else if (
          threadsCopy[fromNumber] &&
          threadsCopy[fromNumber].messages &&
          action.event.wc_id
        ) {
          threadsCopy[fromNumber].messages = _.map(
            threadsCopy[fromNumber].messages,
            (item) => {
              if (
                _.toString(item.wc_id || item.message?.wc_id) ===
                _.toString(action.event.wc_id)
              ) {
                item.message.channel_message_id =
                  action.event.channel_message_id;
              }
              return item;
            },
          );
          if (
            threadsCopy[fromNumber].last_message &&
            _.toString(threadsCopy[fromNumber].last_message.wc_id) ===
              _.toString(action.event.wc_id)
          ) {
            threadsCopy[fromNumber].last_message.channel_message_id =
              action.event.channel_message_id;
          }
        } else if (
          inactivatedThreads[fromNumber] &&
          inactivatedThreads[fromNumber].messages
        ) {
          inactivatedThreads[fromNumber].messages.push({
            messageType:
              action.event.chat_message_type &&
              action.event.chat_message_type !== ""
                ? action.event.chat_message_type
                : messageType,
            messageId: action.event.channel_message_id,
            message: action.event,
            timestamp: new Date(action.event.created_at_utc).getTime(),
          });
        }
      }

      return {
        ...state,
        threads: threadsCopy,
        inactivatedThreads,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_SEND_EVENT_FAILED_MESSAGE: {
      let { isLimitExceeded } = state;
      let { limitExceedErrorCode } = state;
      const threadsCopy = cloneDeep(state.threads);
      if (action.event && action.event.from) {
        fromNumber = sanitizeFromNumber(action.event.from);
        if (threadsCopy[fromNumber]) {
          threadsCopy[fromNumber].messages = threadsCopy[
            fromNumber
          ].messages.map((item) => {
            if (
              item.message.channel_message_id ===
              action.event.channel_message_id
            ) {
              item.message.isNotDelivered = true;

              if (action.event.channel_error_code) {
                item.message.channel_error_code =
                  action.event.channel_error_code;
                item.message.channel_failure_reason =
                  action.event.channel_failure_reason;
              }

              if (
                action.event.channel_error_code &&
                messageErrorCodes.includes(action.event.channel_error_code)
              ) {
                isLimitExceeded = true;
                limitExceedErrorCode = action.event.channel_error_code;
              }
              return item;
            }
            if (item.message.wc_id === action.event.wc_id) {
              item.message.isNotDelivered = true;

              if (action.event.channel_error_code) {
                item.message.channel_error_code =
                  action.event.channel_error_code;
                item.message.channel_failure_reason =
                  action.event.channel_failure_reason;
              }

              if (
                action.event.channel_error_code &&
                messageErrorCodes.includes(action.event.channel_error_code)
              ) {
                isLimitExceeded = true;
                limitExceedErrorCode = action.event.channel_error_code;
              }
              return item;
            }
            return item;
          });
        }
      }
      return {
        ...state,
        threads: threadsCopy,
        toggleForChange: !state.toggleForChange,
        isLimitExceeded,
        limitExceedErrorCode,
      };
    }

    case UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET: {
      const newFromNumber = sanitizeFromNumber(action.event.fromNumber);
      return {
        ...state,
        threads: state.threads[newFromNumber]
          ? {
              ...state.threads,
              [newFromNumber]: {
                ...state.threads[newFromNumber],
                messages: state.threads[newFromNumber].messages.map((item) => {
                  if (item.message.wc_id === action.event.wc_id) {
                    return {
                      ...item,
                      message: {
                        ...(item.message || {}),
                        isNotDelivered: true,
                        channel_error_code:
                          action.event.channel_error_code ||
                          item.message?.channel_error_code,
                        channel_failure_reason:
                          action.event.channel_failure_reason ||
                          item.message?.channel_failure_reason,
                        isBeingRetried: false,
                        rawSocketInput: action.event.rawSocketInput,
                      },
                    };
                  }
                  return item;
                }),
              },
            }
          : state.threads,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_DELIVERY_EVENT_MESSAGE:
      threads = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          threads[fromNumber].messages = _.map(
            threads[fromNumber].messages,
            (item) => {
              if (
                _.toString(item.message.channel_message_id) ===
                _.toString(action.event.channel_message_id)
              ) {
                item.message.delivered_at_utc = action.event.created_at_utc;
                item.message.isNotDelivered = false;

                if (action.event.channel_error_code) {
                  item.message.channel_error_code =
                    action.event.channel_error_code;
                  item.message.channel_failure_reason =
                    action.event.channel_failure_reason;
                }
              }
              return item;
            },
          );
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_SEEN_EVENT_MESSAGE:
      threads = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          if (
            !_.isEmpty(threads[fromNumber].last_message) &&
            threads[fromNumber].last_message.channel_message_id ===
              action.event.channel_message_id
          ) {
            threads[fromNumber].last_message.seen_at_utc =
              action.event.created_at_utc;
            threads[fromNumber].last_message.message_status = "Read";
          }
          threads[fromNumber].messages = threads[fromNumber].messages.map(
            (item) => {
              if (
                item.wc_id &&
                action.event.wc_id &&
                _.toString(item.wc_id) === _.toString(action.event.wc_id)
              ) {
                item.message.seen_at_utc = action.event.created_at_utc;
                item.message.message_status = "Read";
                item.message.isNotDelivered = false;
              }
              if (
                item.message.channel_message_id &&
                action.event.channel_message_id &&
                _.toString(item.message.channel_message_id) ===
                  _.toString(action.event.channel_message_id)
              ) {
                item.message.seen_at_utc = action.event.created_at_utc;
                item.message.message_status = "Read";
                item.message.isNotDelivered = false;

                if (action.event.channel_error_code) {
                  item.message.channel_error_code =
                    action.event.channel_error_code;
                  item.message.channel_failure_reason =
                    action.event.channel_failure_reason;
                }
              }
              return item;
            },
          );
        }

        const activeMessages = threads?.[fromNumber]?.messages;

        const activeChannelMessageId = action?.event?.channel_message_id;

        const recievedMetaDataReaction = action?.event?.meta_data?.reactions;

        if (
          activeMessages?.length > 0 &&
          activeChannelMessageId &&
          Array.isArray(recievedMetaDataReaction)
        ) {
          const selectedMessage = activeMessages.find(
            (item) =>
              item.message.channel_message_id === activeChannelMessageId,
          );

          if (
            selectedMessage?.message &&
            !isEqual(
              selectedMessage.message.reactions,
              recievedMetaDataReaction,
            )
          ) {
            selectedMessage.message.reactions = recievedMetaDataReaction;
          }
        }
      }
      return {
        ...state,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CHAT_CLOSED_MESSAGE: {
      const threadsCopy = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.event.from);
      activeConversation = state.activeConversation;
      if (action.event && fromNumber) {
        if (threadsCopy?.[fromNumber]) {
          if (
            !threadsCopy?.[fromNumber]?.statusMessages[
              action.event.channel_message_id
            ] &&
            !threadsCopy?.[fromNumber]?.statusMessages[action?.event?.wc_id]
          ) {
            threadsCopy[fromNumber].messages.push({
              messageType: "event",
              messageId: action.event.channel_message_id
                ? action.event.channel_message_id
                : "",
              message: action.event,
              timestamp: action.event.created_at_utc
                ? new Date(action.event.created_at_utc).getTime()
                : new Date().getTime(),
            });
            if (action.event.channel_message_id) {
              threadsCopy[fromNumber].statusMessages[
                action.event.channel_message_id
              ] = 1;
            }
            if (action.event.wc_id) {
              threadsCopy[fromNumber].statusMessages[action.event.wc_id] = 1;
            }
            if (
              activeConversation?.phone_number === fromNumber &&
              !activeConversation?.is_closed
            ) {
              activeConversation.is_closed = true;
              threadsCopy[fromNumber].is_closed = true;
            }
          }
        }
      }
      return {
        ...state,
        threads: threadsCopy,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_DOCUMENT_MESSAGE: {
      const threadsCopy = cloneDeep(state.threads);
      activeConversation = state.activeConversation;
      fromNumber = sanitizeFromNumber(action.fromNumber);
      if (fromNumber && threadsCopy[fromNumber] && action.update) {
        if (
          action.isFromSocket &&
          threadsCopy[fromNumber].last_message &&
          threadsCopy[fromNumber].last_message.media_id &&
          _.toString(threadsCopy[fromNumber].last_message.media_id) ===
            _.toString(action.media_id)
        ) {
          threadsCopy[fromNumber].last_message = {
            ...threadsCopy[fromNumber].last_message,
            ...action.update,
          };
        }
        threadsCopy[fromNumber].messages = threadsCopy[fromNumber].messages.map(
          (item) => {
            if (
              _.toString(item.message.media_id) === _.toString(action.media_id)
            ) {
              item.message.isFetchingItem = action.update.isFetchingItem;
              item.message.media_url = action.update.media_url;
              item.message.is_retry_failed = action.update.isRetryFailed;
            }
            return item;
          },
        );
      }
      return {
        ...state,
        threads: threadsCopy,
        toggleForChange: !state.toggleForChange,
        toggleForDocumentChange: !state.toggleForDocumentChange,
      };
    }

    case REMOVE_DOCUMENT_MESSAGE:
      threads = state.threads;
      fromNumber = sanitizeFromNumber(action.fromNumber);
      const previousMessage =
        threads[fromNumber].messages[threads[fromNumber].messages.length - 2];
      if (fromNumber && threads[fromNumber]) {
        if (
          threads[fromNumber].last_message &&
          threads[fromNumber].last_message.media_id &&
          _.toString(threads[fromNumber].last_message.media_id) ===
            _.toString(action.media_id)
        ) {
          threads[fromNumber].last_message = previousMessage.message;
        }
        threads[fromNumber].messages.pop();
      }
      return {
        ...state,
        threads,
        toggleForDocumentChange: !state.toggleForDocumentChange,
      };

    case UPDATE_CHAT_ACTIVATED_MESSAGE:
      chatThreads = state.chatThreads;
      activeConversation = state.activeConversation;
      threads = state.threads;
      inactivatedThreads = state.inactivatedThreads;
      messageType = "event";
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threads[fromNumber]) {
          const oldThread = threads[fromNumber];
          if (
            !_.isEmpty(activeConversation) &&
            sanitizeFromNumber(
              activeConversation?.customer_id.channel_phone_number,
            ) === fromNumber
          ) {
            activeConversation = { ...action.chat };
            activeConversation.phone_number = fromNumber;
          }
          threads[fromNumber] = {
            ...oldThread,
            ...action.chat,
            offset: 0,
            chatPageSize: 100,
            chatTotalCount: 0,
            eventPageNum: 1,
            eventPageSize: 100,
            eventTotalCount: 0,
            notePageNum: 1,
            notePageSize: 100,
            noteTotalCount: 0,
            customerDetails: {},
          };
        } else {
          threads[fromNumber] = {
            ...action.chat,
            offset: 0,
            chatPageSize: 100,
            chatTotalCount: 0,
            eventPageNum: 1,
            eventPageSize: 100,
            eventTotalCount: 0,
            notePageNum: 1,
            notePageSize: 100,
            noteTotalCount: 0,
            last_message_timestamp: new Date(
              action.event.created_at_utc,
            ).getTime(), // change date later on
            messages: [],
            notes: [],
            events: [],
            sentMessages: {},
            statusMessages: {},
          };
        }
        if (
          inactivatedThreads[fromNumber] &&
          inactivatedThreads[fromNumber].messages &&
          inactivatedThreads[fromNumber].messages.length > 0
        ) {
          inactivatedThreads[fromNumber].messages.forEach((item) => {
            threads[fromNumber].messages.push(item);
            if (item.message.channel_message_id) {
              threads[fromNumber].sentMessages[
                item.message.channel_message_id
              ] = 1;
            }
            if (item.message.wc_id) {
              threads[fromNumber].sentMessages[item.message.wc_id] = 1;
            }
          });
        }
        threads[fromNumber].messages.push({
          messageType,
          messageId: action.event.channel_message_id,
          message: action.event,
          timestamp: new Date(action.event.created_at_utc).getTime(),
        });
        if (action.event.id) {
          threads[fromNumber].sentMessages[action.event.id] = 1;
        }
        if (action.event.channel_message_id) {
          threads[fromNumber].sentMessages[action.event.channel_message_id] = 1;
        }
        if (action.event.wc_id) {
          threads[fromNumber].sentMessages[action.event.wc_id] = 1;
        }
        threads[fromNumber].messages = sortArrayWithTimeStamp(
          threads[fromNumber].messages,
        );
        messageLength = threads[fromNumber].messages.length;
        if (messageLength > 1) {
          const chatMessages = threads[fromNumber].messages.filter(
            (item) => item.chat_message_type === "CustomerMessage",
          );
          if (chatMessages && chatMessages.length > 0) {
            action.chat.last_message =
              chatMessages[chatMessages.length - 1].message;
            threads[fromNumber].last_message =
              chatMessages[chatMessages.length - 1].message;
          }
        }
        if (action.chat) {
          chatThreads[action.chat.id] = 1;
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        chatThreads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_CHAT_OPENED_MESSAGE:
      activeConversation = state.activeConversation;
      threads = state.threads;
      messageType = "event";
      fromNumber = sanitizeFromNumber(action.event.from);
      if (action.event && fromNumber) {
        if (threads[fromNumber] && threads[fromNumber].messages) {
          if (
            !threads[fromNumber].sentMessages[
              action.event.channel_message_id
            ] &&
            !threads[fromNumber].sentMessages[action.event.wc_id]
          ) {
            threads[fromNumber].messages.push({
              messageType,
              messageId: action.event.channel_message_id,
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action.event.id) {
              threads[fromNumber].sentMessages[action.event.id] = 1;
            }
            if (action.event.channel_message_id) {
              threads[fromNumber].sentMessages[
                action.event.channel_message_id
              ] = 1;
            }
            if (action.event.wc_id) {
              threads[fromNumber].sentMessages[action.event.wc_id] = 1;
            }

            if (
              activeConversation?.phone_number === fromNumber &&
              activeConversation?.is_closed
            ) {
              activeConversation.is_closed = false;
            }
          }
        }
      }
      return {
        ...state,
        threads,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_AGENT_CHANGE_MESSAGE: {
      const threadsCopy = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.event.from);
      activeConversation = state.activeConversation;
      if (
        !_.isEmpty(activeConversation) &&
        sanitizeFromNumber(
          activeConversation?.customer_id?.channel_phone_number,
        ) === sanitizeFromNumber(fromNumber)
      ) {
        activeConversation.assigned_to_user_id =
          action.event.agent_assigned_user_id;
      }
      if (action?.event && fromNumber) {
        if (threadsCopy && threadsCopy[fromNumber]) {
          if (
            !threadsCopy[fromNumber]?.sentMessages[action?.event.id] &&
            !threadsCopy[fromNumber]?.sentMessages[action?.event.wc_id]
          ) {
            threadsCopy[fromNumber].messages.push({
              messageType: "event",
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action?.event.id) {
              threadsCopy[fromNumber].sentMessages[action.event.id] = 1;
            }
            if (action?.event.wc_id) {
              threadsCopy[fromNumber].sentMessages[action.event.wc_id] = 1;
            }
          }
        }
      }
      return {
        ...state,
        threads: threadsCopy,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL: {
      const threadsCopy = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.event.from);
      activeConversation = state.activeConversation;
      if (
        !_.isEmpty(activeConversation) &&
        sanitizeFromNumber(
          activeConversation?.customer_id.channel_phone_number,
        ) === sanitizeFromNumber(fromNumber)
      ) {
        activeConversation.conversation_label_id =
          action.event.new_conversation_label_id;
      }
      if (action.event && fromNumber) {
        if (threadsCopy[fromNumber]) {
          if (
            !threadsCopy[fromNumber].sentMessages[action.event.id] &&
            !threadsCopy[fromNumber].sentMessages[action.event.wc_id]
          ) {
            threadsCopy[fromNumber].messages.push({
              messageType: "event",
              message: action.event,
              timestamp: new Date(action.event.created_at_utc).getTime(),
            });
            if (action.event.id) {
              threadsCopy[fromNumber].sentMessages[action.event.id] = 1;
            }
            if (action.event.wc_id) {
              threadsCopy[fromNumber].sentMessages[action.event.wc_id] = 1;
            }
          }
        }
      }
      return {
        ...state,
        threads: threadsCopy,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_MESSAGE_THREAD:
      threads = cloneDeep(state.threads);
      fromNumber = action.fromNumber;
      if (!threads[fromNumber]) threads[fromNumber] = {};
      if (fromNumber && threads[fromNumber]) {
        if (!isNaN(action.chatCurrentCount)) {
          threads[fromNumber].chatCurrentCount = action.chatCurrentCount;
        } else {
          threads[fromNumber].chatCurrentCount = action.chatTotalCount;
        }

        if (
          threads[fromNumber].messages &&
          threads[fromNumber].messages.length > 0
        ) {
          if (action.chats.length && action.chats.length > 0) {
            if (!threads[fromNumber].sentMessages) {
              threads[fromNumber].sentMessages = {};
            }
            if (!threads[fromNumber].statusMessages) {
              threads[fromNumber].statusMessages = {};
            }

            action.chats.forEach((item) => {
              if (
                !threads[fromNumber].sentMessages[item.id] &&
                !threads[fromNumber].sentMessages[item.channel_message_id] &&
                !threads[fromNumber].sentMessages[item.wc_id]
              ) {
                threads[fromNumber].messages.unshift({
                  messageType: item.chat_message_type,
                  timestamp: new Date(item.received_at_utc).getTime(),
                  message: item,
                });
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              }
            });
          }

          if (action.notes && action.notes.length > 0) {
            action.notes.forEach((item) => {
              if (
                !threads[fromNumber].sentMessages[item.id] &&
                !threads[fromNumber].sentMessages[item.channel_message_id] &&
                !threads[fromNumber].sentMessages[item.wc_id]
              ) {
                threads[fromNumber].messages.unshift({
                  messageType: "note",
                  timestamp: new Date(item.created_at_utc).getTime(),
                  message: item,
                });
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              }
            });
          }
          if (action.events && action.events.length > 0) {
            action.events.forEach((item) => {
              if (
                !threads[fromNumber].sentMessages[item.id] &&
                !threads[fromNumber].sentMessages[item.channel_message_id] &&
                !threads[fromNumber].sentMessages[item.wc_id]
              ) {
                threads[fromNumber].messages.unshift({
                  messageType: "event",
                  timestamp: new Date(item.created_at_utc).getTime(),
                  message: item,
                });
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              }
            });
          }
        } else {
          threads[fromNumber].messages = [];
          if (!threads[fromNumber].sentMessages) {
            threads[fromNumber].sentMessages = {};
          }
          if (!threads[fromNumber].statusMessages) {
            threads[fromNumber].statusMessages = {};
          }

          if (action.chats && action.chats.length > 0) {
            action.chats.forEach((item) => {
              threads[fromNumber].messages.unshift({
                messageType: item.chat_message_type,
                timestamp: new Date(item.received_at_utc).getTime(),
                message: item,
              });
              if (threads[fromNumber].sentMessages) {
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              } else {
                threads[fromNumber].sentMessages = {};
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              }
            });
          }
          if (action.notes && action.notes.length > 0) {
            action.notes.forEach((item) => {
              threads[fromNumber].messages.unshift({
                messageType: "note",
                timestamp: new Date(item.created_at_utc).getTime(),
                message: item,
              });
              if (threads[fromNumber].sentMessages) {
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              } else {
                threads[fromNumber].sentMessages = {};
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              }
            });
          }
          if (action.events && action.events.length > 0) {
            action.events.forEach((item) => {
              threads[fromNumber].messages.unshift({
                messageType: "event",
                timestamp: new Date(item.created_at_utc).getTime(),
                message: item,
              });

              if (threads[fromNumber].sentMessages) {
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              } else {
                threads[fromNumber].sentMessages = {};
                if (item.id) {
                  threads[fromNumber].sentMessages[item.id] = 1;
                }
                if (item.channel_message_id) {
                  threads[fromNumber].sentMessages[item.channel_message_id] = 1;
                }
                if (item.wc_id) {
                  threads[fromNumber].sentMessages[item.wc_id] = 1;
                }
              }
            });
          }
        }
        threads[fromNumber].messages = sortArrayWithTimeStamp(
          threads[fromNumber].messages,
        );
        threads[fromNumber].notePageNum += 1;
        threads[fromNumber].eventPageNum += 1;
        if (action.eventTotalCount) {
          threads[fromNumber].eventTotalCount = action.eventTotalCount;
        }
        if (action.chatTotalCount) {
          threads[fromNumber].chatTotalCount = action.chatTotalCount;
        }
        if (action.noteTotalCount) {
          threads[fromNumber].noteTotalCount = action.noteTotalCount;
        }
        if (action.chatId) {
          threads[fromNumber].id = action.chatId;
        }
      }

      // Temp optimization removing inactive bundled data
      Object.keys(threads)?.forEach((threadKey) => {
        if (threadKey !== fromNumber) {
          threads[threadKey].messages = [];
          threads[threadKey].noteTotalCount = 0;
          threads[threadKey].eventTotalCount = 0;
          threads[threadKey].sentMessages = {};
        }
      });

      return {
        ...state,
        isFetchingActiveChatMessages: action.isFetchingActiveChatMessages,
        threads,
        toggleForChange: !state.toggleForChange,
      };

    case UPDATE_MESSAGE_THREAD_STATUS:
      return {
        ...state,
        isFetchingActiveChatMessages: action.isFetchingActiveChatMessages,
      };

    case SELECT_FILTER_TYPE:
      const { selectedFilter } = action;
      return {
        ...state,
        selectedFilter,
      };

    case UPDATE_ACTIVE_CHAT_ID:
      return {
        ...state,
        activeFromNumber: action.activeFromNumber,
      };

    case UPDATE_ACTIVE_CHAT:
      return {
        ...state,
        activeConversation: action.activeConversation,
      };

    case UPDATE_LIMIT_EXCEEDED_POPUP_STATE:
      return {
        ...state,
        isLimitExceeded: action.isLimitExceeded,
      };

    case UPDATE_NEW_CONVERSATIONS_WITH_THREAD: {
      const threadsCopy = cloneDeep(state.threads);
      activeConversation = state.activeConversation;
      chatThreads = state.chatThreads;
      if (action.chats && action.chats.length > 0) {
        action.chats.forEach((item) => {
          let phone_number =
            item && item.customer_id && item.customer_id.channel_phone_number
              ? item.customer_id.channel_phone_number.toString()
              : item.customer_id?.channel_account_identifier || "";
          if (phone_number) {
            if (threadsCopy[phone_number]) {
              let oldThread = threadsCopy[phone_number];
              if (
                !_.isEmpty(activeConversation) &&
                sanitizeFromNumber(
                  activeConversation?.customer_id.channel_phone_number,
                ) === phone_number
              ) {
                activeConversation = { ...item };
                activeConversation.phone_number = phone_number;
              }
              threadsCopy[phone_number] = {
                ...oldThread,
                ...item,
                offset: 0,
                chatPageSize: 100,
                chatTotalCount: 0,
                eventPageNum: 1,
                eventPageSize: 100,
                eventTotalCount: 0,
                notePageNum: 1,
                notePageSize: 100,
                noteTotalCount: 0,
                customerDetails: {},
                last_message_timestamp: moment(
                  new Date(item.last_activity_at_utc),
                ),
              };
            } else {
              threadsCopy[phone_number] = {
                ...item,
                offset: 0,
                chatPageSize: 100,
                chatTotalCount: 0,
                eventPageNum: 1,
                eventPageSize: 100,
                eventTotalCount: 0,
                notePageNum: 1,
                notePageSize: 100,
                noteTotalCount: 0,
                sentMessages: {},
                statusMessages: {},
                isResyncNeeded: false,
                customerDetails: {},
                last_message_timestamp: moment(
                  new Date(item.last_activity_at_utc),
                ),
                messages: [],
              };
              item.phone_number =
                item &&
                item.customer_id &&
                item.customer_id.channel_phone_number
                  ? item.customer_id.channel_phone_number.toString()
                  : "";
              item.timestamp = new Date(item.last_activity_at_utc).getTime();
              chatThreads[item.id] = 1;
            }
          }
        });
      }
      return {
        ...state,
        toggleForChange: !state.toggleForChange,
        activeConversation,
        threads: threadsCopy,
        chatThreads,
      };
    }
    case TOGGLE_CHAT_WINDOW:
      return {
        ...state,
        isChatWindowVisible: action.isChatWindowVisible,
      };

    case TOGGLE_USER_DETAIL_VIEW:
      return {
        ...state,
        isUserDetailsViewVisible: action.isUserDetailsViewVisible,
      };

    case UPDATE_CONVERSATION_CLOSED:
      return {
        ...state,
        chatId: action.chatId,
        isChatWindowVisible: action.isChatWindowVisible,
      };

    case ASSIGNING_AGENT_TO_CHAT:
      return {
        ...state,
        isAssigningAgent: action.isAssigningAgent,
        toggleForChange: !state.toggleForChange,
      };

    case CLOSING_CHAT:
      return {
        ...state,
        isClosingChat: action.isClosingChat,
      };

    case UPDATE_AGENT_ASSIGNMENT_MESSAGE: {
      const threadsCopy = cloneDeep(state.threads);
      activeConversation = state.activeConversation;
      if (
        !_.isEmpty(activeConversation) &&
        sanitizeFromNumber(
          activeConversation?.customer_id.channel_phone_number,
        ) === sanitizeFromNumber(action.fromNumber)
      ) {
        activeConversation.assigned_to_user_id =
          action.update.agent_assigned_user_id;
      }
      fromNumber = action.fromNumber;
      if (threadsCopy[action.fromNumber]?.messages) {
        if (
          !threadsCopy[fromNumber].sentMessages[
            action.update.channel_message_id
          ] &&
          !threadsCopy[fromNumber].sentMessages[action.update.wc_id]
        ) {
          threadsCopy[action.fromNumber].messages.push({
            messageType: "event",
            message: action.update,
            timestamp: new Date(action.update.created_at_utc).getTime(),
          });
          if (action.update.channel_message_id) {
            threadsCopy[fromNumber].sentMessages[
              action.update.channel_message_id
            ] = 1;
          }
          if (action.update.wc_id) {
            threadsCopy[fromNumber].sentMessages[action.update.wc_id] = 1;
          }
        }
      }
      return {
        ...state,
        threads: threadsCopy,
        activeConversation,
        toggleForChange: !state.toggleForChange,
      };
    }

    case ASSIGNED_AGENT_TO_CHAT:
      return {
        ...state,
        isAssigningAgent: action.isAssigningAgent,
      };

    case UPDATE_CUSTOMER_DETAILS_CHAT:
      return {
        ...state,
        sentMessages: {},
        statusMessages: {},
      };

    case MARK_MESSAGE_AS_BEING_RETRIED: {
      const threadsCopy = cloneDeep(state.threads);
      threadsCopy[action.fromNumber].messages = threadsCopy[
        action.fromNumber
      ].messages.map((item) => {
        if (item.message.wc_id === action.wc_id) {
          item.message.isBeingRetried = true;
        }
        return item;
      });
      return {
        ...state,
        threads: threadsCopy,
        toggleForChange: !state.toggleForChange,
      };
    }

    case UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID:
      return {
        ...state,
        selectedCustomerFromNumber: action.selectedCustomerFromNumber,
      };

    case UPDATE_INBOX_SYNC_MOBILE:
      const resetData = action.data;
      return {
        ...state,
        selectedFilter: resetData.selectedFilter,
        selectedChatType: resetData.selectedChatType,
        selectedSort: resetData.selectedSort,
        isChatWindowVisible: resetData.isChatWindowVisible,
      };

    case SET_NOTIFICATION_RIBBON:
      return {
        ...state,
        showNotificationRibbon: action.showNotificationRibbon,
      };

    case EMPTY_MESSAGE_THREAD:
      threads = {};
      chatThreads = {};
      return {
        ...state,
        threads,
        chatThreads,
      };

    case ASSIGNING_ACTIVE_CONVERSATION_LABEL: {
      return {
        ...state,
        activeConversation: {
          ...state.activeConversation,
          conversation_label_id: action.payload.conversationLabelId,
        },
      };
    }

    case CLEAR_ACTIVE_CONVERSATION_LABEL:
      return {
        ...state,
        activeConversation: {
          ...state.activeConversation,
          conversation_label_id: null,
        },
      };

    case UPDATE_EVENT_COUNT: {
      const threadsCopy = cloneDeep(state.threads);
      fromNumber = sanitizeFromNumber(action.payload?.from);
      if (fromNumber) {
        if (threadsCopy[fromNumber]) {
          // update events count
          threadsCopy[fromNumber].eventTotalCount =
            threadsCopy[fromNumber].eventTotalCount + 1;
        }
      }

      return { ...state, threads: threadsCopy };
    }
    case CLOSE_SEARCH_SECTION:
      // reset chat selection
      return {
        ...state,
        activeConversation:
          state?.threads && Object.values(state?.threads)?.[0],
      };

    case UPDATE_SPAM_CHAT_STATUS: {
      const updatedMessages =
        action?.payload.chat_id &&
        action.payload?.wc_id &&
        state.threads[action.payload?.fromNumber]?.messages
          ? [
              ...state.threads[action.payload.fromNumber].messages,
              {
                messageType: "event",
                message: action.payload,
                timestamp: new Date(action.payload.created_at_utc).getTime(),
              },
            ]
          : state.threads[action.payload.fromNumber]?.messages || [];

      const updateActiveConversation = () => {
        if (action?.payload.chat_id === state?.activeConversation?.id) {
          return {
            ...state.activeConversation,
            is_spam: action.payload.is_spam,
            messages: updatedMessages,
          };
        }

        return state.activeConversation;
      };

      return {
        ...state,
        threads: {
          ...state.threads,
          [action.payload.fromNumber]: {
            ...state.threads[action.payload.fromNumber],
            messages: updatedMessages,
            is_spam: action.payload.is_spam,
            MultiImage: false,
          },
        },
        activeConversation: updateActiveConversation(),
      };
    }

    case TOOGLE_SPAM_CHAT_START:
      return { ...state, spam: { toogleSpamLoading: true } };

    case TOOGLE_SPAM_CHAT_SUCCESS:
    case TOOGLE_SPAM_CHAT_FAILURE:
      return { ...state, spam: { toogleSpamLoading: false } };

    case UPDATE_NEW_CUSTOMER_MESSAGES_DATA:
      return {
        ...state,
        isNewCustomerMessage: false,
        newCustomerMessagesCount: 0,
      };

    case UPDATE_IS_NEW_CUSTOMER_MESSAGES:
      return {
        ...state,
        isNewCustomerMessage: false,
      };

    case RESET_FILTER:
      return {
        ...state,
        selectedChatType: initialState.selectedChatType,
        selectedSort: initialState.selectedSort,
      };

    case DELETE_MESSAGE_FROM_THREAD: {
      const channelIdentifier = action.payload.from;

      const updatedMessages = state.threads?.[
        channelIdentifier
      ]?.messages?.filter((item) => {
        return item?.message?.channel_message_id !== action.payload?.id;
      });

      if (channelIdentifier) {
        return {
          ...state,
          threads: {
            ...state.threads,
            [channelIdentifier]: {
              ...state.threads[channelIdentifier],
              messages: updatedMessages,
            },
          },
        };
      }

      return { ...state };
    }

    case UPDATE_CHAT_MABA_STATUS: {
      const threadsCopy = { ...state.threads };

      if (threadsCopy[action.payload.channelIdentifier]) {
        threadsCopy[action.payload.channelIdentifier] = {
          ...threadsCopy[action.payload.channelIdentifier],
          maba_chat_status: action.payload.status,
        };
      }

      if (
        action.payload.channelIdentifier ===
        state.activeConversation?.customer_id?.channel_phone_number
      ) {
        return {
          ...state,
          activeConversation: {
            ...state.activeConversation,
            maba_chat_status: action.payload.status,
            threads: threadsCopy,
          },
        };
      }

      return { ...state, threads: threadsCopy };
    }

    default:
      return { ...state };
  }
};

export default inboxReducer;
export const getAllThreads = (state) => state.thread.threads;
export const getActiveConversation = (state) => state.thread.activeConversation;
export const getActiveConversationChatId = (state) =>
  state.thread.activeConversation ? state.thread.activeConversation?.id : "";
export const getActiveCustomerId = (state) =>
  state.thread.activeConversation &&
  state.thread.activeConversation?.customer_id &&
  state.thread.activeConversation?.customer_id.id
    ? state.thread.activeConversation?.customer_id.id
    : "";
export const getActiveFromNumber = (state) => state.thread.activeFromNumber;
export const getCustomerPageActiveFromNumber = (state) =>
  state.thread.selectedCustomerFromNumber;
export const getActiveThreads = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber]
    : {};
export const getActiveCustomerDetailsFromThreads = (state) =>
  state.thread.activeFromNumber && !_.isEmpty(state.thread.threads)
    ? state.thread.threads[state.thread.activeFromNumber].customerDetails
    : {};
export const getCustomerActiveThreads = (state) =>
  state.thread.selectedCustomerFromNumber
    ? state.thread.threads[state.thread.selectedCustomerFromNumber]
    : [];
export const getInActiveThreads = (state) => state.thread.inactivatedThreads;
export const getIfAgentIsAssigned = (state) =>
  state.thread.activeConversation &&
  state.thread.activeConversation?.assigned_to_user_id &&
  state.thread.activeConversation?.assigned_to_user_id !== "";
// export const getActiveThreads = (state) => state.thread.threads['9818130272'];
export const getActiveChatMessagesFetchStatus = (state) =>
  state.thread.isFetchingActiveChatMessages;
export const getActiveThreadsCount = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].messages
    ? state.thread.threads[state.thread.activeFromNumber].messages.length
    : 0;
export const getCustomerActiveThreadsCount = (state) =>
  state.thread.selectedCustomerFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.selectedCustomerFromNumber] &&
  state.thread.threads[state.thread.selectedCustomerFromNumber].messages
    ? state.thread.threads[state.thread.selectedCustomerFromNumber].messages
        .length
    : 0;

export const getIfReachedAllChats = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads &&
  state.thread.threads[state.thread.activeFromNumber]
    ? state.thread.threads[state.thread.activeFromNumber].chatCurrentCount >=
      state.thread.threads[state.thread.activeFromNumber].chatTotalCount
    : false;
// export const getActiveThreadsCount = (state) => state.thread.activeFromNumber && state.thread.threads && state.thread.threads[state.thread.activeFromNumber] ? state.thread.threads[state.thread.activeFromNumber].length : [];
export const getWidgetFetchingState = (state) =>
  state.thread.isFetchingWidgetInfo;
export const getChatWindowVisibleState = (state) =>
  state.thread.isChatWindowVisible;
export const getAllChatLoadedState = (state) =>
  state.thread.isActiveChatLoaded && state.thread.isClosedChatLoaded;
export const getUserDetailsViewVisibleState = (state) =>
  state.thread.isUserDetailsViewVisible;

// chats
export const getChatToggleEvent = (state) => state.thread.toggleForChange;
export const getChatToggleDocumentEvent = (state) =>
  state.thread.toggleForDocumentChange;

// filters
export const getFilterOptions = (state) => state.thread.filterOptions;
export const getSelectedFilter = (state) => state.thread.selectedFilter;
export const getChatOptions = (state) => state.thread.chatOptions;
export const getSelectedSortOption = (state) => state.thread.selectedSort;
export const getSortOptions = (state) => state.thread.sortOptions;
export const getClosingChatState = (state) => state.thread.isClosingChat;

export const getChatAssingmentState = (state) => state.thread.isAssigningAgent;
export const getSentMessageStore = (state) => state.thread.sentMessages;
export const getFirstLoadStatus = (state) => state.thread.isActiveChatLoaded;

export const getOnlyCustomerChats = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].messages; // ? state.thread.threads[state.thread.activeFromNumber].messages.filter(item => item.messageType === 'CustomerMessage') : [];
export const getCustomerPageOnlyCustomerChats = (state) =>
  state.thread.selectedCustomerFromNumber &&
  state.thread.threads[state.thread.selectedCustomerFromNumber] &&
  state.thread.threads[state.thread.selectedCustomerFromNumber].messages; // ? state.thread.threads[state.thread.activeFromNumber].messages.filter(item => item.messageType === 'CustomerMessage') : [];
export const getResyncingState = (state) =>
  state.thread.activeFromNumber &&
  state.thread.threads[state.thread.activeFromNumber] &&
  state.thread.threads[state.thread.activeFromNumber].isResyncNeeded; // ? state.thread.threads[state.thread.activeFromNumber].messages.filter(item => item.messageType === 'CustomerMessage') : [];

// Todo refactor
//export const getShowNotificationRibbon = state => state.inbox.showNotificationRibbon && (state.app.organizations && state.app.organizations[0] && state.app.organizations[0].organization_id && state.app.organizations[0].organization_id.onboarding_status ? state.app.organizations[0].organization_id.onboarding_status === onboardingStatuses.ONBOARDED : false) && state.app.isAuthenticated || (!!state.payment.activeSubscriptionList ? state.payment.activeSubscriptionList.length === 0 : false);

export const checkIfUserHasNotificationPermission = () => {
  if (!("Notification" in window)) {
    return false;
  }

  if (Notification.permission === "granted") return false;
  return true;
};

export const getShowNotificationRibbon = (state) => {
  return (
    state.thread.showNotificationRibbon &&
    (state.app.organizations &&
    state.app.organizations[0] &&
    state.app.organizations[0].organization_id &&
    state.app.organizations[0].organization_id.onboarding_status
      ? state.app.organizations[0].organization_id.onboarding_status ===
        onboardingStatuses.ONBOARDED
      : false) &&
    state.app.isAuthenticated
  );
};

export const getIsLimitExceeded = (state) => state.thread.isLimitExceeded;
export const getLimitExceededErrorCode = (state) =>
  state.thread.limitExceedErrorCode;
