import { useQuery } from "@tanstack/react-query";
import { INT_API_PROFILE } from "constants/mixPanel/api";
import useOrganizationId from "customHooks/useOrganizationId";
import { memoizePromiseFn } from "useApi/utility";
import { callApiV2 } from "util/apiCaller";
import { Mixpanel } from "util/tracking";
import { getFullName } from "util/utility";

export const RQ_USER = "RQ_USER";

const fetchUserProfile = async (orgId) => {
  Mixpanel.track(INT_API_PROFILE, {});

  const res = await callApiV2({
    method: "get",
    endpoint: `/v1/organizations/${orgId}/profile/`,
  });

  if (res.result) {
    return res.data;
  }

  throw new Error(res.message);
};

export const cachedFetchProfile = memoizePromiseFn(fetchUserProfile);

const useUser = (select = (data) => data, options = {}) => {
  const orgId = useOrganizationId();

  return useQuery([RQ_USER, orgId], () => cachedFetchProfile(orgId), {
    placeholderData: {},
    ...options,
    select,
    enabled:
      options.enabled !== undefined ? !!options.enabled && !!orgId : !!orgId,
  });
};

// selectors
export const selectFullName = (data) =>
  getFullName(data.first_name, data.last_name);
export const selectFirstName = (data) => data.first_name;
export const selectUserId = (user) => user.id;
export const selectFeatures = (user) => user.permissions?.features;
export const selectRole = (user) => user.permissions?.role;
export const selectUserEmail = (user) => user.email;

export const selectFeatureCodes = (user) =>
  user.permissions?.features
    ?.map((feature) => (feature.is_enabled ? feature.feature_code : null))
    .filter((feature) => !!feature);
export const selectHasAnyPermission = (shouldHavePermissions = []) => (
  user,
) => {
  const userPermissions = selectFeatureCodes(user);

  // ? No permission is required
  if (!shouldHavePermissions || shouldHavePermissions.length === 0) {
    return true;
  }

  // ? case when user permission is falsy
  if (!userPermissions) {
    return false;
  }

  // ? return true if user has any permission provided in shouldHavePermissions
  for (let i = 0; i < shouldHavePermissions.length; i += 1) {
    if (userPermissions.includes(shouldHavePermissions[i])) {
      return true;
    }
  }

  return false;
};

export const selectHasAnyRole = (shouldHaveRoles = []) => (user) => {
  const userRole = selectRole(user);

  // ? No roles is required
  if (!shouldHaveRoles || shouldHaveRoles.length === 0) {
    return true;
  }

  // ? case when user role is falsy
  if (!userRole) {
    return false;
  }

  // ? return true if user has any role provided in shouldHaveRoles
  for (let i = 0; i < shouldHaveRoles.length; i += 1) {
    if (userRole === shouldHaveRoles[i]) {
      return true;
    }
  }

  return false;
};

export default useUser;
