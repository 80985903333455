export const PAYMENT_OPTIONS = [
  {
    label: "Razorpay",
    value: "razorpay",
  },
  {
    label: "PayU",
    value: "payu",
  },
];

export const GOODS_OPTIONS = [
  {
    label: "Digital Goods",
    value: "digital-goods",
  },
  {
    label: "Physical Goods",
    value: "physical-goods",
  },
];

export const DEFAULT_ORDER_STATUS_TEMPLATE_MESSAGE =
  "Your payment of {{amount}} has been received.";

export const PAYMENT_CONFIG_LENGTH = 60;

export const TEMPLATE_MESSAGE_LENGTH = 1024;

export const ORDER_STATUS_TEMPLATE_NAME = "payment_confirmation_whatsapp_pay";

export const ORDER_STATUS_TEMPLATE_PAYLOAD = {
  display_name: ORDER_STATUS_TEMPLATE_NAME,
  language: "en",
  category: "UTILITY",
  sub_category: "ORDER_STATUS",
  template_category_label: null,
  body: "",
  body_text: null,
  footer: null,
  buttons: "[]",
};

export const WHATSAPP_PAY_PENDING_TEXT =
  "WhatsApp Pay will take some time to go live for your account, because the payment_confirmation template is taking time to get approved. Once the template has been approved & the message is live, we will inform you via email and also here on your dashboard.";

export const WHATSAPP_PAY_REJECTED_TEXT =
  "WhatsApp Pay could not be enabled for your account, because the payment_confirmation template has gotten rejected. Please edit the template from WhatsApp Manager section of your Facebook Business account. The template should only have a ‘Body’ and within the body, there can be only 1 variable. The currency & amount will be substituted in place of that variable. After having edited the template there, wait for the template to get approved. Once it gets approved, come back to Interakt’s Templates page, click on Sync. After Sync gets over, click on the Submit button below.";

export const MESSAGE_REGEX = /\{\{\w*\}\}/g;
