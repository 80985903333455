import React from "react";

function inboxV2({ fill, ...props }) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3333 10H13.3333L11.6667 12.5H8.33334L6.66667 10H1.66667"
        stroke={fill}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.54167 4.25834L1.66667 10V15C1.66667 15.442 1.84227 15.866 2.15483 16.1785C2.46739 16.4911 2.89131 16.6667 3.33334 16.6667H16.6667C17.1087 16.6667 17.5326 16.4911 17.8452 16.1785C18.1577 15.866 18.3333 15.442 18.3333 15V10L15.4583 4.25834C15.3204 3.98067 15.1077 3.74699 14.8441 3.58358C14.5806 3.42017 14.2767 3.33351 13.9667 3.33334H6.03334C5.72327 3.33351 5.41939 3.42017 5.15588 3.58358C4.89236 3.74699 4.67965 3.98067 4.54167 4.25834V4.25834Z"
        stroke={fill}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default inboxV2;
