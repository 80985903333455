import * as Sentry from "@sentry/react";

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  FETCH_CHAT_LIST,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_DETAILS,
} from "../Customer/CustomerActions";

import { addToast } from "../App/ToastActions";
import callApi, { callApiV2 } from "../../util/apiCaller";
import {
  CLOSE_CHAT_IN_NEW_CONVERSATION,
  REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION,
  UPDATE_IS_UPDATING_NEW_CONVERSATION,
  UPDATE_SINGLE_CONVERSATION,
} from "./ConversationActions";
import { resetMultiSelect } from "./inboxStateActions";
import { getSelectedOrganizationId } from "modules/App/AppSelectors";
import log from "util/logger";
import { showNotification } from "util/utility";
import { parseJSON } from "util/jsonUtility";
import { getUrlParams } from "./component/InboxFilter/utility";
import { OPEN } from "constants/inboxfilterConstants";

// api actions
export const UPDATE_ACTIVE_CHAT = "UPDATE_ACTIVE_CHAT";
export const UPDATE_ACTIVE_CHAT_ID = "UPDATE_ACTIVE_CHAT_ID";

export const UPDATE_CONVERSATION_LIST = "UPDATE_CONVERSATION_LIST";
export const UPDATE_CONVERSATION_CLOSED = "UPDATE_CONVERSATION_CLOSED";

export const FETCHING_CHAT_EVENTS = "FETCHING_CHAT_EVENTS";
export const FETCH_CHAT_EVENTS = "FETCH_CHAT_EVENTS";

export const FETCHING_CHAT_NOTES = "FETCHING_CHAT_NOTES";
export const FETCH_CHAT_NOTES = "FETCH_CHAT_NOTES";

export const CREATING_CHAT_NOTE = "CREATING_CHAT_NOTE";
export const CREATED_CHAT_NOTE = "CREATED_CHAT_NOTE";

export const UPDATING_CHAT_NOTE = "UPDATING_CHAT_NOTE";
export const UPDATE_CHAT_NOTE = "UPDATE_CHAT_NOTE";

export const FETCHING_CHAT_NOTE = "FETCHING_CHAT_NOTE";
export const FETCH_CHAT_NOTE = "FETCH_CHAT_NOTE";

export const UPDATE_MESSAGE_THREAD = "UPDATE_MESSAGE_THREAD";

// socket relevant actions
export const UPDATE_BROADCAST_MESSAGE = "UPDATE_BROADCAST_MESSAGE";
export const UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE";
export const UPDATE_CHAT_EVENT_MESSAGE = "UPDATE_CHAT_EVENT_MESSAGE";
export const UPDATE_NOTE_MESSAGE = "UPDATE_NOTE_MESSAGE";
export const UPDATE_SEND_EVENT_MESSAGE = "UPDATE_SEND_EVENT_MESSAGE";
export const UPDATE_SEND_EVENT_FAILED_MESSAGE =
  "UPDATE_SEND_EVENT_FAILED_MESSAGE";
export const UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET =
  "UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET";
export const UPDATE_DELIVERY_EVENT_MESSAGE = "UPDATE_DELIVERY_EVENT_MESSAGE";
export const UPDATE_SEEN_EVENT_MESSAGE = "UPDATE_SEEN_EVENT_MESSAGE";
export const UPDATE_CHAT_CLOSED_MESSAGE = "UPDATE_CHAT_CLOSED_MESSAGE";
export const UPDATE_CHAT_ACTIVATED_MESSAGE = "UPDATE_CHAT_ACTIVATED_MESSAGE";
export const UPDATE_CHAT_OPENED_MESSAGE = "UPDATE_CHAT_OPENED_MESSAGE";
export const UPDATE_AGENT_CHANGE_MESSAGE = "UPDATE_AGENT_CHANGE_MESSAGE";
export const UPDATE_AGENT_LEFT_MESSAGE = "UPDATE_AGENT_LEFT_MESSAGE";
export const ASSIGNING_AGENT_TO_CHAT = "ASSIGNING_AGENT_TO_CHAT";
export const ASSIGNED_AGENT_TO_CHAT = "ASSIGNED_AGENT_TO_CHAT";
export const EMPTY_MESSAGE_THREAD = "EMPTY_MESSAGE_THREAD";
export const UPDATE_MESSAGE_THREAD_STATUS = "UPDATE_MESSAGE_THREAD_STATUS";

export const SELECT_FILTER_TYPE = "SELECT_FILTER_TYPE";
export const TOGGLE_CHAT_WINDOW = "TOGGLE_CHAT_WINDOW";
export const TOGGLE_USER_DETAIL_VIEW = "TOGGLE_USER_DETAIL_VIEW";

export const UPDATE_DOCUMENT_MESSAGE = "UPDATE_DOCUMENT_MESSAGE";
export const REMOVE_DOCUMENT_MESSAGE = "REMOVE_DOCUMENT_MESSAGE";

export const UPDATE_AGENT_ASSIGNMENT_MESSAGE =
  "UPDATE_AGENT_ASSIGNMENT_MESSAGE";

export const CLOSING_CHAT = "CLOSING_CHAT";
export const CLOSE_CHAT = "CLOSE_CHAT";

export const UPDATE_CUSTOMER_DETAILS_CHAT = "UPDATE_CUSTOMER_DETAILS_CHAT";

export const UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID =
  "UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID";
export const UPDATE_INBOX_SYNC = "UPDATE_INBOX_SYNC";

export const UPDATE_INBOX_SYNC_MOBILE = "UPDATE_INBOX_SYNC_MOBILE";
export const UPDATE_BROWSER_NOTIFICATION = "UPDATE_BROWSER_NOTIFICATION";
export const SET_NOTIFICATION_RIBBON = "SET_NOTIFICATION_RIBBON";

export const MARK_MESSAGE_AS_BEING_RETRIED = "MARK_MESSAGE_AS_BEING_RETRIED";

export const UPDATE_NEW_CONVERSATIONS_WITH_THREAD =
  "UPDATE_NEW_CONVERSATIONS_WITH_THREAD";
export const UPDATE_FILTER_OPTIONS = "UPDATE_FILTER_OPTIONS";
export const UPDATE_LIMIT_EXCEEDED_POPUP_STATE =
  "UPDATE_LIMIT_EXCEEDED_POPUP_STATE";

export const ASSIGNING_ACTIVE_CONVERSATION_LABEL =
  "ASSIGNING_ACTIVE_CONVERSATION_LABEL";
export const ASSIGNED_CONVERSATION_LABEL = "ASSIGNED_CONVERSATION_LABEL";
export const UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL =
  "UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL";

export const CLEAR_ACTIVE_CONVERSATION_LABEL =
  "CLEAR_ACTIVE_CONVERSATION_LABEL";

export const UPDATE_EVENT_COUNT = "UPDATE_EVENT_COUNT";
export const TOOGLE_SPAM_CHAT_START = "TOOGLE_SPAM_CHAT_START";
export const TOOGLE_SPAM_CHAT_SUCCESS = "TOOGLE_SPAM_CHAT_SUCCESS";
export const TOOGLE_SPAM_CHAT_FAILURE = "TOOGLE_SPAM_CHAT_FAILURE";
export const UPDATE_SPAM_CHAT_STATUS = "UPDATE_SPAM_CHAT_STATUS";

export const UPDATE_NEW_CUSTOMER_MESSAGES_DATA =
  "UPDATE_NEW_CUSTOMER_MESSAGES_DATA";
export const UPDATE_IS_NEW_CUSTOMER_MESSAGES =
  "UPDATE_IS_NEW_CUSTOMER_MESSAGES";

export const DELETE_MESSAGE_FROM_THREAD = "DELETE_MESSAGE_FROM_THREAD";

export const UPDATE_CHAT_MABA_STATUS = "UPDATE_CHAT_MABA_STATUS";

function getPaginatedOffset(page, size) {
  return (page - 1) * size;
}

function getPages(count, pagesize) {
  if (count > 0) {
    return Math.ceil(count / pagesize);
  }
  return 0;
}

export function updateInboxFilterOptions(filterOptions) {
  return {
    type: UPDATE_FILTER_OPTIONS,
    filterOptions,
  };
}

export function updateChatActivation(
  organizationId,
  event,
  updateInNewConversation,
) {
  // todo here
  return (dispatch) => {
    callApi(
      `v1/organizations/${organizationId}/chats/${event.chat_id}/`,
      "get",
    ).then((res) => {
      if (res) {
        dispatch({
          type: UPDATE_CHAT_ACTIVATED_MESSAGE,
          event,
          chat: res.data,
        });
        if (updateInNewConversation) {
          dispatch({
            type: UPDATE_SINGLE_CONVERSATION,
            chat: res.data,
          });
        }
      }
    });
  };
}

export function updateChatOpen(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_OPENED_MESSAGE,
      event,
    });
  };
}

export function markMessageAsBeingTried(fromNumber, wc_id) {
  return (dispatch) => {
    dispatch({
      type: MARK_MESSAGE_AS_BEING_RETRIED,
      fromNumber,
      wc_id,
    });
  };
}

export function updateChatClosure(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_CLOSED_MESSAGE,
      event,
    });
  };
}

export function updateChatSentEvent(
  phone_number,
  event,
  currentUserId,
  isNewCustomerMessage = false,
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEND_EVENT_MESSAGE,
      phone_number,
      event,
      currentUserId,
      isNewCustomerMessage,
    });
  };
}

export function updateChatDeliveryFailed(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEND_EVENT_FAILED_MESSAGE,
      event,
    });
  };
}

export function updateChatDeliveryFailedAtSocket(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEND_EVENT_FAILED_MESSAGE_AT_SOCKET,
      event,
    });
  };
}

export function updateChatSeen(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEEN_EVENT_MESSAGE,
      event,
    });
  };
}

export function updateChatDelivery(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DELIVERY_EVENT_MESSAGE,
      event,
    });
  };
}

export function updateNoteMessage(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTE_MESSAGE,
      event,
    });
  };
}

export function updateChatEvent(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_EVENT_MESSAGE,
      event,
    });
  };
}

export function updateChatMessage(phone_number, event, currentUserId) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_MESSAGE,
      phone_number,
      event,
      currentUserId,
    });
  };
}

export function updateConversations(phone_number, event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CONVERSATION_LIST,
      event,
    });
  };
}

export function updateAgentChange(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_CHANGE_MESSAGE,
      event,
    });
  };
}

export function updateNewMessageConversationLabel(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER_CONVERSATION_FOR_LABEL,
      event,
    });
  };
}

export function updateAgentLeft(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_LEFT_MESSAGE,
      event,
    });
  };
}

export function assignChatToAgent(
  organizationId,
  chatId,
  agentId,
  webClientId,
  fromNumber,
  isUnassignedFilter,
) {
  return (dispatch) => {
    dispatch({
      type: ASSIGNING_AGENT_TO_CHAT,
      isAssigningAgent: true,
    });
    if (isUnassignedFilter) {
      dispatch({
        type: UPDATE_IS_UPDATING_NEW_CONVERSATION,
        value: true,
      });
    }
    return callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/actions/`,
      "put",
      {
        action: "assign",
        agentId,
        wcId: webClientId,
      },
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: ASSIGNED_AGENT_TO_CHAT,
          isAssigningAgent: false,
          chatId,
          agentId,
        });
        if (isUnassignedFilter) {
          dispatch({
            type: REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION,
            value: false,
            event: { from: fromNumber },
          });
        }
      } else {
        dispatch({
          type: UPDATE_IS_UPDATING_NEW_CONVERSATION,
          value: false,
        });
      }
    });
  };
}

export function unAssignChatToAgent(organizationId, chatId, webClientId) {
  return (dispatch) =>
    callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/actions/`,
      "put",
      {
        action: "unassign",
        wcId: webClientId,
      },
    ).then((res) => {
      dispatch(addToast(res.message, "success"));
      if (res.result) {
      }
    });
}

export const multiAssignAndMultiClose = (payload) => (dispatch, getState) => {
  const organizationId = getSelectedOrganizationId(getState());

  const urlFilters = parseJSON(getUrlParams(window.location.search, "filter"));

  const selectedChatType = urlFilters?.chatStatus || OPEN;

  callApi(`v1/organizations/${organizationId}/chats/actions/bulk/`, "put", {
    ...payload,
    wc_id: uuidv4(),
  })
    .then((result) => {
      if (result.status_code === 200) {
        showNotification("success", result.message);
        dispatch(resetMultiSelect());
        if (payload.action === "close") {
          // todo reset all customer tags and stuff
          dispatch({
            type: FETCH_CHAT_LIST,
            chatList: [],
            isFetchingChatList: false,
          });
          dispatch({
            type: FETCH_CUSTOMER,
            activeCustomer: {},
            isFetchingCustomer: false,
          });
          dispatch({
            type: FETCH_CUSTOMER_DETAILS,
            customerDetails: {},
            isFetchingCustomerDetails: false,
          });
          payload.chat_ids.forEach((chatId) => {
            dispatch({
              type: UPDATE_CONVERSATION_CLOSED,
              chatId,
              isChatWindowVisible: false,
            });
            if (selectedChatType !== "Close") {
              dispatch({
                type: CLOSE_CHAT_IN_NEW_CONVERSATION,
                chatId,
              });
            }
          });
        }
      } else {
        showNotification("error", result.message);
      }
    })
    .catch((error) => {
      showNotification("error", error.message);
    });
};

export function closeChat(
  organizationId,
  chatId,
  webClientId,
  agentId,
  isOpenFilter,
) {
  return (dispatch) => {
    dispatch({
      type: CLOSING_CHAT,
      isClosingChat: true,
    });
    if (isOpenFilter) {
      dispatch({
        type: UPDATE_IS_UPDATING_NEW_CONVERSATION,
        value: true,
      });
    }

    return callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/actions/`,
      "put",
      {
        action: "close",
        agentId,
        wcId: webClientId,
      },
    ).then((res) => {
      dispatch({
        type: CLOSING_CHAT,
        isClosingChat: false,
      });
      if (res.result) {
        showNotification("success", "Chat closed successfully");
        // todo reset all customer tags and stuff
        dispatch({
          type: FETCH_CHAT_LIST,
          chatList: [],
          isFetchingChatList: false,
        });
        dispatch({
          type: FETCH_CUSTOMER,
          activeCustomer: {},
          isFetchingCustomer: false,
        });
        dispatch({
          type: FETCH_CUSTOMER_DETAILS,
          customerDetails: {},
          isFetchingCustomerDetails: false,
        });
        dispatch({
          type: UPDATE_CONVERSATION_CLOSED,
          chatId,
          isChatWindowVisible: false,
        });
        if (isOpenFilter) {
          dispatch({
            type: CLOSE_CHAT_IN_NEW_CONVERSATION,
            chatId,
          });
        }
      } else {
        dispatch({
          type: CLOSING_CHAT,
          isClosingChat: false,
        });
        dispatch({
          type: UPDATE_IS_UPDATING_NEW_CONVERSATION,
          value: false,
        });
      }
    });
  };
}

export const assignActiveConversationLabel = (
  conversationLabelId,
  activeConversationId,
) => ({
  type: ASSIGNING_ACTIVE_CONVERSATION_LABEL,
  payload: {
    conversationLabelId,
    activeConversationId,
  },
});

export const clearConversationLabel = (activeConversationId) => ({
  type: CLEAR_ACTIVE_CONVERSATION_LABEL,
  activeConversationId,
});

export function sortArrayWithTimeStamp(array) {
  if (_.isArray(array)) {
    return array.sort((obj1, obj2) => obj1.timestamp - obj2.timestamp);
  }
}

export function checkIfPageLimitCrossed(pageNum, pageSize, count) {
  return pageNum * pageSize >= count;
}

export function selectActiveChat(activeConversation) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_CHAT,
      activeConversation,
    });
  };
}

export function selectActiveChatId(activeFromNumber) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_CHAT_ID,
      activeFromNumber,
    });
  };
}

export function selectCustomerPageFromNumber(selectedCustomerFromNumber) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_CUSTOMER_PAGE_CHAT_ID,
      selectedCustomerFromNumber,
    });
  };
}

export function selectFilter(val) {
  return (dispatch) => {
    dispatch({
      type: SELECT_FILTER_TYPE,
      selectedFilter: val,
    });

    return Promise.resolve();
  };
}

export function toggleChatWindow(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_CHAT_WINDOW,
      isChatWindowVisible: !!val,
    });
  };
}

export function toggleUserDetailsView(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_USER_DETAIL_VIEW,
      isUserDetailsViewVisible: !!val,
    });
  };
}

export function getInboxMessageFilters(sinceUtc, untilUtc, pageNum, pageSize) {
  let text = ``;
  if (pageSize) {
    text += `?limit=${pageSize}`;
  }
  if (sinceUtc) {
    text += `&sinceUtc=${sinceUtc}`;
  }
  if (untilUtc) {
    text += `&untilUtc=${untilUtc}`;
  }
  if (pageSize && pageNum) {
    text += `&offset=${getPaginatedOffset(pageNum, pageSize)}`;
  }
  return text;
}

export function getInboxEventsAndNotesFilter(sinceUtc, untilUtc) {
  let text = `?limit=1000&offset=0`;
  if (sinceUtc) {
    text += `&sinceUtc=${sinceUtc}`;
  }
  if (untilUtc) {
    text += `&untilUtc=${untilUtc}`;
  }
  return text;
}

// M[] -- 1000, E[] 5-10, N[] 10-20
// nodejs api <--> java upi api
// today messages to and end --> events [] timestamp --> [],[]
export function fetchAllMessagesForChatId(
  organizationId,
  chatId,
  fromNumber,
  offset = 0,
  limit = 100,
) {
  let chatLimit = limit;

  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_MESSAGE_THREAD_STATUS,
      isFetchingActiveChatMessages: true,
    });

    const isSearchSectionVisible = getState()?.inboxState.searchSection
      .isSearchSectionVisible;

    if (isSearchSectionVisible) {
      chatLimit = 400;
    }

    try {
      const response = await callApi(
        `v1/organizations/${organizationId}/chats/${chatId}/bundled-conversation/?limit=${chatLimit}&offset=${offset}`,
      );

      if (response.results && response.results.data) {
        dispatch({
          type: UPDATE_MESSAGE_THREAD,
          fromNumber,
          chats: response.results.data.chat_messages,
          chatTotalCount: response.results.data.chat_messages_count,
          events: response.results.data.chat_events,
          eventTotalCount: response.results.data.chat_events_count,
          notes: response.results.data.chat_notes,
          noteTotalCount: response.results.data.chat_notes_count,
          isFetchingActiveChatMessages: false,
          chatId,
          chatCurrentCount: parseInt(
            response?.next?.split("?")?.["1"]?.split("&")?.["1"]?.split("=")?.[
              "1"
            ],
            10,
          ),
        });
      } else {
        dispatch({
          type: UPDATE_MESSAGE_THREAD_STATUS,
          isFetchingActiveChatMessages: false,
        });

        showNotification("error", response.message);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_THREAD_STATUS,
        isFetchingActiveChatMessages: false,
      });
      showNotification("error", "Something went wrong, contact support!");

      Sentry.captureException(error);
    }
  };
}

export function getChatsForChatThreadById(
  organizationId,
  chatId,
  timeTill,
  pageNum,
  pageSize,
) {
  return (dispatch) =>
    callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/messages/?receivedAtUtc=${timeTill}&limit=${pageSize}&offset=${getPaginatedOffset(
        pageNum,
        pageSize,
      )}`,
      "get",
    ).then((res) => {
      dispatch(addToast(res.message));
      if (res.result) {
        // dispatch({
        //     type: SELECT_WIDGET,
        //     selectedWidget: res.data
        // });
      }
    });
}

export function getNoteForChat(organizationId, chatId, noteId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_CHAT_NOTES,
      isFetchingClosedChats: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/notes/${noteId}/`,
      "get",
    ).then((res) => {
      if (res) {
        dispatch({
          type: FETCH_CHAT_NOTES,
          isFetchingClosedChats: false,
          closedThreads: res.data ? res.data : [],
        });
      } else {
        dispatch({
          type: FETCHING_CHAT_NOTES,
          isFetchingClosedChats: false,
        });
      }
    });
  };
}

export function updateNoteForChat(organizationId, chatId, noteId, text) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_CHAT_NOTES,
      isFetchingClosedChats: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/notes/${noteId}/`,
      "put",
      {
        note: text,
      },
    ).then((res) => {
      if (res) {
        dispatch({
          type: FETCH_CHAT_NOTES,
          isFetchingClosedChats: false,
          closedThreads: res.data ? res.data : [],
        });
      } else {
        dispatch({
          type: FETCHING_CHAT_NOTES,
          isFetchingClosedChats: false,
        });
      }
    });
  };
}

export function updateDocumentMessage(fromNumber, media_id, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DOCUMENT_MESSAGE,
      fromNumber,
      media_id,
      update,
    });
  };
}

export function removeDocumentMessage(fromNumber, media_id) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_DOCUMENT_MESSAGE,
      fromNumber,
      media_id,
    });
  };
}

export function updateAgentAssignment(fromNumber, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_ASSIGNMENT_MESSAGE,
      fromNumber,
      update,
    });
  };
}

export function createNoteForChat(organizationId, chatId, wcId, text) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_CHAT_NOTES,
      isFetchingClosedChats: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/chats/${chatId}/notes/`,
      "post",
      {
        note: text,
        wc_id: wcId,
      },
    ).then((res) => {
      if (res) {
        dispatch({
          type: FETCH_CHAT_NOTES,
          isFetchingClosedChats: false,
          closedThreads: res.data ? res.data : [],
        });
      } else {
        dispatch({
          type: FETCHING_CHAT_NOTES,
          isFetchingClosedChats: false,
        });
      }
    });
  };
}

export function retryMediaDownloadForMessage({
  organizationId,
  channelId,
  fromNumber,
  messageId,
  mediaId,
  isFromSocket = false,
  isErrorSuppressed = false,
}) {
  return (dispatch) => {
    const beforeupdate = {};
    beforeupdate.isFetchingItem = true;
    beforeupdate.media_url = "";
    dispatch(updateDocumentMessage(fromNumber, mediaId, beforeupdate));
    return callApi(
      `v1/organizations/${organizationId}/channels/${channelId}/message/${messageId}/`,
      "get",
      undefined,
      isErrorSuppressed,
    ).then((res) => {
      const update = {};
      update.isFromSocket = isFromSocket;
      if (res.result && res["media-url"]) {
        update.isFetchingItem = false;
        update.media_url = res["media-url"];
        update.isRetryFailed = false;
      } else {
        update.isFetchingItem = false;
        update.media_url = "";
        update.isRetryFailed = true;
      }
      dispatch(updateDocumentMessage(fromNumber, mediaId, update));
    });
  };
}

export function updateCustomerDetailsInChats(customerId, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER_DETAILS_CHAT,
      customerId,
      update,
    });
  };
}

export function setInboxSystem(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_INBOX_SYNC_MOBILE,
      data,
    });
  };
}

export function setNotificationRibbon(val) {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_RIBBON,
      showNotificationRibbon: val,
    });
  };
}

export function updateChatThreadsFromNewConversations(chats) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEW_CONVERSATIONS_WITH_THREAD,
      chats,
    });
  };
}

export function updateLimitExceededPopupState(val) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LIMIT_EXCEEDED_POPUP_STATE,
      isLimitExceeded: val,
    });
  };
}

export const updateEventCount = (payload) => (dispatch) =>
  dispatch({
    type: UPDATE_EVENT_COUNT,
    payload,
  });

export const toogleChatSpamStatus = ({ action, chatId }) => async (
  dispatch,
  getState,
) => {
  const organizationId = getState().app?.organizations[0]?.organization_id.id;
  const wcId = uuidv4();

  dispatch({
    type: TOOGLE_SPAM_CHAT_START,
  });

  try {
    await callApiV2({
      endpoint: `v1/organizations/${organizationId}/chats/${chatId}/spam/`,
      method: "PUT",
      body: {
        action,
        wcId,
      },
    });
    dispatch({
      type: TOOGLE_SPAM_CHAT_SUCCESS,
    });
  } catch (error) {
    showNotification("error", error.message);

    dispatch({
      type: TOOGLE_SPAM_CHAT_FAILURE,
    });
  }
};

export const updateChatSpamStatus = (data) => ({
  type: UPDATE_SPAM_CHAT_STATUS,
  payload: data,
});

export const updateNewCustomerMessagesData = () => ({
  type: UPDATE_NEW_CUSTOMER_MESSAGES_DATA,
});

export const updateIsNewCustomerMessage = () => ({
  type: UPDATE_IS_NEW_CUSTOMER_MESSAGES,
});

export const deleteMessageFromThread = (payload) => ({
  type: DELETE_MESSAGE_FROM_THREAD,
  payload,
});

export const updateChatMabaStatus = (payload) => ({
  type: UPDATE_CHAT_MABA_STATUS,
  payload,
});
