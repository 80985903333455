import {
  PAYU,
  RAZORPAY,
  WHATSAPP_PAY,
  ZOCKET,
} from "modules/BotFlows/constants/Templates/AutoCheckout";
import { valueWithFallbackOnProd } from "util/envUtil";
import { openInNewTab } from "util/locationUtil";
import currencyCodes from "constants/currencyCodes";
import currencySymbols from "constants/currencySymbols";

const currencyToBeIncluded = new Set(["INR", "USD", "EUR"]);

const filteredCurrencyCode = currencyCodes
  ?.filter((cur) => currencyToBeIncluded?.has(cur.value))
  ?.map((cur) => ({
    ...cur,
    symbol: currencySymbols[cur.value] || cur.value,
    code: cur.value,
    value: `${cur.label} (${cur.value})`,
  }));

const defaultFormFields = [
  {
    key: "currency_dropdown",
    type: "dropdown",
    value: filteredCurrencyCode[0],
    options: filteredCurrencyCode,
    label: "Choose Currency",
    fieldName: "currency",
    valueFieldName: "code",
    parse: "string",
  },
  {
    key: "amount",
    type: "number",
    value: "",
    label: "Enter Amount",
    fieldName: "amount",
    parse: "float",
  },
];

const razorpaySpecificFields = [
  {
    key: "payment_type",
    type: "radio",
    value: "true",
    options: [
      {
        label: "UPI Links",
        value: "true",
      },
      {
        label: "Standard Payment links",
        value: "false",
      },
    ],
    label: "Choose payment link type",
    know_more: "https://razorpay.com/docs/payments/payment-links/",
    fieldName: "upi",
    parse: "bool",
  },
];

const appsList = [
  {
    id: "08d20f6e-266a-4fea-a296-4018f0868ac3",
    imageName: "whatsapp",
    appName: WHATSAPP_PAY,
    title: "WhatsApp Pay",
    type: "payment",
    subtitle: "Payment Provider",
    description:
      "Create a frictionless payment experience on WhatsApp to improve conversions",
    videoUrl: "https://youtu.be/PtgOloBOe9M",
    connected: false,
    disabled: false,
    inprogress: false,
    buttonText: "Connect",
    toggleNextStepsModal: () => {},
    supportNextStep: true,
    handleClick: () => {},
  },
  {
    id: "ea7b89ba-f69c-4ec8-834f-c0342b38be6c",
    imageName: "razorpay",
    appName: RAZORPAY,
    title: "Razorpay",
    type: "payment",
    subtitle: "Payment Provider",
    description:
      "When customers send WhatsApp carts, send payment links automatically in a checkout botflow",
    videoUrl: "https://youtu.be/HkV2bv3kK8s",
    connected: false,
    disabled: false,
    buttonText: "Connect to Interakt",
    toggleNextStepsModal: () => {},
    supportNextStep: true,
    formFields: [...defaultFormFields, ...razorpaySpecificFields],
    handleClick: () => {},
    links: {
      automatic_notification: "https://youtu.be/HkV2bv3kK8s",
      webhook_setup: "https://razorpay.com/docs/webhooks/setup-edit-payments/",
      dashboard: "",
    },
  },
  {
    id: "e18f4ffc-058b-48e5-b3ad-3ab0ce52313f",
    imageName: "payu",
    title: "PayU",
    appName: PAYU,
    type: "payment",
    subtitle: "Payment Provider",
    disabled: false,
    inprogress: false,
    isv_url: valueWithFallbackOnProd(
      "https://payu-integration.hellohaptik.com/payu/complete-oauth",
      "https://payu-integration.interakt.ai/payu/complete-oauth",
    ),
    description:
      "When customers send WhatsApp carts, send payment links automatically in a auto-checkout workflow",
    videoUrl: "https://www.youtube.com/watch?v=Wt7kd50IDss",
    connected: false,
    buttonText: "Connect to Interakt",
    toggleNextStepsModal: null,
    supportNextStep: true,
    formFields: defaultFormFields,
    handleClick: () => {},
    links: {
      automatic_notification:
        "http://www.interakt.shop/resource-center/how-to-connect-your-payu-account-with-interakt",
      webhook_setup:
        "http://www.interakt.shop/resource-center/how-to-connect-your-payu-account-with-interakt",
      dashboard: valueWithFallbackOnProd(
        "https://dashboard-staging.payu.in/business/settings/webhooks",
        "https://www.payu.in/business/settings/webhooks",
      ),
    },
  },
  {
    id: "ddcd8e27-9975-4588-b3a6-aba711f7b00e",
    appName: ZOCKET,
    title: "Zocket",
    connected: false,
  },
  {
    id: "10",
    imageName: "pabbly",
    appName: "pabbly",
    title: "Pabbly Connect",
    subtitle: "Connector Platform",
    description:
      "Send WhatsApp notifications on triggers from any software in the world",
    videoUrl: "",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab("https://www.pabbly.com/connect/integrations/interakt/");
    },
  },
  {
    id: "1",
    imageName: "google-sheets",
    appName: "google-sheets",
    title: "Google Sheets",
    subtitle: "Data Storage",
    description:
      "Send automatic WhatsApp notifications whenever a new row is added in a sheet",
    videoUrl: "https://www.youtube.com/watch?v=nDUhzfsul2w",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab(
        "https://www.interakt.shop/whatsapp-integration-for-google-sheets",
      );
    },
  },
  {
    id: "2",
    imageName: "shopify",
    appName: "shopify-sales-channel",
    title: "Shopify Sales Channel",
    subtitle: "e-Commerce Platform",
    description: "Auto-sync Shopify products & collections to WhatsApp",
    videoUrl: "https://www.youtube.com/watch?v=f3hnH7_fG8s",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab("https://apps.shopify.com/whatsapp-sales-channel");
    },
  },
  {
    id: "3",
    imageName: "shopify",
    appName: "shopify-marketting",
    title: "Shopify Marketing",
    subtitle: "e-Commerce Platform",
    description:
      "Send automatic WhatsApp notifications to recover abandoned carts, confirm CoD Orders & much more",
    videoUrl: "https://www.youtube.com/watch?v=7yANoF2P5SM",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab("https://apps.shopify.com/whatsapp-chat-api");
    },
  },
  {
    id: "4",
    imageName: "woo-commerce",
    appName: "woo-commerce",
    title: "Woo Commerce",
    subtitle: "e-Commerce Platform",
    description:
      "Send automatic WhatsApp notifications to recover abandoned carts, confirm orders & much more",
    videoUrl: "",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab(
        "https://wordpress.org/plugins/abandoned-checkout-recovery-order-notifications-for-woocommerce/",
      );
    },
  },
  {
    id: "5",
    imageName: "fb-leads",
    appName: "fb-leads-forms",
    title: "Facebook Lead Forms",
    subtitle: "Ads Platform",
    description:
      "As soon as a lead fills the form, add them in Interakt & send an automatic welcome notification",
    videoUrl: "",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab(
        "https://docs.google.com/forms/d/e/1FAIpQLSfDdz5SFwTC1swtHIjGP7Q2RGA9i0y17Hv5-Dk9j7ow8WGTMg/viewform",
      );
    },
  },
  {
    id: "6",
    imageName: "webengage",
    appName: "webengage",
    title: "Webengage",
    subtitle: "Marketing Automation",
    description:
      "Send WhatsApp notifications via Interakt, in your Webengage campaigns & journeys",
    videoUrl: "",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab(
        "https://www.interakt.shop/resource-center/how-to-integrate-whatsapp-business-api-with-webengage",
      );
    },
  },
  {
    id: "7",
    imageName: "integromat",
    appName: "integromat",
    title: "Integromat",
    subtitle: "Connector Platform",
    description:
      "Send WhatsApp notifications on triggers from any software in the world",
    videoUrl: "",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab("https://www.interakt.shop/blog/connecting-integromat");
    },
  },
  {
    id: "9",
    imageName: "zapier",
    appName: "zapier",
    title: "Zapier",
    subtitle: "Connector Platform",
    description:
      "Send WhatsApp notifications on triggers from any software in the world",
    videoUrl: "",
    connected: false,
    buttonText: "View Details",
    handleClick: () => {
      openInNewTab("https://www.interakt.shop/blog/connecting-zapier");
    },
  },
  {
    id: "11",
    imageName: "zoho-crm",
    appName: "Zoho CRM",
    title: "Zoho CRM",
    subtitle: "CRM Platform",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon user/lead creation or updation in Zoho",
    connected: false,
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-zoho-crm/",
    buttonText: "Connect to Interakt",
    modalDetails: {
      title: "Interakt - Zoho CRM integration",
      useCases: [
        "Automatically send a welcome template when a new user signs up on Zoho",
        "Automatically send a template when a user's details are updated on Zoho",
        "Automatically create a new user on Interakt when a user is created on Zoho",
        "Automatically update the user information on Interakt when a user is updated on Zoho",
        "Automatically send a template when a new deal is created on Zoho",
        "Automatically send a template when a deal is updated on Zoho",
      ],
      usdCharges: 8,
      inrCharges: 699,
      details:
        "Use this only if you are on Professional and above Zoho CRM plans",
      chargesNotes: [
        "The charges are for using the Interakt <> Zoho CRM integration app only.",
        "You can create as many Interakt <> Zoho CRM workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Zoho CRM' integration will be included in the above charges.",
    },
  },
  {
    id: "12",
    imageName: "hubspot",
    appName: "HubSpot",
    title: "HubSpot",
    subtitle: "CRM Platform",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon user/lead creation or updation in Hubspot",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-hubspot/",
    modalDetails: {
      title: "Interakt - HubSpot CRM integration",
      useCases: [
        "Automatically send a template when a new ticket is created on HubSpot",
        "Automatically send a template when a new contact is created on HubSpot",
        "Automatically create a new contact on Interakt when a new contact is added on HubSpot",
        "Automatically update a contact on Interakt when a contact is updated on HubSpot",
        "Automatically send a template when a new deal is created on HubSpot",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> HubSpot CRM integration app only.",
        "You can create as many Interakt <> HubSpot CRM workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Hubspot CRM' integration will be included in the above charges.",
    },
  },
  {
    id: "13",
    imageName: "salesforce",
    appName: "Salesforce",
    title: "Salesforce",
    subtitle: "CRM Platform",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon user/lead creation or updation in Salesforce",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-salesforce/",
    modalDetails: {
      title: "Interakt - Salesforce integration",
      useCases: [
        "Automatically send a template when a new opportunity is created on Salesforce",
        "Automatically send a template when a opportunity is updated on Salesforce",
        "Automatically send a template when a new account is created on Salesforce",
        "Automatically send a template when a new lead is created on Salesforce",
        "Automatically send a template when a new contact is created on Salesforce",
        "Automatically create a new contact on Interakt when a new contact is added on Salesforce",
        "Automatically update a contact on Interakt when a contact is updated on Salesforce",
        "Automatically send a template when a lead is updated on Salesforce",
        "Automatically send a template when a account is updated on Salesforce",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Salesforce CRM integration app only.",
        "You can create as many Interakt <> Salesforce CRM workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Salesforce CRM' integration will be included in the above charges.",
    },
  },
  {
    id: "14",
    imageName: "stripe",
    appName: "Stripe",
    title: "Stripe",
    subtitle: "Payment Provider",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon Payment status updates on Stripe",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-business-api-integration-with-stripe/",
    modalDetails: {
      title: "Interakt - Stripe integration",
      useCases: [
        "Automatically send a template when a payment is Failed",
        "Automatically send a template when a New Subscription is started",
        "Automatically send a template when a Subscription is ended",
        "Automatically create a new customer on Interakt when a new customer is added on Stripe",
        "Automatically update a customer on Interakt when a customer is updated on Stripe",
        "Automatically send a template when a Refund is initiated",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Stripe integration app only.",
        "You can create as many Interakt <> Stripe workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Stripe' integration will be included in the above charges.",
    },
  },
  {
    id: "15",
    imageName: "freshdesk",
    appName: "Freshdesk v2",
    title: "Freshdesk v2",
    subtitle: "Helpdesk Platform",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon Ticket creation or updation on Freshdesk",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-freshdesk/",
    modalDetails: {
      title: "Interakt - Freshdesk v2 integration",
      useCases: [
        "When a New Ticket is Created/Updated, Create/Update User on Interakt",
        "When a New Ticket is Created/Updated, Send a WhatsApp Template via Interakt",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Freshdesk v2 integration app only.",
        "You can create as many Interakt <> Freshdesk v2 workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Freshdesk v2' integration will be included in the above charges.",
    },
  },
  {
    id: "16",
    imageName: "zoho-billing",
    appName: "Zoho Billing",
    title: "Zoho Billing",
    subtitle: "Billing Platform",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon Subscription/Invoice creation or updation on Zoho Billing",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-zoho-billing/",
    modalDetails: {
      title: "Interakt - Zoho Billing integration",
      useCases: [
        "When a New Subscription is Created on Zoho Billing, Send a WhatsApp Template via Interakt",
        "When a Subscription is Activated,Cancelled, Send a WhatsApp Template via Interakt",
        "When a New Invoice is generated, Send a WhatsApp Template via Interakt",
        "When Subscription Billing date is Updated/Expires, Send a WhatsApp Template via Interakt",
        "When a Customer is Created/Updated on Zoho Billing, Create/Update User on Interakt",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Zoho Billing integration app only.",
        "You can create as many Interakt <> Zoho Billing workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Zoho Billing' integration will be included in the above charges.",
    },
  },
  {
    id: "17",
    imageName: "zoho-bigin",
    appName: "Zoho Bigin CRM",
    title: "Zoho Bigin CRM",
    subtitle: "CRM Platform",
    description:
      "Create/Update users on Interakt and Send automatic WhatsApp notifications upon Contact/Company creation or updation on Zoho Billing",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-zoho-bigin/",
    modalDetails: {
      title: "Interakt - Zoho Bigin CRM integration",
      useCases: [
        "When a Contact/Company is Created/Updated, Send a WhatsApp Template via Interakt",
        "When a Contact is Created/Updated, Create/Update User on Interakt",
        "When a New User is Created on Interakt, Create a Contact on Zoho Bigin CRM",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Zoho Bigin CRM integration app only.",
        "You can create as many Interakt <> Zoho Bigin CRM workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Zoho Bigin CRM' integration will be included in the above charges.",
    },
  },
  {
    id: "18",
    imageName: "judgeme",
    appName: "Judge.me",
    title: "Judge.me",
    subtitle: "Product Review Platform",
    description:
      "Create/Update a Review on Judge.me when a customer completes a Feedback workflow on Interakt",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-judgeme/",
    modalDetails: {
      title: "Interakt - Judge.me integration",
      useCases: [
        "When a Feedback Workflow is Completed on Interakt, Create/Update Review on Judge.me",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Judge.me integration app only.",
        "You can create as many Interakt <> Judge.me workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Judge.me' integration will be included in the above charges.",
    },
  },
  {
    id: "19",
    imageName: "freshworkscrm",
    appName: "Freshworks CRM",
    title: "Freshworks CRM",
    subtitle: "CRM Platform",
    description:
      "Freshworks CRM is a platform for sales and marketing people to sell faster and market smarter.",
    videoUrl: "",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-freshworks-crm/",
    modalDetails: {
      title: "Interakt - Freshworks CRM integration",
      useCases: [
        "When a Contact is Created/Updated on Freshworks, Create/Update User on Interakt.",
        "When a User is Created/Updated on Interakt, Create/Update contact on Freshworks CRM.",
        "When a New Contact is Created on Freshworks CRM, Send a Template.",
        "When a New Account is Created on Freshworks CRM, Send a Template.",
        "When a New Deal is Created on Freshworks CRM, Send a Template.",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Freshworks CRM integration app only.",
        "You can create as many Interakt <> Freshworks CRM workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Freshworks CRM' integration will be included in the above charges.",
    },
  },
  {
    id: "20",
    imageName: "calendly",
    appName: "Calendly",
    title: "Calendly",
    subtitle: "Scheduling Automation Platform",
    description:
      "Calendly is an online meeting scheduling platform that helps find the perfect time for a meet.",
    videoUrl: "",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-calendly/",
    modalDetails: {
      title: "Interakt - Calendly integration",
      useCases: [
        "When an Invitee is Created, Create an User on Interakt.",
        "When an Invitee is Cancelled, Update an User on Interakt.",
        "When an Invitee is Created, Send a Template.",
        "When an Invitee is Cancelled, Send a Template.",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Calendly integration app only.",
        "You can create as many Interakt <> Calendly workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Calendly' integration will be included in the above charges.",
    },
  },
  {
    id: "21",
    imageName: "zohobooks",
    appName: "Zoho Books",
    title: "Zoho Books",
    subtitle: "Billing Platform",
    description:
      "Zoho Books is an accounting software for businesses to help in book-keeping, billing, invoicing, bank reconciliation, accounting etc",
    videoUrl: "",
    connected: false,
    buttonText: "Connect to Interakt",
    knowMoreUrl:
      "https://www.interakt.shop/resource-center/whatsapp-api-integration-with-zoho-books/",
    modalDetails: {
      title: "Interakt - Zoho Books integration",
      useCases: [
        "When a Customer is Created/Updated on Zoho Books, Create/Update User on Interakt.",
        "When a New Expense is Created, Send a Template.",
        "When a New Bill is Created, Send a Template.",
        "When a New Invoice is Created, Send a Template.",
        "When a New Vendor is Created, Send a Template.",
        "When a New Customer Payment is Created, Send a Template.",
      ],
      usdCharges: 8,
      inrCharges: 699,
      chargesNotes: [
        "The charges are for using the Interakt <> Zoho Books integration app only.",
        "You can create as many Interakt <> Zoho Books workflows as desired.",
        "You will receive an invoice for the charges on your registered email id soon.",
      ],
      note:
        "All future enhancements in 'Interakt <> Zoho Books' integration will be included in the above charges.",
    },
  },
];

export const isPaymentRelatedApp = (appName) =>
  ["razorpay", "payu"].includes(appName.toLowerCase());

export default appsList;
