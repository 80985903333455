import { geIsInteraktDomain } from "./domain";

export const INTERAKT_DOMAIN = "INTERAKT_DOMAIN";
export const GENERIC_DOMAIN = "GENERIC_DOMAIN";
export const PARTNER_SPECIFIC_DOMAIN = "PARTNER_SPECIFIC_DOMAIN";

const GENERIC_DOMAIN_HOSTS = [
  "appdev.whatsappbiz.com",
  "app.whatsappbiz.com",
  "appstaging.whatsappbiz.com",
];

const getCurrentDomainType = () => {
  const currentHost = window.location.host;
  // ? localStorageDomainType is kept for testing and debugging purposes
  const localStorageDomainType = localStorage.getItem("domainType");

  if (
    GENERIC_DOMAIN_HOSTS.includes(currentHost) ||
    localStorageDomainType === GENERIC_DOMAIN
  ) {
    return GENERIC_DOMAIN;
  }

  if (
    geIsInteraktDomain(currentHost) &&
    localStorageDomainType !== PARTNER_SPECIFIC_DOMAIN
  ) {
    return INTERAKT_DOMAIN;
  }

  return PARTNER_SPECIFIC_DOMAIN;
};

export default getCurrentDomainType;
