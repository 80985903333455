// Main filter
export const ALL = "All";
export const YOU = "You";
export const UNASSIGNED = "Unassigned";
export const SPAM = "Spam";

// Chat type
export const OPEN = "Open";
export const CLOSE = "Close";

// Sort Type
export const OLDEST = "Oldest";
export const NEWEST = "Newest";

// Assigne Type
export const ASSIGNE_UNSASSIGNED = "unassigned";
