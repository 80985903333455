const currencyCodes = [
  {
    label: "Indian Rupee",
    value: "INR",
  },
  {
    label: "US Dollar",
    value: "USD",
  },
  {
    label: "Afghani",
    value: "AFN",
  },
  {
    label: "Lek",
    value: "ALL",
  },
  {
    label: "Algerian Dinar",
    value: "DZD",
  },
  {
    label: "Kwanza",
    value: "AOA",
  },
  {
    label: "Argentine Peso",
    value: "ARS",
  },
  {
    label: "Armenian Dram",
    value: "AMD",
  },
  {
    label: "Aruban Florin",
    value: "AWG",
  },
  {
    label: "Azerbaijan Manat",
    value: "AZN",
  },
  {
    label: "Bahamian Dollar",
    value: "BSD",
  },
  {
    label: "Bahraini Dinar",
    value: "BHD",
  },
  {
    label: "Taka",
    value: "BDT",
  },
  {
    label: "Barbados Dollar",
    value: "BBD",
  },
  {
    label: "Belarusian Ruble",
    value: "BYN",
  },
  {
    label: "Belize Dollar",
    value: "BZD",
  },
  {
    label: "Bermudian Dollar",
    value: "BMD",
  },
  {
    label: "Ngultrum",
    value: "BTN",
  },
  {
    label: "Boliviano",
    value: "BOB",
  },
  {
    label: "Mvdol",
    value: "BOV",
  },
  {
    label: "Convertible Mark",
    value: "BAM",
  },
  {
    label: "Pula",
    value: "BWP",
  },
  {
    label: "Brazilian Real",
    value: "BRL",
  },
  {
    label: "Brunei Dollar",
    value: "BND",
  },
  {
    label: "Bulgarian Lev",
    value: "BGN",
  },
  {
    label: "Burundi Franc",
    value: "BIF",
  },
  {
    label: "Cabo Verde Escudo",
    value: "CVE",
  },
  {
    label: "Riel",
    value: "KHR",
  },
  {
    label: "Canadian Dollar",
    value: "CAD",
  },
  {
    label: "Cayman Islands Dollar",
    value: "KYD",
  },
  {
    label: "Chilean Peso",
    value: "CLP",
  },
  {
    label: "Unidad de Fomento",
    value: "CLF",
  },
  {
    label: "Yuan Renminbi",
    value: "CNY",
  },
  {
    label: "Colombian Peso",
    value: "COP",
  },
  {
    label: "Unidad de Valor Real",
    value: "COU",
  },
  {
    label: "Comorian Franc",
    value: "KMF",
  },
  {
    label: "Congolese Franc",
    value: "CDF",
  },
  {
    label: "Costa Rican Colon",
    value: "CRC",
  },
  {
    label: "Kuna",
    value: "HRK",
  },
  {
    label: "Cuban Peso",
    value: "CUP",
  },
  {
    label: "Peso Convertible",
    value: "CUC",
  },
  {
    label: "Czech Koruna",
    value: "CZK",
  },
  {
    label: "Djibouti Franc",
    value: "DJF",
  },
  {
    label: "Dominican Peso",
    value: "DOP",
  },
  {
    label: "Egyptian Pound",
    value: "EGP",
  },
  {
    label: "El Salvador Colon",
    value: "SVC",
  },
  {
    label: "Nakfa",
    value: "ERN",
  },
  {
    label: "Lilangeni",
    value: "SZL",
  },
  {
    label: "Ethiopian Birr",
    value: "ETB",
  },
  {
    label: "Falkland Islands Pound",
    value: "FKP",
  },
  {
    label: "Fiji Dollar",
    value: "FJD",
  },
  {
    label: "CFA Franc BEAC",
    value: "XAF",
  },
  {
    label: "Dalasi",
    value: "GMD",
  },
  {
    label: "Lari",
    value: "GEL",
  },
  {
    label: "Ghana Cedi",
    value: "GHS",
  },
  {
    label: "Gibraltar Pound",
    value: "GIP",
  },
  {
    label: "Danish Krone",
    value: "DKK",
  },
  {
    label: "Quetzal",
    value: "GTQ",
  },
  {
    label: "Guinean Franc",
    value: "GNF",
  },
  {
    label: "Guyana Dollar",
    value: "GYD",
  },
  {
    label: "Gourde",
    value: "HTG",
  },
  {
    label: "Lempira",
    value: "HNL",
  },
  {
    label: "Hong Kong Dollar",
    value: "HKD",
  },
  {
    label: "Forint",
    value: "HUF",
  },
  {
    label: "Iceland Krona",
    value: "ISK",
  },
  {
    label: "Rupiah",
    value: "IDR",
  },
  {
    label: "SDR (Special Drawing Right)",
    value: "XDR",
  },
  {
    label: "Iranian Rial",
    value: "IRR",
  },
  {
    label: "Iraqi Dinar",
    value: "IQD",
  },
  {
    label: "New Israeli Sheqel",
    value: "ILS",
  },
  {
    label: "Jamaican Dollar",
    value: "JMD",
  },
  {
    label: "Yen",
    value: "JPY",
  },
  {
    label: "Jordanian Dinar",
    value: "JOD",
  },
  {
    label: "Tenge",
    value: "KZT",
  },
  {
    label: "Kenyan Shilling",
    value: "KES",
  },
  {
    label: "North Korean Won",
    value: "KPW",
  },
  {
    label: "Won",
    value: "KRW",
  },
  {
    label: "Kuwaiti Dinar",
    value: "KWD",
  },
  {
    label: "Som",
    value: "KGS",
  },
  {
    label: "Lao Kip",
    value: "LAK",
  },
  {
    label: "Lebanese Pound",
    value: "LBP",
  },
  {
    label: "Loti",
    value: "LSL",
  },
  {
    label: "Liberian Dollar",
    value: "LRD",
  },
  {
    label: "Libyan Dinar",
    value: "LYD",
  },
  {
    label: "Pataca",
    value: "MOP",
  },
  {
    label: "Denar",
    value: "MKD",
  },
  {
    label: "Malagasy Ariary",
    value: "MGA",
  },
  {
    label: "Malawi Kwacha",
    value: "MWK",
  },
  {
    label: "Malaysian Ringgit",
    value: "MYR",
  },
  {
    label: "Rufiyaa",
    value: "MVR",
  },
  {
    label: "Ouguiya",
    value: "MRU",
  },
  {
    label: "Mauritius Rupee",
    value: "MUR",
  },
  {
    label: "ADB Unit of Account",
    value: "XUA",
  },
  {
    label: "Mexican Peso",
    value: "MXN",
  },
  {
    label: "Mexican Unidad de Inversion (UDI)",
    value: "MXV",
  },
  {
    label: "Moldovan Leu",
    value: "MDL",
  },
  {
    label: "Tugrik",
    value: "MNT",
  },
  {
    label: "Mozambique Metical",
    value: "MZN",
  },
  {
    label: "Kyat",
    value: "MMK",
  },
  {
    label: "Namibia Dollar",
    value: "NAD",
  },
  {
    label: "Nepalese Rupee",
    value: "NPR",
  },
  {
    label: "Cordoba Oro",
    value: "NIO",
  },
  {
    label: "Naira",
    value: "NGN",
  },
  {
    label: "Rial Omani",
    value: "OMR",
  },
  {
    label: "Pakistan Rupee",
    value: "PKR",
  },
  {
    label: "Balboa",
    value: "PAB",
  },
  {
    label: "Kina",
    value: "PGK",
  },
  {
    label: "Guarani",
    value: "PYG",
  },
  {
    label: "Sol",
    value: "PEN",
  },
  {
    label: "Philippine Peso",
    value: "PHP",
  },
  {
    label: "Zloty",
    value: "PLN",
  },
  {
    label: "Qatari Rial",
    value: "QAR",
  },
  {
    label: "Romanian Leu",
    value: "RON",
  },
  {
    label: "Russian Ruble",
    value: "RUB",
  },
  {
    label: "Rwanda Franc",
    value: "RWF",
  },
  {
    label: "Saint Helena Pound",
    value: "SHP",
  },
  {
    label: "East Caribbean Dollar",
    value: "XCD",
  },
  {
    label: "Tala",
    value: "WST",
  },
  {
    label: "Dobra",
    value: "STN",
  },
  {
    label: "Saudi Riyal",
    value: "SAR",
  },
  {
    label: "Serbian Dinar",
    value: "RSD",
  },
  {
    label: "Seychelles Rupee",
    value: "SCR",
  },
  {
    label: "Leone",
    value: "SLL",
  },
  {
    label: "Leone",
    value: "SLE",
  },
  {
    label: "Singapore Dollar",
    value: "SGD",
  },
  {
    label: "Netherlands Antillean Guilder",
    value: "ANG",
  },
  {
    label: "Sucre",
    value: "XSU",
  },
  {
    label: "Solomon Islands Dollar",
    value: "SBD",
  },
  {
    label: "Somali Shilling",
    value: "SOS",
  },
  {
    label: "Rand",
    value: "ZAR",
  },
  {
    label: "South Sudanese Pound",
    value: "SSP",
  },
  {
    label: "Euro",
    value: "EUR",
  },
  {
    label: "Sri Lanka Rupee",
    value: "LKR",
  },
  {
    label: "Sudanese Pound",
    value: "SDG",
  },
  {
    label: "Surinam Dollar",
    value: "SRD",
  },
  {
    label: "Norwegian Krone",
    value: "NOK",
  },
  {
    label: "Swedish Krona",
    value: "SEK",
  },
  {
    label: "Swiss Franc",
    value: "CHF",
  },
  {
    label: "WIR Euro",
    value: "CHE",
  },
  {
    label: "WIR Franc",
    value: "CHW",
  },
  {
    label: "Syrian Pound",
    value: "SYP",
  },
  {
    label: "New Taiwan Dollar",
    value: "TWD",
  },
  {
    label: "Somoni",
    value: "TJS",
  },
  {
    label: "Tanzanian Shilling",
    value: "TZS",
  },
  {
    label: "Baht",
    value: "THB",
  },
  {
    label: "CFA Franc BCEAO",
    value: "XOF",
  },
  {
    label: "New Zealand Dollar",
    value: "NZD",
  },
  {
    label: "Pa’anga",
    value: "TOP",
  },
  {
    label: "Trinidad and Tobago Dollar",
    value: "TTD",
  },
  {
    label: "Tunisian Dinar",
    value: "TND",
  },
  {
    label: "Turkish Lira",
    value: "TRY",
  },
  {
    label: "Turkmenistan New Manat",
    value: "TMT",
  },
  {
    label: "Australian Dollar",
    value: "AUD",
  },
  {
    label: "Uganda Shilling",
    value: "UGX",
  },
  {
    label: "Hryvnia",
    value: "UAH",
  },
  {
    label: "UAE Dirham",
    value: "AED",
  },
  {
    label: "Pound Sterling",
    value: "GBP",
  },
  {
    label: "US Dollar (Next day)",
    value: "USN",
  },
  {
    label: "Peso Uruguayo",
    value: "UYU",
  },
  {
    label: "Uruguay Peso en Unidades Indexadas (UI)",
    value: "UYI",
  },
  {
    label: "Unidad Previsional",
    value: "UYW",
  },
  {
    label: "Uzbekistan Sum",
    value: "UZS",
  },
  {
    label: "Vatu",
    value: "VUV",
  },
  {
    label: "Bolívar Soberano",
    value: "VES",
  },
  {
    label: "Bolívar Soberano",
    value: "VED",
  },
  {
    label: "Dong",
    value: "VND",
  },
  {
    label: "CFP Franc",
    value: "XPF",
  },
  {
    label: "Moroccan Dirham",
    value: "MAD",
  },
  {
    label: "Yemeni Rial",
    value: "YER",
  },
  {
    label: "Zambian Kwacha",
    value: "ZMW",
  },
  {
    label: "Zimbabwe Dollar",
    value: "ZWL",
  },
];

export default currencyCodes;
