import moment from "moment";
import { showNotification } from "util/utility";
import {
  getIfOrganizationHasEmbeddedSignupActive,
  getSelectedOrganizationId,
} from "modules/App/AppSelectors";
import { toggleSideBarStatus } from "modules/App/AppActions";
import { sortSubscriptionsWithLatestCreatedStamp } from "util/paymentUtil";
import { PAID_PLAN, TRIAL_PLAN } from "constants/PlanTypes";
import { setNotificationRibbon } from "../Inbox/ThreadActions";
import callApi, { callApiV2 } from "../../util/apiCaller";
import {
  ATTACH_PAYMENT_METHOD_STATUS,
  ATTACH_PAYMENT_METHOD,
  FETCH_INVOICE,
  INVOICE_FETCH_STATUS,
  FETCH_SUBSCRIPTION,
  SUBSCRIPTION_FETCH_STATUS,
  UPDATE_PAYMENT_STATUS,
  UPDATE_PAYMENT_PROCESS_STATUS_MESSAGE,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  PAYMENT_INVOICE_FETCH_STATUS,
  FETCH_PAYMENT_INVOICE,
  FETCHING_ALL_PAYMENT_METHODS,
  FETCH_ALL_PAYMENT_METHODS,
  NOTIFICATION_USAGE_FETCH_STATUS,
  FETCH_NOTIFICATION_USAGE,
  BILLING_CYCLE_RANGE_FETCH_STATUS,
  FETCH_BILLING_CYCLE_RANGE,
  FETCH_UNIQUE_USER_COUNT,
  UNIQUE_USER_COUNT_FETCH_STATUS,
  ADDING_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD,
  UPDATE_PAYMENT_DONE_STATUS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_STATUS,
  SET_DEFAULT_PAYMENT_METHOD_STATUS,
  SET_DEFAULT_PAYMENT_METHOD,
  FETCH_CREDIT_LIST,
  FETCHING_CREDIT_LIST,
  SET_USAGE_HISTORY_ACTIVE_TAB,
  TOGGLE_USAGE_HISTORY_CALENDER,
  SET_USAGE_HISTORY_CALENDER_FIELDS,
  TOGGLE_ADD_PAYMENT_METHOD_MODAL,
  TOGGLE_EDIT_NAME_MODAL,
  TOGGLE_EDIT_EMAIL_MODAL,
  TOGGLE_EDIT_ADDRESS_MODAL,
  TOGGLE_EDIT_GST_NUMBER_MODAL,
  TOGGLE_EDIT_GST_NUMBER_MODAL_MOBILE,
  IS_PAYMENT_DONE_RIBBON,
  IS_DAYS_LEFT_FREE_TRAIL_RIBBON,
  SET_SUBSCRIPTION_EXPIRED_RIBBON_STATUS,
  TOGGLE_REDIRECT_TO_SHOPIFY_STATE,
  FETCHING_ORG_BUSINESS_INFO,
  FETCH_ORG_BUSINESS_INFO,
  SET_WHATSAPP_CONVERSATION_CHARGES,
} from "./PaymentTypes";

export const UPDATE_PAYMENT_PLAN_CHANGE_STATE =
  "UPDATE_PAYMENT_PLAN_CHANGE_STATE";

export function toggleIsDaysLeftFreeTrailRibbon(val) {
  return {
    type: IS_DAYS_LEFT_FREE_TRAIL_RIBBON,
    isDaysLeftFreeTrailRibbon: val,
  };
}

export function toggleRedirectToShopify(val) {
  return {
    type: TOGGLE_REDIRECT_TO_SHOPIFY_STATE,
    isRedirectingToShopify: val,
  };
}

export function toggleIsPaymentDoneRibbon(val) {
  return {
    type: IS_PAYMENT_DONE_RIBBON,
    isPaymentDoneRibbon: val,
  };
}

export function toggleEditNameModalState(val) {
  return {
    type: TOGGLE_EDIT_NAME_MODAL,
    isOpenEditNameModal: !!val,
  };
}

export function toggleEditEmailModalState(val) {
  return {
    type: TOGGLE_EDIT_EMAIL_MODAL,
    isOpenEditEmailModal: !!val,
  };
}

export function toggleEditAddressModalState(val) {
  return {
    type: TOGGLE_EDIT_ADDRESS_MODAL,
    isOpenEditAddressModal: !!val,
  };
}

export function toggleEditGSTNumberModalState(val) {
  return {
    type: TOGGLE_EDIT_GST_NUMBER_MODAL,
    isOpenEditGSTNumberModal: !!val,
  };
}

export function toggleSubscriptionExpiredRibbon(val) {
  return {
    type: SET_SUBSCRIPTION_EXPIRED_RIBBON_STATUS,
    SubscriptionExpiredRibbonStatus: val,
  };
}

export function toggleEditGSTNumberModalStateMobile(val) {
  return {
    type: TOGGLE_EDIT_GST_NUMBER_MODAL_MOBILE,
    isOpenEditGSTNumberModalMobile: !!val,
  };
}

export function toggleAddPaymentMethodModal(val) {
  return {
    type: TOGGLE_ADD_PAYMENT_METHOD_MODAL,
    isOpenAddPaymentModal: !!val,
  };
}

export function setUsageHistoryCalenderFields(data) {
  return {
    type: SET_USAGE_HISTORY_CALENDER_FIELDS,
    usageHistoryCalenderFields: data,
  };
}

export function toggleUsageHistoryCalender(state) {
  return {
    type: TOGGLE_USAGE_HISTORY_CALENDER,
    isOpenUsageHistoryCalender: !!state,
  };
}

export function setUsageHistoryActiveTab(val) {
  return {
    type: SET_USAGE_HISTORY_ACTIVE_TAB,
    usageHistoryActiveTab: val,
  };
}

export function updatePaymantProcessingStatusMessage(msg) {
  return {
    type: UPDATE_PAYMENT_PROCESS_STATUS_MESSAGE,
    paymentProcessStatusMessages: msg,
  };
}

export function updatePaymentStatus(state) {
  return {
    type: UPDATE_PAYMENT_STATUS,
    isProcessingPayment: !!state,
  };
}

export function updatePaymentDoneStatus(state) {
  return {
    type: UPDATE_PAYMENT_DONE_STATUS,
    isPaymentDone: !!state,
  };
}

export function updatePaymentSuccess({
  isProcessingPayment,
  isPaymentDone,
  paymentError,
  paymentProcessStatusMessages,
}) {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    isProcessingPayment,
    isPaymentDone,
    paymentError,
    paymentProcessStatusMessages,
  };
}

export function updatePaymentFailure({
  isProcessingPayment,
  isPaymentDone,
  error,
  paymentProcessStatusMessages,
}) {
  return {
    type: UPDATE_PAYMENT_FAILURE,
    isProcessingPayment,
    isPaymentDone,
    paymentError: error,
    paymentProcessStatusMessages,
  };
}

export function attachPaymentMethod(organizationId, token) {
  return (dispatch) => {
    dispatch({
      type: ATTACH_PAYMENT_METHOD_STATUS,
      isAttachingPaymentMethod: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/paymentmethod/attach/`,
      "post",
      { token },
    ).then((res) => {
      if (res && res.result) {
        showNotification("success", "Payment method attached");
        dispatch({
          type: ATTACH_PAYMENT_METHOD,
          isAttachingPaymentMethod: false,
          payment3dSecurePinUrl:
            res.data &&
            res.data.next_action &&
            res.data.next_action.use_stripe_sdk &&
            res.data.next_action.use_stripe_sdk.stripe_js
              ? res.data &&
                res.data.next_action &&
                res.data.next_action.use_stripe_sdk &&
                res.data.next_action.use_stripe_sdk.stripe_js
              : "",
        });
      } else {
        showNotification("error", res.message);
        dispatch({
          type: ATTACH_PAYMENT_METHOD_STATUS,
          isAttachingPaymentMethod: false,
        });
      }
    });
  };
}

export function fetchAllPaymentmethods(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_ALL_PAYMENT_METHODS,
      isFetchingPaymentMethods: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/paymentmethods/?type=all`,
      "get",
      undefined,
      true,
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_ALL_PAYMENT_METHODS,
          isFetchingPaymentMethods: false,
          paymentMethods: res.data,
        });
      } else {
        dispatch({
          type: FETCHING_ALL_PAYMENT_METHODS,
          isFetchingPaymentMethods: false,
        });
      }
    });
  };
}

export function addPaymentMethodToList(paymentMethod) {
  return (dispatch) => {
    dispatch({
      type: ADD_PAYMENT_METHOD,
      isAddingPaymentMethods: false,
      paymentMethod,
    });
  };
}

export function addPaymentMethod(organizationId, paymentMethodId) {
  return (dispatch) => {
    dispatch({
      type: ADDING_PAYMENT_METHOD,
      isAddingPaymentMethods: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/paymentmethod/add/`,
      "post",
      { payment_method_id: paymentMethodId },
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: ADD_PAYMENT_METHOD,
          isAddingPaymentMethods: false,
          paymentMethod: res.data,
        });
      } else {
        dispatch({
          type: ADDING_PAYMENT_METHOD,
          isAddingPaymentMethods: false,
        });
      }
    });
  };
}

export function splitSubscriptionsIntoPlanTypes(subscriptions) {
  const activeSubscriptionList = [];
  const trialSubscriptionList = [];
  const paidSubscriptionList = [];
  if (subscriptions && subscriptions.length > 0) {
    subscriptions.forEach((item) => {
      let isHaveAnyPaidSubscription = false;
      let isHaveTrialSubscription = false;
      const isSubscriptionDatesNull =
        !item.subscription_start_date && !item.subscription_end_date;
      const isSubscriptionDatesPresent =
        item.subscription_start_date && item.subscription_end_date;
      const isSubscriptionDatePastToday = isSubscriptionDatesPresent
        ? moment(new Date()).isAfter(moment(item.subscription_start_date)) &&
          moment(new Date()).isAfter(moment(item.subscription_end_date))
        : false;
      if (item?.subscription?.plan_type === PAID_PLAN) {
        isHaveAnyPaidSubscription = true;
      }
      if (item?.subscription?.plan_type === TRIAL_PLAN) {
        isHaveTrialSubscription = true;
      }
      const newItem = { ...item };
      newItem.subscriptionPlanType = isHaveAnyPaidSubscription
        ? "paid"
        : "trial";
      newItem.isSubscriptionActive =
        !isSubscriptionDatesNull && !isSubscriptionDatePastToday;
      if (isHaveAnyPaidSubscription) {
        paidSubscriptionList.push(newItem);
      }
      if (isHaveTrialSubscription) {
        trialSubscriptionList.push(newItem);
      }
      if (!isSubscriptionDatesNull && !isSubscriptionDatePastToday) {
        activeSubscriptionList.push(newItem);
      }
    });
    const sortedActiveSubscriptionList = sortSubscriptionsWithLatestCreatedStamp(
      activeSubscriptionList,
    );
    const sortedTrialSubscriptionList = sortSubscriptionsWithLatestCreatedStamp(
      trialSubscriptionList,
    );
    const sortedPaidSubscriptionList = sortSubscriptionsWithLatestCreatedStamp(
      paidSubscriptionList,
    );
    return {
      activeSubscriptionList: sortedActiveSubscriptionList,
      trialSubscriptionList: sortedTrialSubscriptionList,
      paidSubscriptionList: sortedPaidSubscriptionList,
    };
  }
  return {
    activeSubscriptionList,
    trialSubscriptionList,
    paidSubscriptionList,
  };
}

export function fetchPaymentSubscription() {
  return (dispatch, getState) => {
    const organizationId = getSelectedOrganizationId(getState());
    const is_embedded_signup_enabled = getIfOrganizationHasEmbeddedSignupActive(
      getState(),
    );
    dispatch({
      type: SUBSCRIPTION_FETCH_STATUS,
      isFetchingSubscription: true,
      isFetchSubscriptionAPIDone: false,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/subscriptions/?type=all`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        if (res.data && res.data.length > 0) {
          let isHaveAnyPaidSubscription = false;
          let isHaveTrialSubscription = false;
          const computedResult = splitSubscriptionsIntoPlanTypes(res.data);
          isHaveAnyPaidSubscription =
            computedResult.paidSubscriptionList.length > 0;
          isHaveTrialSubscription =
            computedResult.trialSubscriptionList.length > 0;
          let isFreeTrialActive = false;
          computedResult.trialSubscriptionList.forEach((item) => {
            if (item.isSubscriptionActive) {
              isFreeTrialActive = true;
            }
          });
          if (
            !!is_embedded_signup_enabled &&
            !isHaveAnyPaidSubscription &&
            isHaveTrialSubscription
          ) {
            dispatch(setNotificationRibbon(true));
          }
          if (isFreeTrialActive) {
            dispatch(setNotificationRibbon(true));
          }
          dispatch({
            type: FETCH_SUBSCRIPTION,
            isFetchSubscriptionAPIDone: true,
            isFetchingSubscription: false,
            allSubscriptionList: res.data,
            activeSubscriptionList: computedResult.activeSubscriptionList,
            trialSubscriptionList: computedResult.trialSubscriptionList,
            paidSubscriptionList: computedResult.paidSubscriptionList,
            isPaymentDone: res.data.length > 0,
          });
        } else {
          dispatch({
            type: FETCH_SUBSCRIPTION,
            isFetchSubscriptionAPIDone: true,
            isFetchingSubscription: false,
            allSubscriptionList: res.data,
            activeSubscriptionList: [],
            trialSubscriptionList: [],
            paidSubscriptionList: [],
            isPaymentDone: res.data.length > 0,
          });
        }
      } else {
        dispatch({
          type: SUBSCRIPTION_FETCH_STATUS,
          isFetchingSubscription: false,
          isFetchSubscriptionAPIDone: false,
        });
      }
    });
  };
}

export function fetchPaymentInvoiceList(organizationId) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_INVOICE_FETCH_STATUS,
      isFetchingPaymentInvoiceList: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/billing/invoices/`,
      "get",
      undefined,
      true,
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_PAYMENT_INVOICE,
          paymentInvoiceList: res.data,
          isFetchingPaymentInvoiceList: false,
        });
      } else {
        dispatch({
          type: PAYMENT_INVOICE_FETCH_STATUS,
          isFetchingPaymentInvoiceList: false,
        });
      }
    });
  };
}

export function fetchInvoice(organizationId, invoiceId) {
  return (dispatch) => {
    dispatch({
      type: INVOICE_FETCH_STATUS,
      isFetchingPaymentInvoice: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/invoice/pay/`,
      "post",
      { invoice_id: invoiceId },
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_INVOICE,
          paymentInvoice: res.data,
          isFetchingPaymentInvoice: false,
        });
      } else {
        dispatch({
          type: INVOICE_FETCH_STATUS,
          isFetchingPaymentInvoice: false,
        });
      }
    });
  };
}

export function fetchNotificationUsageCount(organizationId, month, year) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_USAGE_FETCH_STATUS,
      isFetchingNotificationUsage: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/chats/notification/count/?month=${month}&year=${year}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_NOTIFICATION_USAGE,
          notificationUsageList: res.data,
          isFetchingNotificationUsage: false,
        });
      } else {
        dispatch({
          type: NOTIFICATION_USAGE_FETCH_STATUS,
          isFetchingNotificationUsage: false,
        });
      }
    });
  };
}

export function fetchPaymentBillingCycleDateRange(organizationId) {
  return (dispatch) => {
    dispatch({
      type: BILLING_CYCLE_RANGE_FETCH_STATUS,
      isFetchingBillingCycle: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/billing-date-ranges/`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_BILLING_CYCLE_RANGE,
          billingCycleDateRanges: res.data,
          isFetchingBillingCycle: false,
        });
      } else {
        dispatch({
          type: BILLING_CYCLE_RANGE_FETCH_STATUS,
          isFetchingBillingCycle: false,
        });
      }
    });
  };
}

export function fetchUniqueUserCount(organizationId, from, to) {
  return (dispatch) => {
    dispatch({
      type: UNIQUE_USER_COUNT_FETCH_STATUS,
      isFetchingUniqueUserCount: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/chats/unique-user/count/?from=${from}&to=${to}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_UNIQUE_USER_COUNT,
          uniqueUserCountList: res.data,
          isFetchingUniqueUserCount: false,
        });
      } else {
        dispatch({
          type: UNIQUE_USER_COUNT_FETCH_STATUS,
          isFetchingUniqueUserCount: false,
        });
      }
    });
  };
}

export function deletePaymentMethod(organizationId, paymentMethodId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_PAYMENT_METHOD_STATUS,
      isDeletingPaymentMethod: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/paymentmethod/remove/`,
      "POST",
      {
        payment_method_id: paymentMethodId,
      },
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: DELETE_PAYMENT_METHOD,
          paymentMethodId,
          isDeletingPaymentMethod: false,
        });
      } else {
        dispatch({
          type: DELETE_PAYMENT_METHOD_STATUS,
          isDeletingPaymentMethod: false,
        });
      }
    });
  };
}

export function setDefaultPaymentMethod(organizationId, paymentMethodId) {
  return (dispatch) => {
    dispatch({
      type: SET_DEFAULT_PAYMENT_METHOD_STATUS,
      isSettingDefaultPaymentMethod: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/paymentmethod/set-default/`,
      "POST",
      {
        payment_method_id: paymentMethodId,
      },
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: SET_DEFAULT_PAYMENT_METHOD,
          paymentMethodId,
          isSettingDefaultPaymentMethod: false,
        });
      } else {
        dispatch({
          type: SET_DEFAULT_PAYMENT_METHOD_STATUS,
          isSettingDefaultPaymentMethod: false,
        });
      }
    });
  };
}

export function fetchCreditHistory(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_CREDIT_LIST,
      isFetchingCreditHistory: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/payments/credit-history/`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_CREDIT_LIST,
          isFetchingCreditHistory: false,
          creditBalance: res.data ? res.data.credit_balance : 0,
          currency: res.data ? res.data.currency : 0,
          creditHistoryList: res.data ? res.data.transactions : [],
        });
      } else {
        dispatch({
          type: FETCHING_CREDIT_LIST,
          isFetchingCreditHistory: false,
        });
      }
    });
  };
}

export function updatePaymentSubscription(
  orgSubscriptionId,
  paymentPlanId,
  paymentPlanName,
  onSuccess,
) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_PAYMENT_PLAN_CHANGE_STATE,
      isUpdatingPaymentPlan: true,
    });
    const organizationId = getSelectedOrganizationId(getState());
    if (organizationId) {
      return callApi(
        `v1/organizations/${organizationId}/payments/subscriptions/${orgSubscriptionId}/update/`,
        "post",
        {
          subscription_plan_id: paymentPlanId,
        },
      ).then((res) => {
        dispatch({
          type: UPDATE_PAYMENT_PLAN_CHANGE_STATE,
          isUpdatingPaymentPlan: false,
        });
        if (res && res.result) {
          if (onSuccess) {
            onSuccess(res.data);
          }
        }
      });
    }
  };
}

export function startOrganizationShopifySubscription(organizationId, data) {
  return (dispatch) => {
    dispatch(toggleRedirectToShopify(true));
    dispatch(setNotificationRibbon(false));
    dispatch(toggleSubscriptionExpiredRibbon(false));
    callApi(
      `v1/organizations/${organizationId}/shopify/start_subscription/`,
      "POST",
      data,
    ).then((res) => {
      if (res && res.data) {
        const url =
          res.data && res.data.confirmation_url
            ? res.data.confirmation_url
            : "";
        window.location.href = `${url}`;
      } else {
        dispatch(setNotificationRibbon(true));
        dispatch(toggleSubscriptionExpiredRibbon(true));
        dispatch(toggleSideBarStatus(true));
        dispatch(toggleRedirectToShopify(false));
      }
    });
  };
}

export function trackFBSignup(organizationId, deviceType, callback) {
  callApi(
    `v1/organizations/${organizationId}/track-fb-signup/?platform=${deviceType}`,
    "get",
  ).then((res) => {
    callback(res);
  });
}

// TODO: Remove this once Embedded Signup Testing is done.
export function trackFBSignupResponse(organizationId, fbSignupResponse) {
  callApiV2({
    endpoint: `v1/organizations/${organizationId}/track-fb-signup/?fb_status_object=${JSON.stringify(
      fbSignupResponse,
    )}`,
    method: "get",
  });
}

export const fetchOrgBusinessInfo = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_ORG_BUSINESS_INFO,
      isFetchingOrgBusinessInfo: true,
    });
    const organizationId = getState().app?.organizations[0]?.organization_id.id;

    if (organizationId) {
      const res = await callApiV2({
        endpoint: `v1/organizations/${organizationId}/business-info/`,
      });
      dispatch({
        type: FETCH_ORG_BUSINESS_INFO,
        orgBusinessInfo: res?.data?.FB_business_verification_status,
        isFetchingOrgBusinessInfo: false,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCHING_ORG_BUSINESS_INFO,
      isFetchingOrgBusinessInfo: false,
    });
  }
};

export const fetchAndSaveWhatsappConversationData = (payload) => (
  dispatch,
  getState,
) => {
  if (payload?.getDataFromGraphApi)
    dispatch({
      type: SET_WHATSAPP_CONVERSATION_CHARGES,
      payload: {
        loading: true,
      },
    });
  const organisationId = getSelectedOrganizationId(getState());
  // Refresh (Boolean) query params will notify BE to actually call graph api.
  // If it will be false BE return data from cache.
  callApi(
    `v1/organizations/${organisationId}/payments/whatsapp-conversation-charge/?month=${
      payload?.month
    }&year=${payload?.year}&refresh=${
      payload?.getDataFromGraphApi ? "true" : "false"
    }`,
  )
    .then((response) => {
      if (response.result) {
        dispatch({
          type: SET_WHATSAPP_CONVERSATION_CHARGES,
          payload: {
            ...response.data,
            loading: false,
          },
        });
        if (payload.getDataFromGraphApi) {
          showNotification(
            "success",
            `${response.message} updated successfully!`,
          );
        }
      } else {
        throw response;
      }
    })
    .catch((error) => {
      showNotification("error", error.message);
      dispatch({
        type: SET_WHATSAPP_CONVERSATION_CHARGES,
        payload: {
          loading: false,
        },
      });
    });
};
