import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { getOrganizationOnboardedStatus } from "modules/App/AppSelectors";
import { useSelector } from "react-redux";
import { callApiV2 } from "util/apiCaller";

export const RQ_FETCH_READ_NUDGE = "RQ_FETCH_READ_NUDGE";

export const LIST_PAGE_SIZE = 5;

const fetchReadNudge = async ({ orgId, offset }) => {
  try {
    const result = await callApiV2({
      endpoint: `/v1/organizations/${orgId}/nudge/?limit=${LIST_PAGE_SIZE}&offset=${offset}&unread=false`,
      method: "GET",
    });

    if (!result?.results?.result) {
      throw new Error(result.message);
    }

    return { count: result.count, results: result.results.data };
  } catch (error) {
    throw new Error(error.message);
  }
};

const useReadNudge = ({
  options = {},
  select = (data) => data,
  page = 1,
} = {}) => {
  const orgId = useOrganizationId();
  const isOnboardedStatus = useSelector(getOrganizationOnboardedStatus);

  return useQuery({
    queryFn: () =>
      fetchReadNudge({ offset: (page - 1) * LIST_PAGE_SIZE, orgId }),
    queryKey: [RQ_FETCH_READ_NUDGE, orgId, page],
    select,
    enabled:
      options.enabled === undefined
        ? !!orgId && !!isOnboardedStatus
        : !!orgId && !!isOnboardedStatus && !!options.enabled,
    ...options,
  });
};

export const getNudgeCount = (data) => {
  return data?.count;
};

export const getNudgeList = (data) => {
  return data?.results;
};

export default useReadNudge;
