import useIsAdsPlanSignupSource from "customHooks/useIsAdsPlanSignupSource";

const withUseSignupSourceAdsPlan = (WrappedComponent) => {
  return (props) => {
    const isAdsPlanSignupSource = useIsAdsPlanSignupSource();

    return (
      <WrappedComponent
        {...props}
        isAdsPlanSignupSource={isAdsPlanSignupSource}
      />
    );
  };
};

export default withUseSignupSourceAdsPlan;
