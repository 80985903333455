import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";
import {
  checkIfStringHasVariables,
  isHTTPinURL,
  showNotification,
  getUrlParams as getUrlParamsV2,
} from "util/utility";
import { isURL } from "validator";
import HEADER_TYPES from "constants/headerTypes";
import { countryCodeList } from "constants/countryConstants";
import { getUrlParams } from "modules/Inbox/component/InboxFilter/utility";
import { CHANNEL_TYPE_RCS } from "components/ChannelTypeDropdown/constants";
import {
  COPY_CODE,
  QUICK_REPLY,
  PHONE_NUMBER,
  URL,
} from "./components/ButtonsSection/constant";
import {
  DYNAMIC_URL,
  OPTION_BUTTONS,
  OPTION_CARAOUSAL,
  OPTION_SEND_PRODUCTS,
  OPTION_LIMITED_TIME_OFFER,
  OPTION_NONE,
  STATIC_URL,
  TYPE_CATALOG,
  TYPE_MPM,
  TYPE_WHATSAPP_FLOW,
  OPTION_WHATSAPP_FLOW,
  OPTION_ONE_TIME_PASSCODE,
} from "../constants";

const getFormattedQuickReplies = (quickReplies) => {
  return quickReplies.map((reply) => {
    return { type: QUICK_REPLY, text: reply.text };
  });
};

const getFormattedWebsiteUrls = (websiteUrls) => {
  return websiteUrls.map((websiteUrl) => {
    return {
      type: URL,
      text: websiteUrl.text,
      urlType: websiteUrl.type,
      url: websiteUrl.url,
    };
  });
};

function getFormattedPhoneNumber(phoneNumber) {
  for (let i = 0; i < countryCodeList.length; i += 1) {
    const { code } = countryCodeList[i];
    if (phoneNumber.includes(code)) {
      return { countryCode: code, phoneNumber: phoneNumber.slice(code.length) };
    }
  }

  return { countryCode: "", phoneNumber };
}

export const getFormattedTemplateButtons = (
  { couponCode, quickReplies, phoneNumber, websiteUrl },
  buttonsOrder,
) => {
  let formattedButtons = [];
  buttonsOrder.forEach((button) => {
    if (button === "couponCode" && !isEmpty(couponCode)) {
      formattedButtons.push({ type: COPY_CODE, example: couponCode[0] });
    }
    if (button === "quickReplies" && !isEmpty(quickReplies)) {
      formattedButtons = [
        ...formattedButtons,
        ...getFormattedQuickReplies(quickReplies),
      ];
    }

    if (button === "websiteUrl") {
      if (!isEmpty(websiteUrl)) {
        formattedButtons = [
          ...formattedButtons,
          ...getFormattedWebsiteUrls(websiteUrl),
        ];
      }

      if (!isEmpty(phoneNumber)) {
        formattedButtons.push({
          type: PHONE_NUMBER,
          text: phoneNumber[0].buttonText,
          phone_number: `${phoneNumber[0].countryCode}${phoneNumber[0].phoneNumber}`,
        });
      }
    }
  });

  return formattedButtons;
};

export function generateButtonsFullOrder(buttonsOrder) {
  return buttonsOrder.map((buttons) => {
    if (buttons.length < 4) {
      const buttonsCopy = [...buttons];

      if (!buttonsCopy.includes("quickReplies")) {
        buttonsCopy.push("quickReplies");
      }

      if (!buttonsCopy.includes("websiteUrl")) {
        buttonsCopy.push("websiteUrl");
      }

      return buttonsCopy;
    }

    return buttons;
  });
}

const getSelectedSectionTypeFromButtons = (
  buttonsSection,
  allLTOData,
  carouselCards,
) => {
  const buttonSectionType = [];

  buttonsSection.forEach((item, index) => {
    if (
      Array.isArray(carouselCards?.[index]) &&
      carouselCards?.[index]?.length > 0
    ) {
      buttonSectionType.push(OPTION_CARAOUSAL);
    } else if (allLTOData?.[index]?.text) {
      buttonSectionType.push(OPTION_LIMITED_TIME_OFFER);
    } else if (buttonsSection[index][0]?.type === TYPE_CATALOG) {
      buttonSectionType.push(OPTION_SEND_PRODUCTS);
    } else if (buttonsSection[index][0]?.type === TYPE_MPM) {
      buttonSectionType.push(OPTION_SEND_PRODUCTS);
    } else if (buttonsSection[index][0]?.type === TYPE_WHATSAPP_FLOW) {
      buttonSectionType.push(OPTION_WHATSAPP_FLOW);
    } else if (isEmpty(item)) {
      buttonSectionType.push(OPTION_NONE);
    } else if (getUrlParams(item[0]?.url?.split("?")?.[1] || "", "otp_type")) {
      buttonSectionType.push(OPTION_ONE_TIME_PASSCODE);
    } else {
      buttonSectionType.push(OPTION_BUTTONS);
    }
  });

  return buttonSectionType;
};

const getSendProductButtonType = (buttonsSection) => {
  let sendProductButtonType = "";
  buttonsSection.forEach((item, index) => {
    if (buttonsSection[index][0]?.type === TYPE_CATALOG) {
      sendProductButtonType = TYPE_CATALOG;
    } else if (buttonsSection[index][0]?.type === TYPE_MPM) {
      sendProductButtonType = TYPE_MPM;
    }
  });
  return sendProductButtonType;
};

export function formatAPIdataForTemplateButtons(
  allButtons,
  allLTOData,
  carouselCards,
) {
  const channelParam = getUrlParamsV2("channel_type");
  const isRCSChannel = channelParam === CHANNEL_TYPE_RCS;
  const formattedButtons = [];
  let buttonsOrder = [];
  const ltoData = [];

  if (Array.isArray(allButtons)) {
    allButtons.forEach((buttons, index) => {
      formattedButtons.push({
        couponCode: [],
        quickReplies: [],
        phoneNumber: [],
        websiteUrl: [],
        whatsAppFlow: [],
      });

      buttonsOrder.push([]);
      if (Array.isArray(buttons)) {
        buttons.forEach((button) => {
          if (button.type === TYPE_WHATSAPP_FLOW) {
            formattedButtons[index].whatsAppFlow.push({
              flow_action: button.flow_action,
              flow_id: button.flow_id,
              navigate_screen: button.navigate_screen,
              text: button.navigate_screen,
            });
          }

          if (button.type === COPY_CODE) {
            formattedButtons[index].couponCode.push(button.example);
            return;
          }

          if (button.type === QUICK_REPLY) {
            if (!buttonsOrder[index].includes("quickReplies")) {
              buttonsOrder[index].push("quickReplies");
            }
            formattedButtons[index].quickReplies.push({
              id: uuidv4(),
              text: isRCSChannel ? button.reply.plainText : button.text,
            });
            return;
          }

          if (button.type === URL) {
            if (!buttonsOrder[index].includes("websiteUrl")) {
              buttonsOrder[index].push("websiteUrl");
            }
            const buttonUrl = isRCSChannel
              ? button.action.openUrl.url
              : button.url;
            formattedButtons[index].websiteUrl.push({
              id: uuidv4(),
              text: isRCSChannel ? button.action.plainText : button.text,
              url: buttonUrl,
              type: buttonUrl.includes("{{1}}") ? DYNAMIC_URL : STATIC_URL,
            });
            return;
          }

          if (button.type === PHONE_NUMBER) {
            const buttonPhoneNumber = isRCSChannel
              ? button.action.dialerAction.phoneNumber
              : button.phone_number;
            formattedButtons[index].phoneNumber.push({
              buttonText: isRCSChannel ? button.action.plainText : button.text,
              countryCode: getFormattedPhoneNumber(buttonPhoneNumber)
                .countryCode,
              phoneNumber: getFormattedPhoneNumber(buttonPhoneNumber)
                .phoneNumber,
            });
          }
        });
      }
    });
  }

  if (Array.isArray(allLTOData)) {
    allLTOData.forEach((lto) => {
      ltoData.push({
        ltoText: lto.text,
      });
    });
  }

  buttonsOrder = generateButtonsFullOrder(buttonsOrder);

  return {
    formattedButtons,
    buttonsOrder,
    sectionTypes: getSelectedSectionTypeFromButtons(
      allButtons,
      allLTOData,
      carouselCards,
    ),
    ltoData,
    sendProductButtonType: getSendProductButtonType(allButtons),
  };
}

export const isValidTemplateButtons = (buttons) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const button of buttons) {
    if (button.type === COPY_CODE && isEmpty(button.example)) {
      showNotification("warning", "Coupon Code can't be empty");
      return false;
    }

    if (button.type === QUICK_REPLY && isEmpty(button.text)) {
      showNotification("warning", "Quick Replies can't be empty");
      return false;
    }

    if (button.type === PHONE_NUMBER) {
      if (isEmpty(button.phone_number)) {
        showNotification("warning", "Phone Number can't be empty");
        return false;
      }

      if (isEmpty(button.text)) {
        showNotification("warning", "Phone Number button text can't be empty");
        return false;
      }
    }

    if (button.type === URL) {
      if (isEmpty(button.text)) {
        showNotification("warning", "Website Url button text can't be empty");
        return false;
      }

      if (isEmpty(button.url)) {
        showNotification("warning", "Website Url can't be empty");
        return false;
      }

      if (!isURL(button.url)) {
        showNotification("warning", "Website Url is not valid");
        return false;
      }

      if (!isHTTPinURL(button.url)) {
        showNotification(
          "warning",
          "Please ensure you enter a complete URL starting with http:// or https://",
        );
        return false;
      }

      const urlVariables = button.url
        ? checkIfStringHasVariables(button.url)
        : [];

      if (button.urlType === STATIC_URL && urlVariables?.length > 0) {
        showNotification(
          "warning",
          "The variable can only be added at the end of the dynamic URL.",
        );
        return false;
      }

      if (button.urlType === DYNAMIC_URL) {
        if (!button.url.endsWith("{{1}}")) {
          showNotification(
            "warning",
            "The Dynamic URL must end with the variable {{1}}",
          );
          return false;
        }

        if (urlVariables.length === 0) {
          showNotification(
            "warning",
            "The Dynamic URL must contain the variable {{1}}",
          );
          return false;
        }

        if (urlVariables[0] !== "{{1}}") {
          showNotification(
            "warning",
            "The variable in the Dynamic URL should be entered as {{1}}.",
          );

          return false;
        }
      }
    }
  }

  return true;
};

export const isValidLTOTemplate = (copyCodeButton, header, ltoText) => {
  if (header && ![HEADER_TYPES.IMAGE, HEADER_TYPES.VIDEO].includes(header)) {
    showNotification(
      "warning",
      "Header should be Image or Video for Limited Time Offer Templates.",
    );
    return false;
  }
  if (!ltoText) {
    showNotification(
      "warning",
      "Text for Limited Time Offer Template can't be empty.",
    );
    return false;
  }
  if (!copyCodeButton?.[0]?.example) {
    showNotification("warning", "Coupon Code can't be empty.");
    return false;
  }
  return true;
};

export const getButtonsDataForTemplate = (buttonsData, selectedChannel) => {
  if (buttonsData && Array.isArray(buttonsData)) {
    const buttons = [];
    if (selectedChannel === CHANNEL_TYPE_RCS) {
      buttonsData.forEach((buttonItem) => {
        if (buttonItem.type === URL) {
          buttons.push({
            action: {
              plainText: buttonItem.text,
              postBack: {
                data: buttonItem.text,
              },
              openUrl: {
                url: buttonItem.url,
              },
            },
            type: URL,
          });
        }
        if (buttonItem.type === QUICK_REPLY) {
          buttons.push({
            reply: {
              plainText: buttonItem.text,
              postBack: { data: buttonItem.text },
            },
            type: QUICK_REPLY,
          });
        }
        if (buttonItem.type === PHONE_NUMBER) {
          buttons.push({
            action: {
              plainText: buttonItem.text,
              postBack: {
                data: buttonItem.text,
              },
              dialerAction: {
                phoneNumber: buttonItem.phone_number,
              },
            },
            type: PHONE_NUMBER,
          });
        }
      });
      return JSON.stringify(buttons);
    }

    buttonsData.forEach((buttonItem) => {
      delete buttonItem.urlType;
      buttons.push(buttonItem);
    });
    return JSON.stringify(buttons);
  }

  return buttonsData;
};

export const getCarouselCardDataForTemplate = (
  carouselCards,
  selectedChannel,
) => {
  if (
    carouselCards &&
    Array.isArray(carouselCards) &&
    selectedChannel === CHANNEL_TYPE_RCS
  ) {
    return carouselCards.map((card) => ({
      body_text: card.body_text,
      button_text: card.button_text,
      card_index: card.card_index,
      header_format: card.header_format,
      cardTitle: card.cardTitle,
      cardDescription: card.body,
      cardMedia: {
        contentInfo: {
          fileUrl: card.header_handle_file_url,
        },
        mediaHeight: "MEDIUM",
      },
      suggestions: card.buttons.map((buttonItem) => {
        if (buttonItem.type === URL) {
          return {
            action: {
              plainText: buttonItem.text,
              postBack: {
                data: buttonItem.text,
              },
              openUrl: {
                url: buttonItem.url,
              },
            },
            type: URL,
          };
        }
        if (buttonItem.type === QUICK_REPLY) {
          return {
            reply: {
              plainText: buttonItem.text,
              postBack: { data: buttonItem.text },
            },
            type: QUICK_REPLY,
          };
        }
      }),
    }));
  }
  return carouselCards;
};
