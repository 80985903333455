import React from "react";

const bulbOutlined = ({ fill, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39_12552)">
        <path
          d="M2.5 10H3.33333M10 2.5V3.33333M16.6667 10H17.5M4.66667 4.66667L5.25 5.25M15.3333 4.66667L14.75 5.25"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.49999 13.3333C6.80039 12.8086 6.28361 12.0771 6.02286 11.2424C5.7621 10.4076 5.7706 9.51202 6.04715 8.68239C6.32369 7.85275 6.85426 7.13116 7.5637 6.61982C8.27314 6.10849 9.12548 5.83333 9.99999 5.83333C10.8745 5.83333 11.7268 6.10849 12.4363 6.61982C13.1457 7.13116 13.6763 7.85275 13.9528 8.68239C14.2294 9.51202 14.2379 10.4076 13.9771 11.2424C13.7164 12.0771 13.1996 12.8086 12.5 13.3333C12.1746 13.6554 11.9297 14.0495 11.7849 14.4839C11.6401 14.9182 11.5996 15.3805 11.6667 15.8333C11.6667 16.2754 11.4911 16.6993 11.1785 17.0118C10.8659 17.3244 10.442 17.5 9.99999 17.5C9.55797 17.5 9.13404 17.3244 8.82148 17.0118C8.50892 16.6993 8.33333 16.2754 8.33333 15.8333C8.40037 15.3805 8.35987 14.9182 8.2151 14.4839C8.07033 14.0495 7.82535 13.6554 7.49999 13.3333Z"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.08333 14.1667H11.9167"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_39_12552">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default bulbOutlined;
