import {
  INT_WEB_NAV_ANALYTICS,
  INT_WEB_NAV_AUTOMATION,
  INT_WEB_NAV_COMMERCE,
  INT_WEB_NAV_INBOX,
  INT_WEB_NAV_NOTIFICATION,
  INT_WEB_NAV_ONBOARDING,
  INT_WEB_NAV_TEMPLATES,
  INT_WEB_NAV_USERS,
} from "constants/mixPanel/inbox";

const COMMON_MENU_LIST = [
  {
    url: "/notification",
    iconName: "notificationsNew",
    cypressData: "notifications",
    isCampaignActive: true,
    tooltipContent: "Notifications",
    mixPanelTrack: INT_WEB_NAV_NOTIFICATION,
    hideForMaskNumber: true,
    shouldShowNotification: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 4,
  },
  {
    url: "/templates/list",
    iconName: "templatesNew",
    cypressData: "templates",
    isTemplateActive: true,
    tooltipContent: "Templates",
    mixPanelTrack: INT_WEB_NAV_TEMPLATES,
    hideForMaskNumber: true,
    shouldShowTemplates: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 5,
  },
  {
    url: "/contacts/list",
    iconName: "usersNew",
    cypressData: "Customers",
    isContactsListActive: true,
    tooltipContent: "Users",
    mixPanelTrack: INT_WEB_NAV_USERS,
    hideForMaskNumber: true,
    shouldShowContacts: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 6,
  },
];

export const SIDEBAR_MENU_LIST = [
  {
    url: "/onboarding",
    iconName: "dashboard",
    cypressData: "onboarding",
    isOnboardingActive: true,
    tooltipContent: "Onboarding",
    mixPanelTrack: INT_WEB_NAV_ONBOARDING,
    showOnboardingFeature: true,
    adsPlanIndex: 1,
  },
  {
    url: "/inbox",
    iconName: "inboxNew",
    cypressData: "inbox",
    isInboxActive: true,
    tooltipContent: "Inbox",
    mixPanelTrack: INT_WEB_NAV_INBOX,
    shouldShowInbox: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 3,
  },
  ...COMMON_MENU_LIST,
  {
    hasOrders: true,
    url: "/commerce-settings",
    hasOrderUrl: "/commerce/order-panel",
    iconName: "commerceNew",
    cypressData: "CommerceSettings",
    isActiveCommerce: true,
    tooltipContent: "Commerce",
    mixPanelTrack: INT_WEB_NAV_COMMERCE,
    hideForTally: true,
    hideForStarter: true,
    shouldShowCommerce: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 7,
  },
  {
    url: "/automation/inbox-setting",
    iconName: "automation",
    cypressData: "Automation",
    isActiveAutomation: true,
    tooltipContent: "Automation",
    mixPanelTrack: INT_WEB_NAV_AUTOMATION,
    hideForTally: true,
    shouldShowAutomation: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 2,
  },
  {
    url: "/analytics/overview",
    iconName: "analyticsNew",
    cypressData: "Analytics",
    isAnalyticsActive: true,
    tooltipContent: "Analytics",
    mixPanelTrack: INT_WEB_NAV_ANALYTICS,
    hideForTally: true,
    hideForMaskNumber: true,
    shouldShowAnalytics: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 7,
  },
  {
    url: "/integrations",
    iconName: "integrationOutlined",
    cypressData: "Integrations",
    isIntegrationActive: true,
    tooltipContent: "Integrations",
    hideForTally: true,
    strokeColor: "#0A0B10",
    shouldShowIntegrations: true,
    hideForResellerWithPlanNotStarted: true,
    hideForAdsPlanNotStarted: true,
    adsPlanIndex: 7,
  },
];

export const SIDEBAR_MENU_LIST_HAPTIK = [
  ...COMMON_MENU_LIST,
  {
    iconName: "haptikConversationStudio",
    tooltipContent: "Conversation Studio",
    url: "https://api.haptikapi.com/mogambo/",
    isExternal: true,
    adsPlanIndex: 7,
  },
  {
    iconName: "haptikIntelligentAnalytics",
    tooltipContent: "Intelligent Analytics",
    url: "https://api.haptikapi.com/dashboard",
    isExternal: true,
    adsPlanIndex: 7,
  },
];
