import {
  TOGGLE_PRODUCT_PANEL_STATUS,
  UPDATE_FETCHING_PRODUCT_STATUS,
  FETCHING_PRODUCT_DATA,
  SET_SEARCH_FILTER,
  SET_COLLECTION_FILTER,
  SYNCING_PRODUCT_DETAILS,
  SYNCING_TIMEOUT,
  UPDATE_SELECTED_PRODUCTS,
} from "./ProductPanelActions";

const initialState = {
  isProductPanelVisible: false,
  isFetchingProductDetails: false,
  productData: {},
  searchFilter: "",
  collectionFilter: { id: "", label: "All Products", value: "All Products" },
  isSyncingProductDetails: null,
  isSyncingTimeExceed: false,
  selectedProducts: {},
};

const productPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PRODUCT_PANEL_STATUS:
      return {
        ...state,
        isProductPanelVisible: action.isProductPanelVisible,
      };

    case UPDATE_FETCHING_PRODUCT_STATUS:
      return {
        ...state,
        isFetchingProductDetails: action.isFetchingProductDetails,
      };

    case FETCHING_PRODUCT_DATA:
      return {
        ...state,
        productData:
          action.shouldConcat && state.productData.productList
            ? {
                ...action.productData,
                productList: [
                  ...state.productData.productList,
                  ...action.productData.productList,
                ],
              }
            : { ...action.productData },
        isFetchingProductDetails: action.isFetchingProductDetails,
      };

    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter,
      };

    case SET_COLLECTION_FILTER:
      return {
        ...state,
        collectionFilter: action.collectionFilter,
      };

    case SYNCING_PRODUCT_DETAILS:
      return {
        ...state,
        isSyncingProductDetails: action.isSyncingProductDetails,
      };

    case SYNCING_TIMEOUT:
      return {
        ...state,
        isSyncingTimeExceed: action.isSyncingTimeExceed,
      };

    case UPDATE_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.products,
      };

    default:
      return { ...state };
  }
};

export default productPanelReducer;
