import { getFormattedTime } from "util/timeUtility";
import { ENDPOINT_TYPES } from "../constants";

const getEndpoint = ({
  type,
  organizationId,
  selectedFromDate,
  selectedToDate,
  offset,
  timezone,
  selectedTags,
  selectedEvents,
}) => {
  switch (type) {
    case ENDPOINT_TYPES.analyticsCards:
      return `v1/organizations/${organizationId}/conversation_analytics/?start_datetime=${selectedFromDate}&end_datetime=${selectedToDate}&tag=${
        selectedTags?.value || ""
      }&event=${selectedEvents?.value || ""}`;
    case ENDPOINT_TYPES.agentPerformance:
      return `v1/organizations/${organizationId}/conversation_analytics/agent_performance/?start_datetime=${selectedFromDate}&end_datetime=${selectedToDate}&offset=${offset}&tag=${
        selectedTags?.value || ""
      }&event=${selectedEvents?.value || ""}`;
    case ENDPOINT_TYPES.analyticsCardsCsv:
      return `/v1/organizations/${organizationId}/conversation_analytics/csv/?start_datetime=${selectedFromDate}&end_datetime=${selectedToDate}&timezone=${timezone}&tag=${
        selectedTags?.value || ""
      }&event=${selectedEvents?.value || ""}`;
    case ENDPOINT_TYPES.agentPerformanceCsv:
      return `/v1/organizations/${organizationId}/conversation_analytics/agent_performance/csv/?start_datetime=${selectedFromDate}&end_datetime=${selectedToDate}&timezone=${timezone}&tag=${
        selectedTags?.value || ""
      }&event=${selectedEvents?.value || ""}`;
    case ENDPOINT_TYPES.analyticsCardsGraph:
      return `/v1/organizations/${organizationId}/conversation_analytics/graphs/?start_datetime=${selectedFromDate}&end_datetime=${selectedToDate}&tag=${
        selectedTags?.value || ""
      }&event=${selectedEvents?.value || ""}`;
    case ENDPOINT_TYPES.adPerformance:
      return `v1/organizations/${organizationId}/conversation_analytics/fb-ads-report/?from=${getFormattedTime(
        new Date(selectedFromDate.split("T")?.[0]),
        "YYYY-MM-DD",
      )}&to=${getFormattedTime(
        new Date(selectedToDate.split("T")?.[0]),
        "YYYY-MM-DD",
      )}`;
    default:
      return "incorrect type";
  }
};

export default getEndpoint;
