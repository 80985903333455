// ACTIONS
export const MIX_PANEL_BTN_CLICK_ACTION = "int_web_button_click";
export const MIX_PANEL_LINK_CLICK_ACTION = "int_web_link_click";
export const MIX_PANEL_EVENT_ACTION = "int_web_event";

// CATEGORIES
export const INTERAKT_WEB_ONBOARDING_SHOPIFY_SANDBOX =
  "int_web_onboarding_shopify_sandbox";
export const INTERAKT_WEB_ONBOARDING_TRADITIONAL_SANDBOX =
  "int_web_onboarding_traditional_sandbox";
export const INTERAKT_WEB_ONBOARDING_SHOPIFY_MARKETING_POST_SANDBOX =
  "int_web_onboarding_shopify_marketing_post_sandbox";
export const INTERAKT_WEB_ONBOARDING_SHOPIFY_SALES_POST_SANDBOX =
  "int_web_onboarding_shopify_sales_post_sandbox";
export const INTERAKT_WEB_ONBOARDING_TRADITIONAL_POST_SANDBOX =
  "int_web_onboarding_traditional_post_sandbox";
export const INTERAKT_WEB_CAMPAIGN = "int_web_campaign";
export const INTERAKT_WEB_EMBEDDED_SIGNUP = "int_web_embedded_signup";
