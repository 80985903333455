export const APP_RETRY_SECONDS = 1500;

export const PAGE_LOAD_ATTEMPT = 3;

export const WA_CATALOG_IN_PROGRESS = "IN_PROGRESS";

export const WA_CATALOG_FAILED = "FAILED";

export const WA_CATALOG_SUCCESS = "SUCCESS";

export const WA_CATALOG_SETUP_PENDING = "SETUP_PENDING";