export const flapMessageTypes = {
  loading: "loading",
  success: "success",
  error: "error",
};

export const flapMessageColors = {
  loading: "#000000",
  success: "#1F7717",
  error: "#D2301A",
};

export const catalogIdConnectionMessages = {
  successMessage: "Great! Your FB catalog is now connected to Interakt!",
  internalServerError:
    "There was some error in fetching your FB Catalog. Please try again",
  loadingMessage: "Checking your Catalog ID",
};

export const accordionTitles = {
  connectCatalogId: "Connect your FB Catalog to Interakt",
  productCollections: "Setup Messages for Product Collections & Catalogs",
  sendCatalogs: "Send out Catalogs in Campaigns",
  sendCatalogsInAutoReply: "Send out Catalogs in Auto Replies",
  sendAllEnquiries: "See all enquiries & orders you get from customers",
  helpCustomers:
    "Help customers place orders with Interakt's Autocheckout Workflow!",
  shopifyAutoCheckout: "Help customers checkout on Shopify",
};

export const connectToCatalogTitles = {
  provideBusinessCompliance: "Provide Compliance Info",
  setupCatalog: "Set up FB Catalog & Collections",
  giveAccess: "Give Catalog Access & Connect to WhatsApp account",
  enterCatalogId: "Enter Facebook Catalog ID",
};

export const connectToCatalogSubTitles = {
  provideBusinessCompliance: "This will appear in your WhatsApp Profile",
  allowInterakt:
    "Add Haptik (928974617157828) as Catalog Partner & connect to WA",
  willFetchProduct: "We will fetch products from this catalog",
};

export const CATALOG_SYNC_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const CATALOG_SYNC_STATUS_SUCCESS = "SUCCESS";
export const CATALOG_SYNC_STATUS_FAILED = "FAILED";

export const autoCheckoutErrorTypes = {
  TEMPLATE_CREATION_FAILED: "TEMPLATE_CREATION_FAILED",
  TEMPLATE_REJECTED: "TEMPLATE_REJECTED",
  CHECKOUT_MESSAGE_CREATE_OR_UPDATE_FAILED:
    "CHECKOUT_MESSAGE_CREATE_OR_UPDATE_FAILED",
  TEMPLATE_APPROVAL_TIMEOUT: "TEMPLATE_APPROVAL_TIMEOUT",
};

// catalog sources
export const FACEBOOK = "FACEBOOK";
export const SHOPIFY = "SHOPIFY";
export const INTERAKT = "INTERAKT";

// catalog connection status
export const CATALOG_CONNECTION_IN_PROGRESS = "IN_PROGRESS";
export const CATALOG_CONNECTION_FAILED = "FAILED";
export const CATALOG_CONNECTION_SUCCESS = "SUCCESS";
export const CATALOG_CONNECTION_SETUP_PENDING = "SETUP_PENDING";
export const CATALOG_CONNECTION_PARTNER_ADDED = "PARTNER_ADDED";
export const CATALOG_CONNECTION_PARTNER_SETUP_PENDING = "PARTNER_SETUP_PENDING";

// mixpanel events create catalog
export const INT_catalogCreate_initiate = "INT_catalogCreate_initiate";
export const INT_catalogCreate_uploadCSV = "INT_catalogCreate_uploadCSV";
export const INT_catalogCreate_selectCSV = "INT_catalogCreate_selectCSV";
export const INT_catalogCreate_downloadTemplate =
  "INT_catalogCreate_downloadTemplate";
export const INT_catalogCreate_downloadInstructions =
  "INT_catalogCreate_downloadInstructions";
export const INT_catalogCreate_closeUploadPopup =
  "INT_catalogCreate_closeUploadPopup";
export const INT_catalogCreate_complianceOpen =
  "INT_catalogCreate_complianceOpen";
export const INT_catalogCreate_complianceSubmit =
  "INT_catalogCreate_complianceSubmit";
export const INT_catalogCreate_setupFBCatalog =
  "INT_catalogCreate_setupFBCatalog";
export const INT_catalogCreate_catalogFBAccess =
  "INT_catalogCreate_catalogFBAccess";
export const INT_catalogCreate_catalogWAConnect =
  "INT_catalogCreate_catalogWAConnect";
export const INT_catalogCreate_catalogConnect =
  "INT_catalogCreate_catalogConnect";

// mixpanel events update catalog
export const INT_catalogUpdate_initiate = "INT_catalogUpdate_initiate";
export const INT_catalogUpdate_uploadCSV = "INT_catalogUpdate_uploadCSV";
export const INT_catalogUpdate_selectCSV = "INT_catalogUpdate_selectCSV";
export const INT_catalogUpdate_downloadTemplate =
  "INT_catalogUpdate_downloadTemplate";
export const INT_catalogUpdate_downloadInstructions =
  "INT_catalogUpdate_downloadInstructions";
export const INT_catalogUpdate_closeUploadPopup =
  "INT_catalogUpdate_closeUploadPopup";
export const INT_catalogUpdate_catalogOpen = "INT_catalogUpdate_catalogOpen";
export const INT_catalogUpdate_catalogDisconnectFB =
  "INT_catalogUpdate_catalogDisconnectFB";

// mixpanel events update catalog for shopify
export const INT_catalogCreate_initiate_shopify =
  "INT_catalogCreate_initiate_shopify";
export const INT_catalogUpdate_initiate_shopify =
  "INT_catalogUpdate_initiate_shopify";
export const INT_catalogCreate_installSalesChannel_shopify =
  "INT_catalogCreate_installSalesChannel_shopify";
export const INT_catalogCreate_altDropdown_shopify =
  "INT_catalogCreate_altDropdown_shopify";
export const INT_CatalogCreate_catalogFBSetup_shopify =
  "INT_CatalogCreate_catalogFBSetup_shopify";
export const INT_catalogCreate_catalogFBAccess_shopify =
  "INT_catalogCreate_catalogFBAccess_shopify";
export const INT_catalogCreate_catalogWAConnect_shopify =
  "INT_catalogCreate_catalogWAConnect_shopify";
export const INT_catalogCreate_catalogConnect_shopify =
  "INT_catalogCreate_catalogConnect_shopify";
