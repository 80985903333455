import { useSelector } from "react-redux";
import { getOrganizationOnboardedStatus } from "modules/App/AppSelectors";
import useActivePlan from "./useActivePlan";
import useIsResellerSignupSource from "./useIsResellerSignupSource";
import useIsSandboxMode from "./useIsSandboxMode";
import useIsSignupSourceTally from "./useIsSignupSourceTally";
import useIsAdsPlanSignupSource from "./useIsAdsPlanSignupSource";

const useShowWidgetPage = () => {
  const activePlan = useActivePlan();
  const isResellerSignupSource = useIsResellerSignupSource();
  const isSandboxMode = useIsSandboxMode();
  const isSignupSourceTally = useIsSignupSourceTally();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const isOrganizationOnboarded = useSelector(getOrganizationOnboardedStatus);

  const isSidebarOnboardedActive =
    isOrganizationOnboarded || isSandboxMode || isSignupSourceTally;
  const isResellerWithPlanNotStarted = !activePlan && isResellerSignupSource;
  const isAdsPlanNotStarted = isAdsPlanSignupSource && !activePlan;

  return (
    isSidebarOnboardedActive &&
    !isSignupSourceTally &&
    !isResellerWithPlanNotStarted &&
    !isAdsPlanNotStarted
  );
};

export default useShowWidgetPage;
