const HEADER_TYPES = {
  NONE: "NONE",
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  DOCUMENT: "DOCUMENT",
  MEDIA: "MEDIA",
};

export const HEADER_UI_NAMES = {
  NONE: "None",
  TEXT: "Text",
  IMAGE: "Image",
  VIDEO: "Video",
  DOCUMENT: "Document",
  MEDIA: "Media",
  FILE: "File",
};

export default HEADER_TYPES;
