import { useInfiniteQuery } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { INT_CAMPAIGN_USER_FILTERING_FAILED } from "constants/mixPanel/campaigns";
import {
  INTERAKT_WEB_CAMPAIGN,
  MIX_PANEL_EVENT_ACTION,
} from "constants/mixPanel/common";
import { WHATSAPP_OPTED_IN } from "constants/userAttributesContants";
import useOrganizationId from "customHooks/useOrganizationId";
import useIsOrgAllowedForOnlyCount from "useApi/Notification/useIsOrgAllowedForOnlyCount";
import { callApiV2 } from "util/apiCaller";
import { Mixpanel } from "util/tracking";

export const RQ_GET_CUSTOMERS = "RQ_GET_CUSTOMERS";

const LIST_PAGE_SIZE = 100;

const orgsListToShowNewEndpoint = [
  "ee20510a-e365-4bd3-915b-8e578a3e5b23", // MLI
  "261503c2-452f-4dd9-a060-7999bd89ad2e", // Dev Darshan
  "74d49d00-7a9b-4c70-82d8-76efab63a844", // Interakt Digest
  "ba4308f1-a506-44d2-a8c3-17380216cf91", // Coversation.ai
  "ec08fad5-5908-4d16-86d4-3bcf04d24379", // Joblett
  "b1fba3a9-0ed7-4add-b473-0f75f86b1434", // kohinoor
  "d37979bd-7d7b-42ab-a57e-9ad426f7d2fd", // Urdhva Management Pvt Ltd
];

function getAPIUrlType(orgId) {
  if (orgsListToShowNewEndpoint.includes(orgId)) {
    return `v2/organizations/${orgId}/customers/filter`;
  }

  return `v1/organizations/${orgId}/customers/list-new`;
}

const fetchCustomers = async ({
  orgId,
  filters,
  pageParam,
  dataSourceEs,
  getAll,
  isCampaignPage,
  onlyCount = false,
}) => {
  const currentPath = window.location.pathname;
  const id = uuidv4();

  try {
    const res = await callApiV2({
      endpoint: `${getAPIUrlType(
        orgId,
      )}/?offset=${pageParam}&limit=${LIST_PAGE_SIZE}${
        dataSourceEs ? "&data_source=es" : ""
      }${getAll ? "&all=true" : ""}${dataSourceEs ? "&data_source=es" : ""}${
        isCampaignPage ? "&is_via_campaign_page=true" : ""
      }${onlyCount ? "&only_count=true" : "&only_count=false"}`,
      method: "post",
      body: filters,
      isErrorSuppressed: true,
      timeout: null,
      shouldUseCampaignDomain: true,
    });

    if (res?.results?.result) {
      return { list: res.results.data, count: res.count };
    }

    throw res;
  } catch (error) {
    Mixpanel.track(INT_CAMPAIGN_USER_FILTERING_FAILED, {
      id,
      category: INTERAKT_WEB_CAMPAIGN,
      action: MIX_PANEL_EVENT_ACTION,
      status_code: error.status_code,
      response: Object.keys(error).length ? error : error.message,
      path: currentPath,
    });
    throw new Error(error.message);
  }
};

const useFetchCustomers = ({
  options = {},
  params = {
    getAll: false,
    filters: [],
    dataSourceEs: false,
    isCampaignPage: false,
    onlyCount: false,
  },
  select = (data) => data,
} = {}) => {
  const orgId = useOrganizationId();

  const {
    data: isOrgAllowedForOnlyCount = false,
  } = useIsOrgAllowedForOnlyCount();

  const isOnlyWhatsappOptedFilter =
    !!params.filters &&
    params.filters.length === 1 &&
    !!params.filters[0].filters &&
    params.filters[0].filters.length === 1 &&
    params.filters[0].filters[0].trait === WHATSAPP_OPTED_IN &&
    params.filters[0].filters[0].val === true;

  if (isOrgAllowedForOnlyCount === false || isOnlyWhatsappOptedFilter) {
    params.onlyCount = false;
  }

  const isCampaignPage = window.location.pathname.includes("/notification/");

  return useInfiniteQuery({
    queryKey: [RQ_GET_CUSTOMERS, orgId, params],
    queryFn: ({ pageParam = 0 }) =>
      fetchCustomers({
        ...params,
        pageParam,
        orgId,
        isCampaignPage,
      }),
    getNextPageParam: (_, pages) => pages.length * LIST_PAGE_SIZE,
    select,
    enabled:
      options.enabled === undefined ? !!orgId : !!orgId && !!options.enabled,
    ...options,
  });
};

export default useFetchCustomers;
