import React from "react";

const storage = ({ fill, ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_262_15741)">
        <path
          id="Vector"
          d="M2.66667 4C2.66667 5.10467 5.05467 6 8.00001 6C10.9453 6 13.3333 5.10467 13.3333 4C13.3333 2.89533 10.9453 2 8.00001 2C5.05467 2 2.66667 2.89533 2.66667 4Z"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.66667 4V8C2.66667 9.10467 5.05467 10 8.00001 10C8.74534 10 9.45534 9.94267 10.1 9.83933"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M13.3333 8V4"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M2.66667 8V12C2.66667 13.1047 5.05467 14 8.00001 14C8.10467 14 8.208 13.9987 8.31067 13.9967"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M10.6667 12.666H14.6667"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M12.6667 10.666L14.6667 12.666L12.6667 14.666"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_262_15741">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default storage;
