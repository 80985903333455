import { useEffect } from "react";

import useEventListener from "customHooks/useEventListener";

const useOnKeyPress = (targetKeys = [], ref = null, callback = null) => {
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, [ref?.current]);

  const handleKeyDown = (e) => {
    if (targetKeys.includes(e.key) && callback) {
      e.stopPropagation();
      callback(e);
    }
  };

  useEventListener("keydown", handleKeyDown, ref?.current);
};

export default useOnKeyPress;
