import React from "react";
import Box from "konnekt/Box";
import AccessControl from "components/PermissionLocks/AccessControl";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon from "konnekt/Icon";
import { useDispatch } from "react-redux";
import Label from "konnekt/Label";

const Container = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e5f5f3;
    border-radius: 4px;
  }
  &:hover p {
    color: #00a68b;
  }
  &:hover div svg {
    stroke: ${(props) => (props.stroke ? "#00a68b" : "none")};
    fill: ${(props) => (props.stroke ? "none" : "#00a68b")};
  }
`;

const MenuItem = ({ item, toggleSettings }) => {
  const dispatch = useDispatch();

  if (item.button) {
    return (
      <Container
        onClick={() => {
          if (item.onClick) {
            item.onClick(dispatch);
          }
        }}
        stroke={item.strokeColor}
      >
        {item.iconName && (
          <Icon
            iconSize="small"
            iconName={item.iconName}
            stroke={item.strokeColor}
            color={item.color}
          />
        )}
        <Label variant="text6" color="#464D6D">
          {item.label}
        </Label>
      </Container>
    );
  }

  if (item.accessControlProps) {
    return (
      <AccessControl {...item.accessControlProps}>
        <Link
          to={item.url}
          target={item.target ?? "_self"}
          style={{
            textDecoration: "none",
            width: "100%",
          }}
          onClick={() => {
            if (toggleSettings) {
              toggleSettings();
            }
          }}
        >
          <Container stroke={item.strokeColor}>
            {item.iconName && (
              <Icon
                iconSize="small"
                iconName={item.iconName}
                color={item.color}
                stroke={item.strokeColor}
              />
            )}
            <Label variant="text6" color="#464D6D">
              {item.label}
            </Label>
          </Container>
        </Link>
      </AccessControl>
    );
  }
  return (
    <Link
      to={item.url}
      target={item.target ?? "_self"}
      style={{
        textDecoration: "none",
        width: "100%",
      }}
      onClick={() => {
        if (toggleSettings) {
          toggleSettings();
        }
      }}
    >
      <Container stroke={item.strokeColor}>
        {item.iconName && (
          <Icon
            iconSize="small"
            iconName={item.iconName}
            color={item.color}
            stroke={item.strokeColor}
          />
        )}
        <Label variant="text6" color="#464D6D">
          {item.label}
        </Label>
      </Container>
    </Link>
  );
};

export default MenuItem;
