/**
 * Memoizes a promise-returning function, caching its results for faster subsequent calls with the same arguments.
 *
 * @param {Function} fn - The function to memoize.
 * @return {Function} - The memoized function.
 */
export function memoizePromiseFn(fn: Function) {
  const cache = new Map();

  return (...args: Array<any>) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key);
    }

    cache.set(
      key,
      fn(...args).catch((error: Error) => {
        // Delete cache entry if API call fails
        cache.delete(key);
        return Promise.reject(error);
      }),
    );

    return cache.get(key);
  };
}
