export const NON_LOGGED_IN_PAGES = [
  "/login",
  "^/signup$",
  "^/shopify/signup$",
  "^/signup/external$",
  "^/reset-password$",
];

export const getIsNonLoggedInPage = (pathname) =>
  NON_LOGGED_IN_PAGES.some((string) => new RegExp(string).test(pathname));
