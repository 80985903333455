import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Button from "konnekt/Buttons";
import GeneralLink from "konnekt/GeneralLink";
import { getRemainingTrialDays } from "modules/Payments/PaymentSelectors";
import { setSandboxRibbonDetails } from "modules/App/AppActions";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";
import { selectBrandName } from "useApi/Partner/useWhitelabeledDetails";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import InpageRibbon from "./InpageRibbon";

const Text = ({ text, linkText, link, linkTo, ...props }) => (
  <>
    {text ? `${text} ` : ""}
    {linkText && (link || linkTo) && (
      <GeneralLink
        fontWeight={600}
        fontSize="12px"
        lineHeight="1.2"
        target={link && "_blank"}
        color="white"
        textDecoration="none"
        href={link || linkTo}
        p="4px"
        borderRadius="3px"
        ml="8px"
        onClick={(e) => {
          if (linkTo) {
            if (props.history) {
              props.history.push(linkTo);
            }
            e.preventDefault();
          }
        }}
        display="inline-block"
        {...props}
      >
        <Button
          p="4px"
          minHeight="20px"
          type="secondary"
          cursor="pointer"
          color="primaryDark"
          text={linkText}
        />
      </GeneralLink>
    )}
  </>
);

const SandboxNotificationRibbon = () => {
  const remainingTrialDays = useSelector(getRemainingTrialDays);
  const isSignupSourceTally = useIsSignupSourceTally();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: brandName } = useCurrentWhitelabeledDetails(selectBrandName);

  const ref = React.useCallback((node) => {
    if (node !== null) {
      dispatch(
        setSandboxRibbonDetails({
          visibility: true,
          height: node.getBoundingClientRect().height,
        }),
      );
    } else {
      dispatch(
        setSandboxRibbonDetails({
          visibility: false,
          height: 0,
        }),
      );
    }
  }, []);

  const trialDaysData = {
    "1_2": {
      text: `Welcome to ${brandName} - A powerful WhatsApp Business platform to grow your online sales.`,
      link: `https://www.youtube.com/watch?v=shDnT8z5gk4`,
      linkText: "Watch how",
    },
    "3_4": {
      text: `Now experience the full range of Interakt's platform features without the hassle of connecting your number first.`,
      link:
        "http://www.interakt.shop/resource-center/how-does-sandbox-mode-work", // link will be provided later
      linkText: "Learn how",
    },
    "5_6": {
      text: `Watch this video & see how a leading D2C brand achieved 13% growth on their repeat purchases using WhatsApp.`,
      link: `https://www.youtube.com/watch?v=UvLvb2UfGJ8`,
      linkText: "Watch Now",
    },
    "7_8": {
      text: `Join our product expert for a live demo session of Interakt's powerful platform features and solution offerings.`,
      link: `https://www.interakt.shop/whatsapp-business-api-live-demo`,
      linkText: "Register Now",
    },
    "9_10": {
      text: `Want to learn how leading Indian D2C brands are driving 40X engagement to win customers on WhatsApp.`,
      link: "https://www.interakt.shop/case-study/the-state-plate", // link will be provided later
      linkText: "Read Now",
    },
    "11_12_13": {
      text: `Only {X} Days left before your free trial ends. To continue the unlimited experience, connect your number with Interakt.`,
      linkTo: "/signup/whatsapp?redirectToPayment=true",
      linkText: "Connect Now",
    },
    14: {
      text: `Your free trial period ends today. Please start your subscription to enjoy uninterrupted access to Interakt.`,
      linkTo: "/billing/subscription",
      linkText: "Start subscription",
    },
  };
  const location = useLocation();

  const ribbonData = useMemo(() => {
    let data = {};
    const trialDays = String(
      15 - (remainingTrialDays < 0 ? 0 : remainingTrialDays),
    ); // calculating the free trial days from remaining trial days
    Object.keys(trialDaysData).forEach((key) => {
      if (key?.split("_")?.includes(trialDays)) {
        data = {
          ...trialDaysData[key],
        };
        if (key === "11_12_13") {
          data.text = data.text.replace("{X}", remainingTrialDays);
        }
        data.text = (
          <Text
            text={data.text}
            linkText={data.linkText}
            link={data.link}
            linkTo={data.linkTo}
            history={history}
          />
        );
      }
    });
    return data;
  }, [remainingTrialDays, history]);

  if (isSignupSourceTally) {
    return null;
  }

  if (location.pathname === "/signup/external/whatsapp") {
    return null;
  }

  if (!ribbonData?.text) {
    return null;
  }

  return (
    <div ref={ref}>
      <InpageRibbon
        forAllPlans
        TextCompo={ribbonData}
        bg="primaryDark"
        p="8px"
        color="white"
      />
    </div>
  );
};

export default SandboxNotificationRibbon;
