/** campaign status */
export const DRAFT = "Draft";
export const LIVE = "Live";
export const PAUSE = "Pause";
export const PAUSED = "Paused";
export const CANCELLED = "Cancelled";
export const FAILED = "Failed";
export const COMPLETED = "Completed";

export const campaignStatusColorMap = {
  [LIVE]: "#0DB350",
  [DRAFT]: "#929AB9",
  [PAUSE]: "#929AB9",
  [PAUSED]: "#929AB9",
  [CANCELLED]: "#929AB9",
  [FAILED]: "#929AB9",
  [COMPLETED]: "#929AB9",
}

export const campaignStatusTooltipMap = {
  [LIVE]: "The campaign is currently being sent to WhatsApp.",
  [DRAFT]: "",
  [PAUSE]: "",
  [PAUSED]: "",
  [CANCELLED]: "",
  [FAILED]: "",
  [COMPLETED]: "The campaign has been sent to WhatsApp. Delivery updates may take time.",
}

/** campaign status */

/** campaign types */
export const ONETIME = "OneTime";
export const ONGOING = "Ongoing";
export const PUBLIC_API = "PublicAPI";
export const API_CAMPAIGN = "api_campaign";
export const SCHEDULED = "Scheduled";
export const IMMEDIATE = "Immediate";
/** campaign types */

/** Sample campaigns */
export const ABANDONED_CHECKOUT_SHOPIFY_SAMPLE_NAME =
  "Abandoned Checkout Shopify";
export const ORDER_PLACED_SHOPIFY_COD_SAMPLE_NAME =
  "Order Placed Shopify (CoD)";
export const ORDER_PLACED_SHOPIFY_PREPAID_SAMPLE_NAME =
  "Order Placed Shopify (Prepaid)";
export const COD_ORDER_DOUBLE_CONFIRMATION_SHOPIFY_SAMPLE_NAME =
  "CoD Order Double Confirmation Shopify";
/** Sample campaigns */
