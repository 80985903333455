export const RESELLER_OFFLINE_NON_ONBOARDED = "RESELLER_OFFLINE_NON_ONBOARDED";
export const RESELLER_ONLINE_NON_ONBOARDED = "RESELLER_ONLINE_NON_ONBOARDED";
export const RESELLER_WHITELABLED_OFFLINE_NON_ONBOARDED =
  "RESELLER_WHITELABLED_OFFLINE_NON_ONBOARDED";
export const RESELLER_WHITELABLED_ONLINE_NON_ONBOARDED =
  "RESELLER_WHITELABLED_ONLINE_NON_ONBOARDED";

export const RESELLER_OFFLINE_ONBOARDED_PLAN_ENDED =
  "RESELLER_OFFLINE_ONBOARDED_PLAN_ENDED";
export const RESELLER_ONLINE_ONBOARDED_PLAN_ENDED =
  "RESELLER_ONLINE_ONBOARDED_PLAN_ENDED";
export const RESELLER_WHITELABLED_OFFLINE_ONBOARDED_PLAN_ENDED =
  "RESELLER_WHITELABLED_OFFLINE_ONBOARDED_PLAN_ENDED";
export const RESELLER_WHITELABLED_ONLINE_ONBOARDED_PLAN_ENDED =
  "RESELLER_WHITELABLED_ONLINE_ONBOARDED_PLAN_ENDED";

export const RESELLER_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN =
  "RESELLER_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN";
export const RESELLER_ONLINE_ONBOARDED_NO_ACTIVE_PLAN =
  "RESELLER_ONLINE_ONBOARDED_NO_ACTIVE_PLAN";
export const RESELLER_WHITELABLED_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN =
  "RESELLER_WHITELABLED_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN";
export const RESELLER_WHITELABLED_ONLINE_ONBOARDED_NO_ACTIVE_PLAN =
  "RESELLER_WHITELABLED_ONLINE_ONBOARDED_NO_ACTIVE_PLAN";

export const RESELLER_OFFLINE_ONBOARDED_ACTIVE_PLAN =
  "RESELLER_OFFLINE_ONBOARDED_ACTIVE_PLAN";
export const RESELLER_ONLINE_ONBOARDED_ACTIVE_PLAN =
  "RESELLER_ONLINE_ONBOARDED_ACTIVE_PLAN";
export const RESELLER_WHITELABLED_OFFLINE_ONBOARDED_ACTIVE_PLAN =
  "RESELLER_WHITELABLED_OFFLINE_ONBOARDED_ACTIVE_PLAN";
export const RESELLER_WHITELABLED_ONLINE_ONBOARDED_ACTIVE_PLAN =
  "RESELLER_WHITELABLED_ONLINE_ONBOARDED_ACTIVE_PLAN";
