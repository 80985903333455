/* eslint-disable no-continue */
function isAplhanumeric(char) {
  const x = `${char || ""}`.charCodeAt(0);
  if (!char || Number.isNaN(x)) {
    return false;
  }
  return !!(
    (x >= 65 && x <= 90) ||
    (x >= 97 && x <= 122) ||
    (x >= 48 && x <= 57)
  );
}

// TODO :- Add test cases for the functions in this
function getIndexes(wildcard, text = "") {
  const indices = [];
  let tempText = text;
  let wildcardOccurences = text.split(wildcard).length - 1;
  for (let i = 0; i < tempText.length; i += 1) {
    if (tempText[i] === wildcard) {
      if (indices.length % 2) {
        if (tempText[i - 1] === " " || isAplhanumeric(tempText[i + 1])) {
          indices.push(i - 1);
          tempText = tempText.replace(wildcard, " ");
        } else {
          indices.push(i);
        }
      } else if (isAplhanumeric(tempText[i - 1])) {
        wildcardOccurences -= 1;
        continue;
      } else if (
        typeof tempText[i + 1] === "undefined" ||
        tempText[i + 1] === " "
      ) {
        if (wildcardOccurences === 1 || tempText[i + 1] === wildcard) {
          wildcardOccurences -= 1;
          continue;
        } else {
          indices.push(i + 1);
          tempText = tempText.replace(wildcard, " ");
        }
      } else {
        if (wildcardOccurences === 1 || tempText[i + 1] === wildcard) {
          wildcardOccurences -= 1;
          continue;
        }
        indices.push(i);
      }
      wildcardOccurences -= 1;
    } else if (tempText[i].charCodeAt(0) === 10 && indices.length % 2) {
      indices.pop();
    }
  }
  if (indices.length % 2) {
    // we have unclosed tags
    indices.pop();
  }
  return { indices, text: tempText };
}

function injectTags(text, indices, rule) {
  let e = 0;
  let tempText = text;
  indices.forEach((value, index) => {
    const tag = index % 2 ? rule.closeTag : rule.openTag;
    let v = value;
    v += e;
    tempText = tempText.substr(0, v) + tag + tempText.substr(v + 1);
    e += tag.length - 1;
  });
  return tempText;
}

function execRule(text, rule) {
  const { indices, text: formattedText } = getIndexes(
    rule.wildcard,
    text.toString(),
  );
  return injectTags(formattedText, indices, rule);
}

function parseText(text, rules) {
  const final = rules.reduce((transformed, rule) => {
    return execRule(transformed, rule);
  }, text);
  return final.replace(/\n/g, "<br />");
}

function parseTextWithoutNReplacement(text, rules) {
  const final = rules.reduce((transformed, rule) => {
    return execRule(transformed, rule);
  }, text);
  return final.replace(/\n/g, " ");
}

export const whatsappRules = [
  {
    closeTag: "</strong>",
    openTag: "<strong>",
    wildcard: "*",
  },
  {
    closeTag: "</i>",
    openTag: "<i>",
    wildcard: "_",
  },
  {
    closeTag: "</del>",
    openTag: "<del>",
    wildcard: "~",
  },
];

export function whatsappFormat(text, rules) {
  return parseText(String(text) ? String(text) : "", rules || whatsappRules);
}

export function whatsappFormatForConversation(text, rules) {
  return parseTextWithoutNReplacement(
    String(text) ? String(text) : "",
    rules || whatsappRules,
  );
}
