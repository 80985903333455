import moment from "moment";
import paymentGateway from "constants/paymentGateway.json";
import {
  CHANGE_PLAN_ROUTE,
  CHOOSE_PLAN_ROUTE,
} from "constants/enums/paymentMethods";
import { PAID_PLAN, TRIAL_PLAN, FREE_PLAN } from "constants/PlanTypes";
import { getIsSandboxMode } from "modules/App/AppSelectors";
import { PAYMENT_DUE_DAYS_LIMIT } from "constants/paymentConstants";

export const getCreditHistoryFetchStatus = (state) =>
  state.payment.isFetchingCreditHistory;
export const getCreditBalance = (state) => state.payment.creditBalance;
export const getCreditCurrency = (state) => state.payment.currency;
export const getCreditHistoryList = (state) => state.payment.creditHistoryList;
export const getDeletePaymentMethodStatus = (state) =>
  state.payment.isDeletingPaymentMethod;
export const getSetDefaultPaymentMethodStatus = (state) =>
  state.payment.isSettingDefaultPaymentMethod;
export const getAddPaymentMethodStatus = (state) =>
  state.payment.isAddingPaymentMethods;
export const getAttachPaymentMethodStatus = (state) =>
  state.payment.isAttachingPaymentMethod;
export const getFetchInvoiceStatus = (state) =>
  state.payment.isFetchingPaymentInvoice;
export const getAllPaymentmethodsLoadingStatus = (state) =>
  state.payment.isFetchingPaymentMethods;
export const getAllPaymentMethods = (state) => state.payment.paymentMethods;
export const getDefaultPaymentMethod = (state) =>
  state.payment.paymentMethods && state.payment.paymentMethods.length > 0
    ? state.payment.paymentMethods.filter((item) => !!item.is_default)[0]
    : null;
export const getPaymentInvoice = (state) => state.payment.paymentInvoice;
export const getPayment3dSecurePinUrl = (state) =>
  state.payment.payment3dSecurePinUrl;
export const getFetchSubscriptionStatus = (state) =>
  state.payment.isFetchingSubscription;
export const getActiveSubscriptionList = (state) =>
  state.payment.activeSubscriptionList;
export const getActiveSubscriptionCount = (state) =>
  !!state.payment.activeSubscriptionList
    ? state.payment.activeSubscriptionList.length
    : 0;
export const getAllSubscriptionList = (state) =>
  state.payment.allSubscriptionList;
export const getAllTrialSubscriptionList = (state) =>
  state.payment.trialSubscriptionList;
export const getAllPaidSubscriptionList = (state) =>
  state.payment.paidSubscriptionList;
export const getAllSubscriptionCount = (state) =>
  state.payment.allSubscriptionList
    ? state.payment.allSubscriptionList.length
    : 0;
export const getPaymentStatus = (state) => state.payment.isPaymentDone;
export const getPaymentProcessStatus = (state) =>
  state.payment.isProcessingPayment;
export const getPaymentProcessStatusMessage = (state) =>
  state.payment.paymentProcessStatusMessages;
export const getPaymentError = (state) => state.payment.paymentError;
export const getPaymentInvoiceList = (state) =>
  state.payment.paymentInvoiceList;
export const getPaymentInvoiceFetchStatus = (state) =>
  state.payment.isFetchingPaymentInvoiceList;
export const getUncollectiblePaymentStatus = (state) =>
  state.payment.isHaveUncollectiblePayment;
export const getOpenInvoicePaymentStatus = (state) =>
  state.payment.isHaveOpenInvoice;
export const getPendingInvoiceNotificationStatus = (state) =>
  state.payment.isShowPendingInvoiceNotification;
export const getRestrictOTCStatus = (state) => {
  if (state.payment.isShowPendingInvoiceNotification) {
    if (state.payment.paymentInvoiceList) {
      for (
        let index = 0;
        index < state.payment.paymentInvoiceList.length;
        index += 1
      ) {
        if (
          !state.payment.paymentInvoiceList[index].paid &&
          state.payment.paymentInvoiceList[index].status === "open"
        ) {
          const paymentDueDays = moment(new Date()).diff(
            moment(new Date(state.payment.paymentInvoiceList[index].created)),
            "days",
          );
          if (paymentDueDays >= 7) {
            return true;
          }
        }
      }
    }
  }
  return false;
};
export const getRestrictCampaignStatusForZoho = (state) => {
  if (state.payment.isShowPendingInvoiceNotification) {
    if (
      Array.isArray(
        state.payment.paymentInvoiceList[0]?.organization_invoices,
      ) &&
      state.payment.paymentInvoiceList[0]?.organization_invoices?.length
    ) {
      for (
        let index = 0;
        index <
        state.payment.paymentInvoiceList[0]?.organization_invoices?.length;
        index += 1
      ) {
        if (
          !["Paid", "Cancelled"].includes(
            state.payment.paymentInvoiceList[0].organization_invoices[index]
              ?.status,
          )
        ) {
          const paymentDueDays = moment(new Date()).diff(
            moment(
              new Date(
                state.payment.paymentInvoiceList[0].organization_invoices[
                  index
                ]?.invoice_date,
              ),
            ),
            "days",
          );
          if (paymentDueDays >= PAYMENT_DUE_DAYS_LIMIT) {
            return true;
          }
        }
      }
    }
  }
  return false;
};
export const getNotificationUsageFetchStatus = (state) =>
  state.payment.isFetchingNotificationUsage;
export const getNotificationUsageList = (state) =>
  state.payment.notificationUsageList;
export const getNotificationUsageTotalCount = (state) =>
  state.payment.notificationUsageTotalCount;
export const getBillingCycleFetchStatus = (state) =>
  state.payment.isFetchingBillingCycle;
export const getBillingCycleRangeList = (state) =>
  state.payment.billingCycleDateRanges;
export const getUniqueUserCountFetchStatus = (state) =>
  state.payment.isFetchingUniqueUserCount;
export const getUniqueUserCountList = (state) =>
  state.payment.uniqueUserCountList;
export const getUniqueUserTotalCount = (state) =>
  state.payment.uniqueUserTotalCount;
export const getOrganizationDetailModalState = (state) =>
  state.payment.organizationDetalModalState;
export const getUsageHistoryActiveTab = (state) =>
  state.payment.usageHistoryActiveTab;
export const getUsageHistoryCalenderModalState = (state) =>
  state.payment.isOpenUsageHistoryCalender;
export const getUsageHistoryCalenderActiveYear = (state) =>
  state.payment.usageHistoryCalenderFields.activeYear;
export const getUsageHistoryCalenderActiveMonth = (state) =>
  state.payment.usageHistoryCalenderFields.activeMonth;
export const getAddPaymentMethodModalState = (state) =>
  state.payment.isOpenAddPaymentModal;
export const getEditNameModalState = (state) =>
  state.payment.isOpenEditNameModal;
export const getEditEmailModalState = (state) =>
  state.payment.isOpenEditEmailModal;
export const getEditAddressModalState = (state) =>
  state.payment.isOpenEditAddressModal;
export const getEditGSTNumberModalState = (state) =>
  state.payment.isOpenEditGSTNumberModal;
export const getEditGSTNumberModalStateMobile = (state) =>
  state.payment.isOpenEditGSTNumberModalMobile;
export const getSubscriptionAPICalledStatus = (state) =>
  state.payment.isFetchSubscriptionAPIDone;
export const getIsPaymentDoneRibbonStatus = (state) =>
  state.payment.isPaymentDoneRibbon;
export const getIsDaysLeftFreeTrailRibbon = (state) =>
  state.payment.isDaysLeftFreeTrailRibbon;
export const getSubscriptionExpiredRibbonStatus = (state) =>
  state.payment.SubscriptionExpiredRibbonStatus;
export const getFirstPendingInvoice = (state) => {
  let pendingInvoices = [];
  if (
    state.payment.paymentInvoiceList &&
    state.payment.paymentInvoiceList.length
  ) {
    pendingInvoices = state.payment.paymentInvoiceList.filter(
      (item) => item.status !== "paid",
    );
  }
  if (pendingInvoices.length > 0) {
    return pendingInvoices[0];
  }
  return {};
};
export const getFreeTrialApplicableStatus = (state) => {
  const isEmbeddedSignupEnabled =
    state.app.organizations?.[0]?.organization_id?.is_embedded_signup_enabled;
  let isHaveAnyPaidSubscription = false;
  let isHaveTrialSubscription = false;
  let isFreePlanExists = false;
  if (
    state.payment.allSubscriptionList &&
    state.payment.allSubscriptionList.length > 0
  ) {
    state.payment.allSubscriptionList.forEach((item) => {
      if (item?.subscription?.plan_type === PAID_PLAN) {
        isHaveAnyPaidSubscription = true;
      }
      if (item?.subscription?.plan_type === TRIAL_PLAN) {
        isHaveTrialSubscription = true;
      }
      if (item?.subscription?.plan_type === FREE_PLAN) {
        isFreePlanExists = true;
      }
    });
  }
  return (
    isEmbeddedSignupEnabled &&
    !isHaveAnyPaidSubscription &&
    isHaveTrialSubscription &&
    !isFreePlanExists
  );
};
export const getPaidSubscriptionStatus = (state) => {
  let isHaveAnyPaidSubscription = false;
  if (
    state.payment.allSubscriptionList &&
    state.payment.allSubscriptionList.length > 0
  ) {
    state.payment.allSubscriptionList.forEach((item) => {
      if (item?.subscription?.plan_type === PAID_PLAN) {
        isHaveAnyPaidSubscription = true;
      }
    });
  }
  return isHaveAnyPaidSubscription;
};

export const getFreeTrialActiveStatus = (state) => {
  let isFreeTrialActive = false;
  if (
    state.payment.activeSubscriptionList &&
    state.payment.activeSubscriptionList.length > 0
  ) {
    state.payment.activeSubscriptionList.forEach((item) => {
      if (item?.subscription?.plan_type === TRIAL_PLAN) {
        isFreeTrialActive = true;
      }
    });
  }
  return isFreeTrialActive;
};

export const getRemainingTrialDays = (state) => {
  const isSandboxMode = getIsSandboxMode(state);
  let trialDaysRemaining = 0;
  if (
    state.payment.activeSubscriptionList &&
    state.payment.activeSubscriptionList.length > 0
  ) {
    state.payment.activeSubscriptionList = state.payment.activeSubscriptionList.filter(
      (item) => item && item.subscription && item.subscription.is_active,
    );
    if (state.payment.activeSubscriptionList) {
      trialDaysRemaining = moment(
        new Date(state.payment.activeSubscriptionList[0].subscription_end_date),
      ).diff(moment(), "days");
    }

    return trialDaysRemaining + 1;
  }
  return isSandboxMode ? trialDaysRemaining : trialDaysRemaining + 1;
};

export const getIsTodayFirstTrailDay = (state) => {
  if (
    state.payment.activeSubscriptionList &&
    state.payment.activeSubscriptionList.length > 0
  ) {
    state.payment.activeSubscriptionList = state.payment.activeSubscriptionList.filter(
      (item) => item && item.subscription && item.subscription.is_active,
    );
    if (state.payment.activeSubscriptionList) {
      const diffFromStartDate = moment(new Date()).diff(
        moment(
          new Date(
            state.payment.activeSubscriptionList[0].subscription_start_date,
          ),
        ),
        "days",
      );
      return !diffFromStartDate;
    }
    return false;
  }
  return false;
};

export const getTotalFreeTrailDays = (state) => {
  const isSandboxMode = getIsSandboxMode(state);
  let totalFreeTrailDays = 0;
  if (
    state.payment.activeSubscriptionList &&
    state.payment.activeSubscriptionList.length > 0
  ) {
    state.payment.activeSubscriptionList = state.payment.activeSubscriptionList.filter(
      (item) => item && item.subscription && item.subscription.is_active,
    );
    if (state.payment.activeSubscriptionList) {
      totalFreeTrailDays = moment(
        new Date(state.payment.activeSubscriptionList[0].subscription_end_date),
      ).diff(
        moment(
          new Date(
            state.payment.activeSubscriptionList[0].subscription_start_date,
          ),
        ),
        "days",
      );
    }
    return isSandboxMode ? totalFreeTrailDays : totalFreeTrailDays + 1;
  }
  return isSandboxMode ? totalFreeTrailDays : totalFreeTrailDays + 1;
};

export const getShopifyRedirectingState = (state) =>
  state.payment.isRedirectingToShopify;

export const getIfOrganizationHasAccessToFeature = (
  state,
  shouldHavePermissions,
) => {
  if (shouldHavePermissions && shouldHavePermissions.length > 0) {
    const userPermissions = [...state.payment.activeSubscriptionFeatureList];
    if (userPermissions.length > 0) {
      const leftPermissions = shouldHavePermissions.filter(
        (item) => userPermissions.indexOf(item) !== -1,
      );
      return leftPermissions && leftPermissions.length > 0;
    }
    return false;
  }
  return true;
};

export const getIfOrgHasPaymentEnabled = (state) =>
  state.app &&
  state.app.organizations &&
  state.app.organizations[0] &&
  state.app.organizations[0].organization_id?.is_payment_enabled;

export const getPaymentPlanUpdatingState = (state) =>
  state.payment.isUpdatingPaymentPlan;

export const getIsPaymentDoneFlow = (state) => {
  let isPaymentDoneFlow = false;
  if (state.payment.activeSubscriptionList?.length > 0) {
    state.payment.activeSubscriptionList.forEach((sub) => {
      if (
        [
          paymentGateway.STRIPE,
          paymentGateway.SHOPIFY,
          paymentGateway.ZOHO,
          paymentGateway.MANUAL,
        ].includes(sub?.payment_gateway) &&
        sub?.subscription?.plan_type === PAID_PLAN
      ) {
        isPaymentDoneFlow = true;
      }
    });
  }
  return isPaymentDoneFlow;
};

export const getIsTrialPeriodOver = (state) => {
  let isTrialPeriodOver = true;
  if (state.payment.activeSubscriptionList?.length > 0) {
    state.payment.activeSubscriptionList.forEach((sub) => {
      if (sub?.subscription?.plan_type === TRIAL_PLAN) {
        isTrialPeriodOver = false;
      }
    });
  }
  return isTrialPeriodOver;
};

export const getIfFeatureHasFeatureAccessByPaymentPlan = (
  state,
  shouldHavePermissions,
) => {
  if (window.localStorage.getItem("is_haptik_user")) {
    return false;
  }
  const isPaymentEnabled = getIfOrgHasPaymentEnabled(state);
  if (!isPaymentEnabled) {
    return true;
  }
  return getIfOrganizationHasAccessToFeature(state, shouldHavePermissions);
};

export const getIfFeatureIsVanishedByPaymentPlan = (
  state,
  shouldHaveVanishFeatures,
) => {
  if (
    Array.isArray(shouldHaveVanishFeatures) &&
    shouldHaveVanishFeatures.length > 0
  ) {
    const vanishFeatures = [
      ...state.payment.activeSubscriptionVanishFeatureList,
    ];
    if (vanishFeatures.length > 0) {
      const leftVanishFeatures = shouldHaveVanishFeatures.filter(
        (item) => vanishFeatures.indexOf(item) !== -1,
      );
      return (
        leftVanishFeatures &&
        leftVanishFeatures.length === shouldHaveVanishFeatures.length
      );
    }
    return false;
  }
  return false;
};

export const getRedirectUrlForPlanUpgrade = (state) => {
  if (
    state.payment.activeSubscriptionList?.length > 0 &&
    state.payment.activeSubscriptionList[0]?.subscription?.plan_type ===
      PAID_PLAN
  ) {
    return CHANGE_PLAN_ROUTE;
  }
  return CHOOSE_PLAN_ROUTE;
};

export const getOrganizationActivePaymentPlan = (state) => {
  if (state.payment.activeSubscriptionList?.length > 0) {
    return state.payment.activeSubscriptionList[0].subscription;
  }
  return null;
};

export const getIfOrganizationIsOnFreePlan = (state) => {
  if (state.payment.activeSubscriptionList?.length > 0) {
    const activeSubscription = state.payment.activeSubscriptionList[0];
    const { subscription = {} } = activeSubscription;
    return subscription?.plan_type === FREE_PLAN;
  }
  return false;
};

export const getIfOrganizationIsOnPaidPlan = (state) => {
  if (state.payment.activeSubscriptionList?.length > 0) {
    const activeSubscription = state.payment.activeSubscriptionList[0];
    const { subscription = {} } = activeSubscription;
    return subscription?.plan_type === PAID_PLAN;
  }
  return false;
};

export const getIfOrganizationIsStripePaymentGateway = (state) => {
  if (state.payment.activeSubscriptionList?.length > 0) {
    const activeSubscription = state.payment.activeSubscriptionList[0];
    const { subscription = {} } = activeSubscription;
    return subscription?.payment_gateway === paymentGateway.STRIPE;
  }
  return false;
};

export const getIfOrganizationIsSubscriptionActive = (state) => {
  if (state.payment.activeSubscriptionList?.length > 0) {
    const activeSubscription = state.payment.activeSubscriptionList[0];
    const { subscription = {} } = activeSubscription;
    return subscription?.is_active;
  }
  return false;
};

export const getWhatsappConversationCharges = (state) =>
  state.payment.whatsappConversationChargesData;
