import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";

export const RQ_CAMPAIGN_BY_ID = "RQ_CAMPAIGN_BY_ID";

const fetchCampaignById = async ({ campaignId, orgId }) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/campaigns/${campaignId}/`,
  });

  if (res.result) {
    return res.data;
  }

  throw new Error(res.message);
};

const useCampaignById = (id, select = (data) => data, options = {}) => {
  const orgId = useOrganizationId();

  return useQuery(
    [RQ_CAMPAIGN_BY_ID, orgId, id],
    () =>
      fetchCampaignById({
        campaignId: id,
        orgId,
      }),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined
          ? !!orgId && !!id
          : !!orgId && !!id && !!options.enabled,
    },
  );
};

export default useCampaignById;
