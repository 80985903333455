import { getActiveSubscriptionList } from "modules/Payments/PaymentSelectors";
import { useSelector } from "react-redux";

const useActivePlanV2 = () => {
  const activePlan = useSelector(
    (state) => getActiveSubscriptionList(state)?.[0]?.subscription?.name,
  );

  return activePlan;
};

export default useActivePlanV2;
