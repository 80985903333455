import {
  UPDATE_ACTIVE_SMART_CARD,
  UPDATE_PERSONAL_DETAILS_DATA,
  SHOW_SEARCH_SECTION,
  CLOSE_SEARCH_SECTION,
  SET_SEARCH_ACTIVE_CONVERSATION_ID,
  IS_FINDING_SEARCH_MESSAGE,
  CHANGE_SANDBOX_MODAL_VISIBILITY,
  CHANGE_SPAM_MODAL_VISIBILITY,
  SET_MULTI_SELECT_CHATS,
  RESET_MULTI_SELECT_STATE,
  ENABLE_MULTI_SELECT_STATE,
  NEW_CHAT_LOADING,
  SET_IS_REPLY_TO_MESSAGE_COMPOSER_ACTIVE,
  CLOSE_REPLY_TO_MESSAGE_PREVIEW,
  SET_REPLY_TO_MESSAGE_PREVIEW_ID,
  SET_REPLY_TO_MESSAGE_PREVIEW_HEIGHT,
  TOOGLE_SHOW_SANDBOX_CHECKPOINTS,
  SET_ANNOUNCEMENT_POPUP_VISIBILITY,
} from "./inboxStateTypes";

export const updateActiveSmartCard = (data) => ({
  type: UPDATE_ACTIVE_SMART_CARD,
  payload: data,
});

export const updatePersonalDetailsData = (data) => ({
  type: UPDATE_PERSONAL_DETAILS_DATA,
  payload: data,
});

export const showSearchSection = () => ({ type: SHOW_SEARCH_SECTION });

export const closeSearchSection = () => ({ type: CLOSE_SEARCH_SECTION });

export const setActiveSearchConversationId = (payload) => ({
  type: SET_SEARCH_ACTIVE_CONVERSATION_ID,
  payload,
});

export const isFindingSearchMessageAction = (payload) => {
  return {
    type: IS_FINDING_SEARCH_MESSAGE,
    payload,
  };
};

export const changeSanboxModalVisibility = (flag) => {
  return {
    type: CHANGE_SANDBOX_MODAL_VISIBILITY,
    payload: flag,
  };
};

export const changeSpamModalVisibility = (flag) => ({
  type: CHANGE_SPAM_MODAL_VISIBILITY,
  payload: flag,
});

export function setMultiSelectChats(chats) {
  return (dispatch) =>
    dispatch({
      type: SET_MULTI_SELECT_CHATS,
      payload: chats,
    });
}

export function resetMultiSelect() {
  return (dispatch) =>
    dispatch({
      type: RESET_MULTI_SELECT_STATE,
    });
}
export function enableMultiSelect() {
  return (dispatch) =>
    dispatch({
      type: ENABLE_MULTI_SELECT_STATE,
    });
}

export const setNewChatLoading = (flag) => ({
  type: NEW_CHAT_LOADING,
  payload: flag,
});

export const setReplyToMessagePreviewID = (id) => ({
  type: SET_REPLY_TO_MESSAGE_PREVIEW_ID,
  payload: id,
});

export const setIsReplyMessageActive = (flag) => ({
  type: SET_IS_REPLY_TO_MESSAGE_COMPOSER_ACTIVE,
  payload: flag,
});

export const closeReplytoMessagePreview = () => ({
  type: CLOSE_REPLY_TO_MESSAGE_PREVIEW,
});

export const setReplytoMessagePreviewHeight = (height) => ({
  type: SET_REPLY_TO_MESSAGE_PREVIEW_HEIGHT,
  payload: height,
});

export const toogleShowSandboxCheckpoints = () => {
  return {
    type: TOOGLE_SHOW_SANDBOX_CHECKPOINTS,
  };
};
export const setAnnouncementPopupVisibility = (payload) => ({
  type: SET_ANNOUNCEMENT_POPUP_VISIBILITY,
  payload,
});
