import { ENTERPRISE_PLANS_LIST } from "constants/enums/paymentMethods";
import useActivePlan from "customHooks/useActivePlan";

const useIsEnterpriseOrg = () => {
  const planName = useActivePlan();

  return ENTERPRISE_PLANS_LIST.includes(planName);
};

export default useIsEnterpriseOrg;
