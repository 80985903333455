import { useInfiniteQuery } from "@tanstack/react-query";
import useIsResellerSignupSource from "customHooks/useIsResellerSignupSource";
import useOrganizationId from "customHooks/useOrganizationId";
import { getOrganizationOnboardedStatus } from "modules/App/AppSelectors";
import { useSelector } from "react-redux";
import { callApiV2 } from "util/apiCaller";
import { showNudge } from "util/utility";

export const RQ_FETCH_UNREAD_NUDGE = "RQ_FETCH_UNREAD_NUDGE";

const LIST_PAGE_SIZE = 5;

let isNotificationShown = false;

const fetchUnreadNudge = async ({ orgId, pageParam, isResellerSignupSource }) => {
  try {
    const result = await callApiV2({
      endpoint: `/v1/organizations/${orgId}/nudge/?limit=${LIST_PAGE_SIZE}&offset=${pageParam}&unread=true`,
      method: "GET",
    });

    if (!isNotificationShown && !isResellerSignupSource && pageParam === 0 && result.count > 0) {
      showNudge({
        title: result?.results?.data?.[0]?.title,
        description: result?.results?.data?.[0]?.body,
      });
      isNotificationShown = true;
    }

    return { count: result.count, results: result.results.data };
  } catch (error) {
    throw new Error(error.message);
  }
};

const useUnreadNudge = ({ options = {}, select = (data) => data } = {}) => {
  const orgId = useOrganizationId();

  const isOnboardedStatus = useSelector(getOrganizationOnboardedStatus);
  const isResellerSignupSource = useIsResellerSignupSource();
  return useInfiniteQuery({
    queryFn: ({ pageParam = 0 }) => fetchUnreadNudge({ pageParam, orgId, isResellerSignupSource }),
    queryKey: [RQ_FETCH_UNREAD_NUDGE, orgId],
    getNextPageParam: (_, pages) => pages.length * LIST_PAGE_SIZE,
    select,
    enabled:
      options.enabled === undefined
        ? !!orgId && !!isOnboardedStatus
        : !!orgId && !!isOnboardedStatus && !!options.enabled,
    ...options,
  });
};

export const getNudgeCount = (data) => {
  const pagesLength = data.pages?.length || 1;

  return data.pages?.[pagesLength - 1]?.count;
};

export const getNudgeList = (data) => {
  const list = [];

  data.pages.forEach((page) => {
    list.push(...page.results);
  });

  return list;
};

export default useUnreadNudge;
