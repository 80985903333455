import getCurrentDomainType, {
  INTERAKT_DOMAIN,
} from "util/getCurrentDomainType";

const HideForNonInteraktDomain = ({ children }) => {
  const currentDomainType = getCurrentDomainType();

  if (currentDomainType === INTERAKT_DOMAIN) {
    return children;
  }

  return null;
};

export default HideForNonInteraktDomain;
