import { SET_IS_LINK_TRIGGERED } from "./ShopifyRegisterAction";

const initialState = {
  isLinkTriggered: false,
};

// eslint-disable-next-line default-param-last
const shopifyRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LINK_TRIGGERED:
      return {
        ...state,
        isLinkTriggered: action.isLinkTriggered,
      };
    default:
      return state;
  }
};

export default shopifyRegisterReducer;
