import { useSelector } from "react-redux";

import { getActiveSubscriptionList } from "modules/Payments/PaymentSelectors";

const useIsConversationBasedPricing = () => {
  const activePlanList = useSelector(getActiveSubscriptionList);

  if (
    activePlanList &&
    Array.isArray(activePlanList) &&
    activePlanList.length > 0
  ) {
    return activePlanList[0].subscription?.usage_type === "Conversation";
  }

  return true;
};

export default useIsConversationBasedPricing;
