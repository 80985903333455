import Banner from "components/LaunchFlaps/Banner/Banner";
import PageRootRibbon from "components/PageRootRibbon/PageRootRibbon";
import React from "react";
import { useLocation } from "react-router-dom";
import { getNotShowSidebar } from "util/sidebar.util";
import { getOrganizationOnboardedStatus } from "modules/App/AppSelectors";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import useIsSandboxMode from "customHooks/useIsSandboxMode";
import { useSelector } from "react-redux";
import { getIsNonLoggedInPage } from "util/nonLoggedInUtils";
import useIsResellerSignupSource from "customHooks/useIsResellerSignupSource";
import useIsAdsPlanSignupSource from "customHooks/useIsAdsPlanSignupSource";
import Sidebar from "./SideBar";

const MegaSidebar = () => {
  const location = useLocation();
  const isOnboarded = useSelector(getOrganizationOnboardedStatus);
  const isSignupSourceTally = useIsSignupSourceTally();
  const isSandboxMode = useIsSandboxMode();
  const isResellerSignupSource = useIsResellerSignupSource();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();

  if (getNotShowSidebar(location.pathname)) {
    if (window.localStorage.getItem("is_haptik_user")) {
      return null;
    }

    return (
      <>
        <PageRootRibbon />
        <Banner />
      </>
    );
  }

  if (getIsNonLoggedInPage(location.pathname)) {
    return (
      <>
        <PageRootRibbon nonLoggedIn />
        <Banner nonLoggedIn />
      </>
    );
  }

  if (
    !isOnboarded &&
    !isSignupSourceTally &&
    !isSandboxMode &&
    !isResellerSignupSource &&
    !isAdsPlanSignupSource
  ) {
    return null;
  }

  return (
    <>
      <Sidebar />
      <PageRootRibbon sidebarGap />
      <Banner />
    </>
  );
};

export default MegaSidebar;
