import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";
import { Helmet } from "react-helmet";

const AppHelmet = () => {
  const { data } = useCurrentWhitelabeledDetails();

  return (
    <Helmet>
      {data?.partner_brand_name && (
        <title id="title">{data?.partner_brand_name}</title>
      )}
      {data?.logo_url && <link rel="icon" href={data?.logo_url} />}
    </Helmet>
  );
};

export default AppHelmet;
