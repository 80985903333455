import { CATALOG_SYNC_STATUS_IN_PROGRESS } from "modules/CommerceSettings/constants";
import { callApiV2 } from "util/apiCaller";

export const TOGGLE_PRODUCT_PANEL_STATUS = "TOGGLE_PRODUCT_PANEL_STATUS";
export const UPDATE_FETCHING_PRODUCT_STATUS = "UPDATE_FETCHING_PRODUCT_STATUS";
export const FETCHING_PRODUCT_DATA = "FETCHING_PRODUCT_DATA";
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const SET_COLLECTION_FILTER = "SET_COLLECTION_FILTER";
export const SYNCING_PRODUCT_DETAILS = "SYNCING_PRODUCT_DETAILS";
export const SYNCING_TIMEOUT = "SYNCING_TIMEOUT";
export const UPDATE_SELECTED_PRODUCTS = "UPDATE_SELECTED_PRODUCTS";

export const toggleProductPanelStatus = (bool) => ({
  type: TOGGLE_PRODUCT_PANEL_STATUS,
  isProductPanelVisible: bool,
});

export const fetchProducts = (
  page,
  orgId,
  searchFilter,
  collectionId,
) => async (dispatch) => {
  const limit = 100;
  const offset = page * 100;
  if (!page) {
    dispatch({
      type: UPDATE_FETCHING_PRODUCT_STATUS,
      isFetchingProductDetails: true,
    });
  }
  let endpointURL = `v1/organizations/${orgId}/products-detail/?limit=${limit}&offset=${offset}`;
  if (searchFilter) {
    endpointURL += `&name=${searchFilter}`;
  }
  if (collectionId && collectionId !== "all") {
    endpointURL += `&collection_id=${collectionId}`;
  }
  const res = await callApiV2({
    endpoint: endpointURL,
  });
  if (res.results?.result) {
    dispatch({
      type: FETCHING_PRODUCT_DATA,
      productData: {
        count: res.count,
        next: res.next,
        previous: res.previous,
        productList: res.results.data,
      },
      shouldConcat: !!page,
      isFetchingProductDetails: false,
    });
  } else {
    dispatch({
      type: UPDATE_FETCHING_PRODUCT_STATUS,
      isFetchingProductDetails: false,
    });
  }
};

export const syncProductsFromFB = (orgId, catalogId) => async (dispatch) => {
  dispatch({
    type: SYNCING_PRODUCT_DETAILS,
    isSyncingProductDetails: CATALOG_SYNC_STATUS_IN_PROGRESS,
  });
  const res = await callApiV2({
    endpoint: `v1/organizations/${orgId}/sync-products/?fb_catalog_id=${catalogId}`,
  });
  if (res.message !== "Syncing") {
    dispatch({
      type: SYNCING_PRODUCT_DETAILS,
      isSyncingProductDetails: "",
    });
  }
};

export const setSearchFilter = (searchFilter) => ({
  type: SET_SEARCH_FILTER,
  searchFilter,
});

export const setCollectionFilter = (collectionFilter) => ({
  type: SET_COLLECTION_FILTER,
  collectionFilter,
});

export const setIsSyncingProductDetails = (status) => ({
  type: SYNCING_PRODUCT_DETAILS,
  isSyncingProductDetails: status,
});

export const setSyncingTimeout = (bool) => ({
  type: SYNCING_TIMEOUT,
  isSyncingTimeExceed: bool,
});

export const setSelectedProducts = (products) => ({
  type: UPDATE_SELECTED_PRODUCTS,
  products,
});
