import React from "react";
import useRefinerInit from "customHooks/integration/useRefinerInit";

const withRefinerInit = (WrappedComponent) => {
  return (props) => {
    useRefinerInit();

    return <WrappedComponent {...props} />;
  };
};

export default withRefinerInit;
