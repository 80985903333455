import useIsSignupSource from "./useIsSignupSource";

const useIsResellerSignupSource = () => {
  const isResellerOnlineSignupSource = useIsSignupSource("ResellerOnline");
  const isResellerOfflineSignupSource = useIsSignupSource("ResellerOffline");
  const isResellerWhiteLabeledOnlineSignupSource = useIsSignupSource(
    "ResellerWhiteLabeledOnline",
  );
  const isResellerWhiteLabeledOfflineSignupSource = useIsSignupSource(
    "ResellerWhiteLabeledOffline",
  );

  return (
    isResellerOnlineSignupSource ||
    isResellerOfflineSignupSource ||
    isResellerWhiteLabeledOnlineSignupSource ||
    isResellerWhiteLabeledOfflineSignupSource
  );
};

export default useIsResellerSignupSource;
