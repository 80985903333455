import React from "react";
import { Provider } from "react-redux";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "config/queryClient";
import { Router } from "react-router-dom";
import { store } from "../store";
import history from "../history";

const MegaContextProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>{children}</Router>
      </QueryClientProvider>
    </Provider>
  );
};

export default MegaContextProvider;
