import React from "react";

const handshake = ({ fill, ...props }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_18_2004)">
        <path
          d="M39 25.144L24 40L8.99999 25.144C8.0106 24.1812 7.23128 23.024 6.71109 21.7453C6.1909 20.4665 5.94112 19.0939 5.97747 17.7139C6.01382 16.3338 6.33552 14.9763 6.92231 13.7267C7.5091 12.4771 8.34826 11.3625 9.38696 10.4531C10.4257 9.5438 11.6414 8.85937 12.9576 8.44295C14.2738 8.02653 15.662 7.88716 17.0347 8.03359C18.4075 8.18003 19.735 8.6091 20.9337 9.2938C22.1325 9.9785 23.1765 10.904 24 12.012C24.8271 10.912 25.8723 9.99463 27.0702 9.3172C28.2681 8.63976 29.593 8.21689 30.9619 8.07503C32.3307 7.93317 33.7142 8.07538 35.0255 8.49277C36.3369 8.91016 37.548 9.59373 38.5831 10.5007C39.6181 11.4077 40.4548 12.5186 41.0408 13.7638C41.6268 15.009 41.9495 16.3618 41.9886 17.7374C42.0277 19.1131 41.7825 20.482 41.2683 21.7585C40.754 23.035 39.9818 24.1916 39 25.156"
          stroke={fill}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 12L17.414 18.586C17.0391 18.9611 16.8284 19.4697 16.8284 20C16.8284 20.5303 17.0391 21.0389 17.414 21.414L18.5 22.5C19.88 23.88 22.12 23.88 23.5 22.5L25.5 20.5C26.6935 19.3065 28.3122 18.6361 30 18.6361C31.6878 18.6361 33.3065 19.3065 34.5 20.5L39 25"
          stroke={fill}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 31L29 35"
          stroke={fill}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 26L34 30"
          stroke={fill}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_2004">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default handshake;
