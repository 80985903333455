import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCampaign } from "modules/Notification/NotificationActions";
import SIDEBAR_WIDTH, { SIDEBAR_EXPANDED_WIDTH } from "constants/sidebar";

import Box from "konnekt/Box";
import useIsSandboxMode from "customHooks/useIsSandboxMode";
import { changeSupportBotVisibility } from "modules/App/AppActions";
import styled from "styled-components";
import { Mixpanel } from "util/tracking";
import {
  INT_WEB_NAV_ACCOUNT_STATUS,
  INT_WEB_NAV_HELP_DESK,
  INT_WEB_NAV_SETTINGS,
  INT_WEB_NAV_WIDGET,
} from "constants/mixPanel/inbox";
import useShowOnboardingFeature from "customHooks/useShowOnboardingFeature";
import useOrganizationDetails from "customHooks/Organization/useOrganizationDetails";
import PersonLogo from "components/ChatWindow/PersonLogo";
import Label from "konnekt/Label";
import { getNameIntials } from "util/chatUtil";

import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import useMaskNumber from "customHooks/useMaskNumber";
import useShouldShowInbox from "customHooks/useShouldShowInbox";
import useShouldShowSmartAds from "customHooks/useShouldShowSmartAds";
import useShouldVanishFeatureByPaymentPlan from "customHooks/useShouldVanishFeatureByPaymentPlan";
import useShowWalletFeature from "customHooks/useShowWalletFeature";
import {
  INBOX,
  CAMPAIGN,
  TEMPLATES,
  USERS,
  COMMERCE_SETTING,
  ORDER_PANEL,
  CATALOG,
  INBOX_SETTING,
  WORKFLOWS,
  CUSTOMER_AUTO_REPLIES,
  ANALYTICS_OVERVIEW,
  AGENT_PERFORMANCE,
  CTWA,
  WIDGET,
  INTEGRATIONS,
  WALLET_MAIN_PAGE,
  TRACK_EXPENSES,
  VIEW_INSIGHTS,
  BILLING_DETAILS,
  CHAT_ASSIGNMENT,
  AUTOCHECKOUT_WORKFLOW,
} from "modules/Payments/vanishFeatureConstant";
import useIsResellerSignupSource from "customHooks/useIsResellerSignupSource";
import useHideCommerceForStarter from "modules/CommerceSettings/hooks/useHideCommerceForStarter";
import useActivePlan from "customHooks/useActivePlan";
import useShowWidgetPage from "customHooks/useShowWidgetPage";
import useUnreadNudge, { getNudgeCount } from "useApi/Inbox/useUnreadNudge";
import AnnouncementsPopup from "modules/Concierge/AnnouncementsPopup/AnnouncementsPopup";
import NoAnnouncements from "modules/Concierge/NoAnnouncements";
import { INT_SMART_ADS_SIDEBAR_ICON_CLICKED } from "constants/mixPanel/ads";
import { INT_CONCIERGE_SIDEBAR_ICON_CLICKED } from "modules/Concierge/constant";
import useIsAdsPlanSignupSource from "customHooks/useIsAdsPlanSignupSource";
import { openInNewTab } from "util/locationUtil";
import { ADS_PLAN_NAME } from "constants/enums/paymentMethods";
import { addUrlParams, deleteUrlParams } from "util/navUtil";
import { getUrlParams } from "util/utility";
import { useHistory } from "react-router-dom";
import SidebarIcon from "./SidebarIcon";
import HelpDeskModal from "./HelpDeskModal/HelpDeskModal";
import { SIDEBAR_MENU_LIST, SIDEBAR_MENU_LIST_HAPTIK } from "./constants";

const SidebarBox = styled(Box)`
  @media (min-height: 40.625em) {
    grid-gap: 12px;
  }

  grid-gap: 8px;
`;

let announcementInterval;

const SidebarContainer = ({
  toggleSettings,
  isCampaignActive,
  isSettingsActive,
  isWidgetActive,
  isAnalyticsActive,
  isContactsListActive,
  isTemplateActive,
  isInboxActive,
  isSignupWhatsappActive,
  isSidebarOnboardedActive,
  isWalletActive,
  isIntegrationActive,
  hasOrders,
  isSettingsMenuVisible,
  isAdsActive,
  isAnnouncementsActive,
}) => {
  const { data: nudgeCount } = useUnreadNudge({ select: getNudgeCount });

  const [showAnnouncementPopup, setShowAnnouncementPopup] = useState(false);

  const hideCommerceForStarter = useHideCommerceForStarter();
  const isHaptikUser = localStorage.getItem("is_haptik_user");

  const dispatch = useDispatch();
  const isSandboxMode = useIsSandboxMode();
  const isSignupSourceTally = useIsSignupSourceTally();

  const orgData = useOrganizationDetails();
  const showOnboardingFeature = useShowOnboardingFeature();
  const [isExpanded, setIsExpanded] = useState(false);

  const shouldShowSmartAds = useShouldShowSmartAds();

  const showContent = isExpanded || isSettingsMenuVisible;
  const [showHelpDeskModal, setShowHelpDeskModal] = useState(false);
  const shouldMaskNumber = useMaskNumber();
  const shouldShowInbox = useShouldShowInbox();
  const showWalletFeature = useShowWalletFeature();

  // Vanish Features
  const shouldVanishInbox = useShouldVanishFeatureByPaymentPlan([INBOX]);
  const shouldVanishNotification = useShouldVanishFeatureByPaymentPlan([
    CAMPAIGN,
  ]);
  const shouldVanishTemplates = useShouldVanishFeatureByPaymentPlan([
    TEMPLATES,
  ]);
  const shouldVanishUsers = useShouldVanishFeatureByPaymentPlan([USERS]);
  const shouldVanishCommerce = useShouldVanishFeatureByPaymentPlan([
    COMMERCE_SETTING,
    ORDER_PANEL,
    CATALOG,
    AUTOCHECKOUT_WORKFLOW,
  ]);
  const shouldVanishAutomation = useShouldVanishFeatureByPaymentPlan([
    INBOX_SETTING,
    WORKFLOWS,
    CUSTOMER_AUTO_REPLIES,
    CHAT_ASSIGNMENT,
  ]);
  const shouldVanishAnalytics = useShouldVanishFeatureByPaymentPlan([
    ANALYTICS_OVERVIEW,
    AGENT_PERFORMANCE,
    CTWA,
  ]);
  const shouldVanishWidget = useShouldVanishFeatureByPaymentPlan([WIDGET]);
  const shouldVanishIntegrations = useShouldVanishFeatureByPaymentPlan([
    INTEGRATIONS,
  ]);
  const shouldVanishWallet = useShouldVanishFeatureByPaymentPlan([
    WALLET_MAIN_PAGE,
    TRACK_EXPENSES,
    VIEW_INSIGHTS,
    BILLING_DETAILS,
  ]);

  const isResellerSignupSource = useIsResellerSignupSource();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const activePlan = useActivePlan();
  const supportBotVisibility = useSelector(
    (state) => state.app.supportBotVisibility,
  );
  const showWidgetPage = useShowWidgetPage();

  const isResellerWithPlanNotStarted = !activePlan && isResellerSignupSource;

  const isAdsPlanNotStarted = isAdsPlanSignupSource && !activePlan;

  useEffect(() => {
    if (!isCampaignActive) {
      dispatch(setActiveCampaign({}));
    }
  }, [isCampaignActive]);

  const handleHelpDeskClick = () => {
    if (isSignupSourceTally || isResellerSignupSource) {
      setShowHelpDeskModal(true);
    } else if (isAdsPlanSignupSource && activePlan === ADS_PLAN_NAME) {
      openInNewTab("https://wa.me/917827483097");
    } else {
      // TODO: showing bot support logic should be handled here only and not via redux. There is no need to add extra complexity
      dispatch(changeSupportBotVisibility(!supportBotVisibility));
    }
    Mixpanel.track(INT_WEB_NAV_HELP_DESK, {});
    if (toggleSettings && isSettingsMenuVisible) {
      toggleSettings();
    }
  };

  const isMenuItemActive = (item) => {
    if (item.isOnboardingActive) {
      return window?.location?.pathname === "/onboarding";
    }
    if (item.isInboxActive) {
      return isInboxActive;
    }
    if (item.isCampaignActive) {
      return isCampaignActive;
    }
    if (item.isTemplateActive) {
      return isTemplateActive;
    }
    if (item.isContactsListActive) {
      return isContactsListActive;
    }
    if (item.isAnalyticsActive) {
      return isAnalyticsActive;
    }
    if (item.isIntegrationActive) {
      return isIntegrationActive;
    }
    if (item.isActiveCommerce)
      return (
        window?.location?.pathname === "/commerce-settings" ||
        window?.location?.pathname?.split("/")[1] === "commerce"
      );
    if (item.isActiveAutomation)
      return window?.location?.pathname?.split("/")[1] === "automation";
    return false;
  };

  const isMenuItemVisible = (item) => {
    if (item.hideForResellerWithPlanNotStarted) {
      if (isResellerWithPlanNotStarted) {
        return false;
      }
    }
    if (item.hideForAdsPlanNotStarted) {
      if (isAdsPlanNotStarted) {
        return false;
      }
    }
    if (item.hideForTally) {
      if (isSignupSourceTally) {
        return false;
      }
    }
    if (item.hideForMaskNumber) {
      if (shouldMaskNumber) {
        return false;
      }
    }
    if (item.hideForStarter) {
      if (hideCommerceForStarter) {
        return false;
      }
    }
    if (item.showOnboardingFeature) {
      return showOnboardingFeature;
    }
    if (item.shouldShowInbox) {
      return !shouldVanishInbox && shouldShowInbox;
    }
    if (item.shouldShowNotification) {
      return !shouldVanishNotification;
    }
    if (item.shouldShowTemplates) {
      return !shouldVanishTemplates;
    }
    if (item.shouldShowContacts) {
      return !shouldVanishUsers;
    }
    if (item.shouldShowCommerce) {
      return !shouldVanishCommerce;
    }
    if (item.shouldShowAutomation) {
      return !shouldVanishAutomation;
    }
    if (item.shouldShowAnalytics) {
      return !shouldVanishAnalytics;
    }
    if (item.shouldShowIntegrations) {
      return !shouldVanishIntegrations;
    }
    return true;
  };

  const getBrandLogoProps = () => {
    if (isAdsPlanSignupSource) {
      if (!activePlan || activePlan === ADS_PLAN_NAME) {
        return { iconName: "smartAdsLogo", width: "32px", height: "32px" };
      }
    }
    if (isSignupSourceTally) {
      return { iconName: "home", width: "24px", height: "24px" };
    }
    return { iconName: "logo", width: "24px", height: "24px" };
  };

  const getSideBarIcons = () => {
    if (!isSidebarOnboardedActive && !isSandboxMode) {
      return null;
    }

    if (isAdsPlanSignupSource) {
      SIDEBAR_MENU_LIST_HAPTIK.sort((a, b) => a.adsPlanIndex - b.adsPlanIndex);
      SIDEBAR_MENU_LIST.sort((a, b) => a.adsPlanIndex - b.adsPlanIndex);
    }

    if (isHaptikUser) {
      return SIDEBAR_MENU_LIST_HAPTIK.map((item) => {
        return (
          <SidebarIcon
            key={item.cypressData}
            url={item.hasOrders && hasOrders ? item.hasOrderUrl : item.url}
            iconName={item.iconName}
            cypressData={item.cypressData}
            isActive={isMenuItemActive(item)}
            tooltipContent={item.tooltipContent}
            color={item.color}
            strokeColor={item.strokeColor}
            trackClick={() => {
              Mixpanel.track(item.mixPanelTrack, {});
              if (toggleSettings && isSettingsMenuVisible) {
                toggleSettings();
              }
            }}
            showContent={showContent}
            sidebarClassName="sidebar-new-link"
            width="24px"
            height="24px"
            isExternal={item.isExternal}
          />
        );
      });
    }
    return (
      SIDEBAR_MENU_LIST?.length &&
      SIDEBAR_MENU_LIST.map((item) => {
        if (!isMenuItemVisible(item)) {
          return null;
        }

        if (isSignupSourceTally && item.url === "/templates/list") {
          item.url = "/templates/list?segment=active";
        }

        if (
          isAdsPlanSignupSource &&
          activePlan === ADS_PLAN_NAME &&
          item.url === "/automation/inbox-setting"
        ) {
          item.iconName = "workflow";
          item.tooltipContent = "Workflows";
          item.url = "/automation/workflows";
        }

        if (isAdsPlanSignupSource && item.url === "/notification") {
          item.tooltipContent = "Retarget";
        }

        if (isAdsPlanSignupSource && item.url === "/contacts/list") {
          item.tooltipContent = "Leads";
        }

        return (
          <SidebarIcon
            key={item.cypressData}
            url={item.hasOrders && hasOrders ? item.hasOrderUrl : item.url}
            iconName={item.iconName}
            cypressData={item.cypressData}
            isActive={isMenuItemActive(item)}
            tooltipContent={item.tooltipContent}
            color={item.color}
            strokeColor={item.strokeColor}
            trackClick={() => {
              Mixpanel.track(item.mixPanelTrack, {});
              if (toggleSettings && isSettingsMenuVisible) {
                toggleSettings();
              }
            }}
            showContent={showContent}
            sidebarClassName="sidebar-new-link"
            width="24px"
            height="24px"
          />
        );
      })
    );
  };

  return (
    <>
      {(isSignupSourceTally || isResellerSignupSource) && showHelpDeskModal && (
        <HelpDeskModal
          isOpen={showHelpDeskModal}
          onHide={() => setShowHelpDeskModal(false)}
        />
      )}
      <Box
        width={showContent ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_WIDTH}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        position="fixed"
        className="desktop font-family-v2"
        boxShadow="4px 0 3px hsl(0deg 0% 62% / 21%)"
        bg="white"
        height="100%"
        top={0}
        left={0}
        p={showContent ? "0px 16px" : "0px 10px"}
        zIndex={999}
        overflowX="hidden"
        onMouseEnter={() => {
          setIsExpanded(true);
        }}
        onMouseLeave={() => {
          setIsExpanded(false);
        }}
        noScrollBar
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          pt="10px"
          pb="18px"
          mt="0px"
        >
          {!isHaptikUser && (
            <SidebarIcon
              url="/signup/whatsapp"
              cypressData="signup-whatsapp"
              isActive={isSignupWhatsappActive}
              tooltipContent="Home"
              color="#00A68B"
              trackClick={() => {
                Mixpanel.track(INT_WEB_NAV_ACCOUNT_STATUS, {});
                if (toggleSettings && isSettingsMenuVisible) {
                  toggleSettings();
                }
              }}
              sidebarClassName="sidebar-new-link"
              showContent={showContent}
              {...getBrandLogoProps()}
            />
          )}
        </Box>

        <SidebarBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {orgData?.name && (
            <SidebarBox
              className={`sidebar-new-link sidebar-new-link--settings ${
                isSettingsActive && showContent ? "active" : ""
              }`}
              display="flex"
              alignItems="center"
              textAlign="left"
              width="100%"
              p="0px 5px"
              position="relative"
              onClick={() => {
                toggleSettings();
                Mixpanel.track(INT_WEB_NAV_SETTINGS, {});
              }}
            >
              <PersonLogo
                textContainerProps={{
                  bg: isSettingsActive ? "#E5F5F3" : "#F3F5F9",
                  fontSize: "14px",
                  fontWeight: "600",
                  ml: "auto",
                  border: isSettingsActive
                    ? "1px solid #00A68B"
                    : "1px solid #464D6D",
                  borderRadius: "31px",
                  color: isSettingsActive ? "#00A68B" : "#464D6D",
                  fontFamily: "Open Sans",
                  cursor: "pointer",
                }}
                size={30}
                text={getNameIntials(orgData?.name)}
              />
              {showContent && (
                <Label
                  variant="text6"
                  color={isSettingsActive ? "#00A68B" : "#0A0B10"}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {orgData.name}
                </Label>
              )}
              {!showContent && isSettingsActive && (
                <Box
                  bg="#00A68B"
                  borderTopLeftRadius="10px"
                  borderBottomLeftRadius="10px"
                  width="4px"
                  height="50px"
                  position="absolute"
                  right="-10px"
                  top="-4px"
                />
              )}
            </SidebarBox>
          )}
          {isAdsPlanSignupSource && shouldShowSmartAds && (
            <SidebarIcon
              url="/smart-ads"
              iconName="smartAds"
              cypressData="smartAds"
              tooltipContent="Smart Ads"
              showContent={showContent}
              sidebarClassName="sidebar-new-link"
              width="24px"
              height="24px"
              isActive={isAdsActive}
              tagContent="NEW"
              trackClick={() => {
                Mixpanel.track(INT_SMART_ADS_SIDEBAR_ICON_CLICKED, {});
              }}
            />
          )}
          {getSideBarIcons()}
          {(isSidebarOnboardedActive || isSandboxMode) &&
            showWidgetPage &&
            !isHaptikUser &&
            !isSignupSourceTally &&
            !shouldVanishWidget && (
              <SidebarIcon
                url="/widget/manage"
                iconName="widgetNew"
                cypressData="widget"
                isActive={isWidgetActive}
                tooltipContent="Widget"
                trackClick={() => {
                  Mixpanel.track(INT_WEB_NAV_WIDGET, {});
                  if (toggleSettings && isSettingsMenuVisible) {
                    toggleSettings();
                  }
                }}
                showContent={showContent}
                sidebarClassName="sidebar-new-link"
                width="24px"
                height="24px"
              />
            )}
          {!isAdsPlanSignupSource && shouldShowSmartAds && (
            <SidebarIcon
              url="/smart-ads"
              iconName="smartAds"
              cypressData="smartAds"
              tooltipContent="Smart Ads"
              showContent={showContent}
              sidebarClassName="sidebar-new-link"
              width="24px"
              height="24px"
              isActive={isAdsActive}
              tagContent="NEW"
              trackClick={() => {
                Mixpanel.track(INT_SMART_ADS_SIDEBAR_ICON_CLICKED, {});
              }}
            />
          )}
        </SidebarBox>

        <SidebarBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          justifyContent="flex-end"
          flex="1"
          pb="10px"
          mt="8px"
        >
          {!isResellerSignupSource && !isAdsPlanNotStarted && (
            <SidebarIcon
              url="/announcements"
              iconName={
                nudgeCount > 0 ? "announceAnimate" : "announcementSidebar"
              }
              cypressData="announcementSidebar"
              tooltipContent="Announcement Hub"
              sidebarClassName="sidebar-new-link"
              width="24px"
              height="24px"
              isActive={isAnnouncementsActive || showAnnouncementPopup}
              showContent={showContent}
              tagContent={nudgeCount}
              trackClick={() => {
                Mixpanel.track(INT_CONCIERGE_SIDEBAR_ICON_CLICKED, {});
              }}
              onMouseEnter={() => {
                clearInterval(announcementInterval);
                setShowAnnouncementPopup(true);
                setIsExpanded(true);
              }}
              onMouseLeave={() => {
                announcementInterval = setTimeout(() => {
                  setShowAnnouncementPopup(false);
                }, 250);
              }}
            />
          )}

          {showWalletFeature && !shouldVanishWallet && (
            <SidebarIcon
              url="/payments/wallet"
              iconName="wallet"
              cypressData="wallet"
              isActive={isWalletActive}
              tooltipContent="Wallet"
              showContent={showContent}
              sidebarClassName="sidebar-new-link"
              width="24px"
              height="24px"
            />
          )}
          {(isSidebarOnboardedActive || isSandboxMode) &&
            !isAdsPlanNotStarted && (
              <SidebarIcon
                iconName="helpDeskNew"
                cypressData="help"
                tooltipContent="Help"
                showContent={showContent}
                trackClick={handleHelpDeskClick}
                sidebarClassName="sidebar-new-link"
                width="24px"
                height="24px"
              />
            )}
        </SidebarBox>
      </Box>
      {showAnnouncementPopup &&
        !isResellerSignupSource &&
        (nudgeCount > 0 ? (
          <AnnouncementsPopup
            onMouseLeave={() => {
              setShowAnnouncementPopup(false);
            }}
            showContent={showContent}
            onMouseEnter={() => clearInterval(announcementInterval)}
          />
        ) : (
          <NoAnnouncements
            onMouseLeave={() => {
              setShowAnnouncementPopup(false);
            }}
            onMouseEnter={() => clearInterval(announcementInterval)}
            showContent={showContent}
          />
        ))}
    </>
  );
};

export default SidebarContainer;
