export const ANALYTICS_TABS = {
  overview: "/analytics/overview",
  agentPerformance: "/analytics/agent-performance",
  adPerformance: "/analytics/ad-performance",
  checkoutLinkTracking: "/analytics/checkout-link-tracking",
};
export const AGENT_TOOLTIPS = {
  name: "Name of the agent",
  assigned:
    "Number of conversations assigned to the agent in the selected time period.",
  responded:
    "Number of assigned conversations in which the agent responded in the selected time period",
  totalResolved:
    "Number of assigned conversations which the agent closed or reassigned in the selected time period",
  reassigned:
    "Number of assigned conversations which the agent reassigned in the selected time period",
  closed:
    "Number of assigned conversations which the agent closed in the selected time period",
  firstResponseTime:
    "Time taken to send 1st response after having been assigned the conversation. Note: Median of all 1st response times is shown here.",
  avgResponseTime:
    "Average time taken to respond to all customer messages in an assigned conversation. Note: Median of these averages is shown here.",
  resolutionTime:
    "Time taken to close or reassign an assigned conversation. Note: Median of all resolution times is shown here.",
};
export const AGENT_HEADERS = {
  name: "Name",
  assigned: "Assigned",
  responded: "Responded",
  totalResolved: "Total Resolved",
  reassigned: "Reassigned",
  closed: "Closed",
  firstResponseTime: "1st Response Time",
  avgResponseTime: "Avg Response Time",
  resolutionTime: "Resolution Time",
};
export const ENDPOINT_TYPES = {
  analyticsCards: "analyticsCards",
  agentPerformance: "agentPerformance",
  analyticsCardsCsv: "analyticsCardsCsv",
  agentPerformanceCsv: "agentPerformanceCsv",
  analyticsCardsGraph: "analyticsCardsGraph",
  adPerformance: "adPerformance",
};
export const ERROR_TXT = {
  timeout:
    "Seems like there are too many conversations to process for the selected time period! Please select a smaller time period to get quick results.",
};
export const HEADER_TXT = {
  [ANALYTICS_TABS.overview]:
    "These stats are for all conversations which were initiated by customers in the selected period",
  [ANALYTICS_TABS.agentPerformance]:
    "These stats are for all conversations initiated by customers & assigned to agents in the selected period",
};
export const MIN_ANALYTICS_DATE = {
  // ? minDate for overview analytics => 1Sep2021
  [ANALYTICS_TABS.overview]: new Date(2021, 8, 1),
  // ? minDate for agent performance analytics => 23Sep2021
  [ANALYTICS_TABS.agentPerformance]: new Date(2021, 8, 23),
};
export const DATA_RIBBON_TEXTS = {
  heading: "Data available only for periods when the Growth Plan is live",
  tooltipL1: "Below stats will be based on customer initiated",
  tooltipL2: " conversations which get updated on the days",
  tooltipL3: " when the Growth Plan is live for your account",
};
export const CHECKOUT_LINK_TRACKING_OVERVIEW_TOOLTIP_TEXT = {
  1: "Links generated when Catalog/MPM/Product messages are sent through Campaigns",
  2: "Links generated when Catalog/MPM/Product messages are sent through any automated messages like Custom Auto-Replies, Welcome Message etc.",
  3: "Links generated when Catalog/MPM/Product messages are sent manually by agents or Meta AI Agent or any other means",
};

export const CHECKOUT_LINK_PAGE_SIZE = 10;
