// Left Panel
export const INT_WEB_INBOX_LIST_TYPE_OPENED = "INT_WEB_INBOX_LIST_TYPE_OPENED";
export const INT_WEB_INBOX_LIST_TYPE_SELECTED =
  "INT_WEB_INBOX_LIST_TYPE_SELECTED";
export const INT_WEB_INBOX_SEARCH = "INT_WEB_INBOX_SEARCH_ENABLED";
export const INT_WEB_INBOX_SEARCH_DONE = "INT_WEB_INBOX_SEARCH_DONE";
export const INT_WEB_INBOX_FILTER_OPENED = "INT_WEB_INBOX_FILTER_OPENED";
export const INT_WEB_INBOX_FILTER_APPLIED = "INT_WEB_INBOX_FILTER_APPLIED";
export const INT_WEB_INBOX_FILTER_CLEARED = "INT_WEB_INBOX_FILTER_CLEARED";
export const INT_WEB_MOBILE_MENU_ICON_CLICKED =
  "INT_WEB_MOBILE_MENU_ICON_CLICKED";
export const INT_WEB_INBOX_BULK_SELECT_STARTED =
  "INT_WEB_INBOX_BULK_SELECT_STARTED";
export const INT_WEB_INBOX_BULK_SELECT_APPLIED =
  "INT_WEB_INBOX_BULK_SELECT_APPLIED";
export const INT_WEB_INBOX_CHAT_ASSIGNATION_TYPE_CLICKED =
  "INT_WEB_INBOX_CHAT_ASSIGNATION_TYPE_CLICKED";
export const INT_WEB_INBOX_CHAT_ASSIGNATION_TYPE_SELECTED =
  "INT_WEB_INBOX_CHAT_ASSIGNATION_TYPE_SELECTED";
export const INT_WEB_INBOX_CHAT_LIST_TYPE_OPENED =
  "INT_WEB_INBOX_CHAT_LIST_TYPE_OPENED";
export const INT_WEB_INBOX_CHAT_LIST_TYPE_SELECTED =
  "INT_WEB_INBOX_CHAT_LIST_TYPE_SELECTED";
export const INT_WEB_INBOX_CHAT_LIST_ORDER_OPENED =
  "INT_WEB_INBOX_CHAT_LIST_ORDER_OPENED";
export const INT_WEB_INBOX_CHAT_LIST_ORDER_SELECTED =
  "INT_WEB_INBOX_CHAT_LIST_ORDER_SELECTED";

// Middle Panel
export const INT_WEB_INBOX_CLOSE_CHAT = "INT_WEB_INBOX_CLOSE_CHAT";
export const INT_WEB_INBOX_ASSIGN_AGENT_OPENED =
  "INT_WEB_INBOX_ASSIGN_AGENT_OPENED";
export const INT_WEB_INBOX_ASSIGN_AGENT_SELECTED =
  "INT_WEB_INBOX_ASSIGN_AGENT_SELECTED";
export const INT_WEB_INBOX_LABEL_OPENED = "INT_WEB_INBOX_LABEL_OPENED";
export const INT_WEB_INBOX_LABEL_SELECTED = "INT_WEB_INBOX_LABEL_SELECTED";
export const INT_WEB_INBOX_LABEL_CREATED = "INT_WEB_INBOX_LABEL_CREATED";
export const INT_WEB_INBOX_LABEL_CLEARED = "INT_WEB_INBOX_LABEL_CLEARED";
export const INT_WEB_INBOX_COMPOSER_AREA_MENU =
  "INT_WEB_INBOX_COMPOSER_AREA_MENU";
export const INT_WEB_INBOX_COMPOSER_AREA_TYPE =
  "INT_WEB_INBOX_COMPOSER_AREA_TYPE";
export const INT_WEB_INBOX_COMPOSER_NOTES_ENABLED =
  "INT_WEB_INBOX_COMPOSER_NOTES_ENABLED";
export const INT_WEB_INBOX_COMPOSER_AREA_PAYMENT_LINK_POPUP_ACTIVATED =
  "INT_WEB_INBOX_COMPOSER_AREA_PAYMENT_LINK_POPUP_ACTIVATED";
export const INT_WEB_INBOX_COMPOSER_AREA_PAYMENT_LINK_GENERATED =
  "INT_WEB_INBOX_COMPOSER_AREA_PAYMENT_LINK_GENERATED";
export const INT_WEB_INBOX_COMPOSER_AREA_CATALOG_PANEL_ENABLED =
  "INT_WEB_INBOX_COMPOSER_AREA_CATALOG_PANEL_ENABLED";
export const INT_WEB_INBOX_COMPOSER_AREA_CATALOG_MESSAGE_SENT =
  "INT_WEB_INBOX_COMPOSER_AREA_CATALOG_MESSAGE_SENT";
export const INT_WEB_INBOX_COMPOSER_AREA_LIST_MESSAGE_MODAL_ENABLED =
  "INT_WEB_INBOX_COMPOSER_AREA_LIST_MESSAGE_MODAL_ENABLED";
export const INT_WEB_INBOX_COMPOSER_AREA_LIST_MESSAGE_PREVIEW_GENERATED =
  "INT_WEB_INBOX_COMPOSER_AREA_LIST_MESSAGE_PREVIEW_GENERATED";
export const INT_WEB_INBOX_COMPOSER_AREA_QUICK_REPLY_ENABLED =
  "INT_WEB_INBOX_COMPOSER_AREA_QUICK_REPLY_ENABLED";
export const INT_WEB_INBOX_COMPOSER_AREA_QUICK_REPLY_PREVIEW =
  "INT_WEB_INBOX_COMPOSER_AREA_QUICK_REPLY_PREVIEW";
export const INT_WEB_INBOX_COMPOSER_AREA_ATTACHMENT_CLICKED =
  "INT_WEB_INBOX_COMPOSER_AREA_ATTACHMENT_CLICKED";
export const INT_WEB_INBOX_COMPOSER_AREA_TEMPLATE_ENABLED =
  "INT_WEB_INBOX_COMPOSER_AREA_TEMPLATE_ENABLED";
export const INT_WEB_INBOX_COMPOSER_AREA_TEMPLATE_PREVIEW_GENERATED =
  "INT_WEB_INBOX_COMPOSER_AREA_TEMPLATE_PREVIEW_GENERATED";
export const INT_WEB_INBOX_COMPOSER_AREA_EMOJI_ENABLED =
  "INT_WEB_INBOX_COMPOSER_AREA_EMOJI_ENABLED";
export const INT_WEB_INBOX_COMPOSER_AREA_EMOJI_ADDED =
  "INT_WEB_INBOX_COMPOSER_AREA_EMOJI_ADDED";

// Right Panel

export const INT_WEB_INBOX_SMART_CARD_REORDER_CLICKED =
  "INT_WEB_INBOX_SMART_CARD_REORDER_CLICKED";
export const INT_WEB_INBOX_SMART_CARD_REORDER_DONE =
  "INT_WEB_INBOX_SMART_CARD_REORDER_DONE";
export const INT_WEB_INBOX_SMART_CARD_REORDER_SAVED =
  "INT_WEB_INBOX_SMART_CARD_REORDER_SAVED";

// Tags Card
export const INT_WEB_INBOX_TAGS_CARD_EDIT_CLICK =
  "INT_WEB_INBOX_TAGS_CARD_EDIT_CLICK";
export const INT_WEB_INBOX_TAGS_CARD_SHOW_MORE =
  "INT_WEB_INBOX_TAGS_CARD_SHOW_MORE";
export const INT_WEB_INBOX_TAGS_CARD_CREATE = "INT_WEB_INBOX_TAGS_CARD_CREATE";
export const INT_WEB_INBOX_TAGS_CARD_SAVE_CLICK =
  "INT_WEB_INBOX_TAGS_CARD_SAVE_CLICK";

// Profile Card
export const INT_WEB_INBOX_PROFILE_CARD_EDIT_CLICK =
  "INT_WEB_INBOX_PROFILE_CARD_EDIT_CLICK";
export const INT_WEB_INBOX_PROFILE_CARD_SHOW_MORE =
  "INT_WEB_INBOX_PROFILE_CARD_SHOW_MORE";
export const INT_WEB_INBOX_PROFILE_CARD_SAVE_CLICK =
  "INT_WEB_INBOX_PROFILE_CARD_SAVE_CLICK";

// Events Card
export const INT_WEB_INBOX_EVENTS_CARD_EXPANDED =
  "INT_WEB_INBOX_EVENTS_CARD_EXPANDED";
export const INT_WEB_INBOX_EVENT_VIEWED = "INT_WEB_INBOX_EVENT_VIEWED";

// Notes Card
export const INT_WEB_INBOX_NOTES_ADDED = "INT_WEB_INBOX_NOTES_ADDED";
export const INT_WEB_INBOX_NOTES_EDIT_CLICK = "INT_WEB_INBOX_NOTES_EDIT_CLICK";
export const INT_WEB_INBOX_NOTES_DELETE_CLICK =
  "INT_WEB_INBOX_NOTES_DELETE_CLICK";
export const INT_WEB_INBOX_NOTES_DELETE_DONE =
  "INT_WEB_INBOX_NOTES_DELETE_DONE";
export const INT_WEB_INBOX_NOTES_DELETE_CANCELLED =
  "INT_WEB_INBOX_NOTES_DELETE_CANCELLED";
export const INT_WEB_INBOX_NOTES_SHOW_MORE = "INT_WEB_INBOX_NOTES_SHOW_MORE";

// Conversation history card
export const INT_WEB_INBOX_HISTORY_SHOW_MORE =
  "INT_WEB_INBOX_CONVERSATION_SHOW_MORE";
export const INT_WEB_INBOX_HISTORY_SELECTED = "INT_WEB_INBOX_HISTORY_SELECTED";

// Spam card
export const INT_WEB_INBOX_SPAM_CLIKED = "INT_WEB_INBOX_SPAM_CLIKED";
export const INT_WEB_INBOX_SPAM_CONFIRMED = "INT_WEB_INBOX_SPAM_CONFIRMED";
export const INT_WEB_INBOX_SPAM_CANCELLED = "INT_WEB_INBOX_SPAM_CANCELLED";

// Navigation
export const INT_WEB_NAV_ACCOUNT_STATUS = "INT_WEB_NAV_ACCOUNT_STATUS";
export const INT_WEB_NAV_ONBOARDING = "INT_WEB_NAV_ONBOARDING";
export const INT_WEB_NAV_PAYMENTS = "INT_WEB_NAV_PAYMENTS";
export const INT_WEB_NAV_INBOX = "INT_WEB_NAV_INBOX";
export const INT_WEB_NAV_NOTIFICATION = "INT_WEB_NAV_NOTIFICATION";
export const INT_WEB_NAV_TEMPLATES = "INT_WEB_NAV_TEMPLATES";
export const INT_WEB_NAV_USERS = "INT_WEB_NAV_USERS";
export const INT_WEB_NAV_COMMERCE = "INT_WEB_NAV_COMMERCE";
export const INT_WEB_NAV_AUTOMATION = "INT_WEB_NAV_AUTOMATION";
export const INT_WEB_NAV_ANALYTICS = "INT_WEB_NAV_ANALYTICS";
export const INT_WEB_NAV_HELP_DESK = "INT_WEB_NAV_HELP_DESK";
export const INT_WEB_NAV_WIDGET = "INT_WEB_NAV_WIDGET";
export const INT_WEB_NAV_SETTINGS = "INT_WEB_NAV_SETTINGS";
