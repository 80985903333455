import useOrganizationDetails from "customHooks/Organization/useOrganizationDetails";
import Box from "konnekt/Box";
import Button from "konnekt/Buttons";
import GeneralLink from "konnekt/GeneralLink";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import Modal from "konnekt/Modal";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const BusinessVerificationFailedModal = () => {
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  const verificationRejectionReasons = useOrganizationDetails(
    (orgData) => orgData?.FB_verification_rejection_reasons,
  );

  const isRejectionReasonBusinessViolated =
    verificationRejectionReasons &&
    verificationRejectionReasons[0] === "BUSINESS VERTICAL VIOLATING";

  const handleOnClick = () => {
    setShowModal(false);
    history.push("/signup/whatsapp");
  };
  return (
    <Modal
      maxWidth="440px"
      isOpen={showModal}
      onHide={() => setShowModal(false)}
    >
      <Box
        position="absolute"
        top="16px"
        right="16px"
        width="24px"
        height="24px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="#EFF1F5"
        borderRadius="50%"
        onClick={() => setShowModal(false)}
      >
        <Icon iconName="close" iconSize="2tiny" color="#1E212F" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="32px"
        gridGap="22px"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="72px"
          height="72px"
          borderRadius="50%"
          bg="#e4f6f3"
        >
          <Icon iconName="verify" width="32px" height="32px" />
        </Box>
        <Box
          fontSize="16px"
          fontWeight={500}
          color="#1E212F"
          lineHeight="22px"
          mb="-8px"
        >
          <Label variant="text5">Business Verification Failed</Label>
        </Box>
        {isRejectionReasonBusinessViolated ? (
          <Box textAlign="center" color="#464D6D">
            <Label variant="text7">
              As per Meta, your business is not eligible for WhatsApp Business
              API. You can read more about their policy{" "}
              <GeneralLink
                href="https://business.whatsapp.com/policy"
                target="_blank"
              >
                here
              </GeneralLink>
            </Label>
            <Label variant="text7">
              If you want to contest this result with Meta, please contact
              support@interakt.ai
            </Label>
          </Box>
        ) : (
          <Label variant="text7" textAlign="center" color="#464D6D">
            Your business verification did not pass Meta's requirements. Please
            re-verify your business within 21 days, or your number will be
            blocked.
          </Label>
        )}
        {!isRejectionReasonBusinessViolated && (
          <Box display="flex" gridGap="16px">
            <Button
              onClick={() => setShowModal(false)}
              type="outline"
              width="100px"
              borderRadius="4px"
              fontSize="14px"
              fontWeight={600}
              height="44px"
            >
              Do it Later
            </Button>
            <Button
              onClick={handleOnClick}
              type="primaryV2"
              width="160px"
              borderRadius="4px"
              fontSize="14px"
              fontWeight={600}
              height="44px"
            >
              Verify Now
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
export default BusinessVerificationFailedModal;
