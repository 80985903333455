import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { callApiV2 } from "util/apiCaller";

export const RQ_PARTNER_WHITELABELED_DETAILS =
  "RQ_PARTNER_WHITELABELED_DETAILS";

interface ResponseData {
  domain?: string | null;
  privacy_policy_link?: string | null;
  partner_brand_name?: string | null;
  terms_and_condition_link?: string | null;
  logo_url?: string | null;
  logo_with_name_url?: string | null;
  partner_id?: string;
  support_email?: string;
}

interface ApiInput {
  domain?: string | null;
  partnerId?: string | null;
}

const fetchWhitelabeledDetails = async ({ domain, partnerId }: ApiInput) => {
  let endpoint = "v1/partner/whitelabeled-details/";

  if (domain) {
    endpoint += `?domain=${domain}`;
  } else if (partnerId) {
    endpoint += `?partner_id=${partnerId}`;
  }

  const res = await callApiV2({
    method: "get",
    endpoint,
    isErrorSuppressed: true,
  });

  if (res?.result) {
    return res.data as ResponseData;
  }
  throw new Error(res.message);
};

export type ApiBody = Awaited<ReturnType<typeof fetchWhitelabeledDetails>>;

const useWhitelabeledDetails = <T = ApiBody>(
  apiParams: ApiInput,
  select?: (data: ApiBody) => T,
  options: UseQueryOptions<ApiBody, Error, T> = {},
) => {
  const enabled = !!apiParams.domain || !!apiParams.partnerId;
  return useQuery<ApiBody, Error, T>(
    [RQ_PARTNER_WHITELABELED_DETAILS, apiParams.domain, apiParams.partnerId],
    () => fetchWhitelabeledDetails(apiParams),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined ? enabled : enabled && options.enabled,
    },
  );
};

export const selectTermsConditionLink = (data: ApiBody) =>
  data.terms_and_condition_link;
export const selectPrivacyPolicyLink = (data: ApiBody) =>
  data.privacy_policy_link;
export const selectLogoUrl = (data: ApiBody) => data.logo_url;
export const selectLargeLogo = (data: ApiBody) => data.logo_with_name_url;
export const selectBrandName = (data: ApiBody) => data.partner_brand_name;
export const selectSupportEmail = (data: ApiBody) => data.support_email;

export default useWhitelabeledDetails;
