import useWalletBalance, {
  selectWalletCurrency,
} from "useApi/Payment/useWalletBalance";
import useIsSignupSourceTally from "./useIsSignupSourceTally";
import useIsEnterpriseOrg from "./Organization/useIsEnterpriseOrg";

const useLowSufficientWalletBalance = () => {
  const isSignupSourceTally = useIsSignupSourceTally();
  const { data: walletCurrency } = useWalletBalance(selectWalletCurrency);

  const isEnterpriseOrg = useIsEnterpriseOrg();

  if (walletCurrency && walletCurrency.toLowerCase() !== "inr") {
    if (isSignupSourceTally) {
      return 4;
    }

    if (isEnterpriseOrg) {
      return 1000;
    }

    return 10;
  }
  if (isSignupSourceTally) {
    return 100;
  }

  if (isEnterpriseOrg) {
    return 10000;
  }

  return 1000;
};

export default useLowSufficientWalletBalance;
