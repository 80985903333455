/**
 * Root Reducer
 */
import { combineReducers } from "redux";
// Import Reducers
import app from "./modules/App/AppReducer";
import inboxState from "./modules/Inbox/inboxStateReducer";
import widget from "./modules/Widget/WidgetReducer";
import customer from "./modules/Customer/CustomerReducer";
import thread from "./modules/Inbox/ThreadReducer";
import conversation from "./modules/Inbox/ConversationReducer";
import tracker from "./modules/Inbox/TrackerReducer";
import toasts from "./modules/App/ToastReducer";
import signup from "./modules/Signup/SignupReducer";
import template from "./modules/Templates/TemplateReducer";
import campaign from "./modules/Notification/NotificationReducer";
import payment from "./modules/Payments/PaymentReducer";
import permissions from "./modules/Settings/PermissionReducer";
import codOrderDetails from "./modules/COD/redux/codOrderReducer";
import imageViewer from "./modules/ImageViewerModule/redux/ImageViewerReducer";
import analytics from "./modules/Analytics/AnalyticsReducer";
import mediaPreview from "./modules/MediaPreviewModule/redux/MediaPreviewReducer";
import commerceSettings from "./modules/CommerceSettings/CommerceSettingsReducer";
import orderPanel from "./modules/CommerceSettings/pages/OrderPanel/OrderPanelReducer";
import automation from "./modules/Automation/AutomationReducer";
import productPanel from "./modules/Inbox/ProductPanelReducer";
import botFlows from "./modules/BotFlows/BotFlowsReducer";
import integrations from "./modules/Integrations/integrationReducer";
import haptikUser from "./modules/App/HaptikReducer";
import socket from "./modules/App/SocketReducer";
import workflows from "./modules/Workflows/WorkflowReducer";
import shopifyRegister from "./modules/ShopifyRegister/ShopifyRegisterReducer";

// Combine all reducers into one root reducer
export default combineReducers({
  app,
  shopifyRegister,
  widget,
  inboxState,
  customer,
  thread,
  tracker,
  toasts,
  socket,
  signup,
  template,
  campaign,
  payment,
  permissions,
  conversation,
  codOrderDetails,
  imageViewer,
  analytics,
  mediaPreview,
  commerceSettings,
  orderPanel,
  automation,
  productPanel,
  botFlows,
  integrations,
  haptikUser,
  workflows,
});
