import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { callApiV2 } from "util/apiCaller";
import useOrganizationId from "customHooks/useOrganizationId";
import { useLocation } from "react-router-dom";
import useIsSandboxMode from "customHooks/useIsSandboxMode";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import useIsResellerSignupSource from "customHooks/useIsResellerSignupSource";
import OrganizationSubMenu from "components/OrganizationSubMenu/OrganizationSubMenu";

import useIsAdsPlanSignupSource from "customHooks/useIsAdsPlanSignupSource";
import {
  getSelectedAccountSegment,
  getSidebarStatus,
  getOrganizationOnboardedStatus,
} from "../../modules/App/AppSelectors";
import SideMenuContainer from "./NewSidebarContainer";

const Sidebar = () => {
  const [isSettingsMenuVisible, setIsSettingsMenuVisible] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const orgId = useOrganizationId();
  const location = useLocation();
  const isOrganizationOnboarded = useSelector(getOrganizationOnboardedStatus);
  const isSandboxMode = useIsSandboxMode();
  const isSignupSourceTally = useIsSignupSourceTally();
  const isResellerSignupSource = useIsResellerSignupSource();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const isShowSidebar = useSelector(getSidebarStatus);
  const selectedAccountSegment = useSelector(getSelectedAccountSegment);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const { count } = await callApiV2({
          method: "GET",
          endpoint: `/v1/organizations/${orgId}/commerce/orders/?limit=1&offset=0`,
        });
        setOrderCount(count);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    };

    if (orgId) {
      fetchOrders();
    }
  }, [orgId]);

  const toggleSettings = () => {
    setIsSettingsMenuVisible((state) => !state);
  };

  const matchUrl = (currentUrl, shouldBe) => {
    const curr = currentUrl.split("/");
    const shd = shouldBe.split("/");
    let isMatch = true;
    curr.forEach((item, index) => {
      const matchVal = !!shd[index] && shd[index] !== "" ? shd[index] : "";
      let matchValIndx = "";
      if (!!shd[index] && shd[index] !== "") {
        // eslint-disable-next-line prefer-destructuring
        matchValIndx = shd[index][0];
      }
      if (!(item === matchVal || matchValIndx === ":")) {
        isMatch = false;
      }
    });
    return isMatch;
  };

  const isCampaignActive = (currentUrl) =>
    currentUrl === "/notification" ||
    currentUrl === "/notification/create-campaign" ||
    matchUrl(currentUrl, "/notification/campaign/:id") ||
    matchUrl(currentUrl, "/notification/campaign/:id/update");

  const isSignupWhatsappActive = (currentPath) =>
    currentPath === "/signup" || currentPath === "/signup/whatsapp";

  const isInboxActive = (currentPath) => currentPath === "/inbox";

  const isTemplateActive = (currentPath) =>
    currentPath === "/templates/list" ||
    matchUrl(currentPath, "/template/:id/view") ||
    matchUrl(currentPath, "/template/:id/edit") ||
    matchUrl(currentPath, "/template/:id/duplicate");

  const isContactsListActive = (currentPath) =>
    currentPath === "/contacts/list" ||
    matchUrl(currentPath, "/contacts/:id/page");

  const isAnalyticsActive = (currentPath) =>
    currentPath?.includes("/analytics");

  const isWidgetActive = (currentPath) =>
    currentPath === "/widget/manage" || currentPath === "/widget/install";

  const isSettingsActive = (currentPath) =>
    currentPath.match("/setting") || currentPath.match("/billing/subscription");

  const isWalletActive = (currentPath) => currentPath?.includes("/payments");

  const isAdsActive = (currentPath) => currentPath?.includes("/smart-ads");

  const isAnnouncementsActive = (currentPath) =>
    currentPath?.includes("/announcements");

  const isIntegrationActive = (currentPath) =>
    currentPath?.match("/integrations");

  const getMenuPopupComponent = () => {
    return (
      isSettingsMenuVisible && (
        <OrganizationSubMenu toggleSettings={toggleSettings} />
      )
    );
  };

  const sidebarOnboardActive = (currentPath) => (
    <>
      <SideMenuContainer
        toggleSettings={toggleSettings}
        isCampaignActive={isCampaignActive(currentPath)}
        isSettingsActive={isSettingsActive(currentPath)}
        isWidgetActive={isWidgetActive(currentPath)}
        isAnalyticsActive={isAnalyticsActive(currentPath)}
        isContactsListActive={isContactsListActive(currentPath)}
        isTemplateActive={isTemplateActive(currentPath)}
        isInboxActive={isInboxActive(currentPath)}
        isSignupWhatsappActive={isSignupWhatsappActive(currentPath)}
        isWalletActive={isWalletActive(currentPath)}
        isIntegrationActive={isIntegrationActive(currentPath)}
        hasOrders={!!orderCount}
        isSidebarOnboardedActive
        isSettingsMenuVisible={isSettingsMenuVisible}
        isAdsActive={isAdsActive(currentPath)}
        isAnnouncementsActive={isAnnouncementsActive(currentPath)}
      />
      {getMenuPopupComponent()}
    </>
  );

  const siderbarOnboardInactive = (currentPath) => (
    <>
      <SideMenuContainer
        toggleSettings={toggleSettings}
        isSignupWhatsappActive={isSignupWhatsappActive(currentPath)}
        isSettingsActive={isSettingsActive(currentPath)}
        isSettingsMenuVisible={isSettingsMenuVisible}
      />
      {getMenuPopupComponent()}
    </>
  );

  if (
    selectedAccountSegment === "thankYouScreen" ||
    (selectedAccountSegment === "paymentPlanSelection" && !isSandboxMode) ||
    !isShowSidebar
  ) {
    return null;
  }
  const currentPath = location && location.pathname ? location.pathname : "";

  return isOrganizationOnboarded ||
    isSandboxMode ||
    isSignupSourceTally ||
    isResellerSignupSource ||
    isAdsPlanSignupSource
    ? sidebarOnboardActive(currentPath)
    : siderbarOnboardInactive(currentPath);
};

export default Sidebar;
