import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import { convert24hFormatTo12h } from "util/timeUtility";
import { createNewCondition, updateIftimezoneIsDifferent } from "util/utility";
import { parseJSON } from "util/jsonUtility";
import {
  ADD_BUTTON_FOR_FAQ_BOT,
  ADD_FAQ_BOT_REPLY,
  ADD_INPUT_VARIATION_FOR_FAQ_BOT,
  DELETE_FAQ_BOT_REPLY_STATUS,
  DELETE_FAQ_BOT_REPLY_STATUS_FAILURE,
  DELETE_FAQ_BOT_REPLY_STATUS_SUCCESS,
  FETCH_DELAYED_MESSAGE_DETAILS,
  FETCH_DELAYED_MESSAGE_DETAILS_FAILURE,
  FETCH_DELAYED_MESSAGE_DETAILS_SUCCESS,
  FETCH_FAQ_BOT_REPLIES_DETAILS,
  FETCH_FAQ_BOT_REPLIES_DETAILS_FAILURE,
  FETCH_FAQ_BOT_REPLIES_DETAILS_SUCCESS,
  FETCH_FAQ_BOT_REPLY_DETAILS,
  FETCH_FAQ_BOT_REPLY_DETAILS_FAILURE,
  FETCH_FAQ_BOT_REPLY_DETAILS_SUCCESS,
  FETCH_OUT_OF_OFFICE_DETAILS_FAILURE,
  FETCH_OUT_OF_OFFICE_DETAILS_START,
  FETCH_OUT_OF_OFFICE_DETAILS_SUCCESS,
  OOO_ADD_DAY,
  OOO_DELETE_DAY,
  OOO_SAVE_BTN_CLICK,
  OOO_UPDATE_DAY,
  OOO_UPDATE_END_TIME,
  OOO_UPDATE_MEDIA_URL,
  OOO_UPDATE_MESSAGE_FALLBACKVALUES,
  OOO_UPDATE_START_TIME,
  SAVE_BTN_CLICK_FOR_DELAYED_MESSAGE,
  SAVE_BTN_CLICK_FOR_FAQ_BOT_REPLY_MESSAGE,
  TOGGLE_DELAYED_MESSAGE_STATUS,
  TOGGLE_DELAYED_MESSAGE_STATUS_FAILURE,
  TOGGLE_DELAYED_MESSAGE_STATUS_SUCCESS,
  TOGGLE_FAQ_BOT_STATUS,
  TOGGLE_FAQ_BOT_STATUS_FAILURE,
  TOGGLE_FAQ_BOT_STATUS_SUCCESS,
  TOGGLE_OUT_OF_OFFICE_STATUS_FAILURE,
  TOGGLE_OUT_OF_OFFICE_STATUS_START,
  TOGGLE_OUT_OF_OFFICE_STATUS_SUCCESS,
  UPDATE_BUTTON_FOR_FAQ_BOT,
  UPDATE_FALLBACK_VALUES_FOR_DELAYED_MESSAGE,
  UPDATE_FALLBACK_VALUES_FOR_FAQ_BOT_REPLY_MESSAGE,
  UPDATE_FAQ_BOT_REPLY_PRODUCT_LIST,
  UPDATE_FAQ_BOT_REPLIES_DETAILS,
  UPDATE_FAQ_BOT_REPLY,
  UPDATE_FAQ_BOT_REPLY_DETAILS,
  UPDATE_INDEX_VALUE_FOR_FAQ_BOT,
  UPDATE_INITIAL_FAQ_BOT_STATUS,
  UPDATE_INPUT_ERROR_FOR_FAQ_BOT,
  UPDATE_INPUT_FOR_FAQ_BOT,
  UPDATE_INPUT_VARIATION_ERROR_FOR_FAQ_BOT,
  UPDATE_INPUT_VARIATION_FOR_FAQ_BOT,
  UPDATE_INTERAKTIVE_LIST,
  UPDATE_IS_BUTTON_DISABLE_FOR_FAQ_BOT,
  UPDATE_IS_LIST_MESSAGE_ENABLE_FAQ_BOT_REPLY,
  UPDATE_MEDIA_URL_FOR_DELAYED_MESSAGE,
  UPDATE_MEDIA_URL_FOR_FAQ_BOT_REPLY_MESSAGE,
  UPDATE_RESPONSE_TIME_FOR_DELAYED_MESSAGE,
  UPDATE_SELECTED_FILTER_FOR_FAQ_BOT,
  UPDATE_VARIABLE_COUNT_FOR_FAQ_BOT,
  UPDATED_FAQ_BOT_REPLIES_FILTER,
  UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_SUCCESS,
  UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_SUCCESS,
  FETCH_INTERAKTIVE_LIST_DETAILS,
  FETCH_INTERAKTIVE_LIST_DETAILS_SUCCESS,
  FETCH_INTERAKTIVE_LIST_DETAILS_FAILURE,
  SET_REPLY_ID_LIST,
  UPDATE_IS_BUTTON_DISABLE_FOR_LIST,
  UPDATE_ERROR_VISIBILITY,
  UPDATE_INTERAKTIVE_LIST_NAME,
  UPDATE_FAQ_REPLIES_CURRENT_PAGE,
  UPDATE_SELECTED_AGENTS,
  UPDATE_SELECTED_VALUES,
  UPDATE_CHAT_ASSIGNMENT_NAME,
  UPDATE_CHAT_ASSIGNMENT_TRAIT,
  UPDATE_CHAT_ASSIGNMENT_CONDITION,
  RESET_CHAT_ASSIGNMENT_VALUES,
  ADD_NEW_WELCOME_MESSAGE,
  UPDATE_FILTER_CONDITION,
  ADD_NEW_FILTER_CONDITION,
  DELETE_FILTER_CONDITION,
  DELETE_WELCOME_MESSAGE,
  UPDATE_WELCOME_MESSAGE_FIELD,
  UPDATE_WELCOME_MESSAGE_REDUCER,
  DISABLING_PERSONALISED_MESSAGE,
  ENABLING_PERSONALISED_MESSAGE,
} from "./AutomationActionTypes";

const generateNewWelcomeMessage = () => {
  const id = uuidv4();

  return {
    id,
    message: "",
    media_url: null,
    variable_fallback_values: [],
    buttons: [],
    is_list_message_enabled: false,
    list_message_name: null,
    template_id: null,
    template_data: {},
    workflow_id: null,
    list_message_id: "",
    conditions: [],
    num_message_sent: 0,
  };
};

const initialState = {
  isFetchingBasePermissions: false,
  basePermissions: [],
  groupedPermissions: [],
  isFetchingOrganizationRoles: false,
  organizationRoles: [],
  groupOrganizationRoles: {},
  selectedRole: "Owner",
  outOfOffice: {
    daysAndTime: [],
    message: "",
    isEnabled: false,
    isListMessageEnabled: false,
    listMessageName: null,
    fallbackValue: [],
    fetching: true,
    updatingStatus: false,
    mediaUrl: "",
    isSaveBtnClick: false,
    numMessageSent: 0,
  },
  delayedResponse: {
    responseTime: 0,
    message: "",
    isEnabled: false,
    isListMessageEnabled: false,
    listMessageName: null,
    fallbackValue: [],
    fetching: true,
    updatingStatus: false,
    mediaUrl: "",
    isSaveBtnClick: false,
    numMessageSent: 0,
  },
  faqBotRepliesResponse: {
    botRepliesCount: 0,
    totalBotReplies: 0,
    totalMessagesSent: 0,
    initialBotReplies: [],
    botReplies: [],
    fetching: false,
    isDeleting: false,
    deletingStatus: false,
    updatingStatus: false,
    isEnabled: false,
    selectedFilter: {
      trait: "",
      op: "",
      value: "",
    },
    currentPage: 1,
    updatedFilter: {},
  },
  botReplyResponse: {
    input: "",
    inputVariation: [],
    message: { customMessage: "", listMessage: "" },
    fallbackValue: [],
    mediaUrl: "",
    mediaFileName: "",
    buttons: [],
    isListMessageEnabled: false,
    fetching: true,
    creatingStatus: false,
    updatingStatus: false,
    isSaveBtnClick: false,
    isInitialValue: false,
    indexValue: "",
    variableCount: 0,
    isButtonDisable: false,
    inputError: {
      isError: null,
    },
    inputVariationError: [],
    responseType: 0,
    workflowId: null,
    match_type: null,
    productList: [],
  },
  interaktiveListResponse: {
    id: "",
    buttonText: "",
    interaktiveList: [],
    fetching: false,
    replyId: "",
    isButtonDisable: false,
    isErrorVisible: false,
  },
  chatAssignment: {
    selectedAgentList: [],
    selectedValues: [],
    name: "",
    trait: "",
    condition: "",
  },
  welcomeMessage: [generateNewWelcomeMessage()],
};

const automationReducer = (state = initialState, action) => {
  switch (action.type) {
    case OOO_ADD_DAY: {
      const updatedDaysAndTime = [
        ...state.outOfOffice.daysAndTime,
        {
          value: action.payload,
          startTime: "10:00 am",
          endTime: "07:00 pm",
        },
      ];

      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, daysAndTime: updatedDaysAndTime },
      };
    }

    case OOO_UPDATE_DAY: {
      const updatedDaysAndTime = state.outOfOffice.daysAndTime.map((item) =>
        item.value === action.payload.oldDay
          ? { ...item, value: action.payload.newDay }
          : item,
      );

      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, daysAndTime: updatedDaysAndTime },
      };
    }

    case OOO_DELETE_DAY: {
      const updatedDaysAndTime = state.outOfOffice.daysAndTime.filter(
        (item) => item.value !== action.payload,
      );

      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, daysAndTime: updatedDaysAndTime },
      };
    }

    case OOO_UPDATE_START_TIME: {
      const updatedDaysAndTime = state.outOfOffice.daysAndTime.map((item) =>
        item.value === action.payload.selectedDay
          ? { ...item, startTime: action.payload.time }
          : item,
      );

      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, daysAndTime: updatedDaysAndTime },
      };
    }

    case OOO_UPDATE_END_TIME: {
      const updatedDaysAndTime = state.outOfOffice.daysAndTime.map((item) =>
        item.value === action.payload.selectedDay
          ? { ...item, endTime: action.payload.time }
          : item,
      );

      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, daysAndTime: updatedDaysAndTime },
      };
    }

    case OOO_UPDATE_MESSAGE_FALLBACKVALUES: {
      const updatedData = {
        message: action.payload.message,
        fallbackValue: action.payload.fallbackValue,
      };

      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, ...updatedData },
      };
    }

    case OOO_UPDATE_MEDIA_URL: {
      return {
        ...state,
        outOfOffice: { ...state.outOfOffice, mediaUrl: action.payload },
      };
    }

    case FETCH_OUT_OF_OFFICE_DETAILS_START:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          fetching: true,
        },
      };

    case FETCH_OUT_OF_OFFICE_DETAILS_SUCCESS: {
      const weeklyData = action.payload.weekly_rule;
      const formattedData = [];

      for (const key in weeklyData) {
        const time_zone_offset =
          weeklyData[key]?.time_zone_offset || new Date().getTimezoneOffset();
        formattedData.push({
          value: key,
          startTime: convert24hFormatTo12h(
            updateIftimezoneIsDifferent(
              weeklyData[key].start_time,
              time_zone_offset,
            ).split("GMT")[0],
          ),
          endTime: convert24hFormatTo12h(
            updateIftimezoneIsDifferent(
              weeklyData[key].end_time,
              time_zone_offset,
            ).split("GMT")[0],
          ),
          time_zone_offset: new Date()?.getTimezoneOffset(),
        });
      }

      const formattedFallbackValues = JSON.parse(
        action.payload.variable_fallback_values,
      )?.map((item) => ({
        variableIndex: item.index,
        variableValue: item.variable_value,
        fallbackValue: item.fallback_values,
      }));

      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          fetching: false,
          daysAndTime: formattedData,
          message: action.payload.message,
          isEnabled: action.payload.is_enabled,
          isListMessageEnabled: action.payload.is_list_message_enabled,
          listMessageName: action.payload?.list_message_name || null,
          fallbackValue: formattedFallbackValues || [],
          mediaUrl: action.payload.media_url,
          numMessageSent: action.payload.num_messages_sent,
        },
      };
    }

    case FETCH_OUT_OF_OFFICE_DETAILS_FAILURE:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          fetching: false,
        },
      };

    case TOGGLE_OUT_OF_OFFICE_STATUS_START:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          updatingStatus: true,
        },
      };

    case TOGGLE_OUT_OF_OFFICE_STATUS_SUCCESS:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          isEnabled: action.payload,
          updatingStatus: false,
        },
      };

    case TOGGLE_OUT_OF_OFFICE_STATUS_FAILURE:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          updatingStatus: false,
        },
      };

    case UPDATING_OUT_OF_OFFICE_LIST_MESSAGE_STATUS_SUCCESS:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          isListMessageEnabled: action.payload?.is_list_message_enabled,
          listMessageName: action.payload?.list_message_name || null,
        },
      };

    case OOO_SAVE_BTN_CLICK:
      return {
        ...state,
        outOfOffice: {
          ...state.outOfOffice,
          isSaveBtnClick: action.payload,
        },
      };

    case UPDATE_FALLBACK_VALUES_FOR_DELAYED_MESSAGE: {
      const updatedData = {
        message: action.payload.message,
        fallbackValue: action.payload.fallbackValue,
      };

      return {
        ...state,
        delayedResponse: { ...state.delayedResponse, ...updatedData },
      };
    }

    case UPDATE_MEDIA_URL_FOR_DELAYED_MESSAGE: {
      return {
        ...state,
        delayedResponse: { ...state.delayedResponse, mediaUrl: action.payload },
      };
    }

    case FETCH_DELAYED_MESSAGE_DETAILS:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          fetching: true,
        },
      };

    case FETCH_DELAYED_MESSAGE_DETAILS_SUCCESS: {
      const formattedFallbackValues = JSON.parse(
        action.payload.variable_fallback_values,
      )?.map((item) => ({
        variableIndex: item.index,
        variableValue: item.variable_value,
        fallbackValue: item.fallback_values,
      }));

      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          fetching: false,
          message: action.payload.message,
          isEnabled: action.payload.is_enabled,
          isListMessageEnabled: action.payload.is_list_message_enabled,
          listMessageName: action.payload.list_message_name || null,
          fallbackValue: formattedFallbackValues || [],
          mediaUrl: action.payload.media_url,
          responseTime: action.payload.delay_by_seconds,
          numMessageSent: action.payload.num_messages_sent,
        },
      };
    }

    case FETCH_DELAYED_MESSAGE_DETAILS_FAILURE:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          fetching: false,
        },
      };

    case TOGGLE_DELAYED_MESSAGE_STATUS:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          updatingStatus: true,
        },
      };

    case TOGGLE_DELAYED_MESSAGE_STATUS_SUCCESS:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          isEnabled: action.payload,
          updatingStatus: false,
        },
      };

    case TOGGLE_DELAYED_MESSAGE_STATUS_FAILURE:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          updatingStatus: false,
        },
      };

    case SAVE_BTN_CLICK_FOR_DELAYED_MESSAGE:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          isSaveBtnClick: action.payload,
        },
      };

    case UPDATE_RESPONSE_TIME_FOR_DELAYED_MESSAGE:
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          responseTime: action.payload,
        },
      };

    case UPDATING_DELAYED_MESSAGE_LIST_MESSAGE_STATUS_SUCCESS: {
      return {
        ...state,
        delayedResponse: {
          ...state.delayedResponse,
          isListMessageEnabled: action.payload?.is_list_message_enabled,
          listMessageName: action.payload?.list_message_name || null,
        },
      };
    }

    case FETCH_FAQ_BOT_REPLIES_DETAILS:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          fetching: true,
        },
      };

    case UPDATE_FAQ_REPLIES_CURRENT_PAGE: {
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          currentPage: action.payload,
        },
      };
    }

    case FETCH_FAQ_BOT_REPLIES_DETAILS_SUCCESS:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          initialBotReplies: action.payload.results,
          botReplies: action.payload.results,
          totalBotReplies: action.payload.count,
          botRepliesCount: action.payload.count,
          totalMessagesSent: action.payload.total_messages_sent,
          fetching: false,
          selectedFilter: {
            trait: "",
            op: "",
            value: "",
          },
        },
      };

    case FETCH_FAQ_BOT_REPLIES_DETAILS_FAILURE:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          fetching: false,
        },
      };

    case DELETE_FAQ_BOT_REPLY_STATUS:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          isDeleting: true,
        },
      };

    case DELETE_FAQ_BOT_REPLY_STATUS_SUCCESS:
      const updatedBotReplies = state.faqBotRepliesResponse?.botReplies.filter(
        (item) => item.id !== action.faqId,
      );

      const updatedInteraktiveList = state.interaktiveListResponse?.interaktiveList.filter(
        (item) => item.id !== action.faqId,
      );

      return {
        ...state,
        faqBotRepliesResponse: {
          botReplies: updatedBotReplies,
          totalBotReplies: state.faqBotRepliesResponse?.totalBotReplies - 1,
          botRepliesCount: state.faqBotRepliesResponse?.totalBotReplies - 1,
          isDeleting: false,
          deletingStatus: true,
        },
        interaktiveListResponse: {
          interaktiveList: updatedInteraktiveList,
        },
      };

    case DELETE_FAQ_BOT_REPLY_STATUS_FAILURE:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          isDeleting: false,
          deletingStatus: false,
        },
      };

    case FETCH_FAQ_BOT_REPLY_DETAILS:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          fetching: true,
          workflowId: null,
          match_type: null,
        },
      };

    case FETCH_FAQ_BOT_REPLY_DETAILS_SUCCESS: {
      const formattedFallbackValues = action.payload?.variable_fallback_values?.map(
        (item) => ({
          variableIndex: item.index,
          variableValue: item.variable_value,
          fallbackValue: item.fallback_values,
        }),
      );

      const inputVariation = clone(action.payload?.keywords);
      inputVariation.shift();

      const data = {
        isError: null,
      };
      const inputVariationError = Array(inputVariation.length)
        .fill()
        .map((v, i) => data);

      const getFormattedMessage = () => {
        if (action.payload?.is_list_message_enabled) {
          return {
            customMessage: state.botReplyResponse?.message?.customMessage || "",
            listMessage: action.payload?.message,
          };
        }

        return {
          customMessage: action.payload?.message,
          listMessage: state.botReplyResponse?.message?.listMessage || "",
        };
      };

      const getReponseType = () => {
        const productList = parseJSON(
          action.payload?.products_list,
          action.payload?.products_list,
        );
        if (action.payload.is_list_message_enabled) {
          return 3;
        }
        if (action.payload.workflow_id) {
          return 2;
        }
        if (productList.length > 0) {
          return 1;
        }
        return 0;
      };

      return {
        ...state,
        botReplyResponse: {
          input: action.payload?.keywords && action.payload.keywords[0],
          inputVariation,
          message: getFormattedMessage(),
          fallbackValue: formattedFallbackValues || [],
          mediaUrl: action.payload.media_url,
          mediaFileName: action.payload.media_file_name || "",
          buttons:
            action.payload?.buttons?.map((item) => item?.reply?.title) || [],
          isListMessageEnabled: action.payload.is_list_message_enabled,
          fetching: false,
          variableCount: action.payload?.variable_fallback_values?.length,
          isButtonDisable: false,
          inputError: { isError: null },
          inputVariationError,
          responseType: getReponseType(),
          workflowId: action.payload?.workflow_id ?? null,
          match_type: action.payload?.match_type ?? null,
          productList: action.payload?.products_list ?? [],
        },
      };
    }

    case FETCH_FAQ_BOT_REPLY_DETAILS_FAILURE:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          fetching: false,
        },
      };

    case SAVE_BTN_CLICK_FOR_FAQ_BOT_REPLY_MESSAGE:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          isSaveBtnClick: action.payload,
        },
      };

    case UPDATE_FALLBACK_VALUES_FOR_FAQ_BOT_REPLY_MESSAGE: {
      const updatedData = {
        message: action.payload.message,
        fallbackValue: action.payload.fallbackValue,
      };

      return {
        ...state,
        botReplyResponse: { ...state.botReplyResponse, ...updatedData },
      };
    }

    case UPDATE_FAQ_BOT_REPLY_PRODUCT_LIST: {
      const updatedData = {
        productList: action.payload,
      };

      return {
        ...state,
        botReplyResponse: { ...state.botReplyResponse, ...updatedData },
      };
    }

    case UPDATE_MEDIA_URL_FOR_FAQ_BOT_REPLY_MESSAGE: {
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          mediaUrl: action.payload?.mediaUrl,
          mediaFileName: action.payload?.mediaFileName,
        },
      };
    }

    case UPDATE_FAQ_BOT_REPLY: {
      return {
        ...state,
        botReplyResponse: {
          input: "",
          inputVariation: [],
          message: "",
          fallbackValue: [],
          mediaUrl: "",
          buttons: [],
          isListMessageEnabled: false,
          isInitialValue: false,
          variableCount: 0,
          inputError: { isError: null },
          inputVariationError: [],
          responseType: 0,
          productList: [],
        },
      };
    }

    case UPDATE_IS_LIST_MESSAGE_ENABLE_FAQ_BOT_REPLY: {
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          isListMessageEnabled: action.payload === 3,
          responseType: action.payload,
        },
      };
    }

    case ADD_INPUT_VARIATION_FOR_FAQ_BOT: {
      const updatedInputVariation = [
        ...state.botReplyResponse.inputVariation,
        "",
      ];
      const updatedInputVariationError = [
        ...state.botReplyResponse.inputVariationError,
        {
          isError: null,
        },
      ];

      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          inputVariation: updatedInputVariation,
          inputVariationError: updatedInputVariationError,
        },
      };
    }

    case UPDATE_INPUT_VARIATION_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          inputVariation: action.payload,
        },
      };

    case ADD_BUTTON_FOR_FAQ_BOT: {
      const updatedInputVariation = [...state.botReplyResponse.buttons, ""];

      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          buttons: updatedInputVariation,
        },
      };
    }

    case UPDATE_BUTTON_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          buttons: action.payload,
        },
      };

    case UPDATE_INPUT_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          input: action.payload,
        },
      };

    case ADD_FAQ_BOT_REPLY:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          creatingStatus: true,
        },
      };

    case UPDATE_FAQ_BOT_REPLY_DETAILS:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          updatingStatus: true,
        },
      };

    case TOGGLE_FAQ_BOT_STATUS:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          updatingStatus: true,
        },
      };

    case TOGGLE_FAQ_BOT_STATUS_SUCCESS:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          isEnabled: action.payload,
          updatingStatus: false,
        },
      };

    case TOGGLE_FAQ_BOT_STATUS_FAILURE:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          updatingStatus: false,
        },
      };

    case UPDATE_INITIAL_FAQ_BOT_STATUS:
      if (action.payload) {
        return {
          ...state,
          botReplyResponse: {
            input: state.botReplyResponse?.input,
            inputVariation: state.botReplyResponse?.inputVariation,
            message: state.botReplyResponse?.message,
            mediaUrl: "",
            fallbackValue: [],
            buttons: [],
            variableCount: 0,
            isListMessageEnabled: state.botReplyResponse?.isListMessageEnabled,
            isInitialValue: action.payload,
            inputError: state.botReplyResponse?.inputError,
            inputVariationError: state.botReplyResponse?.inputVariationError,
            responseType: 0,
          },
        };
      }
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          isInitialValue: action.payload,
        },
      };

    case UPDATE_INDEX_VALUE_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          indexValue: action.payload,
        },
      };

    case UPDATE_VARIABLE_COUNT_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          variableCount: action.payload,
        },
      };

    case UPDATE_FAQ_BOT_REPLIES_DETAILS:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          botReplies: action.payload,
          botRepliesCount: action.payload?.length,
        },
      };

    case UPDATE_SELECTED_FILTER_FOR_FAQ_BOT:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          selectedFilter: action.payload,
        },
      };

    case UPDATED_FAQ_BOT_REPLIES_FILTER:
      return {
        ...state,
        faqBotRepliesResponse: {
          ...state.faqBotRepliesResponse,
          updatedFilter: action.payload,
        },
      };

    case UPDATE_IS_BUTTON_DISABLE_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          isButtonDisable: action.payload,
        },
      };

    case UPDATE_INPUT_VARIATION_ERROR_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          inputVariationError: action.payload,
        },
      };

    case UPDATE_INPUT_ERROR_FOR_FAQ_BOT:
      return {
        ...state,
        botReplyResponse: {
          ...state.botReplyResponse,
          inputError: action.payload,
        },
      };

    case FETCH_INTERAKTIVE_LIST_DETAILS:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          fetching: true,
        },
      };

    case FETCH_INTERAKTIVE_LIST_DETAILS_SUCCESS: {
      const interaktiveListData =
        action.payload.data &&
        action.payload.data.filter(
          (item) => item.type === "interaktive_list",
        )[0];
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          id: interaktiveListData?.id,
          buttonText: interaktiveListData?.button_text,
          interaktiveList: interaktiveListData?.message_content || [],
        },
      };
    }

    case FETCH_INTERAKTIVE_LIST_DETAILS_FAILURE:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          fetching: false,
        },
      };

    case UPDATE_INTERAKTIVE_LIST_NAME:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          buttonText: action.payload,
        },
      };

    case UPDATE_INTERAKTIVE_LIST:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          interaktiveList: action.payload,
        },
      };

    case SET_REPLY_ID_LIST:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          replyId: action.payload,
        },
      };

    case UPDATE_IS_BUTTON_DISABLE_FOR_LIST:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          isButtonDisable: action.payload,
        },
      };

    case UPDATE_ERROR_VISIBILITY:
      return {
        ...state,
        interaktiveListResponse: {
          ...state.interaktiveListResponse,
          isErrorVisible: action.payload,
        },
      };

    case UPDATE_SELECTED_AGENTS:
      return {
        ...state,
        chatAssignment: {
          ...state.chatAssignment,
          selectedAgentList: action.payload,
        },
      };

    case UPDATE_SELECTED_VALUES:
      return {
        ...state,
        chatAssignment: {
          ...state.chatAssignment,
          selectedValues: action.payload,
        },
      };

    case UPDATE_CHAT_ASSIGNMENT_NAME:
      return {
        ...state,
        chatAssignment: {
          ...state.chatAssignment,
          name: action.payload,
        },
      };

    case UPDATE_CHAT_ASSIGNMENT_TRAIT:
      return {
        ...state,
        chatAssignment: {
          ...state.chatAssignment,
          trait: action.payload,
        },
      };

    case UPDATE_CHAT_ASSIGNMENT_CONDITION:
      return {
        ...state,
        chatAssignment: {
          ...state.chatAssignment,
          condition: action.payload,
        },
      };

    case RESET_CHAT_ASSIGNMENT_VALUES:
      return {
        ...state,
        chatAssignment: { ...initialState.chatAssignment },
      };

    case ADD_NEW_WELCOME_MESSAGE: {
      const welcomeMessageCopy = cloneDeep(state.welcomeMessage);

      const welcomeMessageLength = state.welcomeMessage.length;

      welcomeMessageCopy[welcomeMessageLength - 1].conditions = [
        createNewCondition(),
      ];

      return {
        ...state,
        welcomeMessage: [...welcomeMessageCopy, generateNewWelcomeMessage()],
      };
    }

    case UPDATE_FILTER_CONDITION: {
      const { id, conditionId, data: updatedData } = action.payload;

      const getUpdatedCondition = (conditions) => {
        return conditions.map((item) => {
          if (item.id === conditionId) {
            return { ...item, ...updatedData };
          }

          return item;
        });
      };

      const getWelcomeMessageCopy = () => {
        return state.welcomeMessage.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              conditions: getUpdatedCondition(item.conditions),
            };
          }

          return item;
        });
      };

      return {
        ...state,
        welcomeMessage: getWelcomeMessageCopy(),
      };
    }

    case ADD_NEW_FILTER_CONDITION: {
      const getUpdatedWelcomeMessage = () => {
        return state.welcomeMessage.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              conditions: [
                ...item.conditions,
                {
                  ...createNewCondition(),
                  supr_op: { keyName: "AND", keyValue: "and" },
                },
              ],
            };
          }

          return item;
        });
      };

      return {
        ...state,
        welcomeMessage: getUpdatedWelcomeMessage(),
      };
    }

    case DELETE_FILTER_CONDITION: {
      const { id, conditionId } = action.payload;

      const getUpdatedCondition = (conditions) => {
        return conditions.filter((item) => item.id !== conditionId);
      };

      const getWelcomeMessageCopy = () => {
        return state.welcomeMessage.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              conditions: getUpdatedCondition(item.conditions),
            };
          }

          return item;
        });
      };

      return {
        ...state,
        welcomeMessage: getWelcomeMessageCopy(),
      };
    }

    case DELETE_WELCOME_MESSAGE: {
      const welcomeMessageCopy = state.welcomeMessage.filter(
        (item) => item.id !== action.payload,
      );

      return {
        ...state,
        welcomeMessage: welcomeMessageCopy,
      };
    }

    case UPDATE_WELCOME_MESSAGE_FIELD: {
      const welcomeMessageCopy = state.welcomeMessage.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.data };
        }

        return item;
      });

      return {
        ...state,
        welcomeMessage: welcomeMessageCopy,
      };
    }

    case UPDATE_WELCOME_MESSAGE_REDUCER: {
      const getFormattedConditions = (conditions) => {
        if (Array.isArray(conditions)) {
          return conditions.map((item) => ({ ...item, id: uuidv4() }));
        }

        return [];
      };

      const formattedWelcomeMessage = action.payload?.map((item) => {
        return { ...item, conditions: getFormattedConditions(item.conditions) };
      });

      return {
        ...state,
        welcomeMessage: formattedWelcomeMessage,
      };
    }

    case DISABLING_PERSONALISED_MESSAGE: {
      const welcomeMessageCopy = [...state.welcomeMessage];

      return {
        ...state,
        welcomeMessage: [{ ...welcomeMessageCopy[0], conditions: [] }],
      };
    }

    case ENABLING_PERSONALISED_MESSAGE: {
      const welcomeMessageCopy = cloneDeep(state.welcomeMessage);

      welcomeMessageCopy[0].conditions = [
        {
          ...createNewCondition(),
          supr_op: { keyName: "AND", keyValue: "and" },
        },
      ];

      return {
        ...state,
        welcomeMessage: [...welcomeMessageCopy, generateNewWelcomeMessage()],
      };
    }

    default:
      return state;
  }
};

export default automationReducer;
