import React from "react";

function hand({ fill, ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_514_1310)">
        <path
          id="Vector"
          d="M5.33334 8.66602V3.66602C5.33334 3.4008 5.43869 3.14645 5.62623 2.95891C5.81377 2.77137 6.06812 2.66602 6.33334 2.66602C6.59855 2.66602 6.85291 2.77137 7.04044 2.95891C7.22798 3.14645 7.33334 3.4008 7.33334 3.66602V7.99935"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.33334 3.66732V2.33398C7.33334 2.20266 7.3592 2.07263 7.40946 1.9513C7.45971 1.82998 7.53337 1.71974 7.62623 1.62688C7.71909 1.53402 7.82933 1.46036 7.95065 1.4101C8.07198 1.35985 8.20201 1.33398 8.33334 1.33398C8.46466 1.33398 8.59469 1.35985 8.71602 1.4101C8.83734 1.46036 8.94758 1.53402 9.04044 1.62688C9.1333 1.71974 9.20696 1.82998 9.25722 1.9513C9.30747 2.07263 9.33334 2.20266 9.33334 2.33398V8.00065"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M9.33334 3.66602C9.33334 3.4008 9.43869 3.14645 9.62623 2.95891C9.81377 2.77137 10.0681 2.66602 10.3333 2.66602C10.5986 2.66602 10.8529 2.77137 11.0404 2.95891C11.228 3.14645 11.3333 3.4008 11.3333 3.66602V7.99935"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M11.3333 5C11.3333 4.73478 11.4387 4.48043 11.6262 4.29289C11.8138 4.10536 12.0681 4 12.3333 4C12.5986 4 12.8529 4.10536 13.0404 4.29289C13.228 4.48043 13.3333 4.73478 13.3333 5V10.6667C13.3333 11.7275 12.9119 12.7449 12.1618 13.4951C11.4116 14.2452 10.3942 14.6667 9.33333 14.6667H8H8.13867C7.47622 14.6668 6.82412 14.5024 6.24092 14.1882C5.65772 13.874 5.16168 13.4199 4.79733 12.8667C4.75361 12.8001 4.71005 12.7334 4.66667 12.6667C4.45867 12.3473 3.72867 11.0747 2.476 8.848C2.3483 8.62102 2.31419 8.35318 2.38092 8.10143C2.44765 7.84969 2.60997 7.63392 2.83333 7.5C3.07125 7.35724 3.35005 7.29808 3.62544 7.3319C3.90083 7.36572 4.15703 7.49058 4.35333 7.68667L5.33333 8.66667"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_514_1310">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default hand;
