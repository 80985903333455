import React from "react";

const linkage = ({ fill, ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_255_13837)">
        <path
          id="Vector"
          d="M6 10L10 6"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.3335 3.99997L7.64216 3.64264C7.9517 3.33302 8.31921 3.08741 8.72368 2.91983C9.12816 2.75226 9.56168 2.66602 9.9995 2.66602C10.4373 2.66602 10.8708 2.75226 11.2753 2.91983C11.6798 3.08741 12.0473 3.33302 12.3568 3.64264C12.667 3.95191 12.913 4.31935 13.0809 4.72389C13.2487 5.12843 13.3351 5.56212 13.3351 6.00011C13.335 6.4381 13.2485 6.87176 13.0805 7.27626C12.9125 7.68075 12.6664 8.04812 12.3562 8.3573"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.40193 12.3567C7.76926 12.9818 6.91569 13.3324 6.02627 13.3324C5.13685 13.3324 4.28327 12.9818 3.6506 12.3567C3.33884 12.0484 3.09133 11.6813 2.92241 11.2767C2.75348 10.8722 2.6665 10.4381 2.6665 9.99965C2.6665 9.56122 2.75348 9.12714 2.92241 8.72256C3.09133 8.31797 3.33884 7.95092 3.6506 7.64265L3.99993 7.33398"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M10.6665 12.666H14.6665"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M12.6665 10.666V14.666"
          stroke={fill}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_13837">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default linkage;
