import {
  FETCH_CAMPAIGN_STATUS,
  FETCH_CAMPAIGN,
  SET_CREATE_CAMP_ACTIVE_TAB,
  UPDATE_CAMPAIGN_FINAL_FILTERS,
  SET_IS_FILTER_APPLIED,
  SET_ONLY_COUNT,
  SET_RETRY_ENABLED,
} from "./NotificationActions";

const initialState = {
  activeCampaign: {},
  isFetchingCampaign: false,
  campaignActiveTab: 0,
  finalFilters: [],
  isFilterApplied: false,
  onlyCount: false,
};

// eslint-disable-next-line default-param-last
const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREATE_CAMP_ACTIVE_TAB:
      return {
        ...state,
        campaignActiveTab: action.campaignActiveTab,
      };

    case FETCH_CAMPAIGN_STATUS:
      return {
        ...state,
        isFetchingCampaign: action.isFetchingCampaign,
      };

    case FETCH_CAMPAIGN:
      return {
        ...state,
        activeCampaign: action.activeCampaign,
        isFetchingCampaign: action.isFetchingCampaign,
      };

    case UPDATE_CAMPAIGN_FINAL_FILTERS:
      return {
        ...state,
        finalFilters: action.payload,
      };
    case SET_IS_FILTER_APPLIED:
      return {
        ...state,
        isFilterApplied: action.payload,
      };

    case SET_ONLY_COUNT:
      return {
        ...state,
        onlyCount: action.payload,
      };

    case SET_RETRY_ENABLED:
      return {
        ...state,
        retryEnabled: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default campaignReducer;

// selectors

export const getCampaignFetchStatus = (state) =>
  state.campaign.isFetchingCampaign;
export const getActiveCampaign = (state) => state.campaign.activeCampaign;
export const getCampaignActiveTab = (state) => state.campaign.campaignActiveTab;
export const getCampaignFinalFilters = (state) => state.campaign.finalFilters;
export const getIsFilterApplied = (state) => state.campaign.isFilterApplied;
export const getOnlyCount = (state) => state.campaign.onlyCount;
export const getRetryEnabled = (state) => state.campaign.retryEnabled;
