import { useQueryClient } from "@tanstack/react-query";
import {
  APP_INTEGRATION,
  BULK_DELETE,
  BULK_UPLOAD,
  CONVERSATION_LABEL,
  LIST_MESSAGE,
  MESSAGE_TEMPLATE,
  PRODUCT_CATALOG,
  QUICK_REPLY,
  SHOPIFY_CUSTOMER_SYNC,
  WALLET,
  INITIAL_CONTACT_SYNC,
  ORGANIZATION_META_ACCOUNT_UPDATE,
} from "constants/const";
import { INT_WEB_CSV_TOAST_SEND_CAMPAIGN } from "constants/mixPanel/campaigns";
import {
  INTERAKT_WEB_CAMPAIGN,
  MIX_PANEL_BTN_CLICK_ACTION,
} from "constants/mixPanel/common";
import Box from "konnekt/Box";
import GeneralLink from "konnekt/GeneralLink";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import {
  fetchListMessageDetails,
  fetchOrganizationDetailsForUser,
  fetchOrganizationTagById,
  fetchQuickReplies,
  toggleBusinessVerificationModalState,
} from "modules/App/AppActions";
import {
  approveAutoCheckoutSample,
  updateConnectionSyncStatus,
} from "modules/CommerceSettings/CommerceSettingsActions";
import { getCollections } from "modules/CommerceSettings/CommerceSettingsSelectors";
import {
  CATALOG_SYNC_STATUS_FAILED,
  CATALOG_SYNC_STATUS_SUCCESS,
} from "modules/CommerceSettings/constants";
import { updateShopifyCustomerSyncFinalState } from "modules/Customer/CustomerActions";
import {
  setIsSyncingProductDetails,
  setSyncingTimeout,
} from "modules/Inbox/ProductPanelActions";
import {
  fetchAndSetAppsData,
  fetchAppsInfoData,
  updateWhatsAppPayStatus,
} from "modules/Integrations/integrationActions";
import {
  getAllActiveTemplatesV2,
  updateSyncStatus,
  updateTemplateMessage,
} from "modules/Templates/TemplateActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useUser, { selectUserId } from "useApi/App/useUser";
import {
  pushLabelIfNotThere,
  RQ_CONVERSATION_LABELS,
} from "useApi/Inbox/useConversationLabels";
import { RQ_WALLET_BALANCE } from "useApi/Payment/useWalletBalance";
import { RQ_WALLET_TRANSACTIONS } from "useApi/Payment/useWalletTransactions";
import { Mixpanel } from "util/tracking";
import {
  showCustomNotification,
  showNotification,
  showNotificationV2,
} from "util/utility";
import { RQ_SHOPIFY_CUSTOMER_SYNC_STATUS } from "useApi/Customer/useShopifyCustomerSyncStatus";
import {
  SHOPIFY_CUSTOMER_FINAL_SYNC_ERROR,
  SHOPIFY_CUSTOMER_FINAL_SYNC_SUCCESS,
} from "constants/shopify";
import { selectBrandName } from "useApi/Partner/useWhitelabeledDetails";
import { RQ_GET_CUSTOMERS } from "useApi/Customer/useFetchCustomers";
import useOrgChannelList from "useApi/organization/useOrgChannelList";
import { RQ_TOTAL_CUSTOMER_COUNT } from "useApi/Customer/useTotalCustomerCount";
import {
  APPROVED,
  PENDING,
  REJECTED,
  WAITING,
} from "modules/Templates/constants";
import { CONNECTED } from "constants/channelConstants";
import useOrganizationId from "./useOrganizationId";
import useCurrentWhitelabeledDetails from "./useCurrentWhitelabeledDetails";

const useSocketSync = () => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();
  const { data: userId } = useUser(selectUserId);
  const queryClient = useQueryClient();
  const history = useHistory();
  const { data: brandName } = useCurrentWhitelabeledDetails(selectBrandName);
  const { refetch: refetchOrgChannelList } = useOrgChannelList();

  const onSync = (data) => {
    switch (data.syncType) {
      case QUICK_REPLY: {
        if (orgId) {
          return dispatch(fetchQuickReplies(orgId));
        }
        return "";
      }

      case SHOPIFY_CUSTOMER_SYNC: {
        const isSuccessful = data.extra?.success;
        const message = data.extra?.message;
        if (isSuccessful) {
          queryClient.setQueryData(
            [RQ_SHOPIFY_CUSTOMER_SYNC_STATUS, orgId],
            (prev) => ({
              ...prev,
              sync_in_progress: false,
              last_sync_at: new Date(),
              isStateUpdatedBySocket: true,
            }),
          );

          showNotificationV2(
            "success",
            "Sync of customers from Shopify is completed. Refresh the page to see the updated contacts list.",
          );
          dispatch(
            updateShopifyCustomerSyncFinalState(
              SHOPIFY_CUSTOMER_FINAL_SYNC_SUCCESS,
            ),
          );
        } else {
          queryClient.setQueryData(
            [RQ_SHOPIFY_CUSTOMER_SYNC_STATUS, orgId],
            (prev) => ({
              ...prev,
              sync_in_progress: false,
              isStateUpdatedBySocket: true,
            }),
          );

          showNotificationV2("error", message);
          dispatch(
            updateShopifyCustomerSyncFinalState(
              SHOPIFY_CUSTOMER_FINAL_SYNC_ERROR,
            ),
          );
        }

        return "";
      }

      case APP_INTEGRATION: {
        if (data.extra?.type === "EXTERNAL") {
          if (data.extra?.status === "success") {
            if (data.extra?.app === "Instamojo") {
              showCustomNotification(
                "success",
                <Box>
                  <Label variant="text6" color="white">
                    Your Instamojo store is now connected to your Interakt
                    account.
                  </Label>
                  <Label variant="text6" color="white">
                    To set live campaigns for abandoned checkouts, order
                    confirmations etc,{" "}
                    <GeneralLink
                      href="https://www.youtube.com/watch?v=ET_5LZKtZvU"
                      target="_blank"
                      fontSize="14px"
                      fontWeight="600"
                      color="link"
                      mt="10px"
                    >
                      follow the steps given here
                    </GeneralLink>
                    .
                  </Label>
                </Box>,
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  className: "toast-width-s",
                },
              );
            } else if (data.extra?.app === "Woocommerce") {
              showCustomNotification(
                "success",
                <Box>
                  <Label variant="text6" color="white">
                    Your Woocommerce store is now connected to your Interakt
                    account.
                  </Label>
                  <Label variant="text6" color="white">
                    To set live campaigns for abandoned checkouts, order
                    confirmations etc,{" "}
                    <GeneralLink
                      target="_blank"
                      fontSize="14px"
                      fontWeight="600"
                      color="link"
                      mt="10px"
                    >
                      complete all steps of the {brandName} plugin setup in
                      Woocommerce
                    </GeneralLink>
                    , and then{" "}
                    <GeneralLink
                      target="_blank"
                      fontSize="14px"
                      fontWeight="600"
                      color="link"
                      mt="10px"
                    >
                      set campaigns live in Interakt
                    </GeneralLink>
                    .
                  </Label>
                </Box>,
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  className: "toast-width-s",
                },
              );
            }
          } else if (data.extra?.status === "error") {
            showNotification("error", data.extra?.error_description);
          }
        } else if (data.extra?.type === "INTERNAL") {
          return dispatch(fetchAndSetAppsData(data));
        }
        break;
      }

      case MESSAGE_TEMPLATE: {
        if (
          data &&
          typeof data.extra.is_message_template_approve_for_whatsapp_pay ===
            "boolean"
        ) {
          if (data.extra?.approval_status === APPROVED) {
            dispatch(updateWhatsAppPayStatus(CONNECTED));
            showNotification(
              "success",
              "WhatsApp Pay has been enabled for your account!",
            );
          }
          if (
            data.extra?.approval_status === PENDING ||
            data.extra.approval_status === WAITING
          ) {
            dispatch(updateWhatsAppPayStatus(PENDING));
          }
          if (data.extra?.approval_status === REJECTED) {
            dispatch(updateWhatsAppPayStatus(REJECTED));
          }
          dispatch(fetchAppsInfoData());
        } else if (data && data.extra) {
          dispatch(
            approveAutoCheckoutSample(
              data.extra?.id,
              data.extra?.approval_status,
            ),
          );
          return dispatch(updateTemplateMessage(data.extra ? data.extra : {}));
        }
        if (data && data.extra === null) {
          // ? data.extra === null means sync is completed and we need to get templates again
          dispatch(getAllActiveTemplatesV2());
          dispatch(updateSyncStatus(false));
          return showNotification("success", "Template Sync Completed");
        }
        return "";
      }

      case CONVERSATION_LABEL: {
        if (data) {
          return queryClient.setQueryData(
            [RQ_CONVERSATION_LABELS, orgId],
            (prevData) =>
              pushLabelIfNotThere(prevData, data.extra ? data.extra : {}),
          );
        }
        return "";
      }

      // Triggered from Commerce Settings after connecting catalog id
      case PRODUCT_CATALOG: {
        if (data && data.extra) {
          if (data.extra.catalog_sync_status === CATALOG_SYNC_STATUS_FAILED) {
            showNotification("error", "Failed to Sync Catalog");
            dispatch(setIsSyncingProductDetails(""));
            dispatch(setSyncingTimeout(false));
          }
          if (data.extra.catalog_sync_status) {
            dispatch(
              updateConnectionSyncStatus(data.extra.catalog_sync_status, true),
            );
          }
          if (data.extra.catalog_sync_status === CATALOG_SYNC_STATUS_SUCCESS) {
            showNotification(
              "success",
              "Syncing has been successfully completed.",
            );
            if (orgId) {
              dispatch(getCollections(orgId));
            }
          }
          return dispatch(
            setIsSyncingProductDetails(data.extra.catalog_sync_status),
          );
        }
        return "";
      }

      case LIST_MESSAGE: {
        if (orgId) {
          dispatch(fetchListMessageDetails(orgId));
          return "";
        }
        return "";
      }

      case BULK_DELETE: {
        if (data.extra?.success) {
          queryClient.invalidateQueries({ queryKey: [RQ_GET_CUSTOMERS] });
          queryClient.invalidateQueries({
            queryKey: [RQ_TOTAL_CUSTOMER_COUNT],
          });

          showCustomNotification(
            "success",
            <Box display="flex">
              <Icon iconName="delete" iconSize="small" color="white" mr="8px" />
              <Box display="flex" flexDirection="column">
                <Label
                  fontFamily="Roboto"
                  fontSize="14px"
                  fontWeight="600"
                  color="white"
                >
                  {data?.extra?.message}
                </Label>
              </Box>
            </Box>,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              className: "toast-width-s",
            },
          );
        }
        return "";
      }

      case BULK_UPLOAD:
        dispatch(fetchOrganizationTagById(orgId));
        if (data.extra?.success) {
          dispatch({
            type: "UPLOADED_BULK_DATA",
            isUploadingBulkCSVData: false,
          });
          if (data.extra?.user_id === userId) {
            const csvUploadTag = data.extra.tags?.find((tag) =>
              // includes handle case for web and mobile app as well
              tag.name?.includes("Csv upload"),
            );

            const appliedFilters = csvUploadTag?.name
              ? [
                  {
                    keyName: "tags",
                    keyValue: [csvUploadTag?.name],
                    isOpenQuery: false,
                    isShowFilterChip: true,
                    supr_op: null,
                    dataType: "tags",
                    parent: "User",
                    relation: "are",
                    isApplied: true,
                    inputType: "dropdown",
                  },
                ]
              : null;
            showCustomNotification(
              "success",
              <Box display="flex">
                <Icon
                  iconName="upload"
                  iconSize="small"
                  color="white"
                  mr="8px"
                />
                <Box display="flex" flexDirection="column">
                  <Label
                    fontFamily="Roboto"
                    fontSize="14px"
                    fontWeight="600"
                    color="white"
                  >
                    {data.extra.message.split("\n")[0]}
                  </Label>
                  <Box display="flex" mt="10px">
                    <Label
                      fontFamily="Roboto"
                      fontSize="14px"
                      fontWeight="600"
                      color="white"
                    >
                      {data.extra.message.split("\n")[1].split(":")[0].trim()}:
                    </Label>
                    <Label
                      fontFamily="Roboto"
                      fontSize="14px"
                      fontWeight="500"
                      color="white"
                    >
                      {data.extra.message.split("\n")[1].split(":")[1]}
                    </Label>
                  </Box>
                  <Box display="flex">
                    <Label
                      fontFamily="Roboto"
                      fontSize="14px"
                      fontWeight="600"
                      color="white"
                    >
                      {data.extra.message.split("\n")[2].split(":")[0].trim()}:
                    </Label>
                    <Label
                      fontFamily="Roboto"
                      fontSize="14px"
                      fontWeight="500"
                      color="white"
                    >
                      {data.extra.message.split("\n")[2].split(":")[1]}
                    </Label>
                  </Box>
                  <Box display="flex" alignItems="center" gridGap="10px">
                    {csvUploadTag && appliedFilters && (
                      <Label
                        onClick={() => {
                          Mixpanel.track(INT_WEB_CSV_TOAST_SEND_CAMPAIGN, {
                            category: INTERAKT_WEB_CAMPAIGN,
                            action: MIX_PANEL_BTN_CLICK_ACTION,
                          });
                          history.push(`/notification/create-campaign`, {
                            redirectAppliedFilters: appliedFilters,
                          });
                        }}
                        fontSize="14px"
                        fontWeight="600"
                        color="white"
                        mt="10px"
                        textDecoration="underline"
                      >
                        Send them a campaign
                      </Label>
                    )}
                    {data.extra?.error_url && (
                      <GeneralLink
                        href={data.extra.error_url}
                        target="_blank"
                        fontSize="14px"
                        fontWeight="600"
                        color="white"
                        mt="10px"
                      >
                        Download Error Report
                      </GeneralLink>
                    )}
                  </Box>
                </Box>
              </Box>,
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              },
              "csvUploading",
            );
          }
        } else {
          dispatch({
            type: "UPLOADED_BULK_DATA",
            isUploadingBulkCSVData: false,
          });
          if (data.extra?.user_id === userId) {
            showCustomNotification(
              "error",
              <Box display="flex">
                <Icon
                  iconName="upload"
                  iconSize="small"
                  color="white"
                  mr="8px"
                />
                <Label
                  fontFamily="Roboto"
                  font-weight="500"
                  font-size="14px"
                  line-height="16px"
                  color="white"
                >
                  {data.extra.message}
                </Label>
              </Box>,
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                className: "toast-width-s",
              },
              "csvUploading",
            );
          }
        }
        break;

      case WALLET: {
        queryClient.refetchQueries({
          queryKey: [RQ_WALLET_BALANCE, orgId],
        });
        queryClient.removeQueries({
          queryKey: [RQ_WALLET_TRANSACTIONS, orgId],
          exact: false,
        });
        break;
      }

      case INITIAL_CONTACT_SYNC: {
        if (data.extra?.success && data?.extra?.initial_contacts_synced) {
          showCustomNotification(
            "success",
            <Box display="flex" gridGap="12px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="32px"
                height="32px"
              >
                <Icon
                  iconName="filledTick"
                  width="20px"
                  height="20px"
                  color="#0DB350"
                />
              </Box>
              <Box display="flex" flexDirection="column" gridGap="12px">
                <Label
                  fontSize="14px"
                  fontWeight={600}
                  color="#000000"
                  lineHeight="20px"
                >
                  Contact Sync Complete!
                </Label>
                <Label
                  fontSize="14px"
                  fontWeight={400}
                  color="#1E212F"
                  lineHeight="20px"
                >
                  Your contacts have been successfully synchronized. You&apos;re
                  all set to enjoy seamless communication!
                </Label>
              </Box>
            </Box>,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              className: "toast-width-s",
            },
          );
          refetchOrgChannelList();
        }
        break;
      }

      case ORGANIZATION_META_ACCOUNT_UPDATE: {
        dispatch(toggleBusinessVerificationModalState(true));
        dispatch(fetchOrganizationDetailsForUser());
        break;
      }

      default: {
        return "";
      }
    }
    return "";
  };

  return onSync;
};

export default useSocketSync;
