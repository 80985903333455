// Website type
export const STATIC_URL = "Static";
export const DYNAMIC_URL = "Dynamic";

export const urlTypeOptions = [
  {
    label: STATIC_URL,
    value: STATIC_URL,
  },
  {
    label: DYNAMIC_URL,
    value: DYNAMIC_URL,
  },
];

export const COPY_CODE = "COPY_CODE";
export const QUICK_REPLY = "QUICK_REPLY";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const URL = "URL";

export const URL_TEXT_LIMIT = 200;
export const BTN_TEXT_LIMIT = 25;
export const ORDER_DETAILS = "ORDER_DETAILS";
