import _ from "lodash";
import callApi from "../../util/apiCaller";
import { TOGGLE_FOR_NEW_CHANGE } from "./ConversationActions";
export const FETCH_TRACKER_DATA_BY_CHAT_LIST =
  "FETCH_TRACKER_DATA_BY_CHAT_LIST";
export const UPDATE_TRACKER_DATA = "UPDATE_TRACKER_DATA";

export function fetchTrackerReadByChatIds(filters) {
  return (dispatch) => {
    callApi(
      `v1/organizations/${filters.organizationId}/chats/read/${filters.agentId}/`,
      "post",
      {
        chat_ids: filters.chat_ids,
      },
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_TRACKER_DATA_BY_CHAT_LIST,
          trackers: res.data,
        });
      }
    });
  };
}

export function updateTrackerData(organizationId, trackerData) {
  return (dispatch) => {
    let update = _.clone(trackerData);
    dispatch({
      type: UPDATE_TRACKER_DATA,
      isUpdatingTrackerData: false,
      trackerData: update,
    });
    dispatch({
      type: TOGGLE_FOR_NEW_CHANGE,
    });
    callApi(
      `v1/organizations/${organizationId}/chats/read/track/`,
      "put",
      trackerData,
    ).then((res) => {
      if (!res) {
        dispatch({
          type: UPDATE_TRACKER_DATA,
          isUpdatingTrackerData: false,
        });
      }
    });
  };
}
