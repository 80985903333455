import { useInfiniteQuery } from "@tanstack/react-query";
import { CHANNEL_TYPE_RCS } from "components/ChannelTypeDropdown/constants";
import { API_CAMPAIGN, PUBLIC_API } from "constants/campaignsConstants";
import useOrganizationId from "customHooks/useOrganizationId";
import useUrlQuery from "konnekt/hooks/useUrlQuery";
import { getIsSMSChannel } from "modules/App/HaptikReducer";
import { useSelector } from "react-redux";
import { callApiV2 } from "util/apiCaller";
import { getPageDetailViaUrl, getPaginatedOffset } from "util/utility";

export const RQ_CAMPAIGNS_BY_FILTERS = "RQ_CAMPAIGNS_BY_FILTERS";

export function getCampaignFilters(inputFilters) {
  const filters = [];
  if (inputFilters.name_search) {
    filters.push(inputFilters.name_search);
  }
  if (inputFilters.campaign_type === "ongoing") {
    filters.push({
      trait: "campaign_type",
      op: "eq",
      val: "Ongoing",
    });
  } else if (inputFilters.campaign_type === API_CAMPAIGN) {
    filters.push({
      trait: "campaign_type",
      op: "eq",
      val: PUBLIC_API,
    });
  } else if (inputFilters.campaign_type !== "all") {
    filters.push({
      trait: "campaign_type",
      op: "eq",
      val: "OneTime",
    });
  }
  if (inputFilters.campaign_status && inputFilters.campaign_status !== "any") {
    filters.push({
      trait: "campaign_status",
      op: "eq",
      val: inputFilters.campaign_status,
    });
  }
  if (inputFilters.campaign_name && inputFilters.campaign_name !== "") {
    filters.push({
      trait: "campaign_status",
      op: "contains",
      val: inputFilters.campaign_name,
    });
  }
  if (inputFilters?.tagsFilter?.val) {
    filters.push({
      trait: "filter_clause",
      op: inputFilters.tagsFilter.op === "is" ? "contains" : "not",
      val: inputFilters.tagsFilter.val,
    });
  }

  if (inputFilters?.eventsFilter?.val) {
    filters.push({
      trait: "filter_clause",
      op: inputFilters.eventsFilter.op === "is" ? "contains" : "not",
      val: inputFilters.eventsFilter.val,
    });
  }

  return filters;
}

const fetchCampaignsByFilters = async ({ orgId, pageNum, pageSize, body }) => {
  const res = await callApiV2({
    method: "post",
    endpoint: `v1/organizations/${orgId}/campaigns/list/filter/?limit=${pageSize}&offset=${getPaginatedOffset(
      pageNum,
      pageSize,
    )}`,
    body,
    isErrorSuppressed: true,
  });
  if (res.results?.result) {
    return res;
  }
  throw new Error(res.message);
};

const useCampaignsByFilters = (
  filters,
  // eslint-disable-next-line no-use-before-define
  select = (data) => selectAllResults(data),
  options = {},
) => {
  const orgId = useOrganizationId();
  const [query] = useUrlQuery();
  const channelParam = query.get("channel_type");
  const isHaptikSMSChannel = useSelector(getIsSMSChannel);
  const body = {
    filters: getCampaignFilters(filters),
  };
  if (isHaptikSMSChannel) {
    body.channel_type = "sms";
  }
  if (channelParam === CHANNEL_TYPE_RCS) {
    body.channel_type = "rcs";
  }
  return useInfiniteQuery(
    [RQ_CAMPAIGNS_BY_FILTERS, orgId, body],
    ({ pageParam = { pageNum: 1, pageSize: 40 } }) => {
      return fetchCampaignsByFilters({
        body,
        orgId,
        pageNum: pageParam.pageNum,
        pageSize: pageParam.pageSize,
      });
    },
    {
      ...options,
      select,
      enabled:
        options.enabled !== undefined ? !!options.enabled && !!orgId : !!orgId,
      getNextPageParam: (lastPage) => {
        if (lastPage.next) {
          return getPageDetailViaUrl(lastPage.next);
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.previous) {
          return getPageDetailViaUrl(firstPage.previous);
        }
        return undefined;
      },
    },
  );
};

// setters
export const selectAllResults = (data) => {
  const allResults = [];

  data?.pages?.forEach((page) =>
    allResults.push(...(page.results?.data || [])),
  );

  return allResults;
};
export const selectTotalCount = (data) => data?.pages?.[0]?.count || 0;
export const selectPageNum = (data) => {
  return data?.pages?.length || 1;
};

// updaters
export const updateCampaignInInfiniteQuery = (
  prevData,
  newCampaign,
  campaignId,
) => {
  return {
    ...prevData,
    pages: prevData?.pages?.map((page) => ({
      ...page,
      results: {
        ...page.results,
        data: page.results.data.map((currentData) => {
          if (currentData.id === campaignId) {
            return newCampaign;
          }
          return currentData;
        }),
      },
    })),
  };
};

export default useCampaignsByFilters;
