import React from "react";
import { space } from "styled-system";
import Box from "konnekt/Box";
import GeneralLink from "konnekt/GeneralLink";
import Label from "konnekt/Label";
import styled from "styled-components";
import useInDesktop from "customHooks/useInDesktop";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";
import { selectTermsConditionLink } from "useApi/Partner/useWhitelabeledDetails";
import OrganizationDetailCard from "./OrganizationDetailCard";
import MenuList from "./MenuList";
import AccountList from "./AccountList";
import packageJson from "../../meta.json";

const MenuCard = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  width: 381px;
  min-height: 90vh;
  max-height: 96vh;
  background: #fefefe;
  border: 1px solid #d0d4e1;
  box-shadow: 0px 5px 13px rgba(102, 102, 102, 0.1);
  border-radius: 4px;
`;

export const Seperator = styled(Box)`
  width: 320px;
  height: 0px;
  border: 1px solid #eff1f5;
  transform: rotate(0.28deg);
  ${space}
`;

const OrganizationSubMenu = ({ toggleSettings }) => {
  const isDesktop = useInDesktop();
  const { data: termsConditionLink } = useCurrentWhitelabeledDetails(
    selectTermsConditionLink,
  );

  if (!isDesktop) {
    return null;
  }

  return (
    <>
      <Box
        bg="transparent"
        zIndex={998}
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        onClick={toggleSettings}
        cursor="auto"
      />
      <Box
        className="font-family-v2"
        position="fixed"
        left={232}
        top={10}
        zIndex={1000}
      >
        <MenuCard>
          <OrganizationDetailCard toggleSettings={toggleSettings} />
          <Box
            overflowY="auto"
            p="14px 14px 14px 6px"
            minHeight="55vh"
            width="100%"
          >
            <MenuList toggleSettings={toggleSettings} />
            <AccountList toggleSettings={toggleSettings} />
          </Box>
          <Box
            display="flex"
            height="80px"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            p="14px"
            flexDirection="column"
          >
            <Seperator />
            {!!termsConditionLink && (
              <GeneralLink
                onClick={toggleSettings}
                href={termsConditionLink}
                fontSize="14px"
                lineHeight="17px"
                color="darkGrey.100"
                cursor="pointer"
                textDecoration="none"
                target="_blank"
                mt="14px"
                mb="4px"
              >
                <Label variant="text8" color="#0A0B10">
                  Terms and Conditions
                </Label>
              </GeneralLink>
            )}
            <Label variant="text8" color="#5A638C">
              Version : {packageJson.version}
            </Label>
          </Box>
        </MenuCard>
      </Box>
    </>
  );
};

export default OrganizationSubMenu;
