import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useOnKeyPress from "customHooks/useOnKeyPress";
import { ESC } from "constants/KeyboardKeys";

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #222;
  opacity: 0.5;
  pointer-events: auto;
`;

const StyledModal = styled.div`
  position: fixed;
  z-index: 1040;
  width: ${({ width }) => width};
  height: auto;
  background-color: #fff;
  outline: none;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px rgba(214, 214, 214, 0.25);
  border-radius: 8px;
`;

const MiniModal = ({ children, isOpen, onHide, width, style }) => {
  const modalRef = useRef();
  useOnKeyPress([ESC], modalRef, onHide);

  if (!isOpen) {
    return null;
  }
  return (
    <>
      <StyledOverlay onClick={onHide} />
      <StyledModal ref={modalRef} style={style} width={width}>
        {children}
      </StyledModal>
    </>
  );
};

MiniModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  iconName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MiniModal.defaultProps = {
  isOpen: false,
  iconName: null,
  width: "80%",
};

export default MiniModal;
