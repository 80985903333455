import { useSelector } from "react-redux";
import { getIfFeatureIsVanishedByPaymentPlan } from "modules/Payments/PaymentSelectors";

const useShouldVanishFeatureByPaymentPlan = (features) => {
  const shouldVanishFeature = useSelector((state) =>
    getIfFeatureIsVanishedByPaymentPlan(state, features),
  );

  return shouldVanishFeature;
};

export default useShouldVanishFeatureByPaymentPlan;
