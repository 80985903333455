import { useEffect } from "react";
import _refiner from "refiner-js";

import useOrganizationDetails from "customHooks/Organization/useOrganizationDetails";
import useOrganizationId from "customHooks/useOrganizationId";
import useUser from "useApi/App/useUser";
import useActivePlan from "customHooks/useActivePlan";
import { useSelector } from "react-redux";

import {
  getAppsData,
  getSelectedApp,
} from "modules/Integrations/integrationSelectors";
import { getIsHaptikUser } from "modules/App/HaptikReducer";
import useChatsStats, {
  selectOrgActiveChatCount,
  selectOrgClosedChatCount,
} from "useApi/Inbox/useChatsStats";

const REFINER_KEY = "73e6d140-ac4e-11ed-8f02-031ecc7afb20";

const useRefinerInit = () => {
  const orgData = useOrganizationDetails();
  const orgId = useOrganizationId();
  const activePlan = useActivePlan();

  const { data: activeOrgChatCount } = useChatsStats(selectOrgActiveChatCount);
  const { data: closedOrgChatCount } = useChatsStats(selectOrgClosedChatCount);

  const integrationsList = useSelector(getAppsData);
  const selectedIntegrationApp = useSelector(getSelectedApp);

  const { data: userData, isFetched } = useUser();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "development") {
      _refiner("setProject", REFINER_KEY);
    }
  }, []);

  useEffect(() => {
    if (
      orgId &&
      isFetched &&
      activePlan &&
      integrationsList?.length &&
      process.env.REACT_APP_ENVIRONMENT !== "development"
    ) {
      _refiner("identifyUser", {
        id: userData?.id, // Replace with your user ID
        email: userData?.email, // Replace with user Email
        name: `${userData?.first_name} ${userData?.last_name}`,
        account: {
          account: orgId,
          name: orgData.name,
          active_chats_count: activeOrgChatCount,
          closed_chats_count: closedOrgChatCount,
          plan_name: activePlan,
          onboarding_status: orgData?.onboarding_status,
          catalog_id: !!orgData?.metadata?.catalog_id,
          catalog_sync_status: orgData?.metadata?.catalog_sync_status,
          sandbox_code: orgData?.metadata?.sandbox_code,
          credit_balance: orgData?.credit_balance,
          waba_policy_violation_warning_is_visible:
            orgData?.waba_policy_violation_warning_is_visible,
          isHaptikUser: getIsHaptikUser(),
        },

        FB_business_verification_status:
          orgData?.FB_business_verification_status,
        wa_api_approval_status: orgData?.wa_api_approval_status,
        company_type: orgData?.company_type,
        utm_campaign: orgData?.utm_campaign,
        utm_medium: orgData?.utm_medium,
        utm_source: orgData?.utm_source,
        utm_content: orgData?.utm_content,
        subscription_plan_selected_on_sign_up:
          orgData?.subscription_plan_selected_on_sign_up,
        company_location: orgData?.company_location,
        country: orgData?.country_initials,
        is_embedded_signup_enabled: orgData?.is_embedded_signup_enabled,
        signup_source: orgData?.signup_source,
        last_subscription_modified_at: orgData?.last_subscription_modified_at,
        is_faq_bot_enabled: orgData?.is_faq_bot_enabled,
        Customer_creation_date: orgData?.created_at_utc,
        last_waba_policy_violation_status_updated_at:
          orgData?.last_waba_policy_violation_status_updated_at,
        payment_gateway: orgData?.payment_gateway,
        revenue_currency: orgData?.revenue_currency,
        is_conversation_plan_allowed: orgData?.is_conversation_plan_allowed,
        role: userData?.permissions?.role,
        integrationsList,
        integrationsSelectedApp: selectedIntegrationApp,
        permissionsList: userData?.permissions?.features,
      });
    }
  }, [
    orgId,
    isFetched,
    activePlan,
    selectedIntegrationApp,
    integrationsList?.length,
  ]);
};

export default useRefinerInit;
