import { useSelector } from "react-redux";
import { ZOHO, SHOPIFY } from "constants/paymentGateway.json";
import {
  getOrgCurrency,
  getSelectedPhoneExtension,
  getIsOrganizationsHasInteraktPartner,
} from "modules/App/AppSelectors";
import useGetPaymentGateway from "customHooks/useGetPaymentGateway";
import useActivePlanV2 from "customHooks/useActivePlanV2";
import {
  GROWTH_PLAN_NAME,
  STARTER_PLAN_NAME,
  ADVANCED_PLAN_NAME,
  ADS_PLAN_NAME,
} from "constants/enums/paymentMethods";

const useShouldShowSmartAds = () => {
  const paymentGateway = useGetPaymentGateway();
  const hasZohoOrShopifyPaymentGateway = [ZOHO, SHOPIFY].includes(
    paymentGateway,
  );
  const orgCurrency = useSelector(getOrgCurrency);
  const hasINRCurrency = orgCurrency.toUpperCase() === "INR";
  const hasIndianMobileNo = useSelector(getSelectedPhoneExtension) === "+91";
  const isOrgHasInteraktPartner = useSelector(
    getIsOrganizationsHasInteraktPartner,
  );

  const activePlan = useActivePlanV2();
  const showSmartAdsByPlan = [
    STARTER_PLAN_NAME,
    GROWTH_PLAN_NAME,
    ADVANCED_PLAN_NAME,
    ADS_PLAN_NAME,
  ].includes(activePlan);

  return (
    isOrgHasInteraktPartner &&
    hasINRCurrency &&
    hasZohoOrShopifyPaymentGateway &&
    hasIndianMobileNo &&
    showSmartAdsByPlan
  );
};

export default useShouldShowSmartAds;
