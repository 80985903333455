import React from "react";

import useGetOrgCountry from "customHooks/Organization/useGetOrgCountry";

function getOrgCountryHOC(WrappedComponent) {
  return (props) => {
    const orgCountry = useGetOrgCountry();

    return <WrappedComponent orgCountry={orgCountry} {...props} />;
  };
}

export default getOrgCountryHOC;
