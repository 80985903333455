import React from "react";

const tickV2 = ({ fill, ...props }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4444 9.62649V10.4443C19.4433 12.3611 18.8227 14.2262 17.675 15.7614C16.5273 17.2967 14.914 18.4198 13.0759 18.9633C11.2377 19.5068 9.27311 19.4415 7.47508 18.7772C5.67704 18.113 4.14191 16.8852 3.09863 15.2772C2.05534 13.6692 1.55981 11.767 1.68593 9.85433C1.81205 7.94166 2.55307 6.121 3.79846 4.66389C5.04386 3.20678 6.72691 2.19128 8.59661 1.76884C10.4663 1.34641 12.4225 1.53968 14.1733 2.31983M19.4444 3.33316L10.5556 12.2309L7.88889 9.56427"
        stroke={fill}
        strokeWidth="1.77778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default tickV2;
