import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import InpageRibbon from "components/InpageRibbon";

import SIDEBAR_WIDTH from "constants/sidebar";
import GeneralLink from "konnekt/GeneralLink";
import useOrganizationId from "customHooks/useOrganizationId";
import useCloseWithLimit from "customHooks/useCloseWithLimit";
import useActivePlan from "customHooks/useActivePlan";
import { getOrganizationTraitByKeyName } from "modules/App/AppSelectors";
import useFilteredAnnouncement from "customHooks/useFilteredAnnouncement";
import { setInPageRibbon } from "modules/App/AppActions";
import { getActiveSubscriptionList } from "modules/Payments/PaymentSelectors";

import useIsConversationBasedPlansEnabled from "customHooks/useIsConversationBasedPlansEnabled";
import useIsConversationBasedPricing from "customHooks/useIsConversationBasedPricing";
import useRibbon from "useApi/App/useRibbon";
import { useLocation } from "react-router-dom";
import useAnnouncementTextReplace from "customHooks/useAnnouncementTextReplace";
import useUser from "useApi/App/useUser";

const IS_LIVE = true;

const Text = ({ text, linkText, link, linkAction, onCtaClick, ...props }) => (
  <>
    {text ? `${text} ` : ""}
    {linkText && (
      <GeneralLink
        fontWeight={600}
        fontSize="12px"
        lineHeight="1.2"
        color="white"
        target="_blank"
        textDecoration="none"
        href={linkAction !== "popup" && link}
        p="4px"
        borderRadius="3px"
        ml="8px"
        onClick={onCtaClick}
        {...props}
      >
        {linkText}
      </GeneralLink>
    )}
  </>
);

function PageRootRibbon({ sidebarGap = false, nonLoggedIn = false }) {
  const dispatch = useDispatch();

  // callback ref
  const heightRef = React.useCallback((node) => {
    if (node !== null) {
      dispatch(
        setInPageRibbon({
          visibility: true,
          height: node.getBoundingClientRect().height,
        }),
      );
    } else {
      dispatch(
        setInPageRibbon({
          visibility: false,
          height: 0,
        }),
      );
    }
  }, []);

  const orgId = useOrganizationId();
  const activePlan = useActivePlan();
  const isConversationBasedPlansEnabled = useIsConversationBasedPlansEnabled();
  const isConversationBasedPricing = useIsConversationBasedPricing();

  const activePlanList = useSelector(getActiveSubscriptionList);

  const signUpSource = useSelector((state) =>
    getOrganizationTraitByKeyName(state, "signup_source"),
  );

  const getFilteredRibbon = useFilteredAnnouncement();
  const [caughtErr, setCaughtErr] = useState(false);
  const [storageVariable, setStorageVariable] = useState();
  const [closeLimit, setCloseLimit] = useState();
  const [text, setText] = useState("");
  const [linkText, setLinkText] = useState("");
  const [link, setLink] = useState("");
  const [linkSettings, setLinkSettings] = useState({});
  const [iconSettings, setIconSettings] = useState({
    borderRadius: "50%",
    p: "3.5px",
    bg: "white",
  });
  const [ribbonSettings, setRibbonSettings] = useState({
    bg: "secondarydark",
    p: "11px",
    gridGap: "12px",
  });
  const { isShown, handleClose } = useCloseWithLimit({
    defaultShown: closeLimit === null,
    storageVariable,
    closeLimit,
  });

  const handleCloseBtnClick = () => {
    handleClose();
    dispatch(setInPageRibbon({ visibility: false, height: 0 }));
  };
  const { data: ribbonData, isInitialLoading } = useRibbon();

  const [image, setImage] = useState("");
  const [popupComponent, setPopupComponent] = useState(null);
  const [popupComponentProps, setPopupComponentProps] = useState(null);
  const [popupVisibility, setPopupVisibility] = useState(false);
  const [linkAction, setLinkAction] = useState("");
  const location = useLocation();
  const replaceVariables = useAnnouncementTextReplace();
  const { isLoading: userIsLoading } = useUser();
  const channelIsLoaded = useSelector((state) => !!state.app.channels?.[0]);

  useEffect(() => {
    const getDataFromRibbonApi = async () => {
      try {
        const filteredRibbon = getFilteredRibbon(ribbonData);

        setCloseLimit(filteredRibbon.closeLimit);
        setStorageVariable(filteredRibbon.id);
        setRibbonSettings((prev) =>
          typeof filteredRibbon.theme === "object"
            ? { ...prev, ...filteredRibbon.theme }
            : { ...prev },
        );
        setText(replaceVariables(filteredRibbon.text));
        setLinkText(replaceVariables(filteredRibbon.cta?.text));
        setLink(replaceVariables(filteredRibbon.cta?.url));
        setLinkSettings(
          typeof filteredRibbon.cta?.theme === "object"
            ? filteredRibbon.cta?.theme
            : {},
        );
        if (filteredRibbon.cta?.action) {
          setLinkAction(filteredRibbon.cta?.action);
        }
        setIconSettings((prev) =>
          typeof filteredRibbon.icon === "object"
            ? { ...prev, ...filteredRibbon.icon }
            : { ...prev },
        );
        if (filteredRibbon.cta?.popupImage) {
          setImage(filteredRibbon.cta?.popupImage);
        }

        if (filteredRibbon.cta?.componentName) {
          setPopupComponent(filteredRibbon.cta?.componentName);
        }

        if (filteredRibbon.cta?.componentProps) {
          setPopupComponentProps(filteredRibbon.cta?.componentProps);
        }

        setCaughtErr(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Error in ribbon", err.message);
        setCaughtErr(true);
      }
    };
    if (
      (signUpSource &&
        orgId &&
        activePlan &&
        !isInitialLoading &&
        channelIsLoaded &&
        !userIsLoading) ||
      (nonLoggedIn && !isInitialLoading)
    ) {
      getDataFromRibbonApi();
    }
  }, [
    signUpSource,
    orgId,
    activePlan,
    location.pathname,
    isInitialLoading,
    nonLoggedIn,
    channelIsLoaded,
    userIsLoading,
  ]);

  if (!IS_LIVE) return null;

  if (caughtErr) return null;

  if (!isShown) return null;

  if (!text && !linkText) return null;

  if (
    !isConversationBasedPlansEnabled &&
    storageVariable === "ribbon-22-sep-2022-CBP"
  ) {
    return null;
  }

  if (
    (isConversationBasedPricing ||
      activePlanList?.[0]?.subscription?.plan_type !== "Paid") &&
    storageVariable === "ribbon-22-sep-2022-CBP"
  ) {
    return null;
  }

  return (
    <div ref={heightRef}>
      <InpageRibbon
        forAllPlans
        width="auto"
        ml={sidebarGap ? SIDEBAR_WIDTH : "0px"}
        linkAction={linkAction}
        {...ribbonSettings}
        TextCompo={{
          text: (
            <Text
              text={text}
              linkText={linkText}
              link={link}
              linkSettings={linkSettings}
              linkAction={linkAction}
              onCtaClick={() => setPopupVisibility(true)}
              color={linkSettings.color || ribbonSettings?.bg}
              bg={linkSettings.bg || ribbonSettings?.color}
            />
          ),
          icon: {
            ...iconSettings,
          },
        }}
        onClose={closeLimit === null ? null : handleCloseBtnClick}
        popup={{
          src: image,
          setVisibility: setPopupVisibility,
          visibility: popupVisibility,
          link,
          componentName: popupComponent,
          componentProps: popupComponentProps,
        }}
      />
    </div>
  );
}

export default React.memo(PageRootRibbon);
