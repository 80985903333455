import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import useIsSandboxMode from "customHooks/useIsSandboxMode";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import useIsAdsPlanSignupSource from "customHooks/useIsAdsPlanSignupSource";
import PageAccessControl from "../components/PermissionLocks/PageAccessControl";

const ProtectRoute = ({
  component: Component,
  isAuthenticated,
  isOrganizationOnboarded,
  selectedAccountSegment,
  isPaymentDone,
  isRestrictedPage,
  shouldHaveRoles,
  shouldHavePermissions,
  shouldNotHaveVanishFeatures,
  ...rest
}) => {
  const isSandboxMode = useIsSandboxMode();
  const isSignupSourceTally = useIsSignupSourceTally();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (
            selectedAccountSegment === "paymentPlanSelection" &&
            !isPaymentDone &&
            !isSandboxMode &&
            !isSignupSourceTally
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/signup/whatsapp",
                  state: {
                    _refurl: `${props.location.pathname}${
                      !!props.location.search ? props.location.search : ""
                    }`,
                  },
                }}
              />
            );
          }

          if (
            isOrganizationOnboarded ||
            props.location.pathname.match("/signup") ||
            props.location.pathname.match("/billing/org-details") ||
            props.location.pathname.match("/billing/invoice") ||
            props.location.pathname.match("/mobile/settings") ||
            props.location.pathname.match("/settings/profile") ||
            props.location.pathname.match("/organization/account-details") ||
            isSandboxMode ||
            isSignupSourceTally ||
            isAdsPlanSignupSource
          ) {
            return (
              <PageAccessControl
                isRestrictedPage={isRestrictedPage}
                shouldHaveRoles={shouldHaveRoles}
                shouldHavePermissions={shouldHavePermissions}
                shouldNotHaveVanishFeatures={shouldNotHaveVanishFeatures}
              >
                <Component {...props} />
              </PageAccessControl>
            );
          }

          return (
            <Redirect
              to={{
                pathname: "/signup/whatsapp",
                state: {
                  _refurl: `${props.location.pathname}${
                    !!props.location.search ? props.location.search : ""
                  }`,
                },
              }}
            />
          );
        }

        if (location.pathname === "/signup/external/whatsapp") {
          return (
            <Redirect
              to={{
                pathname: "/signup/external",
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                _refurl: `${props.location.pathname}${
                  !!props.location.search ? props.location.search : ""
                }`,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectRoute;
