import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";

const useOrganizationDetails = (selector = (org) => org) =>
  useSelector(
    (state) => selector(state.app?.organizations?.[0]?.organization_id),
    isEqual,
  );

export default useOrganizationDetails;
