import Box from "konnekt/Box";
import Label from "konnekt/Label";
import React from "react";
import { useHistory } from "react-router-dom";
import { getFormattedRelativeTime } from "util/timeUtility";
import { Mixpanel } from "util/tracking";
import { INT_CONCIERGE_SIDEBAR_CARD_CLICKED } from "../constant";

const AnnouncementCard = ({
  name,
  description,
  time,
  showDivider = true,
  index,
}) => {
  const history = useHistory();

  return (
    <Box
      onClick={() => {
        Mixpanel.track(INT_CONCIERGE_SIDEBAR_CARD_CLICKED, {
          name,
        });
        history.push("/announcements", { index });
      }}
      width="100%"
      borderBottom={showDivider ? "1px solid #D0D4E1" : "none"}
      p="16px"
      hoverCss="background-color: #cefbe030"
      cursor="pointer"
    >
      <Box cursor="pointer" display="flex" alignItems="center">
        <Box borderRadius="10px" bg="#00A68B" width="8px" height="8px" />
        <Label variant="text6" color="darkGrey.100" ml="12px">
          {name}
        </Label>
      </Box>
      <Box cursor="pointer" ml="20px">
        <Label mt="12px" color="#1E212F" variant="text7">
          {description}
        </Label>
        <Label cursor="pointer" mt="12px" color="#1E212F" variant="text8">
          {getFormattedRelativeTime(time)}
        </Label>
      </Box>
    </Box>
  );
};

export default AnnouncementCard;
