import { isJSON } from "util/utility";
import { LOCAL_STEP_ID } from "./constants/constants";
import {
  ADD_NEW_WORKFLOW_STEP,
  REMOVE_WORKFLOW_STEP,
  SET_ACTIVE_WORKFLOW_STEP,
  SET_EXISTING_WORKFLOW_VARIABLE,
  SET_WORKFLOW_NAME,
  SET_WORKFLOW_STEP,
  SET_WORKFLOW_STEPS,
  TOGGLE_ADS_SELECTION_MODAL_STATUS,
  TOGGLE_AD_WORKFLOW_SUCCESS_MODAL_STATUS,
  TOGGLE_REMOVE_AD_LINKAGE_MODAL_STATUS,
} from "./WorkflowActions";

const initialState = {
  workflowSteps: [],
  activeStepId: `${LOCAL_STEP_ID}_0`,
  workflowName: "",
  isAdWorkflowSuccessModalOpen: false,
  isAdsSelectionModalOpen: false,
  isRemoveAdLinkageModalOpen: false,
  existingWorkflowVariables: [],
};

const updatedStep = (step) => {
  if (
    typeof step.variable_fallback_values === "object" &&
    Array.isArray(step.variable_fallback_values)
  ) {
    return step;
  }
  if (typeof step.variable_fallback_values === "string") {
    if (isJSON(typeof step.variable_fallback_values)) {
      return {
        ...step,
        variable_fallback_values: JSON.parse(step.variable_fallback_values),
      };
    }
    return {
      ...step,
      variable_fallback_values: [],
    };
  }
  return {
    ...step,
    variable_fallback_values: [],
  };
};

// eslint-disable-next-line default-param-last
const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WORKFLOW_STEPS: {
      return {
        ...state,
        workflowSteps: action.workflowSteps.map((step) => updatedStep(step)),
      };
    }
    case SET_ACTIVE_WORKFLOW_STEP: {
      return {
        ...state,
        activeStepId: action.activeStepId,
      };
    }
    case SET_WORKFLOW_NAME: {
      return {
        ...state,
        workflowName: action.workflowName,
      };
    }
    case ADD_NEW_WORKFLOW_STEP: {
      return {
        ...state,
        workflowSteps: [...state.workflowSteps, updatedStep(action.step)],
      };
    }
    case SET_WORKFLOW_STEP: {
      const newStep = action.override ? updatedStep(action.step) : action.step;
      return {
        ...state,
        workflowSteps: state.workflowSteps.map((step) => {
          if (step.id === action.step.id) {
            return { ...step, ...newStep };
          }
          return step;
        }),
      };
    }
    case REMOVE_WORKFLOW_STEP: {
      return {
        ...state,
        workflowSteps: state.workflowSteps.filter(
          (step) => step.id !== action.stepId,
        ),
      };
    }
    case TOGGLE_AD_WORKFLOW_SUCCESS_MODAL_STATUS:
      return {
        ...state,
        isAdWorkflowSuccessModalOpen: action.selectedAd,
      };

    case TOGGLE_ADS_SELECTION_MODAL_STATUS:
      return {
        ...state,
        isAdsSelectionModalOpen: action.value,
      };

    case TOGGLE_REMOVE_AD_LINKAGE_MODAL_STATUS:
      return {
        ...state,
        isRemoveAdLinkageModalOpen: action.value,
      };

    case SET_EXISTING_WORKFLOW_VARIABLE:
      return {
        ...state,
        existingWorkflowVariables: action.existingWorkflowVariables,
      };

    default: {
      return state;
    }
  }
};

export default workflowReducer;
