import React from "react";
import useInitApiCalls from "customHooks/useInitApiCalls";

const withInitApiCalls = (WrappedComponent: React.ComponentClass<any>) => {
  return (props: Record<string, any>) => {
    const isInitApisLoading = useInitApiCalls();

    return (
      <WrappedComponent isInitApisLoading={isInitApisLoading} {...props} />
    );
  };
};

export default withInitApiCalls;
