export const INT_WEB_EMBEDDED_SIGNUP_CONNECT_TO_FB_CTA =
  "int_web_embedded_signup_connect_to_fb_cta";
export const TALLY_ONBOARDING_DIFFERENCES_ACCORDION_CLICK =
  "tally_onboarding_differences_accordion_click";
export const TALLY_ONBOARDING_RESTRICTEDINDUSTRIES_ACCORDION_CLICK =
  "tally_onboarding_restricedindustries_accordion_click";
export const TALLY_ONBOARDING_WHATYOUNEED_ACCORDION_CLICK =
  "tally_onboarding_whatyouneed_accordion_click";
export const TALLY_ONBOARDING_ACCEPTDISCLAIMERS_CHECKBOX_CLICK =
  "tally_onboarding_acceptdisclaimers_checkbox_click";
export const TALLY_ONBOARDING_STARTEMBEDDDED_CTA_CLICK =
  "tally_onboarding_startembedded_cta_click";
export const TALLY_ONBOARDING_EMBEDDEDGUIDE_CTA_CLICK =
  "tally_onboarding_embeddedguide_cta_click";
export const TALLY_ONBOARDING_EXISTINGNUBERRULES_CTA_CLICK =
  "tally_onboarding_existingnumberrules_cta_click";
export const TALLY_ONBOARDING_FBPAGERULES_CTA_CLICK =
  "tally_onboarding_fbpagerules_cta_click";
