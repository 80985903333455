import { FB_BUSINESS_VERIFICATION_STATUS_VERIFIED } from "constants/enums/FBBusinessVerificationStatuses";
import {
  WABA_BANNED,
  WABA_WARNED,
  WABA_RESTORED,
} from "constants/wabaConstants";
import {
  getKeyValueFromOrganizations,
  getUserActiveOrganization,
  getOrganizationTraitByKeyName,
  getIsWabaPolicyViolationVisible,
  getWabaPolicyViolationStatus,
  getNetworkStatus,
} from "modules/App/AppSelectors";
import { getIsSocketConnected } from "modules/App/SocketSelectors";
import {
  getFreeTrialApplicableStatus,
  getFreeTrialActiveStatus,
  getIsPaymentDoneRibbonStatus,
  getFetchSubscriptionStatus,
  getIsDaysLeftFreeTrailRibbon,
  getRemainingTrialDays,
  getTotalFreeTrailDays,
  getPaidSubscriptionStatus,
  getSubscriptionAPICalledStatus,
  getIfOrganizationIsOnFreePlan,
  getUncollectiblePaymentStatus,
  getOpenInvoicePaymentStatus,
  getActiveSubscriptionCount,
  getSubscriptionExpiredRibbonStatus,
  getRestrictOTCStatus,
  getRestrictCampaignStatusForZoho,
} from "modules/Payments/PaymentSelectors";
import moment from "moment";
import { useSelector } from "react-redux";
import useWalletBalance, {
  selectWalletBalance,
} from "useApi/Payment/useWalletBalance";
import { isNotificationPermissionRequired } from "util/deviceUtility";
import signupSource from "constants/signup-source.json";
import paymentGateway from "constants/paymentGateway.json";
import useIsSignupSourceTally from "./useIsSignupSourceTally";
import useLowSufficientWalletBalance from "./useLowSufficientWalletBalance";
import useShowWalletFeature from "./useShowWalletFeature";
import useIsResellerSignupSource from "./useIsResellerSignupSource";
import useIsSignupSource from "./useIsSignupSource";
import useIsAdsPlanSignupSource from "./useIsAdsPlanSignupSource";

const useActiveRibbonType = (isFetchingOrganizationDetails: boolean) => {
  const lowWalletBalance = useLowSufficientWalletBalance();

  const showWalletFeature = useShowWalletFeature();
  const { data: walletBalance } = useWalletBalance(selectWalletBalance);
  const isFreeTrialApplicable = useSelector(getFreeTrialApplicableStatus);
  const isFreeTrialActive = useSelector(getFreeTrialActiveStatus);
  const isPaymentDoneRibbon = useSelector(getIsPaymentDoneRibbonStatus);
  const isFetchingSubscription = useSelector(getFetchSubscriptionStatus);
  const isDaysLeftFreeTrailRibbon = useSelector(getIsDaysLeftFreeTrailRibbon);
  const trialDays = useSelector(getRemainingTrialDays);
  const totalFreeTrailDays = useSelector(getTotalFreeTrailDays);
  const FBBusinessVerificationStatus = useSelector((state) =>
    getKeyValueFromOrganizations(state, "FB_business_verification_status"),
  );
  const isHaveAnyPaidSubscription = useSelector(getPaidSubscriptionStatus);
  const isFetchSubscriptionAPIDone = useSelector(
    getSubscriptionAPICalledStatus,
  );
  const isOrganizationOnFreePlan = useSelector(getIfOrganizationIsOnFreePlan);
  const isHaveUncollectiblePayment = useSelector(getUncollectiblePaymentStatus);
  const isHaveOpenInvoice = useSelector(getOpenInvoicePaymentStatus);
  const activeSubscriptionCount = useSelector(getActiveSubscriptionCount);
  const payment_gateway = useSelector(
    (state) => getUserActiveOrganization(state)?.payment_gateway,
  );
  const subscriptionExpiredRibbonStatus = useSelector(
    getSubscriptionExpiredRibbonStatus,
  );
  const appSignupSource = useSelector((state) =>
    getOrganizationTraitByKeyName(state, "signup_source"),
  );
  const isWabaPolicyViolationVisible = useSelector(
    getIsWabaPolicyViolationVisible,
  );
  const wabaPolicyVoilationStatus = useSelector(getWabaPolicyViolationStatus);

  const isOTCRestricted = useSelector(getRestrictOTCStatus);
  const isZohoCampaignRestricted = useSelector(
    getRestrictCampaignStatusForZoho,
  );

  const getThirtyPercentageOfDaysLeft = () =>
    Math.floor((30 / 100) * totalFreeTrailDays);

  const isShowTrialExpireRibbon = () =>
    isFreeTrialApplicable && !isFreeTrialActive && isPaymentDoneRibbon;

  const getRemainingFreeXTrailDays = () =>
    isFreeTrialApplicable &&
    isFreeTrialActive &&
    isDaysLeftFreeTrailRibbon &&
    trialDays &&
    getThirtyPercentageOfDaysLeft() &&
    trialDays < getThirtyPercentageOfDaysLeft() + 1;

  const getIfFBBusinessVerificationStatusIsVerified = () =>
    FBBusinessVerificationStatus === FB_BUSINESS_VERIFICATION_STATUS_VERIFIED;

  const showPaymentDueRibbon = () =>
    !isFreeTrialApplicable &&
    !isHaveAnyPaidSubscription &&
    isPaymentDoneRibbon &&
    isFetchSubscriptionAPIDone &&
    !isOrganizationOnFreePlan;

  const showPendingInvoiceRibbonWithBlocker = () =>
    isHaveUncollectiblePayment &&
    isHaveAnyPaidSubscription &&
    isPaymentDoneRibbon;

  const showPendingInvoiceRibbonWithoutBlocker = () =>
    !isHaveUncollectiblePayment &&
    isHaveOpenInvoice &&
    isHaveAnyPaidSubscription &&
    isPaymentDoneRibbon;

  const isShowSubscriptionExpiredRibbon = () =>
    !isFreeTrialApplicable &&
    !isFreeTrialActive &&
    activeSubscriptionCount === 0 &&
    subscriptionExpiredRibbonStatus &&
    payment_gateway !== paymentGateway.MANUAL;

  const isShowBusinessVerificationRibbon = () =>
    isFreeTrialApplicable &&
    isFreeTrialActive &&
    subscriptionExpiredRibbonStatus;

  // This is for blocking Zoho orgs after 24 hours of pending invoice.
  const showPendingInvoiceRibbonWithRestrictCampaignBlocker = () =>
    !isHaveUncollectiblePayment &&
    showWalletFeature &&
    !isFetchingSubscription &&
    !isFetchingOrganizationDetails &&
    isZohoCampaignRestricted;

  const isSocketConnected = useSelector(getIsSocketConnected);
  const isOnline = useSelector(getNetworkStatus);
  const isSignupSourceTally = useIsSignupSourceTally();
  const isResellerOnlineSignupSource = useIsSignupSource("ResellerOnline");
  const isResellerOfflineSignupSource = useIsSignupSource("ResellerOffline");
  const isResellerWhiteLabeledOnlineSignupSource = useIsSignupSource(
    "ResellerWhiteLabeledOnline",
  );
  const isResellerWhiteLabeledOfflineSignupSource = useIsSignupSource(
    "ResellerWhiteLabeledOffline",
  );
  const isResellerSignupSource = useIsResellerSignupSource();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();

  // TODO: Use constants for strings
  if (!isSocketConnected || !isOnline) {
    return "socket";
  }

  if (isWabaPolicyViolationVisible) {
    if (wabaPolicyVoilationStatus === WABA_BANNED) {
      if (isSignupSourceTally) {
        return "waba_banned_tally";
      }
      return "waba_banned";
    }
    if (wabaPolicyVoilationStatus === WABA_WARNED) {
      return "waba_warned";
    }

    if (wabaPolicyVoilationStatus === WABA_RESTORED && isSignupSourceTally) {
      return "waba_restored_tally";
    }

    if (
      wabaPolicyVoilationStatus === WABA_RESTORED &&
      (isResellerOfflineSignupSource ||
        isResellerWhiteLabeledOfflineSignupSource)
    ) {
      return "waba_restored_reseller_offline";
    }

    if (
      wabaPolicyVoilationStatus === WABA_RESTORED &&
      (isResellerWhiteLabeledOnlineSignupSource || isResellerOnlineSignupSource)
    ) {
      return "waba_restored_reseller_online";
    }

    if (wabaPolicyVoilationStatus === WABA_RESTORED) {
      return "waba_restored";
    }
  }

  if (showWalletFeature) {
    if (
      showPendingInvoiceRibbonWithRestrictCampaignBlocker() &&
      !isSignupSourceTally &&
      !isResellerSignupSource
    ) {
      return "user_with_pending_invoices";
    }
    if (walletBalance === 0 && isSignupSourceTally) {
      return "user_has_subscribed_for_first_time_tally";
    }
    if (
      walletBalance === 0 &&
      (isResellerWhiteLabeledOnlineSignupSource || isResellerOnlineSignupSource)
    ) {
      return "user_has_subscribed_for_first_time_reseller_online";
    }
    if (
      walletBalance === 0 &&
      !(
        isResellerOfflineSignupSource ||
        isResellerWhiteLabeledOfflineSignupSource
      )
    ) {
      return "user_has_subscribed_for_first_time";
    }
    if (walletBalance <= 0 && isSignupSourceTally) {
      return "user_with_insufficient_wallet_balance_tally";
    }
    if (
      walletBalance <= 0 &&
      (isResellerWhiteLabeledOnlineSignupSource || isResellerOnlineSignupSource)
    ) {
      return "user_with_insufficient_wallet_balance_reseller_online";
    }
    if (
      walletBalance <= 0 &&
      !(
        isResellerOfflineSignupSource ||
        isResellerWhiteLabeledOfflineSignupSource
      )
    ) {
      return "user_with_insufficient_wallet_balance";
    }
    if (
      walletBalance <= lowWalletBalance &&
      !isSignupSourceTally &&
      !isResellerSignupSource
    ) {
      return "user_has_low_wallet_balance";
    }
  }

  if (
    isShowTrialExpireRibbon() &&
    !isSignupSourceTally &&
    !isResellerSignupSource
  ) {
    return "user_has_trial_expired";
  }

  if (
    getRemainingFreeXTrailDays() &&
    !isSignupSourceTally &&
    !isResellerSignupSource
  ) {
    if (!getIfFBBusinessVerificationStatusIsVerified()) {
      return "user_with_business_unverified_has_free_trial_with_less_than_30_percent_days";
    }

    return "user_with_business_verified_has_free_trial_with_less_than_30_percent_days";
  }

  if (
    showPaymentDueRibbon() &&
    !isSignupSourceTally &&
    !isResellerSignupSource
  ) {
    if (isAdsPlanSignupSource) return null;
    return "old_user_without_subcription";
  }

  if (
    showPendingInvoiceRibbonWithBlocker() &&
    !isSignupSourceTally &&
    !isResellerSignupSource
  ) {
    if (appSignupSource === signupSource.SHOPIFY) {
      return "user_has_paid_plan_with_blocker_shopify";
    }

    return "user_has_paid_plan_with_blocker";
  }

  if (
    showPendingInvoiceRibbonWithoutBlocker() &&
    !isSignupSourceTally &&
    !isResellerSignupSource
  ) {
    if (isOTCRestricted) {
      return "user_has_paid_plan_with_restrict_OTC_blocker";
    }

    if (appSignupSource === signupSource.SHOPIFY) {
      return "user_has_paid_plan_with_grace_period_shopify";
    }

    return "user_has_paid_plan_with_grace_period";
  }

  if (isShowSubscriptionExpiredRibbon()) {
    if (appSignupSource === signupSource.SHOPIFY) {
      return "user_has_expired_Subscription_plan_with_blocker_shopify";
    }

    if (isSignupSourceTally) {
      return "user_has_expired_Subscription_plan_with_blocker_tally";
    }

    if (isResellerSignupSource) {
      return "user_has_expired_Subscription_plan_with_blocker_reseller";
    }

    return "user_has_expired_Subscription_plan_with_blocker";
  }

  if (
    isShowBusinessVerificationRibbon() &&
    !isSignupSourceTally &&
    !isResellerSignupSource
  ) {
    if (!getIfFBBusinessVerificationStatusIsVerified()) {
      return "user_with_business_unverified";
    }

    return "user_with_business_verified";
  }

  if (
    moment().isAfter(moment(process.env.REACT_APP_DOWN_TIME_START)) &&
    moment().isBefore(moment(process.env.REACT_APP_DOWN_TIME_END))
  ) {
    return "maintenance";
  }

  if (isNotificationPermissionRequired()) {
    return "notification";
  }

  return null;
};

export default useActiveRibbonType;
