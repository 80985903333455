import { useMutation, useQueryClient } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";
import { RQ_FETCH_UNREAD_NUDGE } from "./useUnreadNudge";

const mutateNudge = async ({ orgId, data, method = "POST" }) => {
  try {
    const result = await callApiV2({
      endpoint: `/v1/organizations/${orgId}/nudge/`,
      method,
      body: data,
    });

    if (result?.result) {
      return result.id;
    }

    throw new Error(result.message);
  } catch (error) {
    throw new Error(error.message);
  }
};

const useMutateNudge = ({
  options = {},
  select = (data) => data,
  processSuccess = true,
} = {}) => {
  const orgId = useOrganizationId();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ data, method } = {}) => {
      return mutateNudge({ data, orgId, method });
    },
    onSuccess: () => {
      if (processSuccess) {
        queryClient.invalidateQueries({
          queryKey: [RQ_FETCH_UNREAD_NUDGE, orgId],
        });
      }
    },
    select,
    ...options,
  });
};

export default useMutateNudge;
