import React from "react";
import "../../css/inbox/inbox.css";

import Box from "konnekt/Box";

const PersonLogo = ({ className, size, style, textContainerProps, text }) => {
  return (
    <Box className={`img-person ${className}`}>
      <Box
        data-tip
        style={{
          height: `${size || "40"}px`,
          width: `${size || "40"}px`,
          backgroundColor: textContainerProps?.bg || "#92AAB9",
          ...style,
        }}
        className="personal-details-box-image"
        {...textContainerProps}
      >
        {text || ""}
      </Box>
    </Box>
  );
};

export default React.memo(PersonLogo);
