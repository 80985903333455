import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import useWalletBalance, {
  selectWalletId,
} from "useApi/Payment/useWalletBalance";
import { callApiV2 } from "util/apiCaller";

export const RQ_WALLET_TRANSACTIONS = "RQ_WALLET_TRANSACTIONS";

const fetchWalletTransactions = async ({
  orgId,
  walletId,
  limit = 10,
  offset = 0,
  startDatetime,
  endDatetime,
}) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/wallet/${walletId}/wallet_transaction?limit=${limit}&offset=${offset}&start_datetime=${startDatetime}&end_datetime=${endDatetime}`,
  });
  if (res?.result) {
    return res.data;
  }
  throw new Error(res.message);
};

const useWalletTransactions = (
  apiParams = {},
  select = (data) => data,
  options = {},
) => {
  const orgId = useOrganizationId();
  const { data: walletId } = useWalletBalance(selectWalletId);
  const LIMIT = 10;
  const { offset, startDatetime, endDatetime } = apiParams;

  return useQuery(
    [
      RQ_WALLET_TRANSACTIONS,
      orgId,
      walletId,
      LIMIT,
      offset,
      startDatetime,
      endDatetime,
    ],
    () =>
      fetchWalletTransactions({
        orgId,
        walletId,
        limit: LIMIT,
        offset,
        startDatetime,
        endDatetime,
      }),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined
          ? !!orgId && !!walletId
          : !!orgId && !!walletId && !!options.enabled,
    },
  );
};

export default useWalletTransactions;
