import React from "react";

const announcementSidebar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_171_12654)">
        <path
          d="M11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12Z"
          stroke="#32374E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.616 13.924C16.9328 13.1642 17.057 12.3379 16.9777 11.5185C16.8985 10.6991 16.6181 9.91192 16.1615 9.22691C15.7049 8.54191 15.0863 7.98023 14.3605 7.59174C13.6347 7.20326 12.8242 7 12.001 7C11.1778 7 10.3673 7.20326 9.64147 7.59174C8.91567 7.98023 8.29702 8.54191 7.84045 9.22691C7.38387 9.91192 7.1035 10.6991 7.02422 11.5185C6.94494 12.3379 7.0692 13.1642 7.38598 13.924"
          stroke="#32374E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.307 15.469C20.877 14.1013 21.1004 12.6141 20.9576 11.1393C20.8147 9.66447 20.31 8.24776 19.4881 7.01489C18.6662 5.78203 17.5527 4.77114 16.2463 4.07198C14.94 3.37281 13.4812 3.00699 11.9995 3.00699C10.5178 3.00699 9.05904 3.37281 7.75268 4.07198C6.44632 4.77114 5.33278 5.78203 4.51092 7.01489C3.68906 8.24776 3.1843 9.66447 3.04144 11.1393C2.89859 12.6141 3.12206 14.1013 3.69201 15.469"
          stroke="#32374E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 21L12 12L15 21"
          stroke="#32374E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 19H14"
          stroke="#32374E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_171_12654">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default announcementSidebar;
