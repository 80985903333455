import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon from "konnekt/Icon";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import { setNotificationRibbon } from "modules/Inbox/ThreadActions";
import {
  getPaymentEnabledStatus,
  getPaymentModalStatus,
  getWabaId,
} from "modules/App/AppSelectors";
import {
  getActiveSubscriptionList,
  getFetchSubscriptionStatus,
  getRemainingTrialDays,
} from "modules/Payments/PaymentSelectors";
import { setNotificationRibbonDetails } from "modules/App/AppActions";
import { getShowNotificationRibbon } from "modules/Inbox/ThreadReducer";
import useSocket from "customHooks/useSocket";
import useActiveRibbonType from "customHooks/useActiveRibbonType";
import StartSubscriptionPaymentPopup from "./Payments/StartSubscriptionPaymentPopup";
import ribbonCopyChanges from "../constants/ribbonCopyChanges";

const StyledLink = styled(Box)`
  color: white;
  margin-left: 5px;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
`;

const StyledRibbonBackdropWrapper = styled.div`
  opacity: 0.5;
  background-color: rgba(196, 196, 196, 0.55);
  height: 100%;
  width: 100%;
  z-index: 100;
  position: fixed;
`;

const NotificationRibbon = ({
  isAuthenticated,
  isFetchingOrganizationDetails,
}) => {
  const dispatch = useDispatch();

  const ref = React.useCallback((node) => {
    if (node !== null) {
      dispatch(
        setNotificationRibbonDetails({
          visibility: true,
          height: node.getBoundingClientRect().height,
        }),
      );
    } else {
      dispatch(setNotificationRibbonDetails({ visibility: false, height: 0 }));
    }
  }, []);
  const isWebView = localStorage.getItem("webview");

  const isPaymentEnabled = useSelector(getPaymentEnabledStatus);
  const showNotificationRibbon = useSelector(getShowNotificationRibbon);
  const isFetchingSubscription = useSelector(getFetchSubscriptionStatus);
  const trialDays = useSelector(getRemainingTrialDays);
  const isOpenSubscriptionStartPaymentModal = useSelector(
    getPaymentModalStatus,
  );

  const activeSubscriptionStartDate = useSelector(
    (state) => getActiveSubscriptionList(state)?.[0]?.subscription_start_date,
  );

  const wabaId = useSelector(getWabaId);

  const { retryConnection } = useSocket();

  const getCompiledTextForXDays = (text, x) => text?.replace("{{x}}", x);

  const activeRibbonType = useActiveRibbonType();

  const renderGetTime = () => {
    if (activeSubscriptionStartDate) {
      const billedDate = moment(new Date(activeSubscriptionStartDate)).add(
        1,
        "month",
      );
      const date = moment();
      const diffDate = billedDate.diff(date, "days");

      if (billedDate.get("date") !== date.get("date") && diffDate > 0) {
        return `${diffDate} days`;
      }
      if (billedDate.get("hour") > date.get("hour")) {
        return `${billedDate.get("hour") - date.get("hour")} hours`;
      }
      return `${diffDate} days`;
    }
    return null;
  };

  const handleCloseIconClick = () => {
    dispatch(setNotificationRibbon(false));
    dispatch(
      setNotificationRibbonDetails({
        visibility: false,
        height: 0,
      }),
    );
  };

  const askPermission = async () => {
    Notification.requestPermission().then(() => {
      dispatch(setNotificationRibbon(false));
    });
  };

  const handleActionBtnClick = () => {
    if (activeRibbonType === "notification") {
      askPermission();
    }

    if (activeRibbonType === "socket") {
      retryConnection();
    }
  };

  const getRibbonText = () => {
    if (
      activeRibbonType ===
        "user_with_business_unverified_has_free_trial_with_less_than_30_percent_days" ||
      activeRibbonType ===
        "user_with_business_verified_has_free_trial_with_less_than_30_percent_days"
    ) {
      return getCompiledTextForXDays(
        ribbonCopyChanges?.[activeRibbonType]?.ribbonText,
        trialDays,
      );
    }

    if (
      typeof ribbonCopyChanges?.[activeRibbonType]?.ribbonText === "function"
    ) {
      return ribbonCopyChanges?.[activeRibbonType]?.ribbonText();
    }

    return ribbonCopyChanges?.[activeRibbonType]?.ribbonText;
  };

  const renderCtaBtn = () => {
    if (ribbonCopyChanges?.[activeRibbonType]?.ctaText) {
      if (
        typeof ribbonCopyChanges?.[activeRibbonType]?.ctaLink === "function" &&
        ribbonCopyChanges?.[activeRibbonType]?.ctaLink({ wabaId })
      ) {
        return (
          <StyledLink
            to={ribbonCopyChanges?.[activeRibbonType]?.ctaLink({ wabaId })}
            as={Link}
          >
            {ribbonCopyChanges?.[activeRibbonType]?.ctaText}
          </StyledLink>
        );
      }

      if (ribbonCopyChanges?.[activeRibbonType]?.ctaLink) {
        return (
          <StyledLink
            to={ribbonCopyChanges?.[activeRibbonType]?.ctaLink}
            as={Link}
          >
            {ribbonCopyChanges?.[activeRibbonType]?.ctaText}
          </StyledLink>
        );
      }

      if (
        typeof ribbonCopyChanges?.[activeRibbonType]?.redirectLink ===
          "function" &&
        ribbonCopyChanges?.[activeRibbonType]?.redirectLink({ wabaId })
      ) {
        return (
          <StyledLink
            as="a"
            href={ribbonCopyChanges?.[activeRibbonType]?.redirectLink({
              wabaId,
            })}
            target="_blank"
          >
            {ribbonCopyChanges?.[activeRibbonType]?.ctaText}
          </StyledLink>
        );
      }

      if (ribbonCopyChanges?.[activeRibbonType]?.redirectLink) {
        return (
          <StyledLink
            as="a"
            href={ribbonCopyChanges?.[activeRibbonType]?.redirectLink}
            target="_blank"
          >
            {ribbonCopyChanges?.[activeRibbonType]?.ctaText}
          </StyledLink>
        );
      }

      return (
        <StyledLink as="span" onClick={handleActionBtnClick}>
          {ribbonCopyChanges?.[activeRibbonType]?.ctaText}
        </StyledLink>
      );
    }

    return null;
  };

  if (
    !isPaymentEnabled ||
    !isAuthenticated ||
    isFetchingSubscription ||
    isFetchingOrganizationDetails ||
    !showNotificationRibbon ||
    !activeRibbonType ||
    isWebView
  ) {
    return null;
  }

  return (
    <>
      <Box
        ref={ref}
        pl={{ _: "20px", desktop: "80px" }}
        pr="20px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg={ribbonCopyChanges?.[activeRibbonType]?.theme?.bg ?? "#f7617c"}
        py="6px"
      >
        <Box pr="20px" display="flex" mx="auto" alignItems="center">
          {!ribbonCopyChanges?.[activeRibbonType]?.noIcon && (
            <Box minWidth="18px">
              <Icon
                noWrapper
                color="white"
                mt="5px"
                width="18px"
                height="18px"
                iconName={
                  ribbonCopyChanges?.[activeRibbonType]?.iconName || "card"
                }
              />
            </Box>
          )}
          <Label color="white" ml="10px">
            {getRibbonText()}
            {ribbonCopyChanges?.[activeRibbonType]?.ctaText && renderCtaBtn()}
          </Label>
        </Box>

        {!ribbonCopyChanges?.[activeRibbonType]?.hideCloseIcon && (
          <Box onClick={handleCloseIconClick}>
            <Icon noWrapper color="white" width="15px" iconName="close" />
          </Box>
        )}
      </Box>
      {isOpenSubscriptionStartPaymentModal && (
        <StartSubscriptionPaymentPopup getTime={renderGetTime()} />
      )}
      {activeRibbonType === "socket" && <StyledRibbonBackdropWrapper />}
    </>
  );
};

export default NotificationRibbon;
