import moment from "moment";

import { DATE_TIME_FORMAT_SERVER } from "constants/dateTime";
import {
  FETCH_AGENT_PERFORMANCE_DETAILS,
  FETCH_ANALYTICS_CARDS_DETAILS,
  UPDATE_LOADING_AGENT_PERFORMANCE,
  UPDATE_LOADING_CARD_DETAILS,
  UPDATE_LOADING_DETAILS,
  UPDATE_DATE_FILTER_FROM_DATE,
  UPDATE_DATE_FILTER_TO_DATE,
  UPDATE_SELECTED_DATE_FILTER,
  UPDATE_SELECTED_EVENT,
  UPDATE_SELECTED_TAG,
  UPDATE_FB_CONNECTED_STATUS,
  TOGGLE_GENERATE_REPORT_MODAL_STATUS,
} from "./AnalyticsActions";

const initialState = {
  cardDetails: {
    totalConversation: 0,
    resonded: 0,
    closed: 0,
    partiallyClose: 0,
    waitTimeFirstAgentRes: null,
    waitTimeAgentRes: null,
    resolutionTime: null,
  },

  graphs: {
    avgResponseTime: [],
    firstResponseTime: [],
    resolutionTime: [],
  },
  automationStats: {
    oooMessages: 0,
    welcomeMessages: 0,
    delayedMessages: 0,
    workflowMessages: 0,
    faqIg: 0,
    faqWa: 0,
  },

  agentPerformance: [],
  loadings: {
    loadingCardDetails: true,
    loadingAgentPerformance: true,
  },
  datefilter: {
    selectedFilter: "Last 7 days",
    fromDate: moment()
      .startOf("day")
      .subtract(6, "days")
      .tz("UTC")
      .format(DATE_TIME_FORMAT_SERVER),
    toDate: moment()
      .startOf("day")
      .add(1, "days")
      .subtract(1, "seconds")
      .tz("UTC")
      .format(DATE_TIME_FORMAT_SERVER),
  },
  event: "",
  tag: "",
  FBConnectedStatus: false,
  showGenerateReportModal: false,
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANALYTICS_CARDS_DETAILS:
      return {
        ...state,
        cardDetails: {
          ...state.cardDetails,
          totalConversation: action.payload.cards.conversation_count,
          resonded: action.payload.cards.replied_conversation_count,
          closed: action.payload.cards.closed_conversation_count,
          partiallyClose:
            action.payload.cards.partially_closed_conversation_count,
          waitTimeFirstAgentRes:
            action.payload.cards.median_first_agent_response_time,
          waitTimeAgentRes: action.payload.cards.median_average_response_time,
          resolutionTime: action.payload.cards.median_resolution_time,
        },
        graphs: {
          ...state.graphs,
          avgResponseTime: action.payload.graphs.average_response_time,
          firstResponseTime: action.payload.graphs.first_response_time,
          resolutionTime: action.payload.graphs.resolution_time,
        },
        automationStats: {
          ...state.automationStats,
          oooMessages: action.payload.cards.automation_stats?.num_ooo_messages,
          welcomeMessages:
            action.payload.cards.automation_stats?.num_welcome_messages,
          delayedMessages:
            action.payload.cards.automation_stats?.num_delayed_messages,
          workflowMessages:
            action.payload.cards.automation_stats?.num_workflow_messages,
          faqIg: action.payload.cards.automation_stats?.num_faq_messages_ig,
          faqWa: action.payload.cards.automation_stats?.num_faq_messages_wa,
        },
        loadings: {
          ...state.loadings,
          loadingCardDetails: false,
        },
      };
    case FETCH_AGENT_PERFORMANCE_DETAILS: {
      return {
        ...state,
        agentPerformance: [...action.payload],
        loadings: {
          ...state.loadings,
          loadingAgentPerformance: false,
        },
      };
    }
    case UPDATE_LOADING_CARD_DETAILS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          loadingCardDetails: action.payload,
        },
      };
    case UPDATE_LOADING_AGENT_PERFORMANCE: {
      return {
        ...state,
        loadings: {
          ...state.loadings,
          loadingAgentPerformance: action.payload,
        },
      };
    }
    case UPDATE_LOADING_DETAILS: {
      return {
        ...state,
        loadings: {
          ...state.loadings,
          loadingAgentPerformance: action.payload,
          loadingCardDetails: action.payload,
        },
      };
    }

    case UPDATE_DATE_FILTER_FROM_DATE:
      return {
        ...state,
        datefilter: { ...state.datefilter, fromDate: action.payload },
      };

    case UPDATE_DATE_FILTER_TO_DATE:
      return {
        ...state,
        datefilter: { ...state.datefilter, toDate: action.payload },
      };

    case UPDATE_SELECTED_DATE_FILTER:
      return {
        ...state,
        datefilter: { ...state.datefilter, selectedFilter: action.payload },
      };

    case UPDATE_SELECTED_EVENT:
      return {
        ...state,
        event: action.payload,
      };

    case UPDATE_SELECTED_TAG:
      return {
        ...state,
        tag: action.payload,
      };

    case UPDATE_FB_CONNECTED_STATUS:
      return {
        ...state,
        FBConnectedStatus: action.payload,
      };

    case TOGGLE_GENERATE_REPORT_MODAL_STATUS:
      return {
        ...state,
        showGenerateReportModal: !state.showGenerateReportModal,
      };

    default:
      return {
        ...state,
      };
  }
};

export default analyticsReducer;

export const getFBConnectedStatus = (state) =>
  state.analytics.FBConnectedStatus;

export const getGenerateReportModalStatus = (state) =>
  state.analytics.showGenerateReportModal;
