import {
  getOrgCreatedAtUtc,
  getOrganizationOnboardedStatus,
} from "modules/App/AppSelectors";
import moment from "moment";
import { useSelector } from "react-redux";
import useIsSandboxMode from "./useIsSandboxMode";
import useIsSignupSource from "./useIsSignupSource";
import useIsSignupSourceTally from "./useIsSignupSourceTally";
import useIsAdsPlanSignupSource from "./useIsAdsPlanSignupSource";

const useShowOnboardingFeature = () => {
  const orgCreatedAtUtc = useSelector(getOrgCreatedAtUtc);
  const isShopifySignup = useIsSignupSource("Shopify");
  const isSignupSourceTally = useIsSignupSourceTally();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const isSandboxMode = useIsSandboxMode();
  const isOrgOnboarded = useSelector(getOrganizationOnboardedStatus);

  if (!orgCreatedAtUtc) {
    return false;
  }

  // * ShopifySandbox Onboarding
  if (isShopifySignup && isSandboxMode) {
    // ? for backward compatibility
    return moment(orgCreatedAtUtc).isAfter(
      moment(new Date("Dec 27 2022 11:30:00")),
    );
  }

  // * TraditionalSandbox Onboarding
  if (isSandboxMode && !isSignupSourceTally) {
    // ? for backward compatibility
    return moment(orgCreatedAtUtc).isAfter(
      moment(new Date("Jan 6 2023 10:00:00")),
    );
  }

  return isAdsPlanSignupSource && !isOrgOnboarded;
};

export default useShowOnboardingFeature;
