import async from "async";
import callApi, { callApiV2 } from "util/apiCaller";
import { getUrlParams, isJSON, showNotification } from "util/utility";
import {
  getSelectedOrganizationId,
  getUserOrganizationId,
} from "modules/App/AppSelectors";
import { getIsSMSChannel } from "modules/App/HaptikReducer";
import { changeOrganizationDetails } from "modules/App/AppActions";
import { Mixpanel } from "util/tracking";
import {
  INT_API_TEMPLATE_ARCHIVE,
  INT_API_TEMPLATE_V2,
} from "constants/mixPanel/api";
import { formatAPIdataForTemplateButtons } from "./pages/utility";
import { getCarouselApiCardsWithUpdatedHeader } from "./pages/carouselUtil";
import { APPROVED, TYPE_WHATSAPP_FLOW, AUTHENTICATION } from "./constants";
import { getAuthTemplateStateFromAPIResponse } from "./pages/CreateTemplatePage/components/auth-template/authUtility";
import { getChannelQueryParam } from "./utility";

const EDIT_ERROR_MESSAGES_TO_SUPPRESS = [
  "Invalid parameter Required buttons missing URL is required at index 1 when limited-time offer is present",
  "Invalid parameter Operation not allowed Removing limited-time offer during template edit operation is not permitted",
  "Invalid parameter Required buttons missing URL is required at index 0 when limited-time offer is present",
  "Invalid parameter Operation not allowed Adding limited-time offer during template edit operation is not permitted",
];
const EDIT_ERROR_MESSAGE =
  "Changing button type is not allowed by Meta for approved templates. You can still edit the content of the template.";

export const FETCH_TEMPLATE_LIST_STATUS = "FETCH_TEMPLATE_LIST_STATUS";
export const FETCH_TEMPLATE_LIST = "FETCH_TEMPLATE_LIST";
export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const CLEAR_ACTIVE_TEMPLATE = "CLEAR_ACTIVE_TEMPLATE";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_STATUS = "CREATE_TEMPLATE_STATUS";
export const FETCH_TEMPLATE_STATUS = "FETCH_TEMPLATE_STATUS";
export const UPDATING_TEMPLATE_DETAIL = "UPDATING_TEMPLATE_DETAIL";
export const UPDATE_TEMPLATE_DETAIL = "UPDATE_TEMPLATE_DETAIL";
export const UPDATE_TEMPLATE_LIST = "UPDATE_TEMPLATE_LIST";
export const UPDATE_ACTIVE_TEMPLATE_LIST = "UPDATE_ACTIVE_TEMPLATE_LIST";
export const UPDATE_DELETED_TEMPLATE_LIST = "UPDATE_DELETED_TEMPLATE_LIST";
export const FETCH_ACTIVE_TEMPLATE_LIST = "FETCH_ACTIVE_TEMPLATE_LIST";
export const FETCH_ACTIVE_TEMPLATE_LIST_V2 = "FETCH_ACTIVE_TEMPLATE_LIST_V2";
export const FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2 =
  "FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2";
export const FETCH_ACTIVE_TEMPLATE_LIST_STATUS =
  "FETCH_ACTIVE_TEMPLATE_LIST_STATUS";
export const FETCH_DELETED_TEMPLATE_LIST = "FETCH_DELETED_TEMPLATE_LIST";
export const FETCH_DELETED_TEMPLATE_LIST_STATUS =
  "FETCH_DELETED_TEMPLATE_LIST_STATUS";
export const UNARCHIVING_TEMPLATE_DEATILS = "UNARCHIVING_TEMPLATE_DEATILS";
export const UNARCHIVE_TEMPLATE_DEATILS = "UNARCHIVE_TEMPLATE_DEATILS";
export const ARCHIVING_TEMPLATE_DEATILS = "ARCHIVING_TEMPLATE_DEATILS";
export const ARCHIVE_TEMPLATE_DEATILS = "ARCHIVE_TEMPLATE_DEATILS";
export const SYNC_TEMPLATE = "SYNC_TEMPLATE";
export const TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION =
  "TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION";
export const TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL =
  "TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL";
export const UPDATE_ORG_TEMPLATES = "UPDATE_ORG_TEMPLATES";
export const EXPAND_COLLAPSE_TEMPLATE_CARD = "EXPAND_COLLAPSE_TEMPLATE_CARD";
export const SAVE_SAMPLE_TEMPLATE = "SAVE_SAMPLE_TEMPLATE";
export const ADD_FLOW_DETAIL_TO_TEMPLATE_FLOW =
  "ADD_FLOW_DETAIL_TO_TEMPLATE_FLOW";
export const REMOVE_TEMPLATE_FLOW = "REMOVE_TEMPLATE_FLOW";
export const UPDATE_TEMPLATE_BUTTONS_ORDER = "UPDATE_TEMPLATE_BUTTONS_ORDER";
export const UPDATE_COUPON_CODE = "UPDATE_COUPON_CODE";
export const UPDATE_TEMPLATE_QUICK_REPLIES = "UPDATE_TEMPLATE_QUICK_REPLIES";
export const UPDATE_TEMPLATE_PHONE_NUMBER = "UPDATE_TEMPLATE_PHONE_NUMBER";
export const UPDATE_TEMPLATE_WEBSITE_URL = "UPDATE_TEMPLATE_WEBSITE_URL";
export const LOAD_INITIAL_TEMPLATE_BUTTONS = "LOAD_INITIAL_TEMPLATE_BUTTONS";
export const ADD_NEW_TEMPLATE_BUTTONS_SECTION =
  "ADD_NEW_TEMPLATE_BUTTONS_SECTION";
export const ADD_NEW_TEMPLATE_LTO_SECTION = "ADD_NEW_TEMPLATE_LTO_SECTION";
export const RESET_TEMPLATE_BUTTONS = "RESET_TEMPLATE_BUTTONS";
export const RESET_TEMPLATE_LTO = "RESET_TEMPLATE_LTO";
export const RESET_TEMPLATE_BUTTONS_BY_TEMPLATE_INDEX =
  "RESET_TEMPLATE_BUTTONS_BY_TEMPLATE_INDEX,";
export const RESET_TEMPLATE_LTO_BY_TEMPLATE_INDEX =
  "RESET_TEMPLATE_LTO_BY_TEMPLATE_INDEX,";
export const UPDATE_BUTTON_SECTION_TYPE = "UPDATE_BUTTON_SECTION_TYPE";
export const UPDATE_LTO_TEXT = "UPDATE_LTO_TEXT";
export const UPDATE_SEND_PRODUCT_BUTTON_TYPE =
  "UPDATE_SEND_PRODUCT_BUTTON_TYPE";
export const TEMPLATE_CAROUSEL_INIT_ITEM = "TEMPLATE_CAROUSEL_INIT_ITEM";
export const TEMPLATE_CAROUSEL_ADD_NEW_ITEM = "TEMPLATE_CAROUSEL_ADD_NEW_ITEM";
export const TEMPLATE_CAROUSEL_DELETE_ITEM = "TEMPLATE_CAROUSEL_DELETE_ITEM";
export const TEMPLATE_CAROUSEL_UPDATE_ITEM = "TEMPLATE_CAROUSEL_UPDATE_ITEM";
export const TEMPLATE_CAROUSEL_DELETE_BUTTON =
  "TEMPLATE_CAROUSEL_DELETE_BUTTON";
export const TEMPLATE_CAROUSEL_UPDATE_BUTTON =
  "TEMPLATE_CAROUSEL_UPDATE_BUTTON";
export const UPDATE_TEMPLATE_CAROUSEL_FOR_VIEW =
  "UPDATE_TEMPLATE_CAROUSEL_FOR_VIEW";
export const RESET_TEMPLATE_CAROUSEL = "RESET_TEMPLATE_CAROUSEL";
export const INIT_TEMPLATE_CAROUSEL_FALLBACK =
  "INIT_TEMPLATE_CAROUSEL_FALLBACK";
export const UPDATE_CAROUSEL_FALLBACK_BODY = "UPDATE_CAROUSEL_FALLBACK_BODY";
export const UPDATE_CAROUSEL_FALLBACK_BUTTONS =
  "UPDATE_CAROUSEL_FALLBACK_BUTTONS";
export const ADD_CAROUSEL_CARDS_FOR_TEMPLATE_SELECTION =
  "ADD_CAROUSEL_CARDS_FOR_TEMPLATE_SELECTION";
export const ADD_NEW_TEMPLATE_CAROUSEL_SECTION =
  "ADD_NEW_TEMPLATE_CAROUSEL_SECTION";
export const UPDATE_CAROUSEL_FALLBACK_HEADER =
  "UPDATE_CAROUSEL_FALLBACK_HEADER";
export const LOADING_CAROUSEL_CARDS_FOR_TEMPLATE =
  "LOADING_CAROUSEL_CARDS_FOR_TEMPLATE";

export const SET_FLOW_BUTTON_TEXT = "SET_FLOW_BUTTON_TEXT";
export const SET_FLOW_ID = "SET_FLOW_ID";
export const SET_SCREEN_ID = "SET_SCREEN_ID";
export const SET_NAVIGATE_CHECKED = "SET_NAVIGATE_CHECKED";
export const SET_FLOW_TOKEN = "SET_FLOW_TOKEN";
export const SET_FLOW_DATA = "SET_FLOW_DATA";

export const ADD_NEW_AUTH_TEMPLATE_SECTION = "ADD_NEW_AUTH_TEMPLATE_SECTION";
export const UPDATE_CODE_DELIVERY_TYPE = "UPDATE_CODE_DELIVERY_TYPE";
export const UPDATE_ZERO_TAP_CHECKBOX = "UPDATE_ZERO_TAP_CHECKBOX";
export const ADD_NEW_PACKAGE_NAME_IN_AUTH_TEMPLATE =
  "ADD_NEW_PACKAGE_NAME_IN_AUTH_TEMPLATE";
export const UPDATE_PACKAGE_LIST_IN_AUTH_TEMPLATE =
  "UPDATE_PACKAGE_LIST_IN_AUTH_TEMPLATE";
export const UPDATE_SECURITY_RECOMMENDATION_IN_AUTH_TEMPLATE =
  "UPDATE_SECURITY_RECOMMENDATION_IN_AUTH_TEMPLATE";
export const UPDATE_EXPIRY_TIME_STATUS_IN_AUTH_TEMPLATE =
  "UPDATE_EXPIRY_TIME_STATUS_IN_AUTH_TEMPLATE";
export const UPDATE_EXPIRY_TIME_IN_AUTH_TEMPLATE =
  "UPDATE_EXPIRY_TIME_IN_AUTH_TEMPLATE";
export const UPDATE_AUTOFILL_TEXT_IN_AUTH_TEMPLATE =
  "UPDATE_AUTOFILL_TEXT_IN_AUTH_TEMPLATE";
export const UPDATE_COPY_CODE_TEXT_IN_AUTH_TEMPLATE =
  "UPDATE_COPY_CODE_TEXT_IN_AUTH_TEMPLATE";
export const UPDATE_VALIDITY_STATUS_IN_AUTH_TEMPLATE =
  "UPDATE_VALIDITY_STATUS_IN_AUTH_TEMPLATE";
export const UPDATE_VALIDITY_PERIOD_IN_AUTH_TEMPLATE =
  "UPDATE_VALIDITY_PERIOD_IN_AUTH_TEMPLATE";
export const TOGGLE_CHANNEL_SELECTION_MODAL = "TOGGLE_CHANNEL_SELECTION_MODAL";
export const UPDATE_AUTH_TEMPLATE_FOR_VIEW = "UPDATE_AUTH_TEMPLATE_FOR_VIEW";
export const RESET_AUTH_TEMPLATE_STATE = "RESET_AUTH_TEMPLATE_STATE";

export const addFlowDetailToTemplateFlow = (
  flowName,
  areTemplatesCreated,
  templatesThatNeedApproval,
) => ({
  type: ADD_FLOW_DETAIL_TO_TEMPLATE_FLOW,
  payload: {
    flowName,
    areTemplatesCreated,
    templatesThatNeedApproval,
  },
});

export const removeTemplateFlow = (flowName) => ({
  type: REMOVE_TEMPLATE_FLOW,
  payload: flowName,
});

export function toggleAddSampleModal(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ADD_TEMPLATE_SAMPLE_MODAL,
      isTemplateSampleModalVisible: val,
      isTemplateSampleModalConfirmationVisible: false,
    });
  };
}

export function toggleAddSampleConfirmation(val) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TEMPLATE_SAMPLE_CONFIRMATION,
      isTemplateSampleModalConfirmationVisible: val,
    });
  };
}

export function updateTemplateList(originalTemplateList) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TEMPLATE_LIST,
      templateList: originalTemplateList,
    });
  };
}

export function updateActiveTemplateList(activeOriginalTemplateList) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_TEMPLATE_LIST,
      activeTemplateListV2: activeOriginalTemplateList,
    });
  };
}
export function updateDeletedTemplateList(deletedOriginalTemplateList) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DELETED_TEMPLATE_LIST,
      deletedTemplateList: deletedOriginalTemplateList,
    });
  };
}
export function getAllActiveTemplatesV2() {
  const channelParam = getUrlParams("channel_type");

  return (dispatch, getState) => {
    const orgId = getUserOrganizationId(getState());
    const isHaptikSMSChannel = getIsSMSChannel(getState());
    dispatch({
      type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
      isFetchingActiveTemplateListV2: true,
    });

    Mixpanel.track(INT_API_TEMPLATE_V2, {});

    const channelQueryParams = getChannelQueryParam({
      isHaptikSMSChannel,
      channelParam,
    });

    return callApi(
      `v1/organizations/${orgId}/message-templates/v2/${
        channelQueryParams && `?${channelQueryParams}`
      }`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_ACTIVE_TEMPLATE_LIST_V2,
          activeTemplateListV2: res.data,
          activeTotalCount: res.data.length,
          isFetchingActiveTemplateListV2: false,
          isFetchingInitialActiveTemplateList: false,
        });
      } else {
        dispatch({
          type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
          isFetchingActiveTemplateListV2: false,
          isFetchingInitialActiveTemplateList: false,
        });
      }
    });
  };
}

export function getAllDeletedTemplates(
  organizationId,
  pageNum,
  pageSize,
  isFetchingInitialDeletedTemplateList = true,
) {
  const channelParam = getUrlParams("channel_type");

  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
      isFetchingTemplateList: true,
      isFetchingDeletedTemplateList: true,
      isFetchingInitialDeletedTemplateList: !!isFetchingInitialDeletedTemplateList,
    });
    const isHaptikSMSChannel = getIsSMSChannel(getState());
    const channelQueryParams = getChannelQueryParam({
      isHaptikSMSChannel,
      channelParam,
    });

    Mixpanel.track(INT_API_TEMPLATE_ARCHIVE, {});

    return callApi(
      `v1/organizations/${organizationId}/message-templates/archived/?offset=${
        (pageNum - 1) * pageSize
      }&limit=${pageSize}${channelQueryParams && `&${channelQueryParams}`}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_DELETED_TEMPLATE_LIST,
          deletedTemplateList: res.data,
          pageNum,
          pageSize,
          deletedTotalCount: res.data.length,
          isFetchingDeletedTemplateList: false,
          isFetchingInitialDeletedTemplateList: false,
        });
      } else {
        dispatch({
          type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
          isFetchingDeletedTemplateList: false,
          isFetchingInitialDeletedTemplateList: false,
        });
      }
    });
  };
}

export const loadInitialTemplateButtons = (payload) => ({
  type: LOAD_INITIAL_TEMPLATE_BUTTONS,
  payload,
});

export function getTemplateById(organizationId, templateId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_TEMPLATE_STATUS,
      isFetchingTemplate: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/`,
      "get",
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_TEMPLATE,
          activeTemplate: res.data,
          isFetchingTemplate: false,
        });
      } else {
        dispatch({
          type: FETCH_TEMPLATE_STATUS,
          isFetchingTemplate: false,
        });
      }
    });
  };
}

export const updateTemplateCarouselForView = ({ data, templateIndex }) => ({
  type: UPDATE_TEMPLATE_CAROUSEL_FOR_VIEW,
  payload: { data, templateIndex },
});

export const addTemplateCarouselCardsForTemplateSelection = (data) => ({
  type: ADD_CAROUSEL_CARDS_FOR_TEMPLATE_SELECTION,
  payload: data,
});

export const loadingCarouselCardsForTemplate = (payload) => ({
  type: LOADING_CAROUSEL_CARDS_FOR_TEMPLATE,
  payload,
});

export const updateAuthTemplateFromAPIForView = (payload) => ({
  type: UPDATE_AUTH_TEMPLATE_FOR_VIEW,
  payload,
});

export const resetAuthTemplateState = () => ({
  type: RESET_AUTH_TEMPLATE_STATE,
});

export const setFlowButtonText = (text) => ({
  type: SET_FLOW_BUTTON_TEXT,
  payload: text,
});

export const setFlowId = (id) => ({
  type: SET_FLOW_ID,
  payload: id,
});

export const setScreenId = (id) => ({
  type: SET_SCREEN_ID,
  payload: id,
});

export const setNavigateChecked = (checked) => ({
  type: SET_NAVIGATE_CHECKED,
  payload: checked,
});

export function getTemplateByTemplateName(organizationId, templateName) {
  const channelParam = getUrlParams("channel_type");
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_TEMPLATE_STATUS,
      isFetchingTemplate: true,
    });
    const isHaptikSMSChannel = getIsSMSChannel(getState());
    Mixpanel.track(INT_API_TEMPLATE_V2, {});
    const channelQueryParams = getChannelQueryParam({
      isHaptikSMSChannel,
      channelParam,
    });

    return callApi(
      `v1/organizations/${organizationId}/message-templates/v2/?templateName=${templateName}${
        channelQueryParams && `&${channelQueryParams}`
      }`,
      "get",
    ).then((res) => {
      if (res.result && Array.isArray(res.data) && res.data.length) {
        const items = res.data;
        const obj = {};
        obj.id = items[0] && items[0].id ? items[0].id : "";
        obj.name = items[0] && items[0].name ? items[0].name : "";
        obj.display_name =
          items[0] && items[0].display_name ? items[0].display_name : "";
        obj.template_category_label =
          items[0] && items[0].template_category_label
            ? items[0].template_category_label
            : "";
        obj.sub_category =
          items[0] && items[0].sub_category ? items[0].sub_category : "";
        obj.category = items[0] && items[0].category ? items[0].category : "";
        obj.templates = [];

        const templateButtons = [];
        const ltoData = [];
        const carouselCards = [];

        items.forEach((item, index) => {
          const template = {};
          template.id = item.id;
          template.header = item.header;
          template.header_format = item.header_format;
          template.header_text = item.header_text;
          template.header_handle = item.header_handle;
          template.header_handle_file_url = item.header_handle_file_url;
          template.header_handle_file_name = item.header_handle_file_name;
          template.body = item.body;
          template.body_text = item.body_text;
          template.button_text = item.button_text;
          template.footer = item.footer;
          template.language = item.language;
          template.approval_status = item.approval_status;
          template.created_at_utc = item.created_at_utc;
          template.modified_at_utc = item.modified_at_utc;
          templateButtons.push(
            JSON.parse(isJSON(item.buttons) ? item.buttons : "[]"),
          );
          carouselCards.push(item.carousel_cards || []);
          template.buttons = item.buttons;
          template.wa_template_id = item.wa_template_id;
          template.created_by_user_id = item.created_by_user_id;
          template.organization_id = item.organization_id;
          template.limited_time_offer = item.limited_time_offer;
          ltoData.push(item.limited_time_offer ? item.limited_time_offer : {});
          template.is_click_tracking_enabled = item.is_click_tracking_enabled;
          obj.templates.push(template);

          if (templateButtons?.[0]?.[0]?.type === TYPE_WHATSAPP_FLOW) {
            dispatch(setFlowButtonText(templateButtons[0][0].text));
            dispatch(setFlowId(templateButtons[0][0].flow_id));
            if (
              templateButtons?.[0]?.[0]?.flow_action.toLowerCase() ===
              "navigate"
            ) {
              dispatch(setNavigateChecked(true));
              dispatch(setScreenId(templateButtons?.[0]?.[0]?.navigate_screen));
            } else {
              dispatch(setNavigateChecked(false));
            }
          }
          if (item.category === AUTHENTICATION) {
            dispatch(resetAuthTemplateState());

            dispatch(
              updateAuthTemplateFromAPIForView(
                getAuthTemplateStateFromAPIResponse(item),
              ),
            );
          } else {
            dispatch(resetAuthTemplateState());
          }

          if (
            Array.isArray(item?.carousel_cards) &&
            item.carousel_cards.length > 0
          ) {
            if (item.approval_status === APPROVED) {
              dispatch(loadingCarouselCardsForTemplate(true));

              getCarouselApiCardsWithUpdatedHeader(
                item.carousel_cards,
                organizationId,
              ).then((updatedCards) => {
                dispatch(
                  updateTemplateCarouselForView({
                    data: updatedCards,
                    templateIndex: index,
                  }),
                );

                dispatch(loadingCarouselCardsForTemplate(false));
              });
            } else {
              dispatch(
                updateTemplateCarouselForView({
                  data: item.carousel_cards,
                  templateIndex: index,
                }),
              );
            }
          }
        });

        dispatch(
          loadInitialTemplateButtons(
            formatAPIdataForTemplateButtons(
              templateButtons,
              ltoData,
              carouselCards,
            ),
          ),
        );
        dispatch({
          type: FETCH_TEMPLATE,
          activeTemplate: obj,
          isFetchingTemplate: false,
        });
      } else {
        dispatch({
          type: FETCH_TEMPLATE_STATUS,
          isFetchingTemplate: false,
        });
      }
    });
  };
}

export function clearActiveTemplate() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ACTIVE_TEMPLATE,
    });
  };
}

export function createNewTemplate(
  organizationId,
  channelId,
  template,
  history,
  path,
) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TEMPLATE_STATUS,
      isCreatingTemplate: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/channels/${channelId}/`,
      "post",
      template,
    ).then((res) => {
      if (res && res.id && res.result) {
        template.id = res.id;
        dispatch({
          type: CREATE_TEMPLATE,
          isCreatingTemplate: false,
          template,
        });
        history.push(path);
      } else {
        dispatch({
          type: CREATE_TEMPLATE_STATUS,
          isCreatingTemplate: false,
        });
      }
    });
  };
}

export function updateTemplateDetails(
  organizationId,
  templateList,
  handleGoBack,
) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_TEMPLATE_DETAIL,
      isUpdatingTemplateDetail: true,
    });
    const errors = [];
    return async.eachSeries(
      templateList,
      (template, callback) => {
        if (organizationId) {
          callApi(
            `v1/organizations/${organizationId}/message-templates/${template.id}/`,
            "put",
            template,
            true,
          ).then((res) => {
            if (res && res.result) {
              return callback();
            }
            if (EDIT_ERROR_MESSAGES_TO_SUPPRESS.includes(res.message)) {
              errors.push(EDIT_ERROR_MESSAGE);
            } else {
              errors.push(res.message);
            }
            return callback();
          });
        } else {
          return callback();
        }
      },
      () => {
        if (errors && errors.length > 0) {
          errors.forEach((error) => showNotification("error", error));
          dispatch({
            type: UPDATING_TEMPLATE_DETAIL,
            isUpdatingTemplateDetail: false,
          });
        } else {
          dispatch(getAllActiveTemplatesV2());
          dispatch(getAllDeletedTemplates(organizationId, 1, 2000, true));
          showNotification("success", "Template re-submitted for approval.");
          dispatch({
            type: UPDATE_TEMPLATE_DETAIL,
            isUpdatingTemplateDetail: false,
          });
        }
        handleGoBack();
      },
    );
  };
}

export function archiveTemplateDetails(
  organizationId,
  templateId,
  action = "Archive",
) {
  const channelParam = getUrlParams("channel_type");

  return (dispatch, getState) => {
    dispatch({
      type: ARCHIVING_TEMPLATE_DEATILS,
      isArchivingTemplateDetail: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/?action=${action}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
          isFetchingActiveTemplateListV2: true,
        });
        const isHaptikSMSChannel = getIsSMSChannel(getState());
        const channelQueryParams = getChannelQueryParam({
          isHaptikSMSChannel,
          channelParam,
        });
        Mixpanel.track(INT_API_TEMPLATE_V2, {});

        return callApi(
          `v1/organizations/${organizationId}/message-templates/v2/${
            channelQueryParams && `?${channelQueryParams}`
          }`,
          "get",
        ).then((res2) => {
          if (res2 && res2.result) {
            dispatch({
              type: FETCH_ACTIVE_TEMPLATE_LIST_V2,
              activeTemplateListV2: res2.data,
              activeTotalCount: res2.data.length,
              isFetchingActiveTemplateListV2: false,
              isFetchingInitialActiveTemplateList: false,
            });
            dispatch(getAllDeletedTemplates(organizationId, 1, 2000, true));
            showNotification("success", "Deleted Successfully");
            dispatch({
              type: ARCHIVING_TEMPLATE_DEATILS,
              isArchivingTemplateDetail: false,
            });
          } else {
            dispatch({
              type: FETCH_ACTIVE_TEMPLATE_LIST_STATUS_V2,
              isFetchingActiveTemplateListV2: false,
              isFetchingInitialActiveTemplateList: false,
            });
          }
        });
      }
      dispatch({
        type: ARCHIVING_TEMPLATE_DEATILS,
        isArchivingTemplateDetail: false,
      });
    });
  };
}

export function unarchiveTemplateDetails(
  organizationId,
  templateId,
  action = "Unarchive",
  pageNum = 1,
  pageSize = 2000,
  isFetchingInitialDeletedTemplateList = true,
) {
  const channelParam = getUrlParams("channel_type");
  return (dispatch, getState) => {
    dispatch({
      type: UNARCHIVING_TEMPLATE_DEATILS,
      isUnarchivingTemplateDetail: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/message-templates/${templateId}/?action=${action}`,
      "get",
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
          isFetchingTemplateList: true,
          isFetchingDeletedTemplateList: true,
          isFetchingInitialDeletedTemplateList: !!isFetchingInitialDeletedTemplateList,
        });
        const isHaptikSMSChannel = getIsSMSChannel(getState());
        const channelQueryParams = getChannelQueryParam({
          isHaptikSMSChannel,
          channelParam,
        });
        Mixpanel.track(INT_API_TEMPLATE_ARCHIVE, {});

        return callApi(
          `v1/organizations/${organizationId}/message-templates/archived/?offset=${
            (pageNum - 1) * pageSize
          }&limit=${pageSize}${channelQueryParams && `&${channelQueryParams}`}`,
          "get",
        ).then((res2) => {
          if (res2 && res2.result) {
            dispatch({
              type: FETCH_DELETED_TEMPLATE_LIST,
              deletedTemplateList: res2.data,
              pageNum,
              pageSize,
              deletedTotalCount: res2.data.length,
              isFetchingDeletedTemplateList: false,
              isFetchingInitialDeletedTemplateList: false,
            });
            dispatch(getAllActiveTemplatesV2());
            showNotification("success", "Restored Successfully");
            dispatch({
              type: UNARCHIVE_TEMPLATE_DEATILS,
              isUnarchivingTemplateDetail: false,
              isUnarchiveTemplateDetail: true,
            });
          } else {
            dispatch({
              type: FETCH_DELETED_TEMPLATE_LIST_STATUS,
              isFetchingDeletedTemplateList: false,
              isFetchingInitialDeletedTemplateList: false,
            });
          }
        });
      }
      dispatch({
        type: UNARCHIVING_TEMPLATE_DEATILS,
        isUnarchivingTemplateDetail: false,
      });
    });
  };
}

export const updateSyncStatus = (isSyncing) => ({
  type: SYNC_TEMPLATE,
  isSyncing,
});

export function getTemplateSyncStatus(organizationId, organizations) {
  return (dispatch) => {
    dispatch(updateSyncStatus(true));
    return callApi(
      `v1/organizations/${organizationId}/message-templates/sync`,
      "get",
    )
      .then((res) => {
        if (res.result) {
          if (res.refresh_required) {
            if (
              res &&
              res.last_template_sync_at_utc !== "" &&
              organizations &&
              organizations.length > 0
            ) {
              const org = organizations;
              org[0].organization_id.templates_last_synced_at_utc =
                res.last_template_sync_at_utc;
              dispatch(changeOrganizationDetails(org));
            }
            showNotification("success", "Template Sync started");
            dispatch(getAllActiveTemplatesV2());
            dispatch(getAllDeletedTemplates(organizationId, 1, 2000, true));
          } else {
            if (
              res &&
              res.last_template_sync_at_utc !== "" &&
              organizations &&
              organizations.length > 0
            ) {
              const org = organizations;
              org[0].organization_id.templates_last_synced_at_utc =
                res.last_template_sync_at_utc;
              dispatch(changeOrganizationDetails(org));
            }
            showNotification("success", "Template sync started");
          }
        }
      })
      .catch(() => {
        dispatch(updateSyncStatus(false));
      });
  };
}

export const updateTemplateMessage = (event) => ({
  type: UPDATE_ORG_TEMPLATES,
  event,
});

export const expandCollapseTemplateCard = (templateId) => ({
  type: EXPAND_COLLAPSE_TEMPLATE_CARD,
  templateId,
});

export const fetchAndSaveSampleTemplate = () => (dispatch, getState) => {
  const orgId = getSelectedOrganizationId(getState());
  if (!orgId) {
    return;
  }

  callApiV2({
    endpoint: `v1/organizations/${orgId}/message-templates/get/sample_template/`,
  })
    .then((response) => {
      if (response?.result) {
        const otherCatData = [];
        const filteredArray = response?.data?.filter((template) => {
          if (template?.template_category_label === "OTHERS") {
            otherCatData.push({
              ...template,
            });
          }
          return template?.template_category_label !== "OTHERS";
        });
        dispatch({
          type: SAVE_SAMPLE_TEMPLATE,
          payload: [...filteredArray, ...otherCatData],
        });
      } else {
        dispatch({
          type: SAVE_SAMPLE_TEMPLATE,
          payload: [],
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SAVE_SAMPLE_TEMPLATE,
        payload: [],
      });
    });
};

export const updateTemplateButtonsOrder = (data, index) => ({
  type: UPDATE_TEMPLATE_BUTTONS_ORDER,
  payload: { data, index },
});

export const updateCouponCode = (data, index) => ({
  type: UPDATE_COUPON_CODE,
  payload: { data, index },
});

export const updateTemplateQuickReplies = (data, index) => ({
  type: UPDATE_TEMPLATE_QUICK_REPLIES,
  payload: { data, index },
});

export const updateTemplatePhoneNumber = (data, index) => ({
  type: UPDATE_TEMPLATE_PHONE_NUMBER,
  payload: { data, index },
});

export const updateTemplateWebsiteUrl = (data, index) => ({
  type: UPDATE_TEMPLATE_WEBSITE_URL,
  payload: { data, index },
});

export const addNewTemplateButtonsSection = () => ({
  type: ADD_NEW_TEMPLATE_BUTTONS_SECTION,
});

export const addNewTemplateLTOSection = () => ({
  type: ADD_NEW_TEMPLATE_LTO_SECTION,
});

export const resetTemplateButtons = () => ({
  type: RESET_TEMPLATE_BUTTONS,
});

export const resetTemplateLTO = () => ({
  type: RESET_TEMPLATE_LTO,
});

export const resetTemplateButtonsById = (index) => ({
  type: RESET_TEMPLATE_BUTTONS_BY_TEMPLATE_INDEX,
  payload: index,
});

export const resetTemplateLTOById = (index) => ({
  type: RESET_TEMPLATE_LTO_BY_TEMPLATE_INDEX,
  payload: index,
});

export const updateButtonSectionType = (data) => ({
  type: UPDATE_BUTTON_SECTION_TYPE,
  payload: data,
});

export const updateLTOText = (data, index) => ({
  type: UPDATE_LTO_TEXT,
  payload: { data, index },
});

export const updateSendProductButtonType = (data) => ({
  type: UPDATE_SEND_PRODUCT_BUTTON_TYPE,
  payload: data,
});

export const templateCarouselInitItem = () => ({
  type: TEMPLATE_CAROUSEL_INIT_ITEM,
});

export const templateCarouselAddNewItem = (activeIndex) => ({
  type: TEMPLATE_CAROUSEL_ADD_NEW_ITEM,
  payload: activeIndex,
});

export const templateCarouselDeleteItem = (
  activeTemplateIndex,
  activeCarouselIndex,
) => ({
  type: TEMPLATE_CAROUSEL_DELETE_ITEM,
  payload: { activeTemplateIndex, activeCarouselIndex },
});

export const templateCarouselUpdateItem = ({
  activeTemplateIndex,
  activeCarouselIndex,
  data,
}) => ({
  type: TEMPLATE_CAROUSEL_UPDATE_ITEM,
  payload: { activeTemplateIndex, activeCarouselIndex, data },
});

export const templateCarouselDeleteButtons = ({
  activeTemplateIndex,
  activeCarouselIndex,
  buttonId,
}) => ({
  type: TEMPLATE_CAROUSEL_DELETE_BUTTON,
  payload: {
    activeTemplateIndex,
    activeCarouselIndex,
    buttonId,
  },
});

export const templateCarouselUpdateButtons = ({
  activeTemplateIndex,
  activeCarouselIndex,
  buttonId,
  updatedButtonData,
}) => ({
  type: TEMPLATE_CAROUSEL_UPDATE_BUTTON,
  payload: {
    activeTemplateIndex,
    activeCarouselIndex,
    buttonId,
    updatedButtonData,
  },
});

export const resetTemplateCarousel = () => ({
  type: RESET_TEMPLATE_CAROUSEL,
});

export const initTemplateCarouselFallback = (payload) => ({
  type: INIT_TEMPLATE_CAROUSEL_FALLBACK,
  payload,
});

export const updateCarouselFallBackBody = ({ data, cardIndex }) => ({
  type: UPDATE_CAROUSEL_FALLBACK_BODY,
  payload: { data, cardIndex },
});

export const updateCarouselFallBackButtons = ({
  data,
  cardIndex,
  buttonIndex,
}) => ({
  type: UPDATE_CAROUSEL_FALLBACK_BUTTONS,
  payload: { data, cardIndex, buttonIndex },
});

export const updateCarouselFallbackHeader = ({ data, cardIndex }) => ({
  type: UPDATE_CAROUSEL_FALLBACK_HEADER,
  payload: { data, cardIndex },
});

export const addNewTemplateCarouselSection = () => ({
  type: ADD_NEW_TEMPLATE_CAROUSEL_SECTION,
});

export const setFlowTokenValue = (text) => ({
  type: SET_FLOW_TOKEN,
  payload: text,
});

export const setFlowDataValue = (text) => ({
  type: SET_FLOW_DATA,
  payload: text,
});

export const addNewAuthTemplateSection = () => ({
  type: ADD_NEW_AUTH_TEMPLATE_SECTION,
});

export const updateCodeDeliveryType = (data) => ({
  type: UPDATE_CODE_DELIVERY_TYPE,
  payload: data,
});

export const updateZeroTapCheckbox = (data) => ({
  type: UPDATE_ZERO_TAP_CHECKBOX,
  payload: data,
});

export const addNewPackageNameInAuthTemplate = (payload) => ({
  type: ADD_NEW_PACKAGE_NAME_IN_AUTH_TEMPLATE,
  payload,
});

export const updatePackageListInAuthTemplate = (payload) => ({
  type: UPDATE_PACKAGE_LIST_IN_AUTH_TEMPLATE,
  payload,
});

export const updateSecurityRecommendationInAuthTemplate = (payload) => ({
  type: UPDATE_SECURITY_RECOMMENDATION_IN_AUTH_TEMPLATE,
  payload,
});

export const updateExpiryTimeStatusInAuthTemplate = (payload) => ({
  type: UPDATE_EXPIRY_TIME_STATUS_IN_AUTH_TEMPLATE,
  payload,
});

export const updateExpiryTimeInAuthTemplate = (payload) => ({
  type: UPDATE_EXPIRY_TIME_IN_AUTH_TEMPLATE,
  payload,
});

export const updateAutofillTextInAuthTemplate = (payload) => ({
  type: UPDATE_AUTOFILL_TEXT_IN_AUTH_TEMPLATE,
  payload,
});

export const updateCopyCodeTextInAuthTemplate = (payload) => ({
  type: UPDATE_COPY_CODE_TEXT_IN_AUTH_TEMPLATE,
  payload,
});

export const updateValidityStatusInAuthTemplate = (payload) => ({
  type: UPDATE_VALIDITY_STATUS_IN_AUTH_TEMPLATE,
  payload,
});

export const updateValidityPeriodInAuthTemplate = (payload) => ({
  type: UPDATE_VALIDITY_PERIOD_IN_AUTH_TEMPLATE,
  payload,
});

export const toggleChannelSelectionModal = (value) => ({
  type: TOGGLE_CHANNEL_SELECTION_MODAL,
  payload: value,
});
