import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import MegaContextProvider from "context/MegaContextProvider";
import { inLocalhost } from "util/envUtil";
import { store } from "./store";
import App from "./MegaApp";

if (!inLocalhost && process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event, hint) {
      const isNonErrorException =
        event &&
        event.message &&
        event.message.match(/SentryError: HTTP Error \(429\)/) &&
        (event.exception.values[0].value.startsWith(
          "Non-Error exception captured",
        ) ||
          hint.originalException.message.startsWith(
            "Non-Error exception captured",
          ));
      if (isNonErrorException) {
        return null;
      }
      return event;
    },
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.npm_package_version,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <MegaContextProvider>
      <App />
    </MegaContextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

window.Cypress && (window.store = store);
