import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";

export const RQ_MQTT_AUTH_TOKEN = "RQ_MQTT_AUTH_TOKEN";

const fetchMqttAuthToken = async (orgId) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/mqtt_auth_token/`,
  });

  if (res.result) {
    return res.data;
  }

  throw new Error(res.message);
};

// selectors
export const selectToken = (data) => data.token;
export const getMqttTopicList = (data) => {
  return data?.topics?.sub;
};

const useMqttAuthToken = (select = selectToken, options = {}) => {
  const orgId = useOrganizationId();

  return useQuery(
    [RQ_MQTT_AUTH_TOKEN, orgId],
    () => fetchMqttAuthToken(orgId),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined ? !!orgId : !!orgId && !!options.enabled,
      cacheTime: 12_00_000, // 20 mins
      staleTime: 12_00_000, // 20 mins
    },
  );
};

export default useMqttAuthToken;
