export function deleteUrlParams(urlSearch: string, variable: string): any {
  if (variable && typeof variable === "string") {
    const urlParams = new URLSearchParams(urlSearch);

    urlParams.delete(variable);

    return urlParams.toString();
  }

  return "";
}

/**
 * Adds or updates a url parameter and pushes a new url.
 * @param urlSearch The current search params in the url.
 * @param variable The variable name to add/update.
 * @param value The value to set for the variable.
 * @returns The modified history object.
 */
export function addUrlParams({
  urlSearch = "",
  variable = "",
  value = "",
}: {
  urlSearch?: string;
  variable?: string;
  value?: string;
}): string {
  const params = new URLSearchParams(urlSearch);

  if (params.get(variable)) {
    params.set(variable, value);
  } else {
    params.append(variable, value);
  }

  return params.toString();
}
