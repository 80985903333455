import { useQuery } from "@tanstack/react-query";
import { callApiV2 } from "util/apiCaller";

export const RQ_ACCOUNT_LIST = "RQ_ACCOUNT_LIST";

const fetchAccountList = async () => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/user-connected-organizations/`,
  });
  if (res?.result) {
    return res.data;
  }
  throw new Error(res.message);
};

const useAccountList = (select = (data) => data, options = {}) => {
  return useQuery([RQ_ACCOUNT_LIST], () => fetchAccountList(), {
    select,
    ...options,
    enabled: options.enabled,
  });
};

export default useAccountList;
