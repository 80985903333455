import {
  HAPTIK_PARTNER_ID,
  INTERAKT_PARTNER_ID,
  THRIVE_PARTNER_ID,
} from "modules/App/constants/partners";
import { showNotification, validationForMediaFile } from "./utility";
import { callUploadApi } from "./publicApiCaller";

type storeType = {
  getState: Function;
};

export function validStoreInstance(store: storeType | any) {
  return (
    store &&
    typeof store === "object" &&
    store.getState &&
    typeof store.getState === "function"
  );
}

export function getPartnerName(partnerId: string | undefined) {
  if (partnerId === HAPTIK_PARTNER_ID) {
    return "Haptik";
  }

  if (partnerId === INTERAKT_PARTNER_ID) {
    return "Interakt";
  }

  if (partnerId === THRIVE_PARTNER_ID) {
    return "Thrive";
  }

  return partnerId;
}

/**
 * Removes null keys from the template object, except for "footer" and "template_category_label".
 * @param template - The template object to remove null keys from.
 * @returns The template object without null keys, except for "footer" and "template_category_label".
 */
export function deleteTemplateNullKeysExceptForFooterAndCategoryLabels<T>(
  template: Record<string, T> | null | undefined,
): Record<string, T> {
  if (template) {
    return Object.keys(template).reduce((acc, key) => {
      if (
        key === "footer" ||
        key === "template_category_label" ||
        template[key] !== null
      ) {
        acc[key] = template[key] as T;
      }

      return acc;
    }, {} as Record<string, T>);
  }

  return {};
}


export function parsingUnwantedTextKeepingSafeText(html: string): string {
  const allowedTags: string[] = ['br', 'strong', 'em', 'i', 'del'];
  
  return html.replace(/<[^>]*>?/gm, (tag: string) => {
    const tagName: string | undefined = tag.replace(/[<\/>]/g, '').split(' ')[0];
    if (tagName && allowedTags.includes(tagName)) {
      return tag; 
    }
    return tag.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  });
}

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email: string): boolean => {
  if (email.match(EMAIL_REGEX)) {
    return true;
  }
  return false;
};

/**
 * Uploads a file to the specified organization and returns the media-url of the uploaded file.
 * @param fileToUpload - The file to be uploaded.
 * @param organizationId - The id of the organization to upload the file to.
 * @returns The media-url of the uploaded file.
 */
export async function handleFileUploadV2(
  fileToUpload: File,
  organizationId: string,
  category: string = "Inbox_agent_to_customer",
): Promise<string> {
  const formData = new FormData();
  formData.append("uploadFile", fileToUpload);
  if (validationForMediaFile(fileToUpload)) {
    return "";
  }
  if (organizationId) {
    try {
      const res = await callUploadApi(
        `v1/organizations/${organizationId}/file/upload/?fileCategory=${category}`,
        "post",
        formData,
      );
      if (res.result) {
        return res["media-url"];
      }

      throw new Error(res.message);
    } catch (error: Error | any) {
      showNotification("error", error.message);
    }
  }

  showNotification("error", "Failed to upload file");
  return "";
}
