import { getAllSubscriptionList } from "modules/Payments/PaymentSelectors";
import { useSelector } from "react-redux";

const useLastPlan = () => {
  const subscriptionListPlan = useSelector((state) => {
    const subscriptionCount = getAllSubscriptionList(state)?.length;

    return getAllSubscriptionList(state)?.[subscriptionCount - 1]?.subscription
      ?.name;
  });

  return subscriptionListPlan;
};

export default useLastPlan;
