import {
  GET_COLLECTIONS,
  SET_COLLECTION_BODY,
  SET_COLLECTION_IN_TOP_10,
  UPADTE_LOADING_COLLECTIONS,
  CHANGE_COLLECTIONS_SAVED_STATUS,
  UPDATE_COLLECTIONS_FETCH,
  AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE,
  AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED,
  AUTO_CHECKOUT_LOADER,
  AUTO_CHECKOUT_MESSAGE_UPDATE,
  AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED,
  FETCH_AUTO_CHECKOUT_MESSAGE_DETAILS,
  CHOOSE_AUTO_CHECKOUT_TEMPLATE,
  APPROVE_AUTO_CHECKOUT_SAMPLE,
  REJECTED_AUTO_CHECKOUT_SAMPLE,
  INIT_SAMPLE_APPROVED_AND_USED,
  UPDATE_CONNECTION_SYNC_STATUS,
  SET_CATALOG_SOURCE,
  SET_CATALOG_NAME,
  SET_CATALOG_LAST_SYNC_AT,
  SET_CATALOG_TOTAL_COUNT,
  TEMPLATE_TIMEOUT,
} from "./CommerceSettingsActionTypes";
import { autoCheckoutErrorTypes } from "./constants";

const initialState = {
  collections: {
    loading: false,
    data: [],
    collectionsSaved: true,
    fetched: false,
  },
  autoCheckout: {
    templateId: null,
    loading: false,
    sampleApprovedAndUsedForFirst: false,
    err: null,
    isEnabled: false,
    mergeVariablesBody: {},
    mergeVariablesHeader: {},
    isSampleTemplateBeingUsed: false,
  },
  connectionSyncStatus: null,
  catalogSource: null,
  catalogName: null,
  catalogLastSyncedAt: null,
};

// eslint-disable-next-line default-param-last
const commerceSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTIONS: {
      return {
        ...state,
        collections: {
          ...state.collections,
          loading: false,
          data: action.payload,
          collectionsSaved: true,
          fetched: true,
        },
      };
    }
    case UPADTE_LOADING_COLLECTIONS: {
      return {
        ...state,
        collections: {
          ...state.collections,
          loading: action.payload,
        },
      };
    }
    case UPDATE_COLLECTIONS_FETCH: {
      return {
        ...state,
        collections: {
          ...state.collections,
          fetched: action.payload,
        },
      };
    }
    case SET_COLLECTION_BODY: {
      const { id, body } = action.payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          collectionsSaved: false,
          data: state.collections.data.map((collection) => {
            if (collection.id === id) {
              return {
                ...collection,
                body_text: body,
              };
            }
            return collection;
          }),
        },
      };
    }
    case SET_COLLECTION_IN_TOP_10: {
      const { id, isChecked } = action.payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          collectionsSaved: false,
          data: state.collections.data.map((collection) => {
            if (collection.id === id) {
              return {
                ...collection,
                is_selected: isChecked,
              };
            }
            return collection;
          }),
        },
      };
    }
    case CHANGE_COLLECTIONS_SAVED_STATUS: {
      return {
        ...state,
        collections: {
          ...state.collections,
          collectionsSaved: action.payload,
        },
      };
    }
    case AUTO_CHECKOUT_LOADER: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          loading: true,
          isSampleTemplateBeingUsed: action.payload,
        },
      };
    }
    case AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          mergeVariablesHeader: action.payload.mergeVariablesHeader || {},
          mergeVariablesBody: action.payload.mergeVariablesBody || {},
          templateId: action.payload.templateId,
          loading: true,
          sampleApprovedAndUsedForFirst: false,
        },
      };
    }
    case APPROVE_AUTO_CHECKOUT_SAMPLE: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          loading: false,
          sampleApprovedAndUsedForFirst: true,
          err: "",
          isSampleTemplateBeingUsed: false,
        },
      };
    }
    case REJECTED_AUTO_CHECKOUT_SAMPLE: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          loading: false,
          sampleApprovedAndUsedForFirst: false,
          err: autoCheckoutErrorTypes.TEMPLATE_REJECTED,
          isSampleTemplateBeingUsed: false,
          templateId: null,
        },
      };
    }
    case TEMPLATE_TIMEOUT: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          loading: false,
          sampleApprovedAndUsedForFirst: false,
          err: autoCheckoutErrorTypes.TEMPLATE_APPROVAL_TIMEOUT,
          isSampleTemplateBeingUsed: false,
          templateId: null,
        },
      };
    }
    case INIT_SAMPLE_APPROVED_AND_USED: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          sampleApprovedAndUsedForFirst: false,
        },
      };
    }
    case AUTO_CHECKOUT_SAMPLE_TEMPLATE_CREATE_FAILED: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          err: autoCheckoutErrorTypes.TEMPLATE_CREATION_FAILED,
          loading: false,
          isSampleTemplateBeingUsed: false,
        },
      };
    }
    case AUTO_CHECKOUT_MESSAGE_UPDATE: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          loading: false,
          err: null,
          isEnabled: action.payload.isEnabled,
        },
      };
    }
    case AUTO_CHECKOUT_MESSAGE_UPDATE_FAILED: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          loading: false,
          err: autoCheckoutErrorTypes.CHECKOUT_MESSAGE_CREATE_OR_UPDATE_FAILED,
        },
      };
    }
    case FETCH_AUTO_CHECKOUT_MESSAGE_DETAILS: {
      return {
        ...state,
        autoCheckout: {
          ...state.autoCheckout,
          templateId: action.payload.templateId,
          isEnabled: action.payload.isEnabled,
          mergeVariablesHeader: action.payload.mergeVariablesHeader,
          mergeVariablesBody: action.payload.mergeVariablesBody,
        },
      };
    }
    case CHOOSE_AUTO_CHECKOUT_TEMPLATE: {
      return {
        ...state,
        autoCheckout: {
          templateId: action.payload.templateId,
          mergeVariablesHeader: action.payload.mergeVariablesHeader,
          mergeVariablesBody: action.payload.mergeVariablesBody,
        },
      };
    }
    case UPDATE_CONNECTION_SYNC_STATUS: {
      return {
        ...state,
        connectionSyncStatus: action.payload,
      };
    }
    case SET_CATALOG_SOURCE: {
      return {
        ...state,
        catalogSource: action.payload,
      };
    }
    case SET_CATALOG_NAME: {
      return {
        ...state,
        catalogName: action.payload,
      };
    }
    case SET_CATALOG_LAST_SYNC_AT: {
      return {
        ...state,
        catalogLastSyncedAt: action.payload,
      };
    }
    case SET_CATALOG_TOTAL_COUNT: {
      return {
        ...state,
        catalogTotalCount: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default commerceSettingsReducer;
