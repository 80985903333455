import useInDesktop from "customHooks/useInDesktop";
import React from "react";
import FullScreenMobileModal from "./FullScreenMobileModal";
import Modal from "./Modal";

const ResponsiveModal = ({
  children,
  isOpen,
  onHide,
  style,
  desktopProps = {},
  overlayCss = {},
  overlayVariant = "normal",
  ...rest
}) => {
  const inDesktop = useInDesktop();

  if (inDesktop) {
    return (
      <Modal
        isOpen={isOpen}
        onHide={onHide}
        style={style}
        overlayCss={overlayCss}
        overlayVariant={overlayVariant}
        {...desktopProps}
        {...rest}
      >
        {children}
      </Modal>
    );
  }
  return (
    <FullScreenMobileModal isOpen={isOpen} overlayCss={overlayCss} {...rest}>
      {children}
    </FullScreenMobileModal>
  );
};

export default ResponsiveModal;
