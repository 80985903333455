import { STARTER_PLAN_NAME } from "constants/enums/paymentMethods";
import useOrganizationDetails from "customHooks/Organization/useOrganizationDetails";
import useActivePlan from "customHooks/useActivePlan";
import { INTERAKT_PARTNER_ID } from "modules/App/constants/partners";

const CUT_OFF_TIME = new Date("Sep 05 2023").getTime();

const useHideCommerceForStarter = () => {
  const activePlan = useActivePlan();
  const orgCreatedUtc = useOrganizationDetails(
    (state) => state?.created_at_utc,
  );

  const orgCreatedTime = orgCreatedUtc && new Date(orgCreatedUtc)?.getTime();

  const partnerID = useOrganizationDetails((state) => state?.partner);

  return (
    activePlan === STARTER_PLAN_NAME &&
    CUT_OFF_TIME < orgCreatedTime &&
    partnerID === INTERAKT_PARTNER_ID
  );
};

export default useHideCommerceForStarter;
