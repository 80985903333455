import useCurrentWhitelabeledDetails from "./useCurrentWhitelabeledDetails";

// ? Trigger Api calls which are important before showing the page
const useInitApiCalls = () => {
  const { isLoading } = useCurrentWhitelabeledDetails();

  return isLoading;
};

export default useInitApiCalls;
