import React from "react";

const TotalCount = ({ fill, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_49_2743)">
        <path
          d="M6.66675 17.5H13.3334"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 14.1667V17.5001"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.83325 3.33325H14.1666"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1666 3.33325V9.99992C14.1666 11.105 13.7276 12.1648 12.9462 12.9462C12.1648 13.7276 11.105 14.1666 9.99992 14.1666C8.89485 14.1666 7.83504 13.7276 7.05364 12.9462C6.27224 12.1648 5.83325 11.105 5.83325 9.99992V3.33325"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.5 7.49992C2.5 7.94195 2.67559 8.36587 2.98816 8.67843C3.30072 8.99099 3.72464 9.16659 4.16667 9.16659C4.60869 9.16659 5.03262 8.99099 5.34518 8.67843C5.65774 8.36587 5.83333 7.94195 5.83333 7.49992C5.83333 7.05789 5.65774 6.63397 5.34518 6.32141C5.03262 6.00885 4.60869 5.83325 4.16667 5.83325C3.72464 5.83325 3.30072 6.00885 2.98816 6.32141C2.67559 6.63397 2.5 7.05789 2.5 7.49992Z"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1665 7.49992C14.1665 7.94195 14.3421 8.36587 14.6547 8.67843C14.9672 8.99099 15.3911 9.16659 15.8332 9.16659C16.2752 9.16659 16.6991 8.99099 17.0117 8.67843C17.3242 8.36587 17.4998 7.94195 17.4998 7.49992C17.4998 7.05789 17.3242 6.63397 17.0117 6.32141C16.6991 6.00885 16.2752 5.83325 15.8332 5.83325C15.3911 5.83325 14.9672 6.00885 14.6547 6.32141C14.3421 6.63397 14.1665 7.05789 14.1665 7.49992Z"
          stroke={fill}
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_49_2743">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TotalCount;
