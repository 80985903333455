import { callApiV2 } from "util/apiCaller";
import { updateLastSync } from "util/utility";
import { showNotification, showCustomNotification } from "../../util/utility";
import { getTemplateById } from "../Templates/TemplateActions";
import callApi from "../../util/apiCaller";
import { changeOrganizationDetails } from "../App/AppActions";
import EditNotificationToast from "./components/EditNotificationToast";

export const FETCH_CAMPAIGN_LIST = "FETCH_CAMPAIGN_LIST";
export const FETCH_CAMPAIGN = "FETCH_CAMPAIGN";
export const FETCH_CAMPAIGN_STATUS = "FETCH_CAMPAIGN_STATUS";
export const SET_CREATE_CAMP_ACTIVE_TAB = "SET_CREATE_CAMP_ACTIVE_TAB";
export const UPDATE_CAMPAIGN_FINAL_FILTERS = "UPDATE_CAMPAIGN_FINAL_FILTERS";
export const SET_IS_FILTER_APPLIED = "SET_IS_FILTER_APPLIED";
export const SET_ONLY_COUNT = "SET_ONLY_COUNT";
export const SET_RETRY_ENABLED = "SET_RETRY_ENABLED";

export function setCampaignActiveTab(tab) {
  return {
    type: SET_CREATE_CAMP_ACTIVE_TAB,
    campaignActiveTab: tab,
  };
}

export function setActiveCampaign(data = {}) {
  return {
    type: FETCH_CAMPAIGN,
    activeCampaign: data,
    isFetchingCampaign: false,
  };
}

export function getCampaignById(
  organizationId,
  campaignId,
  organizations,
  updateResCb = (resData) => resData,
) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CAMPAIGN_STATUS,
      isFetchingCampaign: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/campaigns/${campaignId}/`,
      "get",
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_CAMPAIGN,
          activeCampaign: updateResCb(res.data),
          isFetchingCampaign: false,
        });
        if (res.data.template_id) {
          dispatch(getTemplateById(organizationId, res.data.template_id));
        }
        if (organizations && organizations.length > 0) {
          const org = organizations;
          updateLastSync(org, campaignId);
          dispatch(changeOrganizationDetails(org));
        }
      } else {
        dispatch({
          type: FETCH_CAMPAIGN_STATUS,
          isFetchingCampaign: false,
        });
      }
    });
  };
}

export const sendCampaignEditNotification = (campaign, history) => {
  showCustomNotification(
    "warning",
    <EditNotificationToast campaign={campaign} history={history} />,
    {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      toastId: campaign.id,
      style: {
        background: "white",
        borderBottom: "3px solid #278351",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      },
    },
  );
};

export const triggerFetchCampaignReport = async (
  organizationId,
  campaignId,
) => {
  try {
    const res = await callApiV2({
      endpoint: `v1/organizations/${organizationId}/campaigns/${campaignId}/report/`,
      method: "get",
    });
    if (res.result) {
      showNotification(
        "success",
        "The campaign report will be sent to your email id within 15 minutes.",
      );
      return 1;
    }
    return 0;
  } catch (err) {
    showNotification(
      "error",
      "Some error occured while fetching campaign report. Please try again in some time.",
    );
    return 0;
  }
};

export const updateCampaignFinalFilters = (finalFilters) => {
  return {
    type: UPDATE_CAMPAIGN_FINAL_FILTERS,
    payload: finalFilters,
  };
};

export const setIsFilterApplied = (flag) => {
  return {
    type: SET_IS_FILTER_APPLIED,
    payload: flag,
  };
};

export const setOnlyCount = (flag) => {
  return {
    type: SET_ONLY_COUNT,
    payload: flag,
  };
};

export const setRetryEnabled = (flag) => {
  return {
    type: SET_RETRY_ENABLED,
    payload: flag,
  };
};
