import React from "react";

function copyTick({ fill, ...props }) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17_4538)">
        <path
          d="M8.01283 16.6666H5.83366C5.39163 16.6666 4.96771 16.4911 4.65515 16.1785C4.34259 15.8659 4.16699 15.442 4.16699 15V4.99998C4.16699 4.55795 4.34259 4.13403 4.65515 3.82147C4.96771 3.50891 5.39163 3.33331 5.83366 3.33331H12.5003C12.9424 3.33331 13.3663 3.50891 13.6788 3.82147C13.9914 4.13403 14.167 4.55795 14.167 4.99998V11.6666"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.667 15.8334L13.3337 17.5L16.667 14.1667"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.66669H10.8333"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 10H9.16667"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4538">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default copyTick;
