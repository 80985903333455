import { QueryClient } from "@tanstack/react-query";

// * reference -> https://tanstack.com/query/v4/docs/reference/QueryClient#queryclientsetdefaultoptions
// ? have set default values for queries and mutation
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 mins
      cacheTime: 300000, // 5 mins
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

export default queryClient;
