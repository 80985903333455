import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import mapKeys from "lodash/mapKeys";
import {
  FETCH_CUSTOMER_STATUS,
  FETCH_CUSTOMER,
  FETCH_NOTE_LIST_STATUS,
  FETCH_NOTE_LIST,
  FETCH_NOTE,
  CREATE_NOTE,
  CREATE_NOTE_STATUS,
  FETCH_NOTE_STATUS,
  UPDATE_NOTE_STATUS,
  UPDATE_NOTE_DETAIL,
  FETCH_CHAT_LIST,
  FETCH_CHAT_LIST_STATUS,
  SET_PAGE_SIZE,
  DELETE_NOTE_STATUS,
  DELETE_NOTE,
  FETCH_CUSTOMER_ATTRIBUTES,
  FETCH_EVENT_ATTRIBUTES,
  UPDATE_CUSTOMER_TAGS,
  UPDATING_CUSTOMER_TAGS,
  FETCH_CUSTOMER_EVENTS,
  FETCH_CUSTOMER_EVENT_STATUS,
  UPDATE_ACTIVE_CUSTOMER,
  FETCH_EVENT_ATTRIBUTES_STATUS,
  FETCH_CUSTOMER_ATTRIBUTES_STATUS,
  TOGGLE_BULK_UPLOAD_SUCCESS_STATUS,
  TOGGLE_CUSTOMER_NOTE,
  BULK_UPLOAD,
  UPLOADING_BULK_DATA,
  UPLOADED_BULK_DATA,
  ERROR_WHILE_UPLOADING_DATA,
  SAVE_USER_COLUMN_PREFERENCES_STATUS,
  SAVE_USER_COLUMN_PREFERENCES,
  BULK_TAG_CUSTOMER_STATUS,
  FETCH_ORDER_LIST_STATUS,
  FETCH_ORDER_LIST,
  UPDATE_APPLIED_FILTER,
  TOGGLE_SINGLE_ADD_CUSTOMER_MODAL,
  TOGGLE_BULK_TAG_CUSTOMER_MODAL,
  TOGGLE_BULK_TAG_CONFIRMATION_MODAL,
  ADD_USERS_TO_SELECTION,
  REMOVE_USERS_FROM_SELECTION,
  CLEAR_ALL_USERS_FROM_SELECTION,
  ADD_BULK_USERS_TO_SELECTION,
  UPDATE_CUSTOMER_TRAIT_START,
  UPDATE_CUSTOMER_TRAIT_FAILURE,
  UPDATE_CUSTOMER_TRAIT_SUCCESS,
  SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE,
  UPDATE_CSV_FILE_NAME,
  UPDATE_CSV_FILE_URL,
  UPDATE_SHOPIFY_CUSTOMER_SYNC_FINAL_STATE,
  UPDATE_CUSTOMERS_FINAL_FILTERS,
  UPLOADING_BULK_DATA_SUCCESS,
  ADD_ALL_USERS_IN_SELECTION,
} from "./CustomerActions";
import { reverseSortArrayWithCreatedAtUtc } from "util/chatUtil";
import { SHOPIFY_CUSTOMER_FINAL_SYNC_IDLE } from "constants/shopify";
import cloneDeep from "lodash/cloneDeep";

/*
* // get, updates, remove, add
        // '1234' : {
        *   activeCustomer: {},
        *  noteList: [],
        *  activeChats: [],
        *  customerEvents: {}
        * }, customerData[1234].chatList =
        // '456' : { activeCustomer: {}, customerNotes: [], activeChats: [], customerEvents: {} },
        // '6789' : { activeCustomer: {}, customerNotes: [], activeChats: [], customerEvents: {} },
csv_data: [{"header_name":"Emp. No.","mapped_variable":"Emp. No.","isMappedToCSVName":true,"items":["1234567","1234568","1234569","1234570","1234571"],"index":0},{"header_name":"Name","mapped_variable":"Name","isMappedToCSVName":true,"items":["Manoj","Shiva","Karthik","Apurva","Ritwik"],"index":1},{"header_name":"First Name","mapped_variable":"First Name","isMappedToCSVName":true,"items":["Manoj","Shiva","Kartik","Apurva","Ritwik"],"index":2},{"header_name":"Last Name","mapped_variable":"Last Name","isMappedToCSVName":true,"items":["Kumar","Kumar","Bansal","","Sahoo"],"index":3},{"header_name":"Email ID","mapped_variable":"Email ID","isMappedToCSVName":true,"items":["","","","",""],"index":4},{"header_name":"Cell number","mapped_variable":"Cell number","isMappedToCSVName":true,"items":[" 23871246 "," 23871247 "," 23871248 "," 23871249 "," 23871250 "],"index":5}],
    csv_filename: 'upload.csv',
* */

const initialState = {
  customerCount: 0,
  pageNum: 1,
  pageSize: 100,
  count: 0,
  totalCount: 0,
  activeCustomer: {},
  customerData: {},
  noteList: [],
  chatEvents: [],
  chats: [],
  activeChatMessages: [],
  activeNote: {},
  isFetchingNoteList: false,
  isFetchingNote: false,
  isCreatingNote: false,
  isUpdatingNote: false,
  isFetchingCustomerList: false,
  isFetchingCustomer: false,
  isFetchingChatList: false,
  chatList: [],
  customerDetails: {},
  isFetchingCustomerDetails: false,
  customer_attributes: [], // 18-20
  eventAttributeList: [],
  isUpdatingCustomerTags: false,
  isUpdatingCustomerDetail: false,
  isLoadingInitialList: true,
  totalCustomerEventCount: 0,
  isFetchingCustomerEvents: false,
  isFetchingEventAttributes: false,
  isFetchingCustomerAttributes: false,
  defaultCustomerAttributes: [
    {
      keyName: "name",
      keyValue: "Name",
      dataType: "str",
    },
    {
      keyName: "phone_number",
      keyValue: "Contact",
      dataType: "str",
    },
    {
      keyName: "email",
      keyValue: "Email",
      dataType: "str",
    },
    {
      keyName: "tags",
      keyValue: "Tags",
      dataType: "list",
    },
    {
      keyName: "created_at_utc",
      keyValue: "Created On",
      dataType: "datetime",
    },
  ], // 4,5
  defaultShopifyCustomerAttributes: [
    {
      keyName: "name",
      keyValue: "Name",
      dataType: "str",
    },
    {
      keyName: "phone_number",
      keyValue: "Contact",
      dataType: "str",
    },
    {
      keyName: "last_order_name",
      keyValue: "Last Order Name",
      dataType: "str",
    },
    {
      keyName: "total_spent",
      keyValue: "Total Spent",
      dataType: "str",
    },
    {
      keyName: "last_order_id",
      keyValue: "Last Order Id",
      dataType: "number",
    },
    {
      keyName: "email",
      keyValue: "Email",
      dataType: "str",
    },
    {
      keyName: "tags",
      keyValue: "Tags",
      dataType: "list",
    },
    {
      keyName: "created_at_utc",
      keyValue: "Created On",
      dataType: "datetime",
    },
  ], // 4,5
  isSavingUserColumnPreferences: false,
  selectedCustomerAttributes: [], // 2 -> 20
  userAttributeDisplayName: {
    user_id: "User Id",
    name: "Name",
    email: "Email",
    phone_number: "Phone Number",
    country_code: "Country Code",
    whatsapp_opted_in: "WhatsApp Opted",
    created_at_utc: "Created On",
    tags: "Tags",
  },
  csv_data: [],
  csv_filename: "",
  isUploadingBulkCSVData: false,
  bulkUploadErrors: [],
  isBulUploadSuccessModalVisible: false,
  isBulkTaggingCustomer: false,
  isFetchingOrderList: false,
  uuid: "",
  updatedAppliedFilters: [],
  isOpenAddContactForm: false,
  selectedUsers: [],
  selectedUserCount: 0,
  isOpenAddTagForm: false,
  isOpenAddTagConfirmationForm: false,
  activeCustomerAllUsersPage: "",
  csvFileName: "",
  csvFileURL: "",
  shopifyCustomerSyncFinalState: SHOPIFY_CUSTOMER_FINAL_SYNC_IDLE,
  finalFilters: [],
  isAllUsersSelected: false,
};

const applyExtraFilters = (list, extraFilters) => {
  if (extraFilters && isArray(extraFilters) && extraFilters.length > 0) {
    extraFilters.forEach((item) => {
      list = list.filter((listItem) => {
        const newListItem = { ...listItem, ...listItem.traits };
        let shouldAdd = true;
        if (item.relation === "is unknown" && !!!newListItem[item.keyName]) {
          shouldAdd = true;
        } else if (
          item.relation === "has any value" &&
          !!newListItem[item.keyName]
        ) {
          shouldAdd = true;
        } else {
          shouldAdd = false;
        }
        return shouldAdd;
      });
    });
  }

  return list;
};

// eslint-disable-next-line default-param-last
const customerReducer = (state = initialState, action) => {
  let selectedUsers = [];
  let customerData = {};
  let selectedUserCount = 0;

  switch (action.type) {
    case UPDATE_SHOPIFY_CUSTOMER_SYNC_FINAL_STATE: {
      return {
        ...state,
        shopifyCustomerSyncFinalState: action.shopifyCustomerSyncFinalState,
      };
    }
    case ADD_BULK_USERS_TO_SELECTION:
      return {
        ...state,
        selectedUsers: action.users,
        selectedUserCount: action.users.length,
      };

    case TOGGLE_SINGLE_ADD_CUSTOMER_MODAL:
      return {
        ...state,
        isOpenAddContactForm: action.isOpenAddContactForm,
      };

    case TOGGLE_BULK_TAG_CUSTOMER_MODAL:
      return {
        ...state,
        isOpenAddTagForm: action.isOpenAddTagForm,
      };

    case TOGGLE_BULK_TAG_CONFIRMATION_MODAL:
      return {
        ...state,
        isOpenAddTagConfirmationForm: action.isOpenAddTagConfirmationForm,
      };

    case UPDATE_APPLIED_FILTER:
      return {
        ...state,
        updatedAppliedFilters: action.updatedAppliedFilters,
      };

    case BULK_TAG_CUSTOMER_STATUS:
      return {
        ...state,
        isBulkTaggingCustomer: action.isBulkTaggingCustomer,
      };

    case UPDATE_ACTIVE_CUSTOMER:
      return {
        ...state,
        activeCustomer: action.activeCustomer,
      };

    case FETCH_CUSTOMER_ATTRIBUTES_STATUS:
      return {
        ...state,
        isFetchingCustomerAttributes: action.isFetchingCustomerAttributes,
      };

    case FETCH_EVENT_ATTRIBUTES_STATUS:
      return {
        ...state,
        isFetchingEventAttributes: action.isFetchingEventAttributes,
      };

    case FETCH_CUSTOMER_STATUS:
      return {
        ...state,
        isFetchingCustomer: action.isFetchingCustomer,
      };

    case FETCH_CUSTOMER:
      customerData = state.customerData;
      if (action.activeCustomer && customerData[action.activeCustomer.id]) {
        customerData[action.activeCustomer.id].activeCustomer =
          action.activeCustomer;
      } else {
        customerData[action.activeCustomer.id] = {};
        customerData[action.activeCustomer.id].activeCustomer =
          action.activeCustomer;
      }
      return {
        ...state,
        activeCustomer: action.activeCustomer,
        customerData,
        isFetchingCustomer: action.isFetchingCustomer,
      };

    case DELETE_NOTE_STATUS:
      return {
        ...state,
        isDeletingNote: action.isDeletingNote,
      };

    case DELETE_NOTE: {
      const noteListMap = mapKeys(
        [...state.customerData[action.customerId].noteList],
        "id",
      );

      delete noteListMap[action.noteId];

      return {
        ...state,
        isDeletingNote: action.isDeletingNote,

        customerData: {
          ...state.customerData,
          [action.customerId]: {
            ...state.customerData[action.customerId],
            noteList: [...Object.values(noteListMap)],
          },
        },
      };
    }

    case UPDATE_CUSTOMER_TRAIT_START:
      return {
        ...state,
        isUpdatingCustomerDetail: true,
      };
    case UPDATE_CUSTOMER_TRAIT_SUCCESS:
      return {
        ...state,
        isUpdatingCustomerDetail: false,
        customerData: {
          ...customerData,
          [action.payload.customerId]: {
            ...state.customerData[action.payload?.customerId],
            activeCustomer: {
              ...state.customerData[action.payload?.customerId]?.activeCustomer,
              traits: {
                ...state.customerData[action.payload?.customerId].activeCustomer
                  ?.traits,
                ...action.payload.data,
              },
            },
          },
        },
      };
    case UPDATE_CUSTOMER_TRAIT_FAILURE:
      return {
        ...state,
        isUpdatingCustomerDetail: false,
      };

    case UPDATING_CUSTOMER_TAGS:
      return {
        ...state,
        isUpdatingCustomerTags: action.isUpdatingCustomerTags,
      };

    case FETCH_NOTE_LIST_STATUS:
      return {
        ...state,
        isFetchingNoteList: action.isFetchingNoteList,
      };

    case FETCH_NOTE_LIST:
      customerData = state.customerData;
      const sortedList = reverseSortArrayWithCreatedAtUtc(action.noteList);
      if (action.customerId && customerData[action.customerId]) {
        if (customerData[action.customerId].noteList) {
          customerData[action.customerId].noteList =
            action.pageNum === 1
              ? [...action.noteList]
              : [
                  ...customerData[action.customerId].noteList,
                  ...action.noteList,
                ];
        } else {
          customerData[action.customerId].noteList = [...sortedList];
        }
      } else {
        customerData[action.customerId] = {};
      }
      return {
        ...state,
        customerData,
        // noteList: action.noteList,
        isFetchingNoteList: action.isFetchingNoteList,
      };

    case FETCH_NOTE_STATUS:
      return {
        ...state,
        isFetchingNote: action.isFetchingNote,
      };

    case TOGGLE_CUSTOMER_NOTE:
      customerData = state.customerData;
      if (action.customerId && action.noteId) {
        customerData[action.customerId].noteList = customerData[
          action.customerId
        ].noteList.map((item) => {
          if (item.id === action.noteId) {
            item.isActive = !!!item.isActive;
          }
          return item;
        });
      }
      return {
        ...state,
        customerData,
      };

    case FETCH_NOTE:
      return {
        ...state,
        activeNote: action.activeNote,
        isFetchingNote: action.isFetchingNote,
      };

    case CREATE_NOTE_STATUS:
      return {
        ...state,
        isCreatingNote: action.isCreatingNote,
      };

    case CREATE_NOTE: {
      const getNoteList = () => {
        if (state.customerData[action.customerId].noteList) {
          return [
            ...state.customerData[action.customerId].noteList,
            {
              ...action.note,
              modified_at_utc: new Date().toISOString(),
              created_by_user_id: action.userId,
            },
          ];
        }
        return [
          {
            ...action.note,
            modified_at_utc: new Date().toISOString(),
            created_by_user_id: action.userId,
          },
        ];
      };

      return {
        ...state,
        isCreatingNote: action.isCreatingNote,
        customerData: {
          ...state.customerData,
          [action.customerId]: {
            ...state.customerData[action.customerId],
            noteList: getNoteList(),
          },
        },
      };
    }

    case UPDATE_NOTE_STATUS:
      return {
        ...state,
        isUpdatingNote: action.isUpdatingNote,
      };

    case UPDATE_NOTE_DETAIL: {
      const noteListCopy = [...state.customerData[action.customerId].noteList];

      const updatedNotesList = noteListCopy.map((item) => {
        if (item.id === action.noteId) {
          return {
            ...item,
            modified_at_utc: new Date().toISOString(),
            created_by_user_id: action.userId,
            notes: action.activeNote.notes,
          };
        }

        return item;
      });

      return {
        ...state,
        isUpdatingNote: action.isUpdatingNote,
        customerData: {
          ...state.customerData,
          [action.customerId]: {
            ...state.customerData[action.customerId],
            noteList: updatedNotesList,
          },
        },
      };
    }

    case FETCH_CHAT_LIST_STATUS:
      return {
        ...state,
        isFetchingChatList: action.isFetchingChatList,
      };

    case FETCH_CHAT_LIST:
      if (action.chatList.length > 0) {
        action.chatList.reverse();
      }
      customerData = state.customerData;
      if (action.customerId && customerData[action.customerId]) {
        customerData[action.customerId].activeChats = [...action.chatList];
      } else {
        customerData[action.customerId] = {};
        customerData[action.customerId].activeChats = [...action.chatList];
      }
      return {
        ...state,
        customerData,
        isFetchingChatList: action.isFetchingChatList,
      };

    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };

    case FETCH_CUSTOMER_ATTRIBUTES:
      const obj =
        action.customer_attributes && !isEmpty(action.customer_attributes)
          ? action.customer_attributes
          : {};
      const attArr = [];
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newObj = {};
          newObj.keyName = key;
          if (key === "created_at_utc") {
            newObj.keyValue = "Created On";
          } else {
            newObj.keyValue = key;
          }
          newObj.dataType = obj[key].DataType;
          attArr.push(newObj);
        }
      }
      attArr.unshift({
        dataType: "str",
        keyName: "id",
        keyValue: "customer_id",
      });
      return {
        ...state,
        customer_attributes: attArr,
        isFetchingCustomerAttributes: action.isFetchingCustomerAttributes,
      };

    case FETCH_EVENT_ATTRIBUTES:
      const attrList =
        action.eventAttributeList && action.eventAttributeList.length > 0
          ? action.eventAttributeList
              .filter((item) => item.event_name !== "")
              .map((item) => {
                item.filters = [];
                const attrObj =
                  item.attributes && !isEmpty(item.attributes)
                    ? item.attributes
                    : {};
                for (const key in attrObj) {
                  if (attrObj.hasOwnProperty(key)) {
                    const newObj = {};
                    newObj.keyName = key;
                    newObj.keyValue = key;
                    newObj.dataType = attrObj[key].DataType;
                    item.filters.push(newObj);
                  }
                }
                return item;
              })
          : [];
      return {
        ...state,
        event_attribute_list: action.event_attribute_list,
        event_name: action.event_name,
        eventAttributeList: attrList,
        isFetchingEventAttributes: action.isFetchingEventAttributes,
      };

    case FETCH_CUSTOMER_EVENTS:
      customerData = state.customerData;
      if (action.customerId && customerData[action.customerId]) {
        if (action.pageNum === 1) {
          customerData[action.customerId].customerEvents =
            action.customerEvents;
        } else if (customerData[action.customerId].customerEvents) {
          customerData[action.customerId].customerEvents = [
            customerData[action.customerId].customerEvents,
            ...action.customerEvents,
          ];
        } else {
          customerData[action.customerId].customerEvents =
            action.customerEvents;
        }
      } else {
        customerData[action.customerId] = {};
        customerData[action.customerId].customerEvents = [
          ...action.customerEvents,
        ];
      }
      return {
        ...state,
        customerData,
        isFetchingCustomerEvents: action.isFetchingCustomerEvents,
        totalCustomerEventCount: action.totalCustomerEventCount,
      };

    case FETCH_CUSTOMER_EVENT_STATUS:
      return {
        ...state,
        isFetchingCustomerEvents: action.isFetchingCustomerEvents,
      };

    case BULK_UPLOAD:
      return {
        ...state,
        csv_data: action.csv_data,
        csv_filename: action.csv_filename,
      };

    case UPLOADING_BULK_DATA:
      return {
        ...state,
        isUploadingBulkCSVData: action.isUploadingBulkCSVData,
      };

    case UPLOADED_BULK_DATA:
      return {
        ...state,
        isUploadingBulkCSVData: action.isUploadingBulkCSVData,
        isBulUploadSuccessModalVisible: action.isBulUploadSuccessModalVisible,
      };

    case UPDATE_CSV_FILE_NAME:
      return {
        ...state,
        csvFileName: action.filename,
      };

    case UPDATE_CSV_FILE_URL:
      return {
        ...state,
        csvFileURL: action.url,
      };

    case ERROR_WHILE_UPLOADING_DATA:
      return {
        ...state,
        isUploadingBulkCSVData: action.isUploadingBulkCSVData,
        bulkUploadErrors: action.bulkUploadErrors,
      };

    case TOGGLE_BULK_UPLOAD_SUCCESS_STATUS:
      return {
        ...state,
        isBulUploadSuccessModalVisible: action.isBulUploadSuccessModalVisible,
      };

    case SAVE_USER_COLUMN_PREFERENCES_STATUS:
      return {
        ...state,
        isSavingUserColumnPreferences: action.isSavingUserColumnPreferences,
      };

    case SAVE_USER_COLUMN_PREFERENCES:
      return {
        ...state,
        isSavingUserColumnPreferences: action.isSavingUserColumnPreferences,
        selectedCustomerAttributes: action.selectedCustomerAttributes,
      };

    case FETCH_ORDER_LIST_STATUS:
      return {
        ...state,
        isFetchingOrderList: action.isFetchingOrderList,
      };

    case FETCH_ORDER_LIST:
      customerData = state.customerData;
      if (action.customerId && customerData[action.customerId]) {
        if (customerData[action.customerId].orderList) {
          customerData[action.customerId].orderList =
            action.pageNum === 1
              ? [...action.orderList]
              : [
                  ...customerData[action.customerId].orderList,
                  ...action.orderList,
                ];
        } else {
          customerData[action.customerId].orderList = [...action.orderList];
        }
      } else {
        customerData[action.customerId] = {};
      }
      return {
        ...state,
        customerData,
        isFetchingOrderList: action.isFetchingOrderList,
      };

    case ADD_USERS_TO_SELECTION:
      selectedUsers = state.selectedUsers;
      if (action.userId && !(selectedUsers.indexOf(action.userId) > -1)) {
        selectedUsers.push(action.userId);
      }
      selectedUserCount = selectedUsers.length;
      return {
        ...state,
        selectedUsers,
        selectedUserCount,
      };

    case REMOVE_USERS_FROM_SELECTION:
      selectedUsers = state.selectedUsers.filter(
        (item) => item !== action.userId,
      );
      selectedUserCount = selectedUsers.length;
      return {
        ...state,
        selectedUsers,
        selectedUserCount,
      };

    case CLEAR_ALL_USERS_FROM_SELECTION:
      selectedUserCount = selectedUsers.length;
      return {
        ...state,
        selectedUsers,
        selectedUserCount,
        isAllUsersSelected: false,
      };

    case ADD_ALL_USERS_IN_SELECTION:
      if (!action.payload) {
        return {
          ...state,
          isAllUsersSelected: true,
        };
      }

      return {
        ...state,
        isAllUsersSelected: true,
        selectedUsers: action.payload,
        selectedUserCount: action.payload?.length,
      };

    case SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE:
      return { ...state, activeCustomerAllUsersPage: action.payload };

    case UPDATE_CUSTOMERS_FINAL_FILTERS:
      return { ...state, finalFilters: action.payload };

    case UPDATE_CUSTOMER_TAGS: {
      const customerDataCopy = cloneDeep(state.customerData);
      if (action.activeCustomer) {
        customerDataCopy[action.activeCustomer.id].activeCustomer =
          action.activeCustomer;
      }
      return {
        ...state,
        isUpdatingCustomerTags: action.isUpdatingCustomerTags,
        activeCustomer: action.activeCustomer,
        customerData: customerDataCopy,
      };
    }

    case UPLOADING_BULK_DATA_SUCCESS:
      return {
        ...state,
        isUploadingBulkCSVData: false,
        isBulUploadSuccessModalVisible: false,
      };

    default:
      return state;
  }
};

export default customerReducer;

// selectors

export const getCustomerListInitialFetchStatus = (state) =>
  state.customer.isLoadingInitialList;
export const getCustomerLoadingState = (state) =>
  state.customer.isFetchingCustomer;
export const getPageNUm = (state) => state.customer.pageNum;
export const getPageSize = (state) => state.customer.pageSize;
export const getCustomerCount = (state) => state.customer.customerCount;

export const getTotalCustomerCount = (state) => state.customer.totalCount;
export const getCustomerData = (state) => state.customer.customerData;
export const getActiveCustomer = (state) => state.customer.activeCustomer;
export const getActiveCustomerTraits = (state) => state.customer.activeCustomer;
export const getChatNotes = (state) => state.customer.noteList;
export const getChatEvents = (state) => state.customer.chatEvents;
export const getChats = (state) => state.customer.chats;
export const getActiveChatMessages = (state) =>
  state.customer.activeChatMessages;

export const getCustomerNoteLoadingState = (state) =>
  state.customer.isFetchingNoteList;
export const getCreateNoteStatus = (state) => state.customer.isCreatingNote;
export const getUpdateNoteStatus = (state) => state.customer.isUpdatingNote;
export const getDeleteNoteStatus = (state) => state.customer.isDeletingNote;
export const getUpdateCustomerStatus = (state) =>
  state.customer.isUpdatingCustomerDetail;
export const getUpdateCustomerTagsStatus = (state) =>
  state.customer.isUpdatingCustomerTags;
export const getFetchChatListStatus = (state) =>
  state.customer.isFetchingChatList;
export const getChatList = (state) => state.customer.chatList;
export const getCustomerAttributes = (state) =>
  state.customer.customer_attributes;
export const getCustomerAttributesWithoutUserId = (state) =>
  state.customer.customer_attributes &&
  state.customer.customer_attributes.length > 0
    ? state.customer.customer_attributes
    : [];
export const getCustomerAttributesWithoutTags = (state) =>
  state.customer.customer_attributes &&
  state.customer.customer_attributes.length > 0
    ? state.customer.customer_attributes.filter(
        (item) => item.keyName !== "tags",
      )
    : [];
export const getActiveEventName = (state) => state.customer.event_name;
export const getEventAttributeList = (state) =>
  state.customer.eventAttributeList;
export const getCustomerEventFetchStatus = (state) =>
  state.customer.isFetchingCustomerEvents;
export const getUserAttributeDisplayName = (state) =>
  state.customer.userAttributeDisplayName;
export const getUserAttributeFetchStatus = (state) =>
  state.customer.isFetchingCustomerAttributes;
export const getEventAttributeFetchStatus = (state) =>
  state.customer.isFetchingEventAttributes;
export const getCsvData = (state) => state.customer.csv_data;
export const getCsvFilename = (state) => state.customer.csv_filename;
export const getCSVFileName = (state) => state.customer.csvFileName;
export const getCSVFileURL = (state) => state.customer.csvFileURL;
export const getCsvUpdatingState = (state) =>
  state.customer.isUploadingBulkCSVData;
export const getBulkUploadErrors = (state) => state.customer.bulkUploadErrors;
export const getBulkUploadSuccessModalState = (state) =>
  state.customer.isBulUploadSuccessModalVisible;
export const getBulkTagCustomerStatus = (state) =>
  state.customer.isBulkTaggingCustomer;
export const getDefaultCustomerAttributes = (state) =>
  state.customer.defaultCustomerAttributes;
export const getDefaultShopifyCustomerAttributes = (state) =>
  state.customer.defaultShopifyCustomerAttributes;
export const selectedCustomerAttributes = (state) =>
  state.customer.selectedCustomerAttributes;
export const getSaveUserColumnPreferencesStatus = (state) =>
  state.customer.isSavingUserColumnPreferences;
export const getOrderListStatus = (state) => state.customer.isFetchingOrderList;
export const getUpdatedAppliedFilters = (state) =>
  state.customer.updatedAppliedFilters;
export const getAppliedFilter = (state) => state.customer.updatedAppliedFilters;
export const getSingleAddCustomerModalState = (state) =>
  state.customer.isOpenAddContactForm;
export const getBulkTagCustomerModalState = (state) =>
  state.customer.isOpenAddTagForm;
export const getBulkTagConfirmationModalState = (state) =>
  state.customer.isOpenAddTagConfirmationForm;
export const getSelectedUsers = (state) => state.customer.selectedUsers;
export const getSelectedUsersCount = (state) =>
  state.customer.selectedUserCount;
export const getShopifyCustomerFinalSyncState = (state) =>
  state.customer.shopifyCustomerSyncFinalState;

export const getCustomersFinalFilters = (state) => state.customer.finalFilters;

export const getIsAllUsersSelected = (state) =>
  state.customer.isAllUsersSelected;
