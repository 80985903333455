import React from "react";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import { ModalHeader } from "konnekt/Modal";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";
import { selectSupportEmail } from "useApi/Partner/useWhitelabeledDetails";
import GeneralLink from "konnekt/GeneralLink";

const HelpDeskModalBodyGeneral = ({ onHide }) => {
  const { data: supportEmail } = useCurrentWhitelabeledDetails(
    selectSupportEmail,
  );
  return (
    <>
      <ModalHeader variant="white" onHide={onHide}>
        Help
      </ModalHeader>
      <Box padding="24px">
        <Label fontSize="16px" fontWeight={500} color="#0A0B10" as="span">
          For regular product-related support queries:
          <br />
          <Label
            display="inline"
            fontSize="14px"
            fontWeight={400}
            color="#0A0B10"
          >
            Please contact the customer care
          </Label>
          {supportEmail && (
            <>
              {" "}
              <Label
                display="inline"
                fontSize="14px"
                fontWeight={400}
                color="#0A0B10"
              >
                at{" "}
              </Label>
              <GeneralLink
                href={`mailto:${supportEmail}`}
                fontSize="14px"
                fontWeight={400}
                color="#0067DD"
                textDecoration="none"
              >
                {supportEmail}
              </GeneralLink>
            </>
          )}
          .
        </Label>
      </Box>
    </>
  );
};

export default HelpDeskModalBodyGeneral;
