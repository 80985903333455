import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import useUser, { selectUserId } from "useApi/App/useUser";
import { callApiV2 } from "util/apiCaller";
import {
  getIsFetchingConversationList,
  getNewChatPageTotalCount,
} from "modules/Inbox/ConversationReducer";
import { useSelector } from "react-redux";
import useIsSandboxMode from "customHooks/useIsSandboxMode";

export const RQ_CHATS_STATS = "RQ_CHATS_STATS";

const fetchChatsStats = async ({ orgId, agentId }) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v2/organizations/${orgId}/chats/stats/${agentId}/?channelTypes=Whatsapp,Instagram&chatsCount=active,closed`,
  });

  if (res.result) {
    return res.data;
  }

  throw new Error(res.message);
};

// ? To be used later once cleanup for Inbox reducer is done
const useChatsStats = (select = (data) => data, options = {}) => {
  const orgId = useOrganizationId();
  const { data: userId } = useUser(selectUserId);

  const isSandboxMode = useIsSandboxMode();

  const fetchingConversationList = useSelector(getIsFetchingConversationList);

  const chatCount = useSelector(getNewChatPageTotalCount);

  const shouldNotCallApi =
    !isSandboxMode || fetchingConversationList || chatCount > 0;

  return useQuery(
    [RQ_CHATS_STATS, orgId, userId],
    () =>
      fetchChatsStats({
        orgId,
        agentId: userId,
      }),
    {
      select,
      placeholderData: { active_chats_count: 0, closed_chats_count: 0 },
      ...options,
      enabled:
        options.enabled === undefined
          ? !!orgId && !!userId && !shouldNotCallApi
          : !!orgId && !!userId && !!options.enabled && !shouldNotCallApi,
    },
  );
};

export const selectOrgActiveChatCount = (data) => data?.active_chats_count;
export const selectOrgClosedChatCount = (data) => data?.closed_chats_count;

export default useChatsStats;
