export const TEXT = "text";
export const IMAGE = "image";
export const AUDIO = "audio";
export const VIDEO = "video";
export const DOCUMENT = "document";
export const TEMPLATE = "template";
export const LOCATION = "location";
export const CONTACT = "contact";
export const STICKER = "sticker";
export const UNKNOWN = "unknown";
export const NOTIFICATION = "notification";
export const RETRY_DOCUMENT = "retry-document";
export const INTERACTIVE = "interactive";
export const INTERACTIVE_LIST = "interactivelist";
export const INTERACTIVE_FLOW_REPLY = "interactiveflowreply";
export const INTERACTIVE_PRODUCT_LIST = "interactiveproductlist";
export const INTERACTIVE_PRODUCT = "interactiveproduct";
export const INTERACTIVE_BUTTON = "interactivebutton";
export const INTERACTIVE_LIST_REPLY = "interactivelistreply";
export const INTERACTIVE_BUTTON_REPLY = "interactivebuttonreply";
export const ORDER = "order";
export const SHARE = "share";
export const STORY_REPLY_TO = "storyreplyto";
export const STORY_MENTION = "storymention";
export const ORDER_DETAILS = "orderdetails";
export const ORDER_STATUS = "orderstatus";
