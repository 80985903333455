import useOrganizationId from "customHooks/useOrganizationId";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import Loader from "konnekt/Loader";
import React, { useState } from "react";
import styled from "styled-components";
import useAccountList from "useApi/Accounts/AccountLists";
import AccountItem from "./AccountItem";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import { Seperator } from "./OrganizationSubMenu";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-top: 14px;
  position: relative;
  padding: 0px 8px;
`;

const MAX_ALLOWED = 1;

const AccountList = ({ toggleSettings }) => {
  const [showAll, setShowAll] = useState(false);
  const orgId = useOrganizationId();
  const { isLoading, data: organizations } = useAccountList((list) =>
    list?.filter((data) => data.id !== orgId),
  );
  const isTallyOrg = useIsSignupSourceTally();

  if (!isLoading && organizations?.length === 0) {
    return null;
  }

  return (
    <>
      <Seperator ml="8px" />
      <Container>
        <Label variant="text6" color="#5A638C">
          Other Accounts
        </Label>
        {isLoading && (
          <Loader
            style={{
              position: "absolute",
              top: 10,
              left: "50%",
              transform: "translateX(-50%)",
            }}
            color="#278351"
          />
        )}
        {!!(!isLoading && organizations?.length) &&
          organizations
            .filter((_, index) => index < MAX_ALLOWED || showAll)
            ?.map((org) => {
              return (
                <AccountItem
                  toggleSettings={toggleSettings}
                  item={org}
                  key={org.id}
                />
              );
            })}
        {!isLoading && !showAll && organizations?.length > MAX_ALLOWED && (
          <Box onClick={() => setShowAll(true)}>
            <Label variant="text6" color="#0067DD">{`+ ${
              organizations.length - MAX_ALLOWED
            } more`}</Label>
          </Box>
        )}
        {isTallyOrg && (
          <Label variant="text12" color="#464D6D">
            To add another account, contact your TallyPrime Partner
          </Label>
        )}
      </Container>
    </>
  );
};

export default AccountList;
