import React from "react";
import useOrgChannelList from "./useOrgChannelList";

const withUseOrgChannelList = (WrappedComponent) => {
  return (props) => {
    const { refetch } = useOrgChannelList();

    return <WrappedComponent refetchOrgChannelList={refetch} {...props} />;
  };
};

export default withUseOrgChannelList;
