import { useMutation, useQueryClient } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";
import { RQ_CAMPAIGN_BY_ID } from "./useCampaignById";
import {
  RQ_CAMPAIGNS_BY_FILTERS,
  updateCampaignInInfiniteQuery,
} from "./useCampaignsByFilters";

const updateCampaign = async ({ orgId, newCampaign, campaignId }) => {
  const res = await callApiV2({
    method: "put",
    endpoint: `v1/organizations/${orgId}/campaigns/${campaignId}/`,
    body: newCampaign,
  });

  if (res && res.result) {
    return res.data;
  }

  throw new Error(res.message);
};

const useUpdateCampaign = (options = {}) => {
  const queryClient = useQueryClient();
  const orgId = useOrganizationId();

  return useMutation(
    ({ newCampaign, campaignId }) =>
      updateCampaign({ newCampaign, orgId, campaignId }),
    {
      ...options,
      onSuccess: (newCampaign) => {
        queryClient.setQueryData(
          [RQ_CAMPAIGN_BY_ID, orgId, newCampaign.id],
          newCampaign,
        );
        queryClient.setQueriesData(
          [RQ_CAMPAIGNS_BY_FILTERS, orgId],
          (prevData) => {
            /* On Notification Dashboard created_by_user is expected to be user_id 
               but in update API response we are getting user object */
            const formattedCampaign = { ...newCampaign };
            formattedCampaign.created_by_user =
              formattedCampaign.created_by_user.id;
            // ? updating the campaign in all pages in infinite query
            const newData = updateCampaignInInfiniteQuery(
              prevData,
              formattedCampaign,
              newCampaign.id,
            );

            return newData;
          },
        );
        if (options.onSuccess) {
          options.onSuccess(newCampaign);
        }
      },
    },
  );
};

export default useUpdateCampaign;
