import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

const useGetAvailableHeight = () => {
  const notificationRibbonDetails = useSelector(
    (state) => state.app.notificationRibbonDetails,
    isEqual,
  );

  const inPageRibbonState = useSelector(
    (state) => state.app.inPageRibbon,
    isEqual,
  );

  const sandboxRibbon = useSelector(
    (state) => state.app.sandboxRibbonDetails,
    isEqual,
  );

  return `100vh - ${notificationRibbonDetails?.height}px -  ${inPageRibbonState?.height}px - ${sandboxRibbon?.height}px`;
};

export default useGetAvailableHeight;
