import { useSelector } from "react-redux";
import {
  getActiveSubscriptionList,
  getPaidSubscriptionStatus,
} from "modules/Payments/PaymentSelectors";
import { ZOHO, SHOPIFY } from "constants/paymentGateway.json";
import useIsSandboxMode from "./useIsSandboxMode";
import useIsSignupSource from "./useIsSignupSource";
import useIsSignupSourceTally from "./useIsSignupSourceTally";
import useOrganizationDetails from "./Organization/useOrganizationDetails";
import useActivePlan from "./useActivePlan";
import useIsResellerSignupSource from "./useIsResellerSignupSource";
import useIsAdsPlanSignupSource from "./useIsAdsPlanSignupSource";

const useShowWalletFeature = () => {
  const isSandboxMode = useIsSandboxMode();
  const isHaveAnyPaidSubscription = useSelector(getPaidSubscriptionStatus);
  const hasSupportedPaymentGateway = useSelector((state) =>
    [ZOHO, SHOPIFY].includes(
      state?.app?.organizations[0]?.organization_id?.payment_gateway,
    ),
  );
  const isResellerSignupSource = useIsResellerSignupSource();
  const isAdsPlanSignupSource = useIsAdsPlanSignupSource();
  const activePlan = useActivePlan();

  const isResellerWithPlanNotStarted = !activePlan && isResellerSignupSource;
  const isAdsPlanNotStarted = isAdsPlanSignupSource && !activePlan;

  const pgMeteredSubscriptionBicId = useSelector(
    (state) =>
      getActiveSubscriptionList(state)?.[0]?.pg_metered_subscription_bic_id,
  );

  const isShopifyOrg = useIsSignupSource("Shopify");
  const isSignupSourceResellerOffline = useIsSignupSource("ResellerOffline");
  const isSignupSourceResellerWhiteLabeledOffline = useIsSignupSource(
    "ResellerWhiteLabeledOffline",
  );
  const isSignupSourceTally = useIsSignupSourceTally();

  const paymentGatewayCustomerID = useOrganizationDetails(
    (org) => org?.payment_gateway_customer_id,
  );

  if (isResellerWithPlanNotStarted || isAdsPlanNotStarted) {
    return false;
  }

  if (
    isSignupSourceResellerOffline ||
    isSignupSourceResellerWhiteLabeledOffline
  ) {
    return false;
  }

  if (
    isShopifyOrg &&
    (!paymentGatewayCustomerID || !pgMeteredSubscriptionBicId)
  ) {
    return false;
  }

  // Show Zoho Integration to Non-Haptik Orgs and orgs not in Sandbox Mode
  if (
    !isSandboxMode &&
    isHaveAnyPaidSubscription &&
    (isSignupSourceTally || hasSupportedPaymentGateway)
  ) {
    return true;
  }

  return false;
};

export default useShowWalletFeature;
