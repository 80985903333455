export const SET_SETTINGS_AND_MESSAGES = "SET_SETTINGS_AND_MESSAGES";
export const SET_ACTIVE_BLOCKER_TYPE = "SET_ACTIVE_BLOCKER_TYPE";
export const UPDATE_CURRENT_FLOW = "UPDATE_CURRENT_FLOW";
export const UPDATE_BOT_MESSAGE = "UPDATE_BOT_MESSAGE";
export const CHANGE_BOT_FLOW_STATUS = "CHANGE_BOT_FLOW_STATUS";
export const UPDATE_IS_FETCHING_BOT_FLOW = "UPDATE_IS_FETCHING_BOT_FLOW";
export const SET_ORG_BOT_ID = "SET_ORG_BOT_ID";
export const UPDATE_SET_LIVE_STATUS = "UPDATE_SET_LIVE_STATUS";
export const SET_SHOW_BOTFLOW_TEMPLATE_REJECTION_MODAL =
  "SET_SHOW_BOTFLOW_TEMPLATE_REJECTION_MODAL";
