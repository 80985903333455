// category
export const RETARGET = "Retarget";
export const ENGAGEMENT = "Engagement";

// Mixpanel
export const INT_CONCIERGE_ACTION_BUTTON_CLICK =
  "INT_CONCIERGE_ACTION_BUTTON_CLICK";
export const INT_CONCIERGE_MARK_AS_READ = "INT_CONCIERGE_MARK_AS_READ";
export const INT_CONCIERGE_MARK_ALL_AS_READ = "INT_CONCIERGE_MARK_ALL_AS_READ";
export const INT_CONCIERGE_MARK_ALL_AS_UNREAD =
  "INT_CONCIERGE_MARK_ALL_AS_UNREAD";
export const INT_CONCIERGE_DELETED = "INT_CONCIERGE_DELETED";
export const INT_CONCIERGE_TAKE_ME_TO_CAMPAIGN =
  "INT_CONCIERGE_TAKE_ME_TO_CAMPAIGN";
export const INT_CONCIERGE_CAMPAIGN_CREATED = "INT_CONCIERGE_CAMPAIGN_CREATED";
export const INT_CONCIERGE_SIDEBAR_ICON_CLICKED =
  "INT_CONCIERGE_SIDEBAR_ICON_CLICKED";
export const INT_CONCIERGE_SIDEBAR_CARD_CLICKED =
  "INT_CONCIERGE_SIDEBAR_CARD_CLICKED";
export const INT_CONCIERGE_NOTIFICATION_CARD_CLICKED =
  "INT_CONCIERGE_NOTIFICATION_CARD_CLICKED";
