import Modal from "./Modal";
import FullScreenMobileModal from "./FullScreenMobileModal";
import MiniModal from "./MiniModal";
import ModalHeader from "./ModalHeader";
import ModalHeaderV2 from "./ModalHeaderV2";
import ModalFooter from "./ModalFooter";
import ResponsiveModal from "./ResponsiveModal";

export default Modal;

export {
  ModalHeader,
  ModalHeaderV2,
  FullScreenMobileModal,
  MiniModal,
  ModalFooter,
  ResponsiveModal,
};
