import React, { Component } from "react";
import Button from "konnekt/Buttons";
import { Link } from "react-router-dom";
import withBrandName from "HOC/withBrandName";
import HideForNonInteraktDomain from "components/HideForNonInteraktDomain";

const NO_OF_CONTACTS = {
  999: "2000",
  2499: "2000",
};

class PaymentDetailSegment extends Component {
  handleGotoNextSlide = () => {
    this.props.handleMoveToCreditCardSlide();
  };

  render() {
    return (
      <div>
        <p className="kiwi-h5">
          {!!this.props.heading1
            ? this.props.heading1
            : `Start using ${this.props.brandName} by starting your subscription now.Click “Next” to continue.`}
        </p>
        <p className="kiwi-h5">
          Please enter your GST tax details on
          <Link
            to="/billing/org-details"
            target="_blank"
            style={{
              textDecorationLine: "underline",
              marginLeft: "5px",
              color: "#4589EF",
              cursor: "pointer",
            }}
          >
            this page
          </Link>{" "}
          first for tax purposes if required.
        </p>
        <div>
          <span className="kiwi-h4">Subscription Detail</span>
        </div>
        <div>
          <div className="row kiwi-h5">
            <div className="col-5">Starter Plan</div>
            <div className="col-7">
              <span>{`-   ${
                !!this.props.organization.subscription_plan_selected_on_sign_up
                  ? this.props.organization
                      .subscription_plan_selected_on_sign_up
                  : 999
              }/month`}</span>
              <span
                style={{
                  color: "#cdcdcd",
                  marginLeft: "10px",
                  fontSize: "12px",
                }}
              >
                + tax
              </span>
            </div>
            <div className="col-5">Number of monthly active contacts</div>
            <div className="col-7">
              <span>{`-   ${
                !!NO_OF_CONTACTS[
                  this.props.organization.subscription_plan_selected_on_sign_up
                ]
                  ? NO_OF_CONTACTS[
                      this.props.organization
                        .subscription_plan_selected_on_sign_up
                    ]
                  : 2000
              }`}</span>
              <HideForNonInteraktDomain>
                <span
                  className="kiwi-text4"
                  style={{
                    textDecorationLine: "underline",
                    color: "#4589EF",
                    marginLeft: "10px",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                >
                  <a
                    style={{ color: "#4589EF" }}
                    href="https://interakt.shop/pricing#monthlyactive"
                    target="_blank"
                  >
                    Know more
                  </a>
                </span>
              </HideForNonInteraktDomain>
            </div>
            <div className="col-5">WhatsApp Conversation Charges</div>
            <div className="col-7">
              <span>- On actuals</span>
              <HideForNonInteraktDomain>
                <span
                  className="kiwi-text4"
                  style={{
                    textDecorationLine: "underline",
                    color: "#4589EF",
                    marginLeft: "10px",
                    cursor: "pointer",
                    fontSize: "10px",
                  }}
                >
                  <a
                    style={{ color: "#4589EF" }}
                    href="https://interakt.shop/pricing#WhatsAppCharge"
                    target="_blank"
                  >
                    Know more
                  </a>
                </span>
              </HideForNonInteraktDomain>
            </div>
          </div>
        </div>

        {!!this.props.shouldShowFooter ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              padding: "15px",
              borderTop: "1px solid" + " #cdcdcd",
              margin: "10px -20px -15px" + " -20px",
              position: "absolute",
              bottom: "15px",
              width: "100%",
            }}
          >
            <div />
            <div className="text-right">
              <Button onClick={() => this.handleGotoNextSlide()}>Next</Button>
            </div>
          </div>
        ) : (
          <div className="text-right">
            <Button onClick={() => this.handleGotoNextSlide()}>Next</Button>
          </div>
        )}
      </div>
    );
  }
}

export default withBrandName(PaymentDetailSegment);
