import { ENV_PRODUCTION } from "constants/environmentTypes";

export const hideInProd = (component) =>
  process.env.REACT_APP_ENVIRONMENT !== ENV_PRODUCTION && component;

export const valueWithFallbackOnProd = (value, fallback) =>
  process.env.REACT_APP_ENVIRONMENT !== ENV_PRODUCTION ? value : fallback;

export const notInProd = process.env.REACT_APP_ENVIRONMENT !== ENV_PRODUCTION;

export const inLocalhost = window.location.hostname === "localhost";
