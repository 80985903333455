import { SET_SOCKET_STATUS } from "./SocketActions";

const initialValues = {
  // ? keeping true by default so that user does not see layout shift
  isSocketConnected: true,
};

// eslint-disable-next-line default-param-last
const socketReducer = (state = initialValues, action) => {
  switch (action.type) {
    case SET_SOCKET_STATUS: {
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default socketReducer;
