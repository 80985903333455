import fetch from "isomorphic-fetch";
import * as Sentry from "@sentry/react";
import sanitizeHtml from "sanitize-html";
import { showNotification, clearLocalStorage } from "./utility";

const PROTOCOL = window.location.protocol;
// const HOST = window.location.host;
// const API_URL = 'https://api.interakt.ai'; // old api
// const API_URL = 'https://app-kiwiapi-dev.azurewebsites.net'; // old api
const API_URL =
  process.env.REACT_APP_API_URL || "https://kiwicoreapidev.cawstudios.com"; // old api
// const API_URL = 'https://kiwicoreapidev.cawstudios.com'; // old api
// const API_URL = 'https://app-kiwi-test.azurewebsites.net'; // new api
// const API_URL = `${PROTOCOL}//${HOST}`;

export default function callApi(
  endpoint,
  method = "get",
  body,
  isErrorSuppressed = false,
) {
  let token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  let headers = {};
  headers["content-type"] = "application/json";
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      let responseStatus = parseInt(response.status);

      if (responseStatus === 401 && !endpoint.includes("login")) {
        clearLocalStorage();
        window.location = "/login";
      }

      if (responseStatus >= 400) {
        if (responseStatus !== 404 || responseStatus !== 405) {
          if (responseStatus < 500) {
            if (json.message) {
              if (!isErrorSuppressed) {
                showNotification(
                  "error",
                  sanitizeHtml(json.message, {
                    allowedTags: ["b", "i"],
                  }),
                );
              }
            } else if (
              json &&
              json.non_field_errors &&
              json.non_field_errors.length > 0
            ) {
              if (!isErrorSuppressed) {
                showNotification("error", json.non_field_errors[0]);
              }
            } else {
              if (!isErrorSuppressed) {
                showNotification("error", JSON.stringify(json));
              }
            }
          } else {
            if (!isErrorSuppressed) {
              showNotification("error", "Server Error, Please try again!");
            }
          }
        }
        Sentry.captureEvent(json);
      }
      if (!response.ok) {
        let data = { ...json, status_code: response.status };
        // return Promise.reject(json);
        return Promise.reject(data);
      }
      return { ...json, status_code: response.status };
    })
    .then(
      (response) => response,
      (error) => error,
    );
}

export const callApiWithoutToast = (endpoint, method = "get", body) => {
  let headers = {};
  headers["content-type"] = "application/json";
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      let responseStatus = parseInt(response.status);
      if (responseStatus >= 400) {
        Sentry.captureEvent(json);
      }
      if (!response.ok) {
        let data = { ...json, status_code: response.status };
        return Promise.reject(data);
      }
      return { ...json, status_code: response.status };
    })
    .then(
      (response) => response,
      (error) => error,
    );
};

export function callUploadApi(endpoint, method = "get", body) {
  let token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  let headers = {};
  if (token && token !== "") {
    headers.Authorization = `Token ${token}`;
    // headers.Authorization = `Token 339619eba96d49d03d5ba85724cd839250589f5f`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: body,
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      (response) => response,
      (error) => error,
    );
}
