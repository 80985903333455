import useOrganizationId from "customHooks/useOrganizationId";
import { getNetworkStatus } from "modules/App/AppSelectors";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import pahoMqtt from "paho-mqtt";
import useMqttAuthToken from "useApi/App/socket/useMqttAuthToken";
import {
  SOCKET_CONNECTION_STATE_CONNECTED,
  SOCKET_CONNECTION_STATE_DISCONNECTED,
  SOCKET_CONNECTION_STATE_DISCONNECTING,
} from "constants/socket";
import { setSocketStatus } from "modules/App/SocketActions";
import { isJson } from "util/jsonUtility";
import logger from "util/logger";
import { PROXY_MQTT_ORGANIZATIONS } from "config/proxyMqttOrganizations";
import useIsOrgAllowedToIgnoreOfflineBanner from "useApi/Notification/useIsOrgAllowedToIgnoreOfflineBanner";
import useSocketSync from "./useSocketSync";
import useSocketTopics from "./useSocketTopics";

const MAX_CONNECTION_TRIES = 10;
const MAX_SUBSCRIPTION_TRIES = 10;

const useSocketV2 = () => {
  const isOnline = useSelector(getNetworkStatus);
  const orgId = useOrganizationId();
  const dispatch = useDispatch();
  const { data: password } = useMqttAuthToken();
  const onSync = useSocketSync();
  const topics = useSocketTopics();
  const {
    data: isOrgAllowedToIgnoreOfflineBanner,
  } = useIsOrgAllowedToIgnoreOfflineBanner();

  const retryConnection = (
    disconnectCount = 0,
    failErr = null,
    resolve = null,
    reject = null,
  ) => {
    if (
      (window.socketInstance.connectionState ===
        SOCKET_CONNECTION_STATE_DISCONNECTED ||
        window.socketInstance.connectionState ===
          SOCKET_CONNECTION_STATE_DISCONNECTING) &&
      window.socketInstance
    ) {
      if (isOnline) {
        if (disconnectCount < MAX_CONNECTION_TRIES) {
          // eslint-disable-next-line no-use-before-define
          createConnection(disconnectCount, resolve, reject);
        } else {
          // eslint-disable-next-line no-console
          console.log(
            `Socket connection failed ${disconnectCount} time`,
            failErr,
          );
          // eslint-disable-next-line no-use-before-define
          stopConnection();
          if (reject) {
            reject();
          }
        }
      }
    }
  };

  function onSubscribeSuccess() {
    // eslint-disable-next-line no-console
    console.log(`Subscription successful to topics - ${topics.join("")}`);
    dispatch(setSocketStatus(true));
    window.socketInstance.connectionState = SOCKET_CONNECTION_STATE_CONNECTED;
  }

  const stopConnection = () => {
    if (
      window.socketInstance &&
      (window.socketInstance.connectionState !==
        SOCKET_CONNECTION_STATE_DISCONNECTED ||
        (window.socketInstance.connectionState !==
          SOCKET_CONNECTION_STATE_DISCONNECTING &&
          !isOrgAllowedToIgnoreOfflineBanner))
    ) {
      dispatch(setSocketStatus(false));
      try {
        window.socketInstance.disconnect();
      } catch (err) {
        // empty catch block
      }
      window.socketInstance.connectionState = SOCKET_CONNECTION_STATE_DISCONNECTED;
    }
  };

  function onSubscribeFailed(failErr) {
    // eslint-disable-next-line no-console
    console.log("Subscription Failed!!!", failErr);
    stopConnection();
  }

  const subscribeTopic = (
    topic,
    failedSubscribeCount = 0,
    failErr = null,
    resolve = null,
    reject = null,
  ) => {
    if (!window.socketInstance) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject("No socket instance found");
      return;
    }
    if (!topic) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject("No topic found");
      return;
    }
    try {
      const onSuccess = (data) => {
        // eslint-disable-next-line no-console
        console.log(`Subscribed to topic - ${topic}`);
        resolve(data);
      };
      if (failedSubscribeCount < MAX_SUBSCRIPTION_TRIES) {
        const subscribeOptions = {
          qos: 0, // QoS
          invocationContext: { foo: true }, // Passed to success / failure callback
          onSuccess,
          onFailure: (newFailErr) =>
            subscribeTopic(
              topic,
              failedSubscribeCount + 1,
              newFailErr,
              resolve,
              reject,
            ),
          timeout: 30,
        };
        window.socketInstance.subscribe(topic, subscribeOptions);
      } else {
        reject(failErr);
      }
    } catch (err) {
      subscribeTopic(topic, failedSubscribeCount + 1, err, resolve, reject);
    }
  };

  const onConnect = (resolve) => {
    // eslint-disable-next-line no-console
    console.log("Connection successful", "Subscribing to topic started");
    if (resolve) {
      resolve();
    }
  };

  const createConnection = (
    disconnectCount = 0,
    resolve = null,
    reject = null,
  ) => {
    if (
      window.socketInstance &&
      (window.socketInstance.connectionState ===
        SOCKET_CONNECTION_STATE_DISCONNECTED ||
        window.socketInstance.connectionState ===
          SOCKET_CONNECTION_STATE_DISCONNECTING)
    ) {
      if (!password) {
        // eslint-disable-next-line no-console
        console.log("No password found");
      } else {
        try {
          window.socketInstance.connect({
            onSuccess: () => onConnect(resolve),
            onFailure: (newFailErr) =>
              retryConnection(disconnectCount + 1, newFailErr, resolve, reject),
            userName: "",
            password,
          });
        } catch (err) {
          retryConnection(disconnectCount + 1, err, resolve, reject);
        }
      }
    }
  };

  const createConnectionPromise = () =>
    new Promise((resolve, reject) => {
      createConnection(0, resolve, reject);
    });

  const onMessageArrived = (message, updateOnboardingStatusForOrg) => {
    const jsonMessage = isJson(message.payloadString)
      ? JSON.parse(message.payloadString)
      : {};

    logger.log("socket message", jsonMessage);

    const data = jsonMessage?.arguments?.[0];

    if (!data) return;

    switch (jsonMessage.target) {
      case "ReceiveMessage":
      case "InstagramReceivedMessage": {
        if (window.downStreamQueue) {
          window.downStreamQueue.push(data);
        }
        break;
      }

      case "InstagramReceivedDeletedMessage": {
        if (window.downStreamQueue) {
          window.downStreamQueue.push({ ...data, type: "deleteMessage" });
        }
        break;
      }

      case "SyncData": {
        onSync(data);
        break;
      }

      case "ReceiveOrgBroadcastMessage": {
        if (data && data.onboardingStatus) {
          if (updateOnboardingStatusForOrg) {
            updateOnboardingStatusForOrg(data.onboardingStatus);
          }
        } else if (window.downStreamQueue && !data.errorMessage) {
          window.downStreamQueue.push(data);
        }
        break;
      }

      default: {
        break;
      }
    }
  };

  const subscribeAfterConnection = (updateOnboardingStatusForOrg) => {
    if (window.socketInstance) {
      window.socketInstance.onMessageArrived = (message) =>
        onMessageArrived(message, updateOnboardingStatusForOrg);
    }
  };

  const init = (updateOnboardingStatusForOrg, retryCount = 0) => {
    const mqttPort = PROXY_MQTT_ORGANIZATIONS.includes(orgId) ? 8088 : 8084;

    if (!window.socketInstance) {
      window.socketInstance = new pahoMqtt.Client(
        `wss://${process.env.REACT_APP_MQTT_SOCKET_HOST}:${mqttPort}/mqtt`,
        `int_web_${uuidv4()}`,
      );
      window.socketInstance.connectionState = SOCKET_CONNECTION_STATE_DISCONNECTED;
      window.socketInstance.onConnectionLost = () => {
        if (retryCount < 6) {
          // eslint-disable-next-line no-use-before-define
          initRetry(updateOnboardingStatusForOrg, retryCount);
        }
      };
    }
  };

  const subscribeTopicPromise = (topic) =>
    new Promise((resolve, reject) => {
      subscribeTopic(topic, 0, null, resolve, reject);
    });

  const initConnection = async (
    updateOnboardingStatusForOrg,
    retryCount = 0,
  ) => {
    try {
      if (orgId && password && !window.socketInstance && topics) {
        init(updateOnboardingStatusForOrg, retryCount);
        await createConnectionPromise();
        Promise.all(topics.map((topic) => subscribeTopicPromise(topic)))
          .then((data) => onSubscribeSuccess(data))
          .catch(onSubscribeFailed);
        // TODO: need to remove dependency on updateOnboardingStatusForOrg
        subscribeAfterConnection(updateOnboardingStatusForOrg);
      }
    } catch (err) {
      stopConnection();
    }
  };

  const initRetry = (updateOnboardingStatusForOrg, retryCount = 0) => {
    window.socketInstance = null;
    initConnection(updateOnboardingStatusForOrg, retryCount + 1);
  };

  return {
    initConnection,
    stopConnection,
    createConnection: createConnectionPromise,
    retryConnection: initRetry,
  };
};

export default useSocketV2;
