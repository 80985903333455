import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";

export const RQ_SHOPIFY_CUSTOMER_SYNC_STATUS =
  "RQ_SHOPIFY_CUSTOMER_SYNC_STATUS";

const fetchShopifyCustomerSyncStatus = async ({ orgId }) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/customers/sync-customers-from-shopify/`,
  });

  if (res.result) {
    return res.data;
  }

  throw new Error(res.message);
};

const useShopifyCustomerSyncStatus = (
  select = (data) => data,
  options = {},
) => {
  const orgId = useOrganizationId();

  return useQuery(
    [RQ_SHOPIFY_CUSTOMER_SYNC_STATUS, orgId],
    () =>
      fetchShopifyCustomerSyncStatus({
        orgId,
      }),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined ? !!orgId : !!orgId && !!options.enabled,
    },
  );
};

export const selectIsSyncInProgress = (data) => data?.sync_in_progress;
export const selectLastSyncAt = (data) => data?.last_sync_at;

export default useShopifyCustomerSyncStatus;
