import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ONBOARDED } from "constants/onBoardingStatus";
import {
  ALL,
  CLOSE,
  OPEN,
  SPAM,
  UNASSIGNED,
  YOU,
} from "constants/inboxfilterConstants";
import { getIsSandboxMode } from "modules/App/AppSelectors";
import callApi from "../../util/apiCaller";
import { fetchTrackerReadByChatIds } from "./TrackerActions";
import {
  EMPTY_MESSAGE_THREAD,
  selectActiveChat,
  selectActiveChatId,
  updateChatThreadsFromNewConversations,
} from "./ThreadActions";
import { CONVERSATION_CHAT_PAGE_SIZE } from "./constants";
import { getConversationMessage, getFileType } from "../../util/chatUtil";
import {
  formatConversationParam,
  getFormattedFilter,
  getUrlParams,
} from "./component/InboxFilter/utility";
import { parseJSON } from "util/jsonUtility";

// api actions
export const UPDATE_FETCH_ALL_INBOX_STATS_STATUS =
  "UPDATE_FETCH_ALL_INBOX_STATS_STATUS";
export const FETCHING_NEW_CONVERSATIONS = "FETCHING_NEW_CONVERSATIONS";
export const FETCH_NEW_CONVERSATIONS = "FETCH_NEW_CONVERSATIONS";
export const TOGGLE_FOR_NEW_CHANGE = "TOGGLE_FOR_NEW_CHANGE";
export const UPDATE_SINGLE_CONVERSATION = "UPDATE_SINGLE_CONVERSATION";
export const UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION =
  "UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION";
export const UPDATE_LAST_MESSAGE_IN_CONVERSATION =
  "UPDATE_LAST_MESSAGE_IN_CONVERSATION";
export const UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION =
  "UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION";
export const UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION =
  "UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION";
export const CLOSE_CHAT_IN_NEW_CONVERSATION = "CLOSE_CHAT_IN_NEW_CONVERSATION";
export const UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION =
  "UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION";
export const UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION =
  "UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION";
export const REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION =
  "REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION";
export const UPDATE_IS_UPDATING_NEW_CONVERSATION =
  "UPDATE_IS_UPDATING_NEW_CONVERSATION";
export const SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const SET_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "SET_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION";
export const CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION =
  "CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION";

export const UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL =
  "UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL";
export const TOGGLE_CONVERSATION_LABEL_DROPDOWN =
  "TOGGLE_CONVERSATION_LABEL_DROPDOWN";

export const UPDATE_CHAT_SEARCH_TEXT = "UPDATE_CHAT_SEARCH_TEXT";

export const REMOVE_CHAT_FROM_CONVERSATION = "REMOVE_CHAT_FROM_CONVERSATION";

export const RESET_FILTER = "RESET_FILTER";

export const FETCHING_CHAT_LIST_START = "FETCHING_CHAT_LIST_START";
export const FETCHING_CHAT_LIST_END = "FETCHING_CHAT_LIST_END";

export function getConversationParamUrl(filters) {
  const params = formatConversationParam(filters);

  return `v2/organizations/${filters.organizationId}/chats/${params}`;
}

const fetchingChatListStart = () => ({
  type: FETCHING_CHAT_LIST_START,
});

const fetchingChatListEnd = () => ({
  type: FETCHING_CHAT_LIST_END,
});

export function getConversations(orgDetails) {
  const urlFilter = parseJSON(getUrlParams(window.location.search, "filter"));
  const urlChatType = getUrlParams(window.location.search, "chatType");

  const filters = getFormattedFilter({
    ...urlFilter,
    ...orgDetails,
    chatType: urlChatType,
  });

  const uuid = uuidv4();
  return (dispatch, getState) => {
    if (filters.assigned === false && filters.selectedChatType === "Closed") {
      dispatch({
        type: FETCH_NEW_CONVERSATIONS,
        isFetchingConversations: false,
        isActiveChatLoaded: true,
        agentId: filters.agentId,
        chats: [],
        chatTotalCount: 0,
        offset: filters.offset,
        sortBy: filters.sortBy,
        isClosedFilter: filters.selectedChatType === "Closed",
      });
    } else {
      dispatch({
        type: FETCHING_NEW_CONVERSATIONS,
        isFetchingConversations: true,
        offset: filters.offset,
        uuid,
      });

      if (filters.offset === 0) {
        dispatch({
          type: EMPTY_MESSAGE_THREAD,
        });
        dispatch(clearNewMessageConversation());
        dispatch(selectActiveChat({}));
        dispatch(selectActiveChatId(""));
      }
      const isOrgOnboarded =
        getState().app?.organizations?.[0]?.organization_id
          ?.onboarding_status === ONBOARDED; // This can be used here as it comes from organization API and will be present beforehand

      const isSandboxMode = getIsSandboxMode(getState());
      if (
        filters.pageSize &&
        filters.channel &&
        (isOrgOnboarded || isSandboxMode)
      ) {
        const url = getConversationParamUrl(filters);

        dispatch(fetchingChatListStart());

        return callApi(`${url}`, "get")
          .then((res) => {
            dispatch(fetchingChatListEnd());
            if (res.results) {
              const chats = res.results.data;
              const chat_ids = chats.map((item) => item.id);
              const trackerFilters = {};
              trackerFilters.chat_ids = chat_ids;
              trackerFilters.organizationId = filters.organizationId;
              trackerFilters.agentId = filters.currentUserId;
              if (chat_ids && chat_ids.length > 0) {
                dispatch(fetchTrackerReadByChatIds(trackerFilters));
                dispatch(updateChatThreadsFromNewConversations(chats));
              }
              dispatch({
                type: FETCH_NEW_CONVERSATIONS,
                isFetchingConversations: false,
                isActiveChatLoaded: true,
                agentId: filters.agentId,
                chats: res.results.data ? res.results.data : [],
                offset: filters.offset,
                chatPageSize: filters.pageSize,
                chatTotalCount: res.count,
                sortBy: filters.sortBy,
                isClosedFilter: filters.selectedChatType === "Closed",
              });
            } else {
              dispatch({
                type: FETCHING_NEW_CONVERSATIONS,
                isFetchingConversations: false,
              });
            }
          })
          .catch(() => {
            dispatch(fetchingChatListEnd());
          });
      }
      dispatch({
        type: FETCHING_NEW_CONVERSATIONS,
        isFetchingConversations: false,
      });
    }
  };
}

export function onlyUpdateTotalCount(sort, chatsLength) {
  return sort === "Oldest" && chatsLength >= CONVERSATION_CHAT_PAGE_SIZE;
}

const isCorrectAllYouChatType = ({
  selectedFilter,
  incomingChat,
  activeUserId,
}) => {
  if (selectedFilter === ALL) return true;

  if (selectedFilter === YOU) {
    return incomingChat?.assigned_to_user_id === activeUserId;
  }

  if (selectedFilter === UNASSIGNED) {
    return !incomingChat?.assigned_to_user_id;
  }

  if (selectedFilter === SPAM) {
    return incomingChat?.is_spam;
  }

  return false;
};

const isCorrectChannelType = ({ activeChannel, incomingChannel }) => {
  return activeChannel?.includes(incomingChannel);
};

const isCorrectOpenCloseFilter = ({ activeFilter, incomingFilter }) => {
  return activeFilter === incomingFilter;
};

const isCorrectLabelFilter = ({ activeLabel, incomingLabel }) => {
  if (activeLabel === "all") return true;

  if (activeLabel === "no") return !incomingLabel;

  return activeLabel === incomingLabel;
};

const isCorrectAssignedFilter = ({ activeAssignee, incomingAssignee }) => {
  if (activeAssignee === "all") return true;

  if (activeAssignee === "unassigned") return !incomingAssignee;

  return activeAssignee === incomingAssignee;
};

export function shouldShowNewChatInView(
  chat,
  activeUserId,
  selectedFilter,
  selectedChatType,
  customFilters,
) {
  return (
    isCorrectAllYouChatType({
      selectedFilter,
      incomingChat: chat,
      activeUserId,
    }) &&
    isCorrectChannelType({
      activeChannel: customFilters?.channel,
      incomingChannel: chat?.channel_type,
    }) &&
    isCorrectOpenCloseFilter({
      activeFilter: selectedChatType,
      incomingFilter: chat?.is_closed ? CLOSE : OPEN,
    }) &&
    isCorrectLabelFilter({
      activeLabel: customFilters?.label,
      incomingLabel: chat?.conversation_label_id,
    }) &&
    isCorrectAssignedFilter({
      activeAssignee: customFilters?.assignee,
      incomingAssignee: chat?.assigned_to_user_id,
    })
  );
}

export function getMissingChat(
  filters,
  chatMessage,
  isFromChatReassignment = false,
  sendBrowserNotification,
) {
  return (dispatch, getState) =>
    callApi(
      `v2/organizations/${filters.organizationId}/chats/?channel_account_identifier=${filters.fromNumber}`,
      "get",
      undefined,
      true,
    ).then((res) => {
      if (res && res.result) {
        const chat_ids = [];
        const trackerFilters = {};
        trackerFilters.chat_ids = chat_ids;
        trackerFilters.organizationId = filters.organizationId;
        trackerFilters.agentId = filters.currentUserId;

        if (
          shouldShowNewChatInView(
            res.data,
            filters.currentUserId,
            filters.selectedFilter,
            filters.selectedChatType,
            filters.customFilters,
          )
        ) {
          const chats = [];
          chats.push(res.data);
          chat_ids.push(res.data.id);
          if (chat_ids && chat_ids.length > 0) {
            dispatch(fetchTrackerReadByChatIds(trackerFilters));
            dispatch(updateChatThreadsFromNewConversations(chats));
          }

          dispatch({
            type: UPDATE_SINGLE_CONVERSATION,
            chat: res.data,
            isFromChatReassignment,
            sortValue: getState()?.thread.selectedSort,
            onlyUpdateTotalCount: onlyUpdateTotalCount(
              getState()?.thread.selectedSort,
              getState()?.conversation.chats?.length,
            ),
          });
        }
        if (
          chatMessage.type == "Message" &&
          chatMessage.chat_message_type == "CustomerMessage"
        ) {
          handleBrowserNotification(
            res.data,
            filters.currentUserId,
            chatMessage,
            filters.isWindowBlurred,
            sendBrowserNotification,
          );
        }
      }
    });
}

export function handleBrowserNotification(
  chat,
  activeUserId,
  chatMessage,
  isWindowBlurred,
  sendBrowserNotification,
) {
  let message = "";
  let showNotification = isWindowBlurred;
  if (chatMessage.media_id || chatMessage.media_url) {
    message = _.startCase(
      getFileType(chatMessage.media_url, chatMessage.media_id),
    );
  } else {
    message = _.truncate(getConversationMessage(chatMessage));
  }
  let senderName = chatMessage.customer_name;
  if (!chat.assigned_to_user_id) {
    senderName = `New Customer ${chatMessage.customer_name} Says`;
  } else if (
    chat.assigned_to_user_id &&
    chat.assigned_to_user_id != activeUserId
  ) {
    showNotification = false;
  }

  if (showNotification && senderName && !chat?.is_spam) {
    sendBrowserNotification(senderName, message);
  }
}

export function updateLastMessageInNewChat(
  phone_number,
  event,
  currentUserId,
  selectedSort,
  isWindowBlurred,
  sendBrowserNotification,
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAST_MESSAGE_IN_CONVERSATION,
      phone_number,
      event,
      currentUserId,
      selectedSort,
      isWindowBlurred,
      sendBrowserNotification,
    });
  };
}

export function updateChatSentEventInNewChat(
  phone_number,
  event,
  currentUserId,
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION,
      phone_number,
      event,
      currentUserId,
    });
  };
}

export function updateChatOpenedInNewChat(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION,
      event,
    });
  };
}

export function updateChatClosedInNewChat(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION,
      event,
    });
  };
}

export function updateAgentChangeInNewConversation(
  event,
  showNotification,
  sendBrowserNotification,
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION,
      event,
      showNotification,
      sendBrowserNotification,
    });
  };
}

export function updateCustomerConversationLabel(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL,
      event,
    });
  };
}

export function updateAgentReplacedInNewConversation(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION,
      event,
    });
  };
}

export function removeAssignedChatInNewConversation(event) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION,
      event,
    });
  };
}

export function toggleForConversationList() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FOR_NEW_CHANGE,
    });
  };
}

export function setFetchingNewMessageInNewConversation(chat) {
  return (dispatch) => {
    dispatch({
      type: SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION,
      chat,
    });
  };
}

export function setNewMessageInNewConversation(
  chat,
  isChatExisting,
  isNewCustomerMessage = false,
) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_MESSAGE_IN_NEW_CONVERSATION,
      chat,
      isChatExisting,
      isNewCustomerMessage,
    });
  };
}

export function updateNewMessageInConversation(key, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION,
      key,
      value,
    });
  };
}

export function removeNewMessageInNewConversation(filters, isCancelled) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION,
      filters,
      isCancelled,
    });
  };
}

export function clearNewMessageConversation() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION,
    });
  };
}

export const toggleConversationlabelDropdown = (flag) => (dispatch) =>
  dispatch({
    type: TOGGLE_CONVERSATION_LABEL_DROPDOWN,
    payload: flag,
  });

export const updateChatSearchText = (searchedText) => ({
  type: UPDATE_CHAT_SEARCH_TEXT,
  searchedText,
});

export const removeChatFromConversation = (data) => ({
  type: REMOVE_CHAT_FROM_CONVERSATION,
  payload: data,
});

export const resetFilters = (payload) => ({
  type: RESET_FILTER,
  payload,
});
