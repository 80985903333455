import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {
  getConversationMessage,
  getCustomerNameOrNumber,
  getFileType,
  removeDuplicateChat,
  reverseSortArrayWithTimeStamp,
  sanitizeFromNumber,
  sortArrayWithTimeStampNewest,
} from "../../util/chatUtil";
import {
  FETCH_NEW_CONVERSATIONS,
  FETCHING_NEW_CONVERSATIONS,
  UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION,
  UPDATE_SINGLE_CONVERSATION,
  UPDATE_LAST_MESSAGE_IN_CONVERSATION,
  UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION,
  CLOSE_CHAT_IN_NEW_CONVERSATION,
  UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION,
  UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION,
  UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION,
  REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION,
  TOGGLE_FOR_NEW_CHANGE,
  UPDATE_IS_UPDATING_NEW_CONVERSATION,
  SET_NEW_MESSAGE_IN_NEW_CONVERSATION,
  REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION,
  shouldShowNewChatInView,
  CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION,
  UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION,
  SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION,
  UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL,
  TOGGLE_CONVERSATION_LABEL_DROPDOWN,
  REMOVE_CHAT_FROM_CONVERSATION,
  UPDATE_CHAT_SEARCH_TEXT,
  RESET_FILTER,
  FETCHING_CHAT_LIST_START,
  FETCHING_CHAT_LIST_END,
} from "./ConversationActions";
import {
  CLEAR_ACTIVE_CONVERSATION_LABEL,
  ASSIGNING_ACTIVE_CONVERSATION_LABEL,
  DELETE_MESSAGE_FROM_THREAD,
  UPDATE_CHAT_MABA_STATUS,
} from "modules/Inbox/ThreadActions";
import { CLOSE_SEARCH_SECTION } from "modules/Inbox/inboxStateTypes";

const removedChatBaseOnId = (chats, chatId) =>
  chats.filter((chat) => chat.id !== chatId);

const initialState = {
  isFetchingConversations: false,
  chats: [],
  offset: 0,
  chatTotalCount: 0,
  phoneThreads: {},
  isUpdatingConversationList: false,
  // Create seperate file for data
  views: {
    1: {
      id: "1",
      name: "All",
      agentId: null,
      selectedChatType: "Open",
      selectedSort: "Newest",
      presentChatIds: {},
      activePageNum: 1,
      activePageSize: 10,
      activeTotalCount: 0,
      closedPageNum: 1,
      closedPageSize: 10,
      closedTotalCount: 0,
      activeChats: [],
      closedChats: [],
    },
    2: {
      id: "2",
      name: "You",
      agentId: "", // on profile load need to set this value
      selectedChatType: "Open",
      selectedSort: "Newest",
      presentChatIds: {},
      activePageNum: 1,
      activePageSize: 10,
      activeTotalCount: 0,
      closedPageNum: 1,
      closedPageSize: 10,
      closedTotalCount: 0,
      activeChats: [],
      closedChats: [],
    },
    3: {
      id: "3",
      name: "Unassigned",
      agentId: null,
      selectedChatType: "Open",
      selectedSort: "Newest",
      presentChatIds: {},
      activePageNum: 1,
      activePageSize: 10,
      activeTotalCount: 0,
      closedPageNum: 1,
      closedPageSize: 10,
      closedTotalCount: 0,
      activeChats: [],
      closedChats: [],
    },
  },
  toggleForNewChange: false,
  loadNewChat: false,
  filteredChatAPIUUID: "",
  isFetchingNewMessageConversation: false,
  sendNewMessageConversation: {},
  afterSendNewMessageConversation: {},
  isSendingNewMessage: false,
  isSendingNewCustomerMessage: false,
  activeTooltip: null,
  conversationLabelDropDownVisiblity: false,
  customFilters: {
    assignee: "all",
    label: "all",
  },
  searchedText: "",
  multiSelectedChats: [],
  isFetchingConversationList: false,
};

const ConversationReducer = (state = initialState, action) => {
  let phoneThreads = {};
  let chats = [];
  let fromNumber = "";
  let newChats = [];
  let chatTotalCount = 0;
  let selectedChat = {};
  let messageType;
  let sendNewMessageConversation = {};
  let afterNewMessageConversation = {};
  let isSendingNewCustomerMessage = false;

  switch (action.type) {
    case UPDATE_SINGLE_CONVERSATION: {
      phoneThreads = cloneDeep(state.phoneThreads);
      afterNewMessageConversation = state.afterSendNewMessageConversation;
      chatTotalCount = state.chatTotalCount;
      let chatsCopy = cloneDeep(state.chats);
      if (action.chat) {
        let phoneNumber = sanitizeFromNumber(
          action.chat.customer_id.channel_phone_number,
        );
        if (!phoneThreads[phoneNumber]) {
          const newChat = action.chat;
          newChat.phone_number = phoneNumber;
          newChat.timestamp = new Date(newChat.last_activity_at_utc).getTime();
          if (!action.onlyUpdateTotalCount) {
            if (action.sortValue !== "Oldest") {
              chatsCopy = [newChat, ...chatsCopy];
            } else {
              chatsCopy = [...chatsCopy, newChat];
            }
          }

          chatTotalCount += 1;
          phoneThreads[phoneNumber] = 1;
        }
        if (
          action.isFromChatReassignment &&
          !_.isEmpty(afterNewMessageConversation)
        ) {
          chatsCopy = chatsCopy.map((chat) => {
            if (chat.id === action.chat.id) {
              chatsCopy.assigned_to_user_id = action.chat.assigned_to_user_id;
              afterNewMessageConversation = {};
            }
            return chatsCopy;
          });
        }
      }

      return {
        ...state,
        chats: chatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        afterSendNewMessageConversation: afterNewMessageConversation,
        toggleForNewChange: !state.toggleForNewChange,
        isSendingNewCustomerMessage: false,
        loadNewChat: chatsCopy.length === 1,
      };
    }
    case FETCHING_NEW_CONVERSATIONS:
      return {
        ...state,
        isFetchingConversations: action.isFetchingConversations,
        offset: action.offset,
        chats: action.offset === 0 ? [] : state.chats,
        loadNewChat: false,
      };

    case CLOSE_SEARCH_SECTION:
      return {
        ...state,
        searchResult: "",
        toggleForNewChange: !state.toggleForNewChange,
      };

    case FETCH_NEW_CONVERSATIONS:
      if (action.offset === 0) {
        phoneThreads = {};
      } else {
        phoneThreads = cloneDeep(state.phoneThreads);
      }
      action.chats.forEach((chat) => {
        let phoneNumber =
          sanitizeFromNumber(chat.customer_id?.channel_phone_number) ||
          chat.customer_id?.channel_account_identifier;
        if (!phoneThreads[phoneNumber]) {
          phoneThreads[phoneNumber] = 1;
        }
      });

      chats = cloneDeep(action.chats).map((item) => {
        if (item) {
          let phone_number =
            item?.customer_id?.channel_phone_number?.toString() ||
            item?.customer_id?.channel_account_identifier?.toString();
          item.phone_number = phone_number;
          item.timestamp = new Date(item.last_activity_at_utc).getTime();
        }
        return item;
      });
      newChats = cloneDeep(chats);

      if (action.offset !== 0) {
        newChats = [...state.chats, ...chats];
        if (action.sortBy == "asc") {
          newChats = reverseSortArrayWithTimeStamp(newChats);
        } else {
          newChats = sortArrayWithTimeStampNewest(newChats);
        }

        newChats = removeDuplicateChat(newChats);
      }
      let loadChatCondition = action.offset === 0 && newChats.length > 0;
      let parsedURL = new URLSearchParams(window.location.search);
      const queryParams = parsedURL.get("channelPhoneNumber"); //checking whether its inbox redirect
      if (queryParams) {
        loadChatCondition = action.offset === 0;
      }

      return {
        ...state,
        isFetchingConversations: action.isFetchingConversations,
        isActiveChatLoaded: action.isActiveChatLoaded,
        chats: newChats,
        loadNewChat: loadChatCondition,
        phoneThreads: phoneThreads,
        chatTotalCount: action.chatTotalCount,
        offset: action.offset,
      };

    case UPDATE_SENT_MESSAGE_FOR_NEW_CONVERSATION:
      chats = state.chats;
      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(action.event.to)
      ) {
        messageType = "AgentMessage";
        fromNumber = sanitizeFromNumber(action.event.from);
      } else {
        fromNumber = sanitizeFromNumber(action.event.to);
        messageType = "CustomerMessage";
      }
      newChats = chats.map((item) => {
        if (sanitizeFromNumber(item.phone_number) === fromNumber) {
          if (
            item.last_message &&
            _.toString(item.last_message.wc_id) ===
              _.toString(action.event.wc_id)
          ) {
            item.last_message.channel_message_id =
              action.event.channel_message_id;
            item.last_message.message_status = "Sent";
          }
          item.recieved_at_utc = new moment().toISOString();
          item.timestamp = new Date().getTime();
        }
        return item;
      });
      return {
        ...state,
        chats: newChats,
      };

    case UPDATE_LAST_MESSAGE_IN_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      let chatMessage = action.event;
      let isChatsModified = false;
      let message = "";
      let isCustomerMessage =
        chatMessage.chat_message_type === "CustomerMessage";
      let showNotification = isCustomerMessage && action.isWindowBlurred;
      sendNewMessageConversation = state.sendNewMessageConversation;
      if (chatMessage.media_id || chatMessage.media_url) {
        message = _.startCase(
          getFileType(chatMessage.media_url, chatMessage.media_id),
        );
      } else {
        message = _.truncate(getConversationMessage(chatMessage));
      }
      let senderName = chatMessage.customer_name;

      if (
        sanitizeFromNumber(action.phone_number) ===
        sanitizeFromNumber(chatMessage.from)
      ) {
        fromNumber = sanitizeFromNumber(chatMessage.to);
      } else {
        fromNumber = sanitizeFromNumber(chatMessage.from);
      }
      if (chatsCopy) {
        chatsCopy = chatsCopy.map((item) => {
          if (sanitizeFromNumber(item.phone_number) === fromNumber) {
            if (!item.assigned_to_user_id) {
              senderName = `New Customer ${chatMessage.customer_name} Says`;
            } else if (
              item.assigned_to_user_id &&
              item.assigned_to_user_id !== action.currentUserId
            ) {
              showNotification = false;
            }
            isChatsModified = true;
            item.last_message = chatMessage;
            if (isCustomerMessage)
              item.last_customer_message_at_utc = new moment().toISOString();
            item.recieved_at_utc = new moment().toISOString();
            item.timestamp = new Date().getTime();
          }
          return item;
        });
      }

      if (
        !_.isEmpty(sendNewMessageConversation) &&
        sanitizeFromNumber(sendNewMessageConversation.phone_number) ===
          fromNumber
      ) {
        sendNewMessageConversation.last_message = chatMessage;
        if (isCustomerMessage)
          sendNewMessageConversation.last_customer_message_at_utc = new moment().toISOString();
        sendNewMessageConversation.recieved_at_utc = new moment().toISOString();
        sendNewMessageConversation.timestamp = new Date().getTime();
      }

      if (showNotification && action.sendBrowserNotification) {
        action.sendBrowserNotification(senderName, message);
      }

      if (isChatsModified)
        if (action.selectedSort == "Newest") {
          chatsCopy = sortArrayWithTimeStampNewest(chatsCopy);
        } else {
          chatsCopy = reverseSortArrayWithTimeStamp(chatsCopy);
        }

      return {
        ...state,
        chats: chatsCopy,
        sendNewMessageConversation: sendNewMessageConversation,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }
    case UPDATE_CHAT_CLOSED_FOR_NEW_CONVERSATION: {
      const chatsCopy = cloneDeep(state.chats);
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      fromNumber = sanitizeFromNumber(action.event.from);
      const filteredChats = chatsCopy.filter((chat) => {
        if (
          fromNumber &&
          chat.phone_number &&
          chat.phone_number === fromNumber
        ) {
          delete phoneThreads[chat.phone_number];
          chatTotalCount--;
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: filteredChats,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case UPDATE_CHAT_OPENED_FOR_NEW_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      fromNumber = sanitizeFromNumber(action.event.from);
      chatsCopy = chatsCopy.filter((chat) => {
        if (
          chat.phone_number &&
          fromNumber &&
          chat.phone_number === fromNumber
        ) {
          delete phoneThreads[chat.phone_number];
          chatTotalCount--;
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: chatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case CLOSE_CHAT_IN_NEW_CONVERSATION: {
      const chatId = action.chatId;
      const chatsCopy = cloneDeep(state.chats);
      phoneThreads = state.phoneThreads;
      chatTotalCount = state.chatTotalCount;
      const newChatsCopy = chatsCopy.filter((item) => {
        if (_.isEqual(_.toString(item.id), _.toString(chatId))) {
          delete phoneThreads[item.phone_number];
          chatTotalCount--;
          return false;
        }
        return true;
      });

      return {
        ...state,
        chats: newChatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
        isUpdatingConversationList: false,
      };
    }

    case UPDATE_AGENT_CHANGE_IN_NEW_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      let customerName = "";
      chatsCopy = chatsCopy.map((item) => {
        if (item.id === action.event.chat_id) {
          item.assigned_to_user_id = action.event.agent_assigned_user_id;
          if (action.showNotification && item.customer_id) {
            customerName = getCustomerNameOrNumber(item.customer_id);
          }
        }
        return item;
      });
      if (
        action.showNotification &&
        customerName.length > 0 &&
        action.sendBrowserNotification
      ) {
        action.sendBrowserNotification(customerName, "Chat Assigned To You");
      }
      return {
        ...state,
        chats: chatsCopy,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case UPDATE_AGENT_REPLACED_IN_NEW_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      chatTotalCount = state.chatTotalCount;
      phoneThreads = state.phoneThreads;
      if (
        action.event &&
        action.event.chat_id &&
        action.event.agent_replaced_user_id
      ) {
        chatsCopy = chatsCopy.filter((item) => {
          if (
            item.id == action.event.chat_id &&
            item.assigned_to_user_id !== action.event.agent_replaced_user_id
          ) {
            chatTotalCount--;
            delete phoneThreads[item.phone_number];
            return false;
          } else {
            return true;
          }
        });
      }
      return {
        ...state,
        chats: chatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
        isUpdatingConversationList: false,
      };
    }

    case UPDATE_NEW_MESSAGE_CUSTOMER_CONVERSATION_FOR_LABEL: {
      let chatsCopy = cloneDeep(state.chats);
      if (action.event && action.event.chat_id) {
        chatsCopy = chatsCopy.filter((item) => {
          if (item.id == action.event.chat_id) {
            item.conversation_label_id = action.event.new_conversation_label_id;
          }
          return item;
        });
      }
      return {
        ...state,
        chats: chatsCopy,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case CLEAR_ACTIVE_CONVERSATION_LABEL: {
      const updatedChats = state.chats.filter((chat) => {
        if (chat.id === action.activeConversationId) {
          chat.conversation_label_id = null;
        }
        return chat;
      });
      return {
        ...state,
        chats: updatedChats,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case ASSIGNING_ACTIVE_CONVERSATION_LABEL: {
      const updatedChats = state.chats.filter((chat) => {
        if (chat.id === action.payload.activeConversationId) {
          chat.conversation_label_id = action.payload.conversationLabelId;
        }
        return chat;
      });
      return {
        ...state,
        chats: updatedChats,
        toggleForNewChange: !state.toggleForNewChange,
      };
    }

    case REMOVE_ASSIGNED_CHAT_IN_NEW_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      phoneThreads = state.phoneThreads;
      chatTotalCount = state.chatTotalCount;
      fromNumber = sanitizeFromNumber(action.event.from);
      chatsCopy = chatsCopy.filter((chat) => {
        if (
          chat.phone_number &&
          fromNumber &&
          fromNumber === chat.phone_number
        ) {
          chatTotalCount--;
          delete phoneThreads[fromNumber];
          return false;
        } else {
          return true;
        }
      });
      return {
        ...state,
        chats: chatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        toggleForNewChange: !state.toggleForNewChange,
        isUpdatingConversationList: action.value,
      };
    }
    case TOGGLE_FOR_NEW_CHANGE:
      return {
        ...state,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case UPDATE_IS_UPDATING_NEW_CONVERSATION:
      return {
        ...state,
        isUpdatingConversationList: action.value,
      };

    case SET_FETCHING_NEW_MESSAGE_IN_NEW_CONVERSATION:
      return {
        ...state,
        sendNewMessageConversation: action.chat,
        isFetchingNewMessageConversation: true,
      };

    case SET_NEW_MESSAGE_IN_NEW_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      phoneThreads = state.phoneThreads;

      selectedChat = action.chat;
      chatTotalCount = state.chatTotalCount;
      if (action.isChatExisting) {
        chatsCopy = chatsCopy.filter((chat) => {
          if (chat.id === action.chat.id) {
            selectedChat = chat;
            chatTotalCount--;
            return false;
          } else {
            return true;
          }
        });
      } else {
        fromNumber = sanitizeFromNumber(
          action.chat.customer_id.channel_phone_number,
        );
        if (!phoneThreads[fromNumber]) {
          phoneThreads[fromNumber] = 1;
        }
      }

      return {
        ...state,
        chats: chatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        isSendingNewCustomerMessage: action.isNewCustomerMessage,
        sendNewMessageConversation: selectedChat,
        afterSendNewMessageConversation: selectedChat,
        isSendingNewMessage: true,
        isFetchingNewMessageConversation: false,
      };
    }

    case REMOVE_NEW_MESSAGE_IN_NEW_CONVERSATION: {
      let chatsCopy = cloneDeep(state.chats);
      phoneThreads = state.phoneThreads;
      afterNewMessageConversation = state.afterSendNewMessageConversation;
      isSendingNewCustomerMessage = state.isSendingNewCustomerMessage;
      let newMessageChat = state.sendNewMessageConversation;
      chatTotalCount = state.chatTotalCount;
      const {
        activeUserId,
        selectedFilter,
        selectedChatType,
        selectedSort,
      } = action.filters;
      const showInCurrentView = shouldShowNewChatInView(
        newMessageChat,
        activeUserId,
        selectedFilter,
        selectedChatType,
      );

      if (showInCurrentView) {
        let isChatExisting = chatsCopy.find(
          (chat) => chat.id === newMessageChat.id,
        );
        if (!isChatExisting) {
          chatsCopy = chatsCopy.concat(newMessageChat);
          chatTotalCount++;
        }
        if (selectedSort == "Newest") {
          chatsCopy = sortArrayWithTimeStampNewest(chatsCopy);
        } else {
          chatsCopy = reverseSortArrayWithTimeStamp(chatsCopy);
        }
      } else {
        fromNumber = sanitizeFromNumber(
          newMessageChat?.customer_id?.channel_phone_number,
        );
      }
      if (action.isCancelled) {
        afterNewMessageConversation = {};
        isSendingNewCustomerMessage = false;
      }
      return {
        ...state,
        chats: chatsCopy,
        phoneThreads: phoneThreads,
        chatTotalCount: chatTotalCount,
        sendNewMessageConversation: {},
        isSendingNewMessage: false,
        afterSendNewMessageConversation: afterNewMessageConversation,
        isSendingNewCustomerMessage: isSendingNewCustomerMessage,
      };
    }

    case UPDATE_NEW_MESSAGE_IN_NEW_CONVERSATION:
      selectedChat = state.sendNewMessageConversation;
      selectedChat[action.key] = action.value;
      return {
        ...state,
        sendNewMessageConversation: selectedChat,
      };

    case CLEAR_NEW_MESSAGE_IN_NEW_CONVERSATION:
      return {
        ...state,
        sendNewMessageConversation: {},
        afterSendNewMessageConversation: {},
        isSendingNewMessage: false,
        isSendingNewCustomerMessage: false,
        isFetchingNewMessageConversation: false,
      };

    case TOGGLE_CONVERSATION_LABEL_DROPDOWN:
      return {
        ...state,
        // if payload then change to payload else toggle
        conversationLabelDropDownVisiblity:
          action.payload !== null
            ? action.payload
            : !state.conversationLabelDropDownVisiblity,
      };

    case UPDATE_CHAT_SEARCH_TEXT: {
      return {
        ...state,
        searchedText: action.searchedText,
      };
    }

    case REMOVE_CHAT_FROM_CONVERSATION:
      return {
        ...state,
        chats: removedChatBaseOnId(state.chats, action.payload.chat_id),
        chatTotalCount: state.chatTotalCount - 1,
        toggleForNewChange: !state.toggleForNewChange,
      };

    case RESET_FILTER:
      return {
        ...state,
        customFilters: {
          ...initialState.customFilters,
          channel: action.payload,
        },
      };

    case DELETE_MESSAGE_FROM_THREAD: {
      const updatedChats = state.chats.map((item) => {
        if (
          item?.last_message?.channel_message_id &&
          item?.last_message?.channel_message_id === action.payload?.id
        ) {
          return {
            ...item,
            last_message: { ...item.last_message, message: null },
          };
        }

        return item;
      });

      return { ...state, chats: updatedChats };
    }

    case UPDATE_CHAT_MABA_STATUS: {
      const updatedChats = state.chats.map((item) => {
        if (
          item?.customer_id?.channel_account_identifier ===
          action.payload?.channelIdentifier
        ) {
          return {
            ...item,
            maba_chat_status: action.payload.status,
          };
        }

        return item;
      });

      return {
        ...state,
        chats: updatedChats,
      };
    }

    case FETCHING_CHAT_LIST_START:
      return {
        ...state,
        isFetchingConversationList: true,
      };

    case FETCHING_CHAT_LIST_END:
      return {
        ...state,
        isFetchingConversationList: false,
      };

    default:
      return { ...state };
  }
};

export default ConversationReducer;
export const getIsFetchingConversations = (state) =>
  state.conversation.isFetchingConversations;

export const getNewChatFetchingState = (state) =>
  state.conversation.isFetchingConversations;
export const getNewChats = (state) => state.conversation.chats;
export const getNewChatPageTotalCount = (state) =>
  state.conversation.chatTotalCount;
export const getPhoneThreads = (state) => state.conversation.phoneThreads;
export const getNewToggleState = (state) =>
  state.conversation.toggleForNewChange;
export const getNewChatLoadState = (state) => state.conversation.loadNewChat;
export const getIsUpdatingNewConversation = (state) =>
  state.conversation.isUpdatingConversationList;
export const getIsFetchingNewMessageConversation = (state) =>
  state.conversation.isFetchingNewMessageConversation;
export const getNewMessageConversation = (state) =>
  state.conversation.sendNewMessageConversation;
export const getAfterNewMessageConversation = (state) =>
  state.conversation.afterSendNewMessageConversation;

export const getIsSendingNewMessage = (state) =>
  state.conversation.isSendingNewMessage;
export const getIsSendingNewCustomerMessage = (state) =>
  state.conversation.isSendingNewCustomerMessage;
export const getCustomFilters = (state) => state.conversation.customFilters;
export const getIsFetchingConversationList = (state) =>
  state.conversation.isFetchingConversationList;
