import React from "react";

const downloadReport = ({ fill, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1044_6572)">
        <path
          d="M11.6667 2.5V5.83333C11.6667 6.05435 11.7545 6.26631 11.9107 6.42259C12.067 6.57887 12.279 6.66667 12.5 6.66667H15.8333"
          stroke={fill}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1667 17.5H5.83334C5.39131 17.5 4.96739 17.3244 4.65483 17.0118C4.34227 16.6993 4.16667 16.2754 4.16667 15.8333V4.16667C4.16667 3.72464 4.34227 3.30072 4.65483 2.98816C4.96739 2.67559 5.39131 2.5 5.83334 2.5H11.6667L15.8333 6.66667V15.8333C15.8333 16.2754 15.6577 16.6993 15.3452 17.0118C15.0326 17.3244 14.6087 17.5 14.1667 17.5Z"
          stroke={fill}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 14.1667V9.16669"
          stroke={fill}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91667 12.0833L10 14.1666L12.0833 12.0833"
          stroke={fill}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1044_6572">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default downloadReport;
