import { useSelector } from "react-redux";
import { getOrganizationTraitByKeyName } from "modules/App/AppSelectors";

export type SignupSources =
  | "AdsPlan"
  | "Shopify"
  | "Traditional"
  | "Tally"
  | "ResellerOffline"
  | "ResellerWhiteLabeledOffline"
  | "ResellerOnline"
  | "ResellerWhiteLabeledOnline";

const useIsSignupSource = (signupSource: SignupSources) =>
  useSelector(
    (state) =>
      getOrganizationTraitByKeyName(state, "signup_source") === signupSource,
  );

export default useIsSignupSource;
