import useUrlQuery from "konnekt/hooks/useUrlQuery";
import useWhitelabeledDetails, {
  ApiBody,
} from "useApi/Partner/useWhitelabeledDetails";
import usePartnerDetails, {
  selectPartnerId,
} from "useApi/Partner/usePartnerDetails";
import { currentBasePath } from "util/domain";
import getCurrentDomainType, {
  GENERIC_DOMAIN,
  INTERAKT_DOMAIN,
  PARTNER_SPECIFIC_DOMAIN,
} from "util/getCurrentDomainType";

const interaktDetails = {
  privacy_policy_link: "https://www.interakt.shop/privacy-policy",
  partner_brand_name: "Interakt",
  terms_and_condition_link: "https://www.interakt.shop/terms-of-service",
  logo_url: "/images/logo.svg",
  logo_with_name_url: "/images/interakt-logo-large.svg",
};

const useCurrentWhitelabeledDetails = <T = ApiBody>(
  select?: (data: ApiBody) => T,
) => {
  const [query] = useUrlQuery();
  const domainType = getCurrentDomainType();
  const finalDomain =
    domainType === PARTNER_SPECIFIC_DOMAIN ? currentBasePath : null;
  const { data: partnerIdFromApi } = usePartnerDetails(selectPartnerId);
  const partnerId = query.get("source") || partnerIdFromApi;
  const finalPartnerId = domainType === GENERIC_DOMAIN ? partnerId : null;

  const { data, isLoading, isError } = useWhitelabeledDetails(
    {
      domain: finalDomain,
      partnerId: finalPartnerId,
    },
    select,
    {
      enabled: domainType !== INTERAKT_DOMAIN,
    },
  );

  let whitelabeledDetail = data;

  if (domainType === INTERAKT_DOMAIN) {
    if (select) {
      whitelabeledDetail = select(interaktDetails);
    } else {
      whitelabeledDetail = interaktDetails as T;
    }
  }

  return {
    data: whitelabeledDetail,
    isLoading:
      domainType === INTERAKT_DOMAIN ||
      (!finalDomain && !finalPartnerId) ||
      isError
        ? false
        : isLoading,
  };
};

export const selectPartnerIdForWhitelabeled = (data: ApiBody) =>
  data?.partner_id;

export default useCurrentWhitelabeledDetails;
