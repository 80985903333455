import { handleFbFileUrlUpload } from "util/uploadUtility";
import { CHANNEL_TYPE_RCS } from "components/ChannelTypeDropdown/constants";
import { getUrlParams } from "util/utility";
import { CarouselCardAPIParamsInterface } from "./templateUtility";

export async function getCarouselApiCardsWithUpdatedHeader(
  carouselCards: Array<CarouselCardAPIParamsInterface>,
  orgId: string,
): Promise<Array<CarouselCardAPIParamsInterface>> {
  if (Array.isArray(carouselCards) && carouselCards.length > 0) {
    const updatedCarouselCards = [];
    const selectedChannel = getUrlParams("channel_type");
    // eslint-disable-next-line no-restricted-syntax
    for (const card of carouselCards) {
      let updatedHeader;
      if (selectedChannel !== CHANNEL_TYPE_RCS) {
        // eslint-disable-next-line no-await-in-loop
        updatedHeader = await handleFbFileUrlUpload({
          fileUrlToUpload: card.header_handle_file_url,
          orgId,
        });
      }

      if (updatedHeader?.fileHandle && updatedHeader?.fileUrl) {
        updatedCarouselCards.push({
          ...card,
          header_handle: [updatedHeader.fileHandle],
          header_handle_file_url: updatedHeader.fileUrl,
        });
      }
      if (selectedChannel === CHANNEL_TYPE_RCS) {
        updatedCarouselCards.push(card);
      }
    }

    return updatedCarouselCards as Array<CarouselCardAPIParamsInterface>;
  }

  return carouselCards;
}
