import React from "react";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";

const ModalHeaderV2 = ({
  iconName,
  iconProps,
  headerText,
  subTitle,
  onHide,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="16px 24px"
      bg="#F3F5F9"
      borderRadius="8px 8px 0px 0px"
    >
      <Box
        display="flex"
        alignItems={subTitle ? "flex-start" : "center"}
        gridGap="8px"
      >
        {iconName && (
          <Icon iconName={iconName} color="#5A638C" {...iconProps} />
        )}
        <div>
          <Label
            fontSize="14px"
            fontWeight={600}
            lineHeight="20px"
            color="#0A0B10"
          >
            {headerText}
          </Label>
          {subTitle && (
            <Label
              fontSize="12px"
              fontWeight={600}
              lineHeight="16px"
              color="#5A638C"
              mt="4px"
            >
              {subTitle}
            </Label>
          )}
        </div>
      </Box>
      <Box display="flex" gridGap="8px" alignItems="center">
        <Box
          width="24px"
          height="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml="60px"
          bg="#fff"
          borderRadius="50%"
          onClick={onHide}
        >
          <Icon iconName="close" iconSize="2tiny" color="#1E212F" />
        </Box>
      </Box>
    </Box>
  );
};

export default ModalHeaderV2;
