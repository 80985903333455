import React from "react";

function diamondOutlined({ fill, ...props }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_242_9484)">
        <path
          d="M5 4.16699H15L17.5 8.33366L10.4167 16.2503C10.3624 16.3058 10.2975 16.3498 10.226 16.3799C10.1544 16.4099 10.0776 16.4254 10 16.4254C9.92239 16.4254 9.84557 16.4099 9.77402 16.3799C9.70247 16.3498 9.63765 16.3058 9.58333 16.2503L2.5 8.33366L5 4.16699Z"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33341 9.99967L6.66675 8.16634L7.16675 7.33301"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_242_9484">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default diamondOutlined;
