import React from "react";

export default function OnlyLoader(props) {
  return (
    <div className="text-center" data-cy="only-loader">
      <img
        width={props.size ? `${props.size}px` : "20px"}
        src={`https://interaktdevweb.z1.web.core.windows.net/assets/gifs/Rolling-1s-${
          props.size ? props.size : "20"
        }px.gif`}
        alt=""
      />
    </div>
  );
}
