import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import useShowWalletFeature from "customHooks/useShowWalletFeature";
import { callApiV2 } from "util/apiCaller";

export const RQ_WALLET_BALANCE = "RQ_WALLET_BALANCE";

const fetchWalletBalance = async ({ orgId }) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/payments/wallet/`,
  });
  if (res?.result) {
    return res.data;
  }
  throw new Error(res.message);
};

const useWalletBalance = (select = (data) => data, options = {}) => {
  const orgId = useOrganizationId();
  const showWalletFeature = useShowWalletFeature();

  return useQuery(
    [RQ_WALLET_BALANCE, orgId],
    () =>
      fetchWalletBalance({
        orgId,
      }),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined
          ? !!orgId && showWalletFeature
          : !!orgId && showWalletFeature && !!options.enabled,
    },
  );
};

// selectors
export const selectWalletBalance = (data) => data?.balance || 0;
export const selectWalletCurrency = (data) => data?.currency || "inr";
export const selectWalletId = (data) => data?.id;
export const selectWalletCreationDate = (data) => data?.created_at_utc;

export default useWalletBalance;
