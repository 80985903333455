import React from "react";
import styled, { useTheme } from "styled-components";
import PropTypes from "prop-types";
import { layout, system, space, border, position } from "styled-system";

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: ${(props) => props.aspectRatio || "unset"};

  ${system({
    objectFit: {
      property: "object-fit",
    },
    objectPosition: {
      property: "object-position",
    },
    cursor: {
      property: "cursor",
    },
    transform: {
      property: "transform",
    },
  })}

  ${layout};
  ${space};
  ${border};
  ${position};
`;

export default function Image({
  source,
  description,
  width,
  height,
  aspectRatio,
  ...props
}) {
  const theme = useTheme();
  return (
    <StyledImage
      {...props}
      srcSet={`${source[0]}, ${source[1]}`}
      sizes={`(max-width: ${theme.breakpoints.tablet}) 50vw, 100vw`}
      src={source[0]}
      alt={description}
      width={width}
      height={height}
      aspectRatio={aspectRatio}
      loading="lazy"
    />
  );
}

Image.propTypes = {
  source: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Image.defaultProps = {
  description: "Image description",
};
