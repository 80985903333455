import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";

export const RQ_PARTNER_DETAILS = "RQ_PARTNER_DETAILS";

interface PartnerMeta {
  phone: string;
  email: string;
}

// ? Add more fields as required
interface ResponseData {
  name: string;
  partner_meta: null | PartnerMeta;
  partner_payment_type: "Prepaid";
  id: string;
}

const fetchPartnerDetails = async (orgId: string) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/partner-details/`,
  });

  if (res?.result) {
    return res.data as ResponseData;
  }
  throw new Error(res.message);
};

type ApiBody = Awaited<ReturnType<typeof fetchPartnerDetails>>;

const usePartnerDetails = <T = ApiBody>(
  select?: (data: ApiBody) => T,
  options: UseQueryOptions<ApiBody, Error, T> = {},
) => {
  const orgId = useOrganizationId() as string;

  return useQuery<ApiBody, Error, T>(
    [RQ_PARTNER_DETAILS, orgId],
    () => fetchPartnerDetails(orgId),
    {
      select,
      ...options,
      enabled:
        options.enabled === undefined ? !!orgId : !!orgId && options.enabled,
    },
  );
};

export const selectPartnerName = (data: ApiBody) => data.name;
export const selectPartnerId = (data: ApiBody) => data.id;

export default usePartnerDetails;
