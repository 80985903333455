export const PAYMENT_METHOD_SHOPIFY = "Shopify";
export const PAYMENT_METHOD_STRIPE = "Stripe";

export const ADVANCED_PLAN_NAME = "Advanced";
export const GROWTH_PLAN_NAME = "Growth";
export const STARTER_PLAN_NAME = "Starter";
export const FREE_PLAN_NAME = "Free";
export const API_ONLY_PLAN_NAME = "APIOnly";
export const PRO_PLAN_NAME = "Pro";
export const LAUNCH_PLAN_NAME = "Launch";
export const FREE_TRIAL_PLAN_NAME = "Growth (free trial)";
export const ENTERPRISE_PLAN_NAME = "Enterprise";
export const HAPTIK_BOT_PLAN_NAME = "Haptik_Bot_Plan";
export const HAPTIK_INBOX_PLUS_ANALYTICS_PLAN_NAME =
  "Haptik_Inbox_Plus_Analytics_Plan";
export const THRIVE_TRIAL_PLAN = "Thrive_Trial_Plan";
export const THRIVE_BOT_PLAN = "Thrive_Bot_Plan";
export const THRIVE_INBOX_PLUS_ANALYTICS_PLAN =
  "Thrive_Inbox_Plus_Analytics_Plan";
export const LOWER_PLANS = [
  FREE_PLAN_NAME,
  FREE_TRIAL_PLAN_NAME,
  STARTER_PLAN_NAME,
];
export const ADS_PLAN_NAME = "Smart Ads";

export const RESELLER_SIGNUP_SOURCES = [
  "ResellerOffline",
  "ResellerWhiteLabeledOffline",
  "ResellerOnline",
  "ResellerWhiteLabeledOnline",
];

export const MONTHLY = "Monthly";
export const YEARLY = "Yearly";
export const QUARTERLY = "Quarterly";

export const SUBSCRIBE_TO_PLAN = "Subscribe to Plan";
export const INTERAKT_ENTERPRISE_INBOX_PLAN =
  "Enterprise Inbox Plan (PID = INT)";
export const INTERAKT_ENTERPRISE_BOT_PLAN = "Enterprise Bot Plan (PID = INT)";
export const HAPTIK_ENTERPRISE_INBOX_PLAN = "Enterprise Inbox Plan (PID = HAP)";
export const HAPTIK_ENTERPRISE_BOT_PLAN = "Enterprise Bot Plan (PID = HAP)";
export const HAPTIK_INBOX_PLAN = "Haptik_Inbox_Plan";
export const HAPTIK_TRIAL_PLAN = "Haptik_Trial_Plan";

export const TALLY_FREE_PLAN_NAME = "Tally Free Trail Plan"; // TODO: will be deprecated
export const TALLY_FREE_TRIAL_PLAN_NAME = "Tally Free Trial Plan";

export const CHANGE_PAYMENT_PLAN_PAGE_NAME = "CHANGE_PLAN";
export const CHOOSE_PAYMENT_PLAN_PAGE_NAME = "CHOOSE_PLAN";

export const BILLING_PAGE_ROUTE = "/billing/subscription";
export const CHANGE_PLAN_ROUTE = "/billing/modify-subscription";
export const CHOOSE_PLAN_ROUTE = "/signup/whatsapp?redirectToPayment=true";

export const FREE_PLAN_LIMIT = 400;

export const ENTERPRISE_PLANS_LIST = [
  HAPTIK_ENTERPRISE_INBOX_PLAN,
  HAPTIK_ENTERPRISE_BOT_PLAN,
  INTERAKT_ENTERPRISE_BOT_PLAN,
  INTERAKT_ENTERPRISE_INBOX_PLAN,
  HAPTIK_BOT_PLAN_NAME,
  HAPTIK_INBOX_PLAN,
  HAPTIK_INBOX_PLUS_ANALYTICS_PLAN_NAME,
  HAPTIK_TRIAL_PLAN,
  ENTERPRISE_PLAN_NAME,
];

export const HAPTIK_PLANS_LIST = [
  HAPTIK_ENTERPRISE_INBOX_PLAN,
  HAPTIK_ENTERPRISE_BOT_PLAN,
  INTERAKT_ENTERPRISE_BOT_PLAN,
  INTERAKT_ENTERPRISE_INBOX_PLAN,
  HAPTIK_BOT_PLAN_NAME,
  HAPTIK_INBOX_PLAN,
  HAPTIK_INBOX_PLUS_ANALYTICS_PLAN_NAME,
  HAPTIK_TRIAL_PLAN,
];
