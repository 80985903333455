import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import Box from "konnekt/Box";

// TODO: need to prop `when` which will take onHover / onClick with default as onHover
const Tooltip = ({
  children,
  place = "top",
  tooltip,
  tooltipClass,
  tooltipProps = {},
  ...props
}) => {
  const [uniqueTooltipId] = useState(`tooltip_${uuidv4()}`);

  return (
    <>
      <Box as="span" {...props} data-tip data-for={uniqueTooltipId}>
        {children}
      </Box>
      {tooltip && (
        <ReactTooltip
          id={uniqueTooltipId}
          className={`tooltip special-tooltip ${tooltipClass}`}
          multiline
          place={place}
          effect="solid"
          {...tooltipProps}
        >
          {tooltip}
        </ReactTooltip>
      )}
    </>
  );
};

export default Tooltip;
