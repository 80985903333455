import React, { Component } from "react";
import { connect } from "react-redux";
import async from "async";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import ReactTooltip from "react-tooltip";
import isEmpty from "lodash/isEmpty";
import round from "lodash/round";
import { showNotification } from "../../util/utility";
import callApi from "../../util/apiCaller";
import {
  getActiveSubscriptionList,
  getAttachPaymentMethodStatus,
  getPayment3dSecurePinUrl,
  getPaymentError,
  getPaymentProcessStatus,
  getPaymentProcessStatusMessage,
  getPaymentStatus,
} from "../../modules/Payments/PaymentSelectors";
import {
  getIfOrganizationHasEmbeddedSignupActive,
  getPaymentEnabledStatus,
  getPaymentModalStatus,
  getSelectedOrganizationId,
  getUserActiveOrganization,
} from "../../modules/App/AppSelectors";
import {
  attachPaymentMethod,
  toggleIsPaymentDoneRibbon,
  updatePaymantProcessingStatusMessage,
  updatePaymentDoneStatus,
  updatePaymentFailure,
  updatePaymentStatus,
  updatePaymentSuccess,
} from "../../modules/Payments/PaymentActions";
import OnlyLoader from "../Loaders/OnlyLoader";
import {
  handleTogglePaymentModal,
  updateIsEMandateStatusForOrg,
} from "../../modules/App/AppActions";
import PaymentDetailSegment from "../../modules/Signup/pages/SignUpWhatsapp/components/PaymentDetailSegment";
import BrandValue from "./BrandValue";
import CreditCardElements from "./CreditCardElements";
import WarningRibbon from "konnekt/WarningRibbon";
import Label from "konnekt/Label";
import Icon from "konnekt/Icon";
import Buttons from "konnekt/Buttons";
import Box from "konnekt/Box";
import { compose } from "redux";
import getOrgCountryHOC from "HOC/organization/getOrgCountryHOC";
// 4000002760003184
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);
let isMobile = false;

class StartSubscriptionPaymentPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: "",
      isGeneratingToken: false,
      isPaymentFailed: false,
      errorMessage: "Your payment has failed ! Please try again.",
      paymentStatusMessage: "",
      error: {
        isValidName: true,
        isValidCardNo: true,
        isValidExpiry: true,
        isValidCVC: true,
      },
      isShowSuccessMessage: false,
      isShowPaymentDetailSlide: false,
      isFormFilled: false,
    };
  }

  componentDidMount() {
    if (!!window) {
      isMobile = this.detectMob();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isProcessingPayment && !this.props.isProcessingPayment) {
      this.setState({
        isGeneratingToken: false,
      });
      if (this.props.isPaymentDone) {
        this.setState({
          isShowSuccessMessage: true,
        });
      }
    }

    if (
      !this.state.isShowPaymentDetailSlide &&
      prevProps.isOpenSubscriptionStartPaymentModal &&
      !this.props.isOpenSubscriptionStartPaymentModal
    ) {
      this.setState({
        isShowPaymentDetailSlide: true,
      });
    }
  }

  hideModal = () => {
    this.setState({
      errorMessage: "",
      isPaymentFailed: false,
    });
    this.props.dispatch(handleTogglePaymentModal(false));
  };

  handlePayment = (event, stripe, elements) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    this.setState({
      isShowSuccessMessage: false,
      isPaymentFailed: false,
    });
    const model = {};
    model.cardholderName = this.state.cardHolderName;
    this.handleChangePaymentStatus(true);
    this.props.dispatch(updatePaymentDoneStatus(false));
    this.handleChangePaymentStatusMessage("Securely authorizing your card");
    async.series(
      [
        (cb) => {
          if (!isEmpty(this.props.selectedPlan)) {
            callApi(
              `v1/organizations/${this.props.organizationId}/payments/stripe/start_subscription/`,
              "post",
              {
                subscription_plan_id: this.props.selectedPlan?.id,
              },
            ).then((res) => {
              if (res && res.result && res.data) {
                model.paymentCompleteData = res.data;
                model.subscription = res.data.subscription;
                model.invoice = res.data.invoice;
                model.payment_intent = res.data.payment_intent;
                model.client_secret =
                  model.payment_intent && model.payment_intent.client_secret
                    ? model.payment_intent.client_secret
                    : model.client_secret;
                model.isNeedDoubleValidation =
                  model.subscription.status == "incomplete" &&
                  model.invoice.status == "open" &&
                  model.payment_intent.status == "requires_confirmation";
                model.isPaymentSucceed =
                  model.subscription.status == "active" &&
                  model.invoice.status == "paid" &&
                  model.payment_intent.status == "succeeded";
                model.isGotErrorInPayment =
                  model.subscription.status == "incomplete" &&
                  model.invoice.status == "open" &&
                  model.payment_intent.status == "requires_payment_method";
                return cb();
              }
              this.setState({
                isPaymentFailed: true,
              });
              return cb(res.message);
            });
          } else {
            return cb("Error");
          }
        },
        (cb) => {
          setTimeout(() => {
            this.handleChangePaymentStatusMessage(
              "Confirming bank authorization",
            );
          }, 4000);
          this.confirmCardPayment(
            stripe,
            elements,
            model.client_secret,
            model.cardholderName,
            (err, res) => {
              if (err) {
                model.isPaymentSucceed = false;
                model.isGotErrorInPayment = true;
                this.setState({
                  isPaymentFailed: true,
                  errorMessage: err.message,
                });
                return cb(err.message);
              }
              model.isPaymentSucceed = res.status === "succeeded";
              model.isGotErrorInPayment = false;
              this.props.dispatch(updateIsEMandateStatusForOrg(true));
              this.handleChangePaymentStatusMessage(
                "Starting your subscription",
              );
              return cb();
            },
          );
        },
      ],
      (err) => {
        const data = {
          isProcessingPayment: false,
          isPaymentDone: model.isPaymentSucceed,
          paymentError: {},
          paymentProcessStatusMessages: "",
        };
        if (!!err || !model.isPaymentSucceed || model.isGotErrorInPayment) {
          this.setState({
            isPaymentFailed: true,
          });
          this.handlePaymentFailure(data);
          this.handleChangePaymentStatus(false);
        } else {
          this.handleChangePaymentStatusMessage("Starting your subscription");
          this.setState({
            isPaymentFailed: false,
          });
          this.handlePaymentSuccess(data);
          this.handleChangePaymentStatus(false);
          this.props.dispatch(toggleIsPaymentDoneRibbon(false));
        }
      },
    );
  };

  confirmCardPayment = (
    stripe,
    elements,
    clientSecret,
    cardholderName,
    next,
  ) => {
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardholderName,
          },
        },
      })
      .then((result) => {
        // Handle result.error or result.paymentIntent
        if (result.error) {
          return next(result.error);
        }
        return next(null, result.paymentIntent);
      });
  };

  handleSubmit = async (event, stripe, elements) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState(
      {
        isGeneratingToken: true,
      },
      () => {
        stripe
          .createToken(elements.getElement(CardNumberElement))
          .then((result) => {
            if (result && result.token && result.token.id) {
              this.handleCreatePaymentMethod(result.token.id);
            } else if (result && (!!!result.token || !!result.token.error)) {
              showNotification("warning", result.token.error.message);
            } else {
              showNotification("warning", "Some error occur");
            }
          });
      },
    );
  };

  getOptions = () => {
    const fontSize = window.innerWidth < 450 ? "16px" : "18px";
    return {
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  handleChangeName = (e) => {
    e.preventDefault();
    this.setState(
      {
        cardHolderName: e.target.value,
        isPaymentFailed: false,
        elementsStatus: {
          ...this.state.elementsStatus,
          cardHolderName: !!e.target.value,
        },
      },
      () => {
        if (Object.keys(this.state.elementsStatus).length === 4) {
          this.checkFormStatus();
        }
      },
    );
  };

  getTotalAmountToPay = () => {
    const { selectedPlan } = this.props;

    if (selectedPlan?.value && this.props.orgCountry === "IN") {
      return round(selectedPlan.value * 1.18, 2);
    }

    return selectedPlan?.value;
  };

  getButtonText = () => {
    const { selectedPlan } = this.props;
    return (
      <span>
        <span>{"Total Pay "}</span>
        <span>{` ${this.getTotalAmountToPay()} ${selectedPlan?.currency.toUpperCase()}`}</span>
      </span>
    );
  };

  handleCVVtext = () => {
    document.querySelectorAll('[name="cvc"]');
  };

  handleCreatePaymentMethod = (token) => {
    if (this.props.organizationId) {
      this.props.dispatch(
        attachPaymentMethod(this.props.organizationId, token),
      );
    }
  };

  handleChangePaymentStatus = (state) => {
    this.props.dispatch(updatePaymentStatus(state));
  };

  handleChangePaymentStatusMessage = (state, complete, elementType) => {
    if (elementType) {
      this.setState(
        {
          isPaymentFailed: !!state,
          elementsStatus: {
            ...this.state.elementsStatus,
            [elementType]: complete,
          },
        },
        () => {
          if (Object.keys(this.state.elementsStatus).length === 4) {
            this.checkFormStatus();
          }
        },
      );
    } else {
      this.setState({
        isPaymentFailed: !!state,
      });
    }
    this.props.dispatch(updatePaymantProcessingStatusMessage(state));
  };

  handlePaymentSuccess = (data) => {
    this.props.dispatch(updatePaymentSuccess(data));
  };

  handlePaymentFailure = (data) => {
    this.props.dispatch(updatePaymentFailure(data));
  };

  detectMob = () => !!window && window.innerWidth <= 768;

  handleMoveToCreditCardSlide = () => {
    this.setState({
      isShowPaymentDetailSlide: false,
      isShowSuccessMessage: false,
    });
  };

  checkFormStatus = () => {
    const elementsStatusList = Object.values(this.state.elementsStatus);

    function conjuction(prevStatus, currStatus) {
      return prevStatus && currStatus;
    }

    const isFormFilled = elementsStatusList.reduce(conjuction, true);
    this.setState({
      isFormFilled,
    });
  };

  render() {
    const options = this.getOptions();
    let segment;
    if (this.state.isShowPaymentDetailSlide) {
      segment = (
        <div style={{ padding: "15px 20px 15px 20px" }}>
          <PaymentDetailSegment
            heading1="You can now activate your monthly subscription to avoid paying manually every month."
            shouldShowFooter
            organization={this.props.organization}
            // handleToggleChoosePlanModal={props.handleToggleChoosePlanModal}
            handleMoveToCreditCardSlide={this.handleMoveToCreditCardSlide}
          />
        </div>
      );
    } else if (!this.state.isShowSuccessMessage) {
      segment = (
        <div
          className="full-width d-flex justify-content-center"
          style={{ height: "100%", minHeight: "400px" }}
        >
          {this.props.isProcessingPayment ? (
            <div
              className="d-flex flex-wrap align-items-center justify-content-center"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#cdcdcd",
                opacity: 0.8,
                zIndex: 1000,
                padding: "15px",
              }}
            >
              <div>
                <OnlyLoader />
                <div
                  className="text-center"
                  style={{
                    color: "#222222",
                    fontSize: "24px",
                    width: "100%",
                  }}
                >
                  {this.props.paymentProcessStatusMessages}
                </div>
              </div>
            </div>
          ) : null}
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <form
                  className="full-width d-flex flex-column justify-content-between align-items-center"
                  style={{
                    margin: 0,
                    paddingTop: "10px",
                    height: "inherit",
                    overflowY: "auto",
                  }}
                  onSubmit={(event) =>
                    this.handlePayment(event, stripe, elements)
                  }
                >
                  <div
                    className="full-width"
                    style={{ padding: "5px 15px", maxWidth: "455px" }}
                  >
                    <Box mb="15px" mt={{ _: 0, tablet: "15px" }}>
                      <WarningRibbon text="Don’t worry! Once you subscribe to this new eMandate-enabled subscription now, your current subscription will be immediately canceled, causing any extra usage to be invoiced immediately, and the remaining unused time being refunded in the upcoming renewal invoice of your new subscription." />
                    </Box>
                    <CreditCardElements
                      handleChangePaymentStatus={this.handleChangePaymentStatus}
                      handleChangeName={this.handleChangeName}
                      handleCVVtext={this.handleCVVtext}
                      options={options}
                    />
                  </div>

                  <div className="full-width desk-mobile-modal-margin">
                    <div className="d-flex justify-content-center">
                      <div style={{ maxWidth: "440px" }}>
                        {!!this.props.shouldHideBrand ? null : <BrandValue />}
                      </div>
                    </div>
                    <Box
                      className="desk-mobile-modal-footer"
                      position="absolute"
                      bottom="0"
                      width="100%"
                      bg="white"
                    >
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          borderTop: "1px solid #C4C4C4",
                          padding: "10px 20px",
                        }}
                      >
                        <div style={{ paddingRight: "15px" }}>
                          {this.state.isPaymentFailed ? (
                            <div>
                              <div className="d-flex full-width align-items-center error-message">
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7.37143 3.42857H8.62857V9.71429H7.37143V3.42857ZM8 13.1429C7.54286 13.1429 7.14286 12.7429 7.14286 12.2857C7.14286 11.8286 7.54286 11.4286 8 11.4286C8.45714 11.4286 8.85714 11.8286 8.85714 12.2857C8.85714 12.7429 8.45714 13.1429 8 13.1429Z"
                                      fill="#FD3A57"
                                    />
                                  </svg>
                                </span>
                                <span style={{ marginLeft: "7px" }}>
                                  {this.state.errorMessage}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <Box display="flex" alignItems="center">
                          <button
                            disabled={
                              this.props.isProcessingPayment ||
                              !stripe ||
                              this.props.isAttachingPaymentMethod
                            }
                            type="submit"
                            className="btn"
                            style={{
                              height: "50px",
                              fontWeight: "600",
                              borderRadius: "6px",
                              backgroundColor: "#278351",
                              color: "#fff",
                              minWidth: "fit-content",
                            }}
                          >
                            {this.props.isProcessingPayment ||
                            !stripe ||
                            this.props.isAttachingPaymentMethod
                              ? "Processing.."
                              : this.getButtonText()}
                          </button>
                          {this.props.orgCountry === "IN" && (
                            <Box ml="5px" data-tip data-for="plan">
                              <Icon iconSize="xs" iconName="tooltip" />
                              <ReactTooltip
                                className="special-tooltip align-tooltip"
                                id="plan"
                                place="top"
                                effect="solid"
                              >
                                <Box
                                  borderBottom="0.5px solid #C4C4C4"
                                  variant="text7"
                                >
                                  <Box p="8px 2px 0px">
                                    Plan amount: INR{" "}
                                    {this.props.selectedPlan?.value}
                                  </Box>
                                  <Box p="8px 2px">
                                    Tax amount: INR{" "}
                                    {this.props.selectedPlan?.value * 0.18}{" "}
                                    (18%)
                                  </Box>
                                </Box>
                                <Box p="8px 2px" variant="text7">
                                  Total amount : INR{" "}
                                  {round(
                                    this.props.selectedPlan?.value * 1.18,
                                    2,
                                  )}
                                </Box>
                              </ReactTooltip>
                            </Box>
                          )}
                        </Box>
                      </div>
                    </Box>
                  </div>
                </form>
              )}
            </ElementsConsumer>
          </Elements>
        </div>
      );
    } else if (this.state.isShowSuccessMessage) {
      segment = (
        <div
          className="d-flex full-width justify-content-center align-items-center"
          style={{ height: "400px", maxHeight: "100%" }}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            p="30px"
            mt={{ _: 0, tablet: "-50px" }}
          >
            <Icon iconName="confirmation" mb="10px" />
            <Label
              textAlign="center"
              text="Amazing! Your new eMandate-enabled subscription is active now. Your old subscription will end now, causing any extra usage to be invoiced immediately, and the remaining unused time being refunded in the upcoming renewal invoice of this new subscription. Happy Interakting!"
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="15px"
            borderTop="1px solid #cdcdcd"
            m="10px -20px -15px -20px"
            bottom="15px"
            width="100%"
            position="absolute"
          >
            <Box />
            <Box textAlign="right">
              <Buttons
                type="primary"
                onClick={() =>
                  this.props.dispatch(handleTogglePaymentModal(false))
                }
              >
                Continue using Interakt
              </Buttons>
            </Box>
          </Box>
        </div>
      );
    }
    return (
      <>
        {this.props.isOpenSubscriptionStartPaymentModal ? (
          <div className="desk-mobile-modal-parent">
            <div
              className="desk-mobile-modal-background"
              onClick={this.hideModal}
            />
            <div className="desk-mobile-modal">
              <div className="desk-mobile-modal-header">
                <Box />
                <div className="d-flex align-items-center">
                  <span className="kiwi-h2" style={{ marginLeft: "20px" }}>
                    {!this.state.isShowSuccessMessage
                      ? "Start a new subscription"
                      : "Payment Successful"}
                  </span>
                </div>
                <div style={{ cursor: "pointer" }} onClick={this.hideModal}>
                  <svg
                    className="desk-mobile-modal-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path d="M1.93103 0L0 1.93103L5.07433 7L0.00268131 12.069L1.93372 14L7 8.92701L12.069 13.996L14 12.069L8.92701 7L13.996 1.93103L12.069 0L7 5.07433L1.93103 0.00268131V0Z" />
                  </svg>
                </div>
              </div>
              <div
                className="desk-mobile-modal-body"
                style={{ minHeight: "380px" }}
              >
                {segment}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    organizationId: getSelectedOrganizationId(state),
    organization: getUserActiveOrganization(state),
    is_embedded_signup_enabled: getIfOrganizationHasEmbeddedSignupActive(state),
    isProcessingPayment: getPaymentProcessStatus(state),
    paymentProcessStatusMessages: getPaymentProcessStatusMessage(state),
    paymentError: getPaymentError(state),
    is_payment_enabled: getPaymentEnabledStatus(state),
    payment3dSecurePinUrl: getPayment3dSecurePinUrl(state),
    isPaymentDone: getPaymentStatus(state),
    isAttachingPaymentMethod: getAttachPaymentMethodStatus(state),
    isOpenSubscriptionStartPaymentModal: getPaymentModalStatus(state),
    selectedPlan: getActiveSubscriptionList(state)?.[0]?.subscription,
    // isOpenSubscriptionStartPaymentModal:true
  };
}

export default compose(
  getOrgCountryHOC,
  connect(mapStateToProps),
)(StartSubscriptionPaymentPopup);
