/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from "react";
import async from "async";
import { compose } from "redux";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encryptString } from "util/encryptionUtil";
import { ThemeProvider } from "styled-components";
import "./css/app/app.css";
import "./css/SideBar/Sidebar.css";
import "./css/Widget/CustomizeWidget.css";
import "./css/Widget/ColorPicker.css";
import "./css/Widget/ChatWidget.css";
import "./css/Widget/InstallWidget.css";
import "./css/BottomBar/BottomBar.css";
import "./css/Billing/Credits.css";
import "./css/inbox/components.css";
import "./css/inbox/inbox.css";
import "./css/inbox/inbox-2.css";
import "./css/Customer/Customer.css";
import "./css/Template/Template.css";
import "./css/Settings/Setting.css";
import "./css/VirtualList.css";
import "./css/Payments/Payments.css";
import "./css/Notification/Notification.css";
import "./css/Stripe.css";
import "modules/Settings/pages/SettingsSignupBusiness/SignUpBusiness.css";
import "modules/Signup/pages/SignUpWhatsapp/SignUpWhatsapp.css";
import "./css/font-family-v2.css";
import "./css/Loader.css";

import * as Sentry from "@sentry/react";
import JSON5 from "json5";

import TagManager from "react-gtm-module";

import logger from "util/logger";
import callApi from "util/apiCaller";
import publicApiCaller from "util/publicApiCaller";
import { Mixpanel, trackEvent } from "util/tracking";
import { getIfOrganizationIsOnboarded } from "util/appUtil";
import closeIfIsPopupWindow from "util/closeIfIsPopupWindow";
import {
  showNotification,
  clearLocalStorage,
  showCustomNotification,
} from "util/utility";

import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";

import WithFavicon from "HOC/WithFavicon";
import withPolyfill from "HOC/withPolyfill";
import withRefinerInit from "HOC/withRefinerInit";
import withInitApiCalls from "HOC/withInitApiCalls";
import withUseSignupSourceTally from "HOC/withUseSignupSourceTally";
import withShowCoexistenceFeature from "HOC/withShowCoexistenceFeature";
import withUseGetNotificationRibbonHeight from "HOC/organization/withUseGetNotificationRibbonHeight";

import BottomBar from "components/BottomBar";
import Loader from "components/Loaders/OnlyLoader";
import BasicLoader from "components/Loaders/BasicLoader";
import MegaSidebar from "components/SideBar/MegaSidebar";
import componentLoader from "components/componentLoader";
import NotificationRibbon from "components/NotificationRibbon";
import SandboxNotificationRibbon from "components/SandboxNotificationRibbon";

import { setNotificationRibbon } from "modules/Inbox/ThreadActions";
import {
  ATTEMPT_TO_LOGIN,
  FETCHING_FB_EMBEDDED_DATA,
  fetchNewBundledData,
  FETCH_USER_ORG,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  toggleCreateAccountSegment,
  VERIFY_SUCCESS,
  fetchOrganizationDetailsForUser,
  setESErrorPopupVisibility,
} from "modules/App/AppActions";
import { getIsHaptikUser } from "modules/App/HaptikReducer";
import {
  fetchPaymentInvoiceList,
  splitSubscriptionsIntoPlanTypes,
  trackFBSignupResponse,
} from "modules/Payments/PaymentActions";
import {
  FETCH_SUBSCRIPTION,
  SUBSCRIPTION_FETCH_STATUS,
} from "modules/Payments/PaymentTypes";
import ProtectedPageWrapper from "modules/App/components/ProtectedPageWrapper";
import {
  AGENT_PERFORMANCE,
  ANALYTICS_OVERVIEW,
  BILLING_DETAILS,
  CATALOG,
  CHAT_ASSIGNMENT,
  COMMERCE_SETTING,
  CTWA,
  CUSTOMER_AUTO_REPLIES,
  INBOX,
  CAMPAIGN,
  INBOX_SETTING,
  INTEGRATIONS,
  ORDER_PANEL,
  TRACK_EXPENSES,
  USERS,
  VIEW_INSIGHTS,
  WALLET_MAIN_PAGE,
  WIDGET,
  WORKFLOWS,
  MEMBER_PROFILE,
  QUICK_REPLIES,
  TEAM_SETTINGS,
  DEVELOPER_SETTINGS,
  ACCESS_PERMISSIONS,
  MANAGE_WHATSAPP_PROFILE,
  TEMPLATES,
  AUTOCHECKOUT_WORKFLOW,
  COD_TO_PREPAID,
} from "modules/Payments/vanishFeatureConstant";

import { ONBOARDED } from "constants/onBoardingStatus";
import { PAGE_LOAD_ATTEMPT } from "constants/enums/appEnums";
import industryMapping from "constants/industry-mapping.json";
import { PROM_EMBEDDED_SIGNUP } from "constants/mixPanel/prom";

import ProtectRoute from "routes/protectRoute";
import AntiProtectedRoute from "routes/AntiProtectedRoute";

import theme from "theme";
import packageJson from "meta.json";
import storageService from "services/storageService";
import withUseOrgChannelList from "useApi/organization/withUseOrgChannelAccount";
import { INT_WEB_LOGIN } from "constants/mixPanel/login";
import { INT_WEB_EMBEDDED_SIGNUP_CONNECT_TO_FB_CTA } from "constants/mixPanel/embeddedSignup";
import {
  INTERAKT_WEB_EMBEDDED_SIGNUP,
  MIX_PANEL_LINK_CLICK_ACTION,
} from "constants/mixPanel/common";
import { cachedFetchProfile } from "useApi/App/useUser";
import BusinessVerificationFailedModal from "modules/OnboardingDashboard/components/BusinessVerificationFailedModal";
import { getBusinessFailedVerificatioModalState } from "modules/App/AppSelectors";
import withUseSignupSourceAdsPlan from "HOC/withUseSignupSourceAdsPlan";

const SupportBot = React.lazy(() =>
  componentLoader(() => import("components/SupportBot"), PAGE_LOAD_ATTEMPT),
);

const LoginPageV2 = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/LoginPage/LoginPageV2"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const RedirectPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/RedirectPage/RedirectPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const SignUpDemo = React.lazy(() =>
  componentLoader(
    () => import("./modules/Signup/pages/SignUpDemo/SignUpDemo"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const SignUpWhatsapp = React.lazy(() =>
  componentLoader(
    () => import("./modules/Signup/pages/SignUpWhatsapp/SignUpWhatsapp"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const CustomizeWidgetPage = React.lazy(() =>
  componentLoader(
    () =>
      import("./modules/Widget/pages/CustomizeWidgetPage/CustomizeWidgetPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const WhatsappWidget = React.lazy(() =>
  componentLoader(
    () => import("./modules/WhatsappWidget/Pages/WhatsappWidgetPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const PartnerDashboard = React.lazy(() =>
  componentLoader(
    () => import("./modules/PartnerDashboard/PartnerDashboard"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ToggleRqDevTools = React.lazy(() =>
  componentLoader(
    () => import("./modules/DevTools/pages/ToggleRqDevTools"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const CommerceSettings = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/CommerceSettings/pages/CommerceSettingsPage/CommerceSettingsPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const OrderPanel = React.lazy(() =>
  componentLoader(
    () => import("./modules/CommerceSettings/pages/OrderPanel/OrderPanel"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const Catalog = React.lazy(() =>
  componentLoader(
    () => import("./modules/CommerceSettings/pages/Catalog/Catalog"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const CodToPrepaid = React.lazy(() =>
  componentLoader(
    () => import("./modules/CommerceSettings/pages/CodToPrepaid/CodToPrepaid"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AppOauth = React.lazy(() =>
  componentLoader(
    () => import("./modules/Integrations/pages/AppOauth"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const CustomerListPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Customer/pages/CustomerListPage/CustomerListPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const OnboardingPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/OnboardingDashboard/pages/Onboarding"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const PaymentsPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/PaymentsV2/PaymentsLayout"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const BulkUploadV2CustomersPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Customer/pages/BulkUploadCustomersPage/BulkUploadV2CustomersPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const CreateCampaignPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Notification/pages/CreateCampaignPage/CreateCampaignPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ChooseSampleCampaignsMobile = React.lazy(() =>
  componentLoader(
    () =>
      import("./modules/Notification/components/ChooseSampleCampaignsMobile"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const CustomerDashboardPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Customer/pages/CustomerDashboard/CustomerDashboardPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const InstallWidgetPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Widget/pages/InstallWidget/InstallWidget"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const CreateAccountPageV2 = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/CreateAccount/CreateAccountV2"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ExternalSignupPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/ExternalSignup/ExternalSignup"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ShopifyCreateAccountPage = React.lazy(() =>
  componentLoader(
    () =>
      import("./modules/App/pages/ShopifyCreateAccount/ShopifyCreateAccount"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ResetPassword = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/ForgotPassword/ResetPassword"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const AutoLoginPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/LoginPage/AutoLoginPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const AutoLogoutPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/AutoLogoutPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const InboxPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Inbox/pages/InboxPage/InboxPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const CampaignDetailPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Notification/pages/CampaignDetailPage/CampaignDetailPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const MainSettingPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Settings/pages/SettingQuickReply/MainSettingPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const MobileSettingPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Settings/Components/MobileRedirectPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AppPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/App/pages/AppPage/AppPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const TeamSetting = React.lazy(() =>
  componentLoader(
    () => import("./modules/Settings/pages/SettingTeamSettingPage/TeamSetting"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ManagePermissionsPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Settings/pages/ManagePermissions/ManagePermissionsPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const DeveloperSetting = React.lazy(() =>
  componentLoader(
    () =>
      import("./modules/Settings/pages/SettingsDeveloperPage/DeveloperSetting"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ProfileSetting = React.lazy(() =>
  componentLoader(
    () => import("./modules/Settings/pages/SettingProfilePage/ProfileSetting"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ManageTags = React.lazy(() =>
  componentLoader(
    () => import("modules/Settings/pages/ManageTagsLabelsAndTraits/ManageTags"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const TemplateListPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Templates/pages/TemplateListPage/TemplateListPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const CreateTemplatePage = React.lazy(() =>
  componentLoader(
    () =>
      import("./modules/Templates/pages/CreateTemplatePage/CreateTemplatePage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const NotificationDashboardPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Notification/pages/NotificationDashboard/NotificationDashboardPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const OrganisationalDetails = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Payments/Pages/OrganisationalDetail/OrganisationalDetails"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const UsageHistory = React.lazy(() =>
  componentLoader(
    () => import("./modules/Payments/Pages/UsageHistory/UsageHistory"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const Credits = React.lazy(() =>
  componentLoader(
    () => import("./modules/Payments/Pages/Credits/Credits"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const InvoiceHistory = React.lazy(() =>
  componentLoader(
    () => import("./modules/Payments/Pages/InvoiceHistory/InvoiceHistory"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const SubscriptionDetail = React.lazy(() =>
  componentLoader(
    () => import("./modules/Payments/Pages/Subscription/SubscriptionDetail"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const InboxSettingsPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/Settings/pages/InboxSetting/InboxSettingsPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
// ? Bot text was converted to Workflow later. These changes have been made in UI side and route side but in code it is still same
const WorkFlow = React.lazy(() =>
  componentLoader(
    () => import("./modules/BotFlows/pages/BotFlow/BotFlow"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const PlanChangeConfirmationPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Payments/Pages/PlanChangeConfirmationPage/PlanChangeConfirmationPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ModifySubscriptionPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Payments/Pages/ModifySubscriptionPage/ModifySubscriptionPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const SettingsSignupBusiness = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Settings/pages/SettingsSignupBusiness/SettingsSignupBusiness"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const NewFBSignUpWhatsapp = React.lazy(() =>
  componentLoader(
    () => import("./modules/Signup/pages/SignUpWhatsapp/NewFBSignUpWhatsapp"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const ExternalEmbeddedSignupPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "./modules/Signup/pages/ExternalEmbeddedSignupPage/ExternalEmbeddedSignupPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const OrderDetailsPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/COD/pages/OrderDetailsPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const OrderConfirmationPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/COD/pages/OrderConfirmationPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const OrderCancelledPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/COD/pages/OrderCancelledPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const OrderLinkExpiredPage = React.lazy(() =>
  componentLoader(
    () => import("./modules/COD/pages/OrderLinkExpiredPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const Order404Page = React.lazy(() =>
  componentLoader(
    () => import("./modules/COD/pages/Order404Page"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const ImageViewer = React.lazy(() =>
  componentLoader(() => import("modules/ImageViewerModule"), PAGE_LOAD_ATTEMPT),
);

const MediaPreview = React.lazy(() =>
  componentLoader(
    () => import("modules/MediaPreviewModule"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AddBusiness = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "modules/Payments/Pages/OrganisationalDetail/Components/AddBusiness"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AutomationPage = React.lazy(() =>
  componentLoader(
    () => import("modules/Automation/Automation"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const WorkflowCreateEditPage = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "modules/Workflows/pages/WorkflowCreateEditPage/WorkflowCreateEditPage"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const Integrations = React.lazy(() =>
  componentLoader(
    () => import("modules/Integrations/Integrations"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const ThirdPartyIntegration = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "modules/ThirdPartyIntegration/pages/ThirdPartyIntegration/ThirdPartyIntegration"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const ShopifyRegister = React.lazy(() =>
  componentLoader(
    () =>
      import("modules/ShopifyRegister/pages/ShopifyRegister/ShopifyRegister"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const ShopifyLink = React.lazy(() =>
  componentLoader(
    () => import("modules/ShopifyRegister/pages/ShopifyLink/ShopifyLink"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AdPerFormanceIndividual = React.lazy(() =>
  componentLoader(
    () => import("modules/Analytics/pages/AdPerformanceIndiviual"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const OverviewPage = React.lazy(() =>
  componentLoader(
    () => import("modules/Analytics/pages/OverviewPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const AgentPerformance = React.lazy(() =>
  componentLoader(
    () => import("modules/Analytics/pages/AgentPerformance/AgentPerformance"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const AdPerformance = React.lazy(() =>
  componentLoader(
    () => import("modules/Analytics/pages/AdPerformance"),
    PAGE_LOAD_ATTEMPT,
  ),
);
const ReportsPage = React.lazy(() =>
  componentLoader(
    () => import("modules/Analytics/pages/Reports"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const CheckoutLinkTracking = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "modules/Analytics/pages/CheckoutLinkTracking/CheckoutLinkTracking"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AccountDetails = React.lazy(() =>
  componentLoader(
    () => import("modules/AccountDetails/pages/AccountDetails"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const RedirectionSubscriptionDetails = React.lazy(() =>
  componentLoader(
    () =>
      import(
        "modules/Payments/Pages/Subscription/RedirectionSubscriptionDetails"
      ),
    PAGE_LOAD_ATTEMPT,
  ),
);

const Advertisement = React.lazy(() =>
  componentLoader(
    () => import("modules/Advertisement/Advertisement"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const AnnouncementPage = React.lazy(() =>
  componentLoader(
    () => import("modules/Concierge/AnnouncementsPage/AnnouncementsPage"),
    PAGE_LOAD_ATTEMPT,
  ),
);

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTakeMeToInteraktTriggered: false,
      isVerifyingToken: false,
      isTokenFound: false,
      isAuthenticated: false,
      isFetchingOrganizationDetails: false,
      isOrganizationOnboarded: true,
      user: {},
      selectedAccountSegment: "",
      organizations: [],
      organizationId: "",
      showResetPasswordWarning: "",
    };
  }

  componentDidMount() {
    const responseStatus = localStorage.getItem("responseStatus");
    logger.log("FB Response Status:", responseStatus);
    if (window && window.analytics) {
      window.analytics.load(`${process.env.REACT_APP_SEGMENT_CODE}`);
      window.analytics.page();
    }
    this.checkToken();
    window.addEventListener("message", this.sessionInfoListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!!prevState.organizationId && this.state.organizationId) {
      this.props.dispatch(fetchPaymentInvoiceList(this.state.organizationId));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.sessionInfoListener);
  }

  getPrefilledData = () => {
    const { organizations } = this.state;
    if (
      organizations &&
      organizations.length > 0 &&
      organizations[0] &&
      organizations[0].organization_id
    ) {
      const {
        name,
        email,
        phone,
        country_code,
        company_website,
        company_type,
      } = organizations[0].organization_id;
      return {
        business: {
          name,
          email,
          phone: {
            code: Number(country_code.substring(1)),
            number: phone,
          },
          website: company_website,
        },
        phone: {
          category: industryMapping[company_type],
        },
      };
    }
    return {};
  };

  // TODO: We need to add logic to call fb-signup API in this function after getting enough logs.
  sessionInfoListener = (event) => {
    // const responseStatus = localStorage.getItem("responseStatus");
    if (event.origin !== "https://www.facebook.com") return;
    try {
      // if (responseStatus !== "connected") {
      const data = JSON.parse(event.data);

      if (data.type === "WA_EMBEDDED_SIGNUP") {
        localStorage.setItem("sessionInfo", JSON.stringify(data));
        if (data.event !== "FINISH") {
          this.props.dispatch(setESErrorPopupVisibility(true));
        }
        trackFBSignupResponse(this.state.organizationId, data);
        // if user finishes the Embedded Signup flow
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
        }
      }
      // }
    } catch {
      // Don’t parse info that’s not a JSON
    }
  };

  showConnectionToast = () => {
    showCustomNotification(
      "success",
      <Box display="flex" gridGap="12px">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="32px"
          height="32px"
        >
          <Icon
            iconName="filledTick"
            width="20px"
            height="20px"
            color="#0DB350"
          />
        </Box>
        <Box display="flex" flexDirection="column" gridGap="12px">
          <Label
            fontSize="14px"
            fontWeight={600}
            color="#000000"
            lineHeight="20px"
          >
            Successfully connected!
          </Label>
          <Label
            fontSize="14px"
            fontWeight={400}
            color="#1E212F"
            lineHeight="20px"
          >
            You have successfully connected your WhatsApp Business App with
            Interakt.
          </Label>
        </Box>
      </Box>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        className: "toast-width-s",
      },
    );
    this.props.refetchOrgChannelList();
  };

  isWebisteRequired = (allowCoexistence) => {
    const orgDetails = this.state.organizations[0].organization_id;

    const isWebsiteSelected =
      orgDetails.FB_business_verification_route === "website";
    const isSignalFlowActivated =
      orgDetails.metadata.signals_verification_flow_activated;

    if (allowCoexistence) {
      return false;
    }

    if (!isSignalFlowActivated) {
      return true;
    }

    return isWebsiteSelected && isSignalFlowActivated;
  };

  // Facebook Login with JavaScript SDK
  launchWhatsAppSignup = (allowCoexistence = false) => {
    const shouldAllowCoexistence = !!(
      allowCoexistence && typeof allowCoexistence === "boolean"
    );
    const esConfig = {
      feature: "whatsapp_embedded_signup",
      sessionInfoVersion: 2,
    };

    if (this.isWebisteRequired(shouldAllowCoexistence)) {
      esConfig.featureType = "business_website_required";
    }

    if (shouldAllowCoexistence) {
      esConfig.featureType = "coexistence";
    }

    if (this.state.organizationId && this.state.organizationId !== "") {
      const orgDetails = this.getPrefilledData();
      // Conversion tracking code
      // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: '1100908887049211', feature: 'whatsapp_embedded_signup'});

      // Launch Facebook login
      if (!!window.FB) {
        window.FB.login(
          (response) => {
            Mixpanel.track(INT_WEB_EMBEDDED_SIGNUP_CONNECT_TO_FB_CTA, {
              category: INTERAKT_WEB_EMBEDDED_SIGNUP,
              action: MIX_PANEL_LINK_CLICK_ACTION,
              status: "FB popup initialized",
            });
            logger.log("Response Status:", response?.status);
            window.localStorage.setItem("responseStatus", response?.status);
            // TODO: Remove this once Embedded Signup Testing is done.
            trackFBSignupResponse(this.state.organizationId, response);
            if (response.status === "connected") {
              Mixpanel.track(INT_WEB_EMBEDDED_SIGNUP_CONNECT_TO_FB_CTA, {
                category: INTERAKT_WEB_EMBEDDED_SIGNUP,
                action: MIX_PANEL_LINK_CLICK_ACTION,
                status: "Connected status returned",
              });

              const { accessToken } = response.authResponse;
              const data = {};
              data.oauth_access_token = accessToken;
              if (shouldAllowCoexistence) {
                data.signup_type = "Coexistence";
              }
              this.props.dispatch({
                type: FETCHING_FB_EMBEDDED_DATA,
                val: true,
              });
              callApi(
                `v1/organizations/${this.state.organizationId}/fb-signup/`,
                "POST",
                data,
              ).then((res) => {
                if (res.result) {
                  if (this.props.showCoexistenceFeature) {
                    this.showConnectionToast();
                  }
                  this.props.dispatch(
                    fetchNewBundledData(this.state.organizationId),
                  );
                  this.props.dispatch(fetchOrganizationDetailsForUser());
                  this.props.dispatch({
                    type: FETCHING_FB_EMBEDDED_DATA,
                    val: false,
                  });
                  this.props.dispatch({
                    type: SUBSCRIPTION_FETCH_STATUS,
                    isFetchingSubscription: true,
                  });
                  callApi(
                    `v1/organizations/${this.state.organizationId}/payments/subscriptions/?type=all`,
                    "get",
                  ).then((subscriptionRes) => {
                    if (subscriptionRes && subscriptionRes.result) {
                      if (process.env.REACT_APP_ENVIRONMENT === "production") {
                        TagManager.dataLayer({ dataLayer: { event: "sql" } });
                      }
                      // put the big logic and put into action for splitting subscriptions
                      if (
                        subscriptionRes.data &&
                        subscriptionRes.data.length > 0
                      ) {
                        const { organizations } = this.state;
                        const is_embedded_signup_enabled =
                          organizations &&
                          organizations[0] &&
                          organizations[0].organization_id &&
                          organizations[0].organization_id
                            .is_embedded_signup_enabled;
                        let isHaveAnyPaidSubscription = false;
                        let isHaveTrialSubscription = false;
                        const computedResult = splitSubscriptionsIntoPlanTypes(
                          subscriptionRes.data,
                        );
                        isHaveAnyPaidSubscription =
                          computedResult.paidSubscriptionList.length > 0;
                        isHaveTrialSubscription =
                          computedResult.trialSubscriptionList.length > 0;
                        let isFreeTrialActive = false;
                        computedResult.trialSubscriptionList.forEach((item) => {
                          if (item.isSubscriptionActive) {
                            isFreeTrialActive = true;
                          }
                        });
                        if (
                          !!is_embedded_signup_enabled &&
                          !isHaveAnyPaidSubscription &&
                          isHaveTrialSubscription
                        ) {
                          this.props.dispatch(setNotificationRibbon(true));
                        }
                        if (isFreeTrialActive) {
                          this.props.dispatch(setNotificationRibbon(true));
                        }
                        if (
                          computedResult.activeSubscriptionList.length === 0
                        ) {
                          this.props.dispatch(setNotificationRibbon(true));
                        }
                        this.props.dispatch({
                          type: FETCH_SUBSCRIPTION,
                          isFetchingSubscription: false,
                          isFetchSubscriptionAPIDone: true,
                          allSubscriptionList: subscriptionRes.data,
                          activeSubscriptionList:
                            computedResult.activeSubscriptionList,
                          trialSubscriptionList:
                            computedResult.trialSubscriptionList,
                          paidSubscriptionList:
                            computedResult.paidSubscriptionList,
                          isPaymentDone:
                            computedResult.activeSubscriptionList.length > 0,
                        });
                      } else {
                        this.props.dispatch({
                          type: FETCH_SUBSCRIPTION,
                          isFetchSubscriptionAPIDone: true,
                          isFetchingSubscription: false,
                          allSubscriptionList: subscriptionRes.data,
                          activeSubscriptionList: [],
                          trialSubscriptionList: [],
                          paidSubscriptionList: [],
                          isPaymentDone: subscriptionRes.data.length > 0,
                        });
                      }
                    } else {
                      this.props.dispatch({
                        type: SUBSCRIPTION_FETCH_STATUS,
                        isFetchingSubscription: false,
                        isFetchSubscriptionAPIDone: true,
                      });
                    }
                  });
                } else {
                  this.props.dispatch({
                    type: FETCHING_FB_EMBEDDED_DATA,
                    val: false,
                  });
                }
              });
              // window.location.reload()
            }
            if (getIsHaptikUser()) {
              Mixpanel.track(PROM_EMBEDDED_SIGNUP, {
                user_email: this.state.user.email,
                embedded_signup_status: response.status,
              });
            }
          },
          {
            scope: "business_management,whatsapp_business_management",
            extras: {
              ...esConfig,
              setup: {
                ...orgDetails,
              },
            },
          },
        );
      }
    }
  };

  setOrganizationOnboarded = () => {
    const { organizations } = this.state;
    if (
      organizations &&
      organizations.length > 0 &&
      organizations[0] &&
      organizations[0].organization_id &&
      organizations[0].organization_id.onboarding_status &&
      organizations[0].organization_id.onboarding_status !== "Onboarded"
    ) {
      Mixpanel.track(INT_WEB_EMBEDDED_SIGNUP_CONNECT_TO_FB_CTA, {
        category: INTERAKT_WEB_EMBEDDED_SIGNUP,
        action: MIX_PANEL_LINK_CLICK_ACTION,
        status: "Organization onboarded",
      });

      organizations[0].organization_id.onboarding_status = "Onboarded";
      this.setState(
        {
          isOrganizationOnboarded: true,
          isTakeMeToInteraktTriggered: true,
          organizations,
        },
        () => {
          this.props.dispatch({
            type: FETCH_USER_ORG,
            isOrganizationOnboarded: true,
            organizations,
            isFetchingOrganizationDetails: false,
          });
        },
      );
    }
    return organizations;
  };

  identifyUserForSentry = (gotUser) => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: `${gotUser.id}`,
        email: `${gotUser.email}`, // Email address
      });
    });
  };

  logUserInfoToStorageService = (gotUser, organizationId) => {
    if (gotUser) {
      window.localStorage.setItem("customerId", gotUser.id);
      window.localStorage.setItem("organizationId", organizationId);
      window.localStorage.setItem("metaVersion", packageJson.version);
    }
  };

  pollForOrganizationStatus = () => {
    this.pollInterval = setInterval(() => {
      const organizationPollingCount = storageService.get(
        "organizationPollingCount",
      );
      if (
        this.state.isOrganizationOnboarded ||
        parseInt(organizationPollingCount, 10) > 24
      ) {
        if (parseInt(organizationPollingCount, 10) > 24) {
          Sentry.captureEvent({
            message: "Organization Polling Limit 25 Exceeded",
          });
        }
        logger.log(
          "Cleared Polling Interval",
          parseInt(organizationPollingCount, 10),
        );
        clearInterval(this.pollInterval);
      } else {
        logger.log("Calling Org API");
        this.fetchUserOrganizations();
      }
    }, 15 * 1000);
  };

  fetchUserOrganizations = () => {
    const organizationPollingCount = storageService.get(
      "organizationPollingCount",
    );
    if (organizationPollingCount) {
      storageService.set(
        "organizationPollingCount",
        `${parseInt(organizationPollingCount, 10) + 1}`,
      );
    } else {
      storageService.set("organizationPollingCount", `${1}`);
    }
    callApi(`v1/organizations/`, "get").then((res) => {
      if (res.result) {
        const onboardingStatus = res.data[0].organization_id
          ? res.data[0].organization_id.onboarding_status
          : "";
        if (onboardingStatus === onboardingStatuses.ONBOARDED) {
          const organizationId = res.data[0].organization_id
            ? res.data[0].organization_id.id
            : "";
          if (organizationId) {
            Mixpanel.people.set({
              organization_id: organizationId,
              signup_source: res.data[0].organization_id.signup_source,
              shopify_app_type: res.data[0].organization_id.shopify_app_type,
            });
          }
          this.setState({
            organizations: res.data,
            isOrganizationOnboarded:
              res.data[0] &&
              res.data[0].organization_id &&
              res.data[0].organization_id.onboarding_status
                ? res.data[0].organization_id.onboarding_status ===
                  onboardingStatuses.ONBOARDED
                : false,
            isFetchingOrganizationDetails: false,
            organizationId,
          });
          this.props.dispatch({
            type: FETCH_USER_ORG,
            isOrganizationOnboarded:
              res.data[0] &&
              res.data[0].organization_id &&
              res.data[0].organization_id.onboarding_status
                ? res.data[0].organization_id.onboarding_status ===
                  onboardingStatuses.ONBOARDED
                : false,
            organizations: res.data,
            isFetchingOrganizationDetails: false,
          });
        }
      }
    });
  };

  // todo - remove this logic from App.js and move it to action
  checkToken = () => {
    // if order pages no need to check token
    if (!window.location.pathname.includes("/order/")) {
      logger.log("Check Token API Called");
      const token =
        typeof window === "undefined" ? "" : localStorage.getItem("token");
      const organizationId = localStorage?.getItem("organizationId");
      if (!token) {
        this.setState({
          isVerifyingToken: false,
          isAuthenticated: false,
          isTokenFound: false,
        });
      } else {
        this.setState({
          isVerifyingToken: true,
          isTokenFound: true,
        });
        callApi(`v1/organizations/`, "get").then((res) => {
          const model = {};
          if (res.status_code !== 200) {
            if (window) {
              clearLocalStorage();
            }
            this.setState({
              isFetchingOrganizationDetails: false,
            });
            window.location.reload();
          }
          if (res.status_code === 401) {
            if (window) {
              clearLocalStorage();
            }
            this.setState({
              isFetchingOrganizationDetails: false,
            });
            window.location.href = "/login";
          } else if (res.result) {
            let organizations = [];
            if (organizationId) {
              organizations = res.data?.filter(
                (org) => org.organization_id.id === organizationId,
              );
            } else {
              organizations = res.data?.filter(
                (org) => !org.organization_id.primary_org_id,
              );
            }
            if (organizations?.length) {
              res.data = organizations;
            }
            model.organizationId = res.data[0].organization_id
              ? res.data[0].organization_id.id
              : "";
            if (res.data?.[0]?.organization_id?.id) {
              Mixpanel.people.set({
                organization_id: res.data[0].organization_id.id,
                signup_source: res.data[0].organization_id.signup_source,
                shopify_app_type: res.data[0].organization_id.shopify_app_type,
              });
            }
            model.organization = res.data;
            if (!model.organizationId) {
              if (window) {
                clearLocalStorage();
              }
              this.setState({
                isFetchingOrganizationDetails: false,
              });
              window.location.href = "/login";
              return;
            }

            async.series(
              [
                (cb) => {
                  cachedFetchProfile(model.organizationId).then((res) => {
                    if (res) {
                      const user = res;
                      this.logUserInfoToStorageService(
                        user,
                        model.organizationId,
                      );
                      this.props.dispatch(
                        fetchNewBundledData(model.organizationId),
                      );
                      Mixpanel.identify(user.id);
                      Mixpanel.people.set({
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                      });
                      this.identifyUserForSentry(user);
                      user.role = res?.permissions?.role;
                      user.userPermissions = res?.permissions?.features
                        .map((item) =>
                          item.is_enabled ? item.feature_code : null,
                        )
                        .filter((item) => {
                          if (item) return item;
                        });
                      this.setState({
                        isAuthenticated: true,
                        isVerifyingToken: false,
                        user,
                        isFetchingOrganizationDetails: true,
                      });
                      this.props.dispatch({
                        type: VERIFY_SUCCESS,
                        isAuthenticated: true,
                        isVerifyingToken: false,
                        user,
                        widget_preferences: res ? res.widget_preferences : null,
                        token,
                        isUserPreferencesLoaded: true,
                        widgetPreferences:
                          user?.preferences &&
                          !!user?.preferences.widget_preferences
                            ? JSON5.parse(user?.preferences.widget_preferences)
                            : null,
                        allUserPreferences:
                          user?.preferences &&
                          !!user?.preferences.all_users_columns_preferences
                            ? JSON5.parse(
                                user?.preferences.all_users_columns_preferences,
                              )
                            : null,
                      });
                    }
                    return cb();
                  });
                },
                (cb) => {
                  this.setState({
                    organizations: model.organization,
                    isOrganizationOnboarded: getIfOrganizationIsOnboarded(
                      model.organization,
                    ),
                    isFetchingOrganizationDetails: false,
                    organizationId: model.organizationId,
                  });
                  if (
                    process.env.REACT_APP_ENVIRONMENT === "development" &&
                    model.organization[0].organization_id &&
                    !!!model.organization[0].organization_id
                      .subscription_plan_selected_on_sign_up
                  ) {
                    this.setState({
                      selectedAccountSegment: "paymentPlanSelection",
                    });
                    this.props.dispatch(
                      toggleCreateAccountSegment("paymentPlanSelection"),
                    );
                  }
                  this.props.dispatch({
                    type: FETCH_USER_ORG,
                    isOrganizationOnboarded: getIfOrganizationIsOnboarded(
                      model.organization,
                    ),
                    organizations: model.organization,
                    isFetchingOrganizationDetails: false,
                  });
                  return cb();
                },
                (cb) => {
                  if (model.organizationId) {
                    this.props.dispatch({
                      type: SUBSCRIPTION_FETCH_STATUS,
                      isFetchingSubscription: true,
                      isFetchSubscriptionAPIDone: false,
                    });
                    callApi(
                      `v1/organizations/${model.organizationId}/payments/subscriptions/?type=all`,
                      "get",
                    ).then((res) => {
                      if (res && res.result) {
                        if (res.data && res.data.length > 0) {
                          const { organizations } = this.state;
                          const is_embedded_signup_enabled =
                            organizations &&
                            organizations[0] &&
                            organizations[0].organization_id &&
                            organizations[0].organization_id
                              .is_embedded_signup_enabled;
                          const computedResult =
                            splitSubscriptionsIntoPlanTypes(res.data);
                          const isHaveAnyPaidSubscription =
                            computedResult.paidSubscriptionList.length > 0;
                          const isHaveTrialSubscription =
                            computedResult.trialSubscriptionList.length > 0;
                          let isFreeTrialActive = false;
                          computedResult.trialSubscriptionList.forEach(
                            (item) => {
                              if (item.isSubscriptionActive) {
                                isFreeTrialActive = true;
                              }
                            },
                          );
                          if (
                            !!is_embedded_signup_enabled &&
                            !isHaveAnyPaidSubscription &&
                            isHaveTrialSubscription
                          ) {
                            this.props.dispatch(setNotificationRibbon(true));
                          }
                          if (isFreeTrialActive) {
                            this.props.dispatch(setNotificationRibbon(true));
                          }
                          if (
                            computedResult.activeSubscriptionList.length === 0
                          ) {
                            this.props.dispatch(setNotificationRibbon(true));
                          }
                          this.props.dispatch({
                            type: FETCH_SUBSCRIPTION,
                            isFetchingSubscription: false,
                            isFetchSubscriptionAPIDone: true,
                            allSubscriptionList: res.data,
                            activeSubscriptionList:
                              computedResult.activeSubscriptionList,
                            trialSubscriptionList:
                              computedResult.trialSubscriptionList,
                            paidSubscriptionList:
                              computedResult.paidSubscriptionList,
                            isPaymentDone:
                              computedResult.activeSubscriptionList.length > 0,
                          });
                        } else {
                          this.props.dispatch({
                            type: FETCH_SUBSCRIPTION,
                            isFetchSubscriptionAPIDone: true,
                            isFetchingSubscription: false,
                            allSubscriptionList: res.data,
                            activeSubscriptionList: [],
                            trialSubscriptionList: [],
                            paidSubscriptionList: [],
                            isPaymentDone: res.data.length > 0,
                          });
                        }
                      } else {
                        this.props.dispatch({
                          type: SUBSCRIPTION_FETCH_STATUS,
                          isFetchingSubscription: false,
                          isFetchSubscriptionAPIDone: true,
                        });
                      }
                      return cb();
                    });
                  } else {
                    return cb();
                  }
                },
              ],
              (err) => {},
            );
          } else {
            this.setState({
              isVerifyingToken: false,
              isAuthenticated: false,
            });
          }
        });
      }
    }
  };

  updateOnboardingStatusForOrg = (status) => {
    this.fetchUserOrganizations();
  };

  loginUser = (creds, url) => {
    if (window) {
      clearLocalStorage();
    }
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });

    creds.password = encryptString(creds.password);

    publicApiCaller(`v2/login/`, "post", creds, true).then((res) => {
      creds.password = "";
      if (res && res.id) {
        if (res.token) {
          setTimeout(() => {
            const token = window.localStorage.getItem("inbox_welcome_done")
              ? window.localStorage.getItem("inbox_welcome_done")
              : "";
            if (!token) {
              window.localStorage.setItem("inbox_welcome_done", res.token);
            }
          }, 4000);
          Mixpanel.track(INT_WEB_LOGIN, {});

          window.localStorage.setItem("token", res.token);
        }
        this.checkToken();
      } else {
        if (
          res?.message ===
            "We have expired your password. Please check your email to reset the password! " ||
          res?.message === "Your password has been expired. "
        ) {
          this.setState({ showResetPasswordWarning: true });
          showNotification(
            "error",
            <>
              Your password has expired. We have sent you an email to reset your
              password - Please check your inbox for the same and if not found,
              then check your Spam or Promotions folder or contact
              support@interakt.ai. For more information on our password policies{" "}
              <a
                style={{ textDecoration: "underline", color: "white" }}
                rel="noreferrer"
                href="https://www.interakt.shop/resource-center/introducing-interakts-new-password-policy/"
                target="_blank"
              >
                Click here
              </a>
            </>,
          );
        } else {
          this.setState({ showResetPasswordWarning: false });
          showNotification("error", res.message);
        }

        this.props.dispatch({
          type: LOGIN_FAILED,
          isAuthenticated: false,
          isLoggingIn: false,
        });
      }
    });
  };

  autoLogin = (autoLoginToken) => {
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    if (autoLoginToken) {
      setTimeout(() => {
        const token = window.localStorage.getItem("inbox_welcome_done")
          ? window.localStorage.getItem("inbox_welcome_done")
          : "";
        if (!token) {
          window.localStorage.setItem("inbox_welcome_done", autoLoginToken);
        }
      }, 4000);
      window.localStorage.setItem("token", autoLoginToken);
    }
    this.checkToken();
  };

  getOrgDetailsSignup = (res) => {
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    if (res.data && (res.data.token !== "" || res.data.accessToken !== "")) {
      let token = res.data?.token;

      if (res.data?.access_token) {
        token = res.data?.access_token;
      }

      window.localStorage.setItem("token", token);
    }
    setTimeout(() => closeIfIsPopupWindow(/(whatsapp-widget-).*/), 700);
    this.checkToken();
    this.setState({
      isAuthenticated: true,
      user: res,
    });
    this.props.dispatch({
      type: LOGIN_SUCCESS,
      isAuthenticated: true,
      user: res,
      widget_preferences: res.widget_preferences
        ? res.widget_preferences
        : null,
      token: res.token,
      isLoggingIn: false,
    });
    callApi(`v1/organizations/`, "get").then((res) => {
      if (res.result) {
        this.setState({
          organizations: res.data,
          isOrganizationOnboarded: getIfOrganizationIsOnboarded(res.data),
          isFetchingOrganizationDetails: false,
        });
        if (
          this.state.organizations &&
          this.state.organizations.length > 0 &&
          this.state.organizations[0].organization_id.FB_business_manager_id ===
            null &&
          !this.isSandboxMode
        ) {
          if (
            process.env.REACT_APP_ENVIRONMENT === "development" &&
            this.state.organizations[0] &&
            this.state.organizations[0].organization_id
          ) {
            this.setState({
              selectedAccountSegment: "paymentPlanSelection",
            });
            this.props.dispatch(
              toggleCreateAccountSegment("paymentPlanSelection"),
            );
          } else {
            this.props.dispatch(toggleCreateAccountSegment("thankYouScreen"));
          }
        }
        this.props.dispatch({
          type: FETCH_USER_ORG,
          isOrganizationOnboarded: getIfOrganizationIsOnboarded(res.data),
          organizations: res.data,
          isFetchingOrganizationDetails: false,
        });
      } else {
        this.setState({
          isFetchingOrganizationDetails: false,
        });
      }
    });
  };

  getOrgDetailsForShopifySignup = (res) => {
    this.props.dispatch({
      type: ATTEMPT_TO_LOGIN,
      isLoggingIn: true,
    });
    if (res.data && res.data.token !== "") {
      let token = res.data?.token;

      if (res.data?.access_token) {
        token = res.data?.access_token;
      }

      window.localStorage.setItem("token", token);
    }
    this.checkToken();
    this.setState({
      isAuthenticated: true,
      user: res,
    });
    this.props.dispatch({
      type: LOGIN_SUCCESS,
      isAuthenticated: true,
      user: res,
      widget_preferences: res.widget_preferences
        ? res.widget_preferences
        : null,
      token: res.token,
      isLoggingIn: false,
    });
    callApi(`v1/organizations/`, "get").then((res) => {
      if (res.result) {
        this.setState({
          organizations: res.data,
          isOrganizationOnboarded: getIfOrganizationIsOnboarded(res.data),
          isFetchingOrganizationDetails: false,
        });
        if (
          this.state.organizations &&
          this.state.organizations.length > 0 &&
          this.state.organizations[0].organization_id.FB_business_manager_id ===
            null
        ) {
          if (
            process.env.REACT_APP_ENVIRONMENT === "development" &&
            this.state.organizations[0] &&
            this.state.organizations[0].organization_id
          ) {
            this.setState({
              selectedAccountSegment: "paymentPlanSelection",
            });
            this.props.dispatch(
              toggleCreateAccountSegment("paymentPlanSelection"),
            );
          } else {
            this.props.dispatch(toggleCreateAccountSegment("thankYouScreen"));
          }
        }
        this.props.dispatch({
          type: FETCH_USER_ORG,
          isOrganizationOnboarded: getIfOrganizationIsOnboarded(res.data),
          organizations: res.data,
          isFetchingOrganizationDetails: false,
        });
      } else {
        this.setState({
          isFetchingOrganizationDetails: false,
        });
      }
    });
  };

  checkIfToShowLoader = () => {
    if (this.props.isInitApisLoading) {
      return true;
    }
    if (this.state.isTokenFound && this.state.isVerifyingToken) {
      return true;
    }
    if (this.state.isTokenFound && this.state.organizations.length === 0) {
      return true;
    }
    return false;
  };

  handleLetsGo = (userData) => {
    this.setState({
      selectedAccountSegment: "firstScreen",
    });
    const properties = {
      label: "Lets Go Button",
      value: "Lets Go Button",
      category: "Lets Go",
      action: "Button Click",
    };
    trackEvent({
      ...properties,
    });
    this.props.dispatch(toggleCreateAccountSegment("firstScreen"));
  };

  // Note: Duplicate to hook to be remove - getting bug when using HOC with redux connection
  isSandboxMode = () => {
    const organizationData =
      this.state.organizations && this.state.organizations[0]?.organization_id;

    const controlSwitch = () => {
      const sandboxModeValue = localStorage.getItem("sandboxMode");

      if (sandboxModeValue === "undefined" || sandboxModeValue === null) {
        return false;
      }

      return sandboxModeValue === true || sandboxModeValue === "true";
    };

    return (
      (organizationData &&
        organizationData.onboarding_status !== ONBOARDED &&
        organizationData.metadata?.sandbox_code?.length > 0 &&
        (process.env.REACT_APP_SANDBOX_MODE_ENABLED === "true" ||
          controlSwitch())) ??
      (process.env.REACT_APP_SANDBOX_MODE_ENABLED === "true" || controlSwitch())
    );
  };

  render() {
    const isHaptikUser = getIsHaptikUser();
    return (
      <div className="App" style={{ height: "100%" }}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <ImageViewer />
            <MediaPreview />
            <SupportBot />
            {this.state.isAuthenticated &&
              this.props.isOpenBusinessVerificationFailedModal && (
                <BusinessVerificationFailedModal />
              )}
            {!this.props.isSignupSourceTally &&
              !this.props.isAdsPlanSignupSource && <AddBusiness />}
            {this.state.isAuthenticated &&
              this.isSandboxMode() &&
              !isHaptikUser && <SandboxNotificationRibbon />}
            {!window.location.pathname.includes("/order/") &&
              !window.location.pathname.includes("/partners/") &&
              !isHaptikUser && (
                <NotificationRibbon
                  isAuthenticated={this.state.isAuthenticated}
                  isFetchingOrganizationDetails={
                    this.state.isFetchingOrganizationDetails
                  }
                />
              )}
            <div
              className={
                this.checkIfToShowLoader() ? "kiwi-fullscreen-loader" : "hide"
              }
            >
              <BasicLoader />
            </div>
            {/* Todo - Split Router file into 2 parts to separate COD */}
            {!window.location.pathname.includes("/order/") && (
              // Do not change this to ProtectRoute. If you do, then page refresh will always
              // redirect to inbox page
              <Route
                path="/"
                isAuthenticated={this.state.isAuthenticated}
                isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                component={(props) => (
                  <AppPage
                    {...props}
                    isTakeMeToInteraktTriggered={
                      this.state.isTakeMeToInteraktTriggered
                    }
                    updateOnboardingStatusForOrg={
                      this.updateOnboardingStatusForOrg
                    }
                  />
                )}
              />
            )}
            <Route
              path="/shopify/order/confirm/:id"
              component={OrderDetailsPage}
            />
            <Route
              path="/shopify/order/confirmed/:id"
              component={OrderConfirmationPage}
            />
            <Route
              path="/shopify/order/cancelled/:id"
              component={OrderCancelledPage}
            />
            <Route
              path="/shopify/order/expired/:id"
              component={OrderLinkExpiredPage}
            />
            <Route path="/shopify/order/404/:id" component={Order404Page} />
            <div
              className={
                this.checkIfToShowLoader() ? "hide" : "kiwi-main-container"
              }
            >
              <MegaSidebar />
              {this.state.isAuthenticated &&
              !this.state.isFetchingOrganizationDetails ? (
                <Route
                  path="/"
                  component={(props) => (
                    <BottomBar
                      {...props}
                      selectedAccountSegment={this.state.selectedAccountSegment}
                    />
                  )}
                />
              ) : null}
              <Switch>
                <Route path="/" exact component={RedirectPage} />
                <Route
                  path="/login"
                  exact
                  component={(props) => (
                    <LoginPageV2
                      {...props}
                      loginUser={this.loginUser}
                      showResetPasswordWarning={
                        this.state.showResetPasswordWarning
                      }
                    />
                  )}
                />
                <AntiProtectedRoute
                  path="/signup"
                  exact
                  component={() => (
                    <CreateAccountPageV2
                      getOrgDetailsSignup={this.getOrgDetailsSignup}
                    />
                  )}
                />
                <AntiProtectedRoute
                  path="/signup/external"
                  exact
                  component={() => (
                    <ExternalSignupPage
                      getOrgDetailsSignup={this.getOrgDetailsSignup}
                    />
                  )}
                />
                <AntiProtectedRoute
                  path="/shopify/signup"
                  exact
                  component={(props) => (
                    <ShopifyCreateAccountPage
                      {...props}
                      getOrgDetailsForShopifySignup={
                        this.getOrgDetailsForShopifySignup
                      }
                    />
                  )}
                />
                <Route
                  path="/reset-password"
                  exact
                  component={(props) => <ResetPassword {...props} />}
                />
                <Route
                  path="/auto-login"
                  exact
                  component={(props) => (
                    <AutoLoginPage {...props} autoLogin={this.autoLogin} />
                  )}
                />
                <Route
                  path="/auto-logout"
                  exact
                  component={(props) => <AutoLogoutPage />}
                />
                <Route
                  path="/whatsapp-widget"
                  exact
                  component={(props) => <WhatsappWidget />}
                />
                <Route
                  path="/whatsapp-widget/auth"
                  exact
                  component={(props) => <WhatsappWidget />}
                />
                <Route
                  path="/partners"
                  component={(props) => <PartnerDashboard />}
                />
                <Route
                  path="/toggle-rq-dev-tools"
                  exact
                  component={(props) => <ToggleRqDevTools />}
                />
                <ProtectedPageWrapper
                  selectedAccountSegment={this.state.selectedAccountSegment}
                >
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/signup/external/whatsapp"
                    exact
                    component={() => (
                      <ExternalEmbeddedSignupPage
                        launchWhatsAppSignup={this.launchWhatsAppSignup}
                      />
                    )}
                  />
                  {this.state.organizations?.[0]?.organization_id
                    ?.is_embedded_signup_enabled ? (
                    <ProtectRoute
                      isAuthenticated={this.state.isAuthenticated}
                      isOrganizationOnboarded={
                        this.state.isOrganizationOnboarded
                      }
                      path="/signup/whatsapp"
                      exact
                      component={() => (
                        <NewFBSignUpWhatsapp
                          pollForOrganizationStatus={
                            this.pollForOrganizationStatus
                          }
                          launchWhatsAppSignup={this.launchWhatsAppSignup}
                        />
                      )}
                    />
                  ) : (
                    <ProtectRoute
                      isAuthenticated={this.state.isAuthenticated}
                      isOrganizationOnboarded={
                        this.state.isOrganizationOnboarded
                      }
                      path="/signup/whatsapp"
                      exact
                      component={(props) => (
                        <SignUpWhatsapp
                          {...props}
                          handleLetsGo={this.handleLetsGo}
                          setOrganizationOnboarded={
                            this.setOrganizationOnboarded
                          }
                          organizations={this.state.organizations}
                        />
                      )}
                    />
                  )}
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[
                      "settings_setup_your_business_account",
                    ]}
                    shouldNotHaveVanishFeatures={[MANAGE_WHATSAPP_PROFILE]}
                    path="/settings/signup-business"
                    exact
                    component={(props) => (
                      <SettingsSignupBusiness
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[INBOX]}
                    path="/inbox"
                    exact
                    component={(props) => (
                      <InboxPage
                        {...props}
                        organizations={this.state.organizations}
                        setFaviconCount={this.props.setFaviconCount}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/settings"
                    exact
                    component={(props) => (
                      <MainSettingPage
                        organizations={this.state.organizations}
                        {...props}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[MEMBER_PROFILE]}
                    path="/settings/profile"
                    exact
                    component={(props) => (
                      <ProfileSetting
                        organizations={this.state.organizations}
                        isOrganizationOnboarded={
                          this.state.isOrganizationOnboarded
                        }
                        {...props}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/settings/manage-tags"
                    exact
                    component={(props) => <ManageTags {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[COMMERCE_SETTING]}
                    path="/commerce-settings"
                    exact
                    component={(props) => <CommerceSettings {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/onboarding"
                    exact
                    component={(props) => <OnboardingPage />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[
                      WALLET_MAIN_PAGE,
                      TRACK_EXPENSES,
                      BILLING_DETAILS,
                      VIEW_INSIGHTS,
                    ]}
                    path="/payments"
                    component={(props) => <PaymentsPage />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[CATALOG]}
                    path="/commerce/catalog"
                    exact
                    component={(props) => <Catalog {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[ORDER_PANEL]}
                    path="/commerce/order-panel"
                    exact
                    component={(props) => <OrderPanel {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[AUTOCHECKOUT_WORKFLOW]}
                    path="/work-flows/:workFlowName"
                    exact
                    component={() => <WorkFlow />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[COD_TO_PREPAID]}
                    path="/commerce/cod-to-prepaid"
                    exact
                    component={(props) => <CodToPrepaid {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/mobile/settings"
                    exact
                    component={(props) => <MobileSettingPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/settings/inbox-setting"
                    exact
                    component={(props) => (
                      <InboxSettingsPage
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[QUICK_REPLIES]}
                    path="/settings/quick-replies"
                    exact
                    component={(props) => (
                      <MainSettingPage
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHavePermissions={["settings_team_management"]}
                    shouldNotHaveVanishFeatures={[TEAM_SETTINGS]}
                    path="/settings/team-setting"
                    exact
                    component={(props) => (
                      <TeamSetting
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[DEVELOPER_SETTINGS]}
                    path="/settings/developer-setting"
                    exact
                    component={(props) => (
                      <DeveloperSetting
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[CAMPAIGN]}
                    path="/notification/campaign/:id"
                    exact
                    component={(props) => <CampaignDetailPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[CAMPAIGN]}
                    path="/notification"
                    exact
                    component={(props) => (
                      <NotificationDashboardPage {...props} />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/organization/account-details"
                    exact
                    component={AccountDetails}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[
                      "notifications_create_new_campaign",
                    ]}
                    shouldNotHaveVanishFeatures={[CAMPAIGN]}
                    path="/notification/create-campaign-from-sample/:samplecampaignid"
                    exact
                    component={(props) => <CreateCampaignPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[
                      "notifications_create_new_campaign",
                    ]}
                    shouldNotHaveVanishFeatures={[CAMPAIGN]}
                    path="/notification/choose-sample-campaign/"
                    exact
                    component={(props) => (
                      <ChooseSampleCampaignsMobile {...props} />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[
                      "notifications_create_new_campaign",
                    ]}
                    shouldNotHaveVanishFeatures={[CAMPAIGN]}
                    path="/notification/create-campaign"
                    exact
                    component={(props) => <CreateCampaignPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[CAMPAIGN]}
                    path="/notification/campaign/:id/update"
                    exact
                    component={(props) => <CreateCampaignPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/signup/demo"
                    exact
                    component={(props) => <SignUpDemo {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/smart-ads"
                    exact
                    component={(props) => <Advertisement {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/widget/install"
                    exact
                    component={(props) => <InstallWidgetPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[WIDGET]}
                    path="/widget/manage"
                    exact
                    component={(props) => (
                      <CustomizeWidgetPage
                        {...props}
                        organization={
                          this.state.organizations &&
                          this.state.organizations[0] &&
                          this.state.organizations[0].organization_id
                            ? this.state.organizations[0].organization_id
                            : ""
                        }
                        isFetchingOrganizationDetails={
                          this.state.isFetchingOrganizationDetails
                        }
                      />
                    )}
                  />

                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/widget/install"
                    exact
                    component={(props) => <InstallWidgetPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["users_all_view"]}
                    shouldNotHaveVanishFeatures={[USERS]}
                    path="/contacts/list"
                    exact
                    component={(props) => <CustomerListPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/contacts/bulk-upload"
                    exact
                    component={(props) => (
                      <BulkUploadV2CustomersPage {...props} />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/contacts/:id/page"
                    exact
                    component={(props) => <CustomerDashboardPage {...props} />}
                  />
                  {/* Added for mobile app */}
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[
                      ANALYTICS_OVERVIEW,
                      AGENT_PERFORMANCE,
                      CTWA,
                    ]}
                    path="/analytics"
                    exact
                    component={(props) => <OverviewPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[ANALYTICS_OVERVIEW]}
                    path="/analytics/overview"
                    exact
                    component={(props) => <OverviewPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[AGENT_PERFORMANCE]}
                    path="/analytics/agent-performance"
                    exact
                    component={(props) => <AgentPerformance {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[CTWA]}
                    path="/analytics/ad-performance"
                    exact
                    component={(props) => <AdPerformance {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[CTWA]}
                    path="/analytics/ad-performance/:id"
                    exact
                    component={(props) => (
                      <AdPerFormanceIndividual {...props} />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/analytics/campaign-reports"
                    exact
                    component={(props) => <ReportsPage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/analytics/checkout-link-tracking"
                    exact
                    component={(props) => <CheckoutLinkTracking {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldNotHaveVanishFeatures={[TEMPLATES]}
                    path="/templates/list"
                    exact
                    component={(props) => (
                      <TemplateListPage
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/template/create"
                    exact
                    component={(props) => <CreateTemplatePage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/template/:name/view"
                    exact
                    component={(props) => <CreateTemplatePage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/template/:name/duplicate"
                    exact
                    component={(props) => <CreateTemplatePage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/template/:name/edit"
                    exact
                    component={(props) => <CreateTemplatePage {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    shouldNotHaveVanishFeatures={[BILLING_DETAILS]}
                    path="/billing/org-details"
                    exact
                    component={(props) => <OrganisationalDetails {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    shouldNotHaveVanishFeatures={[BILLING_DETAILS]}
                    path="/billing/subscription"
                    exact
                    component={(props) => (
                      <SubscriptionDetail
                        {...props}
                        organization={
                          this.state.organizations &&
                          this.state.organizations[0] &&
                          this.state.organizations[0].organization_id
                            ? this.state.organizations[0].organization_id
                            : ""
                        }
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[]}
                    path="/billing/subscription/success-redirection"
                    exact
                    component={(props) => (
                      <RedirectionSubscriptionDetails {...props} />
                    )}
                  />

                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[]}
                    path="/announcements"
                    exact
                    component={(props) => <AnnouncementPage {...props} />}
                  />

                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    path="/billing/modify-subscription/confirmation"
                    exact
                    component={(props) => (
                      <PlanChangeConfirmationPage
                        {...props}
                        organization={
                          this.state.organizations &&
                          this.state.organizations[0] &&
                          this.state.organizations[0].organization_id
                            ? this.state.organizations[0].organization_id
                            : ""
                        }
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    path="/billing/modify-subscription"
                    exact
                    component={(props) => (
                      <ModifySubscriptionPage
                        {...props}
                        organization={
                          this.state.organizations &&
                          this.state.organizations[0] &&
                          this.state.organizations[0].organization_id
                            ? this.state.organizations[0].organization_id
                            : ""
                        }
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    shouldNotHaveVanishFeatures={[BILLING_DETAILS]}
                    path="/billing/invoice"
                    exact
                    component={(props) => (
                      <InvoiceHistory
                        {...props}
                        organization={
                          this.state.organizations &&
                          this.state.organizations[0] &&
                          this.state.organizations[0].organization_id
                            ? this.state.organizations[0].organization_id
                            : ""
                        }
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    shouldNotHaveVanishFeatures={[BILLING_DETAILS]}
                    path="/billing/usage-history"
                    exact
                    component={(props) => <UsageHistory {...props} />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/workflow/create"
                    exact
                    component={() => <WorkflowCreateEditPage />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/workflows/:id?"
                    exact
                    component={() => <WorkflowCreateEditPage />}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    shouldHaveRoles={[]}
                    shouldHavePermissions={["settings_billing_details"]}
                    shouldNotHaveVanishFeatures={[BILLING_DETAILS]}
                    path="/billing/credits"
                    exact
                    component={(props) => (
                      <Credits
                        {...props}
                        organization={
                          this.state.organizations &&
                          this.state.organizations[0] &&
                          this.state.organizations[0].organization_id
                            ? this.state.organizations[0].organization_id
                            : ""
                        }
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={["Owner"]}
                    shouldHavePermissions={[]}
                    shouldNotHaveVanishFeatures={[ACCESS_PERMISSIONS]}
                    path="/settings/permissions"
                    exact
                    component={(props) => (
                      <ManagePermissionsPage
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[]}
                    shouldNotHaveVanishFeatures={[
                      INBOX_SETTING,
                      CUSTOMER_AUTO_REPLIES,
                      WORKFLOWS,
                      CHAT_ASSIGNMENT,
                    ]}
                    path="/automation"
                    component={(props) => (
                      <AutomationPage
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    isRestrictedPage
                    shouldHaveRoles={[]}
                    shouldHavePermissions={[]}
                    shouldNotHaveVanishFeatures={[INTEGRATIONS]}
                    path="/integrations"
                    component={(props) => (
                      <Integrations
                        {...props}
                        organizations={this.state.organizations}
                      />
                    )}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/apps/:app_id/oauth"
                    exact
                    component={AppOauth}
                  />
                  <ProtectRoute
                    isAuthenticated={this.state.isAuthenticated}
                    isOrganizationOnboarded={this.state.isOrganizationOnboarded}
                    path="/third-party/integration/start"
                    exact
                    component={() => <ThirdPartyIntegration />}
                  />
                  <Route
                    path="/shopify/register"
                    exact
                    component={() => <ShopifyRegister />}
                  />
                  <Route
                    path="/shopify/link"
                    exact
                    component={() => <ShopifyLink />}
                  />
                </ProtectedPageWrapper>
              </Switch>
            </div>
            <div style={{ display: "none" }}>
              Version - {packageJson.version}
            </div>
          </Suspense>
        </ThemeProvider>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpenBusinessVerificationFailedModal:
    getBusinessFailedVerificatioModalState(state),
});

export default compose(
  withInitApiCalls,
  withPolyfill,
  WithFavicon,
  withRefinerInit,
  withUseOrgChannelList,
  withUseSignupSourceTally,
  withUseSignupSourceAdsPlan,
  withShowCoexistenceFeature,
  withUseGetNotificationRibbonHeight,
  connect(mapStateToProps),
)(App);
