import { useSelector } from "react-redux";
import { getIsCoexistenceAllowed } from "modules/App/AppSelectors";

const useShouldShowCoexistenceFeature = () => {
  const showCoexistenceFeature = localStorage.getItem("show_coexistence"); // TODO : Remove this before release.
  const isCoexistenceAllowed = useSelector(getIsCoexistenceAllowed);

  return isCoexistenceAllowed || showCoexistenceFeature;
};

export default useShouldShowCoexistenceFeature;
