import { useQuery } from "@tanstack/react-query";
import { ENV_PRODUCTION } from "constants/environmentTypes";

export const RQ_RIBBON = "RQ_RIBBON";

const fetchRibbon = async () => {
  const res = await fetch(
    process.env.REACT_APP_ENVIRONMENT !== ENV_PRODUCTION
      ? `https://hellohaptik.github.io/InteraktBanner/data/ribbon_config/development.json?timestamp=${new Date()}`
      : `https://hellohaptik.github.io/InteraktBanner/data/ribbon_config/production.json?timestamp=${new Date()}`,
  );
  const data = await res.json();

  return data;
};

const useRibbon = (select = (data) => data, options = {}) => {
  return useQuery([RQ_RIBBON], fetchRibbon, {
    select,
    ...options,
  });
};

export default useRibbon;
