import React from "react";

const noteV2 = ({ fill, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_116_293)">
        <path
          d="M7.50008 4.16699H5.83341C5.39139 4.16699 4.96746 4.34259 4.6549 4.65515C4.34234 4.96771 4.16675 5.39163 4.16675 5.83366V15.8337C4.16675 16.2757 4.34234 16.6996 4.6549 17.0122C4.96746 17.3247 5.39139 17.5003 5.83341 17.5003H14.1667C14.6088 17.5003 15.0327 17.3247 15.3453 17.0122C15.6578 16.6996 15.8334 16.2757 15.8334 15.8337V5.83366C15.8334 5.39163 15.6578 4.96771 15.3453 4.65515C15.0327 4.34259 14.6088 4.16699 14.1667 4.16699H12.5001"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 4.16667C7.5 3.72464 7.6756 3.30072 7.98816 2.98816C8.30072 2.67559 8.72464 2.5 9.16667 2.5H10.8333C11.2754 2.5 11.6993 2.67559 12.0118 2.98816C12.3244 3.30072 12.5 3.72464 12.5 4.16667C12.5 4.60869 12.3244 5.03262 12.0118 5.34518C11.6993 5.65774 11.2754 5.83333 10.8333 5.83333H9.16667C8.72464 5.83333 8.30072 5.65774 7.98816 5.34518C7.6756 5.03262 7.5 4.60869 7.5 4.16667Z"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 10H12.5"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 13.333H12.5"
          stroke={fill}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_116_293">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default noteV2;
