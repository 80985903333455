import React from "react";
import useUser, {
  selectHasAnyPermission,
  selectHasAnyRole,
} from "useApi/App/useUser";

const AccessControl = ({
  shouldHaveRoles = [],
  isRestrictedPage = false,
  shouldHavePermissions = [],
  children,
}) => {
  const { data: hasPermission = false } = useUser(
    selectHasAnyPermission(shouldHavePermissions),
  );
  const { data: hasRole = false } = useUser(selectHasAnyRole(shouldHaveRoles));
  const isRestrictedPagePermissionAccess = isRestrictedPage ? hasRole : true;

  if (isRestrictedPagePermissionAccess && hasPermission) {
    return children;
  }
  return <div />;
};

export default AccessControl;
