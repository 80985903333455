export const UPDATE_ACTIVE_SMART_CARD = "UPDATE_ACTIVE_SMART_CARD";
export const UPDATE_PERSONAL_DETAILS_DATA = "UPDATE_PERSONAL_DETAILS_DATA";
export const SHOW_SEARCH_SECTION = "SHOW_SEARCH_SECTION";
export const CLOSE_SEARCH_SECTION = "CLOSE_SEARCH_SECTION";
export const SET_SEARCH_ACTIVE_CONVERSATION_ID =
  "SET_SEARCH_ACTIVE_CONVERSATION_ID";
export const IS_FINDING_SEARCH_MESSAGE = "IS_FINDING_SEARCH_MESSAGE";
export const CHANGE_SANDBOX_MODAL_VISIBILITY =
  "CHANGE_SANDBOX_MODAL_VISIBILITY";
export const CHANGE_SPAM_MODAL_VISIBILITY = "CHANGE_SPAM_MODAL_VISIBILITY";
export const SET_MULTI_SELECT_CHATS = "SET_MULTI_SELECT_CHATS";
export const RESET_MULTI_SELECT_STATE = "RESET_MULTI_SELECT_STATE";
export const ENABLE_MULTI_SELECT_STATE = "ENABLE_MULTI_SELECT_STATE";
export const NEW_CHAT_LOADING = "NEW_CHAT_LOADING";
export const UPDATE_ERROR_POPUP_DATA = "UPDATE_ERROR_POPUP_DATA";
export const SET_REPLY_TO_MESSAGE_PREVIEW_ID =
  "SET_REPLY_TO_MESSAGE_PREVIEW_ID";
export const SET_IS_REPLY_MESSAGE_REPLY_OPTION_ACTIVE =
  "SET_IS_REPLY_MESSAGE_REPLY_OPTION_ACTIVE";
export const SET_IS_REPLY_TO_MESSAGE_COMPOSER_ACTIVE =
  "SET_IS_REPLY_TO_MESSAGE_COMPOSER_ACTIVE";
export const CLOSE_REPLY_TO_MESSAGE_PREVIEW = "CLOSE_REPLY_TO_MESSAGE_PREVIEW";
export const SET_REPLY_TO_MESSAGE_PREVIEW_HEIGHT =
  "SET_REPLY_TO_MESSAGE_PREVIEW_HEIGHT";
export const TOOGLE_SHOW_SANDBOX_CHECKPOINTS =
  "TOOGLE_SHOW_SANDBOX_CHECKPOINTS";
export const SET_ANNOUNCEMENT_POPUP_VISIBILITY =
  "SET_ANNOUNCEMENT_POPUP_VISIBILITY";
