import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { layout, space, border, flexbox, position } from "styled-system";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import Box from "konnekt/Box";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#feedbe59")};
  padding: 6px 9px;
  width: fit-content;

  & > p {
    font-size: ${(props) => (props.textFontSize ? props.textFontSize : "12px")};
    @media (max-width: 768px) {
      font-size: ${(props) =>
        props.textFontSize ? props.textFontSize : "11px"};
    }
  }

  ${layout}
  ${space}
  ${border}
  ${flexbox}
  ${position}
`;

const WarningRibbon = ({
  text,
  iconName,
  iconColor,
  iconSize,
  bgColor,
  textFont,
  textFontSize,
  iconWidth,
  iconProps,
  textProps = {},
  children,
  ...props
}) => (
  <Container
    bgColor={bgColor}
    textFontSize={textFontSize || textProps?.fontSize}
    textFont={textFont}
    {...props}
  >
    {iconName && (
      <Icon
        iconName={iconName}
        color={iconColor}
        iconSize={iconSize}
        width={iconWidth}
        {...iconProps}
      />
    )}
    <Label
      color="#222222"
      fontWeight="normal"
      ml="12px"
      mb="0px"
      {...textProps}
    >
      {text || children}
    </Label>
  </Container>
);

WarningRibbon.prototypes = {
  text: propTypes.string.isRequired,
  iconName: propTypes.string,
  textFont: propTypes.string,
  textFontSize: propTypes.string,
  iconColor: propTypes.string,
  iconSize: propTypes.string,
  bgColor: propTypes.string,
};

WarningRibbon.defaultProps = {
  iconName: "tooltip",
  textFont: null,
  textFontSize: null,
  iconColor: "#EBBB3D",
  iconSize: "tiny",
  bgColor: null,
};

export default WarningRibbon;
