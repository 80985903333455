import {
  ADD_MEDIA_PREVIEW_ITEM_START,
  ADD_MEDIA_PREVIEW_ITEM_SUCCESS,
  ADD_MEDIA_PREVIEW_ITEM_FAILURE,
  DELETE_MEDIA_PREVIEW_ITEM,
  CLOSE_MEDIA_PREVIEW_VISIBILITY,
  SET_ACTIVE_INDEX_MEDIA_PREVIEW,
  UPDATE_TEXT_MEDIA_PREVEIW,
} from "./MediaPreviewType";

const initialState = {
  showViewer: false,
  dataList: [],
  activeIndex: 0,
  isUploading: false,
};

const MediaPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEDIA_PREVIEW_ITEM_START: {
      return {
        ...state,
        isUploading: true,
        showViewer: true,
      };
    }

    case ADD_MEDIA_PREVIEW_ITEM_SUCCESS: {
      return {
        ...state,
        isUploading: false,
        showViewer: true,
        dataList: [...state.dataList, ...action.payload],
      };
    }

    case ADD_MEDIA_PREVIEW_ITEM_FAILURE: {
      const showViewer = state.dataList.length === 0 ? false : true;

      return {
        ...state,
        isUploading: false,
        showViewer,
      };
    }

    case DELETE_MEDIA_PREVIEW_ITEM: {
      const updatedData = [...state.dataList];

      updatedData.splice(action.payload, 1);

      return {
        ...state,
        dataList: updatedData,
      };
    }
    case CLOSE_MEDIA_PREVIEW_VISIBILITY: {
      return {
        ...state,
        showViewer: false,
        activeIndex: 0,
        dataList: [],
      };
    }

    case SET_ACTIVE_INDEX_MEDIA_PREVIEW: {
      return {
        ...state,
        activeIndex: action.payload,
      };
    }

    case UPDATE_TEXT_MEDIA_PREVEIW: {
      const dataListCopy = [...state.dataList];

      const activeIndexValue = {
        ...dataListCopy[state.activeIndex],
        text: action.payload,
      };

      dataListCopy[state.activeIndex] = activeIndexValue;

      return {
        ...state,
        dataList: dataListCopy,
      };
    }

    default:
      return state;
  }
};

export default MediaPreviewReducer;
