import React from "react";
import Box from "konnekt/Box";
import { ReactComponent as Background404 } from "images/404_background.svg";
import Label from "konnekt/Label";
import Button from "konnekt/Buttons";
import { detectMobile } from "util/utility";

const Error404Page = () => {
  const isMobile = detectMobile(); // not able to use useMediaQuery as this component is outside App.js

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100vw"
      height="100vh"
    >
      <Background404
        width="90vw"
        maxWidth="600"
        height={isMobile ? "200" : "400"}
      />
      <Label
        p="20px"
        color="black"
        variant="text1"
        fontWeight="400"
        textAlign="center"
      >
        There is seems to be an error happening here
      </Label>
      <Button
        text="Return Home"
        type="primary"
        onClick={() => {
          window.location.replace("/");
        }}
      />
    </Box>
  );
};

export default Error404Page;
