import React from "react";
import propTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  typography,
  space,
  border,
  layout,
  position,
  flexbox,
  grid,
  color,
  system,
  background,
} from "styled-system";
import Icon from "konnekt/Icon";
import Loader from "components/Loaders/OnlyLoader";
import WhiteLoader from "konnekt/Loader";
import Tooltip from "konnekt/Tooltip";

const typeToColorMapping = {
  primary: "#ffffff",
  primaryV2: "#FFFFFF",
  primaryV3: "#FFFFFF",
  secondary: "#278351",
  outline: "#222222",
  outlineGreen: "#278351",
  outlineBlue: "#0067DD",
  outlinePrimaryV2: "#00A68B",
  outlinePrimaryV3: "#1e212f",
  alert: "#ffffff",
  basic: "#989898",
  light: "#444444",
  lightV2: "#000000",
  disabled: "#000000",
  disabledV2: "#ffffff",
  disabledV3: "#ffffff",
  disabledPrimary: "#94bfa2",
  disabledAlert: "#fd3a5799",
  disabledSecondary: "#94bfa2",
  disabledWithText: "#989898",
  warning: "#F7BA00",
  brand: "#ffffff",
  brandOutline: "#00A68B",
};

// TODO: use consistent sizes for all variants
const mapFontSizes = {
  lg: "14px",
  normal: "16px",
  small: "14px",
  xs: "12px",
};

const mapFontWeights = {
  lg: 600,
  normal: 400,
  small: 400,
  xs: 500,
};

const mapPadding = {
  lg: "15px 24px",
  normal: "6px 24px",
  small: "6px 16px",
  xs: "6px 8px",
};

const mapLineHeight = {
  lg: "14px",
  normal: "28px",
  small: "24px",
  xs: "18px",
};

const disabled = css`
  background: #e4e4e4;
  color: #000000;
  border: 1px solid #e4e4e4;
  cursor: not-allowed;
  height: 40px;
`;

const disabledV2 = css`
  background: #b1b7cd;
  color: #ffffff;
  border: 1px solid #b1b7cd;
  cursor: not-allowed;
  border-radius: 4px;
  font-weight: ${({ size }) => mapFontWeights[size]};
  font-size: ${({ size }) => mapFontSizes[size]};
  padding: ${({ size }) => mapPadding[size]};
  min-width: auto;
  min-height: auto;
  & > p {
    line-height: ${({ size }) => mapLineHeight[size]} !important;
  }
`;

const disabledV3 = css`
  background: #b1b7cd;
  color: #ffffff;
  border: 1px solid #b1b7cd;
  cursor: not-allowed;
  border-radius: 4px;
  font-weight: ${({ size }) => mapFontWeights[size]};
  font-size: ${({ size }) => mapFontSizes[size]};
  padding: ${({ size }) => mapPadding[size]};
  min-width: auto;
  min-height: auto;
  & > p {
    line-height: ${({ size }) => mapLineHeight[size]} !important;
  }
`;

const disabledWithText = disabled;

const primary = css`
  background: #278351;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #268351;
`;

const primaryV2 = css`
  background: #00a68b;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #00a68b;
  font-weight: ${({ size }) => mapFontWeights[size]};
  font-size: ${({ size }) => mapFontSizes[size]};
  padding: ${({ size }) => mapPadding[size]};
  border-radius: 6px;
  min-width: auto;
  min-height: auto;
  & > p {
    line-height: ${({ size }) => mapLineHeight[size]} !important;
  }
`;

const primaryV3 = css`
  background: #1d4b3e;
  color: #ffffff;
  cursor: pointer;
  font-weight: ${({ size }) => mapFontWeights[size]};
  font-size: ${({ size }) => mapFontSizes[size]};
  padding: ${({ size }) => mapPadding[size]};
  border-radius: 8px;
  min-width: auto;
  min-height: auto;
`;

const secondary = css`
  color: #278351;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #fff;
`;

const outline = css`
  color: #222222;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #222222;
`;

const outlineGreen = css`
  color: #278351;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #278351;
`;

const outlineBlue = css`
  color: #0067dd;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #0067dd;
`;

const outlinePrimaryV2 = css`
  color: #1e212f;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #00a68b;
  font-weight: 400;
  font-size: 16px;
  padding: 6px 24px;
  border-radius: 4px;
`;

const outlinePrimaryV3 = css`
  color: #1e212f;
  background: #d4f4e5;
  cursor: pointer;
  border: 1px solid #1e212f;
  font-weight: 400;
  font-size: 14px;
  padding: 6px 24px;FO
  border-radius: 4px;
  min-width: max-content;
`;

const alert = css`
  color: #ffffff;
  background-color: #fd3a57;
  cursor: pointer;
  border: 1px solid #fd3a57;
`;

const alertV2 = css`
  color: #d91e2d;
  background-color: #fad8db;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  min-height: auto;
  min-width: max-content;
  border-radius: 2px;
`;

const basic = css`
  color: #ffffff;
  background-color: #989898;
  cursor: pointer;
  border: 1px solid #989898;
`;

const light = css`
  color: #444444;
  background-color: #e9e9e9;
  cursor: pointer;
  border: none;
`;

const lightV2 = css`
  color: #000000;
  background-color: #f2f2f2;
  cursor: pointer;
  border: none;
`;

const disabledPrimary = css`
  background: #94bfa2;
  color: #ffffff;
  cursor: unset;
  border: none;
`;

const disabledSecondary = css`
  color: #94bfa2;
  background: #ffffff;
  cursor: unset;
  border: none;
`;

const disabledAlert = css`
  background: #fd3a5799;
  color: #ffffff;
  cursor: unset;
  border: none;
`;

const warning = css`
  background: #f7ba00;
  color: #444444;
  cursor: pointer;
  border: unset;
`;

const info = css`
  background: #1877f2;
  color: #ffffff;
  cursor: pointer;
  border: unset;
`;

const brand = css`
  background: #00a68b;
  color: white;
  cursor: pointer;
  border: unset;
`;

const brandOutline = css`
  background: white;
  border: 1px solid currentColor;
  color: #00a68b;
  cursor: pointer;
`;

const buttonMapping = {
  primary,
  primaryV2,
  primaryV3,
  secondary,
  outline,
  outlineGreen,
  outlineBlue,
  outlinePrimaryV2,
  outlinePrimaryV3,
  disabled,
  disabledV2,
  disabledV3,
  warning,
  alert,
  alertV2,
  basic,
  light,
  lightV2,
  disabledPrimary,
  disabledSecondary,
  disabledAlert,
  disabledWithText,
  info,
  brand,
  brandOutline,
};

const mapIconSize = {
  primaryV2: {
    xs: {
      right: "2xs",
    },
  },
};

const StyledButton = styled.button`
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  border: none;

  transition: 0.2s ease-in-out background-color;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }

  min-width: 120px;
  min-height: 42px;
  ${(props) => buttonMapping[props.type]}

  ${typography}
  ${space}
  ${border}
  ${layout}
  ${position}
  ${flexbox}
  ${grid}
  ${color}
  ${background}

  ${system({
    cursor: {
      property: "cursor",
    },
    whiteSpace: {
      property: "white-space",
    },
    boxShadow: {
      property: "box-shadow",
    },
  })};
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  line-height: 1;
`;

/**
 * @param {"normal" | "small" | "xs"} [size]
 * @returns
 */
const Button = ({
  children,
  onClick,
  iconName,
  type = "primary",
  iconSize,
  iconRight,
  // TODO : Rather than two different props for loading, use one prop. It should be isLoading
  loader,
  loadingIcon,
  iconProps,
  hoverColor,
  size = "normal",
  tooltip,
  tooltipClass,
  tooltipPlace,
  ...props
}) => {
  const getIconSize = (dir = "right") => {
    if (iconSize) {
      return iconSize;
    }

    return mapIconSize[type]?.[size]?.[dir];
  };

  const getLeftIcon = () => {
    if (loadingIcon) {
      if (
        type === "primary" ||
        type === "basic" ||
        type === "alert" ||
        type === "primaryV2"
      ) {
        return <WhiteLoader mr="8px" />;
      }
      return <Loader mr="8px" />;
    }
    if (iconName) {
      return (
        <Icon
          mr="8px"
          iconSize={getIconSize("left")}
          iconName={iconName}
          color={typeToColorMapping[type]}
          {...iconProps}
        />
      );
    }
    return null;
  };

  const renderContent = () => {
    if (loader) {
      if (type === "primary") {
        return <WhiteLoader />;
      }
      return <Loader />;
    }
    return (
      <>
        {getLeftIcon()}
        <Text>{children || props.text}</Text>
        {iconRight && (
          <Icon
            ml="8px"
            iconSize={getIconSize("right")}
            iconName={iconRight}
            color={typeToColorMapping[type]}
            {...iconProps}
          />
        )}
      </>
    );
  };

  const getClassName = () => {
    if (type === "primary") {
      return "haptik-button-primary";
    }
    if (
      type === "outlineGreen" ||
      type === "outlineBlue" ||
      type === "outline" ||
      type === "secondary"
    ) {
      return "haptik-button-secondary";
    }
    if (
      type === "disabled" ||
      type === "disabledWithText" ||
      type === "disabledV2" ||
      type === "disabledV3" ||
      type === "disabledPrimary"
    ) {
      return "haptik-button-disabled";
    }
    return "";
  };

  const className = `${props.className} ${getClassName()}`;
  const renderButton = () => (
    <StyledButton
      hoverColor={hoverColor}
      disabled={
        type === "disabled" ||
        type === "disabledPrimary" ||
        type === "disabledAlert" ||
        type === "disabledV2" ||
        type === "disabledV3" ||
        type === "disabledSecondary"
      }
      size={size}
      onClick={type !== "disabledWithText" && !loadingIcon && onClick}
      type={type}
      {...props}
      className={className}
    >
      {renderContent()}
    </StyledButton>
  );

  if (tooltip) {
    return (
      <Tooltip
        place={tooltipPlace}
        tooltipClass={tooltipClass}
        tooltip={tooltip}
        width="max-content"
      >
        {renderButton()}
      </Tooltip>
    );
  }
  return renderButton();
};

Button.propTypes = {
  onClick: propTypes.func,
  iconName: propTypes.string,
  type: propTypes.oneOf([
    "primary",
    "primaryV2",
    "primaryV3",
    "secondary",
    "outline",
    "outlineGreen",
    "outlineBlue",
    "outlinePrimaryV2",
    "outlinePrimaryV3",
    "basic",
    "disabled",
    "disabledV2",
    "disabledV3",
    "warning",
    "alert",
    "light",
    "lightV2",
    "disabledPrimary",
    "disabledSecondary",
    "disabledAlert",
    "disabledWithText",
    "info",
    "brand",
    "brandOutline",
  ]),
  iconSize: propTypes.oneOf(["large", "normal", "small", "xs", "tiny"]),
  loader: propTypes.bool,
  children: propTypes.node.isRequired,
};

Button.defaultProps = {
  onClick: null,
  iconName: null,
  type: "primary",
  loader: false,
  justifyContent: "center",
};
export default Button;
