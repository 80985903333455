import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";

import { makeNameVariableFirstInList } from "../../util/utility";
import {
  ATTEMPT_TO_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  VERIFY_FAILED,
  VERIFY_SUCCESS,
  ATTEMPT_TO_VERIFY,
  FETCH_USER_PROFILE,
  FETCH_USER_ORG,
  ATTEMPT_TO_LOGOUT,
  FETCH_USER_CHANNELS,
  ADD_USER_CHANNEL,
  ADDING_USER_CHANNEL,
  ORGANIZATION_TAG_LIST_FETCH_STATUS,
  ORGANIZATION_TAG_LIST,
  CREATE_ORGANIZATION_TAG_STATUS,
  CREATE_ORGANIZATION_TAG,
  FETCHING_ALL_ORG_AGENTS,
  FETCH_ALL_ORG_AGENTS,
  UPDATE_WEB_CLIENT_ID,
  CHECK_ORGANIZATION_ONBOARD_STATUS,
  FETCHING_OTP,
  FETCH_OTP,
  VERIFYING_OTP,
  VERIFY_OTP,
  FETCHING_ORGANIZATION_DETAILS,
  UPDATE_ORGANIZATION_ONBOARD_STATUS,
  FETCHING_ORG_EVENT_SOURCES,
  FETCH_ORG_EVENT_SOURCES,
  FETCHING_ORG_QUICK_REPLIES,
  FETCH_ORG_QUICK_REPLIES,
  REMOVING_ORG_QUICK_REPLIES,
  UPDATING_ORG_QUICK_REPLIES,
  FETCHING_ORG_MEMBERS,
  FETCH_ORG_MEMBERS,
  ADDING_ORG_MEMBERS,
  UPDATING_ORG_MEMBERS,
  REMOVING_ORG_MEMBERS,
  ADDING_ORG_QUICK_REPLIES,
  ADDED_ORG_QUICK_REPLIES,
  ADDED_ORG_MEMBERS,
  UPDATED_ORG_MEMBERS,
  REMOVED_ORG_MEMBERS,
  UPDATED_ORG_QUICK_REPLIES,
  REMOVED_ORG_QUICK_REPLIES,
  FETCH_USER_DEVICE_TYPE,
  REQUEST_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GENERATE_VERIFICATION_EMAIL,
  EMAIL_VERIFYING,
  EMAIL_VERIFY,
  VERIFYING_BUSINESS_MANAGER,
  VERIFIED_BUSINESS_MANAGER,
  UPDATING_BUSINESS_MANAGER,
  UPDATED_BUSINESS_MANAGER,
  TOGGLE_CREATE_ACCOUNT_SEGMENT,
  CREATING_ORGANIZATION_CHANNEL,
  CREATED_ORGANIZATION_CHANNEL,
  SET_NETWORK_STATUS,
  SET_QUALITY_RATING,
  TOGGLE_BOTTOM_NAVBAR,
  FETCHING_USER_CHANNELS,
  SET_SOCKET_STATUS,
  SEND_EMAIL_RESET_PASSWORD,
  TOGGLE_RECOVERY_PASSWORD_SEGMENT,
  TOGGLE_RESET_PASSWORD_SEGMENT,
  UPDATING_RESET_PASSWORD,
  RESEND_INVITATION_MEMBER,
  SET_BOX_VALUE,
  RECOVERY_PASSWORD_STATUS,
  SET_AUTHENTICATION,
  SAVE_WIDGET_PREFERENCES,
  SAVE_WIDGET_PREFERENCES_STATUS,
  ADD_NEW_BULK_UPLOAD_SELECTED_TAGS,
  REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS,
  TOGGLE_BULK_UPLOAD_SEGMENT,
  UPDATE_BULK_UPLOAD_SELECTED_TAGS,
  CLEAR_BULK_UPLOAD_NON_CREATED_TAGS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_STATUS,
  TOGGLE_SHOPIFY_SEGMENT,
  FETCHING_BUNDLED_DATA,
  FETCH_BUNDLED_DATA,
  TOGGLE_ADD_QUICKREPLY_MODAL,
  TOGGLE_EDIT_QUICKREPLY_MODAL,
  TOGGLE_DELETE_QUICKREPLY_MODAL,
  TOGGLE_DELETE_TEAMSETTING_MODAL,
  TOGGLE_EDIT_TEAMSETTING_MODAL,
  TOGGLE_PAYMENT_MODAL,
  SET_BUG_REPORT_ISSUE_WITH,
  SET_BUG_REPORT_SUBJECT,
  SET_BUG_REPORT_DETAIL,
  TOGGLE_EMOJI_CARD_MODAL,
  SET_QUICK_REPLY_FIELDS,
  SET_QUICK_REPLY_INPUT_FIELDS,
  SET_QUICK_REPLY_VARIABLE_COUNT,
  SET_TEAM_MEMBER_FIELDS,
  TOGGLE_ADD_TEAM_MEMBER_MODAL,
  SET_ORGANIZATION_DETAIL_INPUT_VALUES,
  SET_USER_TO_CAMP_REDIRECT_DATA,
  FETCHING_FB_EMBEDDED_DATA,
  UPDATE_BUG_REPORT,
  TOGGLE_SIDEBAR_STATUS,
  FETCH_LIST_MESSAGE_DETAILS_SUCCESS,
  UPDATE_LIST_MESSAGE_DETAILS,
  UPDATE_ORG_FOR_FAQ_BOT,
  UPDATE_LIST_MESSAGE_DATA,
  UPDATE_ORG_FOR_E_MANDATE,
  SET_CATALOG_ID,
  FETCH_AUTO_USER_SEGMENTS_TAGS_START,
  FETCH_AUTO_USER_SEGMENTS_TAGS_SUCCESS,
  FETCH_AUTO_USER_SEGMENTS_TAGS_FAILURE,
  UPDATE_AUTO_USER_SEGMENT_START,
  UPDATE_AUTO_USER_SEGMENT_SUCCESS,
  UPDATE_AUTO_USER_SEGMENT_FAILURE,
  SET_IN_PAGE_RIBBON,
  SET_NOTIFICATION_RIBBION_DETAILS,
  SET_SANDBOX_RIBBON_DETAILS,
  CHANGE_SUPPORT_BOT_VISIBILITY,
  SET_ACTIVE_ACCOUNT,
  UPDATE_WELCOME_MESSAGE_STATUS,
  UPDATE_LOAD_BALANCED_STATUS,
  SET_ES_ERROR_POPUP,
  HANDLE_TAGS_FOR_BULK_UPLOAD_FAIL,
  TOGGLE_BUSINESS_VERIFICATION_FAILED_STATE,
} from "./AppActions";
import {
  UPDATE_WIDGET_INFO,
  LOAD_INACTIVE_WIDGET_INFO,
  CREATE_WIDGET_INFO,
} from "modules/Widget/WidgetActions";
import { UPLOADING_BULK_DATA } from "modules/Customer/CustomerActions";

const onboardingStatuses = {
  ONBOARDED: "Onboarded",
  OTP_VERIFICATION_PENDING: "Otp Verification Pending",
  CONTAINER_CONFIGURATION_PENDING: "Container Configuration Pending",
};

const initialState = {
  isOpenAddReplyForm: false,
  isOpenEditReplyForm: false,
  isOpenDeleteReplyForm: false,
  isOpenDeleteMember: false,
  isOpenEditMember: false,
  isVerifyingToken: false,
  isTokenFound: true,
  isAuthenticated: false,
  isLoadingApp: false,
  isBottomNavVisible: true,
  user: {},
  isLoggingIn: false,
  isVerifying: false,
  token: "",
  organizations: [],
  organizationDetailInputValues: {},
  isFetchingOrganizationDetails: false,
  isOrganizationOnboarded: true,
  isOrganizationFirstOnboarded: false,
  channels: [],
  isAddingUserChannel: false,
  channelId: "",
  isCreatingOrganizationTag: false,
  isFetchingOrganizationTags: false,
  tagList: [],
  allTags: {},
  autoUserSegment: {
    isFetching: false,
    updatingSegment: false,
    isAutoUserSegmentLoaded: false,
    data: [],
  },
  isFetchingAgents: false,
  activeAgents: [],
  allAgents: [],
  allAgentsObjectMap: {},
  webClientId: "",
  customer_attributes: [],
  isSendingOTP: false,
  isOTPSent: false,
  isVerifyingOTP: false,
  isVerified: false,
  isFetchingUserChannels: true,
  isQuickRepliesLoadedAlready: false,
  isOrganizationtagsLoadedAlready: false,
  isEventSourcesLoadedAlready: false,
  isFetchingEventSources: false,
  orgEventSources: [],
  orgQuickReplies: [],
  isAddingQuickReplies: false,
  isUpdatingQuickReplies: false,
  isRemovingQuickReplies: false,
  quickReplyFields: {
    slash_command: "",
    quick_reply: "",
  },
  quickReplyVariableCount: 0,
  quickReplyInputfields: [],
  teamMemberInputFields: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "+91",
  },
  isOpenAddTeamMemberModal: false,
  isOpenBusinessVerificationFailedModal: false,
  isOnline: true,
  orgMembers: [],
  isAddingMembers: false,
  isUpdatingMembers: false,
  isUserDeviceMobile: false,
  qualityList: [],
  activeQuality: {},
  bugReportSubject: "",
  bugReportDetail: "",
  bugReportIssueWith: "",
  isOpenEmojiCard: false,
  // Make a seperate file for this
  countryList: [
    {
      label: "India",
      value: "IN",
    },
  ],
  isRegistering: false,
  isRegistered: false,
  isGeneratingEmail: false,
  isVerifyingBusinessManagerId: false,
  isVerifiedBusinessManagerId: false,
  isUpdatingBusinessManagerId: false,
  isUpdatedBusinessManagerId: false,
  selectedAccountSegment: "firstScreen",
  languages: [
    {
      value: "af",
      label: "Afrikaans",
      isActive: true,
    },
    {
      value: "sq",
      label: "Albanian",
      isActive: true,
    },
    {
      value: "ar",
      label: "Arabic",
      isActive: true,
    },
    {
      value: "az",
      label: "Azerbaijani",
      isActive: true,
    },
    {
      value: "bn",
      label: "Bengali",
      isActive: true,
    },
    {
      value: "bg",
      label: "Bulgarian",
      isActive: true,
    },
    {
      value: "ca",
      label: "Catalan",
      isActive: true,
    },
    {
      value: "zh_CN",
      label: "Chinese (CHN)",
      isActive: true,
    },
    {
      value: "zh_HK",
      label: "Chinese (HKG)",
      isActive: true,
    },
    {
      value: "zh_TW",
      label: "Chinese (TAI)",
      isActive: true,
    },
    {
      value: "hr",
      label: "Croatian",
      isActive: true,
    },
    {
      value: "cs",
      label: "Czech",
      isActive: true,
    },
    {
      value: "da",
      label: "Danish",
      isActive: true,
    },
    {
      value: "nl",
      label: "Dutch",
      isActive: true,
    },
    {
      value: "en",
      label: "English",
      isActive: true,
    },
    {
      value: "en_GB",
      label: "English (UK)",
      isActive: true,
    },
    {
      value: "en_US",
      label: "English (US)",
      isActive: true,
    },
    {
      value: "et",
      label: "Estonian",
      isActive: true,
    },
    {
      value: "fil",
      label: "Filipino",
      isActive: true,
    },
    {
      value: "fi",
      label: "Finnish",
      isActive: true,
    },
    {
      value: "fr",
      label: "French",
      isActive: true,
    },
    {
      value: "de",
      label: "German",
      isActive: true,
    },
    {
      value: "el",
      label: "Greek",
      isActive: true,
    },
    {
      value: "gu",
      label: "Gujarati",
      isActive: true,
    },
    {
      value: "ha",
      label: "Hausa",
      isActive: true,
    },
    {
      value: "he",
      label: "Hebrew",
      isActive: true,
    },
    {
      value: "hi",
      label: "Hindi",
      isActive: true,
    },
    {
      value: "hu",
      label: "Hungarian",
      isActive: true,
    },
    {
      value: "id",
      label: "Indonesian",
      isActive: true,
    },
    {
      value: "ga",
      label: "Irish",
      isActive: true,
    },
    {
      value: "it",
      label: "Italian",
      isActive: true,
    },
    {
      value: "ja",
      label: "Japanese",
      isActive: true,
    },
    {
      value: "kn",
      label: "Kannada",
      isActive: true,
    },
    {
      value: "kk",
      label: "Kazakh",
      isActive: true,
    },
    {
      value: "ko",
      label: "Korean",
      isActive: true,
    },
    {
      value: "lo",
      label: "Lao",
      isActive: true,
    },
    {
      value: "lv",
      label: "Latvian",
      isActive: true,
    },
    {
      value: "lt",
      label: "Lithuanian",
      isActive: true,
    },
    {
      value: "mk",
      label: "Macedonian",
      isActive: true,
    },
    {
      value: "ms",
      label: "Malay",
      isActive: true,
    },
    {
      value: "ml",
      label: "Malayalam",
      isActive: true,
    },
    {
      value: "mr",
      label: "Marathi",
      isActive: true,
    },
    {
      value: "nb",
      label: "Norwegian",
      isActive: true,
    },
    {
      value: "fa",
      label: "Persian",
      isActive: true,
    },
    {
      value: "pl",
      label: "Polish",
      isActive: true,
    },
    {
      value: "pt_BR",
      label: "Portuguese (BR)",
      isActive: true,
    },
    {
      value: "pt_PT",
      label: "Portuguese (POR)",
      isActive: true,
    },
    {
      value: "pa",
      label: "Punjabi",
      isActive: true,
    },
    {
      value: "ro",
      label: "Romanian",
      isActive: true,
    },
    {
      value: "ru",
      label: "Russian",
      isActive: true,
    },
    {
      value: "sr",
      label: "Serbian",
      isActive: true,
    },
    {
      value: "sk",
      label: "Slovak",
      isActive: true,
    },
    {
      value: "sl",
      label: "Slovenian",
      isActive: true,
    },
    {
      value: "es",
      label: "Spanish",
      isActive: true,
    },
    {
      value: "es_AR",
      label: "Spanish (ARG)",
      isActive: true,
    },
    {
      value: "es_ES",
      label: "Spanish (SPA)",
      isActive: true,
    },
    {
      value: "es_MX",
      label: "Spanish (MEX)",
      isActive: true,
    },
    {
      value: "sw",
      label: "Swahili",
      isActive: true,
    },
    {
      value: "sv",
      label: "Swedish",
      isActive: true,
    },
    {
      value: "ta",
      label: "Tamil",
      isActive: true,
    },
    {
      value: "te",
      label: "Telugu",
      isActive: true,
    },
    {
      value: "th",
      label: "Thai",
      isActive: true,
    },
    {
      value: "tr",
      label: "Turkish",
      isActive: true,
    },
    {
      value: "uk",
      label: "Ukrainian",
      isActive: true,
    },
    {
      value: "ur",
      label: "Urdu",
      isActive: true,
    },
    {
      value: "uz",
      label: "Uzbek",
      isActive: true,
    },
    {
      value: "vi",
      label: "Vietnamese",
      isActive: true,
    },
    {
      value: "zu",
      label: "Zulu",
      isActive: true,
    },
  ],
  languageDict: {
    af: "Afrikaans",
    ar: "Arabic",
    az: "Azerbaijani",
    bg: "Bulgarian",
    bn: "Bengali",
    ca: "Catalan",
    cs: "Czech",
    da: "Danish",
    de: "German",
    el: "Greek",
    en: "English",
    en_GB: "English (UK)",
    en_US: "English (US)",
    es: "Spanish",
    es_AR: "Spanish (ARG)",
    es_ES: "Spanish (SPA)",
    es_MX: "Spanish (MEX)",
    et: "Estonian",
    fa: "Persian",
    fi: "Finnish",
    fil: "Filipino",
    fr: "French",
    ga: "Irish",
    gu: "Gujarati",
    ha: "Hausa",
    he: "Hebrew",
    hi: "Hindi",
    hr: "Croatian",
    hu: "Hungarian",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    kk: "Kazakh",
    kn: "Kannada",
    ko: "Korean",
    lo: "Lao",
    lt: "Lithuanian",
    lv: "Latvian",
    mk: "Macedonian",
    ml: "Malayalam",
    mr: "Marathi",
    ms: "Malay",
    nb: "Norwegian",
    nl: "Dutch",
    pa: "Punjabi",
    pl: "Polish",
    pt_BR: "Portuguese (BR)",
    pt_PT: "Portuguese (POR)",
    ro: "Romanian",
    ru: "Russian",
    sk: "Slovak",
    sl: "Slovenian",
    sq: "Albanian",
    sr: "Serbian",
    sv: "Swedish",
    sw: "Swahili",
    ta: "Tamil",
    te: "Telugu",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    vi: "Vietnamese",
    zh_CN: "Chinese (CHN)",
    zh_HK: "Chinese (HKG)",
    zh_TW: "Chinese (TAI)",
    zu: "Zulu",
  },
  selectedRecoveryPasswordSegment: "firstScreen",
  isSendingMailForResetPassword: false,
  isRecoveryPassword: false,
  selectedResetPasswordSegment: "firstScreen",
  isUpdatingResetPassword: false,
  isResendingInvitationForMember: false,
  isShowBox: false,
  widgetPreferences: [
    {
      id: "Personal Detail",
      isVisible: true,
      displayOrder: 1,
    },
    {
      id: "Tags",
      isVisible: true,
      displayOrder: 2,
    },
    {
      id: "Notes",
      isVisible: true,
      displayOrder: 3,
    },
    {
      id: "Conversation History",
      isVisible: true,
      displayOrder: 4,
    },
    {
      id: "Events",
      isVisible: true,
      displayOrder: 5,
    },
    {
      id: "Orders",
      isVisible: true,
      displayOrder: 6,
    },
  ],
  allUserPreferences: [],
  selectedBulkUploadSegment: "firstScreen",
  selectedBulkUploadTags: [],
  isSavingWidgetPreferences: false,
  isUpdatingOrganization: false,
  selectedShopifySegment: "installScreen",
  isOpenSubscriptionStartPaymentModal: false,
  isFetchingFbEmbeddedData: false,
  isUserPreferencesLoaded: false,
  isShowSidebar: true,
  convLabelId: "",
  isFetchingOutOfOfficeDetails: false,
  outOfOfficeDetails: {},
  isUpdatingOutOfOfficeDetails: false,
  listMessage: [],
  listMessageDetails: {},
  inPageRibbon: { visible: false, height: 0 },
  notificationRibbonDetails: { visible: false, height: 0 },
  sandboxRibbonDetails: { visible: false, height: 0 },
  supportBotVisibility: false,
  activeAccountId: null,
  ESErrorPopupVisibility: false,
};

const getSanitizeData = (text) => {
  let newBody = "";
  if (text) {
    newBody = text.replace(/&gt;/gi, ">");
    newBody = newBody.replace(/&lt;/gi, "<");
    newBody = newBody.replace(/&nbsp;/gi, " ");
    newBody = newBody.replace(/\u21b5/gi, "\n");
  }
  return newBody;
};

const sortAgentData = (list) => {
  if (list.length > 0) {
    return list.sort((a, b) => {
      if (
        `${a?.first_name}${a?.last_name}`.toLowerCase() <
        `${b?.first_name}${b?.last_name}`.toLowerCase()
      ) {
        return -1;
      }
      if (
        `${a?.first_name}${a?.last_name}`.toLowerCase() >
        `${b?.first_name}${b?.last_name}`.toLowerCase()
      ) {
        return 1;
      }
      return 0;
    });
  }

  return list;
};

const appReducer = (state = initialState, action) => {
  let orgQuickReplies = [];
  let orgMembers = [];
  let channels = [];
  let organizations = [];
  let tagList = [];
  let selectedBulkUploadTags = [];
  let allTags = {};
  const orgEventSources = [];
  const allAgentsObjectMap = {};
  let allUserPreferences = [];

  const changeShopifyWidgetStatus = (status) => {
    const newState = { ...state };
    if (newState.organizations[0]) {
      newState.organizations[0].organization_id.metadata.has_active_shopify_widget = status;
    }
    return newState;
  };

  switch (action.type) {
    case TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        isOpenSubscriptionStartPaymentModal:
          action.isOpenSubscriptionStartPaymentModal,
      };

    case SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccountId: action.activeAccountId,
      };

    case UPDATE_ORGANIZATION_STATUS:
      return {
        ...state,
        isUpdatingOrganization: action.isUpdatingOrganization,
      };

    case UPDATE_ORGANIZATION:
      organizations = state.organizations.map((item) => {
        if (item.organization_id.id === action.organization.id) {
          item.organization_id = action.organization;
        }
        return item;
      });
      return {
        ...state,
        organizations,
        isUpdatingOrganization: action.isUpdatingOrganization,
      };

    case SAVE_WIDGET_PREFERENCES_STATUS:
      return {
        ...state,
        isSavingWidgetPreferences: action.isSavingWidgetPreferences,
      };

    case SAVE_WIDGET_PREFERENCES:
      allUserPreferences =
        !!action.allUserPreferences &&
        !_.isEmpty(action.allUserPreferences) &&
        action.allUserPreferences.length > 0
          ? action.allUserPreferences
          : state.allUserPreferences;
      if (allUserPreferences && allUserPreferences.length > 0) {
        allUserPreferences = makeNameVariableFirstInList(allUserPreferences);
      }
      return {
        ...state,
        isUserPreferencesLoaded: action.isUserPreferencesLoaded,
        isSavingWidgetPreferences: action.isSavingWidgetPreferences,
        widgetPreferences:
          !!action.widgetPreferences &&
          !_.isEmpty(action.widgetPreferences) &&
          action.widgetPreferences.length > 0
            ? action.widgetPreferences
            : state.widgetPreferences,
        allUserPreferences,
      };

    case ATTEMPT_TO_LOGIN:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
      };

    case TOGGLE_CREATE_ACCOUNT_SEGMENT:
      return {
        ...state,
        selectedAccountSegment: action.selectedAccountSegment,
      };

    case CREATING_ORGANIZATION_CHANNEL:
      return {
        ...state,
        isCreatingOrganizationChannel: action.isCreatingOrganizationChannel,
      };

    case CREATED_ORGANIZATION_CHANNEL:
      channels = state.channels;
      if (action.channel) {
        channels.push(action.channel);
      }
      return {
        ...state,
        channels,
        isCreatingOrganizationChannel: action.isCreatingOrganizationChannel,
      };

    case UPDATE_WEB_CLIENT_ID:
      return {
        ...state,
        webClientId: action.webClientId,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
        isAuthenticated: action.isAuthenticated,
        token: action.token,
        user: action.user,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
        isAuthenticated: action.isAuthenticated,
      };

    case VERIFY_FAILED:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        isTokenFound: action.isTokenFound,
      };

    case VERIFY_SUCCESS:
      allUserPreferences =
        !!action.allUserPreferences &&
        !_.isEmpty(action.allUserPreferences) &&
        action.allUserPreferences.length > 0
          ? action.allUserPreferences
          : state.allUserPreferences;
      if (allUserPreferences && allUserPreferences.length > 0) {
        allUserPreferences = makeNameVariableFirstInList(allUserPreferences);
      }
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        token: action.token,
        isUserPreferencesLoaded: action.isUserPreferencesLoaded,
        isSavingWidgetPreferences: action.isSavingWidgetPreferences,
        widgetPreferences:
          !!action.widgetPreferences &&
          !_.isEmpty(action.widgetPreferences) &&
          action.widgetPreferences.length > 0
            ? action.widgetPreferences
            : state.widgetPreferences,
        allUserPreferences,
      };

    case FETCHING_ORGANIZATION_DETAILS:
      return {
        ...state,
        isFetchingOrganizationDetails: action.isFetchingOrganizationDetails,
      };

    case ATTEMPT_TO_LOGOUT:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
        token: action.token,
      };

    case ATTEMPT_TO_VERIFY:
      return {
        ...state,
        isVerifyingToken: action.isVerifyingToken,
        isTokenFound: action.isTokenFound,
      };

    case FETCH_USER_PROFILE:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.user,
      };

    case FETCH_USER_ORG:
      const isVerified =
        action.organizations &&
        action.organizations[0] &&
        action.organizations[0].organization_id &&
        action.organizations[0].organization_id.onboarding_status
          ? action.organizations[0].organization_id.onboarding_status ===
            onboardingStatuses.ONBOARDED
          : false;
      return {
        ...state,
        organizations: action.organizations,
        isVerified,
        isFetchingOrganizationDetails: action.isFetchingOrganizationDetails,
        isOrganizationOnboarded: action.isOrganizationOnboarded,
        organizationDetailInputValues: action.organizations[0].organization_id,
      };

    case UPDATE_ORG_FOR_FAQ_BOT:
      const updatedOrganizationId = state.organizations[0]?.organization_id;
      updatedOrganizationId.is_faq_bot_enabled = action.payload;
      return {
        ...state,
        organizations: {
          ...state.organizations,
          updatedOrganizationId,
        },
      };

    case FETCH_USER_CHANNELS:
      return {
        ...state,
        channels: action.channels,
        isFetchingUserChannels: action.isFetchingUserChannels,
        channelId: action.channelId,
      };

    case ADD_USER_CHANNEL:
      channels = state.channels;
      if (action.channel) {
        channels.push(action.channel);
      }
      return {
        ...state,
        channels,
        isAddingUserChannel: action.isAddingUserChannel,
      };

    case ADDING_USER_CHANNEL:
      return {
        ...state,
        isAddingUserChannel: action.isAddingUserChannel,
      };

    case ORGANIZATION_TAG_LIST_FETCH_STATUS:
      return {
        ...state,
        isFetchingOrganizationTags: action.isFetchingOrganizationTags,
      };
    case ORGANIZATION_TAG_LIST:
      allTags = {};
      action.tagList.forEach((item) => {
        allTags[item.id] = item;
      });
      return {
        ...state,
        isFetchingOrganizationTags: action.isFetchingOrganizationTags,
        tagList: action.tagList,
        isOrganizationtagsLoadedAlready: !!action.isOrganizationtagsLoadedAlready,
        allTags,
      };
    case CREATE_ORGANIZATION_TAG_STATUS:
      return {
        ...state,
        isCreatingOrganizationTag: action.isCreatingOrganizationTag,
      };

    case CREATE_ORGANIZATION_TAG:
      state.allTags[action.tag.id] = action.tag;

      return {
        ...state,
        isCreatingOrganizationTag: action.isCreatingOrganizationTag,
        tagList: [action.tag, ...state.tagList],
        allTags: { ...state.allTags },
      };

    case FETCHING_ALL_ORG_AGENTS:
      return {
        ...state,
        isFetchingAgents: action.isFetchingAgents,
      };

    case FETCH_ALL_ORG_AGENTS:
      if (action.allAgents && action.allAgents.length > 0) {
        action.allAgents.forEach((item) => {
          allAgentsObjectMap[item.id] = item;
        });
      }

      return {
        ...state,
        isFetchingAgents: action.isFetchingAgents,
        activeAgents: sortAgentData(action.activeAgents),
        allAgentsObjectMap,
        allAgents: action.allAgents,
      };

    case CHECK_ORGANIZATION_ONBOARD_STATUS:
      return {
        ...state,
        isOrganizationOnboarded: action.isOrganizationOnboarded,
      };

    case FETCHING_OTP:
      return {
        ...state,
        isSendingOTP: action.isSendingOTP,
      };

    case FETCH_OTP:
      return {
        ...state,
        isSendingOTP: action.isSendingOTP,
        isOTPSent: action.isOTPSent,
      };

    case VERIFYING_OTP:
      return {
        ...state,
        isVerifyingOTP: action.isVerifyingOTP,
      };

    case VERIFY_OTP:
      return {
        ...state,
        isVerifyingOTP: action.isVerifyingOTP,
        isSendingOTP: !!action.isSendingOTP,
        isVerified: action.isVerified,
      };

    case FETCHING_ORG_EVENT_SOURCES:
      return {
        ...state,
        isFetchingEventSources: action.isFetchingEventSources,
      };

    case FETCH_ORG_EVENT_SOURCES:
      if (action.orgEventSources && action.orgEventSources.length > 0) {
        action.orgEventSources.forEach((item) => {
          if (item.app_type == "Custom") {
            orgEventSources.push({
              secret_key: item.secret_key,
              organization_id: item.organization_id,
            });
          }
        });
      }
      return {
        ...state,
        isFetchingEventSources: action.isFetchingEventSources,
        isEventSourcesLoadedAlready: action.isEventSourcesLoadedAlready,
        orgEventSources,
      };

    case FETCHING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isFetchingQuickReplies: action.isFetchingQuickReplies,
      };

    case FETCH_ORG_QUICK_REPLIES:
      action.orgQuickReplies.sort(
        (a, b) =>
          new Date(b.created_at_utc).getTime() -
          new Date(a.created_at_utc).getTime(),
      );
      if (action.orgQuickReplies && action.orgQuickReplies.length > 0) {
        action.orgQuickReplies.forEach((item) => {
          orgQuickReplies.push({
            id: item.id,
            slash_command: getSanitizeData(item.slash_command),
            quick_reply: getSanitizeData(item.quick_reply),
            media_url: getSanitizeData(item.media_url),
            variable_fallback_values: getSanitizeData(
              item.variable_fallback_values,
            ),
            created_by_user: item.created_by_user,
            created_at_utc: item.created_at_utc,
          });
        });
      }
      return {
        ...state,
        isFetchingQuickReplies: action.isFetchingQuickReplies,
        isQuickRepliesLoadedAlready: !!action.isQuickRepliesLoadedAlready,
        orgQuickReplies,
      };

    case UPDATE_ORGANIZATION_ONBOARD_STATUS:
      organizations = state.organizations.map((item) => {
        if (item && item.organization_id) {
          item.organization_id.is_onboarded = action.isOrganizationOnboarded;
          item.organization_id.onboarding_status = action.onBoardingStatus;
        }
        return item;
      });
      return {
        ...state,
        isOrganizationFirstOnboarded: action.isOrganizationFirstOnboarded,
        organizations,
      };

    case ADDING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isAddingQuickReplies: action.isAddingQuickReplies,
      };

    case ADDED_ORG_QUICK_REPLIES:
      orgQuickReplies = state.orgQuickReplies;
      const quickReply = {
        id: action.quickReply.id,
        slash_command: getSanitizeData(action.quickReply.slash_command),
        quick_reply: getSanitizeData(action.quickReply.quick_reply),
        media_url: action.quickReply.media_url,
        variable_fallback_values: action.quickReply.variable_fallback_values,
        created_by_user: action.quickReply.created_by_user,
      };
      if (orgQuickReplies && _.isArray(orgQuickReplies)) {
        orgQuickReplies.unshift(quickReply);
      } else {
        orgQuickReplies = [];
        orgQuickReplies.push(quickReply);
      }
      return {
        ...state,
        orgQuickReplies,
        isAddingQuickReplies: action.isAddingQuickReplies,
      };

    case UPDATING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isUpdatingQuickReplies: action.isUpdatingQuickReplies,
      };

    case UPDATED_ORG_QUICK_REPLIES:
      orgQuickReplies = state.orgQuickReplies;
      orgQuickReplies = state.orgQuickReplies.map((item) => {
        if (item.id === action.quickReply.id) {
          item = {
            id: action.quickReply.id,
            slash_command: getSanitizeData(action.quickReply.slash_command),
            quick_reply: getSanitizeData(action.quickReply.quick_reply),
            media_url: action.quickReply.media_url,
            created_by_user: item.created_by_user,
            variable_fallback_values:
              action.quickReply.variable_fallback_values,
          };
        }
        return item;
      });
      return {
        ...state,
        orgQuickReplies,
        isUpdatingQuickReplies: action.isUpdatingQuickReplies,
      };

    case REMOVING_ORG_QUICK_REPLIES:
      return {
        ...state,
        isRemovingQuickReplies: action.isRemovingQuickReplies,
      };

    case REMOVED_ORG_QUICK_REPLIES:
      orgQuickReplies = state.orgQuickReplies.filter(
        (item) => item.id !== action.quickReply.id,
      );
      return {
        ...state,
        orgQuickReplies,
        isRemovingQuickReplies: action.isRemovingQuickReplies,
      };

    case SET_NETWORK_STATUS:
      return {
        ...state,
        isOnline: action.isOnline,
      };

    case FETCHING_ORG_MEMBERS:
      return {
        ...state,
        isFetchingMembers: action.isFetchingMembers,
      };

    case FETCHING_USER_CHANNELS:
      return {
        ...state,
        isFetchingUserChannels: action.isFetchingUserChannels,
      };

    case FETCH_ORG_MEMBERS:
      if (action.orgMembers && action.orgMembers.length > 0) {
        action.orgMembers.forEach((item) => {
          orgMembers.push({
            id: item.user.id,
            first_name:
              item.user && item.user.first_name ? item.user.first_name : "",
            last_name:
              item.user && item.user.last_name ? item.user.last_name : "",
            email: item.user && item.user.email ? item.user.email : "",
            phone: item.user && item.user.phone ? item.user.phone : "",
            country_code:
              item.user && item.user.country_code ? item.user.country_code : "",
            created_by_user: item.created_by_user,
            last_logged_in_at_utc:
              item.user && item.user.last_logged_in_at_utc
                ? item.user.last_logged_in_at_utc
                : "",
            role: item.role ? item.role : "",
          });
        });
      }
      orgMembers = orgMembers.sort((a, b) => {
        if (_.toLower(a.first_name) < _.toLower(b.first_name)) {
          return -1;
        }
        if (_.toLower(a.first_name) > _.toLower(b.first_name)) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        isFetchingMembers: action.isFetchingMembers,
        orgMembers,
      };

    case ADDING_ORG_MEMBERS:
      return {
        ...state,
        isAddingMembers: action.isAddingMembers,
      };

    case ADDED_ORG_MEMBERS:
      orgMembers = state.orgMembers;
      if (orgMembers && _.isArray(orgMembers)) {
        orgMembers.push(action.member);
      } else {
        orgMembers = [];
        orgMembers.push(action.member);
      }
      orgMembers = orgMembers.sort((a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        orgMembers,
        isAddingMembers: action.isAddingMembers,
      };

    case UPDATING_ORG_MEMBERS:
      return {
        ...state,
        isUpdatingMembers: action.isUpdatingMembers,
      };

    case UPDATED_ORG_MEMBERS:
      orgMembers = state.orgMembers;
      orgMembers = state.orgMembers.map((item) => {
        if (item.id === action.member.id) {
          item.first_name = action.member.first_name;
          item.last_name = action.member.last_name;
          item.phone = action.member.phone;
          item.role = action.member.role;
        }
        return item;
      });
      return {
        ...state,
        orgMembers,
        isUpdatingMembers: action.isUpdatingMembers,
      };

    case REMOVING_ORG_MEMBERS:
      return {
        ...state,
        isRemovingMembers: action.isRemovingMembers,
      };

    case REMOVED_ORG_MEMBERS:
      orgMembers = state.orgMembers.filter(
        (item) => item.id !== action.member.id,
      );
      return {
        ...state,
        orgMembers,
        isRemovingMembers: action.isRemovingMembers,
      };

    case FETCH_USER_DEVICE_TYPE:
      return {
        ...state,
        IsUserDeviceMobile: action.IsUserDeviceMobile,
      };

    case REQUEST_REGISTER:
      return {
        ...state,
        isRegistering: action.isRegistering,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegistering: action.isRegistering,
        isRegistered: action.isRegistered,
      };

    case REGISTER_FAILURE:
      return {
        ...state,
        isRegistering: action.isRegistering,
      };

    case GENERATE_VERIFICATION_EMAIL:
      return {
        ...state,
        isGeneratingEmail: action.isGeneratingEmail,
      };

    case EMAIL_VERIFYING:
      return {
        ...state,
        isVerifyingEmail: action.isVerifyingEmail,
      };

    case EMAIL_VERIFY:
      return {
        ...state,
        isVerifyingEmail: action.isVerifyingEmail,
        isVerifiedEmail: action.isVerifiedEmail,
      };

    case VERIFYING_BUSINESS_MANAGER:
      return {
        ...state,
        isVerifyingBusinessManagerId: action.isVerifyingBusinessManagerId,
      };

    case VERIFIED_BUSINESS_MANAGER:
      return {
        ...state,
        isVerifyingBusinessManagerId: action.isVerifyingBusinessManagerId,
        isVerifiedBusinessManagerId: action.isVerifiedBusinessManagerId,
      };

    case UPDATING_BUSINESS_MANAGER:
      return {
        ...state,
        isUpdatingBusinessManagerId: action.isUpdatingBusinessManagerId,
      };

    case UPDATED_BUSINESS_MANAGER:
      organizations = state.organizations.map((item) => {
        if (item.organization_id.id === action.organization.id) {
          return { organization_id: action.organization };
        }
        return item;
      });
      return {
        ...state,
        organizations,
        isUpdatingBusinessManagerId: action.isUpdatingBusinessManagerId,
        isUpdatedBusinessManagerId: action.isUpdatedBusinessManagerId,
      };

    case SET_QUALITY_RATING:
      return {
        ...state,
        activeQuality: action.activeQuality,
        qualityList: action.qualityList,
      };

    case TOGGLE_BOTTOM_NAVBAR:
      return {
        ...state,
        isBottomNavVisible: action.isBottomNavVisible,
      };

    case TOGGLE_RECOVERY_PASSWORD_SEGMENT:
      return {
        ...state,
        selectedRecoveryPasswordSegment: action.selectedRecoveryPasswordSegment,
      };

    case RECOVERY_PASSWORD_STATUS:
      return {
        ...state,
        isRecoveryPassword: action.isRecoveryPassword,
      };

    case SEND_EMAIL_RESET_PASSWORD:
      return {
        ...state,
        isSendingMailForResetPassword: action.isSendingMailForResetPassword,
      };

    case TOGGLE_RESET_PASSWORD_SEGMENT:
      return {
        ...state,
        selectedResetPasswordSegment: action.selectedResetPasswordSegment,
      };

    case UPDATING_RESET_PASSWORD:
      return {
        ...state,
        isUpdatingResetPassword: action.isUpdatingResetPassword,
      };

    case RESEND_INVITATION_MEMBER:
      orgMembers = state.orgMembers.map((item) => {
        if (item.email === action.email) {
          item.isResendingInvitationForMember = !item.isResendingInvitationForMember;
        }
        return item;
      });
      return {
        ...state,
        orgMembers,
        isResendingInvitationForMember: action.isResendingInvitationForMember,
      };

    case SET_BOX_VALUE:
      return {
        ...state,
        isShowBox: action.isShowBox,
      };

    case SET_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case TOGGLE_BULK_UPLOAD_SEGMENT:
      return {
        ...state,
        selectedBulkUploadSegment: action.selectedBulkUploadSegment,
      };

    case ADD_NEW_BULK_UPLOAD_SELECTED_TAGS:
      tagList = state.tagList;
      allTags = state.allTags;
      tagList.push(action.tag);
      allTags[action.tag.id] = action.tag;
      selectedBulkUploadTags = state.selectedBulkUploadTags;
      selectedBulkUploadTags.push(action.tag.id);
      return {
        ...state,
        tagList,
        allTags,
        selectedBulkUploadTags,
      };

    case REMOVE_NEW_BULK_UPLOAD_SELECTED_TAGS:
      allTags = state.allTags;
      tagList = state.tagList;
      if (allTags[action.tagId] && allTags[action.tagId].isTagNotCreated) {
        tagList = state.tagList.filter((item) => item.id !== action.tagId);
        delete allTags[action.tagId];
      }
      selectedBulkUploadTags = state.selectedBulkUploadTags.filter(
        (item) => item !== action.tagId,
      );
      return {
        ...state,
        tagList,
        allTags,
        selectedBulkUploadTags,
      };

    case UPDATE_BULK_UPLOAD_SELECTED_TAGS:
      return {
        ...state,
        selectedBulkUploadTags: action.selectedBulkUploadTags,
      };

    case CLEAR_BULK_UPLOAD_NON_CREATED_TAGS: {
      allTags = state.allTags;
      const nonCreatedTags = state.tagList.filter(
        (item) => item.isTagNotCreated,
      );
      tagList = state.tagList.filter((item) => !item.isTagNotCreated);
      nonCreatedTags.forEach((item) => {
        delete allTags[item.id];
      });
      return {
        ...state,
        tagList,
        allTags,
        selectedBulkUploadTags: [],
      };
    }

    case TOGGLE_SHOPIFY_SEGMENT:
      return {
        ...state,
        selectedShopifySegment: action.selectedShopifySegment,
      };

    case FETCHING_BUNDLED_DATA:
      return {
        ...state,
        isFetchingUserChannels: action.isFetchingUserChannels,
        isFetchingAgents: action.isFetchingAgents,
      };

    case FETCH_BUNDLED_DATA:
      if (action.allAgents && action.allAgents.length > 0) {
        action.allAgents.forEach((item) => {
          allAgentsObjectMap[item.id] = item;
        });
      }
      return {
        ...state,
        isFetchingUserChannels: action.isFetchingUserChannels,
        isFetchingAgents: action.isFetchingAgents,
        channels: action.channels,
        channelId: action.channelId,
        allAgents: action.allAgents,
        allAgentsObjectMap,
        activeAgents: sortAgentData(action.activeAgents),
        catalogId: action.catalogId,
      };

    case TOGGLE_ADD_QUICKREPLY_MODAL:
      return {
        ...state,
        isOpenAddReplyForm: action.isOpenAddReplyForm,
      };

    case TOGGLE_EDIT_QUICKREPLY_MODAL:
      return {
        ...state,
        isOpenEditReplyForm: action.isOpenEditReplyForm,
      };

    case TOGGLE_DELETE_QUICKREPLY_MODAL:
      return {
        ...state,
        isOpenDeleteReplyForm: action.isOpenDeleteReplyForm,
      };

    case TOGGLE_DELETE_TEAMSETTING_MODAL:
      return {
        ...state,
        isOpenDeleteMember: action.isOpenDeleteMember,
      };
    case TOGGLE_EDIT_TEAMSETTING_MODAL:
      return {
        ...state,
        isOpenEditMember: action.isOpenEditMember,
      };

    case TOGGLE_EMOJI_CARD_MODAL:
      return {
        ...state,
        isOpenEmojiCard: action.isOpenEmojiCard,
      };

    case SET_BUG_REPORT_ISSUE_WITH:
      return {
        ...state,
        bugReportIssueWith: action.text,
      };

    case SET_BUG_REPORT_SUBJECT:
      return {
        ...state,
        bugReportSubject: action.text,
      };

    case SET_BUG_REPORT_DETAIL:
      return {
        ...state,
        bugReportDetail: action.text,
      };
    case UPDATE_BUG_REPORT:
      return {
        ...state,
        bugReportSubject: action.bugReportSubject,
        bugReportDetail: action.bugReportDetail,
        bugReportIssueWith: action.bugReportIssueWith,
      };
    case SET_QUICK_REPLY_FIELDS:
      return {
        ...state,
        quickReplyFields: action.data,
      };

    case SET_QUICK_REPLY_VARIABLE_COUNT:
      return {
        ...state,
        quickReplyVariableCount: action.count,
      };

    case SET_QUICK_REPLY_INPUT_FIELDS:
      return {
        ...state,
        quickReplyInputfields: action.data,
      };

    case SET_TEAM_MEMBER_FIELDS:
      return {
        ...state,
        teamMemberInputFields: action.data,
      };

    case TOGGLE_ADD_TEAM_MEMBER_MODAL:
      return {
        ...state,
        isOpenAddTeamMemberModal: action.isOpenAddTeamMemberModal,
      };

    case SET_ORGANIZATION_DETAIL_INPUT_VALUES:
      return {
        ...state,
        organizationDetailInputValues: action.organizationDetailInputValues,
      };

    case FETCHING_FB_EMBEDDED_DATA:
      return {
        ...state,
        isFetchingFbEmbeddedData: action.val,
      };

    case TOGGLE_SIDEBAR_STATUS:
      return {
        ...state,
        isShowSidebar: action.isShowSidebar,
      };

    case UPDATE_WIDGET_INFO:
      return changeShopifyWidgetStatus(true);

    case LOAD_INACTIVE_WIDGET_INFO:
      return changeShopifyWidgetStatus(false);

    case CREATE_WIDGET_INFO:
      return changeShopifyWidgetStatus(true);

    case FETCH_LIST_MESSAGE_DETAILS_SUCCESS: {
      // Todo remove this logic after BE fixed
      const listMessage = action.payload?.filter(
        (item) => item?.message_content?.length,
      );

      return {
        ...state,
        listMessage,
        listMessageDetails: listMessage?.[0],
      };
    }

    case UPDATE_LIST_MESSAGE_DATA:
      return {
        ...state,
        listMessageDetails: action.payload,
      };

    case UPDATE_LIST_MESSAGE_DETAILS: {
      const updatedData = {
        body_text: action.payload.body_text,
      };

      return {
        ...state,
        listMessageDetails: { ...state.listMessageDetails, ...updatedData },
      };
    }

    case UPDATE_ORG_FOR_E_MANDATE: {
      const org = state.organizations[0]?.organization_id;
      org.is_mandate_migrated = action.payload;
      return {
        ...state,
        organizations: {
          ...state.organizations,
          org,
        },
      };
    }

    case SET_CATALOG_ID: {
      const orgCopy = [...state.organizations];
      orgCopy[0] = {
        ...orgCopy[0],
        organization_id: {
          ...orgCopy[0].organization_id,
          metadata: {
            ...orgCopy[0].organization_id.metadata,
            catalog_id: action.payload,
          },
        },
      };

      return {
        ...state,
        organizations: orgCopy,
      };
    }

    case FETCH_AUTO_USER_SEGMENTS_TAGS_START: {
      return {
        ...state,
        autoUserSegment: { ...state.autoUserSegment, isFetching: true },
      };
    }

    case FETCH_AUTO_USER_SEGMENTS_TAGS_SUCCESS: {
      return {
        ...state,
        autoUserSegment: {
          ...state.autoUserSegment,
          data: action.payload,
          isFetching: false,
          isAutoUserSegmentLoaded: true,
        },
      };
    }

    case FETCH_AUTO_USER_SEGMENTS_TAGS_FAILURE: {
      return {
        ...state,
        autoUserSegment: {
          ...state.autoUserSegment,
          isFetching: false,
          isAutoUserSegmentLoaded: true,
        },
      };
    }

    case UPDATE_AUTO_USER_SEGMENT_START: {
      return {
        ...state,
        autoUserSegment: { ...state.autoUserSegment, updatingSegment: true },
      };
    }

    case UPDATE_AUTO_USER_SEGMENT_SUCCESS: {
      return {
        ...state,
        autoUserSegment: {
          ...state.autoUserSegment,
          updatingSegment: false,
          data: [...action.payload],
        },
      };
    }

    case UPDATE_AUTO_USER_SEGMENT_FAILURE: {
      return {
        ...state,
        autoUserSegment: { ...state.autoUserSegment, updatingSegment: false },
      };
    }

    case SET_IN_PAGE_RIBBON: {
      return {
        ...state,
        inPageRibbon: {
          visible: action.payload.visibility,
          height: action.payload.height,
        },
      };
    }

    case SET_NOTIFICATION_RIBBION_DETAILS: {
      return {
        ...state,
        notificationRibbonDetails: {
          visible: action.payload.visibility,
          height: action.payload.height,
        },
      };
    }

    case SET_SANDBOX_RIBBON_DETAILS: {
      return {
        ...state,
        sandboxRibbonDetails: {
          visible: action.payload.visibility,
          height: action.payload.height,
        },
      };
    }

    case CHANGE_SUPPORT_BOT_VISIBILITY:
      return {
        ...state,
        supportBotVisibility: action.payload,
      };

    case UPDATE_WELCOME_MESSAGE_STATUS: {
      const organizationCopy = cloneDeep(state.organizations);

      organizationCopy[0].organization_id.is_welcome_message_enabled =
        action.payload;

      return {
        ...state,
        organizations: organizationCopy,
      };
    }

    case UPDATE_LOAD_BALANCED_STATUS: {
      const organizationCopy = cloneDeep(state.organizations);

      organizationCopy[0].organization_id.is_load_balance_enabled =
        action.payload;

      return {
        ...state,
        organizations: organizationCopy,
      };
    }

    case UPLOADING_BULK_DATA: {
      const updatedTags = {};

      Object.keys(action.payload).forEach((key) => {
        updatedTags[key] = {
          ...state.allTags[key],
          bulk_upload_in_progress: true,
        };
      });

      return {
        ...state,
        allTags: { ...state.allTags, ...updatedTags },
      };
    }

    case HANDLE_TAGS_FOR_BULK_UPLOAD_FAIL: {
      const updatedTags = {};

      Object.keys(action.payload).forEach((key) => {
        updatedTags[key] = {
          ...state.allTags[key],
          bulk_upload_in_progress: false,
        };
      });

      return {
        ...state,
        allTags: { ...state.allTags, ...updatedTags },
      };
    }

    case TOGGLE_BUSINESS_VERIFICATION_FAILED_STATE: {
      return {
        ...state,
        isOpenBusinessVerificationFailedModal: action.payload,
      };
    }

    case SET_ES_ERROR_POPUP:
      return { ...state, ESErrorPopupVisibility: action.payload };

    default:
      return state;
  }
};

export default appReducer;
