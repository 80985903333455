import React from "react";
import async from "async";
import JSON5 from "json5";
import { v4 as uuidv4 } from "uuid";

import { addToast } from "../App/ToastActions";
import callApi, { callApiV2 } from "../../util/apiCaller";
import { updateCustomerDetailsInChats } from "../Inbox/ThreadActions";
import {
  clearNonCreatedTags,
  CREATE_ORGANIZATION_TAG,
  CREATE_ORGANIZATION_TAG_STATUS,
  handleTagsForBulkUploadFail,
  toggleBulkUploadSelectedSegment,
} from "../App/AppActions";
import Icon from "konnekt/Icon";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import { showNotification, showCustomNotification } from "util/utility";
import { getSelectedOrganizationId } from "modules/App/AppSelectors";
import { toast } from "react-toastify";
import { Mixpanel } from "util/tracking";
import {
  INT_API_CUSTOMER_EVENT_ATTRIBUTES,
  INT_API_CUSTOMERS_ATTRIBUTES,
  INT_API_TAGS,
} from "constants/mixPanel/api";

export const TOGGLE_BULK_UPLOAD_SUCCESS_STATUS =
  "TOGGLE_BULK_UPLOAD_SUCCESS_STATUS";

export const UPDATE_ACTIVE_CUSTOMER = "UPDATE_ACTIVE_CUSTOMER";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_CUSTOMER_STATUS = "FETCH_CUSTOMER_STATUS";
export const UPDATE_CUSTOMER_TRAIT_START = "UPDATE_CUSTOMER_TRAIT_START";
export const UPDATE_CUSTOMER_TRAIT_SUCCESS = "UPDATE_CUSTOMER_TRAIT_SUCCESS";
export const UPDATE_CUSTOMER_TRAIT_FAILURE = "UPDATE_CUSTOMER_TRAIT_FAILURE";

export const TOGGLE_CUSTOMER_NOTE = "TOGGLE_CUSTOMER_NOTE";
export const UPDATING_CUSTOMER_TAGS = "UPDATING_CUSTOMER_TAGS";
export const UPDATE_CUSTOMER_TAGS = "UPDATE_CUSTOMER_TAGS";

export const FETCH_NOTE_LIST_STATUS = "FETCH_NOTE_LIST_STATUS";
export const FETCH_NOTE_LIST = "FETCH_NOTE_LIST";
export const FETCH_NOTE = "FETCH_NOTE";
export const CREATE_NOTE = "CREATE_NOTE";
export const CREATE_NOTE_STATUS = "CREATE_NOTE_STATUS";
export const FETCH_NOTE_STATUS = "FETCH_NOTE_STATUS";
export const DELETE_NOTE_STATUS = "DELETE_NOTE_STATUS";
export const DELETE_NOTE = "DELETE_NOTE";
export const UPDATE_NOTE_STATUS = "UPDATE_NOTE_STATUS";
export const UPDATE_NOTE_DETAIL = "UPDATE_NOTE_DETAIL";
export const UPDATE_SHOPIFY_CUSTOMER_SYNC_FINAL_STATE =
  "UPDATE_SHOPIFY_CUSTOMER_SYNC_FINAL_STATE";

export const FETCH_CHAT_LIST_STATUS = "FETCH_CHAT_LIST_STATUS";
export const FETCH_CHAT_LIST = "FETCH_CHAT_LIST";
export const FETCH_CUSTOMER_DETAILS = "FETCH_CUSTOMER_DETAILS";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const FETCH_CUSTOMER_ATTRIBUTES = "FETCH_CUSTOMER_ATTRIBUTES";
export const FETCH_EVENT_ATTRIBUTES = "FETCH_EVENT_ATTRIBUTES";
export const FETCH_CUSTOMER_EVENT_STATUS = "FETCH_CUSTOMER_EVENT_STATUS";
export const FETCH_CUSTOMER_EVENTS = "FETCH_CUSTOMER_EVENTS";
export const FETCH_CUSTOMER_ATTRIBUTES_STATUS =
  "FETCH_CUSTOMER_ATTRIBUTES_STATUS";
export const FETCH_EVENT_ATTRIBUTES_STATUS = "FETCH_EVENT_ATTRIBUTES_STATUS";
export const BULK_UPLOAD = "BULK_UPLOAD";

export const UPLOADING_BULK_DATA = "UPLOADING_BULK_DATA";
export const UPLOADED_BULK_DATA = "UPLOADED_BULK_DATA";
export const ERROR_WHILE_UPLOADING_DATA = "ERROR_WHILE_UPLOADING_DATA";
export const BULK_TAG_CUSTOMER_STATUS = "BULK_TAG_CUSTOMER_STATUS";
export const BULK_TAG_CUSTOMER = "BULK_TAG_CUSTOMER";
export const SAVE_USER_COLUMN_PREFERENCES = "SAVE_USER_COLUMN_PREFERENCES";
export const SAVE_USER_COLUMN_PREFERENCES_STATUS =
  "SAVE_USER_COLUMN_PREFERENCES_STATUS";
export const FETCH_ORDER_LIST = "FETCH_ORDER_LIST";
export const FETCH_ORDER_LIST_STATUS = "FETCH_ORDER_LIST_STATUS";
export const UPDATE_APPLIED_FILTER = "UPDATE_APPLIED_FILTER";
export const TOGGLE_SINGLE_ADD_CUSTOMER_MODAL =
  "TOGGLE_SINGLE_ADD_CUSTOMER_MODAL";
export const TOGGLE_BULK_TAG_CUSTOMER_MODAL = "TOGGLE_BULK_TAG_CUSTOMER_MODAL";
export const TOGGLE_BULK_TAG_CONFIRMATION_MODAL =
  "TOGGLE_BULK_TAG_CONFIRMATION_MODAL";

export const ADD_USERS_TO_SELECTION = "ADD_USERS_TO_SELECTION";
export const REMOVE_USERS_FROM_SELECTION = "REMOVE_USERS_FROM_SELECTION";
export const CLEAR_ALL_USERS_FROM_SELECTION = "CLEAR_ALL_USERS_FROM_SELECTION";
export const ADD_BULK_USERS_TO_SELECTION = "ADD_BULK_USERS_TO_SELECTION";
export const UPDATE_CSV_FILE_NAME = "UPDATE_CSV_FILE_NAME";
export const UPDATE_CSV_FILE_URL = "UPDATE_CSV_FILE_URL";
export const ADD_ALL_USERS_IN_SELECTION = "ADD_ALL_USERS_IN_SELECTION";

export const SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE =
  "SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE";

export const UPDATE_CUSTOMERS_FINAL_FILTERS = "UPDATE_CUSTOMERS_FINAL_FILTERS";

export const UPLOADING_BULK_DATA_SUCCESS = "UPLOADING_BULK_DATA_SUCCESS";

export function toggleSingleCustomerAddModal(val) {
  return {
    type: TOGGLE_SINGLE_ADD_CUSTOMER_MODAL,
    isOpenAddContactForm: !!val,
  };
}

export function addBulkUserToSelection(users) {
  return {
    type: ADD_BULK_USERS_TO_SELECTION,
    users,
  };
}

export function addUserToSelection(userId) {
  return {
    type: ADD_USERS_TO_SELECTION,
    userId,
  };
}

export function removeUserFromSelection(userId) {
  return {
    type: REMOVE_USERS_FROM_SELECTION,
    userId,
  };
}

export function removeAllUsersFromSelection() {
  return {
    type: CLEAR_ALL_USERS_FROM_SELECTION,
  };
}

export function selectAllUsersList(payload) {
  return {
    type: ADD_ALL_USERS_IN_SELECTION,
    payload,
  };
}

export function toggleBulkCustomerTagModal(val) {
  return {
    type: TOGGLE_BULK_TAG_CUSTOMER_MODAL,
    isOpenAddTagForm: !!val,
  };
}

export function toggleBulkConfirmtaionTagModal(val) {
  return {
    type: TOGGLE_BULK_TAG_CONFIRMATION_MODAL,
    isOpenAddTagConfirmationForm: !!val,
  };
}

export function updateAppliedFilter(filter) {
  return {
    type: UPDATE_APPLIED_FILTER,
    updatedAppliedFilters: filter,
  };
}

export function bulkUpload(data, filename) {
  return {
    type: BULK_UPLOAD,
    csv_data: data,
    csv_filename: filename,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    pageSize,
  };
}

export function updateActiveCustomer(customer) {
  return {
    type: UPDATE_ACTIVE_CUSTOMER,
    activeCustomer: customer,
  };
}

export function getCustomerById(organizationId, customerId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUSTOMER_STATUS,
      isFetchingCustomer: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/`,
      "get",
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_CUSTOMER,
          activeCustomer: res.data,
          isFetchingCustomer: false,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER_STATUS,
          isFetchingCustomer: false,
        });
      }
    });
  };
}

export function updateUserTraits(data) {
  return (dispatch, getState) => {
    const organizationId = getState().app?.organizations[0]?.organization_id.id;
    const customerId = window.location.pathname.includes("/contacts/")
      ? getState().customer.activeCustomerAllUsersPage
      : getState().thread?.activeConversation?.customer_id?.id;

    let trimmedData = {};

    data &&
      Object.keys(data).forEach((key) => {
        trimmedData = { ...trimmedData, [key]: data[key].trim() };
      });

    dispatch({
      type: UPDATE_CUSTOMER_TRAIT_START,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/`,
      "put",
      { traits: trimmedData },
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: UPDATE_CUSTOMER_TRAIT_SUCCESS,
          payload: { customerId, data: trimmedData },
        });
        showNotification("success", "User card details updated successfully");
      } else {
        dispatch({
          type: UPDATE_CUSTOMER_TRAIT_FAILURE,
        });
        showNotification("error", res.message);
      }
    });
  };
}

export function updateCustomerTags(organizationId, customerId, update) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_CUSTOMER_TAGS,
      isUpdatingCustomerTags: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/`,
      "put",
      update,
    ).then((res) => {
      if (res && res.result) {
        dispatch({
          type: UPDATE_CUSTOMER_TAGS,
          isUpdatingCustomerTags: false,
          activeCustomer: update,
        });

        showNotification("success", "Tags updated successfully");
      } else {
        dispatch({
          type: UPDATING_CUSTOMER_TAGS,
          isUpdatingCustomerTags: false,
        });
        showNotification("warning", res.message);
      }
    });
  };
}

export function getAllNotes(organizationId, customerId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_NOTE_LIST_STATUS,
      isFetchingNoteList: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/?page=1&page_size=2000`,
      "get",
    ).then((res) => {
      if (res.results && res.results.data) {
        dispatch({
          type: FETCH_NOTE_LIST,
          customerId,
          noteList: res.results.data,
          isFetchingNoteList: false,
        });
      } else {
        dispatch({
          type: FETCH_NOTE_LIST_STATUS,
          isFetchingNoteList: false,
        });
      }
    });
  };
}

export function getNoteById(organizationId, customerId, noteId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_NOTE_STATUS,
      isFetchingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/${noteId}/`,
      "get",
    ).then((res) => {
      if (res.data) {
        dispatch({
          type: FETCH_NOTE,
          activeNote: res.data,
          isFetchingNote: false,
        });
      } else {
        dispatch({
          type: FETCH_NOTE_STATUS,
          isFetchingNote: false,
        });
      }
    });
  };
}

export function deleteNoteById(organizationId, customerId, noteId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_NOTE_STATUS,
      isDeletingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/${noteId}/`,
      "DELETE",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: DELETE_NOTE,
          noteId,
          isDeletingNote: false,
          customerId,
        });

        showNotification("success", "Note deleted successfully");
      } else {
        dispatch({
          type: DELETE_NOTE_STATUS,
          isDeletingNote: false,
        });

        showNotification("error", res.message);
      }
    });
  };
}

export function createNewNote(organizationId, customerId, note) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_NOTE_STATUS,
      isCreatingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/`,
      "post",
      note,
    ).then((res) => {
      const userId = getState()?.app?.user?.id;

      if (res && res.id) {
        note.id = res.id;
        dispatch(addToast(res.message, "success", 3));
        dispatch({
          type: CREATE_NOTE,
          customerId,
          isCreatingNote: false,
          note,
          userId,
        });
        showNotification("success", "Note added successfully");
      } else {
        dispatch({
          type: CREATE_NOTE_STATUS,
          isCreatingNote: false,
        });

        showNotification("error", res?.message);
      }
    });
  };
}

export function updateNoteDetails(organizationId, customerId, noteId, note) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_NOTE_STATUS,
      isUpdatingNote: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/notes/${noteId}/`,
      "put",
      note,
    ).then((res) => {
      const userId = getState()?.app?.user?.id;

      if (res && res.result) {
        dispatch(addToast(res.message, "success", 3));
        dispatch({
          type: UPDATE_NOTE_DETAIL,
          customerId,
          isUpdatingNote: false,
          activeNote: note,
          noteId,
          userId,
        });
        showNotification("success", "Notes updated successfully");
      } else {
        dispatch({
          type: UPDATE_NOTE_STATUS,
          isUpdatingNote: false,
        });

        showNotification("error", res?.message);
      }
    });
  };
}

export function fetchCustomerActiveChats(organizationId, customerId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CHAT_LIST_STATUS,
      isFetchingChatList: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/chats/customers/${customerId}/conversations/`,
      "get",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_CHAT_LIST,
          customerId,
          chatList: res.data,
          isFetchingChatList: false,
        });
      } else {
        dispatch({
          type: FETCH_CHAT_LIST_STATUS,
          isFetchingChatList: false,
        });
      }
    });
  };
}

export function fetchCustomerFilterAttributes(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUSTOMER_ATTRIBUTES_STATUS,
      isFetchingCustomerAttributes: true,
    });

    Mixpanel.track(INT_API_CUSTOMERS_ATTRIBUTES, {});

    return callApi(
      `v1/organizations/${organizationId}/customers/attributes/`,
      "get",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_CUSTOMER_ATTRIBUTES,
          customer_attributes: res.data
            ? res.data &&
              res.data.length > 0 &&
              res.data[0].organization_id &&
              res.data[0].attributes
            : {},
          isFetchingCustomerAttributes: false,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER_ATTRIBUTES_STATUS,
          isFetchingCustomerAttributes: false,
        });
      }
    });
  };
}

export function fetchEventFilterAttributes(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_EVENT_ATTRIBUTES_STATUS,
      isFetchingEventAttributes: true,
    });

    Mixpanel.track(INT_API_CUSTOMER_EVENT_ATTRIBUTES, {});

    return callApi(
      `v1/organizations/${organizationId}/customers/event/attributes/`,
      "get",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_EVENT_ATTRIBUTES,
          event_name:
            res.data && res.data.length > 0 && res.data[0]
              ? res.data[0].event_name
              : "",
          eventAttributeList: res.data,
          isFetchingEventAttributes: false,
        });
      } else {
        dispatch({
          type: FETCH_EVENT_ATTRIBUTES_STATUS,
          isFetchingEventAttributes: true,
        });
      }
    });
  };
}

export function getCustomerEventsByCustomerId(
  organizationId,
  customerId,
  pageNum,
  pageSize = 1000,
) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUSTOMER_EVENT_STATUS,
      isFetchingCustomerEvents: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/${customerId}/events/?offset=${
        (pageNum - 1) * pageSize
      }&limit=${pageSize}`,
      "get",
    ).then((res) => {
      if (res.results) {
        dispatch({
          type: FETCH_CUSTOMER_EVENTS,
          pageNum,
          customerId,
          customerEvents: res.results.data,
          totalCustomerEventCount: res.count,
          isFetchingCustomerEvents: false,
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER_EVENT_STATUS,
          isFetchingCustomerEvents: false,
        });
      }
    });
  };
}

export function toggleCustomerNoteActive(customerId, noteId) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_CUSTOMER_NOTE,
      customerId,
      noteId,
    });
  };
}

export function toggleBulkUploadSuccess(isBulUploadSuccessModalVisible) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_BULK_UPLOAD_SUCCESS_STATUS,
      isBulUploadSuccessModalVisible,
    });
  };
}

export function updateBulkCustomersByCSV(
  organizationId,
  data,
  selectedTags,
  tagsNotCreated,
  history,
) {
  return (dispatch) => {
    dispatch({
      type: UPLOADING_BULK_DATA,
      isUploadingBulkCSVData: true,
    });
    let isUploadSuccess = true;

    async.series(
      [
        (cb) => {
          if (tagsNotCreated && tagsNotCreated.length > 0) {
            Mixpanel.track(INT_API_TAGS, {});

            async.each(
              tagsNotCreated,
              (tag, callback) => {
                callApi(
                  `v1/organizations/${organizationId}/tags/`,
                  "POST",
                  tag,
                  true,
                ).then((res) => {
                  if (res && res.result) {
                    if (selectedTags) {
                      selectedTags.push(res.id);
                    }
                    const newTag = { ...tag, id: res.id };
                    dispatch({
                      type: CREATE_ORGANIZATION_TAG,
                      tag: newTag,
                      isCreatingOrganizationTag: false,
                    });
                    return callback();
                  }
                  dispatch({
                    type: CREATE_ORGANIZATION_TAG_STATUS,
                    isCreatingOrganizationTag: false,
                  });
                  return callback();
                });
              },
              (err) => cb(),
            );
          } else {
            return cb();
          }
        },
        (cb) => {
          if (selectedTags && selectedTags.length > 0) {
            data = data.map((item) => {
              item.tags = selectedTags;
              return item;
            });
          }
          return callApi(
            `v1/organizations/${organizationId}/customers/bulk-upload/`,
            "post",
            {
              customers: data,
            },
            true,
          ).then((res) => {
            if (res.result) {
              dispatch({
                type: UPLOADED_BULK_DATA,
                isUploadingBulkCSVData: false,
                isBulUploadSuccessModalVisible: true,
              });
              history.push(`/contacts/list`);
              dispatch(fetchCustomerFilterAttributes(organizationId));
              dispatch(toggleBulkUploadSelectedSegment("firstScreen"));
              return cb();
            }
            isUploadSuccess = false;
            if (res.message) {
              try {
                const data = JSON5.parse(res.message);
                if (data.key === "limit_exceed_error") {
                  showNotification("error", data.error);
                  dispatch({
                    type: ERROR_WHILE_UPLOADING_DATA,
                    isUploadingBulkCSVData: false,
                  });
                  return cb();
                }
                if (data.key === "validation_error") {
                  dispatch(toggleBulkUploadSelectedSegment("errorScreen"));
                  dispatch({
                    type: ERROR_WHILE_UPLOADING_DATA,
                    isUploadingBulkCSVData: false,
                    bulkUploadErrors: data.error,
                  });
                  return cb();
                }
              } catch {
                showNotification("error", res.message);
                dispatch({
                  type: ERROR_WHILE_UPLOADING_DATA,
                  isUploadingBulkCSVData: false,
                });
                return cb();
              }
            } else {
              return cb();
            }
          });
        },
      ],
      (err) => {
        if (isUploadSuccess && tagsNotCreated && tagsNotCreated.length > 0) {
          dispatch(clearNonCreatedTags());
        }
      },
    );
  };
}

export function updateBulkCustomersByCSVv2(
  organizationId,
  requestBody,
  tagsNotCreated,
  history,
) {
  return (dispatch) => {
    const payload = { ...requestBody };
    dispatch({
      type: UPLOADING_BULK_DATA,
      isUploadingBulkCSVData: true,
      payload: requestBody.tags,
    });
    let isUploadSuccess = true;
    async.series(
      [
        (cb) => {
          if (tagsNotCreated && tagsNotCreated.length > 0) {
            Mixpanel.track(INT_API_TAGS, {});

            async.each(
              tagsNotCreated,
              (tag, callback) => {
                callApi(
                  `v1/organizations/${organizationId}/tags/`,
                  "POST",
                  tag,
                  true,
                ).then((res) => {
                  if (res && res.result) {
                    if (payload) {
                      payload.tags[res.id] = tag.name;
                    }
                    const newTag = {
                      ...tag,
                      id: res.id,
                      bulk_upload_in_progress: true,
                    };
                    dispatch({
                      type: CREATE_ORGANIZATION_TAG,
                      tag: newTag,
                      isCreatingOrganizationTag: false,
                    });
                    return callback();
                  }
                  dispatch({
                    type: CREATE_ORGANIZATION_TAG_STATUS,
                    isCreatingOrganizationTag: false,
                  });
                  return callback();
                });
              },
              (err) => cb(),
            );
          } else {
            return cb();
          }
        },
        (cb) =>
          callApi(
            `v1/organizations/${organizationId}/customers/bulk-upload/async/`,
            "post",
            payload,
            true,
          ).then((res) => {
            if (res.result) {
              dispatch({
                type: UPLOADING_BULK_DATA_SUCCESS,
              });

              showCustomNotification(
                "warning",
                <Box display="flex">
                  <Icon
                    iconName="upload"
                    iconSize="small"
                    color="white"
                    mr="8px"
                  />
                  <Label
                    fontFamily="Roboto"
                    fontSize="16px"
                    lineHeight="20px"
                    color="white"
                  >
                    Bulk Upload is in progress. We will notify you here and also
                    by email, once the upload is complete. The upload is
                    expected to happen @ 2000 contacts per minute.
                  </Label>
                </Box>,
                {
                  position: "top-center",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  toastId: "csvUploading",
                  className: "toast-width-lg",
                },
              );
              history.push(`/contacts/list`);
              dispatch(fetchCustomerFilterAttributes(organizationId));
            } else {
              dispatch(handleTagsForBulkUploadFail(tagsNotCreated));

              showCustomNotification(
                "error",
                <Box display="flex">
                  <Icon
                    iconName="upload"
                    iconSize="small"
                    color="white"
                    mr="8px"
                  />
                  <Label
                    fontFamily="Roboto"
                    font-weight="500"
                    font-size="14px"
                    line-height="16px"
                    color="white"
                  >
                    {res?.message}
                  </Label>
                </Box>,
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  className: "toast-width-s",
                },
              );
              isUploadSuccess = false;
            }
            return cb();
          }),
      ],
      (err) => {
        if (isUploadSuccess && tagsNotCreated && tagsNotCreated.length > 0) {
          dispatch(clearNonCreatedTags());
        }
      },
    );
  };
}

export const updateCSVFileName = (filename) => ({
  type: UPDATE_CSV_FILE_NAME,
  filename,
});

export const updateCSVFileURL = (url) => ({
  type: UPDATE_CSV_FILE_URL,
  url,
});

export function customerBulkTag(organizationId, data = {}, refetchCustomers) {
  return (dispatch) => {
    dispatch({
      type: BULK_TAG_CUSTOMER_STATUS,
      isBulkTaggingCustomer: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/customers/bulk-tag/`,
      "PUT",
      data,
    ).then((res) => {
      refetchCustomers();
      dispatch({
        type: BULK_TAG_CUSTOMER_STATUS,
        isBulkTaggingCustomer: false,
      });
      if (res.result) {
        showNotification("success", "tags added successfully");
      } else {
        showNotification("error", "failed to add tags");
      }
    });
  };
}

export function saveUserColumnPreferences(
  organizationId,
  all_users_columns_preferences,
) {
  return (dispatch) => {
    dispatch({
      type: SAVE_USER_COLUMN_PREFERENCES_STATUS,
      isSavingUserColumnPreferences: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "PUT",
      {
        all_users_columns_preferences: JSON5.stringify(
          all_users_columns_preferences,
        ),
      },
    ).then((res) => {
      if (res.result) {
        showNotification("success", "Preferences updated successfully");
        dispatch({
          type: SAVE_USER_COLUMN_PREFERENCES,
          isSavingUserColumnPreferences: false,
          selectedCustomerAttributes: all_users_columns_preferences,
        });
      } else {
        showNotification("error", "Failed to update");
        dispatch({
          type: SAVE_USER_COLUMN_PREFERENCES_STATUS,
          isSavingUserColumnPreferences: false,
        });
      }
    });
  };
}

export function fetchUserColumnPreferences(organizationId) {
  return (dispatch) =>
    callApi(
      `v1/organizations/${organizationId}/users/me/preferences/`,
      "get",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: SAVE_USER_COLUMN_PREFERENCES,
          isSavingUserColumnPreferences: false,
          selectedCustomerAttributes:
            res.data && !!res.data.all_users_columns_preferences
              ? JSON5.parse(res.data.all_users_columns_preferences)
              : null,
        });
      }
    });
}

export function fetchOrderList(organizationId, customerId, pageNum) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ORDER_LIST_STATUS,
      isFetchingOrderList: true,
    });
    return callApi(
      `v1/orgs/${organizationId}/shopify/${customerId}/orders/`,
      "POST",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: FETCH_ORDER_LIST,
          pageNum,
          customerId,
          orderList: res.data,
          isFetchingOrderList: false,
        });
      } else {
        dispatch({
          type: FETCH_ORDER_LIST_STATUS,
          isFetchingOrderList: false,
        });
      }
    });
  };
}

export const setActiveCustomerAllUsersPage = (id) => ({
  type: SET_ACTIVE_CUSTOMER_ALL_USERS_PAGE,
  payload: id,
});

export const removeUsers = (users) => (dispatch, getState) => {
  const organizationId = getSelectedOrganizationId(getState());

  callApiV2({
    method: "DELETE",
    endpoint: `v1/organizations/${organizationId}/customers/`,
    body: {
      ids: users,
      async: true,
    },
  }).then((response) => {
    if (response.result) {
      showCustomNotification(
        "warning",
        <Box display="flex">
          <Icon iconName="delete" iconSize="small" color="white" mr="8px" />
          <Label
            fontFamily="Roboto"
            fontSize="16px"
            lineHeight="20px"
            color="white"
          >
            {response.message}
          </Label>
        </Box>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          toastId: "csvUploading",
          className: "toast-width-lg",
        },
      );
    }
  });
};

export const updateShopifyCustomerSyncFinalState = (
  shopifyCustomerSyncFinalState,
) => ({
  type: UPDATE_SHOPIFY_CUSTOMER_SYNC_FINAL_STATE,
  shopifyCustomerSyncFinalState,
});

export const updateCustomersFinalFilters = (payload) => ({
  type: UPDATE_CUSTOMERS_FINAL_FILTERS,
  payload,
});
