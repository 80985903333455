import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";

const RQ_CAMPAIGN_ONLY_COUNT = "RQ_CAMPAIGN_ONLY_COUNT";

const fetchOnlyCountOrgs = async (): Promise<Array<string>> => {
  try {
    const result = await fetch(
      `https://hellohaptik.github.io/InteraktBanner/data/campaignOnlyCountWhitelist.json?timestamp=${new Date()}`,
    );

    return await result.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const useIsOrgAllowedForOnlyCount = () => {
  const orgId = useOrganizationId();

  return useQuery({
    queryFn: () => fetchOnlyCountOrgs(),
    queryKey: [RQ_CAMPAIGN_ONLY_COUNT],
    select: (data: Array<string>) => {
      if (data.includes("__all__")) {
        return true;
      }

      return data.includes(orgId);
    },
  });
};

export default useIsOrgAllowedForOnlyCount;
