import { isEmpty, isObject, toString } from "lodash";
import JSON5 from "json5";
import emojiStrip from "emoji-strip";
import { XmlEntities } from "html-entities";
import replaceAll from "replaceall";
import sanitizeHtml from "sanitize-html";
import ReactHtmlParser from "react-html-parser";
import { whatsappFormat } from "./whatsappFormatter";
import { isJson } from "./jsonUtility";
import { maskNumber, maskUserTraits } from "util/utility";
import { INTERACTIVE } from "util/messageType";
import { getFormattedTime } from "util/timeUtility";
import { WHATSAPP } from "constants/channelConstants";
import {
  ALL,
  NEWEST,
  OLDEST,
  OPEN,
  UNASSIGNED,
} from "constants/inboxfilterConstants";
import { getUrlParams } from "modules/Inbox/component/InboxFilter/utility";
import { parsingUnwantedTextKeepingSafeText } from "./utilityTs";

const HtmlEntities = new XmlEntities();

const imageTypes = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
const audioTypes = [
  "wav",
  "mp3",
  "audio",
  "opus",
  "3gp",
  "aa",
  "aac",
  "webm",
  "m4a",
  "ogg",
  "oga",
  "mogg",
  "aax",
  "act",
  "aiff",
  "alac",
  "amr",
  "ape",
  "au",
  "awb",
  "dct",
  "flac",
  "gsm",
  "iklax",
  "ivs",
  "m4b",
  "m4p",
  "mmf",
  "mpc",
  "msv",
  "nmf",
  "rm",
  "ra",
  "raw",
  "rf64",
  "sln",
  "tta",
  "voc",
  "vox",
  "wma",
  "wv",
  "8svx",
  "cda",
];
const videoTypes = ["mp4", "3gpp"];

export function sortArrayWithTimeStamp(array) {
  if (Array.isArray(array)) {
    return array.sort((obj1, obj2) => obj1.timestamp - obj2.timestamp);
  }
}

export function sortArrayWithTimeStampNewest(array) {
  if (Array.isArray(array)) {
    return array.sort((obj1, obj2) => obj2.timestamp - obj1.timestamp);
  }
}

export function reverseSortArrayWithTimeStamp(array) {
  if (Array.isArray(array)) {
    return array.sort((obj1, obj2) => obj1.timestamp - obj2.timestamp);
  }
}

export function reverseSortArrayWithCreatedAtUtc(array) {
  if (Array.isArray(array)) {
    return array.sort(
      (obj1, obj2) =>
        new Date(obj2.created_at_utc).getTime() -
        new Date(obj1.created_at_utc).getTime(),
    );
  }
}

export function sanitizeFromNumber(phone_number) {
  phone_number = toString(phone_number);
  phone_number = replaceAll(" ", "", phone_number);
  phone_number = phone_number.replace(/-/g, "");
  phone_number = phone_number.replace(/\+/g, "");
  return phone_number;
}

export function getValueFromTemplateParameter(parameter) {
  const value = "";
  if (parameter) {
    if (parameter.type === "text") {
      return parameter.text;
    }
    if (parameter.type === "date_time") {
      const params = parameter.date_time;
      const date = new Date(
        params.year,
        params.month,
        params.day_of_month,
        params.hour,
        params.minute,
      );
      return getFormattedTime(date, "dddd, MMMM d, YYYY hh:mm:ss a");
    }
  }
  return value;
}

export const getConversationMessage = (last_message) => {
  let text = "";
  const messageType =
    last_message.message_content_type || last_message.message_type;
  if (
    last_message &&
    (last_message.raw_template ||
      messageType?.toLowerCase().startsWith(INTERACTIVE) ||
      messageType === "Order")
  ) {
    let template = null;
    if (isJson(last_message.raw_template)) {
      template = JSON5.parse(last_message.raw_template);
      if (typeof template === "string") {
        template = JSON5.parse(template);
      }
    } else if (isObject(last_message.raw_template)) {
      template = last_message.raw_template;
    }
    let messageParams = null;
    if (last_message.message) {
      if (isJson(last_message.message)) {
        messageParams = JSON5.parse(last_message.message);
        if (typeof messageParams === "string") {
          messageParams = JSON5.parse(messageParams);
        }
      } else if (last_message.message) {
        messageParams = last_message.message;
      }
    }
    let bodyMessage = "";

    if (messageType?.toLowerCase().startsWith(INTERACTIVE)) {
      if (messageParams?.list_reply) {
        bodyMessage = messageParams?.list_reply?.title;
      } else if (messageParams?.button_reply) {
        bodyMessage = messageParams?.button_reply?.title;
      } else {
        bodyMessage = messageParams?.body?.text;
      }
    } else if (messageType === "Order") {
      bodyMessage = messageParams?.text ? messageParams.text : "No Text";
    } else {
      bodyMessage = template?.body;
    }

    let headerMessage = "";
    let footerMessage = "";
    if (template?.footer) {
      footerMessage = template.footer;
    }
    let bodyParams = [];
    let headerParams = [];
    let footerParams = [];
    if (
      messageParams &&
      messageParams.length > 0 &&
      Array.isArray(messageParams)
    ) {
      messageParams.forEach((item) => {
        if (item.type === "body") {
          if (item.parameters) {
            bodyParams = item.parameters;
          }
        }
        if (item.type === "header") {
          if (item.parameters) {
            headerParams = item.parameters;
          }
        }
        if (item.type === "footer") {
          if (item.parameters) {
            footerParams = item.parameters;
          }
        }
      });
    }
    if (template?.header_format === "TEXT") {
      headerMessage = template?.header;
      if (headerParams && headerParams.length > 0 && headerMessage) {
        headerParams.forEach((item, index) => {
          const replaceStr = `{{${index + 1}}}`;
          const value = getValueFromTemplateParameter(item);
          headerMessage = replaceAll(
            replaceStr,
            toString(value),
            headerMessage,
          );
        });
      }
    }
    if (bodyParams && bodyParams.length > 0 && bodyMessage) {
      bodyParams.forEach((item, index) => {
        const replaceStr = `{{${index + 1}}}`;
        const value = getValueFromTemplateParameter(item);
        bodyMessage = replaceAll(replaceStr, toString(value), bodyMessage);
      });
    }
    if (footerParams && footerParams.length > 0 && footerMessage) {
      footerParams.forEach((item, index) => {
        const replaceStr = `{{${index + 1}}}`;
        const value = getValueFromTemplateParameter(item);
        footerMessage = replaceAll(replaceStr, toString(value), footerMessage);
      });
    }

    text = `${headerMessage} ${bodyMessage} ${footerMessage}`;
  } else if (last_message.message) {
    const messageParams = last_message?.message;
    if (messageParams?.list_reply) {
      text = messageParams?.list_reply?.title;
    } else if (messageParams?.button_reply) {
      text = messageParams?.button_reply?.title;
    } else if (messageParams?.body) {
      text = messageParams.body?.text || "No text";
    } else if (messageParams?.catalog_id) {
      text = messageParams?.text || "No text";
    } else if (
      messageParams?.type === "product" ||
      messageParams?.type === "product_list"
    ) {
      text = messageParams?.text || "No text";
    } else {
      text = `${last_message.message}` || "No text";
    }
  }
  text = text.replace(/(\r\n|\n|\r)/gm, " ");
  if (text === "") {
    text = "No Text";
  }
  return text;
};

export const getCustomerNameOrNumber = (
  customer,
  shouldMaskNumber = false,
  shouldMaskUserTraits = false,
) => {
  if (!isEmpty(customer)) {
    if (customer.traits && customer.traits.name) {
      return shouldMaskUserTraits
        ? maskUserTraits(customer.traits.name)
        : customer.traits.name;
    }
    if (customer.phone_number) {
      return shouldMaskNumber
        ? `${customer?.country_code}${maskNumber(
            customer?.phone_number,
            shouldMaskNumber,
          )}`
        : `${customer?.country_code}${customer?.phone_number}`;
    }
    return "No Message";
  }
  return "";
};
// agents --> activeAgentList --> is_deleted = false
// code --> fullAgentList --> 2 [ChatWindow, ChatHistoryWindow] --> 11, activeAgentList --> 12-13 places --> 7 --> is_deleted = false

export const getAgentName = (userId, agents) => {
  if (agents && userId) {
    let userIdString = "";
    if (userId && isObject(userId)) {
      userIdString = userId.id;
    } else if (userId) {
      userIdString = userId;
    }
    const filterItem = agents.filter(
      (item) => toString(item.id) === toString(userIdString),
    );
    return filterItem && filterItem[0]
      ? `${filterItem[0].first_name ? filterItem[0].first_name : ""} ${
          filterItem[0].last_name ? filterItem[0].last_name : ""
        }`.trim()
      : "NA";
  }
  return "NA";
};

export const getShortName = (str) => {
  if (str) {
    const strCopy = typeof str !== "string" ? str.toString() : str;
    const name = emojiStrip(strCopy)?.[0]?.toUpperCase();

    return name;
  }

  return str;
};

export const getUrlExtension = (url) =>
  url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();

export const getFileType = (mediaUrl, media_id) => {
  if (mediaUrl) {
    const ext = getUrlExtension(mediaUrl);
    if (imageTypes.indexOf(ext) > -1) {
      return "image";
    }
    if (audioTypes.indexOf(ext) > -1) {
      return "audio";
    }
    if (videoTypes.indexOf(ext) > -1) {
      return "video";
    }
    return "document";
  }
  if (media_id) {
    return "document";
  }
  return "failed to load";
};

export const getHTMLDecodedText = (str) => {
  if (str) {
    return HtmlEntities.decode(str);
  }
  return "";
};

export const getHTMLEncodedText = (str) => {
  if (str) {
    return HtmlEntities.encode(str);
  }
  return "";
};

export function getImageMeta(url, callback) {
  const img = new Image();
  img.src = url;
  img.onload = function () {
    callback(this.width, this.height);
  };
}

export function getConvLabelById(convLabelId, convLabelList) {
  let convLabelsListFilteredById = {};
  if (convLabelId && convLabelList && convLabelList.length > 0) {
    convLabelsListFilteredById = convLabelList.filter(
      (item) => toString(item.id) === toString(convLabelId),
    )[0];
  }
  return convLabelsListFilteredById;
}

export const getNameIntials = (name) => {
  const splitedName = name && name.split(" ");

  return `${splitedName[0] ? splitedName[0][0] : ""}${
    splitedName[1] ? splitedName[1][0] : ""
  }`;
};

export const getSanitizeData = (text, initalLoad = false) => {
  let msg = text;
  if (msg && msg.length > 0) {
    if (!initalLoad) {
      msg = msg.replace(/\n/g, "");
    }

    // regex matching all <p> tags is replaced with \n
    msg = msg.replace(/<p[^>]*>/g, "\n");

    // regex matching all <br/> tags is replaced with \n
    msg = msg.replace(/<br[^>]*>/g, "\n");
    msg = sanitizeHtml(msg, {
      allowedTags: ["b", "i", "em", "strong", "del"],
    });
    msg = msg.replace(/<strong>[\s]+/g, " *");
    msg = msg.replace(/[\s]+<\/strong>/g, "* ");
    msg = msg.replace(/<b>[\s]+/g, " *");
    msg = msg.replace(/[\s]+<\/b>/g, "* ");
    msg = msg.replace(/<i>[\s]+/g, " _");
    msg = msg.replace(/[\s]+<\/i>/g, "_ ");
    msg = msg.replace(/<em>[\s]+/g, " _");
    msg = msg.replace(/[\s]+<\/em>/g, "_ ");
    msg = msg.replace(/<del>[\s]+/g, " ~");
    msg = msg.replace(/[\s]+<\/del>/g, "~ ");
    msg = replaceAll("<strong>", "*", msg);
    msg = replaceAll("</strong>", "*", msg);
    msg = replaceAll("<b>", "*", msg);
    msg = replaceAll("</b>", "*", msg);
    msg = replaceAll("<i>", "_", msg);
    msg = replaceAll("</i>", "_", msg);
    msg = replaceAll("<em>", "_", msg);
    msg = replaceAll("</em>", "_", msg);
    msg = replaceAll("<del>", "~", msg);
    msg = replaceAll("</del>", "~", msg);
    msg = getHTMLDecodedText(msg);
    msg = msg.trim();
    return msg;
  }
};

export const getSanitizeDataV2 = (text, initalLoad = false) => {
  let msg = text;
  if (msg && msg.length > 0) {
    if (!initalLoad) {
      msg = msg.replace(/\n/g, "");
    }

    // replace all <p><br></p> with <br>
    msg = msg.replace(/<p><br><\/p>/g, "<br>");

    // replace all <p><br></br></p> with <br>
    msg = msg.replace(/<p><br><\/br><\/p>/g, "<br>");

    // regex matching all <p> tags is replaced with \n
    msg = msg.replace(/<p[^>]*>/g, "\n");

    // regex matching all <br> tags is replaced with \n
    msg = msg.replace(/<br[^>]*>/g, "\n");
    msg = sanitizeHtml(msg, {
      allowedTags: ["b", "i", "em", "strong", "del"],
    });
    msg = msg.replace(/<strong>[\s]+/g, " *");
    msg = msg.replace(/[\s]+<\/strong>/g, "* ");
    msg = msg.replace(/<b>[\s]+/g, " *");
    msg = msg.replace(/[\s]+<\/b>/g, "* ");
    msg = msg.replace(/<i>[\s]+/g, " _");
    msg = msg.replace(/[\s]+<\/i>/g, "_ ");
    msg = msg.replace(/<em>[\s]+/g, " _");
    msg = msg.replace(/[\s]+<\/em>/g, "_ ");
    msg = msg.replace(/<del>[\s]+/g, " ~");
    msg = msg.replace(/[\s]+<\/del>/g, "~ ");
    msg = replaceAll("<strong>", "*", msg);
    msg = replaceAll("</strong>", "*", msg);
    msg = replaceAll("<b>", "*", msg);
    msg = replaceAll("</b>", "*", msg);
    msg = replaceAll("<i>", "_", msg);
    msg = replaceAll("</i>", "_", msg);
    msg = replaceAll("<em>", "_", msg);
    msg = replaceAll("</em>", "_", msg);
    msg = replaceAll("<del>", "~", msg);
    msg = replaceAll("</del>", "~", msg);
    msg = getHTMLDecodedText(msg);
    msg = msg.trim();
    return msg;
  }
};

export const messageBoxStyle = (isOpenedFromHistory, isCustomerReplies) => ({
  backgroundColor: isOpenedFromHistory ? "#A6F2B2" : null,
  boxShadow: !isCustomerReplies
    ? "2px 2px 4px rgb(155 155 155 / 25%)"
    : "unset",
});

export const renderBodyMessage = (message, isAutomatedMessage = false) => {
  let updatedMessage;
  if (typeof message === "string" && isJson(message)) {
    updatedMessage = JSON.parse(message);
    if (typeof updatedMessage === "string" && isJson(updatedMessage)) {
      updatedMessage = JSON.parse(updatedMessage);
    }
  } else {
    updatedMessage = message;
  }
  const bodyMessage = updatedMessage?.body?.text || updatedMessage;

  if (isAutomatedMessage) {
    return ReactHtmlParser(
      parsingUnwantedTextKeepingSafeText(whatsappFormat(bodyMessage)),
    );
  }
  return (
    ReactHtmlParser(
      parsingUnwantedTextKeepingSafeText(whatsappFormat(message)),
    ) ?? ""
  );
};

const getIfElementIsEndOrStartOfTag = (arrayMap, index) => {
  const lastElem =
    arrayMap[index + 1] + arrayMap[index + 2] + arrayMap[index + 3];

  const startElem =
    arrayMap[index - 3] + arrayMap[index - 2] + arrayMap[index - 1];

  if (lastElem === "</b" || lastElem === "</i") {
    return { flag: true, position: "back" };
  }

  if (startElem === "<i>" || startElem === "<b>") {
    return { flag: true, position: "front" };
  }

  return { flag: false };
};

const removeUnwantedTags = (text) => {
  if (text) {
    return text.replaceAll("<b></b>", "");
  }

  return text;
};

// resolve break line issue
// i.e providing 2 line break result into 3 line break
// Example -
// <b>hello</b><p><b><br></b></p><p><b>hello</b></p> =>
// <b>hello</b><p><br /></p><p><b>hello</b></p>

export const breakTextFormat = (text) => {
  const arrayMap = text.split("<b><br></b>");

  arrayMap.forEach(() => {
    if (arrayMap[arrayMap.length - 1] === "") {
      arrayMap.pop();
    }
  });

  if (arrayMap[arrayMap.length - 1] === "") {
    arrayMap.pop();
  }

  return arrayMap.join("<br />");
};

// Example -> "<i>Hello </i>" -> "<i>Hello</i> "
// Example -> "<i>  Hello  </i>" -> "  <i>Hello</i>  "
export const getFormattedTextForWhatsapp = (text) => {
  const arrayMap = breakTextFormat(removeUnwantedTags(text))?.split("");

  [...arrayMap].forEach(() => {
    [...arrayMap].forEach((item, index) => {
      if (
        (item === " " || item === "<br />") &&
        getIfElementIsEndOrStartOfTag(arrayMap, index).flag
      ) {
        if (
          getIfElementIsEndOrStartOfTag(arrayMap, index).position === "front"
        ) {
          arrayMap.splice(index - 3, 0, item);
          arrayMap.splice(index + 1, 1);
        }

        if (
          getIfElementIsEndOrStartOfTag(arrayMap, index).position === "back"
        ) {
          arrayMap.splice(index + 5, 0, item);
          arrayMap.splice(index, 1);
        }
      }
    });
  });

  return arrayMap.join("").replaceAll("<p><br /></p>", "<br />");
};

export const removeDuplicateChat = (chats) => {
  if (Array.isArray(chats)) {
    const chatsId = [];

    return chats.filter((item) => {
      if (!chatsId.includes(item.id)) {
        chatsId.push(item.id);
        return item;
      }

      return false;
    });
  }

  return chats;
};

const getConvLabelData = (label) => {
  if (label === "all") {
    return { assigned: false, id: null };
  }

  if (label === "no") {
    return { assigned: true, id: null };
  }

  return { assigned: Boolean(label), id: label ?? null };
};

const getAssignedData = ({ chatType, assignee }) => {
  if (chatType === UNASSIGNED) {
    return { assigned: false, id: "" };
  }

  if (assignee === "all") {
    return { assigned: null, id: "" };
  }

  if (assignee) {
    return { assigned: true, id: assignee };
  }

  return { assigned: null, id: "" };
};

const getSortByValue = (sort) => {
  if (sort === OLDEST) {
    return "asc";
  }
  return "desc";
};

export const getFormattedFilter = ({
  chatType = "All",
  assignee = "all",
  channel = [WHATSAPP],
  chatStatus = OPEN,
  label = "all",
  sort = NEWEST,
  offset = 0,
  pageSize = 10,
  currentUserId,
  organizationId,
}) => {
  return {
    channel,
    selectedChatType: chatStatus,
    pageSize,
    sortBy: getSortByValue(sort),
    offset,
    isAssignedConvLabel: getConvLabelData(label).assigned,
    conversationLabelId: getConvLabelData(label).id,
    assigned: getAssignedData({ chatType, assignee }).assigned,
    agentId: getAssignedData({ chatType, assignee }).id,
    currentUserId,
    organizationId,
    chatType,
  };
};

export function getSelectedChatType(fallback = ALL) {
  return getUrlParams(window.location.search, "chatType") || fallback;
}
