import useShouldVanishFeatureByPaymentPlan from "customHooks/useShouldVanishFeatureByPaymentPlan";
import React from "react";
import useUser, {
  selectHasAnyPermission,
  selectHasAnyRole,
} from "useApi/App/useUser";
import ErrorPage from "../Error403Page/Error403Page";

const PageAccessControl = ({
  shouldHaveRoles = [],
  isRestrictedPage = false,
  shouldHavePermissions = [],
  doesUserNeedSubscription = false,
  userHasSubscriptionForFeature = false,
  shouldNotHaveVanishFeatures = [],
  children,
}) => {
  const { data: hasPermission = false } = useUser(
    selectHasAnyPermission(shouldHavePermissions),
  );
  const shouldVanishFeature = useShouldVanishFeatureByPaymentPlan(
    shouldNotHaveVanishFeatures,
  );
  const { data: hasRole = false } = useUser(selectHasAnyRole(shouldHaveRoles));
  const isRestrictedPagePermissionAccess = isRestrictedPage ? hasRole : true;

  if (shouldVanishFeature) {
    return <ErrorPage text="You don’t have permission to view this page." />;
  }

  if (
    (doesUserNeedSubscription && !userHasSubscriptionForFeature) ||
    (isRestrictedPagePermissionAccess && hasPermission)
  ) {
    return children;
  }

  return (
    <ErrorPage text="You don’t have permission to view this page. You may ask your organization’s Owner to review your permissions." />
  );
};

export default PageAccessControl;
