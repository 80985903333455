import mixpanel from "mixpanel-browser";
import { getIsHaptikUser, getHaptikUserData } from "modules/App/HaptikReducer";
import {
  getCatalogId,
  getOrgCreatedAtUtc,
  getOrganizationCompanyType,
  getOrganizationEmail,
  getOrganizationLocation,
  getOrganizationMaxRevenue,
  getOrganizationMinRevenue,
  getOrganizationOnboardingStatus,
  getOrganizationOnboardingType,
  getOrganizationPhone,
  getOrganizationWebsite,
  getPartnerId,
  getSelectedOrganizationName,
  getUserOrganizationId,
} from "modules/App/AppSelectors";
import queryClient from "config/queryClient";
import { RQ_WALLET_BALANCE } from "useApi/Payment/useWalletBalance";
import { getActiveSubscriptionList } from "modules/Payments/PaymentSelectors";
import { RQ_USER } from "useApi/App/useUser";
import { getPartnerName, validStoreInstance } from "./utilityTs";
import { store } from "../store";
import { getDeviceType } from "./deviceUtility";

export function trackPage() {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname,
        title: document.title,
      },
    });
  }
}

export function trackEvent(input) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: input.category,
        action: input.action,
        label: input.label,
        value: input.value,
      },
    });
  }
}

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN);

const env_check = process.env.REACT_APP_ENVIRONMENT === "production";

const actions = {
  identify: (id) => {
    if (env_check && mixpanel.__loaded) mixpanel.identify(id);
  },
  track: (eventName, { category, action, ...props } = {}) => {
    if (env_check && mixpanel.__loaded) {
      let promPayload = {};
      if (getIsHaptikUser()) {
        const { partnerName, businessViaName } = getHaptikUserData();
        promPayload = {
          partner_name: partnerName,
          business_via_name: businessViaName,
          "Tool Name": "ProM",
        };
      }
      mixpanel.track(eventName, {
        ...props,
        category,
        action,
        "Tool Name": "Interakt Web",
        "Org Name": validStoreInstance(store)
          ? getSelectedOrganizationName(store.getState())
          : null,
        INT_Org_ID: validStoreInstance(store)
          ? getUserOrganizationId(store.getState())
          : null,
        INT_Org_name: validStoreInstance(store)
          ? getSelectedOrganizationName(store.getState())
          : null,
        INT_Org_Creation_Date: validStoreInstance(store)
          ? getOrgCreatedAtUtc(store.getState())
          : null,
        INT_Org_PlanType: validStoreInstance(store)
          ? getActiveSubscriptionList(store.getState())?.[0]?.subscription?.name
          : null,
        INT_User_Role:
          queryClient.getQueriesData([RQ_USER])?.[1]?.[1]?.permissions?.role ??
          null,
        INT_Org_Signup_Source: validStoreInstance(store)
          ? getOrganizationOnboardingType(store.getState())
          : null,
        INT_Catalog_Id: validStoreInstance(store)
          ? getCatalogId(store.getState())
          : null,
        INT_Company_Type: validStoreInstance(store)
          ? getOrganizationCompanyType(getOrganizationOnboardingType)
          : null,
        INT_Onboarding_Status: validStoreInstance(store)
          ? getOrganizationOnboardingStatus(store.getState())
          : null,
        INT_Org_WalletBalance:
          queryClient.getQueriesData([RQ_WALLET_BALANCE])?.[1]?.[1]?.balance ??
          null,
        INT_User_Email: validStoreInstance(store)
          ? getOrganizationEmail(store.getState())
          : null,
        INT_User_Phone: validStoreInstance(store)
          ? getOrganizationPhone(store.getState())
          : null,
        // "INT_User_Platform": validStoreInstance(store) ? : null,
        INT_User_Company_Website: validStoreInstance(store)
          ? getOrganizationWebsite(store.getState())
          : null,
        INT_Org_Partner_name: validStoreInstance(store)
          ? getPartnerName(getPartnerId(store.getState()))
          : null,
        INT_Org_Revenue: validStoreInstance(store)
          ? `${getOrganizationMinRevenue(
              store.getState(),
            )} - ${getOrganizationMaxRevenue(store.getState())}`
          : null,
        INT_User_Company_Location: validStoreInstance(store)
          ? getOrganizationLocation(store.getState())
          : null,
        Device: getDeviceType(),
        ...promPayload,
      });
    }
  },
  people: {
    set: (props) => {
      if (env_check && mixpanel.__loaded) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
