import Box from "konnekt/Box";
import Button from "konnekt/Buttons";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { selectBrandName } from "useApi/Partner/useWhitelabeledDetails";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";

const Container = styled(Box)`
  display: flex;
  width: 358px;
  height: 56px;
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: linear-gradient(92deg, #bd546b 0%, #f7817d 100%);
  margin-top: 8px;
`;

const SubContainer = styled(Box)`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: linear-gradient(92deg, #bd546b 0%, #f7817d 100%);
  margin-top: 8px;
`;

const ConnectNumberCard = ({ subOrg, toggleSettings }) => {
  const history = useHistory();
  const { data: brandName } = useCurrentWhitelabeledDetails(selectBrandName);

  if (subOrg) {
    return (
      <SubContainer>
        <Icon iconSize="2xs" color="white" iconName="call" />
        <Label variant="text12" color="white">
          Connect a number to get started
        </Label>
      </SubContainer>
    );
  }
  return (
    <Container>
      <Icon color="white" iconName="call" />
      <Label variant="text12" color="white">
        Add your Contact No. to get started with {brandName}
      </Label>
      <Button
        onClick={() => {
          if (toggleSettings) {
            toggleSettings();
          }
          history.push("/signup/whatsapp");
        }}
        minHeight="25px"
        bg="white"
        color="black"
        border="none"
      >
        <Label variant="text12" color="#0A0B10">
          Connect Number
        </Label>
      </Button>
    </Container>
  );
};

export default ConnectNumberCard;
