import React from "react";
import styled from "styled-components";
import { layout, flexbox, space, grid, border, position } from "styled-system";
import PropTypes from "prop-types";

const StyledFooterLayout = styled.div`
  background: #ffffff;
  padding: 15px;
  border-radius: 0px 0px 6px 6px;
  z-index: 1040;
  position: relative;

  ${layout}
  ${flexbox}
  ${grid}
  ${space}
  ${border}
  ${position}
`;

const ModalFooter = ({ children, ...props }) => {
  return <StyledFooterLayout {...props}>{children}</StyledFooterLayout>;
};

ModalFooter.propTypes = {
  children: PropTypes.element.isRequired,
};

ModalFooter.defaultProps = {
  borderTop: "1px solid #c4c4c4",
};

export default ModalFooter;
