import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color as systemColor } from "styled-system";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import BrandLogo from "components/BrandLogo";

const StyledLink = styled(Link)`
  ${systemColor}
`;

const SidebarIcon = ({
  tooltipContent,
  toggleItem = {},
  cypressData,
  iconName,
  isActive = false,
  url,
  width = "20px",
  height = "20px",
  bg,
  color,
  strokeColor,
  iconProps = {},
  trackClick,
  showContent,
  sidebarClassName = "sidebar-link",
  isExternal,
  tagContent,
  ...rest
}) => {
  const defaultColor = strokeColor || "#0A0B10";
  const activeColor = "#00A68B";
  const [hoverColor, updateHoverColor] = useState(defaultColor);

  const getIconColor = () => {
    if (strokeColor) {
      return null;
    }
    if (color) {
      return color;
    }
    if (isActive) {
      return activeColor;
    }
    return hoverColor;
  };

  const getContainer = () => {
    if (isExternal) {
      return "a";
    }

    if (url) {
      return StyledLink;
    }

    return Box;
  };

  return (
    <Box
      as={getContainer()}
      href={isExternal ? url : ""}
      data-tip
      data-for={iconName}
      to={url}
      onClick={() => {
        if (toggleItem) toggleItem();
        if (trackClick) trackClick();
      }}
      className={`${sidebarClassName} ${
        isActive && !bg && (showContent || showContent === undefined)
          ? "active"
          : ""
      }`}
      data-cy={cypressData}
      bg={bg}
      onMouseEnter={() => {
        updateHoverColor(activeColor);
      }}
      onMouseLeave={() => {
        updateHoverColor(defaultColor);
      }}
      {...rest}
    >
      {iconName === "logo" ? (
        <BrandLogo width={width} height={height} />
      ) : (
        <Icon
          iconName={iconName}
          color={getIconColor()}
          stroke={strokeColor && hoverColor}
          width={width}
          height={height}
          noWrapper
          {...iconProps}
        />
      )}
      {showContent && (
        <Label
          display="flex"
          alignItems="center"
          variant="text6"
          color={getIconColor()}
        >
          {tooltipContent}
          {Boolean(tagContent) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="4px"
              ml="8px"
              bg="#00A68B"
              px="4px"
              height="19px"
              minWidth="22px"
            >
              <Label color="#FFF" variant="text12" lineHeight="14px">
                {tagContent}
              </Label>
            </Box>
          )}
        </Label>
      )}
      {!showContent && isActive && (
        <Box
          bg="#00A68B"
          borderTopLeftRadius="10px"
          borderBottomLeftRadius="10px"
          width="4px"
          height="50px"
          position="absolute"
          right="-10px"
          top="-5px"
        />
      )}
    </Box>
  );
};

SidebarIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  cypressData: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  toggleItem: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

SidebarIcon.defaultProps = {
  isActive: false,
  width: "20px",
  height: "20px",
  toggleItem: null,
  color: null,
};

export default React.memo(SidebarIcon);
