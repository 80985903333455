const SET_HAPTIK_USER = "SET_HAPTIK_USER";
const SET_SMS_CHANNEL = "SET_SMS_CHANNEL";

const initialState = {
  isHaptikUser: !!window.localStorage.getItem("is_haptik_user"),
  isSMSChannel: false,
};

const HaptikReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HAPTIK_USER:
      return { ...state, isHaptikUser: action.payload };
    case SET_SMS_CHANNEL:
      return { ...state, isSMSChannel: action.payload };
    default:
      return state;
  }
};

export const setHaptikUser = (isHaptikUser) => ({
  type: SET_HAPTIK_USER,
  payload: isHaptikUser,
});

export const setSMSChannel = (isSMSChannel) => ({
  type: SET_SMS_CHANNEL,
  payload: isSMSChannel,
});

export const getIsHaptikUser = () =>
  !!window.localStorage.getItem("is_haptik_user");

export const getIsSMSChannel = (state) =>
  getIsHaptikUser() && state.haptikUser.isSMSChannel;

export const getPermissionList = () => {
  const permissions = window.localStorage.getItem("haptik_user_permission");
  if (permissions) {
    const permissionList = JSON.parse(permissions);
    return Array.isArray(permissionList) ? permissionList : ["whatsapp"];
  }
  return ["whatsapp"];
};

export const getHaptikUserData = () => {
  const partnerName = window.localStorage.getItem("partnerName");
  const businessViaName = window.localStorage.getItem("businessViaName");
  return { partnerName, businessViaName };
};

export default HaptikReducer;
