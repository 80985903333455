import { useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useUrlQuery = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const pushQuery = useCallback(() => {
    history.push({
      search: `?${query.toString()}`,
    });
  }, [history, query.toString()]);

  return [query, pushQuery] as const;
};

export default useUrlQuery;
