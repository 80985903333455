function log(...params) {
  if (process.env.REACT_APP_ENVIRONMENT !== "development") {
    if (!window._logs) {
      window._logs = [];
    }
    window._logs.push(...params);
  } else {
    console.log(...params);
  }
}

export default {
  log: log,
};
