import useSocketV2 from "./useSocketV2";

const useSocket = () => {
  const {
    initConnection: initConnectionV2,
    stopConnection: stopConnectionV2,
    createConnection: createConnectionV2,
    retryConnection: retryConnectionV2,
  } = useSocketV2();

  return {
    initConnection: initConnectionV2,
    stopConnection: stopConnectionV2,
    createConnection: createConnectionV2,
    retryConnection: retryConnectionV2,
  };
};

export default useSocket;
