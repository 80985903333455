import { parseJSON } from "util/jsonUtility";
import { getUrlParams } from "modules/Inbox/component/InboxFilter/utility";
import { isJSON } from "util/utility";
import {
  CODE_DELIVERY_COPY_CODE,
  CODE_DELIVERY_ONE_TAP,
  CODE_DELIVERY_ZERO_TAP,
  authBodyList,
} from "./auth-template-constant";

export function isValidPackageName(packageName: string): boolean {
  const regex = /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/;
  return regex.test(packageName);
}

interface PackageListInterface {
  id: string;
  name: string;
  signature: string;
}

interface AuthTemplateStateInterface {
  id: string;
  codeDeliveryType:
    | typeof CODE_DELIVERY_ZERO_TAP
    | typeof CODE_DELIVERY_ONE_TAP
    | typeof CODE_DELIVERY_COPY_CODE;
  zeroTapCheckbox: boolean;
  packageList: Array<PackageListInterface>;
  addSecurityRecommendation: boolean;
  addExpiryTime: boolean;
  expiryTime: number | null;
  autofillText: string;
  copyCodeText: string;
  validityStatus: boolean;
  validityPeriod: string;
  language?: string;
}

interface AuthTemplateAPIInterface {
  code_expiration_minutes: number | null;
  add_security_recommendation: boolean;
  message_send_ttl_seconds: number | null;
  body_text: ["123456"];
  body: string;
  footer: null;
  buttons: string;
}

export function getAuthTempButtonsUrl(
  authTemplateState: AuthTemplateStateInterface,
): string {
  let urlText = `https://www.whatsapp.com/otp/code?otp_type=${authTemplateState.codeDeliveryType}&code=otp{{1}}`;

  if (
    authTemplateState.autofillText &&
    authTemplateState.codeDeliveryType !== CODE_DELIVERY_COPY_CODE
  ) {
    urlText = urlText.concat(
      `&cta_display_name=${authTemplateState.autofillText}`,
    );
  }

  if (authTemplateState.codeDeliveryType !== CODE_DELIVERY_COPY_CODE) {
    authTemplateState.packageList?.forEach((item, index) => {
      if (index === 0) {
        urlText = urlText.concat(`&package_name=${item.name}`);
      } else {
        urlText = urlText.concat(`%2C${item.name}`);
      }
    });

    authTemplateState.packageList?.forEach((item, index) => {
      if (index === 0) {
        urlText = urlText.concat(`&signature_hash=${item.signature}`);
      } else {
        urlText = urlText.concat(`%2C${item.signature}`);
      }
    });
  }

  return urlText;
}

interface AuthTemplateButtonsAPIInterface {
  type: "URL";
  text: string;
  url: string;
  example: string[];
}

export function getAuthTemplateButtonsPayload(
  authTemplateState: AuthTemplateStateInterface,
): AuthTemplateButtonsAPIInterface {
  return {
    type: "URL",
    text: authTemplateState.copyCodeText,
    url: getAuthTempButtonsUrl(authTemplateState),
    example: [
      getAuthTempButtonsUrl(authTemplateState).replace("{{1}}", "123456"),
    ],
  };
}

function getAuthTemplateAPIBody(
  language: string | undefined,
  addSecurityRecommendation?: boolean,
): string {
  if (language) {
    return `${authBodyList[language]?.mainText || ""}${
      addSecurityRecommendation ? authBodyList[language]?.security : ""
    }`;
  }

  return "";
}

export function getAuthTemplateCreateApiResponse(
  authTemplateState: AuthTemplateStateInterface,
): AuthTemplateAPIInterface {
  return {
    code_expiration_minutes: authTemplateState.addExpiryTime
      ? authTemplateState.expiryTime
      : null,
    add_security_recommendation: authTemplateState?.addSecurityRecommendation,
    body_text: ["123456"],
    body: getAuthTemplateAPIBody(
      authTemplateState.language,
      authTemplateState.addSecurityRecommendation,
    ),

    footer: null,
    buttons: JSON.stringify([getAuthTemplateButtonsPayload(authTemplateState)]),
    message_send_ttl_seconds: authTemplateState.validityStatus
      ? Number(authTemplateState.validityPeriod) * 60
      : -1,
  };
}

function getPackageListFromPackageNameAndSignature(
  packageName: string,
  signature: string,
): Array<PackageListInterface> {
  const packageList: Array<PackageListInterface> = [];

  const packageNameList = packageName?.split(",");

  const signatureList = signature?.split(",");

  packageNameList?.forEach((item, index) => {
    packageList.push({
      id: item,
      name: packageNameList[index] as string,
      signature: signatureList[index] as string,
    });
  });

  return packageList;
}

export function getAuthTemplateStateFromAPIResponse(
  apiResponse: AuthTemplateAPIInterface,
): AuthTemplateStateInterface {
  const buttons = isJSON(apiResponse.buttons)
    ? parseJSON(apiResponse.buttons)
    : apiResponse.buttons;

  const codeDeliveryType = getUrlParams(
    buttons?.[0]?.url?.split("?")[1],
    "otp_type",
  );

  const autofillText = getUrlParams(buttons?.[0]?.url, "cta_display_name");
  const copyCodeText = buttons?.[0]?.text;

  const packageName = getUrlParams(buttons?.[0]?.url, "package_name");
  const signature = getUrlParams(buttons?.[0]?.url, "signature_hash");

  return {
    id: "",
    codeDeliveryType,
    zeroTapCheckbox: codeDeliveryType === CODE_DELIVERY_ZERO_TAP,
    packageList: getPackageListFromPackageNameAndSignature(
      packageName,
      signature,
    ),
    addSecurityRecommendation: apiResponse.add_security_recommendation,
    addExpiryTime: !!apiResponse.code_expiration_minutes,
    expiryTime: apiResponse.code_expiration_minutes ?? 10,
    autofillText,
    copyCodeText,
    validityStatus: !(
      apiResponse.message_send_ttl_seconds === -1 ||
      apiResponse.message_send_ttl_seconds === null
    ),
    validityPeriod:
      apiResponse.message_send_ttl_seconds &&
      apiResponse.message_send_ttl_seconds !== -1
        ? (apiResponse.message_send_ttl_seconds / 60).toString()
        : "10",
  };
}

export function checkDuplicatePackageNameAndSignature(
  packageList: Array<PackageListInterface>,
) {
  const packageNameSignatureArray = packageList?.map((item) => {
    return `${item.name}${item.signature}`;
  });

  if (
    packageNameSignatureArray.length !== new Set(packageNameSignatureArray).size
  ) {
    return {
      isValid: false,
      message:
        "Duplicate package name and signature combination is not allowed",
    };
  }

  return {
    isValid: true,
    message: "",
  };
}

export const isValidAuthTemplateState = (
  authTemplateState: Array<AuthTemplateStateInterface>,
) => {
  for (let i = 0; i < authTemplateState.length; i++) {
    const activeTemplate = authTemplateState[i];

    if (activeTemplate) {
      if (activeTemplate.codeDeliveryType === CODE_DELIVERY_ZERO_TAP) {
        if (!activeTemplate.zeroTapCheckbox) {
          return {
            isValid: false,
            message: "Please select checkbox for zero tap",
          };
        }
      }

      if (activeTemplate.codeDeliveryType !== CODE_DELIVERY_COPY_CODE) {
        // eslint-disable-next-line no-restricted-syntax
        for (const templatePackage of activeTemplate.packageList) {
          if (
            !isValidPackageName(templatePackage.name) ||
            !templatePackage.name
          ) {
            return {
              isValid: false,
              message: "Please enter valid package name",
            };
          }

          if (!templatePackage.signature) {
            return {
              isValid: false,
              message: "Please enter valid signature",
            };
          }

          if (templatePackage.signature.length !== 11) {
            return {
              isValid: false,
              message: "Signature length should be 11",
            };
          }
        }

        const checkDuplicate = checkDuplicatePackageNameAndSignature(
          activeTemplate.packageList,
        );

        if (checkDuplicate?.isValid === false) {
          return checkDuplicate;
        }
      }

      if (activeTemplate.addExpiryTime && !activeTemplate.expiryTime) {
        return {
          isValid: false,
          message: "Please enter expiry time",
        };
      }

      if (
        activeTemplate.addExpiryTime &&
        activeTemplate.expiryTime &&
        activeTemplate.expiryTime > 90
      ) {
        return {
          isValid: false,
          message: "Please enter expiry time less than 90 minutes",
        };
      }
    }
  }

  return { isValid: true };
};
