import {
  ASSIGNE_UNSASSIGNED,
  CLOSE,
  NEWEST,
  OLDEST,
  OPEN,
  SPAM,
  UNASSIGNED,
  YOU,
} from "constants/inboxfilterConstants";
import isNull from "lodash/isNull";
import toString from "lodash/toString";
import { INSTAGRAM, WHATSAPP } from "constants/channelConstants";

export function getUrlParams(urlSearch: string, variable: string): any {
  if (variable && typeof variable === "string") {
    const urlParams = new URLSearchParams(urlSearch);

    return urlParams.get(variable);
  }

  return "";
}

interface FilterType {
  [key: string]: string;
}

export function getUpdatedUrlFilter(
  appliedFilters: FilterType,
  filter: FilterType,
): FilterType {
  return { ...appliedFilters, ...filter };
}

export function getUpdatedUrlForFilters(filters: FilterType) {
  return `${JSON.stringify(filters)}`;
}

interface AssigneeType {
  chatType: "All" | "You" | "Unassigned" | "Spam";
  assignee: string;
}

const getAssignedData = ({ chatType, assignee }: AssigneeType) => {
  if (chatType === UNASSIGNED || assignee === ASSIGNE_UNSASSIGNED) {
    return { assigned: false, id: "" };
  }

  if (assignee === "all") {
    return { assigned: null, id: "" };
  }

  if (assignee) {
    return { assigned: true, id: assignee };
  }

  return { assigned: null, id: "" };
};

const getConvLabelData = (label: string) => {
  if (label === "all") {
    return { assigned: false, id: null };
  }

  if (label === "no") {
    return { assigned: true, id: null };
  }

  return { assigned: Boolean(label), id: label ?? null };
};

const getSortByValue = (sort: "Oldest" | "Newest") => {
  if (sort === OLDEST) {
    return "asc";
  }
  return "desc";
};

interface FormattedFilter {
  chatType?: "All" | "You" | "Unassigned" | "Spam";
  assignee?: string;
  channel?: ["Whatsapp", "Instagram"] | ["Instagram"] | ["Whatsapp"];
  chatStatus?: "Open" | "Close";
  label?: string;
  sort?: "Oldest" | "Newest";
  offset?: number;
  pageSize?: number;
  currentUserId?: string;
  organizationId?: string;
}

export const getFormattedFilter = ({
  chatType = "All",
  assignee = "all",
  channel = [WHATSAPP, INSTAGRAM],
  chatStatus = OPEN,
  label = "all",
  sort = NEWEST,
  offset = 0,
  pageSize = 10,
  currentUserId,
  organizationId,
}: FormattedFilter = {}) => {
  return {
    channel,
    chatStatus,
    pageSize,
    sortBy: getSortByValue(sort),
    offset,
    isAssignedConvLabel: getConvLabelData(label).assigned,
    conversationLabelId: getConvLabelData(label).id,
    assigned: getAssignedData({ chatType, assignee }).assigned,
    agentId: getAssignedData({ chatType, assignee }).id,
    currentUserId,
    organizationId,
    chatType,
    label,
    assignee,
  };
};

interface ConversationFilter {
  assigned?: boolean;
  agentId?: string;
  isAssignedConvLabel?: boolean;
  sortBy?: "Oldest" | "Newest";
  pageSize?: number;
  chatStatus?: "Open" | "Close";
  channel?: ["Whatsapp", "Instagram"] | ["Instagram"] | ["Whatsapp"];
  conversationLabelId?: string;
  chatType?: "All" | "You" | "Unassigned" | "Spam";
  offset?: number;
  currentUserId?: string;
}

export function formatConversationParam({
  chatStatus,
  pageSize = 10,
  assigned,
  agentId,
  isAssignedConvLabel,
  sortBy,
  channel,
  conversationLabelId,
  chatType,
  offset = 0,
  currentUserId,
}: ConversationFilter) {
  let params = ``;

  if (chatStatus === CLOSE) {
    params = `?type=closed`;
  } else if (chatType === SPAM) {
    params = `?type=spam`;
  } else {
    params = `?type=active`;
  }

  if (pageSize) {
    params += `&limit=${pageSize}`;
  }

  if (chatType === YOU) {
    params += `&assigned=true&agentId=${currentUserId}`;
  } else if (!isNull(assigned)) {
    params += `&assigned=${toString(assigned)}`;
    if (agentId) {
      params += `&agentId=${agentId}`;
    }
  }

  if (isAssignedConvLabel) {
    params += `&conversationLabelId=${conversationLabelId}`;
  }

  params += `&offset=${offset}`;

  if (sortBy) {
    params += `&sortBy=${sortBy}`;
  }

  params += `&channelTypes=${channel?.join(",") || "Whatsapp,Instagram"}`;

  return params;
}
