import {
  CREATE_WIDGET_INFO,
  CREATING_WIDGET_INFO,
  FETCHING_WIDGET_INFO,
  LOAD_INACTIVE_WIDGET_INFO,
  LOAD_WIDGET_INFO,
  SELECT_WIDGET,
  UPDATE_WIDGET_INFO,
  UPDATE_WIDGET_MODAL,
  UPDATING_WIDGET_INFO,
} from "./WidgetActions";

// [0] --.
const initialState = {
  isLoadingPage: false,
  isFetchingWidgetList: true,
  isFetchingWidgetInfo: false,
  widgets: [],
  selectedWidgetId: "",
  selectedWidget: {},
  isCreatingWidgetInfo: false,
  isUpdatingWidgetInfo: false,
  isWidgetActive: false,
  isWidgetModalShow: false,
};

const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_WIDGET_INFO:
      return {
        ...state,
        isFetchingWidgetList: action.isFetchingWidgetList,
      };

    case LOAD_WIDGET_INFO:
      return {
        ...state,
        isFetchingWidgetList: action.isFetchingWidgetList,
        widgets: action.widgets,
        selectedWidget: action.selectedWidget,
        selectedWidgetId: action.selectedWidgetId,
      };

    case SELECT_WIDGET:
      return {
        ...state,
        selectedWidget: action.selectedWidget,
      };

    case CREATING_WIDGET_INFO:
      return {
        ...state,
        isCreatingWidgetInfo: action.isCreatingWidgetInfo,
      };

    case CREATE_WIDGET_INFO:
      return {
        ...state,
        isCreatingWidgetInfo: action.isCreatingWidgetInfo,
        selectedWidgetId: action.selectedWidgetId,
        isWidgetActive: action.isWidgetActive,
        isWidgetModalShow: action.isWidgetModalShow,
      };

    case UPDATING_WIDGET_INFO:
      return {
        ...state,
        isUpdatingWidgetInfo: action.isUpdatingWidgetInfo,
      };

    case UPDATE_WIDGET_INFO:
      return {
        ...state,
        isUpdatingWidgetInfo: action.isUpdatingWidgetInfo,
        selectedWidget: action.selectedWidget,
        isWidgetActive: action.isWidgetActive,
        isWidgetModalShow: action.isWidgetModalShow,
      };

    case LOAD_INACTIVE_WIDGET_INFO:
      return {
        ...state,
        isWidgetActive: action.isWidgetActive,
        isWidgetModalShow: action.isWidgetModalShow,
      };

    case UPDATE_WIDGET_MODAL:
      return {
        ...state,
        isWidgetModalShow: action.isWidgetModalShow,
      };

    default:
      return { ...state };
  }
};

export default widgetReducer;

export const getWidgetPageLoadingState = (state) => state.widget.isLoadingPage;
export const getWidgetListFetchingState = (state) =>
  state.widget.isFetchingWidgetList;
export const getWidgetFetchingState = (state) =>
  state.widget.isFetchingWidgetInfo;
export const getWidgets = (state) => state.widget.widgets;
export const getSelectedWidget = (state) => state.widget.selectedWidget;
export const getWidgetToken = (state) =>
  state.widget.selectedWidget && state.widget.selectedWidget.widget_token
    ? state.widget.selectedWidget.widget_token
    : "";
export const getSelectedWidgetId = (state) => state.widget.selectedWidgetId;
export const getWidgetCreatingState = (state) =>
  state.widget.isCreatingWidgetInfo;
export const getWidgetUpdatingState = (state) =>
  state.widget.isUpdatingWidgetInfo;
export const getActiveWidgetState = (state) => state.widget.isWidgetActive;
export const getStatusWidgetModalState = (state) =>
  state.widget.isWidgetModalShow;
