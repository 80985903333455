export const INBOX = "inbox_#page";
export const CAMPAIGN = "campaign_#page";
export const TEMPLATES = "templates_#page";
export const USERS = "users_#page";
export const WIDGET = "widget_#page";
export const INBOX_SETTING = "inbox_setting_#page";
export const WORKFLOWS = "workflow_#page";
export const CUSTOMER_AUTO_REPLIES = "customer_auto_replies_#page";
export const CHAT_ASSIGNMENT = "chat_assignment_#page";
export const WEB_SCAN = "web_qna_#page";
export const COMMERCE_SETTING = "commerce_setting_#page";
export const ORDER_PANEL = "order_panel_#page";
export const CATALOG = "catalog_#page";
export const AUTOCHECKOUT_WORKFLOW = "autocheckout_workflow_#page";
export const COD_TO_PREPAID = "cod_to_prepaid_#page";
export const ANALYTICS_OVERVIEW = "analytics_overview_#page";
export const AGENT_PERFORMANCE = "agent perfomance_#page";
export const CTWA = "ctwa_#page";
export const WELCOME_MESSAGE = "welcome_message_#section";
export const OOO_MESSAGE = "OOO_message_#section";
export const DELAYED_MESSAGE = "delayed message_#section";
export const ONGOING_CAMPAIGNS = "ongoing_campaign_#section";
export const API_CAMPAINGS = "api_campaign_#section";
export const INTEGRATIONS = "integrations_#page";
export const WALLET_MAIN_PAGE = "wallet_main_page_#page";
export const TRACK_EXPENSES = "track_expenses_#page";
export const VIEW_INSIGHTS = "view_insight_#page";
export const BILLING_DETAILS = "billing_details_#page";
export const MEMBER_PROFILE = "member_profile_#page";
export const QUICK_REPLIES = "quick_replies_#page";
export const TEAM_SETTINGS = "team_settings_#page";
export const DEVELOPER_SETTINGS = "developer_settings_#page";
export const ACCESS_PERMISSIONS = "access_permissions_#page";
export const MANAGE_WHATSAPP_PROFILE = "manage_whatsapp_profile_#page";
