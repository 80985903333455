export const getFormattedChannelPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "-";
  }
  const country_code = phoneNumber
    ? phoneNumber.substring(0, phoneNumber.length - 10)
    : "";
  const phone = phoneNumber
    ? phoneNumber.substring(phoneNumber.length - 10)
    : "";

  if (country_code) {
    return `+${country_code} ${phone}`;
  }

  return phone;
};
