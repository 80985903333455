const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
};

const storageService = {
  html5StorageEnabled: window.localStorage && window.sessionStorage,
  appStoragePrefix: "kiwi_",

  set: (name, value) => {
    if (storageService.html5StorageEnabled) {
      localStorage.setItem(storageService.appStoragePrefix + name, value);
    }
  },

  pushInObj: (name, valueObj) => {
    const prevValue = storageService.get(name);

    if (!prevValue || !isJson(prevValue)) {
      storageService.set(name, JSON.stringify(valueObj));
      return;
    }
    const prevObjValue = JSON.parse(prevValue);
    storageService.set(name, JSON.stringify({ ...prevObjValue, ...valueObj }));
  },

  setObject: (name, value, isForSession) => {
    let validateValue = isJson(value);
    if (validateValue) {
      localStorage.setItem(
        storageService.appStoragePrefix + name,
        JSON.stringify(value),
      );
    } else {
      console.error(`${value} is not a JSON object`);
    }
  },
  // getter for the set key value pair
  get: (name) => {
    if (storageService.html5StorageEnabled) {
      return localStorage.getItem(storageService.appStoragePrefix + name);
    }
  },

  //getter for a set object
  getObject: (name) => {
    if (storageService.html5StorageEnabled) {
      const localValue = localStorage.getItem(
        storageService.appStoragePrefix + name,
      );
      if (localValue != null) {
        if (isJson(localValue)) return JSON.parse(localValue);
        else console.error(`${localValue} is not a JSON object`);
      }
    }
  },
  //removing a name
  remove: (name) => {
    if (storageService.html5StorageEnabled) {
      localStorage.removeItem(storageService.appStoragePrefix + name);
    }
  },

  flush: () => {
    localStorage.clear();
  },
};

export default storageService;
