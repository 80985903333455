import React from "react";
import Image from "konnekt/Image";
import {
  selectLargeLogo,
  selectLogoUrl,
} from "useApi/Partner/useWhitelabeledDetails";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";

const BrandLogo = ({ width = "157px", height = "40px", largeLogo = false }) => {
  const { data: logoUrl } = useCurrentWhitelabeledDetails(selectLogoUrl);
  const { data: largeLogoUrl } = useCurrentWhitelabeledDetails(selectLargeLogo);

  if (!logoUrl) return null;

  return (
    <Image
      source={[largeLogo ? largeLogoUrl : logoUrl]}
      description="Brand Logo"
      width={width}
      height={height}
    />
  );
};

export default BrandLogo;
