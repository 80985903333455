import React, { useState, Suspense, useMemo } from "react";
import styled from "styled-components";
import { space, position, color, layout } from "styled-system";
import PropTypes from "prop-types";
import Box from "konnekt/Box";
import Icon from "konnekt/Icon";
import Label from "konnekt/Label";
import GeneralLink from "konnekt/GeneralLink";
import componentLoader from "components/componentLoader";

function loadComponent(name) {
  if (name) {
    return React.lazy(() =>
      componentLoader(() => import(`components/${name}/${name}.jsx`)),
    );
  }
  return null;
}

const Container = styled.div`
  ${space}
  ${position}
  ${layout}
  display: flex;
`;

const MobileIcon = styled.div`
  ${color}
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  display: ${(props) => (props.show ? "flex" : "none")};

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    display: none;
  }
`;

const StyledAnchor = styled("a")`
  cursor: pointer;
`;

const StyledDiv = styled.div`
  ${color}
  padding: 16px 22px 16px 16px;
  width: max-content;
  border-radius: 10px;

  display: ${(props) => (props.show ? "flex" : "none")};

  @media (min-width: ${(props) => props.theme.breakpoints.mobileL}) {
    display: flex;
  }
`;

const TextContainer = styled.div`
  padding: 0 29px 0 8px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  font-weight: 600;

  & > p {
    margin-bottom: 10px;
  }

  & > a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: underline;
  }

  ${space}
`;

const LaunchFlap = ({
  message,
  path,
  handleClose,
  onClick,
  headingProps = {},
  textContainerProps = {},
  iconSettings = {
    iconName: "announcement",
    iconSize: "small",
  },
  popup,
  linkAction,
  ...props
}) => {
  const [visibility, setVisibility] = useState(true);

  const [boxVisibility, setBoxVisibility] = useState(false);

  if (!visibility) {
    return null;
  }

  const renderPopup = useMemo(() => {
    if (popup?.componentName && popup?.visibility) {
      const Component = loadComponent(popup?.componentName);

      return (
        <Suspense>
          <Component
            modalVisibility={popup?.visibility}
            setModalVisibility={popup?.setVisibility}
            {...(popup?.componentProps || {})}
          />
        </Suspense>
      );
    }

    if (popup?.visibility && linkAction === "popup") {
      return (
        <Box
          position="fixed"
          left="50%"
          top="50%"
          height="100vh"
          width="100vw"
          bg="rgba(0, 0, 0, 0.3)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={99999}
          transform="translate(-50%, -50%)"
          onClick={() => popup?.setVisibility(false)}
        >
          <Box
            position="relative"
            height="540px"
            width="540px"
            maxHeight="90vh"
            maxWidth="90vw"
          >
            <GeneralLink target="_blank" href={popup?.link}>
              <img alt="" width="100%" src={popup?.src} />
            </GeneralLink>
            <Box
              bg="white"
              borderRadius="50%"
              position="absolute"
              right="13px"
              top="13px"
              padding="4px"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon
                iconName="close"
                color="primary"
                height="10px"
                width="10px"
                onClick={() => popup?.setVisibility(false)}
              />
            </Box>
          </Box>
        </Box>
      );
    }
    return null;
  }, [popup?.componentName, popup?.setVisibility, popup?.visibility]);

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    setVisibility(false);
  };

  const renderLink = () => {
    if (path && (path.url || linkAction)) {
      return (
        <GeneralLink
          color="white"
          target="_blank"
          href={linkAction !== "popup" && !onClick && path.url}
          onClick={() => popup?.setVisibility(true)}
        >
          {path.name}
        </GeneralLink>
      );
    }
    if (path) {
      return (
        <StyledAnchor onClick={handleOnClick} rel="noopener noreferrer">
          {path.name}
        </StyledAnchor>
      );
    }
    return null;
  };

  return (
    <>
      <Container {...props}>
        <StyledDiv show={boxVisibility} {...props}>
          <Icon {...iconSettings} />
          <TextContainer {...textContainerProps}>
            <Label {...headingProps}>{message}</Label>
            {renderLink()}
          </TextContainer>
          <Icon
            mt="8px"
            iconName="close"
            color="white"
            iconSize="tiny"
            onClick={() => handleClose()}
          />
        </StyledDiv>
        <MobileIcon show={!boxVisibility} {...props}>
          <Icon
            iconName="announcement"
            color="white"
            iconSize="small"
            onClick={() => setBoxVisibility(true)}
          />
        </MobileIcon>
      </Container>
      {renderPopup}
    </>
  );
};

LaunchFlap.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  handleClose: PropTypes.func,
};

LaunchFlap.defaultProps = {
  path: {
    path: "#",
  },
  onClick: null,
  handleClose: () => {},
};

export default LaunchFlap;
