import React from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

export default function CreditCardElements(props) {
  return (
    <div>
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <div>
          <label className="stripe-label" htmlFor="">
            NAME ON CARD
          </label>
        </div>
        <input
          className="stripe-input"
          style={{
            border: "1px solid #222222",
            borderRadius: "4px",
            maxWidth: "420px",
            marginTop: "10px",
            marginBottom: "20px",
            outline: "none",
          }}
          onChange={(e) => props.handleChangeName(e)}
          placeholder=""
          type="text"
        />
      </div>
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <label className="stripe-label">CARD NUMBER</label>
        <CardNumberElement
          options={{
            ...props.options,
            showIcon: true,
          }}
          onChange={(event) => {
            props.handleChangePaymentStatus(
              false,
              event.complete,
              event.elementType,
            );
          }}
        />
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <div style={{ marginRight: "8px", width: "49%" }}>
          <label className="stripe-label">EXPIRY DATE</label>
          <CardExpiryElement
            options={props.options}
            onChange={(event) => {
              props.handleChangePaymentStatus(
                false,
                event.complete,
                event.elementType,
              );
            }}
          />
        </div>
        <div style={{ width: "49%" }}>
          <label className="stripe-label">CVV CODE</label>
          <CardCvcElement
            options={{
              ...props.options,
              placeholder: "CVV",
            }}
            onChange={(event) => {
              props.handleChangePaymentStatus(
                false,
                event.complete,
                event.elementType,
              );
            }}
            onReady={() => props.handleCVVtext()}
          />
        </div>
      </div>
    </div>
  );
}
