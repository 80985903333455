import React, { Suspense, useMemo } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import Label from "konnekt/Label";
import GeneralLink from "konnekt/GeneralLink";

import Icon from "konnekt/Icon";
import {
  FREE_PLAN_NAME,
  GROWTH_PLAN_NAME,
} from "constants/enums/paymentMethods";
import {
  getActiveSubscriptionList,
  getAllSubscriptionList,
} from "modules/Payments/PaymentSelectors";
import useMediaQuery from "customHooks/useMediaQuery";
import Box from "konnekt/Box";
import componentLoader from "components/componentLoader";

function loadComponent(name) {
  if (name) {
    return React.lazy(() =>
      componentLoader(() => import(`components/${name}/${name}.jsx`)),
    );
  }
  return null;
}

const RibbonText = styled(Label)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: currentColor;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
const StyledTooltipIcon = styled("div")`
  cursor: pointer;
  margin-left: 5px;
`;

const StyledTooltipText = styled("div")`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

// TODO: Make storybook of this component.
export default function InpageRibbon({
  displayedFor = [GROWTH_PLAN_NAME, FREE_PLAN_NAME],
  InfoTextCompo,
  TextCompo,
  forAllPlans = false,
  onClose,
  popup,
  linkAction,
  ...props
}) {
  const inMobile = useMediaQuery("tablet", "down");
  const activePlanList = useSelector(getActiveSubscriptionList);
  const allSubscriptionList = useSelector(getAllSubscriptionList);
  let activePlan = activePlanList?.[0]?.subscription?.name;
  if (activePlanList.length === 0) {
    activePlan = allSubscriptionList?.[0]?.subscription?.name;
  }

  const renderPopup = useMemo(() => {
    if (popup?.componentName && popup?.visibility) {
      const Component = loadComponent(popup?.componentName);

      return (
        <Suspense>
          <Component
            modalVisibility={popup?.visibility}
            setModalVisibility={popup?.setVisibility}
            {...(popup?.componentProps || {})}
          />
        </Suspense>
      );
    }

    if (popup?.visibility && linkAction === "popup") {
      return (
        <Box
          position="fixed"
          left="50%"
          top="50%"
          height="100vh"
          width="100vw"
          bg="rgba(0, 0, 0, 0.3)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={99999}
          transform="translate(-50%, -50%)"
          onClick={() => popup?.setVisibility(false)}
        >
          <Box
            position="relative"
            height="540px"
            width="540px"
            maxHeight="90vh"
            maxWidth="90vw"
          >
            <GeneralLink target="_blank" href={popup?.link}>
              <img alt="" width="100%" src={popup?.src} />
            </GeneralLink>

            <Box
              bg="white"
              borderRadius="50%"
              position="absolute"
              right="13px"
              top="13px"
              padding="4px"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon
                iconName="close"
                color="primary"
                height="10px"
                width="10px"
                onClick={() => popup?.setVisibility(false)}
              />
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  }, [popup?.componentName, popup?.setVisibility, popup?.visibility]);

  if ((!displayedFor.includes(activePlan) && !forAllPlans) || inMobile) {
    return null;
  }

  return (
    <>
      <Box
        position="sticky"
        width="100%"
        zIndex={800}
        display="grid"
        gridGap="0"
        alignItems="center"
        gridTemplateColumns={onClose ? "95% 4%" : "100%"}
        {...props}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridGap="18px"
        >
          {TextCompo?.icon?.name && (
            <Icon
              iconName={TextCompo.icon.name}
              iconSize={TextCompo.icon.size}
              color={TextCompo.icon.color || props.color}
              mb="1px"
            />
          )}
          <RibbonText>{TextCompo.text}</RibbonText>
          {InfoTextCompo && (
            <>
              <StyledTooltipIcon data-tip data-for="premium">
                <Icon
                  iconName="tooltip"
                  color="white"
                  height="12px"
                  width="12px"
                  mt="1px"
                />
              </StyledTooltipIcon>
              <ReactTooltip
                className="special-tooltip"
                id="premium"
                place="bottom"
                effect="solid"
              >
                <StyledTooltipText>
                  <InfoTextCompo />
                </StyledTooltipText>
              </ReactTooltip>
            </>
          )}
        </Box>
        {onClose && (
          <Icon
            iconName="close"
            color="white"
            iconSize="2xs"
            cursor="pointer"
            onClick={onClose}
          />
        )}
      </Box>
      {renderPopup}
    </>
  );
}
