import moment from "moment";
import { DATE_TIME_FORMAT_SERVER } from "constants/dateTime";

export const PAGE_SIZE = 50;

const ORDER_KEYS = {
  customer_name: "Customer Name",
  cart_received_at: "Cart Date",
  order_id: "Order ID",
  order_details: "Order Details",
  order_status: "Order Status",
  payment_status: "Payment Status",
  fulfillment_status: "Fulfillment Status",
  shipping_address: "Shipping Address",
};

export const ORDER_COLUMN_DATA = [
  {
    id: 1,
    heading: ORDER_KEYS.customer_name,
  },
  {
    id: 2,
    heading: ORDER_KEYS.cart_received_at,
  },
  {
    id: 3,
    heading: ORDER_KEYS.order_id,
  },
  {
    id: 4,
    heading: ORDER_KEYS.order_details,
  },
  {
    id: 5,
    heading: ORDER_KEYS.order_status,
  },
  {
    id: 6,
    heading: ORDER_KEYS.payment_status,
  },
  {
    id: 7,
    heading: ORDER_KEYS.fulfillment_status,
  },
  {
    id: 8,
    heading: ORDER_KEYS.shipping_address,
  },
];

export const order_status_options = [
  {
    id: "1",
    label: "Cart Received",
    value: "Cart Received",
    filter_value: "Cart Received",
    value_in_db: "CART_RECEIVED",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "2",
    label: "On Hold",
    value: "On Hold",
    filter_value: "On Hold",
    value_in_db: "ON_HOLD",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "3",
    label: "Order Confirmed",
    value: "Confirmed",
    filter_value: "Order Confirmed",
    value_in_db: "CONFIRMED",
    bg: "rgba(62, 152, 20, 0.15)",
    color: "#3E9814",
  },
  {
    id: "4",
    label: "Order Cancelled",
    value: "Cancelled",
    filter_value: "Order Cancelled",
    value_in_db: "CANCELLED",
    bg: "rgba(217, 45, 32, 0.15)",
    color: "#D92D20",
  },
];

export const payment_status_options = [
  {
    id: "1",
    label: "Payment Pending",
    value: "Pending",
    filter_value: "Payment Pending",
    value_in_db: "PENDING",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "2",
    label: "COD Confirmation Pending",
    value: "COD Confirmation Pending",
    filter_value: "COD Confirmation Pending",
    value_in_db: "COD_CONFIRMATION_PENDING",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "3",
    label: "Paid",
    value: "Paid",
    filter_value: "Payment Paid",
    value_in_db: "PAID",
    bg: "rgba(62, 152, 20, 0.15)",
    color: "#3E9814",
  },
  {
    id: "4",
    label: "COD Confirmed",
    value: "COD Confirmed",
    filter_value: "COD Confirmed",
    value_in_db: "COD_CONFIRMED",
    bg: "rgba(62, 152, 20, 0.15)",
    color: "#3E9814",
  },
];

export const fulfillment_status_options = [
  {
    id: "1",
    label: "Not Scheduled",
    value: "Not Scheduled",
    filter_value: "Fulfillment Not Scheduled",
    value_in_db: "NOT_SCHEDULED",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "2",
    label: "Scheduled",
    value: "Scheduled",
    filter_value: "Fulfillment Scheduled",
    value_in_db: "SCHEDULED",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "3",
    label: "Partially Shipped",
    value: "Partially Shipped",
    filter_value: "Fulfillment Partially Shipped",
    value_in_db: "PARTIALLY_SHIPPED",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "4",
    label: "Shipped",
    value: "Shipped",
    filter_value: "Fulfillment Shipped",
    value_in_db: "SHIPPED",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "5",
    label: "Partially Delivered",
    value: "Partially Delivered",
    filter_value: "Fulfillment Partially Delivered",
    value_in_db: "PARTIALLY_DELIVERED",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "6",
    label: "On Hold",
    value: "On Hold",
    filter_value: "Fulfillment Pending",
    value_in_db: "ON_HOLD",
    bg: "rgba(177, 91, 11, 0.15)",
    color: "#B15B0B",
  },
  {
    id: "7",
    label: "Delivered",
    value: "Delivered",
    filter_value: "Fulfillment Delivered",
    value_in_db: "DELIVERED",
    bg: "rgba(62, 152, 20, 0.15)",
    color: "#3E9814",
  },
  {
    id: "8",
    label: "Cancelled",
    value: "Cancelled",
    filter_value: "Fulfillment Cancelled",
    value_in_db: "CANCELLED",
    bg: "rgba(217, 45, 32, 0.15)",
    color: "#D92D20",
  },
];

export const orderStatusOptions = [
  { id: "all", label: "Order Status", value: "Order Status", value_in_db: "" },
  ...order_status_options.map((option) => ({
    id: option.id,
    label: option.filter_value,
    value: option.filter_value,
    value_in_db: option.value_in_db,
  })),
];

export const paymentStatusOptions = [
  {
    id: "all",
    label: "Payment Status",
    value: "Payment Status",
    value_in_db: "",
  },
  ...payment_status_options.map((option) => ({
    id: option.id,
    label: option.filter_value,
    value: option.filter_value,
    value_in_db: option.value_in_db,
  })),
];

export const fulFillmentStatusOptions = [
  {
    id: "all",
    label: "Fulfillment Status",
    value: "Fulfillment Status",
    value_in_db: "",
  },
  ...fulfillment_status_options.map((option) => ({
    id: option.id,
    label: option.filter_value,
    value: option.filter_value,
    value_in_db: option.value_in_db,
  })),
];

export const ORDER_TAB = {
  value: "order_status",
  label: "Order",
};

export const PAYMENT_TAB = {
  value: "payment_status",
  label: "Payment",
};

export const FULFILLMENT_TAB = {
  value: "fulfillment_status",
  label: "Fulfillment",
};

export const INITIAL_FILTERS = {
  orderStatus: {
    id: "all",
    label: "Order Status",
    value: "Order Status",
    value_in_db: "",
  },
  paymentStatus: {
    id: "all",
    label: "Payment Status",
    value: "Payment Status",
    value_in_db: "",
  },
  fulFillmentStatus: {
    id: "all",
    label: "Fulfillment Status",
    value: "Fulfillment Status",
    value_in_db: "",
  },
  selectedDateFilter: "Today",
  selectedFromDate: moment()
    .startOf("day")
    .subtract(0, "days")
    .tz("UTC")
    .format(DATE_TIME_FORMAT_SERVER),
  selectedToDate: moment()
    .startOf("day")
    .add(1, "days")
    .subtract(1, "seconds")
    .utc()
    .format(DATE_TIME_FORMAT_SERVER),
};
