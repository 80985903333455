import PersonLogo from "components/ChatWindow/PersonLogo";
import useOrganizationDetails from "customHooks/Organization/useOrganizationDetails";
import useInDesktop from "customHooks/useInDesktop";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import React from "react";
import { useSelector } from "react-redux";
import { getNameIntials } from "util/chatUtil";
import { getFormattedChannelPhoneNumber } from "util/accountUtil";
import { getInternalPhoneNumber } from "../../modules/App/AppSelectors";
import ConnectNumberCard from "./ConnectNumberCard";

const OrganizationDetailCard = ({
  toggleSettings = () => {},
  openSwitchAccountModel = () => {},
}) => {
  const isDesktop = useInDesktop();
  const orgDetails = useOrganizationDetails();
  const channelPhoneNumber = useSelector(getInternalPhoneNumber);

  if (!isDesktop) {
    return (
      <Box
        justifyContent="flex-end"
        display="flex"
        height={channelPhoneNumber ? "178px" : "208px"}
        p="14px"
        flexDirection="column"
        gap="15px"
        width="100%"
      >
        <Box
          mb="8px"
          display="flex"
          width="100%"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Label mt="10px" variant="text4" color="#0A0B10">
              {orgDetails.name}
            </Label>
            {channelPhoneNumber && (
              <Label mt="4px" variant="text7" color="#5A638C">
                {getFormattedChannelPhoneNumber(channelPhoneNumber)}
              </Label>
            )}
            <Box onClick={openSwitchAccountModel}>
              <Label mt="4px" variant="text6" color="#0067DD">
                Switch Account
              </Label>
            </Box>
          </Box>
          <PersonLogo
            textContainerProps={{
              bg: "rgba(0, 166, 139, 0.10)",
              color: "#00A68B",
              fontWeight: "600",
              fontSize: "20px",
            }}
            className="mobile-personal-img"
            size={60}
            text={getNameIntials(orgDetails?.name)}
          />
        </Box>
        {!channelPhoneNumber && (
          <ConnectNumberCard toggleSettings={toggleSettings} />
        )}
      </Box>
    );
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      height={channelPhoneNumber ? "140px" : "190px"}
      p="14px"
      flexDirection="column"
      gap="15px"
      width="100%"
    >
      <PersonLogo
        textContainerProps={{
          bg: "#eff1f4",
          color: "#32374E",
          fontWeight: "600",
          fontSize: "20px",
        }}
        size={60}
        text={getNameIntials(orgDetails?.name)}
      />
      <Label mt="10px" variant="text4" color="#0A0B10">
        {orgDetails.name}
      </Label>
      {channelPhoneNumber ? (
        <Label variant="text7" color="#737DA5">
          {getFormattedChannelPhoneNumber(channelPhoneNumber)}
        </Label>
      ) : (
        <ConnectNumberCard toggleSettings={toggleSettings} />
      )}
    </Box>
  );
};

export default OrganizationDetailCard;
