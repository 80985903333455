import { UPDATE_ACTIVE_CHAT } from "modules/Inbox/ThreadActions";
import { UPDATE_CUSTOMER_TRAIT_SUCCESS } from "modules/Customer/CustomerActions";
import {
  UPDATE_ACTIVE_SMART_CARD,
  UPDATE_PERSONAL_DETAILS_DATA,
  SHOW_SEARCH_SECTION,
  CLOSE_SEARCH_SECTION,
  SET_SEARCH_ACTIVE_CONVERSATION_ID,
  IS_FINDING_SEARCH_MESSAGE,
  CHANGE_SANDBOX_MODAL_VISIBILITY,
  CHANGE_SPAM_MODAL_VISIBILITY,
  SET_MULTI_SELECT_CHATS,
  RESET_MULTI_SELECT_STATE,
  ENABLE_MULTI_SELECT_STATE,
  NEW_CHAT_LOADING,
  SET_IS_REPLY_TO_MESSAGE_COMPOSER_ACTIVE,
  CLOSE_REPLY_TO_MESSAGE_PREVIEW,
  SET_REPLY_TO_MESSAGE_PREVIEW_ID,
  SET_REPLY_TO_MESSAGE_PREVIEW_HEIGHT,
  TOOGLE_SHOW_SANDBOX_CHECKPOINTS,
} from "./inboxStateTypes";
import { CLOSE_CHAT_IN_NEW_CONVERSATION } from "./ConversationActions";

const initialState = {
  smartCard: {
    activeSmartCard: {
      name: null,
      mode: null,
      lastMode: null,
    },
    personalDetailUpdatedData: null,
  },
  searchSection: {
    isSearchSectionVisible: false,
    activeConvesationId: "",
    isFindingSearchMessage: false,
  },
  sandbox: {
    modalVisibility: false,
  },
  spam: {
    modalVisibility: false,
  },
  multiSelect: {
    chats: [],
    visible: false,
  },
  newChatLoading: false,
  replyToMessage: {
    previewID: "",
    isComposerReplyActive: false,
    height: 0,
  },
  isShowSandboxCheckpoints: false,
};

const inboxStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MULTI_SELECT_STATE:
      return {
        ...state,
        multiSelect: {
          chats: [],
          visible: false,
        },
      };
    case ENABLE_MULTI_SELECT_STATE:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          visible: true,
        },
      };
    case SET_MULTI_SELECT_CHATS: {
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          multiSelect: {
            ...state.multiSelect,
            chats: action.payload,
          },
        };
      }
      const foundItem = state.multiSelect.chats?.find(
        (chat) => chat.id === action.payload.id,
      );
      if (foundItem) {
        return {
          ...state,
          multiSelect: {
            ...state.multiSelect,
            chats: state.multiSelect.chats?.filter(
              (chat) => chat.id !== action.payload.id,
            ),
          },
        };
      }
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          chats: [...state.multiSelect.chats, action.payload],
        },
      };
    }
    case CLOSE_CHAT_IN_NEW_CONVERSATION: {
      const { chatId } = action;
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          chats: state.multiSelect.chats?.filter((chat) => chat.id !== chatId),
        },
      };
    }
    case UPDATE_ACTIVE_SMART_CARD:
    case UPDATE_CUSTOMER_TRAIT_SUCCESS:
      return {
        ...state,
        smartCard: {
          ...state.smartCard,
          activeSmartCard: {
            name: action.payload?.name || null,
            mode: action.payload?.mode || null,
            lastMode: action.payload?.lastMode || null,
          },
          personalDetailUpdatedData: null,
          personalDetailsErrors: null,
        },
      };
    case UPDATE_ACTIVE_CHAT:
      return {
        ...state,
        smartCard: {
          ...state.smartCard,
          activeSmartCard: {
            name: null,
            mode: null,
            lastMode: null,
          },
          personalDetailUpdatedData: null,
          personalDetailsErrors: null,
        },
      };

    case UPDATE_PERSONAL_DETAILS_DATA:
      return {
        ...state,
        smartCard: {
          ...state.smartCard,
          personalDetailUpdatedData: {
            ...state.smartCard.personalDetailUpdatedData,
            [action.payload.keyName]: action.payload.value,
          },
          personalDetailsErrors: {
            ...state.smartCard.personalDetailsErrors,
            [action.payload.keyName]: action.payload.error,
          },
        },
      };

    case SHOW_SEARCH_SECTION:
      return {
        ...state,
        searchSection: {
          ...state.searchSection,
          isSearchSectionVisible: true,
        },
      };

    case CLOSE_SEARCH_SECTION:
      return {
        ...state,
        searchSection: {
          ...state.searchSection,
          isSearchSectionVisible: false,
        },
      };

    case SET_SEARCH_ACTIVE_CONVERSATION_ID:
      return {
        ...state,
        searchSection: {
          ...state.searchSection,
          activeConvesationId: action.payload,
        },
      };

    case IS_FINDING_SEARCH_MESSAGE:
      return {
        ...state,
        searchSection: {
          ...state.searchSection,
          isFindingSearchMessage: action.payload,
        },
      };

    case CHANGE_SANDBOX_MODAL_VISIBILITY:
      return {
        ...state,
        sandbox: {
          ...state.sandbox,
          modalVisibility: action.payload,
        },
      };

    case CHANGE_SPAM_MODAL_VISIBILITY:
      return {
        ...state,
        spam: {
          ...state.spam,
          modalVisibility: action.payload,
        },
      };

    case NEW_CHAT_LOADING:
      return {
        ...state,
        newChatLoading: action.payload,
      };

    case SET_REPLY_TO_MESSAGE_PREVIEW_ID:
      return {
        ...state,
        replyToMessage: { ...state.replyToMessage, previewID: action.payload },
      };

    case SET_IS_REPLY_TO_MESSAGE_COMPOSER_ACTIVE:
      return {
        ...state,
        replyToMessage: {
          ...state.replyToMessage,
          isComposerReplyActive: action.payload,
        },
      };

    case CLOSE_REPLY_TO_MESSAGE_PREVIEW:
      return {
        ...state,
        replyToMessage: {
          ...initialState.replyToMessage,
        },
      };

    case SET_REPLY_TO_MESSAGE_PREVIEW_HEIGHT:
      return {
        ...state,
        replyToMessage: {
          ...state.replyToMessage,
          height: action.payload,
        },
      };

    case TOOGLE_SHOW_SANDBOX_CHECKPOINTS:
      return {
        ...state,
        isShowSandboxCheckpoints: !state.isShowSandboxCheckpoints,
      };

    default:
      return state;
  }
};

export default inboxStateReducer;
