import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useIsSandboxMode from "customHooks/useIsSandboxMode";
import useMaskNumber from "customHooks/useMaskNumber";
import useShowOnboardingFeature from "customHooks/useShowOnboardingFeature";
import { Mixpanel } from "util/tracking";
import {
  INT_WEB_NAV_ANALYTICS,
  INT_WEB_NAV_INBOX,
  INT_WEB_NAV_NOTIFICATION,
  INT_WEB_NAV_SETTINGS,
  INT_WEB_NAV_USERS,
  INT_WEB_NAV_PAYMENTS,
} from "constants/mixPanel/inbox";
import {
  INBOX,
  CAMPAIGN,
  WALLET_MAIN_PAGE,
  TRACK_EXPENSES,
  VIEW_INSIGHTS,
  BILLING_DETAILS,
  ANALYTICS_OVERVIEW,
  AGENT_PERFORMANCE,
  CTWA,
  USERS,
} from "modules/Payments/vanishFeatureConstant";
import useShowWalletFeature from "customHooks/useShowWalletFeature";
import useShouldVanishFeatureByPaymentPlan from "customHooks/useShouldVanishFeatureByPaymentPlan";
import Icon from "konnekt/Icon";
import useIsSignupSourceTally from "customHooks/useIsSignupSourceTally";
import { getNameIntials } from "util/chatUtil";
import useOrganizationDetails from "customHooks/Organization/useOrganizationDetails";
import useInDesktop from "customHooks/useInDesktop";
import {
  getBottomNavBarState,
  getOrganizationOnboardedStatus,
} from "../modules/App/AppSelectors";
import { getChatWindowVisibleState } from "../modules/Inbox/ThreadReducer";
import PersonLogo from "./ChatWindow/PersonLogo";

const BottomBar = ({ selectedAccountSegment }) => {
  const isChatWindowVisible = useSelector(getChatWindowVisibleState);
  const isBottomNavVisible = useSelector(getBottomNavBarState);
  const location = useLocation();
  const isOnboardedStatus = useSelector(getOrganizationOnboardedStatus);
  const maskNumber = useMaskNumber();
  const showOnboardingFeature = useShowOnboardingFeature();
  const isSandboxMode = useIsSandboxMode();
  const showWalletFeature = useShowWalletFeature();
  const isSignupSourceTally = useIsSignupSourceTally();
  const orgData = useOrganizationDetails();
  const isDesktop = useInDesktop();
  const shouldVanishInbox = useShouldVanishFeatureByPaymentPlan([INBOX]);
  const shouldVanishNotification = useShouldVanishFeatureByPaymentPlan([
    CAMPAIGN,
  ]);
  const shouldVanishWallet = useShouldVanishFeatureByPaymentPlan([
    WALLET_MAIN_PAGE,
    TRACK_EXPENSES,
    VIEW_INSIGHTS,
    BILLING_DETAILS,
  ]);
  const shouldVanishAnalytics = useShouldVanishFeatureByPaymentPlan([
    ANALYTICS_OVERVIEW,
    AGENT_PERFORMANCE,
    CTWA,
  ]);
  const shouldVanishUsers = useShouldVanishFeatureByPaymentPlan([USERS]);

  const matchUrl = (currentUrl, shouldBe) => {
    const curr = currentUrl.split("/");
    const shd = shouldBe.split("/");
    let isMatch = true;
    curr.forEach((item, index) => {
      const matchVal = !!shd[index] && shd[index] !== "" ? shd[index] : "";
      let matchValIndx = "";
      if (!!shd[index] && shd[index] !== "") {
        // eslint-disable-next-line prefer-destructuring
        matchValIndx = shd[index][0];
      }
      if (!!!(item === matchVal || matchValIndx === ":")) {
        isMatch = false;
      }
    });
    return isMatch;
  };

  const isCampaignActive = (currentUrl) => {
    return (
      currentUrl === "/notification" ||
      currentUrl === "/notification/create-campaign" ||
      matchUrl(currentUrl, "/notification/campaign/:id") ||
      matchUrl(currentUrl, "/notification/campaign/:id/update")
    );
  };

  const isFirstIconActive = (currentPath) => {
    if (showOnboardingFeature) {
      return currentPath === "/onboarding";
    }

    return currentPath.includes("/analytics");
  };

  const isInboxActive = (currentPath) => {
    return currentPath === "/inbox";
  };

  const isPaymentActive = (currentPath) => {
    return currentPath.includes("/payments");
  };

  const isContactsListActive = (currentPath) => {
    return (
      currentPath === "/contacts/list" ||
      matchUrl(currentPath, "/contacts/:id/page")
    );
  };

  const isMobileSettingsActive = (currentPath) => {
    return (
      currentPath === "/mobile/settings" ||
      currentPath === "/organization/account-details"
    );
  };

  const isActiveForItem = (isActive) => {
    if (isActive) {
      return "active";
    }
    return "";
  };

  const isActiveForIcon = (isActive) => {
    if (isActive) {
      return "#FFFFFF";
    }
    return "#278351";
  };

  const isWebView = localStorage.getItem("webview");

  if (
    selectedAccountSegment === "thankYouScreen" ||
    (selectedAccountSegment === "paymentPlanSelection" && !isSandboxMode) ||
    isWebView
  ) {
    return null;
  }

  const currentPath = location && location.pathname ? location.pathname : "";
  const firstIconTo = showOnboardingFeature
    ? "/onboarding"
    : "/analytics/overview";

  const getFirstIcon = () => {
    if (showOnboardingFeature) {
      return "dashboard";
    }
    if (!isSignupSourceTally) {
      return "analytics";
    }
    return null;
  };

  const firstIcon = getFirstIcon();

  if (isDesktop || location.pathname === "/signup/external/whatsapp") {
    return null;
  }

  return (
    <section
      className={`${
        isBottomNavVisible ? "bottom-nav" : "bottom-nav-without-z-index"
      } mobile ${
        currentPath === "/login" || isChatWindowVisible ? "hide" : ""
      }`}
    >
      <div className="row align-items-center justify-content-around full-width">
        {(firstIconTo !== "/analytics/overview" || !shouldVanishAnalytics) &&
          (isOnboardedStatus || isSandboxMode) &&
          !maskNumber &&
          firstIcon && (
            <Link
              className={`bottom-bar-item ${isActiveForItem(
                isFirstIconActive(currentPath),
              )}`}
              to={firstIconTo}
              onClick={() => Mixpanel.track(INT_WEB_NAV_ANALYTICS, {})}
            >
              <Icon
                iconName={firstIcon}
                color={isActiveForIcon(isFirstIconActive(currentPath))}
                width="22px"
                height="22px"
              />
            </Link>
          )}
        {!shouldVanishNotification &&
        (isOnboardedStatus || isSandboxMode || isSignupSourceTally) &&
        !maskNumber ? (
          <Link
            className={`bottom-bar-item ${isActiveForItem(
              isCampaignActive(currentPath),
            )}`}
            to="/notification"
            onClick={() => Mixpanel.track(INT_WEB_NAV_NOTIFICATION, {})}
          >
            <Icon
              iconName="notifications"
              color={isActiveForIcon(isCampaignActive(currentPath))}
              width="22px"
              height="22px"
            />
          </Link>
        ) : null}
        {!shouldVanishInbox &&
        (isOnboardedStatus || isSandboxMode || isSignupSourceTally) ? (
          <Link
            className={`bottom-bar-item ${isActiveForItem(
              isInboxActive(currentPath),
            )}`}
            to="/inbox"
            onClick={() => Mixpanel.track(INT_WEB_NAV_INBOX, {})}
          >
            <Icon
              iconName="inbox"
              color={isActiveForIcon(isInboxActive(currentPath))}
              width="22px"
              height="22px"
            />
          </Link>
        ) : null}
        {!shouldVanishWallet && showWalletFeature ? (
          <Link
            className={`bottom-bar-item ${isActiveForItem(
              isPaymentActive(currentPath),
            )}`}
            to="/payments/wallet"
            onClick={() => Mixpanel.track(INT_WEB_NAV_PAYMENTS, {})}
          >
            <Icon
              iconName="wallet"
              color={isActiveForIcon(isPaymentActive(currentPath))}
              width="22px"
              height="22px"
            />
          </Link>
        ) : (
          !shouldVanishUsers &&
          (isOnboardedStatus || isSandboxMode || isSignupSourceTally) &&
          !maskNumber && (
            <Link
              className={`bottom-bar-item ${isActiveForItem(
                isContactsListActive(currentPath),
              )}`}
              to="/contacts/list"
              onClick={() => Mixpanel.track(INT_WEB_NAV_USERS, {})}
            >
              <Icon
                iconName="users"
                color={isActiveForIcon(isContactsListActive(currentPath))}
                width="22px"
                height="22px"
              />
            </Link>
          )
        )}
        <Link
          className={`bottom-bar-item ${
            isSignupSourceTally
              ? ""
              : isActiveForItem(isMobileSettingsActive(currentPath))
          }`}
          to="/mobile/settings"
          onClick={() => Mixpanel.track(INT_WEB_NAV_SETTINGS, {})}
        >
          {isSignupSourceTally ? (
            <PersonLogo
              style={{
                backgroundColor: isMobileSettingsActive(currentPath)
                  ? "#E5F5F3"
                  : "#F3F5F9",
                fontSize: "14px",
                fontWeight: "600",
                marginLeft: "auto",
                border: isMobileSettingsActive(currentPath)
                  ? "1px solid #00A68B"
                  : "1px solid #464D6D",
                borderRadius: "31px",
                color: isMobileSettingsActive(currentPath)
                  ? "#00A68B"
                  : "#464D6D",
              }}
              className="mobile-personal-img"
              size={30}
              text={getNameIntials(orgData?.name)}
            />
          ) : (
            <Icon
              iconName="settings"
              color={isActiveForIcon(isMobileSettingsActive(currentPath))}
              width="22px"
              height="22px"
            />
          )}
        </Link>
      </div>
    </section>
  );
};

export default BottomBar;
