import theme from "theme";

export const isIosSafari = () => {
  if ("navigator" in window) {
    return !!(
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/)
    );
  }

  return false;
};

export const isAppleDevice = () =>
  /Safari|iPhone|iPad|iPod/i.test(navigator.userAgent) &&
  /Apple Computer/.test(navigator.vendor);

export const isNotificationAllowed = () => {
  if (!("Notification" in window)) {
    return false;
  }

  return Notification.permission === "granted";
};

export const isNotificationPermissionRequired = () => {
  return "Notification" in window && Notification.permission !== "granted";
};

/**
 *
 * @return {"Tablet" | "Mobile" | "Desktop"}
 */
export const getDeviceType = () => {
  const mediaMatchTabletOrMobile = window.matchMedia(
    `(max-width:${theme.breakpoints.tablet})`,
  );

  const mediaMatchMobile = window.matchMedia(
    `(max-width:${theme.breakpoints.mobileL})`,
  );

  if (mediaMatchTabletOrMobile.matches) {
    if (mediaMatchMobile.matches) {
      return "Mobile";
    }
    return "Tablet";
  }

  return "Desktop";
};

export const isChrome = () => window.chrome;

export const isMacOS = () => {
  if ("navigator" in window) {
    return /(Mac)/i.test(navigator.platform);
  }

  return false;
};
