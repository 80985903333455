import React from "react";

function paused({ fill, ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_514_1299)">
        <path
          id="Vector"
          d="M4 4.00065C4 3.82384 4.07024 3.65427 4.19526 3.52925C4.32029 3.40422 4.48986 3.33398 4.66667 3.33398H6C6.17681 3.33398 6.34638 3.40422 6.4714 3.52925C6.59643 3.65427 6.66667 3.82384 6.66667 4.00065V12.0007C6.66667 12.1775 6.59643 12.347 6.4714 12.4721C6.34638 12.5971 6.17681 12.6673 6 12.6673H4.66667C4.48986 12.6673 4.32029 12.5971 4.19526 12.4721C4.07024 12.347 4 12.1775 4 12.0007V4.00065Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.33334 4.00065C9.33334 3.82384 9.40357 3.65427 9.5286 3.52925C9.65362 3.40422 9.82319 3.33398 10 3.33398H11.3333C11.5101 3.33398 11.6797 3.40422 11.8047 3.52925C11.9298 3.65427 12 3.82384 12 4.00065V12.0007C12 12.1775 11.9298 12.347 11.8047 12.4721C11.6797 12.5971 11.5101 12.6673 11.3333 12.6673H10C9.82319 12.6673 9.65362 12.5971 9.5286 12.4721C9.40357 12.347 9.33334 12.1775 9.33334 12.0007V4.00065Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_514_1299">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default paused;
