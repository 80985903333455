import React from "react";
import callApi from "util/apiCaller";
import { showNotification } from "util/utility";

export const FETCHING_WIDGET_INFO = "FETCHING_WIDGET_INFO";
export const LOAD_WIDGET_INFO = "LOAD_WIDGET_INFO";

export const SELECT_WIDGET = "SELECT_WIDGET";

export const CREATING_WIDGET_INFO = "CREATING_WIDGET_INFO";
export const CREATE_WIDGET_INFO = "CREATE_WIDGET_INFO";

export const UPDATING_WIDGET_INFO = "UPDATING_WIDGET_INFO";
export const UPDATE_WIDGET_INFO = "UPDATE_WIDGET_INFO";
export const UPDATE_WIDGET_MODAL = "UPDATE_WIDGET_MODAL";
export const LOAD_INACTIVE_WIDGET_INFO = "LOAD_INACTIVE_WIDGET_INFO";

export function getAllChatWidgets(organizationId) {
  return (dispatch) => {
    dispatch({
      type: FETCHING_WIDGET_INFO,
      isFetchingWidgetList: true,
    });
    return callApi(`v1/organizations/${organizationId}/widgets/`, "get").then(
      (res) => {
        if (res.result) {
          dispatch({
            type: LOAD_WIDGET_INFO,
            isFetchingWidgetList: false,
            widgets: res.data,
            selectedWidget: res.data[0],
            selectedWidgetId:
              res.data && res.data[0] && res.data[0].id ? res.data[0].id : "",
          });
        } else {
          dispatch({
            type: FETCHING_WIDGET_INFO,
            isFetchingWidgetList: false,
          });
        }
      },
    );
  };
}

export function getChatWidgetById(organizationId, widgetId) {
  return (dispatch) => {
    return callApi(
      `v1/organizations/${organizationId}/widgets/${widgetId}/`,
      "get",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: SELECT_WIDGET,
          selectedWidget: res.data,
        });
      } else {
      }
    });
  };
}

export function createNewChatWidget(
  organizationId,
  update,
  isSignupSourceShopify,
) {
  return (dispatch) => {
    dispatch({
      type: CREATING_WIDGET_INFO,
      isCreatingWidgetInfo: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/widgets/`,
      "post",
      update,
    ).then((res) => {
      if (res && res.result) {
        if (!isSignupSourceShopify) {
          showNotification("success", res.message);
        }
        dispatch(getChatWidgetById(organizationId, res.id));
        dispatch({
          type: CREATE_WIDGET_INFO,
          isCreatingWidgetInfo: false,
          selectedWidgetId: res.id,
          isWidgetActive: true,
          isWidgetModalShow: true,
        });
      } else {
        dispatch({
          type: CREATING_WIDGET_INFO,
          isCreatingWidgetInfo: false,
        });
      }
    });
  };
}

export function updateChatWidget(
  organizationId,
  widgetId,
  update,
  isSignupSourceShopify,
) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_WIDGET_INFO,
      isUpdatingWidgetInfo: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/widgets/${widgetId}/`,
      "put",
      update,
    ).then((res) => {
      if (res && res.result) {
        if (!isSignupSourceShopify) {
          showNotification("success", res.message);
        }
        dispatch({
          type: UPDATE_WIDGET_INFO,
          isUpdatingWidgetInfo: false,
          selectedWidget: res.data,
          isWidgetActive: true,
          isWidgetModalShow: true,
        });
      } else {
        dispatch({
          type: UPDATING_WIDGET_INFO,
          isAuthenticated: false,
          isUpdatingWidgetInfo: false,
        });
      }
    });
  };
}

export function deleteWidgetforShopify(organizationId) {
  return (dispatch) => {
    return callApi(`v1/shopify/${organizationId}/widget/`, "DELETE").then(
      (res) => {
        if (res.result) {
          dispatch({
            type: LOAD_INACTIVE_WIDGET_INFO,
            isWidgetActive: false,
            isWidgetModalShow: true,
          });
        } else {
        }
      },
    );
  };
}

export function updateWidgetModal(val) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_WIDGET_MODAL,
      isWidgetModalShow: val,
    });
  };
}
