import {
  UPDATE_TRACKER_DATA,
  FETCH_TRACKER_DATA_BY_CHAT_LIST,
} from "./TrackerActions";

const initialState = {
  readTracker: {},
};

const trackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TRACKER_DATA: {
      const readTracker = { ...state.readTracker };
      if (action.trackerData) {
        readTracker[action.trackerData.chat_id] = action.trackerData;
      }
      return {
        ...state,
        readTracker,
      };
    }

    case FETCH_TRACKER_DATA_BY_CHAT_LIST: {
      const readTracker = { ...state.readTracker };
      if (action.trackers && action.trackers.length > 0) {
        action.trackers.forEach((item) => {
          readTracker[item.chat_id] = item;
        });
      }
      return {
        ...state,
        readTracker,
      };
    }

    default:
      return state;
  }
};

export default trackerReducer;

export const getReadTracker = (state) => state.tracker.readTracker;
export const getReadTrackerForChat = (state, chatId) =>
  state.tracker.readTracker[chatId];
