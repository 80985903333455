import { UPDATE_ORDER_PANEL_FILTERS } from "./OrderPanelActionTypes";
import { INITIAL_FILTERS } from "./constants/OrderData";

const initialState = {
  orderPanelFilters: INITIAL_FILTERS,
};

// eslint-disable-next-line default-param-last
const orderPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_PANEL_FILTERS: {
      return {
        ...state,
        orderPanelFilters: { ...state.orderPanelFilters, ...action.payload },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default orderPanelReducer;
