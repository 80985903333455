import React from "react";
import useIsSandboxMode from "customHooks/useIsSandboxMode";
import useShowOnboardingFeature from "customHooks/useShowOnboardingFeature";
import {
  getAuthenticatedState,
  getOrganizationOnboardStatus,
  getOrganizationsLength,
} from "modules/App/AppSelectors";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";

const AntiProtectedRoute = ({ component, path, exact }) => {
  const isAuthenticated = useSelector(getAuthenticatedState);
  const organizationLength = useSelector(getOrganizationsLength);
  const isSandboxMode = useIsSandboxMode();
  const location = useLocation();
  const isOrganizationOnboarded = useSelector(getOrganizationOnboardStatus);
  const showOnboardingFeature = useShowOnboardingFeature();

  const getRedirectRoute = () => {
    if (isAuthenticated && organizationLength) {
      if (location.pathname === "/signup/external") {
        return "/signup/external/whatsapp";
      }
      if (isOrganizationOnboarded || isSandboxMode) {
        if (showOnboardingFeature) {
          return "/onboarding";
        }
        return "/inbox";
      }
      return "/signup/whatsapp";
    }
    return null;
  };

  const redirectUrl = getRedirectRoute();

  if (redirectUrl) {
    return (
      <Redirect
        to={{
          pathname: redirectUrl,
        }}
      />
    );
  }

  return <Route path={path} exact={exact} component={component} />;
};

export default AntiProtectedRoute;
