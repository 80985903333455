import React from "react";
import { ErrorBoundary } from "@sentry/react";
import Error404Page from "components/Error404Page";
import { EMBEDDED_FB_APP_ID } from "constants/fbAppId";
import useFBInit from "customHooks/integration/useFBInit";
import { inLocalhost } from "util/envUtil";
import CacheBuster from "./CacheBuster";
import App from "./App";
import AppHelmet from "./components/AppHelmet";
import BasicLoader from "./components/Loaders/BasicLoader";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

const MegaApp = () => {
  useFBInit(EMBEDDED_FB_APP_ID);
  // removing till we resolve tag manager issue with links
  // useTagManagerInit();

  return (
    <>
      <AppHelmet />
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return <BasicLoader />;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <ErrorBoundary fallback={Error404Page}>
              <App />
            </ErrorBoundary>
          );
        }}
      </CacheBuster>
      {(localStorage.getItem("showRqDevTools") === "1" || inLocalhost) && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction position="bottom-right" />
        </React.Suspense>
      )}
    </>
  );
};

export default MegaApp;
