import React from "react";
import Box from "konnekt/Box";
import Label from "konnekt/Label";

const Error403Page = ({ text }) => {
  return (
    <Box width="100%">
      <Box position="relative" top="300px">
        <Label fontSize="14px" fontWeight={400} textAlign="center">
          {text}
        </Label>
      </Box>
    </Box>
  );
};

export default Error403Page;
