import isArray from "lodash/isArray";
import currencySymbols from "constants/currencySymbols";

export const getCurrencySymbol = (currency) => {
  if (currency) {
    return currencySymbols[currency.toUpperCase()] || currency.toUpperCase();
  }
  return "";
};

export const formatAmount = (amount, decimal = 3) => {
  return Math.round((amount + Number.EPSILON) * 10 ** decimal) / 10 ** decimal;
};

export const getPlanUiName = (plan) => {
  if (!plan) {
    return plan;
  }

  return plan.split("_")?.join(" ");
};

export function sortSubscriptionsWithLatestCreatedStamp(array) {
  if (isArray(array)) {
    return array.sort((obj1, obj2) => {
      return (
        new Date(obj2.created_at_utc).getTime() -
        new Date(obj1.created_at_utc).getTime()
      );
    });
  }
}

export const findIsTrialExpired = (remainingTrialDays) => {
  return remainingTrialDays < 1;
};
