const currencySymbols = {
  INR: "₹",
  USD: "$",
  EUR: "€",
  AFN: "؋",
  ALL: "Lek",
  ANG: "ƒ",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "ман",
  BAM: "KM",
  BBD: "$",
  BGN: "лв",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BRL: "R$",
  BSD: "$",
  BWP: "P",
  BYR: "p.",
  BZD: "BZ$",
  CAD: "$",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUP: "₱",
  CZK: "Mč",
  DKK: "kr",
  DOP: "RD$",
  EEK: "kr",
  EGP: "£",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GGP: "£",
  GHC: "¢",
  GIP: "£",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JPY: "¥",
  KGS: "лв",
  KHR: "៛",
  KPW: "₩",
  KRW: "₩",
  KYD: "$",
  KZT: "лв",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LTL: "Lt",
  LVL: "Ls",
  MKD: "ден",
  MNT: "₮",
  MUR: "₨",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RON: "lei",
  RSD: "Дин.",
  RUB: "руб",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SOS: "S",
  SRD: "$",
  SVC: "$",
  SYP: "£",
  THB: "฿",
  TRL: "₤",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  UAH: "₴",
  UYU: "$U",
  UZS: "лв",
  VEF: "Bs",
  VND: "₫",
  XCD: "$",
  YER: "﷼",
  ZAR: "R",
  ZWD: "Z$",
};

export default currencySymbols;
