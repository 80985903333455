import { useQuery } from "@tanstack/react-query";
import useOrganizationId from "customHooks/useOrganizationId";
import { callApiV2 } from "util/apiCaller";

const sortConversationLabel = (list) => {
  if (list.length > 0) {
    return list.sort((a, b) => {
      if (a?.name.toLowerCase() < b?.name.toLowerCase()) {
        return -1;
      }
      if (a?.name.toLowerCase() > b?.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  return list;
};

export const RQ_CONVERSATION_LABELS = "RQ_CONVERSATION_LABELS";

const fetchConversationLabels = async ({ orgId }) => {
  const res = await callApiV2({
    method: "get",
    endpoint: `v1/organizations/${orgId}/conversation-labels/`,
  });

  if (res?.result) {
    return sortConversationLabel(res.data);
  }

  throw new Error(res.message);
};

const useConversationLabels = (select = (data) => data, options = {}) => {
  const orgId = useOrganizationId();
  return useQuery(
    [RQ_CONVERSATION_LABELS, orgId],
    () => fetchConversationLabels({ orgId }),
    {
      select,
      ...options,
      placeholderData: {},
      enabled:
        options.enabled === undefined ? !!orgId : !!orgId && !!options.enabled,
    },
  );
};

// selectors
// ! data is coming in Array from BE but looks like for Backward compatibility Object.values was used earlier so implemented here
export const selectLabelsList = (data) => (data ? Object.values(data) : []);

// setters
export const pushLabelIfNotThere = (data, value) => {
  if (value) {
    if (Array.isArray(data)) {
      if (data.find((label) => label.id === value.id)) {
        return data;
      }
      return sortConversationLabel([...data, value]);
    }
    return [value];
  }
  return data;
};

export const pushLabel = (data, value) => {
  if (value) {
    if (Array.isArray(data)) {
      return sortConversationLabel([...data, value]);
    }
    return [value];
  }
  return data;
};

export default useConversationLabels;
