import { inLocalhost, notInProd } from "./envUtil";

export const geIsInteraktDomain = (host: string) => {
  if (host === "app.interakt.ai") {
    return true;
  }

  if (host === "appstaging.interakt.ai") {
    return true;
  }

  if (host === "appdev.interakt.ai") {
    return true;
  }

  if (/[a-zA-Z]+.netlify.app/.test(host) && notInProd) {
    return true;
  }

  if (inLocalhost) {
    return true;
  }

  return false;
};

export const currentBasePath = `${window.location.protocol}//${window.location.host}`;
