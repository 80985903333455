export const CHANNEL_TYPE_RCS = "rcs";
export const CHANNEL_TYPE_WHATSAPP = "whatsapp";

export const CHANNEL_OPTIONS = [
  {
    label: "WhatsApp",
    value: CHANNEL_TYPE_WHATSAPP,
    leftIconProps: {
      iconName: "whatsapp",
      width: "20px",
      height: "20px",
      color: "darkGrey.80",
    },
  },
  {
    label: "RCS",
    value: CHANNEL_TYPE_RCS,
    leftIconProps: {
      iconName: "rcs",
      width: "20px",
      height: "20px",
      color: "darkGrey.80",
    },
  },
];
