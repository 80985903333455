export const MARKETING = "MARKETING";
export const UTILITY = "UTILITY";
export const AUTHENTICATION = "AUTHENTICATION";

export const templateCategories = Object.freeze([
  {
    value: "MARKETING",
    label: "Marketing",
  },
  {
    value: "UTILITY",
    label: "Utility",
  },
  {
    value: "AUTHENTICATION",
    label: "Authentication",
  },
]);

export const rcsTemplateCategories = Object.freeze([
  {
    value: "MARKETING",
    label: "Marketing",
  },
]);

export const customTemplateCategory = Object.freeze([
  {
    value: "Report",
    label: "Report",
  },
  {
    value: "Transaction",
    label: "Transaction",
  },
  {
    value: "Others",
    label: "Others",
  },
]);

export const haptikSMSChannelCategories = Object.freeze([
  {
    value: "TRANSACTIONAL",
    label: "Transactional",
  },
  {
    value: "PROMOTIONAL",
    label: "Promotional",
  },
  {
    value: "SERVICE",
    label: "Service",
  },
]);

export const CALL_TO_ACTION = "Call To Action";
export const QUICK_REPLIES = "Quick Replies";

export const buttonsOptions = [
  {
    label: QUICK_REPLIES,
    value: QUICK_REPLIES,
  },
  {
    label: CALL_TO_ACTION,
    value: CALL_TO_ACTION,
  },
];

export const CALL_PHONE_NUMBER = "Call Phone Number";
export const VISIT_WEBSITE = "Visit Website";
export const callToActionButtonOptions = [
  {
    label: CALL_PHONE_NUMBER,
    value: CALL_PHONE_NUMBER,
  },
  {
    label: VISIT_WEBSITE,
    value: VISIT_WEBSITE,
  },
];

export const STATIC_URL = "Static";
export const DYNAMIC_URL = "Dynamic";
export const urlTypeOptions = [
  {
    label: STATIC_URL,
    value: STATIC_URL,
  },
  {
    label: DYNAMIC_URL,
    value: DYNAMIC_URL,
  },
];

export const TABLE_LIST_COLUMN_COLUMN = [
  {
    id: 1,
    heading: "Template Name",
  },
  {
    id: 2,
    heading: "Status",
  },
  {
    id: 3,
    heading: "Category",
  },
  {
    id: 4,
    heading: "Language(s)",
  },
  {
    id: 5,
    heading: "Created By",
  },
];

// template status
export const APPROVED = "APPROVED";
export const WAITING = "WAITING";
export const PENDING = "PENDING";
export const REJECTED = "REJECTED";
export const DISABLED = "DISABLED";
export const IN_APPEAL = "IN_APPEAL";
export const PENDING_DELETION = "PENDING_DELETION";

export const colorDictionary = {
  [APPROVED]: "#0DB350",
  [REJECTED]: "#DC6803",
  [WAITING]: "#F6A400",
  [PENDING]: "#FEC84B",
  [DISABLED]: "#999999",
  [IN_APPEAL]: "#454ADE",
  [PENDING_DELETION]: "#132E2F",
};

export const backgroundDictionary = {
  APPROVED: "#CEFBE0",
  REJECTED: "#F0F0F0",
  WAITING: "#FFF0D3",
  PENDING: "#FFF0D3",
  DISABLED: "#F0F0F0",
  IN_APPEAL: "#B0C4DE",
  PENDING_DELETION: "#FDEED1",
};

export const ACTION_OPTIONS = [
  {
    label: "Duplicate",
    value: "duplicate",
    leftIconProps: {
      iconName: "duplicate",
      iconSize: "tiny",
      color: "#464D6D",
    },
  },
  {
    label: "Edit",
    value: "edit",
    leftIconProps: {
      iconName: "editV3",
      iconSize: "tiny",
      color: "#464D6D",
    },
  },
  {
    label: "Delete",
    value: "delete",
    leftIconProps: {
      iconName: "delete",
      iconSize: "tiny",
      color: "#464D6D",
    },
  },
];

export const LIBRARY = "library";
export const ACTIVE = "active";
export const DELETED = "deleted";

// Buttons options
export const OPTION_NONE = "None";
export const OPTION_BUTTONS = "Copy Code, URL, Quick Replies etc";
export const OPTION_SEND_PRODUCTS = "Send Products";
export const OPTION_LIMITED_TIME_OFFER = "Limited Time Offer";
export const OPTION_CARAOUSAL = "Carousel";
export const OPTION_WHATSAPP_FLOW = "WhatsApp Flow";
export const OPTION_ONE_TIME_PASSCODE = "One-Time Passcode";

// Buttons Type
export const TYPE_CATALOG = "CATALOG";
export const TYPE_MPM = "MPM";
export const TYPE_WHATSAPP_FLOW = "FLOW";

export const BUTTONS_OPTIONS = [
  {
    label: OPTION_NONE,
    value: OPTION_NONE,
  },
  {
    label: OPTION_BUTTONS,
    value: OPTION_BUTTONS,
  },
];

export const BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS = [
  ...BUTTONS_OPTIONS,
  {
    label: OPTION_SEND_PRODUCTS,
    value: OPTION_SEND_PRODUCTS,
  },
  {
    label: OPTION_LIMITED_TIME_OFFER,
    value: OPTION_LIMITED_TIME_OFFER,
  },
];

export const CAROUSEL_OPTIONS = [
  {
    label: OPTION_CARAOUSAL,
    value: OPTION_CARAOUSAL,
  },
];

export const WHATSAPP_FLOW_OPTION = [
  {
    label: OPTION_WHATSAPP_FLOW,
    value: OPTION_WHATSAPP_FLOW,
  },
];

export const CAROUSEL_AND_WA_FLOW_OPTIONS = [
  {
    label: OPTION_CARAOUSAL,
    value: OPTION_CARAOUSAL,
  },
  {
    label: OPTION_WHATSAPP_FLOW,
    value: OPTION_WHATSAPP_FLOW,
  },
];

export const BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS_AND_CAROUSAL = [
  ...BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS,
  ...CAROUSEL_OPTIONS,
];

export const BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS_AND_WHATSAPPFLOW = [
  ...BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS,
  ...WHATSAPP_FLOW_OPTION,
];

export const BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS_WITH_CAROUSAL_AND_WHATSAPPFLOW = [
  ...BUTTONS_OPTIONS_WITH_NEW_META_COMPONENTS,
  ...CAROUSEL_AND_WA_FLOW_OPTIONS,
];

export const EXCLUDED_SIGNUP_SOURCE_FOR_WHATSAPP_FLOW = [
  "AdsPlan",
  "ResellerOnline",
  "ResellerOffline",
  "ResellerWhiteLabeledOnline",
  "ResellerWhiteLabeledOffline",
  "Tally",
];

export const AUTHENTICATION_BUTTONS_OPTION = [
  {
    label: OPTION_ONE_TIME_PASSCODE,
    value: OPTION_ONE_TIME_PASSCODE,
  },
];
