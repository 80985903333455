/* eslint-disable import/order */
import React from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { space, position, system, color, border, layout } from "styled-system";
import ReactTooltip from "react-tooltip";
import { getThemeColor } from "util/styledComponent";

import { ReactComponent as ActiveDot } from "konnekt/Icon/active-dot.svg";
import { ReactComponent as InactiveDot } from "konnekt/Icon/inactive-dot.svg";
import { ReactComponent as GreyDot } from "konnekt/Icon/greyDot.svg";
import { ReactComponent as Time } from "konnekt/Icon/time.svg";
import { ReactComponent as Audience } from "konnekt/Icon/audience.svg";
import { ReactComponent as Square } from "konnekt/Icon/square.svg";
import { ReactComponent as SquareWithTick } from "konnekt/Icon/squareWithTick.svg";
import { ReactComponent as SquareWithTickV2 } from "konnekt/Icon/squareWithTickV2.svg";
import { ReactComponent as HourGlass } from "konnekt/Icon/hourGlass.svg";
import { ReactComponent as CallOutlinedV2 } from "konnekt/Icon/callOutlined.svg";
import HourGlassV2 from "./hourGlassV2";
import { ReactComponent as UnsupportedFile } from "konnekt/Icon/unsupportedFile.svg";
import { ReactComponent as Preview } from "konnekt/Icon/preview.svg";
import { ReactComponent as Settings } from "konnekt/Icon/settings.svg";
import { ReactComponent as PauseIcon } from "./pause.svg";
import { ReactComponent as PlayIcon } from "./play.svg";
import { ReactComponent as Microphone } from "./microphone.svg";
import { ReactComponent as QuestionMark } from "./question-mark.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as CloseInCircleIcon } from "./closeInCircle.svg";
import { ReactComponent as RemoveIcon } from "./remove.svg";
import { ReactComponent as WhatsappIcon } from "./whatsapp.svg";
import { ReactComponent as ConfirmationIcon } from "./confirmation.svg";
import { ReactComponent as LinkBrokenIcon } from "./linkBroken.svg";
import { ReactComponent as CancelledIcon } from "./cancelled.svg";
import { ReactComponent as PlusIcon } from "./plus-icon.svg";
import { ReactComponent as ProfileIcon } from "./profile-circle.svg";
import { ReactComponent as RectangleIcon } from "./rectangle-mobile-head.svg";
import { ReactComponent as EmojiIcon } from "./emoji.svg";
import { ReactComponent as Bug } from "./bug.svg";
import { ReactComponent as BoldIcon } from "./bold.svg";
import { ReactComponent as BoldV2 } from "./boldV2.svg";
import { ReactComponent as ItalicIcon } from "./italic.svg";
import { ReactComponent as ItalicV2 } from "./italicV2.svg";
import { ReactComponent as StrikethroughIcon } from "./strikethrough.svg";
import { ReactComponent as StrikethroughV2 } from "./strikethroughV2.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Thunder } from "./thunder.svg";
import { ReactComponent as Info2 } from "./info2.svg";
import { ReactComponent as Critical } from "./critical.svg";
import { ReactComponent as InfoOutlined } from "./infoOutlined.svg";
import { ReactComponent as InfoOutlinedV2 } from "./infoOutlinedV2.svg";
import { ReactComponent as InfoOctagon } from "./infoOctagon.svg";
import { ReactComponent as Attachment } from "./attachment.svg";
import { ReactComponent as Error } from "./error.svg";
import { ReactComponent as ErrorOutlined } from "./error-outlined.svg";
import { ReactComponent as InboxSetting } from "./inbox-setting.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Edit2 } from "./edit_icon.svg";
import { ReactComponent as EditV3 } from "./editV3.svg";
import { ReactComponent as EditFlow } from "./editFlow.svg";
import { ReactComponent as QuestionMarkOutlined } from "./question-mark-outlined.svg";
import { ReactComponent as Hours } from "./hours.svg";
import { ReactComponent as Message } from "./message.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as FilledTick } from "./filledTick.svg";
import { ReactComponent as FilledCross } from "./filledCross.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as HoursFilled } from "./hours-filled.svg";
import { ReactComponent as MessageFilled } from "./message-filled.svg";
import { ReactComponent as Tooltip } from "./tooltip.svg";
import { ReactComponent as TooltipV2 } from "./tooltipV2.svg";
import { ReactComponent as Premium } from "./premium.svg";
import { ReactComponent as CrossFilled } from "./cross-filled.svg";
import { ReactComponent as Interakt } from "./interakt.svg";
import { ReactComponent as InteraktLogo } from "./interakt-logo.svg";
import { ReactComponent as Export } from "./export.svg";
import { ReactComponent as ExportV2 } from "./exportV2.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Analytics } from "./analytics.svg";
import { ReactComponent as AnalyticsNew } from "./analytics_new.svg";
import { ReactComponent as AnalyticsV2 } from "./analyticsV2.svg";
import { ReactComponent as NewTab } from "./newTab.svg";
import { ReactComponent as NewTabV2 } from "./newTabV2.svg";
import { ReactComponent as NewTabV3 } from "./newTabV3.svg";
import { ReactComponent as Warning } from "./warning-icon.svg";
import { ReactComponent as Announcement } from "./announcement.svg";
import { ReactComponent as BackArrow } from "./backArrow.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as DownloadFile } from "./downloadFile.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as UploadFile } from "./upload-file.svg";
import { ReactComponent as RotateRight } from "./rotate-right.svg";
import { ReactComponent as Incoming } from "./incoming.svg";
import { ReactComponent as Outgoing } from "./outgoing.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Help } from "./help.svg";
import { ReactComponent as AgentAssign } from "./agent-assign.svg";
import { ReactComponent as ConvLabel } from "./conv-label.svg";
import { ReactComponent as AppliedFilter } from "./applied-filter.svg";
import { ReactComponent as SearchBG } from "./searchBG.svg";
import { ReactComponent as MessageBG } from "./messageBG.svg";
import { ReactComponent as ArrowBottom } from "./arrow-bottom.svg";
import { ReactComponent as ArrowRight } from "./arrowRight.svg";
import { ReactComponent as SendMessage } from "./send-message.svg";
import { ReactComponent as Prev } from "./prev.svg";
import { ReactComponent as Next } from "./next.svg";
import { ReactComponent as AudioPreview } from "./audioPreview.svg";
import { ReactComponent as DocPreview } from "./docPreview.svg";
import { ReactComponent as Video } from "./video.svg";
import { ReactComponent as SadCard } from "./sad-card.svg";
import { ReactComponent as VideoPreivew } from "./video-preview.svg";
import { ReactComponent as VideoPreview2 } from "./video-preview2.svg";
import { ReactComponent as CSVFile } from "./csv-file.svg";
import { ReactComponent as Widget } from "konnekt/Icon/widget.svg";
import { ReactComponent as WidgetNew } from "konnekt/Icon/widget_new.svg";
import { ReactComponent as Templates } from "konnekt/Icon/templates.svg";
import { ReactComponent as TemplatesNew } from "konnekt/Icon/template_new.svg";
import { ReactComponent as Users } from "konnekt/Icon/users.svg";
import { ReactComponent as UsersNew } from "konnekt/Icon/user_new.svg";
import { ReactComponent as FilledUser } from "konnekt/Icon/filledUser.svg";
import { ReactComponent as Notifications } from "konnekt/Icon/notifications.svg";
import { ReactComponent as NotificationsNew } from "konnekt/Icon/notification_new.svg";
import { ReactComponent as Inbox } from "konnekt/Icon/inbox.svg";
import { ReactComponent as InboxNew } from "konnekt/Icon/inbox_new.svg";
import InboxV2 from "./inboxV2";
import CopyTick from "./copyTick";
import { ReactComponent as SignupWhatsapp } from "konnekt/Icon/signupWhatsapp.svg";
import { ReactComponent as Logo } from "konnekt/Icon/logo.svg";
import { ReactComponent as Bot } from "konnekt/Icon/bot.svg";
import { ReactComponent as Automation } from "konnekt/Icon/automation.svg";
import { ReactComponent as Trigger } from "konnekt/Icon/trigger.svg";
import { ReactComponent as Tag } from "konnekt/Icon/tag.svg";
import { ReactComponent as FilledAudience } from "konnekt/Icon/filledAudience.svg";
import { ReactComponent as Commerce } from "konnekt/Icon/commerce.svg";
import { ReactComponent as CommerceNew } from "konnekt/Icon/commerce_new.svg";
import { ReactComponent as ProductPanel } from "konnekt/Icon/product.svg";
import { ReactComponent as Shop } from "konnekt/Icon/shop.svg";
import { ReactComponent as ShopV2 } from "konnekt/Icon/shopV2.svg";
import { ReactComponent as QuickReply } from "konnekt/Icon/quickReply.svg";
import { ReactComponent as QuickReplyNew } from "konnekt/Icon/quick_replies_icon.svg";
import { ReactComponent as InboxTemplate } from "konnekt/Icon/inboxTemplate.svg";
import { ReactComponent as ListBullet } from "konnekt/Icon/listBullet.svg";
import { ReactComponent as Cart } from "konnekt/Icon/cart.svg";
import { ReactComponent as CurrencyRs } from "konnekt/Icon/currencyRs.svg";
import { ReactComponent as Success } from "konnekt/Icon/success.svg";
import { ReactComponent as FilledFile } from "konnekt/Icon/filledFile.svg";
import { ReactComponent as Diamond } from "konnekt/Icon/diamond.svg";
import DiamondOutlined from "./diamond-outlined";
import { ReactComponent as SwitchOn } from "konnekt/Icon/switch-on.svg";
import { ReactComponent as SwitchOff } from "konnekt/Icon/switch-off.svg";
import { ReactComponent as Rotate } from "konnekt/Icon/rotate.svg";
import { ReactComponent as Call } from "konnekt/Icon/call.svg";
import { ReactComponent as CallOutlined } from "konnekt/Icon/call-outlined.svg";
import { ReactComponent as VisitLink } from "konnekt/Icon/visit-link.svg";
import { ReactComponent as CheckboxEmpty } from "konnekt/Icon/checkbox-empty.svg";
import { ReactComponent as CheckboxCircled } from "konnekt/Icon/checkBoxedCircled.svg";
import { ReactComponent as CheckboxIntermediate } from "konnekt/Icon/checkbox_intermediate.svg";
import { ReactComponent as CheckboxFilled } from "konnekt/Icon/checkbox_filled.svg";
import { ReactComponent as DiamondWOBG } from "konnekt/Icon/diamondWOBG.svg";
import { ReactComponent as Back } from "konnekt/Icon/back.svg";
import { ReactComponent as Global } from "konnekt/Icon/global.svg";
import { ReactComponent as RefreshLoading } from "konnekt/Icon/refresh-loading.svg";
import { ReactComponent as Refresh } from "konnekt/Icon/refresh.svg";
import { ReactComponent as PlusFilled } from "konnekt/Icon/plusFilled.svg";
import { ReactComponent as PlusOutlined } from "konnekt/Icon/plus-outlined.svg";
import { ReactComponent as Input } from "konnekt/Icon/input.svg";
import { ReactComponent as NoResult } from "konnekt/Icon/noResult.svg";
import { ReactComponent as Circle } from "konnekt/Icon/circle.svg";
import { ReactComponent as CircleWithDot } from "konnekt/Icon/circleWithDot.svg";
import { ReactComponent as CalendarOutline } from "konnekt/Icon/calendarOutline.svg";
import { ReactComponent as EyeOpen } from "konnekt/Icon/eye-open.svg";
import { ReactComponent as EyeClose } from "konnekt/Icon/eye-close.svg";
import { ReactComponent as GreenTagsCircled } from "konnekt/Icon/greenTagsCircled.svg";
import { ReactComponent as VerticalTagOutline } from "konnekt/Icon/verticalTag.svg";
import { ReactComponent as CurveLeft } from "konnekt/Icon/curveLeft.svg";
import { ReactComponent as Tags } from "konnekt/Icon/tags.svg";
import { ReactComponent as PorfileOutline } from "konnekt/Icon/profileOutline.svg";
import { ReactComponent as WhiteCircle } from "konnekt/Icon/whiteCircle.svg";
import { ReactComponent as WhiteCircleWithDot } from "konnekt/Icon/whiteCircleWithDot.svg";
import { ReactComponent as CampaignBlack } from "konnekt/Icon/campaign-black.svg";
import { ReactComponent as CampaignV2 } from "konnekt/Icon/campaignV2.svg";
import { ReactComponent as CampaignOutlined } from "konnekt/Icon/campaign-outlined.svg";
import { ReactComponent as CampaignOutlinedV2 } from "konnekt/Icon/campaign-outlined-v2.svg";
import { ReactComponent as Forward } from "konnekt/Icon/forward.svg";
import { ReactComponent as TestWebhook } from "konnekt/Icon/test-webhook.svg";
import { ReactComponent as ControlPanel } from "konnekt/Icon/controlPanel.svg";
import { ReactComponent as NoListMessage } from "konnekt/Icon/noListMessage.svg";
import { ReactComponent as MenuDot } from "konnekt/Icon/menuDot.svg";
import { ReactComponent as BorderDocument } from "konnekt/Icon/borderDocument.svg";
import { ReactComponent as TagOutline } from "konnekt/Icon/tagOutline.svg";
import { ReactComponent as Note } from "konnekt/Icon/note.svg";
import NoteV2 from "./noteV2";
import { ReactComponent as OrderDots } from "konnekt/Icon/orderDots.svg";
import { ReactComponent as Document } from "./document.svg";
import { ReactComponent as AddDocument } from "./addDocument.svg";
import { ReactComponent as Chat } from "./chat.svg";
import { ReactComponent as Contact } from "./contact.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as DelayedResponse } from "./delayedResponse.svg";
import { ReactComponent as TopRightArrow } from "./topRightArrow.svg";
import { ReactComponent as Gift } from "./gift.svg";
import { ReactComponent as Shopify } from "./shopify.svg";
import { ReactComponent as ShopifyLogo } from "./shopifyLogo.svg";
import CommerceV2 from "./commerceV2";
import { ReactComponent as DoubleTick } from "./doubleTick.svg";
import { ReactComponent as SingleTick } from "./singleTick.svg";
import { ReactComponent as SendGreen } from "./sendGreen.svg";
import AddInCircle from "./addInCircle";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as Flow } from "./flow.svg";
import { ReactComponent as FlowOutlined } from "./flow-outlined.svg";
import { ReactComponent as ChatNotification } from "./chat-notification.svg";
import { ReactComponent as Integration } from "./integration.svg";
import { ReactComponent as IntegrationOutlined } from "./integrationOutlined.svg";
import { ReactComponent as IntegrationNew } from "./integration_icon.svg";
import { ReactComponent as AddMessage } from "./addMessage.svg";
import { ReactComponent as Duplicate } from "./duplicate.svg";
import { ReactComponent as Events } from "./events.svg";
import OutlinedTick from "./outlinedTick";
import { ReactComponent as Restore } from "./restore.svg";
import { ReactComponent as Bulb } from "./bulb.svg";
import { ReactComponent as BulbV2 } from "./bulbV2.svg";
import BulbOutlined from "./bulbOutlined";
import { ReactComponent as CurvedFlow } from "./curved-flow.svg";
import { ReactComponent as Reset } from "./reset.svg";
import { ReactComponent as TriggerPoint } from "./triggerPoint.svg";
import { ReactComponent as RoundedArrow } from "./roundedArrow.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as FacebookFilled } from "./facebook-filled.svg";
import { ReactComponent as ReplyBack } from "./replyBlack.svg";
import { ReactComponent as FilledFacebook } from "./filledFacebook.svg";
import { ReactComponent as Category } from "./category.svg";
import { ReactComponent as DownloadDoc } from "./downloadDoc.svg";
import { ReactComponent as AudioChat } from "./audioChat.svg";
import { ReactComponent as NoShipping } from "./noShipping.svg";
import { ReactComponent as Shipping1 } from "./shipping1.svg";
import { ReactComponent as Shipping } from "./shipping.svg";
import { ReactComponent as City } from "./city.svg";
import { ReactComponent as NoCity } from "./noCity.svg";
import { ReactComponent as Online } from "./online.svg";
import { ReactComponent as Cash } from "./cash.svg";
import { ReactComponent as MultiSelect } from "./multiSelect.svg";
import { ReactComponent as MultiAssign } from "./multiAssign.svg";
import { ReactComponent as MultiCloseChat } from "./multiCloseChat.svg";
import { ReactComponent as AppleIcon } from "./appleIcon.svg";
import { ReactComponent as GoogleLogo } from "./googleLogo.svg";
import { ReactComponent as GooglePLayIcon } from "./googlePlayIcon.svg";
import { ReactComponent as Flag } from "./flag.svg";
import { ReactComponent as FilledFlag } from "./filledFlag.svg";
import { ReactComponent as Trending } from "./trending.svg";
import { ReactComponent as Quote } from "./quote.svg";
import { ReactComponent as MoneyTag } from "./moneyTag.svg";
import { ReactComponent as FilledArrow } from "./filledArrow.svg";
import { ReactComponent as HaptikLogo } from "./haptik-logo.svg";
import { ReactComponent as HaptikCampaigns } from "./haptik-campaigns.svg";
import { ReactComponent as HaptikTemplate } from "./haptik-template.svg";
import { ReactComponent as HaptikUsers } from "./haptik-users.svg";
import { ReactComponent as HaptikConversationStudio } from "./haptik-conversation-studio.svg";
import { ReactComponent as HaptikIntelligentAnalytics } from "./haptik-intelligent-analytics.svg";
import { ReactComponent as HaptikSms } from "./haptik-sms.svg";
import { ReactComponent as HaptikWhatsapp } from "./haptik-whatsapp.svg";
import { ReactComponent as WhatsAppProfile } from "./whatsapp_profile_icon.svg";
import { ReactComponent as WhatsAppBusiness } from "./whatsapp-business-icon.svg";
import { ReactComponent as LoginBlocker } from "./loginBlocker.svg";
import { ReactComponent as PaymentLink } from "./paymentLink.svg";
import { ReactComponent as Hundred } from "./hundred.svg";
import { ReactComponent as Segment } from "./segment.svg";
import { ReactComponent as LabelOutline } from "./labelOutline.svg";
import { ReactComponent as Webhook } from "./webhook.svg";
import { ReactComponent as Card } from "./card.svg";
import Card2 from "./card2";
import { ReactComponent as NoInternet } from "./noInternet.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as BellV2 } from "./bell-outlined.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as HelpDesk } from "./helpDesk.svg";
import { ReactComponent as HelpDeskNew } from "./help_new.svg";
import Home from "./home";
import { ReactComponent as Imagine } from "./imagine.svg";
import { ReactComponent as Clear } from "./clear.svg";
import { ReactComponent as DragIndicator } from "./drag-indicator.svg";
import { ReactComponent as Wallet } from "./wallet.svg";
import { ReactComponent as AddToWallet } from "./addToWallet.svg";
import { ReactComponent as FacebookSquare } from "./facebookSquare.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as ClockV2 } from "./clockV2.svg";
import { ReactComponent as Verified } from "./verified.svg";
import { ReactComponent as Pending } from "./pending.svg";
import { ReactComponent as Rejected } from "./rejected.svg";
import AndroidIcon from "./androidIcon";
import { ReactComponent as WhatsappTick } from "./whatsappTick.svg";
import { ReactComponent as WhatsappBot } from "./whatsappBot.svg";
import { ReactComponent as DocumentV2 } from "./documentV2.svg";
import AddInCircleEmpty from "./addInCircleEmpty";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as LockV2 } from "./lockV2.svg";
import { ReactComponent as ImageIcon } from "./imageIcon.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as ContactCard } from "./contactCard.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as InstaDM } from "./instaDM.svg";
import { ReactComponent as InstaComment } from "./instaComment.svg";
import { ReactComponent as MouseClick } from "./mouse-click.svg";
import { ReactComponent as Star } from "./star.svg";
import TickV2 from "./tickV2";
import TickV4 from "./tickV4";
import MinusCircle from "./minusCircle";
import { ReactComponent as Column } from "./column.svg";
import { ReactComponent as Carousel } from "./carousel.svg";
import { ReactComponent as Workflow } from "./workflow.svg";
import { ReactComponent as WhatsappFilled } from "./whatsapp-filled.svg";
import { ReactComponent as PlusFilledV2 } from "./plus-filled-v2.svg";
import { ReactComponent as LongArrow } from "./long-arrow.svg";
import { ReactComponent as ArrowV2 } from "./arrowV2.svg";
import { ReactComponent as EmojiV2 } from "./emojiV2.svg";
import { ReactComponent as ChatBubbles } from "./chat-bubbles.svg";
import { ReactComponent as NoMessage } from "./no-message.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as AccessPermission } from "./access_permission_icon.svg";
import { ReactComponent as AccountDetails } from "./account_details_icon.svg";
import { ReactComponent as DeveloperSetting } from "./developer_icon.svg";
import { ReactComponent as Logout } from "./log_out_icon.svg";
import { ReactComponent as MemberProfile } from "./member_profile_icon.svg";
import { ReactComponent as ErrorOctagon } from "./errorOctagon.svg";
import { ReactComponent as KebabMenu } from "./kebabMenu.svg";
import { ReactComponent as Success2 } from "./success2.svg";
import { ReactComponent as TickV3 } from "./tickV3.svg";
import { ReactComponent as ChatGroup } from "./chatGroup.svg";
import { ReactComponent as Greeting } from "./greeting.svg";
import { ReactComponent as CommentRedo } from "./comment-redo.svg";
import { ReactComponent as StopwatchSlash } from "./stopwatch-slash.svg";
import { ReactComponent as Meta } from "./meta.svg";
import { ReactComponent as Code } from "./code.svg";
import { ReactComponent as Add } from "./add.svg";
import { ReactComponent as RemoveDash } from "./remove-dash.svg";
import { ReactComponent as ToggleOff } from "./toggle-off.svg";
import { ReactComponent as ToggleOn } from "./toggle-on.svg";
import Copy from "./copy";
import { ReactComponent as Website } from "./website.svg";
import { ReactComponent as TallyPrime } from "./tallyprime.svg";
import { ReactComponent as Shield } from "./shield.svg";
import Handshake from "./handshake";
import SmartAds from "./smartAds";
import { ReactComponent as GlobeSearch } from "./globe_search.svg";
import { ReactComponent as BotIcon } from "./bot_icon.svg";
import { ReactComponent as AnnouncementHub } from "./announcementHub.svg";
import { ReactComponent as Smiley } from "./smiley.svg";
import { ReactComponent as SearchV3 } from "./searchV3.svg";
import AnnounceAnimate from "./announceAnimate";
import AnnouncementSidebar from "./announcementSidebar";
import { ReactComponent as MobileTick } from "./mobile_with_tick.svg";
import { ReactComponent as AddImage } from "./addImage.svg";
import CarouselCard from "./carouselCard";
import { ReactComponent as ReplaceIcon } from "./replaceIcon.svg";
import Linkage from "./linkage";
import Storage from "./storage";
import Hand from "./hand";
import Paused from "./paused";
import Stop from "./stop";
import Completed from "./completed";
import { ReactComponent as SmartAdsLogo } from "./smartAdsLogo.svg";
import RemoveLinkage from "./removeLinkage";
import { ReactComponent as Verify } from "./verify.svg";
import AppleIconV2 from "./appleIcon";
import { ReactComponent as ProfilePlaceHolder } from "./profilePlaceHolder.svg";
import { ReactComponent as CampaignResume } from "./campaign-resume.svg";
import { ReactComponent as RoundedInfo } from "./rounded-info.svg";
import { ReactComponent as MetaV2 } from "./metaV2.svg";
import { ReactComponent as GoogleSheets } from "./googleSheets.svg";
import { ReactComponent as CatalogNodes } from "./catalogNodes.svg";
import { ReactComponent as MagicSticks } from "./magicstick.svg";
import { ReactComponent as CommerceSettings } from "./commerce_setting.svg";
import { ReactComponent as OrderPanel } from "./order_panel.svg";
import { ReactComponent as Catalog } from "./catalog.svg";
import { ReactComponent as AutoCheckoutFlow } from "./auto_checkout_flow.svg";
import DownloadReport from "./downloadReport";
import CustomReply from "./customReply";
import useHover from "customHooks/useHover";
import Share from "./share";
import Loading from "./loading";
import TotalCount from "./totalCount";
import RCS from "./rcs";

// Always keep this list alphabetically sorted
export const iconMap = {
  accessPermission: AccessPermission,
  accountDetails: AccountDetails,
  activeDot: ActiveDot,
  greyDot: GreyDot,
  addInCircle: AddInCircle,
  agentAssign: AgentAssign,
  analytics: Analytics,
  analyticsNew: AnalyticsNew,
  analyticsV2: AnalyticsV2,
  noMessage: NoMessage,
  announcement: Announcement,
  appliedFilter: AppliedFilter,
  errorOctagon: ErrorOctagon,
  thunder: Thunder,
  arrowBottom: ArrowBottom,
  arrowRight: ArrowRight,
  attachment: Attachment,
  audience: Audience,
  automation: Automation,
  plusFilledV2: PlusFilledV2,
  questionMarkOutlined: QuestionMarkOutlined,
  audioChat: AudioChat,
  audioPreview: AudioPreview,
  backArrow: BackArrow,
  bold: BoldIcon,
  boldV2: BoldV2,
  italicV2: ItalicV2,
  sadCard: SadCard,
  bug: Bug,
  bot: Bot,
  bulb: Bulb,
  bulbV2: BulbV2,
  bulbOutlined: BulbOutlined,
  calendar: Calendar,
  cancelled: CancelledIcon,
  cash: Cash,
  chatBubbles: ChatBubbles,
  chat: Chat,
  close: CloseIcon,
  confirmation: ConfirmationIcon,
  contact: Contact,
  convLabel: ConvLabel,
  commerce: Commerce,
  commerceNew: CommerceNew,
  commerceV2: CommerceV2,
  curvedFlow: CurvedFlow,
  crossFilled: CrossFilled,
  clear: Clear,
  critical: Critical,
  csvFile: CSVFile,
  city: City,
  emojiV2: EmojiV2,
  noCity: NoCity,
  outlinedTick: OutlinedTick,
  delayedResponse: DelayedResponse,
  delete: Delete,
  developerSetting: DeveloperSetting,
  dashboard: Dashboard,
  docPreview: DocPreview,
  document: Document,
  downloadDoc: DownloadDoc,
  duplicate: Duplicate,
  download: Download,
  downloadFile: DownloadFile,
  dragIndicator: DragIndicator,
  upload: Upload,
  uploadFile: UploadFile,
  edit: Edit,
  edit2: Edit2,
  editV3: EditV3,
  editFlow: EditFlow,
  emoji: EmojiIcon,
  error: Error,
  errorOutlined: ErrorOutlined,
  export: Export,
  exportV2: ExportV2,
  eye: Eye,
  facebook: Facebook,
  facebookFilled: FacebookFilled,
  filledFacebook: FilledFacebook,
  filter: Filter,
  filledUser: FilledUser,
  filledArrow: FilledArrow,
  gift: Gift,
  grid: Grid,
  help: Help,
  hourGlass: HourGlass,
  hourGlassV2: HourGlassV2,
  hours: Hours,
  hundred: Hundred,
  hoursFilled: HoursFilled,
  inactiveDot: InactiveDot,
  inbox: Inbox,
  inboxNew: InboxNew,
  inboxV2: InboxV2,
  copyTick: CopyTick,
  verify: Verify,
  inboxSetting: InboxSetting,
  incoming: Incoming,
  info: Info,
  info2: Info2,
  infoOutlined: InfoOutlined,
  infoOutlinedV2: InfoOutlinedV2,
  infoOctagon: InfoOctagon,
  interakt: Interakt,
  interaktLogo: InteraktLogo,
  italic: ItalicIcon,
  imagine: Imagine,
  linkBroken: LinkBrokenIcon,
  loginBlocker: LoginBlocker,
  logo: Logo,
  lock: Lock,
  lockV2: LockV2,
  logout: Logout,
  success2: Success2,
  message: Message,
  memberProfile: MemberProfile,
  mail: Mail,
  messageBG: MessageBG,
  messageFilled: MessageFilled,
  microphone: Microphone,
  minusCircle: MinusCircle,
  multiSelect: MultiSelect,
  star: Star,
  callOutlinedV2: CallOutlinedV2,
  multiAssign: MultiAssign,
  multiCloseChat: MultiCloseChat,
  mouseClick: MouseClick,
  newTab: NewTab,
  newTabV2: NewTabV2,
  newTabV3: NewTabV3,
  next: Next,
  notifications: Notifications,
  notificationsNew: NotificationsNew,
  outgoing: Outgoing,
  online: Online,
  pause: PauseIcon,
  paymentLink: PaymentLink,
  play: PlayIcon,
  plus: PlusIcon,
  premium: Premium,
  prev: Prev,
  profile: ProfileIcon,
  questionMark: QuestionMark,
  rectangle: RectangleIcon,
  reset: Reset,
  replyBack: ReplyBack,
  rotate: Rotate,
  rotateRight: RotateRight,
  search: SearchIcon,
  searchBG: SearchBG,
  sendMessage: SendMessage,
  shopify: Shopify,
  shopifyLogo: ShopifyLogo,
  success: Success,
  shipping1: Shipping1,
  shipping: Shipping,
  noShipping: NoShipping,
  video: Video,
  videoPreview: VideoPreivew,
  videoPreview2: VideoPreview2,
  warning: Warning,
  unsupportedFile: UnsupportedFile,
  time: Time,
  trending: Trending,
  remove: RemoveIcon,
  roundedArrow: RoundedArrow,
  settings: Settings,
  signupWhatsapp: SignupWhatsapp,
  square: Square,
  squareWithTick: SquareWithTick,
  squareWithTickV2: SquareWithTickV2,
  preview: Preview,
  widget: Widget,
  widgetNew: WidgetNew,
  strikethrough: StrikethroughIcon,
  strikethroughV2: StrikethroughV2,
  templates: Templates,
  templatesNew: TemplatesNew,
  tick: Tick,
  filledTick: FilledTick,
  filledCross: FilledCross,
  tooltip: Tooltip,
  tooltipV2: TooltipV2,
  users: Users,
  usersNew: UsersNew,
  whatsapp: WhatsappIcon,
  trigger: Trigger,
  triggerPoint: TriggerPoint,
  tag: Tag,
  tagOutline: TagOutline,
  topRightArrow: TopRightArrow,
  filledAudience: FilledAudience,
  closeInCircle: CloseInCircleIcon,
  clock: Clock,
  clockV2: ClockV2,
  verified: Verified,
  rejected: Rejected,
  pending: Pending,
  productPanel: ProductPanel,
  borderDocument: BorderDocument,
  shop: Shop,
  shopV2: ShopV2,
  quickReply: QuickReply,
  quickReplyNew: QuickReplyNew,
  inboxTemplate: InboxTemplate,
  listBullet: ListBullet,
  cart: Cart,
  currencyRs: CurrencyRs,
  filledFile: FilledFile,
  diamond: Diamond,
  diamondOutlined: DiamondOutlined,
  switchOn: SwitchOn,
  switchOff: SwitchOff,
  call: Call,
  callOutlined: CallOutlined,
  visitLink: VisitLink,
  checkboxEmpty: CheckboxEmpty,
  checkboxCircled: CheckboxCircled,
  checkboxIntermediate: CheckboxIntermediate,
  checkboxFilled: CheckboxFilled,
  diamondWOBG: DiamondWOBG,
  back: Back,
  global: Global,
  refreshLoading: RefreshLoading,
  refresh: Refresh,
  plusFilled: PlusFilled,
  plusOutlined: PlusOutlined,
  input: Input,
  noResult: NoResult,
  circle: Circle,
  circleWithDot: CircleWithDot,
  whiteCircle: WhiteCircle,
  whiteCircleWithDot: WhiteCircleWithDot,
  campaignBlack: CampaignBlack,
  campaignV2: CampaignV2,
  campaignOutlined: CampaignOutlined,
  campaignOutlinedV2: CampaignOutlinedV2,
  forward: Forward,
  controlPanel: ControlPanel,
  noListMessage: NoListMessage,
  menuDot: MenuDot,
  note: Note,
  noteV2: NoteV2,
  orderDots: OrderDots,
  user: User,
  doubleTick: DoubleTick,
  singleTick: SingleTick,
  sendGreen: SendGreen,
  flow: Flow,
  flowOutlined: FlowOutlined,
  chatNotification: ChatNotification,
  integration: Integration,
  integrationNew: IntegrationNew,
  integrationOutlined: IntegrationOutlined,
  events: Events,
  addMessage: AddMessage,
  restore: Restore,
  category: Category,
  appleIcon: AppleIcon,
  googleLogo: GoogleLogo,
  googlePlayIcon: GooglePLayIcon,
  flag: Flag,
  filledFlag: FilledFlag,
  quote: Quote,
  testWebhook: TestWebhook,
  moneyTag: MoneyTag,
  haptikLogo: HaptikLogo,
  workflow: Workflow,
  haptikCampaigns: HaptikCampaigns,
  haptikTemplate: HaptikTemplate,
  haptikUsers: HaptikUsers,
  haptikConversationStudio: HaptikConversationStudio,
  haptikIntelligentAnalytics: HaptikIntelligentAnalytics,
  haptikSms: HaptikSms,
  haptikWhatsapp: HaptikWhatsapp,
  segment: Segment,
  labelOutline: LabelOutline,
  webhook: Webhook,
  card: Card,
  card2: Card2,
  noInternet: NoInternet,
  bell: Bell,
  bellV2: BellV2,
  helpDesk: HelpDesk,
  helpDeskNew: HelpDeskNew,
  home: Home,
  wallet: Wallet,
  addToWallet: AddToWallet,
  facebookSquare: FacebookSquare,
  imageIcon: ImageIcon,
  location: Location,
  contactCard: ContactCard,
  addInCircleEmpty: AddInCircleEmpty,
  androidIcon: AndroidIcon,
  whatsappTick: WhatsappTick,
  whatsappBot: WhatsappBot,
  documentV2: DocumentV2,
  addDocument: AddDocument,
  appleIconV2: AppleIconV2,
  profilePlaceHolder: ProfilePlaceHolder,
  instagram: Instagram,
  instagramDM: InstaDM,
  instaComment: InstaComment,
  column: Column,
  carousel: Carousel,
  whatsappFilled: WhatsappFilled,
  whatsappProfile: WhatsAppProfile,
  whatsAppBusiness: WhatsAppBusiness,
  longArrow: LongArrow,
  tickV2: TickV2,
  arrowV2: ArrowV2,
  commentRedo: CommentRedo,
  stopwatchSlash: StopwatchSlash,
  kebabMenu: KebabMenu,
  tickV3: TickV3,
  chatGroup: ChatGroup,
  greeting: Greeting,
  meta: Meta,
  code: Code,
  copy: Copy,
  website: Website,
  tickV4: TickV4,
  tallyPrime: TallyPrime,
  shield: Shield,
  handshake: Handshake,
  smartAds: SmartAds,
  add: Add,
  removeDash: RemoveDash,
  announcementHub: AnnouncementHub,
  smiley: Smiley,
  announcementSidebar: AnnouncementSidebar,
  searchV3: SearchV3,
  announceAnimate: AnnounceAnimate,
  mobileTick: MobileTick,
  toggleOff: ToggleOff,
  toggleOn: ToggleOn,
  globeSearch: GlobeSearch,
  botIcon: BotIcon,
  addImage: AddImage,
  carouselCard: CarouselCard,
  replaceIcon: ReplaceIcon,
  campaignResume: CampaignResume,
  roundedInfo: RoundedInfo,
  linkage: Linkage,
  storage: Storage,
  stop: Stop,
  hand: Hand,
  paused: Paused,
  completed: Completed,
  smartAdsLogo: SmartAdsLogo,
  removeLinkage: RemoveLinkage,
  downloadReport: DownloadReport,
  share: Share,
  loading: Loading,
  rcs: RCS,
  customReply: CustomReply,
  totalCount: TotalCount,
  calendarOutline: CalendarOutline,
  eyeOpen: EyeOpen,
  eyeClose: EyeClose,
  profileOutline: PorfileOutline,
  tags: Tags,
  greenTagsCircled: GreenTagsCircled,
  verticalTagOutline: VerticalTagOutline,
  curveLeft: CurveLeft,
  commerceSettings: CommerceSettings,
  catalog: Catalog,
  autoCheckoutFlow: AutoCheckoutFlow,
  orderPanel: OrderPanel,
  metaV2: MetaV2,
  googleSheets: GoogleSheets,
  catalogNodes: CatalogNodes,
  magicSticks: MagicSticks,
};

const iconSizeMap = {
  xl: "76px",
  "2large": "60px",
  large: "42px",
  normal: "36px",
  small: "24px",
  xs: "19px",
  "2xs": "16px",
  tiny: "13px",
  "2tiny": "10px",
};

const StyledIcon = styled.div`
  height: ${(props) => iconSizeMap[props.iconSize]};
  width: ${(props) => iconSizeMap[props.iconSize]};
  cursor: ${(props) => (props.onClick ? "pointer" : null)};
  display: flex;

  & > svg {
    transform: ${(props) => props.svgTransform ?? "none"};
  }

  ${space}
  ${position}
  ${layout}
  ${color}
  ${border}

  ${system({
    transform: {
      property: "transform",
    },
    cursor: {
      property: "cursor",
    },
  })}
`;

// IconSize is predefined sizes

/**
 * Generates a wrapper component for an icon with optional functionality.
 *
 * @param {object} props - The component props.
 * @param {string} props.iconName - The name of the icon.
 * @param {function} [props.onClick=null] - The function to call when the icon is clicked.
 * @param {('xl' | '2large' | 'large' | 'normal' | 'small' | 'xs' | '2xs' | 'tiny' | '2tiny')} [props.iconSize=null)} - The size of the icon.
 * @param {boolean} [props.noWrapper=false] - Whether to render the icon without a wrapper.
 * @param {string} [props.stroke=null] - The stroke color of the icon.
 * @param {string} [props.hoverColor=null] - The color to use when the icon is hovered.
 * @param {...rest} - Additional props that can be passed to the component.
 * @return {ReactElement} The rendered wrapper component.
 */
const IconWrapper = ({
  iconName,
  onClick = null,
  iconSize = null,
  noWrapper = false,
  stroke = null,
  hoverColor = null,
  width,
  ...rest
}) => {
  const theme = useTheme();

  const [ref, isIconHovered] = useHover();

  const IconSVG = iconMap[iconName] || QuestionMark;
  const iconProps = {};
  if (rest.color) {
    iconProps.fill = getThemeColor(
      theme,
      isIconHovered && hoverColor ? hoverColor : rest.color,
    );
  }
  if (iconSize || rest.height) {
    iconProps.height = iconSizeMap[iconSize] || rest.height;
  }
  if (iconSize || width) {
    iconProps.width = iconSizeMap[iconSize] || width;
  }

  if (stroke) {
    iconProps.stroke = stroke;
  }

  if (rest.iconClass) {
    iconProps.className = rest.iconClass;
  }

  const renderReactToolTip = () => {
    if (rest["data-for"] && rest["data-tip"]) {
      return (
        <ReactTooltip
          className={`tooltip special-tooltip  ${
            rest.toolTipClass || "tooltip__image-preview"
          }`}
          multiline
          place={rest.place || "top"}
          effect="solid"
          id={rest["data-for"]}
        >
          {rest["data-tip"]}
        </ReactTooltip>
      );
    }
    return null;
  };

  if (!noWrapper) {
    return (
      <>
        {renderReactToolTip()}
        <StyledIcon {...rest} ref={ref} iconSize={iconSize} onClick={onClick}>
          <IconSVG {...iconProps} />
        </StyledIcon>
      </>
    );
  }
  return (
    <>
      {renderReactToolTip()}
      <IconSVG {...rest} ref={ref} {...iconProps} onClick={onClick} />
    </>
  );
};

IconWrapper.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf([
    "xl",
    "2large",
    "large",
    "normal",
    "small",
    "xs",
    "2xs",
    "tiny",
    "2tiny",
  ]),
  noWrapper: PropTypes.bool,
  stroke: PropTypes.string,
  width: PropTypes.string,
};

IconWrapper.defaultProps = {
  noWrapper: false,
  iconSize: null,
  onClick: null,
  stroke: null,
  width: undefined,
};

export default IconWrapper;
