import React from "react";
import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  border,
  position,
  shadow,
  flexbox,
  grid,
  system,
} from "styled-system";
import Icon from "konnekt/Icon";
import { Link } from "react-router-dom";

const StyledLink = styled("a")`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-decoration: ${(props) => props.textDecoration ?? "underline !important"};
  cursor: pointer;

  ${system({
    whiteSpace: {
      property: "white-space",
    },
    textOverflow: {
      property: "text-overflow",
    },
  })};

  ${space};
  ${color};
  //? Sometimes the browers was giving default color set by browser for a tag. So set it to important
  color: ${(props) => `${color(props).color} !important`};
  ${typography};
  ${layout};
  ${border};
  ${position};
  ${shadow};
  ${flexbox};
  ${grid}
`;

const GeneralLink = ({
  withNewTabIcon,
  useLink = false,
  iconColor,
  iconName = "newTab",
  iconSize,
  children,
  ...props
}) => {
  const extraProps =
    props.target === "_blank"
      ? {
          rel: "noopener noreferrer",
        }
      : {};

  if (withNewTabIcon) {
    return (
      <StyledLink
        color="secondarydark"
        display="flex"
        alignItems="center"
        gridGap="3px"
        {...extraProps}
        {...props}
      >
        {children}
        <Icon
          iconName={iconName}
          color={iconColor ?? "black"}
          iconSize={iconSize ?? "2tiny"}
        />
      </StyledLink>
    );
  }
  return (
    <StyledLink
      as={useLink ? Link : "a"}
      color="secondarydark"
      {...extraProps}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default GeneralLink;
