import JSON5 from "json5";
import callApi, { callApiV2 } from "../../util/apiCaller";
import { callUploadApi } from "../../util/apiCaller";
import { showNotification } from "../../util/utility";
import { fetchAllChannelsForOrganization } from "../App/AppActions";
import closeIfIsPopupWindow from "util/closeIfIsPopupWindow";
import { encryptString } from "util/encryptionUtil";

export const SAVING_SETUP_CHANGES = "SAVING_SETUP_CHANGES";
export const SAVE_SETUP_CHANGES = "SAVE_SETUP_CHANGES";
export const GETTING_BUSINESS_DETAILS = "GETTING_BUSINESS_DETAILS";
export const GETTING_BUSINESS_COMPLIANCE_DETAILS =
  "GETTING_BUSINESS_COMPLIANCE_DETAILS";
export const GET_BUSINESS_DETAILS = "GET_BUSINESS_DETAILS";
export const SET_COMPLIANCE_INFO = "SET_COMPLIANCE_INFO";
export const CHANGE_BUSINESS_DETAILS = "CHANGE_BUSINESS_DETAILS";
export const UPLOAD_BUSINESS_LOGO = "UPLOAD_BUSINESS_LOGO";
export const CHANGE_USER_PASSWORD_STATUS = "CHANGE_USER_PASSWORD_STATUS";
export const RESET_USER_PASSWORD_STATUS = "RESET_USER_PASSWORD_STATUS";
export const REQUEST_CHANGE_USER_PASSWORD_STATUS =
  "REQUEST_CHANGE_USER_PASSWORD_STATUS";

export function changeBusinessDetails(business) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_BUSINESS_DETAILS,
      business: business,
    });
  };
}

export function changeBusinessComplianceDetails(complianceDetails) {
  return (dispatch) => {
    dispatch({
      type: SET_COMPLIANCE_INFO,
      payload: complianceDetails,
    });
  };
}

export const getBusinessComplianceDetails = (orgId) => async (dispatch) => {
  try {
    dispatch({
      type: GETTING_BUSINESS_COMPLIANCE_DETAILS,
      payload: true,
    });
    const res = await callApiV2({
      endpoint: `/v1/organizations/${orgId}/compliance-info/`,
      method: "get",
      isErrorSuppressed: true,
    });
    if (res.result) {
      dispatch({
        type: SET_COMPLIANCE_INFO,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GETTING_BUSINESS_COMPLIANCE_DETAILS,
        payload: false,
      });
    }
  } catch (err) {
    dispatch({
      type: GETTING_BUSINESS_COMPLIANCE_DETAILS,
      payload: false,
    });
  }
};

export function getBusinessDetails(organizationId, channelId) {
  return (dispatch) => {
    dispatch({
      type: GETTING_BUSINESS_DETAILS,
      isFetchingBusinessSettings: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/channels/${channelId}/business-profile/`,
      "get",
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: GET_BUSINESS_DETAILS,
          business: res.data,
          isBusinessDataLoaded: true,
          isFetchingBusinessSettings: false,
        });
      } else {
        dispatch({
          type: GETTING_BUSINESS_DETAILS,
          isFetchingBusinessSettings: false,
        });
      }
    });
  };
}

export function updateBusinessDetails(organizationId, channelId, body) {
  return (dispatch) => {
    dispatch({
      type: SAVING_SETUP_CHANGES,
      isUpdatingBusinessSettings: true,
    });
    return callApi(
      `v1/organizations/${organizationId}/channels/${channelId}/business-profile/`,
      "put",
      body,
    ).then((res) => {
      if (res.result) {
        dispatch({
          type: SAVE_SETUP_CHANGES,
          isUpdatingBusinessSettings: false,
        });
        showNotification("success", "Saved Business Info");
        closeIfIsPopupWindow();
      } else {
        dispatch({
          type: SAVING_SETUP_CHANGES,
          isUpdatingBusinessSettings: false,
        });
      }
    });
  };
}

export function uploadBusinessLogo(organizationId, channelId, body) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_BUSINESS_LOGO,
      isUploadingBusinessLogo: true,
    });
    return callUploadApi(
      `v1/organizations/${organizationId}/channels/${channelId}/business-profile-photo/`,
      "put",
      body,
    ).then((res) => {
      if (res.result) {
        dispatch(fetchAllChannelsForOrganization(organizationId));
        showNotification("success", "Business Logo Updated");
      } else {
        if (res.message && typeof res.message === "string") {
          let message = JSON5.parse(res.message);
          if (message && message[0] && message[0].details) {
            showNotification("error", message[0].details);
          }
        }
      }
      dispatch({
        type: UPLOAD_BUSINESS_LOGO,
        isUploadingBusinessLogo: false,
      });
    });
  };
}

export function requestChangeUserPassword(details) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_CHANGE_USER_PASSWORD_STATUS,
      isRequestingChangePassword: true,
    });
    return callApi(
      `v1/user/password/reset-generate-email/`,
      "post",
      details,
    ).then((res) => {
      if (res.result) {
        // dispatch({
        //     type: CHNAGE_USER_PASSWORD,
        //     isUploadingBusinessLogo: false
        // });
        showNotification(
          "success",
          "Password change link sent" + " to you, check your mail.",
        );
      }
      dispatch({
        type: REQUEST_CHANGE_USER_PASSWORD_STATUS,
        isRequestingChangePassword: false,
      });
    });
  };
}

export function changeUserPassword(details) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_USER_PASSWORD_STATUS,
      isChangingPassword: true,
    });
    return callApi(`v1/user/password/change/`, "post", details).then((res) => {
      if (res.result) {
        // dispatch({
        //     type: CHNAGE_USER_PASSWORD,
        //     isUploadingBusinessLogo: false
        // });
        showNotification("success", "Your password changed successfully");
      }
      dispatch({
        type: CHANGE_USER_PASSWORD_STATUS,
        isChangingPassword: false,
      });
    });
  };
}

export function resetUserPassword(details) {
  return (dispatch) => {
    dispatch({
      type: RESET_USER_PASSWORD_STATUS,
      isResetingPassword: true,
    });

    details.password = encryptString(details.password);

    return callApi(`v2/user/password/reset/`, "post", details).then((res) => {
      details.password = "";
      if (res.result) {
        // dispatch({
        //     type: CHNAGE_USER_PASSWORD,
        //     isUploadingBusinessLogo: false
        // });
        showNotification("success", "Password reset successfully");
      }
      dispatch({
        type: RESET_USER_PASSWORD_STATUS,
        isResetingPassword: false,
      });
    });
  };
}
