import { callApiV2 } from "../../util/apiCaller";
import { ENDPOINT_TYPES, ERROR_TXT } from "./constants";
import getEndpoint from "./utils/getEndpoint";

export const UPDATE_LOADING_DETAILS = "UPDATE_LOADING_DETAILS";
export const UPDATE_LOADING_CARD_DETAILS = "UPDATE_LOADING_CARD_DETAILS";
export const FETCH_ANALYTICS_CARDS_DETAILS = "FETCH_ANALYTICS_CARDS_DETAILS";
export const UPDATE_LOADING_AGENT_PERFORMANCE =
  "UPDATE_LOADING_AGENT_PERFORMANCE";
export const FETCH_AGENT_PERFORMANCE_DETAILS =
  "FETCH_AGENT_PERFORMANCE_DETAILS";
export const UPDATE_DATE_FILTER_TO_DATE = "UPDATE_DATE_FILTER_TO_DATE";
export const UPDATE_DATE_FILTER_FROM_DATE = "UPDATE_DATE_FILTER_FROM_DATE";
export const UPDATE_SELECTED_DATE_FILTER = "UPDATE_SELECTED_DATE_FILTER";
export const UPDATE_SELECTED_EVENT = "UPDATE_SELECTED_EVENT";
export const UPDATE_SELECTED_TAG = "UPDATE_SELECTED_TAG";
export const UPDATE_FB_CONNECTED_STATUS = "UPDATE_FB_CONNECTED_STATUS";
export const TOGGLE_GENERATE_REPORT_MODAL_STATUS =
  "TOGGLE_GENERATE_REPORT_MODAL_STATUS";

export const getAnalyticsCardDetails = (
  organizationId,
  selectedFromDate,
  selectedToDate,
  selectedTags,
  selectedEvents,
) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_LOADING_CARD_DETAILS,
      payload: true,
    });
    const res = await callApiV2({
      endpoint: getEndpoint({
        type: ENDPOINT_TYPES.analyticsCards,
        organizationId,
        selectedFromDate,
        selectedToDate,
        selectedTags,
        selectedEvents,
      }),
      errors: {
        timeout: ERROR_TXT.timeout,
      },
    });
    const graphRes = await callApiV2({
      endpoint: getEndpoint({
        type: ENDPOINT_TYPES.analyticsCardsGraph,
        organizationId,
        selectedFromDate,
        selectedToDate,
        selectedTags,
        selectedEvents,
      }),
      errors: {
        timeout: ERROR_TXT.timeout,
      },
    });
    dispatch({
      type: FETCH_ANALYTICS_CARDS_DETAILS,
      payload: { cards: res.data, graphs: graphRes.data },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_LOADING_CARD_DETAILS,
      payload: false,
    });
  }
};

export const triggerLoading = (loadingBool) => ({
  type: UPDATE_LOADING_DETAILS,
  payload: loadingBool,
});

export const getAgentPerformanceDetails = (
  organizationId,
  selectedFromDate,
  selectedToDate,
  selectedTags,
  selectedEvents,
) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_AGENT_PERFORMANCE_DETAILS,
      payload: [],
    });
    dispatch({
      type: UPDATE_LOADING_AGENT_PERFORMANCE,
      payload: true,
    });
    let offset = 0;
    let hasNextPage = true;
    let response = [];
    while (hasNextPage && offset <= 200) {
      const res = await callApiV2({
        endpoint: getEndpoint({
          type: ENDPOINT_TYPES.agentPerformance,
          organizationId,
          selectedFromDate,
          selectedToDate,
          offset,
          selectedTags,
          selectedEvents,
        }),
        errors: {
          timeout: ERROR_TXT.timeout,
        },
      });
      hasNextPage = res.data.has_next_page;
      response = [...response, ...res.data.agent_performance];
      offset += 8;
    }
    dispatch({
      type: FETCH_AGENT_PERFORMANCE_DETAILS,
      payload: response.sort((a, b) => {
        if (a.assigned_conversations > b.assigned_conversations) {
          return -1;
        }
        if (a.assigned_conversations < b.assigned_conversations) {
          return 1;
        }
        return 0;
      }),
    });
  } catch (err) {
    dispatch({
      type: UPDATE_LOADING_AGENT_PERFORMANCE,
      payload: false,
    });
  }
};

export const updateDateFilterToDate = (toDate) => {
  return {
    type: UPDATE_DATE_FILTER_TO_DATE,
    payload: toDate,
  };
};

export const updateDateFilterFromDate = (fromDate) => {
  return {
    type: UPDATE_DATE_FILTER_FROM_DATE,
    payload: fromDate,
  };
};

export const updateSelectedDateFilter = (filter) => {
  return {
    type: UPDATE_SELECTED_DATE_FILTER,
    payload: filter,
  };
};

export const updateSelectedEvent = (event) => {
  return {
    type: UPDATE_SELECTED_EVENT,
    payload: event,
  };
};

export const updateSelectedTag = (tag) => {
  return {
    type: UPDATE_SELECTED_TAG,
    payload: tag,
  };
};

export const updateFBConnectedStatus = (value) => {
  return {
    type: UPDATE_FB_CONNECTED_STATUS,
    payload: value,
  };
};

export const toggleGenerateReportModalStatus = () => ({
  type: TOGGLE_GENERATE_REPORT_MODAL_STATUS,
});
