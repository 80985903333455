import {
  FREE_TRIAL_PLAN_NAME,
  GROWTH_PLAN_NAME,
  STARTER_PLAN_NAME,
} from "constants/enums/paymentMethods";

export const isLimitExceedForAppIntegration = (apps, subscriptionList) => {
  const activePlanName = subscriptionList?.[0]?.subscription?.name;
  let appConnectedCount = 0;
  apps?.forEach((app) => {
    if (app.connected) {
      appConnectedCount += 1;
    }
  });

  if (activePlanName === FREE_TRIAL_PLAN_NAME && appConnectedCount >= 1) {
    return true;
  }
  if (activePlanName === STARTER_PLAN_NAME && appConnectedCount >= 1) {
    return true;
  }
  if (activePlanName === GROWTH_PLAN_NAME && appConnectedCount >= 3) {
    return true;
  }
  return false;
};

export const getErrorCodeForLimitExceed = (subscription) => {
  const activePlanName = subscription?.name;

  if (activePlanName === FREE_TRIAL_PLAN_NAME) {
    return "APP_INT_1";
  }
  if (activePlanName === STARTER_PLAN_NAME) {
    return "APP_INT_1";
  }
  if (activePlanName === GROWTH_PLAN_NAME) {
    return "APP_INT_3";
  }
  return "";
};
