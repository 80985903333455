import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getOrganizationTraitByKeyName } from "modules/App/AppSelectors";
import { useLocation } from "react-router-dom";
import useOrgChannelList, {
  checkIfInstaConnected,
} from "useApi/organization/useOrgChannelList";
import useIsSandboxMode from "./useIsSandboxMode";
import useOrganizationId from "./useOrganizationId";
import useActivePlan from "./useActivePlan";
import useOrganizationDetails from "./Organization/useOrganizationDetails";

// This hook is made for all announcements like Banner and PageRootRibbon
const useFilteredAnnouncement = () => {
  const orgId = useOrganizationId();
  const signUpSource = useSelector((state) =>
    getOrganizationTraitByKeyName(state, "signup_source"),
  );
  const activePlan = useActivePlan();
  const isSandboxMode = useIsSandboxMode();
  const location = useLocation();

  const isInstaActive = useOrganizationDetails(
    (org) => org?.activate_channel_instagram,
  );

  const { data: isInstaConnected } = useOrgChannelList({
    select: checkIfInstaConnected,
  });

  return useCallback(
    (announcements) => {
      if (!Array.isArray(announcements)) {
        throw new Error("Announcements must be an array");
      }

      for (let i = 0; i < announcements.length; i += 1) {
        // * If current announcement is expired then check next announcement
        if (
          announcements[i]?.expireOn &&
          typeof announcements[i]?.expireOn === "object"
        ) {
          const expiryDate = new Date(
            parseInt(announcements[i].expireOn.year, 10),
            parseInt(announcements[i].expireOn.month, 10) - 1,
            parseInt(announcements[i].expireOn.date, 10),
            parseInt(announcements[i].expireOn.hour, 10),
          );

          if (expiryDate && new Date() > expiryDate) {
            // eslint-disable-next-line no-continue
            continue;
          }
        }

        // * If current page is not included in the pages filter
        if (
          !Array.isArray(announcements[i]?.pages) ||
          !announcements[i]?.pages.some((string) =>
            new RegExp(string).test(location.pathname),
          )
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }

        // * If all filters are empty or not provided. Will assume announcement has to be shown to all
        if (
          (announcements[i]?.filters?.orgIds?.length === 0 ||
            announcements[i]?.filters?.orgIds?.length === undefined) &&
          (announcements[i]?.filters?.signupSources?.length === 0 ||
            announcements[i]?.filters?.signupSources?.length === undefined) &&
          (announcements[i]?.filters?.plans?.length === 0 ||
            announcements[i]?.filters?.plans?.length === undefined) &&
          typeof announcements[i]?.filters?.isSandboxMode !== "boolean" &&
          typeof announcements[i]?.filters?.isInstaActive !== "boolean"
        ) {
          return announcements[i];
        }

        // * If match signupSource.
        if (announcements[i]?.filters?.signupSources?.includes(signUpSource)) {
          return announcements[i];
        }

        // * If match orgId.
        if (announcements[i]?.filters?.orgIds?.includes(orgId)) {
          if (
            typeof announcements[i]?.filters?.isInstaConnected !== "boolean"
          ) {
            return announcements[i];
          }

          if (
            announcements[i]?.filters?.isInstaConnected === true &&
            isInstaConnected
          ) {
            return announcements[i];
          }

          if (
            announcements[i]?.filters?.isInstaConnected === false &&
            !isInstaConnected
          ) {
            return announcements[i];
          }
        }

        // * If match plan.
        if (announcements[i]?.filters?.plans?.includes(activePlan)) {
          return announcements[i];
        }

        // * check for isSandboxMode filter
        if (
          announcements[i]?.filters?.isSandboxMode === true &&
          isSandboxMode
        ) {
          return announcements[i];
        }
        if (
          announcements[i]?.filters?.isSandboxMode === false &&
          !isSandboxMode
        ) {
          return announcements[i];
        }

        if (
          announcements[i]?.filters?.isInstaActive === true &&
          isInstaActive
        ) {
          if (
            typeof announcements[i]?.filters?.isInstaConnected !== "boolean"
          ) {
            return announcements[i];
          }

          if (
            announcements[i]?.filters?.isInstaConnected === true &&
            isInstaConnected
          ) {
            return announcements[i];
          }

          if (
            announcements[i]?.filters?.isInstaConnected === false &&
            !isInstaConnected
          ) {
            return announcements[i];
          }
        }

        if (
          announcements[i]?.filters?.isInstaActive === false &&
          !isInstaActive
        ) {
          return announcements[i];
        }
      }
      return {};
    },
    [orgId, signUpSource, activePlan, isSandboxMode, location.pathname],
  );
};

export default useFilteredAnnouncement;
