import appsList from "./AppListData";
import {
  SET_APPS_DATA,
  SET_NEXT_APP_MODAL,
  SET_LIMIT_EXCEEDED_FOR_APP_INTEGRATION,
  UPDATE_WHATSAPP_PAY_STATUS,
  SET_APP_DETAILS_MODAL,
} from "./integrationTypes";

const initialState = {
  appsData: appsList,
  selectedApp: null,
  showNextModal: false,
  showAppDetailsModal: false,
  limitExceedForAppIntegration: true,
  whatsAppPayStatus: "",
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPS_DATA:
      return {
        ...state,
        appsData: [...action.payload],
      };
    case SET_LIMIT_EXCEEDED_FOR_APP_INTEGRATION:
      return {
        ...state,
        limitExceedForAppIntegration: action.payload,
      };
    case SET_NEXT_APP_MODAL:
      return {
        ...state,
        selectedApp: action.payload.selectedApp,
        showNextModal: action.payload.showNextModal,
      };
      case SET_APP_DETAILS_MODAL:
        return {
          ...state,
          selectedApp: action.payload.selectedApp,
          showAppDetailsModal: action.payload.showAppDetailsModal,
        };
    case UPDATE_WHATSAPP_PAY_STATUS:
      return {
        ...state,
        whatsAppPayStatus: action.payload,
      };
    default:
      return state;
  }
};

export default integrationReducer;
