import usePartnerDetails, {
  selectPartnerName,
} from "useApi/Partner/usePartnerDetails";
import {
  RESELLER_OFFLINE_NON_ONBOARDED,
  RESELLER_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN,
  RESELLER_ONLINE_NON_ONBOARDED,
  RESELLER_ONLINE_ONBOARDED_NO_ACTIVE_PLAN,
  RESELLER_WHITELABLED_OFFLINE_NON_ONBOARDED,
  RESELLER_WHITELABLED_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN,
  RESELLER_WHITELABLED_ONLINE_NON_ONBOARDED,
  RESELLER_WHITELABLED_ONLINE_ONBOARDED_NO_ACTIVE_PLAN,
} from "./resellerConstants";
import {
  TALLY_NON_ONBOARDED_NO_ACTIVE_PLAN,
  TALLY_ONBOARDED_NO_ACTIVE_PLAN,
  TALLY_ONBOARDED_PLAN_ENDED,
} from "./tallyConstants";

const ribbonDetails = {
  waba_banned: {
    ribbonText:
      "WhatsApp has disabled your WhatsApp Business Account due to policy issues. You can request for a review.",
    ctaText: "See Details",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    redirectLink: ({ wabaId }: { wabaId: string }) =>
      `https://business.facebook.com/accountquality/928974617157828/${wabaId}`,
    hideCloseIcon: false,
    theme: {
      bg: "#ef4444",
    },
  },
  waba_banned_tally: {
    ribbonText:
      "WhatsApp has disabled your WhatsApp Business Account due to policy issues. A review has been requested & should take up to 72 hours.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#ef4444",
    },
  },
  waba_restored: {
    ribbonText:
      "WhatsApp has restored your account. Please get in touch at support@interakt.ai to get your WhatsApp number re-connected to Interakt.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  user_has_expired_Subscription_plan_with_blocker_reseller: {
    ribbonText:
      "You currently don’t have an active subscription. To resume using our services, please get a subscription activated.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  user_with_insufficient_wallet_balance_reseller_online: {
    ribbonText: "Your wallet balance is very low. Please recharge your wallet.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  user_has_subscribed_for_first_time_reseller_online: {
    ribbonText: "Your wallet balance is very low. Please recharge your wallet.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  waba_restored_reseller_offline: {
    ribbonText:
      "WhatsApp has restored your account. Please get in touch with us to get your WhatsApp number re-connected.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  waba_restored_reseller_online: {
    ribbonText: () => {
      const { data: partnerName } = usePartnerDetails(selectPartnerName);

      return `WhatsApp has restored your account. Please get in touch with ${partnerName} to get your WhatsApp number re-connected to Interakt.`;
    },
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  waba_restored_tally: {
    ribbonText:
      "WhatsApp has restored your account. To get back all your templates, click on Sync in the Templates page. If you face any issues, contact Tally Support.",
    ctaText: "",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    ctaLink: "",
    hideCloseIcon: false,
    theme: {
      bg: "#00A68B",
    },
  },
  waba_warned: {
    ribbonText:
      "WhatsApp has sent a warning that your WhatsApp Business Account might be violating some policies.",
    ctaText: "Check Activity Issues",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    templateText: "",
    redirectLink: ({ wabaId }: { wabaId: string }) =>
      `https://business.facebook.com/accountquality/928974617157828/${wabaId}`,
    hideCloseIcon: false,
    theme: {
      bg: "#F79009",
    },
  },
  old_user_without_subcription: {
    ribbonText:
      "Your payment is due. Please clear your pending payment to continue using interakt.",
    ctaText: "Start your subscription",
    inboxHeaderText: "Your payment is due",
    inboxBodyText: "Please clear your pending payment using the above ribbon",
    inboxCTAText: "",
    campaignText:
      "Your payment is due. Please clear your pending payment using the above ribbon",
    templateText:
      "Your payment is due. Please clear your pending payment using the above ribbon",
    ctaLink: "/signup/whatsapp?redirectToPayment=true",
    hideCloseIcon: true,
  },
  user_has_trial_expired: {
    ribbonText:
      "Your trial has expired. Please start your subscription to continue using interakt.",
    ctaText: "Start your subscription",
    inboxHeaderText: "Your free trial has expired",
    inboxBodyText: "Please start your subscription to continue using interakt",
    inboxCTAText: "Start your subscription",
    campaignText:
      "You can’t create new campaigns because your free trial has expired. Please start your subscription from Billing page",
    templateText:
      "You can’t create new templates because your free trial has expired. Please start your subscription from Billing page",
    ctaLink: "/billing/subscription",
  },
  user_has_paid_plan_with_grace_period_shopify: {
    ribbonText:
      "Please clear your pending payments to continue using interakt without any interruption",
    ctaText: "Visit pending invoices",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    ctaLink: "/billing/invoice",
  },
  user_has_paid_plan_with_grace_period: {
    ribbonText:
      "Please clear your pending payments to continue using interakt without any interruption",
    ctaText: "Visit invoice history",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText: "",
    ctaLink: "/billing/invoice",
  },
  user_has_paid_plan_with_restrict_OTC_blocker: {
    ribbonText:
      "You have an outstanding invoice. Please complete the payment to continue using the service.",
    ctaText: "Visit invoice history",
    inboxHeaderText: "",
    inboxBodyText: "",
    inboxCTAText: "",
    campaignText:
      "You have an outstanding payment against you. Kindly clear it to set up a new campaign.",
    ctaLink: "/billing/invoice",
  },
  user_has_paid_plan_with_blocker_shopify: {
    ribbonText:
      "Please clear your pending payments to continue using interakt without any interruption",
    ctaText: "Visit pending invoices",
    inboxHeaderText: "Your account is paused due to non-payment",
    inboxBodyText:
      "Please clear your pending payments to continue using interakt without any interruption",
    inboxCTAText: "",
    campaignText:
      "Your account is paused due to non-payment. Please clear your pending payments to continue using interakt without any interruption",
    templateText:
      "Your account is paused due to non-payment. Please clear your pending payments to continue using interakt without any interruption",
    ctaLink: "/billing/invoice",
  },
  user_has_paid_plan_with_blocker: {
    ribbonText:
      "Please clear your pending payments to continue using interakt without any interruption",
    ctaText: "Visit invoice history",
    inboxHeaderText: "Your account is paused due to non-payment",
    inboxBodyText:
      "Please clear your pending payments to continue using interakt without any interruption",
    inboxCTAText: "",
    campaignText:
      "Your account is paused due to non-payment. Please clear your pending payments to continue using interakt without any interruption",
    templateText:
      "Your account is paused due to non-payment. Please clear your pending payments to continue using interakt without any interruption",
    ctaLink: "/billing/invoice",
  },
  user_has_expired_Subscription_plan_with_blocker: {
    ribbonText:
      "You currently dont have an active subscription. Please start your subscription to resume using interakt.",
    ctaText: "Start your subscription",
    inboxHeaderText: "Your payment is due",
    inboxBodyText:
      "You currently dont have an active subscription. Please start your subscription to resume using interakt.",
    inboxCTAText: "Start your subscription",
    campaignText:
      "You currently dont have an active subscription. Please start your subscription to resume using interakt.",
    templateText:
      "You currently dont have an active subscription. Please start your subscription to resume using interakt.",
    ctaLink: "/signup/whatsapp?redirectToPayment=true",
  },
  user_has_expired_Subscription_plan_with_blocker_shopify: {
    ribbonText: "You currently do not have an active subscription.",
    ctaText: "Start your subscription",
    inboxHeaderText: "Your payment is due",
    inboxBodyText: "You do not have an active subscription.",
    inboxCTAText: "Start your subscription",
    campaignText: "You do not have an active subscription.",
    ctaLink: "/signup/whatsapp?redirectToPayment=true",
  },
  user_has_expired_Subscription_plan_with_blocker_tally: {
    ribbonText:
      "You currently don’t have an active subscription. To use Interakt, please ask your TallyPrime Partner to activate subscription.",
    campaignText:
      "You currently don’t have an active subscription. To use Interakt, please ask your TallyPrime Partner to activate subscription.",
  },
  user_with_business_verified_has_free_trial_with_less_than_30_percent_days: {
    ribbonText:
      "Only {{x}} days left in your trial. Have questions around leveraging interakt for your business, pricing or anything else?",
    ctaText: "Talk to us now",
    redirectLink:
      "https://wa.me/917021512345?text=I had a few questions, could you help me please?",
    inboxHeaderText: "Your payment is due",
    inboxBodyText: "You do not have an active subscription.",
    inboxCTAText: "Start your subscription",
    campaignText: "You do not have an active subscription.",
  },
  user_with_business_unverified_has_free_trial_with_less_than_30_percent_days: {
    ribbonText:
      "Only {{x}} days left in your trial. Have questions around your business verification, messaging limits or anything else?",
    ctaText: "Talk to us now",
    redirectLink:
      "https://wa.me/917021512345?text=I had a few questions, could you help me please?",
    inboxHeaderText: "Your payment is due",
    inboxBodyText: "You do not have an active subscription.",
    inboxCTAText: "Start your subscription",
    campaignText: "You do not have an active subscription.",
  },
  user_with_business_verified: {
    ribbonText:
      "Have questions around leveraging interakt for your business, pricing or anything else?",
    ctaText: "Talk to us now",
    redirectLink:
      "https://wa.me/917021512345?text=I had a few questions, could you help me please?",
    inboxHeaderText: "Your payment is due",
    inboxBodyText: "You do not have an active subscription.",
    inboxCTAText: "Start your subscription",
    campaignText: "You do not have an active subscription.",
  },
  user_has_subscribed_for_first_time: {
    ribbonText: "Please add balance to your wallet.",
    ctaText: "Go to Wallet",
    ctaLink: "/payments/wallet",
  },
  user_has_subscribed_for_first_time_tally: {
    ribbonText:
      "Your wallet balance is very low. Please ask your TallyPrime Partner to recharge your wallet.",
    ctaText: "",
    ctaLink: "",
  },
  user_has_low_wallet_balance: {
    ribbonText:
      "Your wallet balance is low. Please recharge your wallet now to reduce the chances of your account getting paused.",
    ctaText: "Go to Wallet",
    ctaLink: "/payments/wallet",
  },
  user_with_insufficient_wallet_balance_tally: {
    ribbonText:
      "Your wallet balance is very low. Please ask your TallyPrime Partner to recharge your wallet.",
  },
  user_with_insufficient_wallet_balance: {
    ribbonText:
      "Due to insufficient wallet balance, all campaigns are now blocked on your account. Please recharge immediately to continue using your WhatsApp number.",
    ctaText: "Go to Wallet",
    ctaLink: "/payments/wallet",
    campaignText:
      "To set up a campaign, please settle your negative wallet balance first and recharge your wallet for the new campaign basis estimates.",
  },
  user_with_pending_invoices: {
    ribbonText:
      "You have pending subscription invoices. Please pay them immediately to continue using your WhatsApp number.",
    ctaText: "Clear Invoice Now",
    ctaLink: "/payments/track-expenses?type=subscriptionDeductions",
    campaignText:
      "You have pending subscription invoices. Please pay them immediately to continue using your WhatsApp number.",
  },
  user_with_business_unverified: {
    ribbonText:
      "Have questions around your business verification, messaging limits or anything else?",
    ctaText: "Talk to us now",
    redirectLink:
      "https://wa.me/917021512345?text=I had a few questions, could you help me please?",
    inboxHeaderText: "Your payment is due",
    inboxBodyText: "You do not have an active subscription.",
    inboxCTAText: "Start your subscription",
    campaignText: "You do not have an active subscription.",
  },
  maintenance: {
    ribbonText:
      "Planned maintenance underway.You might be experiencing delay in receiving and sending messages From 11.30PM IST 8th June to 2.30AM IST 9th JUNE.",
    noIcon: true,
  },
  notification: {
    ribbonText: "Get notified of new messages from your users",
    iconName: "bell",
    ctaText: "Enable Browser Notifications",
  },
  socket: {
    ribbonText: "Offline ! Could not reconnect you. Please",
    iconName: "noInternet",
    ctaText: "Click here to try again.",
    hideCloseIcon: true,
  },
  user_dont_have_permission: {
    campaignText: "You don't have permission",
  },
  [TALLY_NON_ONBOARDED_NO_ACTIVE_PLAN]: {
    campaignText: `To start sending campaigns, connect a number and then, ask your TallyPrime partner to activate your subscription.`,
  },
  [TALLY_ONBOARDED_NO_ACTIVE_PLAN]: {
    campaignText: `To start sending campaigns, ask your TallyPrime partner to activate your subscription.`,
  },
  [TALLY_ONBOARDED_PLAN_ENDED]: {
    campaignText: `Your subscription has ended. To start sending campaigns, ask your TallyPrime partner to activate your subscription.`,
  },
  [RESELLER_OFFLINE_NON_ONBOARDED]: {
    campaignText: `To create campaign, connect a number.`,
  },
  [RESELLER_WHITELABLED_OFFLINE_NON_ONBOARDED]: {
    campaignText: `To create campaign, connect a number & then get subscription activated.`,
  },
  [RESELLER_ONLINE_NON_ONBOARDED]: {
    campaignText: `To create campaign, connect a number & then get subscription activated`,
  },
  [RESELLER_WHITELABLED_ONLINE_NON_ONBOARDED]: {
    campaignText: `To create campaign, connect a number.`,
  },
  [RESELLER_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN]: {
    campaignText: `To create campaign, get subscription activated`,
  },
  [RESELLER_ONLINE_ONBOARDED_NO_ACTIVE_PLAN]: {
    campaignText: `To create campaign, get subscription activated`,
  },
  [RESELLER_WHITELABLED_OFFLINE_ONBOARDED_NO_ACTIVE_PLAN]: {
    campaignText: `To create campaign, get subscription activated`,
  },
  [RESELLER_WHITELABLED_ONLINE_ONBOARDED_NO_ACTIVE_PLAN]: {
    campaignText: `To create campaign, get subscription activated.`,
  },
} as const;

export default ribbonDetails;
