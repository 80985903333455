export const PAGES_TO_NOT_SHOW_SIDEBAR = [
  "^/workflow/create$",
  "^/workflows/",
  "/shopify/order/",
  "^/partners",
  "^/signup/external/whatsapp$",
];

export const getNotShowSidebar = (pathname) =>
  PAGES_TO_NOT_SHOW_SIDEBAR.some((string) => new RegExp(string).test(pathname));
