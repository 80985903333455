import React, { useState, useEffect } from "react";
import FavIcon from "favico.js";
import { useSelector } from "react-redux";
import useOrganizationId from "customHooks/useOrganizationId";
import { getNewChatPageTotalCount } from "modules/Inbox/ConversationReducer";
const ORG_LIST = ["2571d44f-383d-4980-aae9-811b6619efe3"];

function WithFavicon(WrappedComponent) {
  return (props) => {
    const orgId = useOrganizationId();

    const [favIconInstance, setFavIconInstance] = useState(null);
    const [faviconCount, setFaviconCount] = useState(0);

    const chatCount = useSelector(getNewChatPageTotalCount);

    useEffect(() => {
      if (chatCount >= 0 && favIconInstance) {
        setFaviconCount(chatCount);
      }
    }, [chatCount, favIconInstance]);

    useEffect(() => {
      if (!ORG_LIST.includes(orgId)) {
        if (favIconInstance?.badge) {
          favIconInstance.badge(faviconCount);
        }
      }
    }, [favIconInstance, faviconCount, orgId]);

    useEffect(() => {
      if (!ORG_LIST.includes(orgId)) {
        setFavIconInstance(
          new FavIcon({
            animation: "none",
            position: "up",
          }),
        );
      }
    }, [orgId]);

    return <WrappedComponent {...props} />;
  };
}

export default WithFavicon;
