import React from "react";

import Label from "konnekt/Label";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";
import { selectBrandName } from "useApi/Partner/useWhitelabeledDetails";

export default function BrandValue() {
  const { data: brandName } = useCurrentWhitelabeledDetails(selectBrandName);
  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          marginTop: "10px",
          marginBottom: "15px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <span className="d-flex align-items-center">
          <span>
            <img
              style={{ height: "34px", position: "relative" }}
              src="/images/pcidss.png"
              alt=""
            />
          </span>
          <span>
            <img
              style={{ width: "90px", marginLeft: "17px" }}
              src="/images/powered-by-stripe-black.svg"
              alt=""
            />
          </span>
        </span>
        <span className="d-flex align-items-center">
          <span>
            <svg
              style={{
                width: "12px",
                height: "12px",
                marginBottom: "2px",
                marginRight: "2px",
              }}
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3333 6.99992C11.3333 6.26459 10.7353 5.66659 10 5.66659H9.33333V3.66659C9.33333 1.82859 7.838 0.333252 6 0.333252C4.162 0.333252 2.66666 1.82859 2.66666 3.66659V5.66659H2C1.26466 5.66659 0.666664 6.26459 0.666664 6.99992V12.3333C0.666664 13.0686 1.26466 13.6666 2 13.6666H10C10.7353 13.6666 11.3333 13.0686 11.3333 12.3333V6.99992ZM4 3.66659C4 2.56392 4.89733 1.66659 6 1.66659C7.10266 1.66659 8 2.56392 8 3.66659V5.66659H4V3.66659Z"
                fill="#222222"
              />
            </svg>
          </span>
          <span
            style={{
              fontSize: "8px",
              fontWeight: 300,
              lineHeight: "14.62px",
            }}
          >
            HTTPS SECURE
          </span>
        </span>
      </div>
      <Label mt="10px" fontSize="12px" color="#989898" px="10px">
        Upon clicking Pay, you could be redirected to your bank's website for 3D
        secure verification.
      </Label>

      <Label mt="10px" fontSize="12px" color="#989898" px="10px">
        You also acknowledge that e-mandate will be enabled on your card and you
        will be auto-debited for the subscription charges and WhatsApp
        conversation charges incurred till the last day of your subscription.
      </Label>
      <Label fontSize="12px" color="#989898" px="10px" pb="70px">
        {brandName} does not provide refunds for cases where you don't use the
        platform or stopped using the platform for any reason whatsoever but did
        not cancel your subscription. Charges will be levied to your card until
        your subscription is canceled.
      </Label>
    </div>
  );
}
