import useUser, {
  selectFirstName,
  selectFullName,
  selectUserEmail,
  selectUserId,
} from "useApi/App/useUser";
import useChannelPhoneNumber from "./useChannelPhoneNumber";
import useOrganizationId from "./useOrganizationId";

const useAnnouncementTextReplace = () => {
  const orgId = useOrganizationId();
  const channelPhoneNumber = useChannelPhoneNumber();
  const { data: firstName } = useUser(selectFirstName);
  const { data: fullName } = useUser(selectFullName);
  const { data: userEmail } = useUser(selectUserEmail);
  const { data: userId } = useUser(selectUserId);

  const replacementMap = {
    "{{org_id}}": orgId,
    "{{channel_phone_number}}": channelPhoneNumber,
    "{{first_name}}": firstName,
    "{{full_name}}": fullName,
    "{{user_email}}": userEmail,
    "{{user_id}}": userId,
  };

  const replaceVariables = (text) => {
    let currentText = text;

    Object.entries(replacementMap).forEach(([key, value]) => {
      currentText = currentText?.replaceAll(key, value) || "";
    });

    return currentText;
  };

  return replaceVariables;
};

export default useAnnouncementTextReplace;
