import React from "react";
import Box from "konnekt/Box";
import Label from "konnekt/Label";
import GeneralLink from "konnekt/GeneralLink";
import { ModalHeader } from "konnekt/Modal";
import styled from "styled-components";

const UnorderedList = styled("ul")`
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  list-style-type: ${(props) => props.listStyleType || "circle"};
  margin-bottom: 0;
`;

const Link = ({ text, href }) => (
  <GeneralLink
    target="_blank"
    href={href}
    fontSize="16px"
    fontWeight={400}
    lineHeight="22px"
    color="#0067DD"
    textDecoration="none"
  >
    {text}
  </GeneralLink>
);

const HelpDeskModalBodyTally = ({ onHide }) => (
  <>
    <ModalHeader variant="white" onHide={onHide}>
      Help
    </ModalHeader>
    <Box display="flex" flexDirection="column" gridGap="12px" padding="24px">
      <Label fontSize="16px" fontWeight={400} color="#0A0B10" as="span">
        Refer to the following to explore more on using TallyPrime with WhatsApp
        for Business :
      </Label>
      <UnorderedList listStyleType="disc">
        <li>
          <Link
            text="TallyHelp"
            href="https://help.tallysolutions.com/tally-prime/share-documents-using-whatsapp-for-business/"
          />
        </li>
        <li>
          <Link
            text="Interakt Resource Centre"
            href="https://www.interakt.shop/resource_center/tally/"
          />
        </li>
        <li>
          <Link
            text="TallyPrime with WhatsApp - FAQ"
            href="https://help.tallysolutions.com/tally-prime/share-documents-using-whatsapp-for-business-faq/"
          />
        </li>
        <li>
          <Link
            text="Interakt Account for WhatsApp - FAQ"
            href="https://www.interakt.shop/tally-faqs/"
          />
        </li>
      </UnorderedList>
      <Label fontSize="16px" fontWeight={400} color="#0A0B10">
        In case you have any further queries, reach out to your Tally
        Partner/Tally Support.
      </Label>
    </Box>
  </>
);

export default HelpDeskModalBodyTally;
