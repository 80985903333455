import _ from "lodash";
import {
  FETCHING_ALL_PLATFORM_PERMISSIONS,
  FETCH_ALL_PLATFORM_PERMISSIONS,
  FETCHING_ORGANIZATION_ROLES,
  FETCH_ORGANIZATION_ROLES,
  SELECT_ROLE_TAB,
  UPDATE_PERMISSION_ROLE,
} from "./PermissionActions";

const initialState = {
  isFetchingBasePermissions: false,
  basePermissions: [],
  groupedPermissions: [],
  isFetchingOrganizationRoles: false,
  organizationRoles: [],
  groupOrganizationRoles: {},
  selectedRole: "Owner",
};

// eslint-disable-next-line default-param-last
const paymentReducer = (state = initialState, action) => {
  let groupedPermissions = [];
  let organizationRoles = [];
  let groupedPermisisonObject = {};
  let groupOrganizationRoles = {};
  let selectedRole = "";
  switch (action.type) {
    case FETCHING_ALL_PLATFORM_PERMISSIONS:
      return {
        ...state,
        isFetchingBasePermissions: action.isFetchingBasePermissions,
      };

    // feature_code(pin):"users_export_users"
    // feature_name(pin):"Allow to Export Users"
    // group_name(pin):"All Users"
    // is_editable(pin):true
    case FETCH_ALL_PLATFORM_PERMISSIONS:
      groupedPermissions = [];
      groupedPermisisonObject = {};
      if (action.basePermissions && action.basePermissions.length > 0) {
        action.basePermissions.forEach((item) => {
          if (groupedPermisisonObject[_.kebabCase(item.group_name)]) {
            groupedPermissions = groupedPermissions.map((groupItem) => {
              if (groupItem.group_name === item.group_name) {
                groupItem.permissions.push(item);
              }
              return groupItem;
            });
          } else {
            groupedPermisisonObject[_.kebabCase(item.group_name)] = 1;
            groupedPermissions.push({
              group_name: item.group_name,
              permissions: [item],
            });
          }
        });
      }
      return {
        ...state,
        isFetchingBasePermissions: action.isFetchingBasePermissions,
        basePermissions: action.basePermissions,
        groupedPermissions,
      };

    case FETCHING_ORGANIZATION_ROLES:
      return {
        ...state,
        isFetchingOrganizationRoles: action.isFetchingOrganizationRoles,
      };

    case FETCH_ORGANIZATION_ROLES: {
      groupOrganizationRoles = {};
      organizationRoles = [];
      const order = {
        Owner: "",
        Admin: "",
        Teammate: "",
      };
      if (action.organizationRoles && action.organizationRoles.length > 0) {
        action.organizationRoles = action.organizationRoles
          .filter((item) => !!item)
          .map((item) => {
            if (item.role === "Owner") {
              selectedRole = item.id;
            }
            item.label = item.role;
            item.value = item.id;
            order[item.role] = item.id;
            groupOrganizationRoles[item.id] = item;
            return item;
          });
        Object.keys(order).forEach((key) => {
          if (groupOrganizationRoles[order[key]]) {
            organizationRoles.push(groupOrganizationRoles[order[key]]);
          }
        });
      }
      return {
        ...state,
        isFetchingOrganizationRoles: action.isFetchingOrganizationRoles,
        organizationRoles,
        selectedRole,
        groupOrganizationRoles,
      };
    }

    case SELECT_ROLE_TAB:
      return {
        ...state,
        selectedRole: action.selectedRole,
      };

    case UPDATE_PERMISSION_ROLE:
      groupOrganizationRoles = state.groupOrganizationRoles;
      organizationRoles = state.organizationRoles.map((item) => {
        if (item.id === action.roleId) {
          item.features = item.features.map((featureItem) => {
            if (featureItem.feature_code === action.update.feature_code) {
              featureItem.is_enabled = action.update.is_enabled;
            }
            return featureItem;
          });
          groupOrganizationRoles[
            action.roleId
          ].features = groupOrganizationRoles[action.roleId].features.map(
            (featureItem) => {
              if (featureItem.feature_code === action.update.feature_code) {
                featureItem.is_enabled = action.update.is_enabled;
              }
              return featureItem;
            },
          );
        }
        return item;
      });
      return {
        ...state,
        organizationRoles,
        groupOrganizationRoles,
      };

    default:
      return state;
  }
};

export default paymentReducer;

export const getBasePermissionsFetching = (state) =>
  state.permissions.isFetchingBasePermissions;
export const getBasePermissionList = (state) =>
  state.permissions.basePermissions;
export const getGroupedPermissionList = (state) =>
  state.permissions.groupedPermissions;
export const getOrganizationRolesFetchingState = (state) =>
  state.permissions.isFetchingOrganizationRoles;
export const getOrganizationRoleList = (state) =>
  state.permissions.organizationRoles;
export const getGroupedOrganizationRoleList = (state) =>
  state.permissions.groupOrganizationRoles;
export const getSelectedRoleTab = (state) => state.permissions.selectedRole;
