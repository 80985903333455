import moment from "moment-timezone";
import { getFormattedUnit, getTimeZone } from "util/utility";

// 5(input) - 05(output)
export const addLeadingZero = (number) => {
  if (number < 10) {
    return `0${number}`;
  }

  return number;
};

export const getTimeSlots = () => {
  let hours = 0;
  let meridian = "am";

  return Array(48)
    .fill()
    .map((element, index, theArray) => {
      if (index > 23) {
        meridian = "pm";
      }

      if (index % 2 === 0) {
        let string;
        if (meridian === "pm" && hours === 12) {
          string = `${addLeadingZero(12)}:00 ${meridian}`;
        } else {
          string = `${addLeadingZero(hours % 12)}:00 ${meridian}`;
        }

        return {
          label: string,
          value: string,
        };
      }
      let string;

      if (meridian === "pm" && hours === 12) {
        string = `${addLeadingZero(12)}:30 ${meridian}`;
      } else {
        string = `${addLeadingZero(hours % 12)}:30 ${meridian}`;
      }

      hours += 1;

      return {
        label: string,
        value: string,
      };
    });
};

export const getFormattedTime = (
  time = new Date(),
  format = "DD MMM, hh:mm a",
  tz = null,
) => {
  if (time) {
    return !tz
      ? moment(time).format(format)
      : moment(time).tz(tz).format(format);
  }
  return null;
};

export const getLocalFormattedTime = (
  time = new Date(),
  format = "DD MMM, hh:mm a",
) => {
  if (time) {
    return moment(moment.utc(time).toDate()).local().format(format);
  }
  return null;
};

// Do not specify a timezone, so that the system specific timezone
export const getFormattedTimeNow = (format = "DD MMM, hh:mm a") =>
  moment().format(format);

// 18:30:00(input) -> 06:30 pm(output)
export const convert24hFormatTo12h = (time) => {
  if (time) {
    const hours = time.toString().substr(0, 2);
    const minutes = time.toString().substr(3, 2);
    const meridian = hours >= 12 ? "pm" : "am";

    return `${
      hours > 12 ? addLeadingZero(hours % 12) : hours
    }:${minutes} ${meridian}`;
  }

  return null;
};

// 10:00 am(input) - 10am(output)
// 10:30 am(input) - 10:30am(output)
export const removeMinutesIfZero = (time) => {
  if (time) {
    const [number, meridian] = time.split(" ");

    const [hrs, mins] = number.split(":");

    if (parseInt(mins) > 0) {
      return time;
    }
    return `${hrs} ${meridian}`;
  }

  return null;
};

// 10:00 am(input) - 10:00(output)
// 02:00 pm(input) - 14:00(output)
export const convert12hto24hFormat = (time12h) => {
  if (time12h) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  return null;
};

// ? converts seconds to hr, min, sec string. Gives sec if num<60 otherwise gives hr,min string
export function secondsToHms(num) {
  num = Number(num);
  const h = Math.floor(num / 3600);
  const m = Math.floor((num % 3600) / 60);
  const s = Math.floor((num % 3600) % 60);

  const hDisplay = h > 0 ? `${h}hr` : "";
  const mDisplay = m > 0 ? `${m}min` : "";
  const sDisplay = s > 0 ? `${s}sec` : "";
  if (num < 60) {
    return sDisplay;
  }
  return hDisplay + mDisplay;
  // * Examples input = 20 => output 20sec
  // * input = 296 => output 4min
  // * input = 10000 => output 2hr46min
}

/**
 * This function converts time in sec to another unit
 * @param {number} time time in sec
 * @param {('min'|'mins'|'hr'|'hrs')} unit the unit in which time is to be converted
 * @example
 * // returns 98
 * convertTimeFromSec(5900,'min');
 * @returns {number} time in converted unit
 */
export const convertTimeFromSec = (time, unit) => {
  switch (unit) {
    case "min" || "mins":
      return Math.round(time / 60);
    case "hr" || "hrs":
      return Math.round(time / 3600);
    default:
      return time;
  }
};

export const getFormattedRelativeTime = (time) => {
  const formattedTime = moment.utc(time).fromNow(true);

  const timeArray = formattedTime.split(" ");

  if (formattedTime === "a few seconds") {
    return `< 1 min`;
  }
  if (formattedTime[0] === "a") {
    return `1 ${getFormattedUnit(timeArray[timeArray.length - 1])}`;
  }

  return `${formattedTime && formattedTime.split(" ")[0]} ${
    formattedTime &&
    formattedTime.split(" ")[1] &&
    getFormattedUnit(formattedTime.split(" ")[1])
  }`;
};

/**
 * Get format of time
 * @param {number} timeInSec
 * @returns "Days" | "Hours" | "Minutes"
 */
export const getTypeOfDateTime = (timeInSec) => {
  if (timeInSec > 86400) {
    return "Days";
  }
  if (timeInSec > 3600) {
    return "Hours";
  }
  return "Minutes";
};

/**
 * Opposite of convertToSec. Converts sec to given time format
 * @param {number} timeInSec
 * @param {"Days" | "Hours" | "Minutes"} format
 * @returns number
 */
export const getTimeInFormat = (timeInSec, format) => {
  if (timeInSec || timeInSec === 0) {
    switch (format) {
      case "Days":
        return timeInSec / (60 * 60 * 24);
      case "Hours":
        return timeInSec / (60 * 60);
      case "Minutes":
        return timeInSec / 60;
      default:
        return null;
    }
  }
  return null;
};

/**
 * Convert time from days | hours | mins to secs
 * @param {number} time
 * @param {"Days" | "Hours" | "Minutes"} format
 * @returns number
 */
export const convertToSec = (time, format) => {
  if (time || time === 0) {
    switch (format) {
      case "Days":
        return time * (60 * 60 * 24);
      case "Hours":
        return time * (60 * 60);
      case "Minutes":
        return time * 60;
      default:
        return null;
    }
  }
  return null;
};

/**
 * Convert time from hours to mins
 * @param {number} time
 * @returns number
 */
export const convertHrsToMins = (hrs) => {
  if (Number.isInteger(hrs)) {
    return hrs * 60;
  }

  return 0;
};

/**
 * @typedef {Object} Time
 * @property {number} hours
 * @property {number} minutes
 */
/**
 * Convert time from hours to mins
 * @param {number} mins
 * @returns {Time}
 */
export const convertMinsToHrs = (mins) => {
  if (mins && typeof mins === "number") {
    const hours = Math.floor(mins / 60);
    const minutes = mins % 60;

    return { hours, minutes };
  }

  return { hours: 0, minutes: 0 };
};

export const isValidDate = (date) => {
  try {
    return new Date(date) && !!date;
  } catch (e) {
    return false;
  }
};

export const getMilliseconds = (date) => {
  if (isValidDate(date)) {
    return new Date(date).getTime();
  }

  return date;
};

export const getLocalelang = () => window.navigator.language;

export const getIsTimezoneOfIndia = () => {
  const timezone = getTimeZone();

  return (
    timezone?.toLowerCase() === "asia/kolkata" ||
    timezone?.toLowerCase() === "asia/calcutta"
  );
};
