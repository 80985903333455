import JSON5 from "json5";

export const isJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isJson5 = (str) => {
  try {
    JSON5.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseJSON = (str, defaultReturn = {}) => {
  if (isJson5(str)) {
    return JSON5.parse(str);
  }
  if (isJson(str)) {
    return JSON.parse(str);
  }
  return defaultReturn;
};
