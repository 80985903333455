import React from "react";
import useCurrentWhitelabeledDetails from "customHooks/useCurrentWhitelabeledDetails";
import { selectBrandName } from "useApi/Partner/useWhitelabeledDetails";

const withInitApiCalls = (WrappedComponent: React.ComponentClass<any>) => {
  return (props: Record<string, any>) => {
    const { data: brandName = "" } = useCurrentWhitelabeledDetails(
      selectBrandName,
    );

    return <WrappedComponent brandName={brandName} {...props} />;
  };
};

export default withInitApiCalls;
