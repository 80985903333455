import React from "react";

const removeLinkage = ({ fill, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_705_1354)">
        <path
          id="Vector"
          d="M12 20L16 16M18.6667 13.3333L20 12"
          stroke={fill}
          strokeWidth="2.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14.6665 7.99911L15.2838 7.28444C16.5342 6.03421 18.2301 5.33191 19.9983 5.33203C21.7665 5.33216 23.4623 6.0347 24.7125 7.28511C25.9627 8.53551 26.665 10.2314 26.6649 11.9996C26.6648 13.7678 25.9622 15.4635 24.7118 16.7138L23.9998 17.3324"
          stroke={fill}
          strokeWidth="2.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M4 4L28 28"
          stroke={fill}
          strokeWidth="2.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M17.3332 23.9993L16.8039 24.7113C15.5389 25.9623 13.8316 26.6639 12.0525 26.6639C10.2735 26.6639 8.5662 25.9623 7.3012 24.7113C6.67767 24.0948 6.18266 23.3607 5.84481 22.5515C5.50697 21.7424 5.33301 20.8742 5.33301 19.9973C5.33301 19.1205 5.50697 18.2523 5.84481 17.4432C6.18266 16.634 6.67767 15.8999 7.3012 15.2833L7.99986 14.666"
          stroke={fill}
          strokeWidth="2.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_705_1354">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default removeLinkage;
