import { ONGOING } from "constants/campaignsConstants";
import {
  DEFAULT_ORDER_STATUS_TEMPLATE_MESSAGE,
  ORDER_STATUS_TEMPLATE_NAME,
} from "modules/Integrations/components/ConfigureWhatsAppPay/constants";
import {
  BOT_FLOW_MESSAGE_LINK,
  BOT_FLOW_ORDER_DETAILS_MESSAGE,
  BOT_FLOW_ORDER_STATUS_MESSAGE,
  BOT_FLOW_QUICK_REPLY,
  BOT_FLOW_REROUTE,
  BOT_FLOW_TEMPLATE_MESSAGE,
  BOT_FLOW_TEXT_MESSAGE,
} from "./BubbleTypes";
import { TRIGGER_TYPE_STATIC_CART } from "./triggerTypes";

//! -- start -- Don't change these identifiers, they are used by Bots. If we change from here we need to change in Bots
export const confirmShippingCostAndDiscount = "B1";
const freeShippingNoDiscount = "B1_1";
const freeShippingWithDiscountThreshold = "B1_2";
const freeShippingWithDiscount = "B1_3";
const shippingThresholdWithDiscountThreshold = "B1_4";
const shippingWithDiscountThreshold = "B1_5";
const shippingThresholdWithDiscount = "B1_6";
const shippingWithDiscount = "B1_7";
const shippingThresholdWithNoDiscount = "B1_8";
const shippingWithNoDiscount = "B1_9";

const requireFullName = "B2";
export const providePincode = "B3";
const enterCity = "B4";
const provideAddress = "B5";

const paymentMethodLink = "B6";
export const preferredPaymentMethod = "B6_1";
export const moveWithOnline = "B6_2";
export const moveWithCod = "B6_3";

export const paymentLinkProvide = "B7";
const cartWaiting = "B8";
const orderPlaced = "B9";
const confirmOrder = "B10";
const orderPlacedCod = "B11";
const howCanHelp = "B12";
const needRepresentative = "B13";
const confirmLastShippingAddress = "B16";
const confirmGt1ShippingAddress = "B17";
const viewPastAddress = "B18";
const orderDetails = "B19";
const orderPlacedWAPay = "B20";
const cartWaitingWAPay = "B21";

// ! -- end --

const reRouteToPinCode = "reRouteToPinCode";

export const UPI_LINK = "upi_link";
export const STANDARD_LINK = "standard_payment_link";
export const RAZORPAY = "razorpay";
export const PAYU = "payu";
export const NATIVE = "native";
export const LINK = "link";
export const WHATSAPP_PAY = "whatsapp_pay";
export const WHATSAPP_PAY_APP_ID = "08d20f6e-266a-4fea-a296-4018f0868ac3";
export const ZOCKET_APP_ID = "ddcd8e27-9975-4588-b3a6-aba711f7b00e";
export const PERCENT = "percent";
export const FIXED = "fixed";
export const ZOCKET = "zocket";

const confirmShippingCostAndDiscountCommonDetails = {
  metadata: {
    mandatory: {
      confirmText: "Confirm Shipping Costs & Discounts",
      reviewText: "Review Shipping Costs & Discounts",
      doneWhen: {
        settings: [
          {
            name: "mandatory_steps_status.shipping_cost",
            op: "eq",
            val: true,
          },
          {
            name: "mandatory_steps_status.discount",
            op: "eq",
            supr_op: "and",
            val: true,
          },
        ],
      },
    },
    junctions: ["No"],
    mandatoryPanelIndex: 0,
    userSaysJunction: [
      {
        mainTitle: "Yes",
        payload: "Yes",
        text: "Customer Response : Yes",
      },
      {
        mainTitle: "No",
        payload: "No",
        text: "Customer Response : No",
      },
    ],
    simpleJunction: [
      {
        mainTitle: "Shipping Address doesn't exist",
        payload: "No",
        iconName: "noShipping",
        text: "Shipping Address doesn't exist",
        whenUserOpt: "Yes",
      },
      {
        mainTitle: "1 Shipping Address Exists",
        payload: "1exists",
        iconName: "shipping1",
        text: "1 Shipping Address Exists",
        whenUserOpt: "Yes",
      },
      {
        mainTitle: ">1 Shipping Address Exists",
        payload: "gt1exists",
        iconName: "shipping",
        text: ">1 Shipping Address Exists",
        whenUserOpt: "Yes",
      },
    ],
    editMessage: {
      validations: [
        {
          variable: "{total_order_value}",
          op: "in",
          errorTitle: "Editing Error:",
          errorText:
            "It is mandatory to have {total_order_value} variable in your message body.",
        },
      ],
      note: {
        title: "Note",
        text:
          "This message is for you to communicate to your customer the total order value, which will be calculated as (Cart Value + Shipping Cost - Discount).",
      },
    },
  },
  relation: [
    { to: howCanHelp, userSays: "No" },
    {
      to: requireFullName,
      userSays: "Yes",
      junction: "No",
    },
    { to: confirmLastShippingAddress, userSays: "Yes", junction: "1exists" },
    { to: confirmGt1ShippingAddress, userSays: "Yes", junction: "gt1exists" },
  ],
};

const AutoCheckoutBotflowTemplate = {
  id: "81e186a5-299f-40d5-89a6-f77652516085",
  botName: "autocheckout",
  trigger: {
    type: TRIGGER_TYPE_STATIC_CART,
  },
  root: confirmShippingCostAndDiscount,
  chatBubbles: {
    [confirmShippingCostAndDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_MESSAGE_LINK,
        message_link: freeShippingNoDiscount,
      },
      relation: [
        {
          to: freeShippingNoDiscount,
          settings: [
            {
              name: "shipping_cost.value",
              op: "eq",
              val: 0,
            },
            {
              name: "discount.value",
              op: "eq",
              val: 0,
            },
          ],
        },
        {
          to: freeShippingWithDiscountThreshold,
          settings: [
            {
              name: "shipping_cost.value",
              op: "eq",
              val: 0,
            },
            {
              name: "discount.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.min_cart_value",
              op: "gt",
              val: 0,
            },
          ],
        },
        {
          to: freeShippingWithDiscount,
          settings: [
            {
              name: "shipping_cost.value",
              op: "eq",
              val: 0,
            },
            {
              name: "discount.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.min_cart_value",
              op: "eq",
              val: 0,
            },
          ],
        },
        {
          to: shippingThresholdWithDiscountThreshold,
          settings: [
            {
              name: "shipping_cost.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.value",
              op: "gt",
              val: 0,
            },
            {
              name: "shipping_cost.max_cart_value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.min_cart_value",
              op: "gt",
              val: 0,
            },
          ],
        },
        {
          to: shippingWithDiscountThreshold,
          settings: [
            {
              name: "shipping_cost.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.value",
              op: "gt",
              val: 0,
            },
            {
              name: "shipping_cost.max_cart_value",
              op: "eq",
              val: -1,
            },
            {
              name: "discount.min_cart_value",
              op: "gt",
              val: 0,
            },
          ],
        },
        {
          to: shippingThresholdWithDiscount,
          settings: [
            {
              name: "shipping_cost.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.value",
              op: "gt",
              val: 0,
            },
            {
              name: "shipping_cost.max_cart_value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.min_cart_value",
              op: "eq",
              val: 0,
            },
          ],
        },
        {
          to: shippingWithDiscount,
          settings: [
            {
              name: "shipping_cost.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.value",
              op: "gt",
              val: 0,
            },
            {
              name: "shipping_cost.max_cart_value",
              op: "eq",
              val: -1,
            },
            {
              name: "discount.min_cart_value",
              op: "eq",
              val: 0,
            },
          ],
        },
        {
          to: shippingThresholdWithNoDiscount,
          settings: [
            {
              name: "shipping_cost.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.value",
              op: "eq",
              val: 0,
            },
            {
              name: "shipping_cost.max_cart_value",
              op: "gt",
              val: 0,
            },
          ],
        },
        {
          to: shippingWithNoDiscount,
          settings: [
            {
              name: "shipping_cost.value",
              op: "gt",
              val: 0,
            },
            {
              name: "discount.value",
              op: "eq",
              val: 0,
            },
            {
              name: "shipping_cost.max_cart_value",
              op: "eq",
              val: -1,
            },
          ],
        },
      ],
    },
    [freeShippingNoDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver for free all over India.
Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [freeShippingWithDiscountThreshold]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver for free all over the country.
A discount of *{discount_amount}* is applied on carts above *{cart_value_discount}*. Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [freeShippingWithDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver for free all over India.
A discount of *{discount_amount}* is applied on all carts. Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [shippingThresholdWithDiscountThreshold]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver all over India.
We charge a shipping cost of *{shipping_cost}* for carts below *{cart_value_shipment}*. Moreover, a discount of *{discount_amount}* is applied on carts above *{cart_value_discount}*! Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [shippingWithDiscountThreshold]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver all over India.
We charge a shipping cost of *{shipping_cost}*. Moreover, a discount of *{discount_amount}* is applied on carts above *{cart_value_discount}*! Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [shippingThresholdWithDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver all over India. We charge a shipping cost of *{shipping_cost}* for carts below *{cart_value_shipment}*. Moreover, a discount of *{discount_amount}* is applied on all carts! Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [shippingWithDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver all over India. We charge a shipping cost of *{shipping_cost}*. Moreover, a discount of *{discount_amount}* is applied on all carts! Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [shippingThresholdWithNoDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver all over India. We charge a shipping cost of *{shipping_cost}* for carts below *{cart_value_shipment}*. Your total Order Value = *{total_order_value}*. 
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [shippingWithNoDiscount]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for your cart! We currently deliver all over India.
We charge a shipping cost of *{shipping_cost}* for all carts. Your total Order Value = *{total_order_value}*.
Would you like to proceed?`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      ...confirmShippingCostAndDiscountCommonDetails,
    },
    [howCanHelp]: {
      defaultMessage: {
        type: BOT_FLOW_TEXT_MESSAGE,
        text: `Sure, how can we help you?`,
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer how you can help them (in case they have replied 'No' in the previous step).",
          },
        },
      },
      relation: [
        { to: needRepresentative, userSays: "<Customer types the query>" },
      ],
    },
    [needRepresentative]: {
      defaultMessage: {
        type: BOT_FLOW_TEXT_MESSAGE,
        text: `Noted! One of our representatives will take a look at this and get back to you at the earliest.`,
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to tell your customer that a representative from your team will get in touch with them soon.",
          },
        },
      },
    },
    [requireFullName]: {
      defaultMessage: {
        type: BOT_FLOW_TEXT_MESSAGE,
        text: `Great! We will require some details to ship the order. Please provide your full name.`,
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer their full name which should be used for shipping purposes.",
          },
        },
      },
      relation: [{ to: providePincode, userSays: "<Customer Enters Name>" }],
    },
    [confirmLastShippingAddress]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Great! We will now confirm your shipping details.

Since you have shopped from us before, we have the following address stored: 
{last_address_given_by_customer}

*Should we go ahead with the above address?*`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          { title: "No", payload: "No" },
        ],
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer if the order should be shipped to the same shipping address that is stored with you from an earlier order placed by the customer.",
          },
          validations: [
            {
              variable: "{last_address_given_by_customer}",
              op: "in",
              errorTitle: "Editing Error:",
              errorText:
                "It is mandatory to have {last_address_given_by_customer} variable in your message body.",
            },
          ],
        },
        userSaysJunction: [
          {
            mainTitle: "Yes",
            payload: "Yes",
            text: "Customer Response : Yes",
          },
          {
            mainTitle: "No",
            payload: "No",
            text: "Customer Response : No",
          },
        ],
      },
      relation: [
        { to: paymentMethodLink, userSays: "Yes" },
        { to: howCanHelp, userSays: "No" },
      ],
    },
    [confirmGt1ShippingAddress]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Great! We will now confirm your shipping details.

Since you have shopped from us before, we have your past addresses stored with us: 

This is the last address given by you: *{last_address_given_by_customer}*`,
        quick_replies: [
          {
            title: "Select this address",
            payload: "Select this address",
          },
          { title: "View past addresses", payload: "View past addresses" },
        ],
      },
      metadata: {
        editMessage: {
          validations: [
            {
              variable: "{last_address_given_by_customer}",
              op: "in",
              errorTitle: "Editing Error:",
              errorText:
                "It is mandatory to have {last_address_given_by_customer} variable in your message body.",
            },
          ],
          note: {
            title: "Note",
            text:
              "This message is for you to show the customer the shipping address that is stored with you for the last order placed by the customer, and to also ask him/her whether he/she would instead like to see the other shipping addresses of past orders.",
          },
        },
        userSaysJunction: [
          {
            mainTitle: "Select this address",
            payload: "Select this address",
            iconName: "shipping",
            text: "Customer Response : Select this address",
          },
          {
            mainTitle: "View past addresses",
            payload: "View past addresses",
            iconName: "shipping",
            text: "Customer Response : View past addresses",
          },
        ],
      },
      relation: [
        { to: paymentMethodLink, userSays: "Select this address" },
        { to: viewPastAddress, userSays: "View past addresses" },
      ],
    },
    [viewPastAddress]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `*{past_address_given_by_customer}*`,
        quick_replies: [
          {
            title: "Select this address",
            payload: "Enter new address",
          },
          { title: "Enter new addresss", payload: "Enter new addresss" },
        ],
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to show the customer the other shipping address that is stored with you, for a past order.",
          },
          validations: [
            {
              variable: "{past_address_given_by_customer}",
              op: "in",
              errorTitle: "Editing Error:",
              errorText:
                "It is mandatory to have {past_address_given_by_customer} variable in your message body.",
            },
          ],
        },
        userSaysJunction: [
          {
            mainTitle: "Select this address",
            payload: "Select this address",
            iconName: "shipping",
            text: "Customer Response : Select this address",
          },
          {
            mainTitle: "Enter new addresss",
            payload: "Enter new addresss",
            iconName: "shipping",
            text: "Customer Response : Enter new addresss",
          },
        ],
      },
      relation: [
        { to: paymentMethodLink, userSays: "Select this address" },
        { to: providePincode, userSays: "Enter new addresss" },
      ],
    },
    [providePincode]: {
      defaultMessage: {
        type: BOT_FLOW_TEXT_MESSAGE,
        text: `Please provide the *Pincode/ Postcode/ Zipcode* of the delivery location.`,
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer their postal code, which should be used for shipping purposes.",
          },
        },
        junctions: ["Yes", "No"],
        simpleJunction: [
          {
            mainTitle: "City auto-detected from Pincode",
            payload: "Yes",
            iconName: "city",
            text: "City auto-detected from Pincode",
          },
          {
            mainTitle: "City not auto-detected from Pincode",
            payload: "No",
            iconName: "noCity",
            text: "City not auto-detected from Pincode",
          },
        ],
      },
      relation: [
        {
          to: provideAddress,
          junction: "Yes",
          userSays: "<Customer Enters Pincode>",
        },
        {
          to: enterCity,
          junction: "No",
          userSays: "<Customer Enters Pincode>",
        },
      ],
    },
    [provideAddress]: {
      defaultMessage: {
        type: BOT_FLOW_TEXT_MESSAGE,
        text: `Please enter your street address, building name/ number, flat number, floor etc.
*For example: 123, MG Road, Active Acres Apartments, Flat no. 123, 1st floor*`,
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer their exact address, which should be used for shipping purposes.",
          },
        },
      },
      relation: [
        {
          to: paymentMethodLink,
        },
      ],
    },
    [paymentMethodLink]: {
      defaultMessage: {
        type: BOT_FLOW_MESSAGE_LINK,
        message_link: preferredPaymentMethod,
      },
      relation: [
        {
          to: preferredPaymentMethod,
          settings: [
            {
              name: "preferred_payment_methods",
              op: "eq",
              val: ["Online", "COD"],
            },
          ],
        },
        {
          to: moveWithOnline,
          settings: [
            {
              name: "preferred_payment_methods",
              op: "eq",
              val: ["Online"],
            },
          ],
        },
        {
          to: moveWithCod,
          settings: [
            {
              name: "preferred_payment_methods",
              op: "eq",
              val: ["COD"],
            },
          ],
        },
      ],
    },
    [preferredPaymentMethod]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for providing the details!
We have noted your address as: {address}
  
*What is your preferred payment method?*`,
        quick_replies: [
          {
            title: "Online",
            payload: "Online",
          },
          { title: "COD", payload: "COD" },
          {
            title: "Change Shipping Address",
            payload: "Change Shipping Address",
          },
        ],
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer their preferred payment method and also to give them the option to change the address they provided earlier in the chat.",
          },
        },
        mandatory: {
          confirmText: "Confirm Payment Settings",
          reviewText: "Review Payment Settings",
          doneWhen: {
            settings: [
              {
                name: "mandatory_steps_status.payment_options",
                op: "eq",
                val: true,
              },
            ],
          },
        },
        mandatoryPanelIndex: 2,
        userSaysJunction: [
          {
            mainTitle: "Online",
            payload: "Online",
            iconName: "online",
            text: "Customer Response : Online",
          },
          {
            mainTitle: "COD",
            payload: "COD",
            iconName: "cash",
            text: "Customer Response : CoD",
          },
          {
            mainTitle: "Change Shipping Address",
            payload: "Change Shipping Address",
            iconName: "shipping",
            text: "Customer Response : Change Shipping Address",
          },
        ],
      },
      relation: [
        {
          to: orderDetails,
          userSays: "Online",
          settings: [
            {
              name: "payment_gateway_type",
              op: "eq",
              val: NATIVE,
            },
          ],
        },
        {
          to: paymentLinkProvide,
          userSays: "Online",
          settings: [
            {
              name: "payment_gateway_type",
              op: "eq",
              val: LINK,
            },
          ],
        },
        { to: confirmOrder, userSays: "COD" },
        {
          to: reRouteToPinCode,
          userSays: "Change Shipping Address",
        },
      ],
    },
    [orderDetails]: {
      defaultMessage: {
        type: BOT_FLOW_ORDER_DETAILS_MESSAGE,
        body: {
          text: "Click on Review and Pay to pay for this order.",
        },
        order: {
          number: "123456789",
          currency: "INR",
          total: 3999,
          items: [
            {
              name: "Product Name",
              image: "/images/shoe-image.png",
              quantity: 1,
              amount: 3999,
            },
          ],
        },
        action: {
          buttons: [
            {
              type: "order_details",
              text: "Review And Pay",
            },
          ],
        },
      },
      metadata: {
        junctions: ["Yes", "No"],
        simpleJunction: [
          {
            mainTitle: "Customer makes the payment",
            payload: "Yes",
            text: "Customer makes the payment",
          },
          {
            mainTitle: "Customer doesn't pay for 30 mins",
            payload: "No",
            text: "Customer doesn't pay for 30 mins",
          },
        ],
      },
      relation: [
        { to: orderPlacedWAPay, junction: "Yes" },
        { to: cartWaitingWAPay, junction: "No" },
      ],
    },
    [paymentLinkProvide]: {
      defaultMessage: {
        type: BOT_FLOW_TEMPLATE_MESSAGE,
        campaign: {
          name: "interakt__autocheckout__payment_link_generated",
          campaign_type: ONGOING,
          template_id: null,
          reply_rules: [],
          merge_variables_header: "{}",
          merge_variables_body:
            '{"1":{"var_name":"name","default_val":"there"}}',
          merge_variables_footer: `{"1":{"var_name":"Payment Link Generated.payment_link","default_val":"NA"}}`,
          filter_clause: [
            {
              trigger: true,
              filters: [],
              name: "Payment Link Generated",
            },
          ],
          send_at_utc: null,
          send_until_utc: null,
          campaign_one_time_type: "Immediate",
        },
      },
      metadata: {
        default_template_body: {
          header: "Here is your payment link!",
          header_format: "TEXT",
          footer: null,
          body: `Hey {{1}}

We look forward to your order.`,
          body_text: ["John"],
          category: "ALERT_UPDATE",
          display_name: "interakt_autocheckout_payment_link_generated",
          language: "en",
          buttons: `[{"type":"URL","text":"Click to Pay","url":"https://api.interakt.ai/cta?redirect={{1}}"}]`,
          button_text: [
            "https://api.interakt.ai/cta?redirect=https://interakt.shop",
          ],
          allow_delete: false,
        },
        junctions: ["Yes", "No"],
        simpleJunction: [
          {
            mainTitle: "Customer makes the payment",
            payload: "Yes",
            text: "Customer makes the payment",
          },
          {
            mainTitle: "Customer doesn't pay for 30 mins",
            payload: "No",
            text: "Customer doesn't pay for 30 mins",
          },
        ],
        inlineModal: {
          subheading:
            "Template should have a CTA button having the dynamic URL:  https://api.interakt.ai/cta?redirect={{1}}.",
          variant: "primaryDark",
        },
        templateModalDetails: {
          title: "Select Template (with CTA button having dynamic URL)",
          filtering: [
            {
              name: "buttons",
              value: {
                type: "URL",
                url: "https://api.interakt.ai/cta?redirect={{1}}",
              },
            },
          ],
        },
      },
      relation: [
        { to: orderPlaced, junction: "Yes" },
        { to: cartWaiting, junction: "No" },
      ],
    },
    [orderPlaced]: {
      defaultMessage: {
        type: BOT_FLOW_TEMPLATE_MESSAGE,
        campaign: {
          name: "interakt__autocheckout__order_placed",
          campaign_type: ONGOING,
          reply_rules: [],
          template_id: null,
          merge_variables_header: "{}",
          merge_variables_body:
            '{"1":{"var_name":"name","default_val":"there"}}',
          merge_variables_footer: "{}",
          filter_clause: [
            {
              trigger: true,
              filters: [
                {
                  trait: "payment_mode",
                  op: "ne",
                  val: "cod",
                },
                {
                  supr_op: "and",
                  trait: "payment_mode",
                  op: "ne",
                  val: "wa_pay",
                },
              ],
              name: "Whatsapp Order Placed",
            },
          ],
          send_at_utc: null,
          send_until_utc: null,
          campaign_one_time_type: "Immediate",
        },
      },
      metadata: {
        default_template_body: {
          header: "Your Order is Placed!",
          header_format: "TEXT",
          body: `Hey {{1}}

We have received payment for the cart sent by you and your order is now confirmed.
          
We are getting your order ready and will send you further updates.`,
          body_text: ["John"],
          category: "ALERT_UPDATE",
          display_name: "interakt_autocheckout_order_placed",
          language: "en",

          allow_delete: false,
          footer: null,
        },
        inlineModal: {
          subheading:
            "This template is for you to communicate to your customer that the payment has been received and hence, the order is confirmed.",
          variant: "primaryDark",
        },
        templateModalDetails: {
          title: "Select Template",
        },
      },
    },
    [orderPlacedWAPay]: {
      defaultMessage: {
        type: BOT_FLOW_ORDER_STATUS_MESSAGE,
        template_name: ORDER_STATUS_TEMPLATE_NAME,
        order: {
          number: "123456789",
          items: [
            {
              name: "Product Name",
              image: "/images/shoe-image.png",
            },
          ],
        },
      },
      metadata: {
        default_template_body: DEFAULT_ORDER_STATUS_TEMPLATE_MESSAGE,
      },
    },
    [orderPlacedCod]: {
      defaultMessage: {
        type: BOT_FLOW_TEMPLATE_MESSAGE,
        campaign: {
          name: "interakt__autocheckout__order_placed_cod",
          campaign_type: ONGOING,
          reply_rules: [],
          template_id: null,
          merge_variables_header: "{}",
          merge_variables_body:
            '{"1":{"var_name":"name","default_val":"there"}}',
          merge_variables_footer: "{}",
          filter_clause: [
            {
              trigger: true,
              filters: [
                {
                  trait: "payment_mode",
                  op: "eq",
                  val: "cod",
                },
              ],
              name: "Whatsapp Order Placed",
            },
          ],
          send_at_utc: null,
          send_until_utc: null,
          campaign_one_time_type: "Immediate",
        },
      },
      metadata: {
        default_template_body: {
          header: "Your Order is Placed!",
          header_format: "TEXT",
          body: `Hey {{1}}

Thanks for confirming.
We are getting your order ready and will send you further updates soon.`,
          body_text: ["John"],
          category: "ALERT_UPDATE",
          display_name: "interakt_autocheckout_order_placed_cod",
          language: "en",
          allow_delete: false,
          footer: null,
        },
        inlineModal: {
          subheading:
            "This template is for you to communicate to your customer that the CoD order has been confirmed.",
          variant: "primaryDark",
        },
        templateModalDetails: {
          title: "Select Template",
        },
      },
    },
    [cartWaiting]: {
      defaultMessage: {
        type: BOT_FLOW_TEMPLATE_MESSAGE,
        campaign: {
          name: "interakt__autocheckout__abandoned_checkout",
          campaign_type: ONGOING,
          reply_rules: [],
          template_id: null,
          merge_variables_header: "{}",
          merge_variables_body:
            '{"1":{"var_name":"name","default_val":"there"}}',
          merge_variables_footer: `{"1":{"var_name":"Payment Link Generated.payment_link","default_val":"NA"}}`,
          filter_clause: [
            {
              trigger: true,
              filters: [
                {
                  op: "delta",
                  supr_op: "and",
                  trait: "created_at_utc",
                  val: 1800,
                },
              ],
              name: "Payment Link Generated",
            },
            {
              name: "Whatsapp Order Placed",
              negate: true,
              filters: [
                {
                  trait: "created_at_utc",
                  op: "within",
                  val: 1800,
                },
              ],
              type: "trigger1-event",
            },
          ],
          send_at_utc: null,
          send_until_utc: null,
          campaign_one_time_type: "Immediate",
        },
      },
      metadata: {
        default_template_body: {
          // Template Obj,
          header: "Your cart is waiting!",
          header_format: "TEXT",
          body: `Hey {{1}},
Let us know if you need any assistance in placing the order.`,
          body_text: ["John"],
          category: "ALERT_UPDATE",
          display_name: "interakt_autocheckout_abandoned_checkout",
          language: "en",
          buttons: `[{"type":"URL","text":"Click to Pay","url":"https://api.interakt.ai/cta?redirect={{1}}"}]`,
          button_text: [
            "https://api.interakt.ai/cta?redirect=https://interakt.shop",
          ],
          footer: null,
          allow_delete: false,
        },
        inlineModal: {
          subheading:
            "This template is for you to remind your customer to make the payment for the cart they sent.",
          variant: "primaryDark",
        },
        templateModalDetails: {
          title: "Select Template",
        },
      },
    },
    [cartWaitingWAPay]: {
      defaultMessage: {
        type: BOT_FLOW_TEMPLATE_MESSAGE,
        campaign: {
          name: "interakt__autocheckout__abandoned_checkout_wapay",
          campaign_type: ONGOING,
          reply_rules: [],
          template_id: null,
          merge_variables_header: "{}",
          merge_variables_body:
            '{"1":{"var_name":"name","default_val":"there"}}',
          merge_variables_footer: `{}`,
          filter_clause: [
            {
              trigger: true,
              filters: [
                {
                  op: "delta",
                  supr_op: "and",
                  trait: "created_at_utc",
                  val: 1800,
                },
              ],
              name: "wa pay message delivered",
            },
            {
              name: "Whatsapp Order Placed",
              negate: true,
              filters: [
                {
                  trait: "created_at_utc",
                  op: "within",
                  val: 1800,
                },
              ],
              type: "trigger1-event",
            },
          ],
          send_at_utc: null,
          send_until_utc: null,
          campaign_one_time_type: "Immediate",
        },
      },
      metadata: {
        default_template_body: {
          header: "Your cart is waiting!",
          header_format: "TEXT",
          body: `Hey {{1}},
Please click on *Review and Pay* in the previous message to complete your order. Let us know if you need any assistance in placing the order.`,
          body_text: ["John"],
          category: "MARKETING",
          display_name: "interakt_autocheckout_abandoned_checkout_wa_pay",
          language: "en",
          footer: null,
          allow_delete: false,
        },
        inlineModal: {
          subheading:
            "This template is for you to remind your customer to make the payment for the cart they sent.",
          variant: "primaryDark",
        },
        templateModalDetails: {
          title: "Select Template",
        },
      },
    },
    [confirmOrder]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Sure, we have recorded your preference for a cod order. Please click below to confirm the order`,
        quick_replies: [
          {
            title: "Yes, Confirm",
            payload: "Yes, Confirm",
          },
        ],
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to confirm with the customer that he/she wants to go ahead with CoD payment.",
          },
        },
      },
      relation: [{ to: orderPlacedCod }],
    },
    [moveWithOnline]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for providing the details!
We have noted your address as: {address}
*Would you like to move ahead with online payment for the order?*`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          {
            title: "No",
            payload: "No",
          },
          {
            title: "Change Shipping Address",
            payload: "Change Shipping Address",
          },
        ],
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to confirm with the customer whether they want to go ahead with online payment or not. You can also give here an option to the customer to change shipping address.",
          },
        },
        mandatory: {
          confirmText: "Confirm Payment Settings",
          reviewText: "Review Payment Settings",
          doneWhen: {
            settings: [
              {
                name: "mandatory_steps_status.payment_options",
                op: "eq",
                val: true,
              },
            ],
          },
        },
        mandatoryPanelIndex: 2,
        userSaysJunction: [
          {
            mainTitle: "Yes",
            payload: "Yes",
            text: "Customer Response : Yes",
          },
          {
            mainTitle: "No",
            payload: "No",
            text: "Customer Response : No",
          },
          {
            mainTitle: "Change Shipping Address",
            payload: "Change Shipping Address",
            iconName: "shipping",
            text: "Customer Response : Change Shipping Address",
          },
        ],
      },
      relation: [
        {
          to: orderDetails,
          userSays: "Yes",
          settings: [
            {
              name: "payment_gateway_type",
              op: "eq",
              val: NATIVE,
            },
          ],
        },
        {
          to: paymentLinkProvide,
          userSays: "Yes",
          settings: [
            {
              name: "payment_gateway_type",
              op: "eq",
              val: LINK,
            },
          ],
        },
        { to: howCanHelp, userSays: "No" },
        { to: reRouteToPinCode, userSays: "Change Shipping Address" },
      ],
    },
    [moveWithCod]: {
      defaultMessage: {
        type: BOT_FLOW_QUICK_REPLY,
        text: `Thanks for providing the details!
We have noted your address as: {address}
*We only offer Cash on Delivery. Would you like to confirm the order?*`,
        quick_replies: [
          {
            title: "Yes",
            payload: "Yes",
          },
          {
            title: "No",
            payload: "No",
          },
          {
            title: "Change Shipping Address",
            payload: "Change Shipping Address",
          },
        ],
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to confirm with the customer whether they want to go ahead with CoD payment or not. You can also give here an option to the customer to change shipping address.",
          },
        },
        mandatory: {
          confirmText: "Confirm Payment Settings",
          reviewText: "Review Payment Settings",
          doneWhen: {
            settings: [
              {
                name: "mandatory_steps_status.payment_options",
                op: "eq",
                val: true,
              },
            ],
          },
        },
        mandatoryPanelIndex: 2,
        userSaysJunction: [
          {
            mainTitle: "Yes",
            payload: "Yes",
            text: "Customer Response : Yes",
          },
          {
            mainTitle: "No",
            payload: "No",
            text: "Customer Response : No",
          },
          {
            mainTitle: "Change Shipping Address",
            payload: "Change Shipping Address",
            iconName: "shipping",
            text: "Customer Response : Change Shipping Address",
          },
        ],
      },
      relation: [
        { to: orderPlacedCod, userSays: "Yes" },
        { to: howCanHelp, userSays: "No" },
        { to: reRouteToPinCode, userSays: "Change Shipping Address" },
      ],
    },
    [enterCity]: {
      defaultMessage: {
        type: BOT_FLOW_TEXT_MESSAGE,
        text: `Now, please enter your city name below
*For example: Kolkata*`,
      },
      metadata: {
        editMessage: {
          note: {
            title: "Note",
            text:
              "This message is for you to ask your customer their city name, which should be used for shipping purposes.",
          },
        },
      },
      relation: [{ to: provideAddress }],
    },
    [reRouteToPinCode]: {
      defaultMessage: {
        type: BOT_FLOW_REROUTE,
        text: `Rerouted to pin code collection step`,
      },
      metadata: {
        rerouteId: providePincode,
      },
    },
  },
  settings: {
    shipping_cost: {
      // Will be 0 if free shipping
      value: 0,
      // Will be -1 if shipping applied for all carts
      max_cart_value: -1,
    },
    discount: {
      // type => "fixed" | "percent"
      type: FIXED,
      // Will be 0 if No Discount
      value: 0,
      // Will be 0 if applied for all carts
      min_cart_value: 0,
    },
    mandatory_steps_status: {
      shipping_cost: false,
      discount: false,
      payment_options: false,
    },
    preferred_payment_methods: ["COD", "Online"],
    online_payment_link_type: UPI_LINK,
    online_payment_mode: RAZORPAY,
    online_payment_enabled: true,
    payment_gateway_type: NATIVE,
    currency: "INR",
    installed_apps: [],
  },
  defaultFlow: {
    [freeShippingNoDiscount]: {
      userSays: "Yes",
      junction: "No",
    },
    [freeShippingWithDiscountThreshold]: {
      userSays: "Yes",
      junction: "No",
    },
    [freeShippingWithDiscount]: {
      userSays: "Yes",
      junction: "No",
    },
    [shippingThresholdWithDiscountThreshold]: {
      userSays: "Yes",
      junction: "No",
    },
    [shippingWithDiscountThreshold]: {
      userSays: "Yes",
      junction: "No",
    },
    [shippingThresholdWithDiscount]: {
      userSays: "Yes",
      junction: "No",
    },
    [shippingThresholdWithNoDiscount]: {
      userSays: "Yes",
      junction: "No",
    },
    [shippingWithDiscount]: {
      userSays: "Yes",
      junction: "No",
    },
    [shippingWithNoDiscount]: {
      userSays: "Yes",
      junction: "No",
    },
    [howCanHelp]: { userSays: "<Customer types the query>" },
    [requireFullName]: { userSays: "<Customer Enters Name>" },
    [confirmLastShippingAddress]: { userSays: "Yes" },
    [confirmGt1ShippingAddress]: { userSays: "Select this address" },
    [viewPastAddress]: { userSays: "Select this address" },
    [providePincode]: {
      userSays: "<Customer Enters Pincode>",
      junction: "Yes",
    },
    [provideAddress]: {
      userSays:
        "<Customer enters street address, building name/number, flat number, floor etc.>",
    },
    [enterCity]: {
      userSays: "<Customer Enters City Name>",
    },
    [preferredPaymentMethod]: {
      userSays: "Online",
    },
    [paymentLinkProvide]: {
      junction: "Yes",
    },
    [orderDetails]: {
      junction: "Yes",
    },
    [confirmOrder]: {
      userSays: "Yes, Confirm",
    },
    [moveWithOnline]: {
      userSays: "Yes",
    },
    [moveWithCod]: {
      userSays: "Yes",
    },
  },
  mandatoryStepsPanel: {
    steps: ["shipping_cost", "discount", "payment_options"],
  },
  introPage: {
    images: {
      desktop: "/images/overview-desktop.png",
      mobile: "/images/overview-mobile.png",
      overviewGIF: "/images/overview.gif",
    },
  },
  blocker: {
    catalogNotConnected: {
      toast: {
        type: "warning",
        text:
          "To configure the workflow, you need to connect a catalog to your Interakt account.",
      },
      ribbon: {
        text:
          "To use the workflow, Catalog should be connected to Interakt. Connect from ",
        linkProps: {
          href: "/commerce-settings",
          target: "_blank",
          text: "here",
        },
        ribbonProps: { bgColor: "#F7C8A5", color: "darGrey.80" },
        iconProps: {
          iconName: "shop",
          iconColor: "darGrey.80",
          iconSize: "xs",
        },
      },
    },
  },
  analytics: {
    botLive: {
      ribbon: {
        text: `See details of your carts & orders in the <a href="/commerce/order-panel" target="_blank" style="color:#454ADE;text-decoration:underline;font-weight:500;">Order Panel</a> or in `,
        linkProps: {
          text: "Campaigns",
          color: "link",
          fontWeight: 500,
        },
        ribbonProps: { bgColor: "#F7E5A5", color: "darGrey.80" },
        iconProps: {
          iconName: "trending",
          iconColor: "darGrey.80",
          iconSize: "xs",
        },
      },
    },
  },
};

export default AutoCheckoutBotflowTemplate;
