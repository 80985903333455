import { useSelector } from "react-redux";
import { getIsSandboxMode } from "modules/App/AppSelectors";

const useIsSandboxMode = () => {
  const isSandboxModeApplicable = useSelector(getIsSandboxMode);

  return isSandboxModeApplicable;
};

export default useIsSandboxMode;
