import { v4 as uuidv4 } from "uuid";

import { parseJSON } from "util/jsonUtility";
import {
  QUICK_REPLY,
  URL,
} from "modules/Templates/pages/components/ButtonsSection/constant";
import { callUploadApi } from "util/publicApiCaller";
import {
  getUrlParams,
  showNotification,
  validationForMediaFile,
} from "util/utility";
import {
  getFileType,
  getFormattedTextForWhatsapp,
  getSanitizeDataV2,
} from "util/chatUtil";
import snakeCase from "lodash/snakeCase";
import HEADER_TYPES from "constants/headerTypes";
import { CHANNEL_TYPE_RCS } from "components/ChannelTypeDropdown/constants";
import {
  OPTION_LIMITED_TIME_OFFER,
  OPTION_SEND_PRODUCTS,
  TYPE_CATALOG,
  TYPE_MPM,
  DYNAMIC_URL,
  STATIC_URL,
  OPTION_CARAOUSAL,
} from "../constants";

const ORDER_DETAILS = "ORDER_DETAILS";

enum TypesCarouselSendTemplate {
  Header = "HEADER",
  Image = "IMAGE",
  Video = "VIDEO",
  Body = "BODY",
  Button = "BUTTON",
  Text = "text",
  QuickReply = "QUICK_REPLY",
  Url = "URL",
  Payload = "PAYLOAD",
  Carousel = "CAROUSEL",
}

interface MockTemplateInterface {
  buttons?: string;
}

export const getTemplateDisabledStatusByButtonType = (
  templates: MockTemplateInterface[],
): boolean => {
  if (Array.isArray(templates)) {
    return templates
      .map((template: MockTemplateInterface) => {
        const templateButtonsList = parseJSON(template.buttons);
        return (
          Array.isArray(templateButtonsList) &&
          templateButtonsList.some((button) => button.type === ORDER_DETAILS)
        );
      })
      .some((item) => !!item);
  }
  return false;
};

type HeaderFormatType =
  | TypesCarouselSendTemplate.Image
  | TypesCarouselSendTemplate.Video
  | null;

interface QuickyReplyButtonInterface {
  id: string;
  type: typeof QUICK_REPLY;
  buttonText: string;
}

interface WebsiteUrlButtonInterface {
  id: string;
  type: typeof URL;
  url: string;
  buttonText: string;
  urlType: typeof STATIC_URL | typeof DYNAMIC_URL;
}

export type CarouselButtonType =
  | QuickyReplyButtonInterface
  | WebsiteUrlButtonInterface;

export interface CarouselInterface {
  id: string;
  buttons: Array<CarouselButtonType>;
  cardTitle: string;
  text?: string;
  image: string;
  headerHandle: string;
  textVariableSample: Array<string>;
  buttonsVariableSample: Array<string>;
}

interface UpdateCarouselParamsInterface {
  templateCarousels: Array<Array<CarouselInterface>>;
  activeTemplateIndex: number;
  activeCarouselIndex: number;
  manipulateButtonsFn: Function;
}

export function getUpdatedCarouselButtons({
  templateCarousels,
  activeTemplateIndex,
  activeCarouselIndex,
  manipulateButtonsFn,
}: UpdateCarouselParamsInterface): Array<Array<CarouselInterface>> {
  return templateCarousels.map((item, index) => {
    if (index === activeTemplateIndex) {
      return item.map((carousel, carouselIndex) => {
        if (activeCarouselIndex === carouselIndex) {
          return {
            ...carousel,
            buttons: manipulateButtonsFn(carousel.buttons),
          };
        }

        return carousel;
      });
    }

    return item;
  });
}

export function carouselDeleteButton(
  buttons: Array<CarouselButtonType>,
  buttonId: string,
) {
  return buttons.filter((button) => button.id !== buttonId);
}

export function carouselUpdateButton(
  buttons: Array<CarouselButtonType>,
  buttonId: string,
  updatedButtonData: CarouselButtonType,
) {
  return buttons.map((button) => {
    if (button.id === buttonId) {
      return {
        ...button,
        ...updatedButtonData,
      };
    }
    return button;
  });
}

interface CarouselQuickReplyButtonsAPIInterface {
  type: typeof QUICK_REPLY;
  text: string;
}

interface CarouselWebsiteUrlButtonsAPIBase {
  type: typeof URL;
  url: string;
  text: string;
}

interface CarouselWebsiteUrlButtonsAPIInterface
  extends CarouselWebsiteUrlButtonsAPIBase {
  urlType: typeof STATIC_URL | typeof DYNAMIC_URL;
}

export const templateHasButtonType = (
  buttons: Array<
    | CarouselQuickReplyButtonsAPIInterface
    | CarouselWebsiteUrlButtonsAPIInterface
  >,
  buttonType: string,
): boolean => {
  return buttons.some((btn) => btn.type === buttonType);
};

export type CarouselButtonsAPIType =
  | CarouselQuickReplyButtonsAPIInterface
  | CarouselWebsiteUrlButtonsAPIInterface;

export interface CarouselCardAPIParamsInterface {
  card_index: number;
  cardTitle: string;
  cardDescription?: string;
  cardMedia?: any;
  suggestions?: any;
  header_format: HeaderFormatType;
  header: null;
  header_handle: [string];
  header_handle_file_url: string;
  body: string;
  body_text: Array<string>;
  buttons: Array<CarouselButtonsAPIType>;
  button_text: Array<string>;
}

function getCarouselButtonsAPIParams(
  buttons: Array<CarouselButtonType> | null,
): Array<CarouselButtonsAPIType> {
  if (!buttons) {
    return [];
  }

  return buttons.map((button) => {
    if (button.type === URL) {
      return {
        type: URL,
        text: button.buttonText,
        url: button.url,
        urlType: button.urlType,
      };
    }

    return {
      type: QUICK_REPLY,
      text: button.buttonText,
    };
  });
}

function getApiParamsHeaderFormat(
  mediaurl: string | undefined,
): HeaderFormatType {
  if (mediaurl) {
    if (getFileType(mediaurl) === "video") {
      return TypesCarouselSendTemplate.Video;
    }

    return TypesCarouselSendTemplate.Image;
  }

  return null;
}

export function getCarouselCardAPIParams(
  carouselCards: Array<CarouselInterface>,
): Array<CarouselCardAPIParamsInterface> {
  return carouselCards.map((card, index) => {
    return {
      card_index: index,
      header_format: getApiParamsHeaderFormat(card.image),
      header: null,
      header_handle: [card.headerHandle],
      header_handle_file_url: card.image,
      cardTitle: card.cardTitle
        ? getSanitizeDataV2(getFormattedTextForWhatsapp(card.cardTitle))
        : "",
      body: card.text
        ? getSanitizeDataV2(getFormattedTextForWhatsapp(card.text))
        : "",
      body_text: card.textVariableSample,
      buttons: getCarouselButtonsAPIParams(card.buttons),
      button_text: card.buttonsVariableSample || [],
    };
  });
}

export function getCarouselButtonsFromAPIParams(
  buttons: Array<CarouselButtonsAPIType>,
): Array<CarouselButtonType> {
  if (!buttons) {
    return [];
  }

  return buttons.map((button) => {
    if (button.type === URL) {
      return {
        id: uuidv4(),
        type: URL,
        buttonText: button.text,
        url: button.url,
        urlType: button.url.includes("{{1}}") ? DYNAMIC_URL : STATIC_URL,
      };
    }

    return {
      id: uuidv4(),
      type: QUICK_REPLY,
      buttonText: button.text,
    };
  });
}

export function getCarouselButtonsForRCSFromAPIParams(
  suggestions: Array<any>,
): Array<CarouselButtonType> {
  return suggestions.map((button) => {
    if (button.type === URL) {
      return {
        id: uuidv4(),
        type: URL,
        buttonText: button.action.plainText,
        url: button.action.openUrl.url,
        urlType: button.action.openUrl.url.includes("{{1}}")
          ? DYNAMIC_URL
          : STATIC_URL,
      };
    }

    return {
      id: uuidv4(),
      type: QUICK_REPLY,
      buttonText: button.reply.plainText,
    };
  });
}

export function getCarouselDataFromAPIParams(
  carouselCards: Array<CarouselCardAPIParamsInterface>,
): Array<CarouselInterface> {
  const isRCSChannel = getUrlParams("channel_type") === CHANNEL_TYPE_RCS;
  return carouselCards.map((card) => {
    return {
      id: uuidv4(),
      buttons: isRCSChannel
        ? getCarouselButtonsForRCSFromAPIParams(card.suggestions)
        : getCarouselButtonsFromAPIParams(card.buttons),
      text: isRCSChannel ? card.cardDescription : card.body,
      cardTitle: isRCSChannel ? card.cardTitle : "",
      image: isRCSChannel
        ? card?.cardMedia?.contentInfo?.fileUrl
        : card.header_handle_file_url,
      headerHandle: isRCSChannel ? "" : card.header_handle?.[0],
      textVariableSample: card.body_text,
      buttonsVariableSample: card.button_text,
    };
  });
}

export function getVariableListFromString(
  text: string | undefined,
): Array<string> {
  if (typeof text !== "string") {
    return [];
  }

  const variableMatch = /\{\{\d+\}\}/g;

  return text.match(variableMatch) || [];
}

export interface FallbackInterface {
  index: number;
  name: string;
  variable_value: string;
  fallback_values: string;
}

export function getFallbackList(
  fallbackVariables: Array<string> | undefined,
): Array<FallbackInterface> {
  if (!fallbackVariables) {
    return [];
  }

  return fallbackVariables.map((variable, index) => {
    return {
      index: index + 1,
      name: variable,
      variable_value: "",
      fallback_values: "",
    };
  });
}

interface CarouselFallBackVariableInterface {
  header: string;
  bodyVariables: Array<string>;
  buttonsVariables: Array<string>;
  buttonsVariableIndex: Array<number>;
}

export function getFallbackForAllCarouselCard(
  carouselCardList: Array<CarouselInterface>,
): Array<CarouselFallBackVariableInterface> {
  if (!carouselCardList) {
    return [];
  }

  return carouselCardList.map((carousel) => {
    const buttonsVariableIndex: Array<number> = [];

    const dynamicButtons =
      carousel?.buttons?.filter((button, index) => {
        const isDyamicUrl =
          button.type === URL && button.url?.includes("{{1}}");

        if (isDyamicUrl) {
          buttonsVariableIndex.push(index);
        }

        return isDyamicUrl;
      }) || [];

    return {
      header: carousel.image,
      bodyVariables: getVariableListFromString(carousel.text),
      buttonsVariables: Array(dynamicButtons?.length ?? 0).fill("{{1}}"),
      buttonsVariableIndex,
    };
  });
}

interface CarouselMessageHeaderInterfaceVideo {
  type: typeof TypesCarouselSendTemplate.Video;
  video: {
    link: string;
  };
}

interface CarouselMessageHeaderInterfaceImage {
  type: typeof TypesCarouselSendTemplate.Image;
  image: {
    link: string;
  };
}

interface CarouselMessageHeaderInterface {
  type: typeof TypesCarouselSendTemplate.Header;
  parameters: [
    CarouselMessageHeaderInterfaceVideo | CarouselMessageHeaderInterfaceImage,
  ];
}

interface CarouselMessageBodyParameters {
  type: typeof TypesCarouselSendTemplate.Text;
  text: string;
}

interface CarouselMessageBodyInterface {
  type: typeof TypesCarouselSendTemplate.Body;
  parameters?: Array<CarouselMessageBodyParameters>;
}

interface CarouselMessageButtonInterface {
  type: typeof TypesCarouselSendTemplate.Button;
  index: number;
  sub_type:
    | typeof TypesCarouselSendTemplate.QuickReply
    | typeof TypesCarouselSendTemplate.Url;
  parameters: [
    {
      type: typeof TypesCarouselSendTemplate.Payload;
      payload: string;
    },
  ];
}

function getButtonParameterPayload(
  button: CarouselButtonsAPIType,
  carouselParametersButtons: Array<string> | undefined,
): string {
  if (button.type === TypesCarouselSendTemplate.QuickReply) {
    return button.text || "";
  }

  if (button.url.includes("{{1}}") && carouselParametersButtons) {
    return carouselParametersButtons[0] ?? "";
  }

  return button.url || "";
}

export function getCarouselSendMessageDataFromCarouselButtons(
  buttons: Array<CarouselButtonsAPIType>,
  carouselParametersButtons: Array<string> | undefined,
): Array<CarouselMessageButtonInterface> {
  return buttons.map((button, index) => {
    return {
      type: TypesCarouselSendTemplate.Button,
      index,
      sub_type:
        button.type === TypesCarouselSendTemplate.Url
          ? TypesCarouselSendTemplate.Url
          : TypesCarouselSendTemplate.QuickReply,
      parameters: [
        {
          type: TypesCarouselSendTemplate.Payload,
          payload: getButtonParameterPayload(button, carouselParametersButtons),
        },
      ],
    };
  });
}

function getCarouselSendMessageBodyData(
  carouselParametersBody: Array<string> | undefined,
): CarouselMessageBodyInterface {
  if (!carouselParametersBody || carouselParametersBody.length === 0) {
    return {
      type: TypesCarouselSendTemplate.Body,
      parameters: [],
    };
  }

  return {
    type: TypesCarouselSendTemplate.Body,
    parameters: carouselParametersBody.map((item) => {
      return {
        type: TypesCarouselSendTemplate.Text,
        text: item,
      };
    }),
  };
}

interface CarouselSendMesssageInterface {
  type: typeof TypesCarouselSendTemplate.Carousel;
  cards: Array<{
    card_index: number;
    components: Array<
      | CarouselMessageHeaderInterface
      | CarouselMessageBodyInterface
      | CarouselMessageButtonInterface
    >;
  }>;
}

function getCarouselSendMessageHeader(
  headerHandle: string | undefined,
  headerFormat: HeaderFormatType,
): CarouselMessageHeaderInterface {
  if (headerFormat === TypesCarouselSendTemplate.Image) {
    return {
      type: TypesCarouselSendTemplate.Header,
      parameters: [
        {
          type: TypesCarouselSendTemplate.Image,
          image: {
            link: headerHandle ?? "",
          },
        },
      ],
    };
  }

  return {
    type: TypesCarouselSendTemplate.Header,
    parameters: [
      {
        type: TypesCarouselSendTemplate.Video,
        video: {
          link: headerHandle ?? "",
        },
      },
    ],
  };
}

interface CarouselParametersInterface {
  body: Array<string>;
  buttons: Array<string>;
  header: string;
}

export function getCarouselSendMessageDataFromCarouselCards(
  carouselCards: Array<CarouselCardAPIParamsInterface>,
  carouselParameters: Array<CarouselParametersInterface>,
): CarouselSendMesssageInterface {
  return {
    type: TypesCarouselSendTemplate.Carousel,
    cards: carouselCards.map((card, index) => {
      return {
        card_index: index,
        components: [
          getCarouselSendMessageHeader(
            carouselParameters?.[index]?.header,
            card.header_format,
          ),
          getCarouselSendMessageBodyData(carouselParameters?.[index]?.body),
          ...getCarouselSendMessageDataFromCarouselButtons(
            card.buttons,
            carouselParameters?.[index]?.buttons,
          ),
        ],
      };
    }),
  };
}

interface FileUploadInterface {
  fileToUpload: File;
  orgId: string;
  category?: string;
  partnerID?: string;
}

interface FileUploadResponseInterface {
  fileHandle?: string;
  fileUrl: string;
}

export async function handleFbFileUpload({
  fileToUpload,
  orgId,
  category = "message_template_media",
}: FileUploadInterface): Promise<FileUploadResponseInterface | null> {
  const formData = new FormData();
  formData.append("uploadFile", fileToUpload);
  if (validationForMediaFile(fileToUpload)) {
    return null;
  }
  if (orgId) {
    try {
      const res = await callUploadApi(
        `v1/organizations/${orgId}/file/upload_to_fb/?fileCategory=${category}`,
        "post",
        formData,
      );

      if (res?.result) {
        if (res?.data?.file_handle && res?.data?.file_url) {
          return {
            fileHandle: res.data.file_handle,
            fileUrl: res.data.file_url,
          };
        }
      }

      throw new Error(res.message);
    } catch (error: Error | any) {
      showNotification("error", error.message);
    }
  }

  showNotification("error", "Failed to upload file");
  return null;
}

export async function handleRCSFileUpload({
  fileToUpload,
  orgId,
  partnerID,
  category = "message_template_media",
}: FileUploadInterface): Promise<FileUploadResponseInterface | null> {
  const formData = new FormData();
  formData.append("file", fileToUpload);
  formData.append("channel_type", "rcs");
  if (validationForMediaFile(fileToUpload)) {
    return null;
  }
  if (orgId) {
    try {
      const res = await callUploadApi(
        `v1/organizations/${orgId}/file/upload_to_rcs/?fileCategory=${category}&partner_id=${partnerID}`,
        "post",
        formData,
      );

      if (res?.result) {
        if (res?.data?.file_url) {
          return {
            fileUrl: res.data.file_url,
          };
        }
      }

      throw new Error(res.message);
    } catch (error: Error | any) {
      showNotification("error", error.message);
    }
  }

  showNotification("error", "Failed to upload file");
  return null;
}

function getCarouselButtonsVariableCount(
  buttons: Array<CarouselButtonType>,
): number {
  if (!buttons) {
    return 0;
  }

  let count = 0;

  buttons.forEach((button) => {
    if (button.type === URL && button.urlType === DYNAMIC_URL) {
      count += 1;
    }
  });

  return count;
}

export interface CarouselVariableForSampleInterface {
  carouselHasVariables: boolean;
  carouselBodyVariableCountList: number;
  carouselButtonsVariableCountList: number;
}

export const getCarouselVariableForSample = (
  carouselCards: Array<CarouselInterface>,
): Array<CarouselVariableForSampleInterface> | [] => {
  if (!carouselCards) {
    return [];
  }

  return carouselCards.map((card) => {
    const carouselBodyVariableCount = getVariableListFromString(card.text)
      .length;

    const carouselButtonsVariableCount = getCarouselButtonsVariableCount(
      card.buttons,
    );

    return {
      carouselHasVariables: Boolean(
        carouselBodyVariableCount + carouselButtonsVariableCount,
      ),
      carouselBodyVariableCountList: carouselBodyVariableCount,
      carouselButtonsVariableCountList: carouselButtonsVariableCount,
    };
  });
};

export function checkIsCarouselHasVariables(
  carouselData: Array<CarouselVariableForSampleInterface> | undefined,
): boolean {
  if (!carouselData && !Array.isArray(carouselData)) {
    return false;
  }

  return carouselData.some((data) => data.carouselHasVariables);
}

export function checkIfArrayHasValues(data: Array<any> | undefined): boolean {
  if (!Array.isArray(data)) {
    return false;
  }

  return data.every((item) => Boolean(item));
}

export function checkIfCarouselHasAllSampleVariables(
  carouselVariables: Array<CarouselVariableForSampleInterface>,
  carouselCards: Array<CarouselInterface>,
): boolean {
  if (
    !carouselVariables ||
    !carouselCards ||
    !checkIsCarouselHasVariables(carouselVariables)
  ) {
    return true;
  }

  const hasAllBodyVariables = carouselVariables.every((_, index) => {
    const carouselBodyVariableCount =
      carouselVariables?.[index]?.carouselBodyVariableCountList;

    if (carouselBodyVariableCount === 0) return true;

    return checkIfArrayHasValues(carouselCards[index]?.textVariableSample);
  });

  const hasAllButtonsVariables = carouselCards.every((card, index) => {
    const carouselButtonsVariableCount =
      carouselVariables?.[index]?.carouselButtonsVariableCountList;

    if (carouselButtonsVariableCount === 0) return true;

    return (
      carouselVariables?.[index]?.carouselButtonsVariableCountList ===
      (card?.buttonsVariableSample?.length || 0)
    );
  });

  return hasAllBodyVariables && hasAllButtonsVariables;
}

interface CarouselCardAPIParamsInterfaceFinal {
  card_index: number;
  header_format: HeaderFormatType;
  header: null;
  header_handle: [string];
  header_handle_file_url: string;
  body: string;
  body_text: Array<string>;
  button_text: Array<string>;
  buttons: Array<
    CarouselQuickReplyButtonsAPIInterface | CarouselWebsiteUrlButtonsAPIBase
  >;
}

export function removeUrlTypeFromCarouselCardsButtons(
  carouselCards: Array<CarouselCardAPIParamsInterface>,
): Array<CarouselCardAPIParamsInterfaceFinal> {
  return carouselCards.map((card) => {
    return {
      ...card,
      buttons: card.buttons.map((button) => {
        if (button.type === URL) {
          return {
            type: button.type,
            text: button.text,
            url: button.url,
          };
        }

        return button;
      }),
    };
  });
}

// interface PayloadHeaderInterface {
//   media_url: {
//     var_name: "";
//     default_val: string;
//   };
// }

interface PayloadBodyAndFooterInterface {
  [key: string]: {
    var_name: string;
    default_val: string;
  };
}

interface CarouselCardsCampaginInterface {
  merge_variables_header: string;
  merge_variables_body: string;
  merge_variables_footer: string;
}

interface FallbackValuesInterface {
  body: Array<FallbackInterface>;
  buttons: Array<FallbackInterface>;
  header: string;
  buttonsIndex: Array<number>;
}

function getMergeVaraiblesData(
  carouselCards: Array<FallbackInterface>,
  buttonsIndex?: Array<number>,
): PayloadBodyAndFooterInterface {
  return carouselCards.reduce((acc, item, index) => {
    return {
      ...acc,
      // buttonsIndex hack is because of BE architecture
      [buttonsIndex?.[index] ?? index + 1]: {
        var_name: snakeCase(item.variable_value),
        default_val: item.fallback_values,
      },
    };
  }, {} as PayloadBodyAndFooterInterface);
}

export function getCarouselCardsPayloadForCampaignCreation(
  carouselCards: Array<FallbackValuesInterface>,
): Array<CarouselCardsCampaginInterface> {
  const isRCSChannel = getUrlParams("channel_type") === CHANNEL_TYPE_RCS;
  return carouselCards.map((card) => {
    return {
      merge_variables_header: isRCSChannel
        ? "{}"
        : JSON.stringify({
            media_url: {
              var_name: "",
              default_val: card.header ?? "",
            },
          }),
      merge_variables_body: JSON.stringify(getMergeVaraiblesData(card.body)),
      merge_variables_footer: JSON.stringify(
        getMergeVaraiblesData(card.buttons, card.buttonsIndex),
      ),
    };
  });
}
// This function returns true if header type to be shown for given arguments.
type ShouldShowHeaderTypeProps = {
  sectionType: string;
  sendButtonType: string;
  headerType: string;
};

export const shouldShowHeaderType = ({
  sectionType,
  sendButtonType,
  headerType,
}: ShouldShowHeaderTypeProps): boolean => {
  switch (sectionType) {
    case OPTION_SEND_PRODUCTS:
      switch (headerType) {
        case HEADER_TYPES.NONE:
          return sendButtonType !== TYPE_MPM;
        case HEADER_TYPES.TEXT:
          return sendButtonType !== TYPE_CATALOG;
        case HEADER_TYPES.IMAGE:
        case HEADER_TYPES.VIDEO:
        case HEADER_TYPES.DOCUMENT:
          return false;
        default:
          return true;
      }

    case OPTION_LIMITED_TIME_OFFER:
      switch (headerType) {
        case HEADER_TYPES.TEXT:
        case HEADER_TYPES.DOCUMENT:
          return false;
        default:
          return true;
      }

    case OPTION_CARAOUSAL:
      if (headerType === HEADER_TYPES.NONE) {
        return true;
      }
      return false;

    default:
      return true;
  }
};

export const checkIfContainSpecialChar = (str: string) => {
  const rxgs = /[!@#$%^&*(),.?":{}|<>]/g;
  return rxgs.test(str);
};

export function isValidateTemplateName(templateName: string) {
  if (templateName.length > 512) {
    return {
      isValid: false,
      message: "Name can not be more than 512 Characters",
    };
  }
  if (templateName.length === 0) {
    return {
      isValid: false,
      message: "Name can not be empty",
    };
  }
  if (checkIfContainSpecialChar(templateName)) {
    return {
      isValid: false,
      message: "Name can not have special characters",
    };
  }

  return {
    isValid: true,
    message: "",
  };
}
